import React from 'react';
import { BrowserRouter as Router, Route, Link, Redirect } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import { TweenLite } from 'gsap';
import Withdraw from './Withdraw';
import Transfer from './Transfer';
import Deposit from './Deposit';
import History from './History';
import Rebate from './instant-rebate/component/Rebate';
import WapLayout from 'root/layout/WapLayout';
import { connect } from 'react-redux';
import { mapStateToProps } from 'root/redux/selector/selector';
import { withRouter } from 'react-router-dom';
import { Translation, withTranslation } from 'react-i18next';
import { MdClose } from 'react-icons/md';
import Loading from 'root/components/Loading/Loading';
import { userAction } from 'root/redux/action';
// import Moment from 'moment';
import SVGElements from 'root/components/SVGElements';
import { walletAction } from 'root/redux/action/wallet.action';
import commonUtil from 'root/utils/common-util';
import AuthSettings from '@components/molecules/auth-settings/auth-settings';
import { MODULE_TYPE } from '@utils/constants';

const routes = [
    {
        path: '/account/deposit',
        key: 'deposit',
        name: 'global:global.menu.account.deposit',
        Component: Deposit,
    },
    {
        path: '/account/transfer',
        key: 'transfer',
        name: 'global:global.menu.account.transfer',
        Component: Transfer,
    },
    {
        path: '/account/withdraw',
        key: 'withdraw',
        name: 'global:global.menu.account.withdraw',
        Component: Withdraw,
    },
    {
        path: '/account/report',
        key: 'report',
        name: 'global:global.sidebar.funds.history',
        Component: History,
    },
];
class Funds extends React.Component {
    isMount = true;
    constructor(props) {
        super(props);
        this.state = {
            route: '',
            showWallet: true,
            autoTranfer: true,
            path: '',
            mainWallet: '',
            hasFakeSeamLess: true,
            disabledSeamless: false,
            disableSeamlessButton: false,
            seamLessModal: false,
            currency: '',
            liveChatUrl: '',
            // progressBarPercentage: 0,
            // depositProgress: ''
            compSettings: [],
        };
    }

    componentDidMount() {
        this.isMount = true;

        this.initCompSettings().then(() => {
            this.checkInstantRebateShow();

            const current = routes.find((it) => it.key == this.props.location.pathname.replace('/account/', ''));

            this.setState({
                route: current,
                path: (current && current.path) || '/account/transfer',
            });

            if (this.props.screen.viewType === 'web' && this.props.location.pathname.includes('/account/')) {
                this.props.history.push('/myaccount/deposit');
            }
        });

        this.loadMainWallet();
        this.getUserSeamlessStatus();
        this.intervalId = setInterval(this.contactGet, 2000);
        this.loadCurrency();
        this.countMsg();
        this.msgId = setInterval(() => {
            this.countMsg();
        }, 180000);
        // this.getProgressBarData();
    }

    countMsg = () => {
        window.SPL_Member.getUnreadMessageCount().then((data) => {
            if (this.isMount) {
                this.setState({ unreadMsgCount: data.unreadCount }, () => {
                    let count = this.state.unreadMsgCount;

                    this.props.dispatch(userAction.getUnreadMsg(count));
                });
            }
        });
    };

    // getProgressBarData = () => {
    //   const { account } = this.props.user;
    //   const { key } = this.props.language;
    //   const { isMobile } = this.props.screen;

    //   if (key) {
    //     let lang_key = key.toLowerCase();

    //     window.SPL_Member.loadMemberGroupAutomationSummary(isMobile, account.currency, account.groupName, account.imagePath, lang_key).then(data => {
    //       this.setState({ progressBarPercentage: data.progressBarPercentage, memberGroupLevelMessages: data.memberGroupLevelMessages, userGroupImage: data.userGroupImage, userReviewDate: data.firstDayOfMonth, depositProgress: data.depositProgress });
    //     });
    //   }
    // }

    loadCurrency() {
        const { currency } = this.props.user.account || { currency: '' };
        this.setState({ currency: currency });
    }

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            // this.getUserSeamlessStatus();
        }

        if (prevProps.location.pathname !== this.props.location.pathname) {
            const current = routes.find((it) => it.key == this.props.location.pathname.replace('/account/', ''));

            this.setState({
                route: current,
                path: (current && current.path) || '/account/transfer',
            });
        }

        if (prevProps.wallet.loadWallet !== this.props.wallet.loadWallet) {
            if (this.props.wallet.loadWallet) {
                this.loadMainWallet();

                setTimeout(() => {
                    this.props.dispatch(walletAction.updateLoadWallet(false));
                }, 500);
            }
        }
    }

    componentWillUnmount() {
        this.isMount = false;
        clearInterval(this.intervalId);
        clearInterval(this.msgId);
    }

    contactGet = () => {
        const { language, screen } = this.props;
        window.SPL_Content.getContact(language.countryLanguageKey, screen.viewType).then((data) => {
            if (this.isMount) {
                if (data) {
                    for (let i = 0; i < data.length; i++) {
                        const contact = data[i];
                        if (contact.name === 'LiveChat') {
                            this.setState({ liveChatUrl: contact.desc });
                        }
                    }
                }
            }
        });
    };

    contactOnClick = (url) => {
        window.open(url, '', 'width=500,height=700,toolbar=no,location=no,directories=no,status=yes,menubar=no,scrollbars=no');
    };

    getUserSeamlessStatus() {
        const { isLogin } = this.props.user;
        if (isLogin === true) {
            window.SPL_Member.loadMemberSeamlessStatus().then((res) => {
                if (this.isMount) {
                    this.setState({
                        hasFakeSeamLess: res.hasFakeSeamless,
                        disabledSeamless: res.isFakeSeamlessDisabled,
                    });
                }
            });
        }
    }

    handleSeamlessChange = () => {
        this.setState({ disableSeamlessButton: true });
        window.SPL_Member.updateMemberSeamlessStatus(this.state.disabledSeamless, this.state.hasFakeSeamLess).then((res) => {
            if (res.hasFakeSeamless) {
                this.setState(
                    {
                        disabledSeamless: res.isFakeSeamlessDisabled,
                        message: res.message,
                        disableSeamlessButton: false,
                    },
                    () => {
                        this.getSeamlessModal();
                    }
                );
            }
        });
    };

    getSeamlessModal = () => {
        this.setState({ seamLessModal: !this.state.seamLessModal });
    };

    loadMainWallet = () => {
        window.SPL_Member.getMainWallet().then((mainWallet) => {
            this.setState({
                mainWallet: mainWallet.data.balance,
                restoreOnLoad: false,
            });
        });
    };

    restoreWallet = () => {
        this.setState({ restoreOnLoad: true });
        window.SPL_Transaction.transferAllBackMainWallet()
            .then(() => {
                setTimeout(() => {
                    // this.loadMainWallet();
                    this.props.dispatch(walletAction.updateLoadWallet(true));
                }, 5000);
            })
            .catch(() => {
                setTimeout(() => {
                    // this.loadMainWallet();
                    this.props.dispatch(walletAction.updateLoadWallet(true));
                }, 5000);
            });
    };

    onReload = (target) => {
        // this.loadMainWallet();
        this.props.dispatch(walletAction.updateLoadWallet(true));
        TweenLite.fromTo(
            target,
            1,
            {
                rotate: 0,
            },
            {
                rotate: 360,
            }
        );
    };

    handleLink(route) {
        this.props.history.push(route.path);
        this.setState({
            route,
            path: route.path,
        });
    }

    redirectMessaging = () => {
        const { screen } = this.props;
        if (screen.viewType === 'web') {
            this.props.history.push('/myaccount/messaging');
        } else {
            this.props.history.push('/myprofile/messaging');
        }
    };

    checkInstantRebateShow() {
        const { compSettings } = this.state;
        let checkRebate = routes.filter((e) => e.key === 'rebate');

        if (checkRebate.length === 0) {
            let res = {
                path: '/account/rebate',
                key: 'rebate',
                name: 'global:global.sidebar.funds.rebate',
                Component: Rebate,
            };
            if (compSettings && compSettings.showInstantRebate) {
                routes.push(res);
            }
        }
    }

    initCompSettings() {
        return new Promise((resolve) => {
            // Step 1: Standard way to get from portal settings
            let tempSettings = commonUtil.getSettingFromPortalSettings({
                vm: this,
                settingName: 'memberSettings',
            });

            // Step 2: Massage again to get the specific data you need (Skip if not needed)

            // Step 3: Initial all into compSettings
            let compSettings = {
                showInstantRebate: tempSettings.showInstantRebate,
            };

            this.setState({ compSettings }, () => {
                resolve();
            });
        });
    }

    render() {
        const { user } = this.props;

        // const styles = {
        //   progressbarWidth: {
        //     width: this.state.progressBarPercentage + '%',
        //   }
        // };
        // const { progressbarWidth } = styles;

        return (
            <Translation>
                {(t) => (
                    <div className='wap'>
                        <AuthSettings module={MODULE_TYPE?.BIND_AUTHENTICATOR} isPublic={false} />
                        <AuthSettings module={MODULE_TYPE?.SIX_PIN} isPublic={false} />

                        {this.state.restoreOnLoad && <Loading />}

                        {this.state.seamLessModal && (
                            <div className='messaging-popup'>
                                <div className='popup'>
                                    <div className='popup-header'>
                                        <div className='popup-header-left'>
                                            <div className='popup-title'>{t('global:global.menu.account.autoTransfer', 'Main Wallet Auto Transfer')}</div>
                                        </div>
                                        <div className='popup-header-right'>
                                            <SVGElements name='close-icon' onClick={() => this.getSeamlessModal()} />
                                            {/* <MdClose onClick={() => this.getSeamlessModal()} /> */}
                                        </div>
                                    </div>

                                    <div className='popup-body'>
                                        {t('global:global.messages.autoTransfer.content1', 'You have')}{' '}
                                        {!this.state.disabledSeamless
                                            ? t('global:global.messages.autoTransfer.enable', 'enable')
                                            : t('global:global.messages.autoTransfer.disable', 'disable')}{' '}
                                        {t(
                                            'global:global.messages.autoTransfer.content2',
                                            'Auto Transfer. Please allow up to 5 minutes for the system to update your preferences. Thank you.'
                                        )}
                                    </div>

                                    <div className='popup-footer'>
                                        <span className='btn-normal btn-body' onClick={() => this.getSeamlessModal()}>
                                            {t('login:login.ok', 'ok')}
                                        </span>
                                    </div>
                                </div>
                                <div className='popup-overlay' onClick={() => this.getSeamlessModal()}></div>
                            </div>
                        )}

                        <WapLayout name={t(this.state.route.name)}>
                            <div className='wap-pages-out'>
                                <div className='fixed-box mAccTopPadding'>
                                    {/* <div className="title">{this.state.route.name}</div> */}
                                    <div className='auto-tranfer-box'>
                                        <div className='left'>
                                            <span>{t('global:global.form.mainWallet', 'Main Wallet')}</span>
                                            <SVGElements
                                                className={`icon-wap-eye ${!this.state.showWallet ? 'off' : ''}`}
                                                name={this.state.showWallet ? 'eye-show-icon' : 'eye-hide-icon'}
                                                onClick={() =>
                                                    this.setState({
                                                        showWallet: !this.state.showWallet,
                                                    })
                                                }
                                            />
                                            {/* <i className={["icon-wap-eye", this.state.showWallet ? "" : "off",].join(" ")}
                        onClick={() => this.setState({ showWallet: !this.state.showWallet })}></i> */}
                                            {/* <div className="member-info">
                        <div className='member-name-level'>
                          <img src={this.state.userGroupImage} className="icon-sapphire" />
                          <span>{this.props.user.account.groupName}</span>
                        </div>
                        <div className='member-name-info'>
                          <span className="highlight-text">{this.props.user.account.login}</span>
                          <span className='info-progress'>{this.state.depositProgress}</span>
                          <div className="line-out">
                            <div className="line-inner" style={progressbarWidth}></div>
                            <span>{this.state.progressBarPercentage}%</span>
                          </div>
                          <div className='rank-condition' dangerouslySetInnerHTML={{ __html: this.state.memberGroupLevelMessages }} />
                          <span className='memberReview-date'>{t('member:PortalApp.member.reviewDate', 'Review Date')}: {this.state.userReviewDate && Moment(this.state.userReviewDate).format('DD/MM/YYYY')}</span>
                        </div>
                      </div> */}
                                        </div>
                                        <div className='right'>
                                            <div className='auto-transfer'>
                                                <span className='highlight-text'>{t('global:global.menu.account.autoTransfer', 'Main Wallet Auto Transfer')}</span>
                                                <div
                                                    className={['icon-wap-auto', !this.state.disabledSeamless ? '' : 'off'].join(' ')}
                                                    onClick={() => this.handleSeamlessChange()}
                                                    disabled={this.state.disableSeamlessButton}
                                                ></div>
                                            </div>
                                            {/* <div className="message-box">
                        <i className="icon-wap-chat" onClick={() => this.contactOnClick(this.state.liveChatUrl)}></i>
                        <i className="icon-wap-message" onClick={() => this.redirectMessaging()}>
                          {user.unreadMsg > 0 && (
                            <span>{user.unreadMsg}</span>
                          )}
                        </i>
                      </div> */}
                                        </div>
                                    </div>
                                    <div className='currency-box'>
                                        <div className='num'>
                                            <span>{this.state.currency}</span>
                                            <span>{this.state.showWallet ? window.SPL_Other.formatAmount(this.state.mainWallet) : '******'}</span>
                                        </div>
                                        <SVGElements className='icon-wap-reload' name='reload-icon' onClick={(e) => this.onReload(e.target)} />
                                        {/* <i className="icon-wap-reload" onClick={(e) => this.onReload(e.target)}></i> */}
                                    </div>
                                    <div className='restore-box'>
                                        <span className='btn-restore' onClick={() => this.restoreWallet()}>
                                            {t('settings:settings.transferwalletv2', 'Restore')}
                                        </span>
                                        <div className='message-box'>
                                            {/* {this.state.liveChatUrl &&
                        <i className="icon-wap-chat" onClick={() => this.contactOnClick(this.state.liveChatUrl)}></i>
                      } */}
                                            <i className='icon-wap-message' onClick={() => this.redirectMessaging()}>
                                                <SVGElements name='message-icon' />
                                                {user.unreadMsg > 0 && (
                                                    <div className='msg-count-box'>
                                                        <div className='tag'>{user.unreadMsg}</div>
                                                    </div>
                                                )}
                                            </i>
                                        </div>
                                    </div>
                                </div>

                                <section className='st-shortcut-menu-section'>
                                    <Router>
                                        <ul className='wap-funds-list funds-list'>
                                            {routes.map((route) => (
                                                <li key={route.key} className={this.state.route.key == route.key ? 'on' : ''}>
                                                    <p
                                                        onClick={() => {
                                                            this.handleLink(route);
                                                        }}
                                                    >
                                                        <span className='icon-box'>
                                                            <SVGElements
                                                                className={'icon-wap-' + route.key}
                                                                name={this.state.route.key == route.key ? 'm-' + route.key + '-icon-on' : 'm-' + route.key + '-icon'}
                                                            />
                                                            {/* <i className={["icon-wap-" + route.key, this.state.route.key == route.key ? "on" : "",].join(" ")}></i> */}
                                                        </span>
                                                        <span>{t(route.name)}</span>
                                                    </p>
                                                </li>
                                            ))}
                                        </ul>
                                    </Router>
                                </section>

                                <div className='wap-pages'>
                                    {routes.map(({ path, Component }) => (
                                        <CSSTransition
                                            key={path}
                                            in={this.state.path === path}
                                            timeout={300}
                                            //classNames="page-slide"
                                            unmountOnExit
                                        >
                                            <Component onReload={this.onReload} />
                                        </CSSTransition>
                                    ))}
                                </div>
                            </div>
                        </WapLayout>
                    </div>
                )}
            </Translation>
        );
    }
}

export default connect(mapStateToProps)(withTranslation()(withRouter(Funds)));
