import PropTypes from 'prop-types';
import React from 'react';
import { Translation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Col, ModalBody, ModalHeader, Modal } from 'reactstrap';
import { mapStateToProps } from '../../../redux/selector/selector';
import SVGMaster from '../../../components/Icon/SVGMaster';

class NavigationAnnounceModal extends React.Component {
  isMount = true;
  constructor(props) {
    super();
    this.state = {
      isMount: true,
      annoucementList: [],
      isOpen: false,
    };
    this.toggle = this.toggle.bind(this);
  }

  componentDidMount() {
    this.isMount = true;

    this.getAnnoucement();
  }

  toggle() {
    this.setState({ isOpen: !this.state.isOpen });
  }

  getAnnoucement() {
    const { countryLanguageKey } = this.props.language;
    const { isLogin } = this.props.user;

    window.SPL_Content.getAnnoucementList(isLogin, countryLanguageKey).then((data) => {
      if (this.isMount) {
        this.setState({ annoucementList: data.announcementsDisplay });
      }
    });
  }

  render() {
    return (
      <Translation>
        {(t) => (
          <div>
            <Modal className='modal-lg' isOpen={this.state.isOpen} toggle={this.toggle}>
              <ModalHeader>
                <Col xs='10' md='10'>
                  <div className='text-nowrap'>{t('global:global.announcement2', 'Annoucement')}</div>
                </Col>
                <Col xs='2' md='2' className='d-flex align-items-center justify-content-end'>
                  <button onClick={() => this.setState({ isOpen: false })} className='close'>
                    <span className='inner-cancel'>x</span>
                  </button>
                </Col>
              </ModalHeader>

              <ModalBody className='text-left w-100'>
                {this.state.annoucementList.length > 0 ? (
                  <div>
                    {this.state.annoucementList.map((announcement, index) => {
                      return (
                        <div key={index}>
                          <span>{announcement}</span>
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <div className='d-flex justify-content-center'>{t('global:global.noannouncement', 'No Latest Annoucement..')}</div>
                )}
              </ModalBody>
            </Modal>

            <a onClick={() => this.setState({ isOpen: true })} className='d-flex justify-content-center align-items-center'>
              <svg className='icon-notified icon-sm' aria-hidden='true'>
                <SVGMaster name='notified' />
              </svg>
            </a>
          </div>
        )}
      </Translation>
    );
  }
}

export default connect(mapStateToProps)(withTranslation(['promotion', 'settings'])(NavigationAnnounceModal));
NavigationAnnounceModal.defaultProps = {
  buttonText: 'Click Me!',
};
NavigationAnnounceModal.propTypes = {
  buttonText: PropTypes.string,
};
