import React, { Suspense } from 'react';

const LazyComponent = (Component, props) => {
    return (
        <Suspense fallback={<></>}>
            <Component {...props}></Component>
        </Suspense>
    );
};

export default LazyComponent;