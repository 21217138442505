// library
import React from 'react';
import { connect } from 'react-redux';
import { mapStateToProps } from 'root/redux/selector/selector';
import { withRouter } from 'react-router-dom';
import Slider from 'react-slick';

// selector / component
import FloatingNotificationContent from './floating-notification-content';

class NotificationList extends React.Component {
    constructor() {
        super();
        this.state = {
            notificationList: [],
            firstNotification: [],
            isClose: false,
        };
    }

    componentDidMount() {
        this.getList();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.notificationList !== this.props.notificationList || prevProps.closedFloatingNotification !== this.props.closedFloatingNotification) {
            this.getList();
        }
    }

    getList() {
        const { notificationList, notificationType, filterNotificationContent } = this.props;
        const { firstNotification } = this.state;
        const _notificationList = [];

        for (const notification of notificationList) {
            const data = filterNotificationContent(notification?.floatingNotificationContents, notificationType, notification?.floatingNotificationSettingId);

            if (data.length > 0) {
                if (firstNotification.length === 0) {
                    this.setState({ firstNotification: data });
                }

                _notificationList.push(notification);
            }
        }

        this.setState({ notificationList: _notificationList });
    }

    getNotificationListClassName() {
        const { firstNotification } = this.state;
        let className = '';

        if (firstNotification?.length > 0) {
            const data = firstNotification[0];

            if (data.horizontalPosition === 'BOTTOM') {
                let bottomClassName = 'floating-banner-container-bottom';
                className = className.concat(' ', bottomClassName);
            } else if (data.horizontalPosition === 'RIGHT') {
                className = className.concat(' ', 'floating-banner-container-right');
            } else {
                className = className.concat(' ', 'floating-banner-container-left');
            }
        }

        return className;
    }

    setNotificationClose() {
        this.setState({ isClose: true });
    }

    render() {
        const { notificationList, firstNotification, isClose } = this.state;
        const verticalPosition = firstNotification?.[0]?.verticalPosition;
        const { filterNotificationContent, notificationType, closeNotification, notificationClick, displayFrequencyType, customTranslationObj } = this.props;

        if (isClose) {
            return null;
        }

        return (
            <div
                className={`${notificationType === 'SLIDING' ? 'notification-slider-list-container' : 'notification-list-container'} ${this.getNotificationListClassName()}`}
                style={verticalPosition && { top: verticalPosition + '%' }}
            >
                {notificationList &&
                    (notificationType === 'SLIDING' ? (
                        <Slider infinite={notificationList?.length > 1} slidesToShow={1} slidesToScroll={1} autoplay={true} arrows={false} autoplaySpeed={7000} dots={false}>
                            {notificationList.map((data) => {
                                return (
                                    <FloatingNotificationContent
                                        key={data.id}
                                        notificationContent={filterNotificationContent(
                                            data?.floatingNotificationContents,
                                            notificationType,
                                            data?.floatingNotificationSettingId
                                        )}
                                        closeNotification={closeNotification}
                                        floatingNotificationSettingId={data?.floatingNotificationSettingId}
                                        notificationClick={notificationClick}
                                        displayFrequencyType={displayFrequencyType}
                                        customTranslationObj={customTranslationObj}
                                        notificationType={this.props.notificationType}
                                        setNotificationClose={() => this.setNotificationClose()}
                                    />
                                );
                            })}
                        </Slider>
                    ) : (
                        notificationList.map((data) => {
                            return (
                                <FloatingNotificationContent
                                    key={data.id}
                                    notificationContent={filterNotificationContent(data?.floatingNotificationContents, notificationType, data?.floatingNotificationSettingId)}
                                    closeNotification={closeNotification}
                                    floatingNotificationSettingId={data?.floatingNotificationSettingId}
                                    notificationClick={notificationClick}
                                    displayFrequencyType={displayFrequencyType}
                                    customTranslationObj={customTranslationObj}
                                />
                            );
                        })
                    ))}
            </div>
        );
    }
}

export default connect(mapStateToProps)(withRouter(NotificationList));
