import React from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { AiOutlineCopy } from 'react-icons/ai';

// components
import PasswordGuideLine from './../password-guideline/password-guideline';
import PasswordInput from './../../atoms/password-input/password-input';
import Input from './../../atoms/input/input';
import styles from './form-field.module.scss';
import { ValidationCheckers } from '../password-guideline/password-guideline';

// core / logic

/**
 *
 * @param {String} layout -- horizontal or vertical
 * @returns
 */
const FormField = ({ label, value, errorMessage, ...props }) => {
    const {
        placeholder,
        onBlur,
        onChange,
        name,
        isShowSpecialCharsGuide,
        disabled,
        type,
        debouncedInputMS,
        onCopyClick,
        layout,
        isMandatory,
        onKeyPress,
        isValidationChecker,
        validationCheckers,
        onPaste,
        showErrorMsgPriority,
    } = props;
    const [touched, setTouched] = useState(false);
    // const [focusedField, setFocusedField] = useState(null);

    const handleFieldChange = (event) => {
        onChange && onChange(event);
        setTouched(true);
    };

    const handleFieldBlur = (event) => {
        onBlur && onBlur(event);
        // setFocusedField(null);
    };

    const handlePaste = (event) => {
        onPaste && onPaste(event);
    };

    // const handleFieldFocus = (event) => {
    //     const { name } = event.target;
    //     setFocusedField(name);
    // };

    const _fieldProps = {
        value: value,
        type: type,
        name: name,
        className: `${styles.customInput || ''} ${onCopyClick ? 'copy-input' : ''}`,
        onChange: handleFieldChange,
        onBlur: handleFieldBlur,
        // onFocus: handleFieldFocus,
        onKeyPress: onKeyPress,
        placeholder: placeholder,
        disabled: disabled,
        debouncedInputMS: debouncedInputMS,
        readOnly: props.readOnly,
        onPaste: handlePaste,
    };

    return (
        <>
            <div className={`standard-form-field ${styles.standardFormField || ''} ${styles?.[layout] || ''}  `}>
                {/* LABEL */}
                {label && <label className={`standard-label ${styles.fieldLabel} ${isMandatory ? 'standard-mandatory' : ''}`}> {label}</label>}
                {/* INPUT */}
                {type === 'password' ? (
                    <PasswordInput {..._fieldProps} layout={layout} hasLabel={label} />
                ) : (
                    <>
                        {onCopyClick ? (
                            <div className={`standard-form-field-wrapper ${styles.formFieldWrapper}`}>
                                <Input {..._fieldProps} />
                                {/* <div className={styles.copy} onClick={onCopyClick}>
                                <AiOutlineCopy className='icon-copy' onClick={onCopyClick} />
                            </div> */}
                                <AiOutlineCopy className={`${styles.copy} icon-copy`} onClick={onCopyClick} />
                            </div>
                        ) : (
                            <Input {..._fieldProps} />
                        )}
                    </>
                )}
                {/* ERROR MESSAGE */}
                <RenderErrorMessage
                    isValidationChecker={isValidationChecker}
                    name={name}
                    touched={touched}
                    isShowSpecialCharsGuide={isShowSpecialCharsGuide}
                    value={value}
                    errorMessage={errorMessage}
                    layout={layout}
                    validationCheckers={validationCheckers}
                    showErrorMsgPriority={showErrorMsgPriority}
                />
            </div>
        </>
    );
};

export default FormField;

export const RenderInput = () => {};

export const RenderErrorMessage = ({ ...props }) => {
    const passwordReducer = useSelector((state) => state.password);
    const { isValidationChecker, touched, value, isShowSpecialCharsGuide, errorMessage, layout, validationCheckers, showErrorMsgPriority } = props;
    return (
        <>
            {/* NOTE: validationCheckers is the flexible version of isValidationChecker && passwordReducer?.processedData?.passwordConfig?.newErrorFormat */}
            {isValidationChecker && validationCheckers ? (
                <>
                    {touched && (
                        <div className={`${styles.errorMessageWrapper} error-message-wrapper`}>
                            <ValidationCheckers validationCheckers={validationCheckers} />
                        </div>
                    )}
                </>
            ) : (
                <>
                    {isValidationChecker && passwordReducer?.processedData?.passwordConfig?.newErrorFormat && !showErrorMsgPriority ? (
                        <>
                            {touched && (
                                <div className={`${styles.errorMessageWrapper} error-message-wrapper`}>
                                    <PasswordGuideLine
                                        password={value}
                                        customStatusImage={{
                                            success: 'check-circle',
                                            fail: 'warning-circle',
                                        }}
                                        isShowSpecialCharsGuide={isShowSpecialCharsGuide}
                                    />
                                </div>
                            )}
                        </>
                    ) : (
                        <>
                            {errorMessage && (
                                <div className={`${styles.errorValidation} ${styles?.[layout] || ''} standard-error-validation`}>
                                    <span>{errorMessage}</span>
                                </div>
                            )}
                        </>
                    )}
                </>
            )}
        </>
    );
};
