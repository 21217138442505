import depositMaterial from './deposit-method';
import moment from 'moment';
import commonUtil from '../../utils/common-util';
import { trans_massageHideAddBank } from 'root/utils/transaction-util';
import notification from 'root/utils/notification';
import { sumUp } from './deposit-method';
import { trans_getDepositAmountOptionByMethod } from 'root/utils/transaction-util';
import { scrollToDepositStep2 } from './deposit-util';

const controller = {
    vm: null,

    init(vm) {
        controller.vm = vm;
        let currentDate = new Date();
        const disablePreselectBank = !!controller.vm.props.portal?.settings?.features?.depositSettings?.disablePreselectBank;

        controller.vm.state = {
            activeTab: 'onlinetransfer',
            iconLoading: false,
            depositDetails: null,
            selectedMethod: [],
            selectedBank: [],
            amount: '',
            amountErrMsg: [],
            disabledDepositButton: true,
            limitAmount: '',
            currency: '',
            referenceCode: '',
            depositOptions: null,
            bankList: [],
            bankNameErrMsg: [],
            selectedDepositChannel: [],
            receiptImg: [],
            selectedDepositBank: null,
            radioValue: disablePreselectBank ? null : 0,
            status: '',
            message: '',
            moment: moment(),
            histories: [],
            start: new Date(new Date().setMonth(currentDate.getMonth() - 1)),
            end: currentDate,
            currentPage1: 1,
            todosPerPage1: 5,
            showLoading: false,
            isLoading: false,
            merchantBankList: [],
            thisDate: new Date(),
            language: '',
            visible: false,
            receiptMandatoryBankCodes: [],
            requiredReceiptForOnlineBanking: false,
            bankAccountRequired: false,
            enable7Eleven: false,
            enableOTC: false,
            hideATM: false,
            showOnlineBankingOnly: false,
            hideCashDeposit: false,
            showDepositChannel: true,
            hidePWallet: true,
            memberBankAsMandatory: false,
            showOnlineBankDetails: false,
            hideAddBankAccount: false,
            showRefId: true,
            autoSelectMemberBankId: null,
            uploadReceipt: false,
            isUploadOnlineBankReceiptOptional: false,
            getTncReferral: false,
            depositNotice: '',
            certainBankRequiredReceipt: false,

            depositAllSettings: controller.vm.props.depositAllSettings,
            filteredDepositApiJson: controller.vm.props.filteredDepositApiJson,
            depositMinMaxLimit: null,

            //notice artical settings
            showDefaultArticle: false,
            showCustomDepositArticle: false,
            showCustomDepositArticleContent: '',

            enableCustomArticleJSON: false,
            compSettings: [],
            depositSubmitCountdown: null,
            isShowNoticeBox: false,
            depositAmountOptions: trans_getDepositAmountOptionByMethod(controller.vm.props.depositAmountOptions),
        };
    },

    initCompSettings() {
        commonUtil.loadCustomTranslation('bankTransfer', controller.vm);

        return new Promise((resolve) => {
            // Step 1: Standard way to get from portal settings
            let tempSettings = commonUtil.getSettingFromPortalSettings({
                vm: controller.vm,
                settingName: 'bankTransferSettings',
            });

            // Step 2: Massage again to get the specific data you need (Skip if not needed)

            // Step 3: Initial all into compSettings
            let compSettings = {
                hideDisplayInfo: tempSettings.hideDisplayInfo,
                showCustomArticle: tempSettings.showCustomArticle,
                enableCustomArticleJSON: tempSettings.enableCustomArticleJSON,
                showOnlineTransactionMode: tempSettings.showOnlineTransactionMode,
                isCustomArticlePath: tempSettings.isCustomArticlePath,
            };

            controller.vm.setState({ compSettings }, () => {
                const { showCustomArticle } = controller.vm.state.compSettings;
                if (showCustomArticle) {
                    controller.readCustomDepositArticle();
                }
                resolve();
            });
        });
    },

    readCustomDepositArticle() {
        const { compSettings } = controller.vm.state;
        const customFilename = compSettings && compSettings.isCustomArticlePath ? 'b' : '';
        commonUtil.readCustomDepositArticle(controller.vm, customFilename);
    },

    loadLastOnlineBankingMemberId() {
        window.SPL_Transaction.loadLastOnlineBankingMemberId().then((res) => {
            const { memberBankList, selectedBank, autoSelectMemberBankId } = controller.vm.state;
            let selectedBankUpdate = selectedBank;
            let autoSelectMemberBankIdUpdate = autoSelectMemberBankId;

            for (let i = 0; i < memberBankList.length; i++) {
                if (memberBankList[i].memberBankId + '' === res) {
                    selectedBankUpdate = [memberBankList[i]];
                    autoSelectMemberBankIdUpdate = memberBankList[i].bankId;
                    break;
                }
            }

            controller.vm.setState({ selectedBank: selectedBankUpdate, autoSelectMemberBankId: autoSelectMemberBankIdUpdate });
        });
    },

    setDepositOptions() {
        const { t } = controller.vm.props;
        const { showOnlineBankingOnly, hideATM, enable7Eleven, hideCashDeposit, enableOTC } = controller.vm.state;
        let onlineBanking = { label: t('settings:settings.dropdown.onlineBanking'), value: 'O' };
        let cashDepositMachine = { label: t('settings:settings.dropdown.cashDepositMachine'), value: 'D' };
        let atmTransfer = { label: t('settings:settings.dropdown.atmTransfer'), value: 'A' };
        let sevenEleven = { label: t('settings:settings.dropdown.sevenEleven'), value: 'SE' };
        let overTheCounter = { label: t('settings:settings.dropdown.otc'), value: 'OTC' };
        let depositOptions = [];

        depositOptions.push(onlineBanking);

        if (showOnlineBankingOnly) {
            controller.vm.setState({ depositOptions: depositOptions, selectedDepositChannel: [onlineBanking] });
        } else {
            if (!hideCashDeposit) {
                depositOptions.push(cashDepositMachine);
            }

            if (!hideATM) {
                depositOptions.push(atmTransfer);
            }

            if (enable7Eleven) {
                depositOptions.push(sevenEleven);
            }

            if (enableOTC) {
                depositOptions.push(overTheCounter);
            }
        }
        controller.vm.setState({ depositOptions: depositOptions });
    },

    handleBankChange(value) {
        controller.vm.setState({ selectedBank: value }, () => {
            controller.checkCertainBankRequiredReceipt();
            controller.checkError();
        });
    },

    handleDepositChannelChange(value) {
        const vm = controller.vm;
        let stateObj = {};

        vm.setState({ selectedDepositChannel: value }, () => {
            const { selectedDepositChannel, memberBankList, memberBankAsMandatory, selectedBank, autoSelectMemberBankId } = vm.state;

            let showOnlineBankDetailsUpdate = false;
            let selectedBankUpdate = selectedBank;
            if (selectedDepositChannel.length > 0) {
                let depositChannel = selectedDepositChannel[0].value;
                if (depositChannel === 'O' || depositChannel === 'A') {
                    showOnlineBankDetailsUpdate = memberBankAsMandatory;

                    if (memberBankList && memberBankList.length === 1) {
                        selectedBankUpdate = [memberBankList[0]];
                    } else if (autoSelectMemberBankId != null) {
                        for (var i = 0; i < memberBankList.length; i++) {
                            if (
                                (memberBankList[i].bankId != null && memberBankList[i].bankId === autoSelectMemberBankId) ||
                                (memberBankList[i].bankId == null && autoSelectMemberBankId === 0)
                            ) {
                                selectedBankUpdate = [memberBankList[i]];
                                break;
                            }
                        }
                    }
                }
            }

            stateObj = Object.assign(stateObj, { showOnlineBankDetails: showOnlineBankDetailsUpdate, selectedBank: selectedBankUpdate });

            if (value.length > 0) {
                const depositChannel = value[0].value;
                if (depositChannel === 'O') {
                    stateObj = Object.assign(stateObj, { status: '', message: '' });
                } else {
                    if (controller.checkReceiptRequired()) {
                        stateObj = Object.assign(stateObj, { status: 'F', message: 'transaction:transaction.reload.receiptIsRequired' });
                    }
                }
            }

            controller.checkCertainBankRequiredReceipt();
            vm.setState(stateObj, () => {
                controller.checkError();
            });
        });
    },

    checkReceiptRequired() {
        const {
            selectedDepositChannel,
            receiptImg,
            receiptMandatoryBankCodes,
            requiredReceiptForOnlineBanking,
            selectedDepositBank,
            uploadReceipt,
            certainBankRequiredReceipt,
            isUploadOnlineBankReceiptOptional,
        } = controller.vm.state;

        if (isUploadOnlineBankReceiptOptional) return false;

        if (uploadReceipt) {
            if (selectedDepositChannel.length > 0) {
                let depositChannel = selectedDepositChannel[0].value;

                if (
                    ((receiptMandatoryBankCodes && receiptMandatoryBankCodes.indexOf(selectedDepositBank.bankCode) !== -1) ||
                        depositChannel === 'D' ||
                        depositChannel === 'A' ||
                        (depositChannel === 'O' && requiredReceiptForOnlineBanking && !certainBankRequiredReceipt) ||
                        depositChannel === 'SE' ||
                        depositChannel === 'OTC') &&
                    receiptImg.length <= 0
                ) {
                    return true;
                } else {
                    return false;
                }
            } else {
                controller.vm.setState({ disabledDepositButton: true });

                return false;
            }
        } else {
            controller.vm.setState({ disabledDepositButton: true });

            return false;
        }
    },

    handleReceiptImageChange(event) {
        if (event && event.target && event.target.files.length > 0) {
            controller.vm.setState({ receiptImg: URL.createObjectURL(event.target.files[0]), receiptImgFile: event.target.files[0] }, () => {
                if (controller.vm.state.receiptImg.length > 0) {
                    controller.vm.setState({ status: '', message: '', visible: false });
                }

                controller.checkError();
            });
        }
    },

    handlereferenceCodeChange(event) {
        controller.vm.setState({ referenceCode: event.target.value }, () => {
            controller.checkError();
        });
    },

    onDepositBankAcc(index) {
        const oldSelectedDepositBank = controller.vm.state.selectedDepositBank;
        const {
            enableScrollToDepositStep2 = false,
            scrollToDepositStep2Attempts = 15,
            scrollToDepositStep2Delay = 200,
        } = controller.vm.props.portal?.settings?.features?.depositSettings || {};

        controller.vm.setState({ selectedDepositBank: controller.vm.state.merchantBankList[index], radioValue: index }, () => {
            scrollToDepositStep2(
                enableScrollToDepositStep2,
                oldSelectedDepositBank,
                controller.vm.state.selectedDepositBank,
                scrollToDepositStep2Attempts,
                scrollToDepositStep2Delay
            );

            commonUtil.getDepositMinMax(
                controller.vm,
                'B',
                controller.vm.props.user.account.currency,
                controller.vm.state.merchantBankList[controller.vm.state.radioValue]?.id
            );
            controller.checkCertainBankRequiredReceipt();
        });
    },

    getDepositDetails() {
        window.SPL_Transaction.loadDepositRelatedSettings().then((data) => {
            if (data) {
                controller.vm.setState({ depositNotice: data.depositJson.onlinetransfer.custom_article });
            }
        });
    },

    checkCountryLanguage() {
        if (controller.vm.props.language.countryLanguageKey === 'TH_TH') {
            controller.vm.setState({ language: 'th' });
        } else if (controller.vm.props.language.countryLanguageKey === 'ZH_MY') {
            controller.vm.setState({ language: 'zhCN' });
        } else {
            controller.vm.setState({ language: '' });
        }
    },

    loadDepositDetails() {
        controller.vm.setState({ isLoading: true });
        const { account } = controller.vm.props.user;
        const { filteredDepositApiJson } = controller.vm.state;
        let currency = '';
        if (account) {
            currency = account.currency;
        }

        if (filteredDepositApiJson) {
            // controller.getCorrectDepositMinMaxLimit();
        } else {
            // selectedMethod dont know for what purpose, because is quick-pay data lai de
            if (Object.keys(depositMaterial.depositDetails).length > 0 && currency === depositMaterial.currentCurrency) {
                controller.vm.setState(
                    {
                        depositDetails: depositMaterial.getDepositDetails(),
                        selectedMethod: [depositMaterial.getDepositDetails().defaultMethod],
                        isLoading: false,
                        depositMinMaxLimit: depositMaterial.getDepositDetails().depositLimit,
                    },
                    () => {
                        controller.validateDepositAmount();
                    }
                );
            } else {
                window.SPL_Transaction.loadDepositDetails(currency, controller.vm.state.activeTab, false).then((depositDetails) => {
                    controller.vm.setState(
                        {
                            depositDetails: depositDetails,
                            selectedMethod: [depositDetails.defaultMethod],
                            isLoading: false,
                            depositMinMaxLimit: depositDetails.depositLimit,
                        },
                        () => {
                            controller.validateDepositAmount();
                            depositMaterial.setDepositDetails(depositDetails);
                            depositMaterial.setCurrentCurrency(currency);
                        }
                    );
                });
            }
        }
    },

    getCorrectDepositMinMaxLimit() {
        const { depositAllSettings } = controller.vm.state;
        const currency = controller.vm.props.user.account.currency;
        let _depositMinMaxLimit = { min: 0, max: 0 };

        if (depositAllSettings && depositAllSettings.depositJson && depositAllSettings.depositJson.onlinetransfer && depositAllSettings.depositJson.onlinetransfer[currency]) {
            let depositLimit = depositAllSettings.depositJson.onlinetransfer[currency];
            _depositMinMaxLimit.minLimit = depositLimit.min;
            _depositMinMaxLimit.maxLimit = depositLimit.max;
        }
        controller.vm.setState({ depositMinMaxLimit: _depositMinMaxLimit });
    },

    async loadMerchantBank() {
        let currency = null;

        if (controller.vm.props.user && controller.vm.props.user.account) {
            currency = controller.vm.props.user.account.currency;
        }

        const { filteredDepositApiJson } = controller.vm.state;
        const disablePreselectBank = !!controller.vm.props.portal?.settings?.features?.depositSettings?.disablePreselectBank;

        // always get first one channel, because bank transfer currently only has 1 channel
        if (filteredDepositApiJson && filteredDepositApiJson.channel && Object.values(filteredDepositApiJson.channel) && Object.values(filteredDepositApiJson.channel)[0]) {
            let _merchantBankList = Object.values(filteredDepositApiJson.channel)[0];
            for (let i in _merchantBankList) {
                _merchantBankList[i].accountHolder = _merchantBankList[i].accountName;
            }
            _merchantBankList = await controller.mapBankImg(_merchantBankList);

            // massage the merchant bank list
            window.SPL_Transaction.getBankMaintenanceStatusV2(_merchantBankList).then(function (res) {
                _merchantBankList = res;
                controller.vm.setState(
                    {
                        merchantBankList: _merchantBankList,
                        selectedDepositBank: disablePreselectBank ? null : _merchantBankList[0],
                    },
                    () => {
                        commonUtil.getDepositMinMax(
                            controller.vm,
                            'B',
                            controller.vm.props.user.account.currency,
                            controller.vm.state.merchantBankList[controller.vm.state.radioValue]?.id
                        );
                    }
                );
            });
        } else {
            // ori deposit method
            if (depositMaterial.merchantBank.length > 0 && currency === depositMaterial.currentBankCurrency) {
                controller.vm.setState(
                    {
                        merchantBankList: depositMaterial.getMerchantBank(),
                        selectedDepositBank: disablePreselectBank ? null : depositMaterial.getMerchantBank()[controller.vm.state.radioValue],
                    },
                    () => {
                        commonUtil.getDepositMinMax(
                            controller.vm,
                            'B',
                            controller.vm.props.user.account.currency,
                            controller.vm.state.merchantBankList[controller.vm.state.radioValue]?.id
                        );
                    }
                );
            } else {
                let merchantBankList = this.props.merchantBankList;
                // window.SPL_Transaction.loadMerchantBank().then(async (merchantBankList) => {
                if (currency) {
                    // merchantBankList = await this.mapBankImg(merchantBankList);
                    merchantBankList = this.mapBankImg(merchantBankList);
                }

                const disablePreselectBank = !!controller.vm.props.portal?.settings?.features?.depositSettings?.disablePreselectBank;
                controller.vm.setState(
                    { merchantBankList: merchantBankList, selectedDepositBank: disablePreselectBank ? null : merchantBankList[controller.vm.state.radioValue] },
                    () => {
                        depositMaterial.setMerchantBank(merchantBankList);
                        depositMaterial.setCurrentBankCurrency(currency);
                    },
                    () => {
                        commonUtil.getDepositMinMax(
                            controller.vm,
                            'B',
                            controller.vm.props.user.account.currency,
                            controller.vm.state.merchantBankList[controller.vm.state.radioValue]?.id
                        );
                    }
                );
                // });
            }
        }
    },

    async mapBankImg(banks) {
        return banks;
    },

    isBankUnderMaintenanceCheck(start, end) {
        if (start === null) {
            return false;
        }

        let now = new Date();
        let startDate = new Date(start);
        let endDate = new Date(end);

        if (now.getTime() >= startDate.getTime()) {
            if (endDate === null) {
                return true;
            }

            if (now.getTime() < endDate.getTime()) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    },

    loadBankAccounts(callback) {
        window.SPL_Transaction.loadAllOwnedAndUnOwnedBank().then((data) => {
            if (data && data.unOwnedBankList !== undefined) {
                const { t } = controller.vm.props;
                let bankList = data && data.unOwnedBankList;
                for (let i = 0; i < bankList.length; i++) {
                    const bank = data && data.unOwnedBankList && data.unOwnedBankList[i];
                    if (bank.bankName === 'transaction:transaction.withdrawal.otherBanks') {
                        bank.bankName = t('transaction:transaction.withdrawal.otherBanks');
                    }
                }
            } else {
                return null;
            }

            let memberBankList = data.memberBankList ? data.memberBankList : [];

            for (let i = 0; i < memberBankList.length; i++) {
                const memberBank = memberBankList[i];
                //memberBank.displayName = `${memberBank.bankName} ${memberBank.accountNumber} ${memberBank.accountHolder}`;
                memberBank.displayName = `${memberBank.bankName}`;
            }

            controller.vm.setState({ bankList: data.bankList, memberBankList: memberBankList, unOwnedBankList: data.unOwnedBankList }, () => {
                if (callback) {
                    callback();
                }

                controller.loadLastOnlineBankingMemberId();
            });
        });
    },

    handleInputFieldAmountChange(value) {
        if (value.toString().match(/^(\d*)\.{0,1}(\d){0,2}$/)) {
            controller.vm.setState(
                {
                    amount: value,
                },
                () => {
                    controller.validateDepositAmount();
                }
            );
        }
    },

    handleButtonAmountChange(value) {
        const { enableAccValueButton } = controller.vm.props.portal.settings.features.depositPagesSettings;
        const totalAmount = enableAccValueButton ? sumUp(controller.vm.state.amount, value) : value;
        controller.vm.setState(
            {
                amount: totalAmount,
            },
            () => {
                controller.validateDepositAmount();
            }
        );
    },

    clearInputFieldAmount() {
        controller.vm.setState({ amount: '' });
    },

    checkError() {
        const vm = controller.vm;
        const { amountErrMsg, amount, showDepositChannel, selectedDepositChannel, selectedBank, receiptImg, memberBankList, status, message } = vm.state;

        const commonChecking = () => {
            if (
                !amount ||
                amountErrMsg.length > 0 ||
                (showDepositChannel && selectedDepositChannel.length <= 0) ||
                (controller.checkReceiptRequired() && receiptImg.length <= 0)
            ) {
                vm.setState({ disabledDepositButton: true });
            } else {
                vm.setState({ disabledDepositButton: false });
            }
        };

        if (selectedDepositChannel.length > 0) {
            const depositChannel = selectedDepositChannel[0].value;
            if (depositChannel === 'O') {
                if (
                    !amount ||
                    amountErrMsg.length > 0 ||
                    selectedDepositChannel.length <= 0 ||
                    memberBankList.length <= 0 ||
                    selectedBank.length <= 0 ||
                    (status === 'F' && message === 'transaction:transaction.reload.receiptIsRequired' && controller.checkReceiptRequired() && receiptImg.length <= 0)
                ) {
                    vm.setState({ disabledDepositButton: true, certainBankRequiredReceipt: false });
                } else {
                    vm.setState({ disabledDepositButton: false, certainBankRequiredReceipt: false });
                }
            } else {
                commonChecking();
            }
        } else {
            commonChecking();
        }
    },

    handleSubmit(type) {
        const { depositSubmission, t } = controller.vm.props;
        const { filteredDepositApiJson, depositSubmitCountdown } = controller.vm.state;
        const paymentCode = filteredDepositApiJson && filteredDepositApiJson.optionCode;
        const submissionData = depositSubmission && depositSubmission.submissionData;
        const paymentObj = submissionData && submissionData[paymentCode];

        if (paymentObj && paymentObj.submissionRetryTime > Date.now().valueOf()) {
            // controller.vm.setState({ disabledDepositButton: true });
            notification.showNotification('error', t('transaction:transaction.deposit.resubmitError', { retryTime: depositSubmitCountdown }));
        } else {
            controller.localPaySubmit();
        }
    },

    validateDepositAmount() {
        const { depositMinMaxLimit } = controller.vm.state;
        const depositLimitObj = { minLimit: depositMinMaxLimit.min, maxLimit: depositMinMaxLimit.max };
        window.SPL_Transaction.validateDepositAmount(controller.vm.state.amount, depositLimitObj).then((errMsg) => {
            let errMsgLimitAmount = controller.getLimitAmountForErrMsg(errMsg[0]);

            controller.vm.setState({ amountErrMsg: errMsg, limitAmount: errMsgLimitAmount.limitAmount, currency: errMsgLimitAmount.currency }, () => {
                controller.checkError();
            });
        });
    },

    getLimitAmountForErrMsg(errMsg) {
        let limitAmount = '';
        let currency = controller.vm.props.user.account.currency;
        if (errMsg) {
            if (errMsg.includes('invalidMinAmount')) {
                limitAmount = window.SPL_Other.formatAmount(controller.vm.state.depositMinMaxLimit.min);
            } else if (errMsg.includes('invalidMaxAmount')) {
                limitAmount = window.SPL_Other.formatAmount(controller.vm.state.depositMinMaxLimit.max);
            }
        }

        return {
            limitAmount: limitAmount,
            currency: currency,
        };
    },

    localPaySubmit() {
        //const { preferredWalletDetails } = controller.vm.state.depositDetails;
        const vm = controller.vm;
        const {
            amount,
            selectedDepositChannel,
            selectedDepositBank,
            receiptImgFile,
            referenceCode,
            selectedBank,
            amountErrMsg,
            bankAccountRequired,
            showDepositChannel,
            depositOptions,
        } = vm.state;

        if (
            !amount ||
            amountErrMsg.length > 0 ||
            (!selectedDepositBank && bankAccountRequired) ||
            (showDepositChannel && selectedDepositChannel.length <= 0) ||
            controller.checkReceiptRequired()
        ) {
            return;
        }

        let preferredWallet = null; //preferredWalletDetails.preferredWallet; // no more preferred wallet
        let hidePWallet = true; //preferredWalletDetails.hidePWallet; // no more preferred wallet
        let depositAmount = amount;
        let depositChannel = null;

        if (!showDepositChannel) depositChannel = depositOptions[0].value;
        else depositChannel = selectedDepositChannel[0].value;

        let merchantBankId = selectedDepositBank.id;
        let memberBankId = selectedBank.length > 0 ? selectedBank[0].memberBankId : null;
        let picFile = receiptImgFile;

        // preferredWallet, hidePWallet, merchantBankId, depositAmount, depositChannel, depositDateTime, memberBankId, picFile, referenceCode
        window.SPL_Transaction.localPaySubmit(preferredWallet, hidePWallet, merchantBankId, depositAmount, depositChannel, null, memberBankId, picFile, referenceCode).then(
            (data) => {
                if (data.status === 'S') {
                    const stateObj = {
                        disabledDepositButton: false,
                        status: 'S',
                        message: 'transaction:transaction.deposit.request-success',
                        amount: '',
                        receiptImgFile: null,
                        referenceCode: '',
                        selectedBank: [],
                        receiptImg: [],
                        visible: true,
                    };
                    // NOTE: trap, if want on off certain view, create a boolean instead of having dependency on certain data.
                    if (showDepositChannel) Object.assign(stateObj, { selectedDepositChannel: [] });

                    vm.setState(stateObj, () => {
                        if (vm.state.status === 'S') {
                            setTimeout(() => {
                                vm.setState({ message: '', visible: false });
                            }, 5000);
                        }
                    });
                } else {
                    vm.setState({ disabledDepositButton: false });

                    if (data.code === 'receipt_fail') {
                        vm.setState({
                            status: 'F',
                            message: 'transaction:transaction.withdrawal.request-uploadReceiptError',
                            visible: true,
                        });
                    } else if (data.msg.data.detail === 'Merchant Bank inactive or not belong to member bank group.') {
                        vm.setState({
                            status: 'F',
                            message: 'transaction:transaction.withdrawal.request-bankInactive',
                            visible: true,
                        });
                    } else if (data.msg.data.status === 409) {
                        vm.setState({
                            status: 'F',
                            message: 'transaction:transaction.withdrawal.request-pending',
                            visible: true,
                        });
                    } else if (data.msg.data.status === 500) {
                        vm.setState({
                            status: 'F',
                            message: 'transaction:transaction.withdrawal.request-pending',
                        });
                    } else if (data.msg.status === 400) {
                        vm.setState({
                            status: 'F',
                            message: 'transaction:transaction.withdrawal.request-pending',
                        });
                    } else {
                        vm.setState({
                            status: 'F',
                            message: 'transaction:transaction.withdrawal.request-error',
                            visible: true,
                        });
                    }
                }
            }
        );
    },

    copy(text, t) {
        let successful = window.SPL_Other.copyToClipboard(text);
        if (successful && t) {
            notification.showNotification('info', t('transaction:transaction.withdrawal.copied', 'Copied'), { autoClose: 2000 });
        }
    },

    closeBankModal() {
        controller.vm.setState({ isOpenBankModal: false });
    },

    loadAdditionalDepositSettings() {
        const vm = controller.vm;
        const { user, portal } = vm.props;
        const { account } = user;

        const currency = account ? account.currency : '';
        window.SPL_Transaction.loadAdditionalDepositSettings(currency).then((data) => {
            let showRefId = true;

            const preprocessData = (dataToCheck) => {
                if (!dataToCheck) return null;

                let data;
                if (typeof dataToCheck === 'boolean') data = dataToCheck;
                else data = dataToCheck.indexOf(currency) !== -1;
                return data;
            };

            if (data) {
                if (data.showRefId && data.showRefId.hasOwnProperty(currency)) {
                    showRefId = data.showRefId[currency];
                }
            }

            data.hideAddBankAccount = trans_massageHideAddBank(vm, 'deposit', 'B');

            vm.setState(
                {
                    receiptMandatoryBankCodes: data.receiptMandatoryBankCodes,
                    requiredReceiptForOnlineBanking: data.requiredReceiptForOnlineBanking,
                    hideATM: data.hideATM,
                    showOnlineBankingOnly: preprocessData(data.showOnlineBankingOnly),
                    enable7Eleven: data.enable7Eleven,
                    enableOTC: data.enableOTC,
                    hideCashDeposit: data.hideCashDeposit,
                    showDepositChannel: preprocessData(data.showDepositChannel),
                    hidePWallet: data.hidePWallet,
                    memberBankAsMandatory: preprocessData(data.memberBankAsMandatory),
                    hideAddBankAccount: data.hideAddBankAccount,
                    showRefId: showRefId,
                    uploadReceipt: data.uploadReceipt,
                    isUploadOnlineBankReceiptOptional: preprocessData(portal.settings.isUploadOnlineBankReceiptOptional), // with this setting exist, uploadReceipt must be false, otherwise backend api would block.
                },
                () => {
                    controller.setDepositOptions();
                }
            );
        });
    },

    toggleLoading(toggle) {
        controller.vm.setState({ showLoading: toggle });
    },

    loadBankAccountsForDeposit() {
        window.SPL_Transaction.loadAllOwnedAndUnOwnedBank()
            .then((data) => {
                controller.vm.setState({ unOwnedBankList: data.unOwnedBankList });
                controller.toggleLoading(false);
            })
            .catch((err) => {
                controller.toggleLoading(false);
            });
    },

    getTncReferral() {
        controller.vm.setState({ getTncReferral: !controller.vm.state.getTncReferral });
    },

    checkCertainBankRequiredReceipt() {
        const vm = controller.vm;
        const { selectedDepositBank, selectedBank, isUploadOnlineBankReceiptOptional } = vm.state;
        const { portal, user } = vm.props;
        const currency = user.account.currency;

        if (isUploadOnlineBankReceiptOptional) return;

        let requiredReceiptForCertainOnlineBanking = portal.settings.requiredReceiptForCertainOnlineBanking;
        let bankSelected = {};
        let memberBankSelected = [];

        if (selectedDepositBank) {
            bankSelected = selectedDepositBank.bankCode || selectedDepositBank.code;
        }

        if (selectedBank && selectedBank.length > 0) {
            memberBankSelected = selectedBank[0].bankCode.toUpperCase();
        }

        if (requiredReceiptForCertainOnlineBanking && bankSelected && memberBankSelected && memberBankSelected.length > 0) {
            window.SPL_Transaction.checkCertainBankRequiredReceipt(
                currency,
                requiredReceiptForCertainOnlineBanking,
                bankSelected,
                memberBankSelected,
                controller.checkError
            ).then((res) => {
                let _status = '';
                let _message = '';
                if (res) {
                    _status = 'F';
                    _message = 'transaction:transaction.reload.receiptIsRequired';
                }
                vm.setState({ certainBankRequiredReceipt: true, status: _status, message: _message });

                controller.checkError();
            });
        } else {
            controller.checkError();
        }
    },

    onImportantNoticeIconHover(flag) {
        controller.vm.setState({ isShowNoticeBox: flag });
    },

    onImportantNoticeIconClick() {
        controller.vm.setState({ isShowNoticeBox: !controller.vm.state.isShowNoticeBox });
    },
};

export default controller;
