import React, { Component } from 'react';
import { Translation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
// import classnames from 'classnames';
import { mapStateToProps } from '../../../redux/selector/selector';
import { withRouter } from 'react-router-dom';
import { BsCheck } from 'react-icons/bs';

// components
import ImageHelper from 'root/pages/Share/image-helper';
import EditDOBPopup from '../shared/dialog/edit-dob-popup/component/edit-dob-popup';
import AuthSettings, { useAuthSettingsSetup } from '@components/molecules/auth-settings/auth-settings';

// core / logic
import { gu_togglePopup } from 'root/utils/general-util';
import commonUtil from 'root/utils/common-util';
import { mu_getContactVerification, mu_getMemberVerifyOptions } from 'root/utils/member-util';
import { userAction } from 'root/redux/action';
import { MODULE_TYPE, POPUP_TYPE, OTP_CONTACT_TYPE as OCT } from '@utils/constants';
import { authSettingsAction } from '@redux/action';
import { popupAction } from '@redux/action/popup.action';

class Profile extends Component {
    isMount = true;

    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.state = {
            activeTab: 'privateMessage',
            fields: {
                email: '',
                phone: '',
                login: '',
                name: '',
                dob: '',
                currency: '',
            },
            memberBankList: [],
            detailsObj: [],
            currentpassword: '',
            newpassword: '',
            confirmpassword: '',
            samePassword: false,
            hiddenCurrent: false,
            hiddenNewPassword: true,
            hiddenConfirmPassword: true,
            messageList: [],
            isLoading: false,
            languageList: [
                {
                    id: 1,
                    language: 'English',
                },
                {
                    id: 2,
                    language: 'Chinese',
                },
                {
                    id: 3,
                    language: 'Thailand',
                },
            ],
            memberVerifyOptions: [],
            tempFieldDob: null,
            isSubmitting: false,

            compSettings: {},
        };
    }
    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab,
            });
        }
    }

    identity = () => {
        const { account } = this.props.user;
        const { registerAge } = this.state.compSettings;
        let tempFieldDob = null;
        let day = null,
            month = null,
            year = null;

        if (this.isMount && account) {
            let duplicateAccount = window.SPL_Other.deepCopy(account);
            for (var key in duplicateAccount) {
                if (Object.keys(duplicateAccount[key]).length === 0 && duplicateAccount[key].constructor === Object) {
                    duplicateAccount[key] = '';
                }
            }
            if (duplicateAccount && duplicateAccount.phone) {
                duplicateAccount.phone = window.SPL_Other.censored(duplicateAccount.phone, 'phone');
            }
            if (duplicateAccount && duplicateAccount.email) {
                duplicateAccount.email = window.SPL_Other.censored(duplicateAccount.email, 'email');
            }

            const splittedDOB = duplicateAccount.dob.split('-');
            if (duplicateAccount.dob && splittedDOB && splittedDOB.length > 0) {
                tempFieldDob = splittedDOB[0] + '-' + splittedDOB[1] + '-' + splittedDOB[2]; // used in submitEditDOB only
                day = splittedDOB[2];
                month = splittedDOB[1];
                year = splittedDOB[0];
            } else {
                let currentDate = new Date();
                day = currentDate.getDate();
                month = currentDate.getMonth() + 1;
                year = currentDate.getFullYear() - registerAge;
                if (day < 10) {
                    day = '0' + day.toString();
                }
                if (month < 10) {
                    month = '0' + month.toString();
                }
                tempFieldDob = year + '-' + month + '-' + day;
            }

            this.setState({ fields: duplicateAccount, year, month, day, tempFieldDob });
        }
    };

    componentWillUnmount() {
        this.isMount = false;
    }
    componentDidMount() {
        this.initCompSettings().then(() => {
            this.identity();
        });
        if (this.props.newpassword) {
            this.setState({ newpassword: this.props.newpassword });
        }
        this.getMessages();
        this.loadBankAccounts();
        if (this.props.screen.viewType === 'web' && this.props.location.pathname === '/profile') {
            this.props.history.push('/myaccount/myprofile');
        }
    }
    componentDidUpdate(prevProps) {
        if (prevProps?.user?.account !== this.props?.user?.account) {
            this.identity();
        }
    }

    loadBankAccounts = () => {
        window.SPL_Transaction.loadAllOwnedAndUnOwnedBank()
            .then((data) => {
                if (this.isMount) {
                    this.setState({ memberBankList: data.memberBankList });
                }
            })
            .catch((err) => {});
    };

    handlePasswordCurrentChange = (e) => {
        this.setState({ password: e.target.value });
    };

    toggleShowCurrent = () => {
        this.setState({ hiddenCurrent: !this.state.hiddenCurrent });
    };

    handlePasswordNewChange = (e) => {
        this.setState({ password: e.target.value });
    };
    handleConfirmPasswordChange = (e) => {
        this.setState({ password: e.target.value });
    };

    toggleShowNewPassword = () => {
        this.setState({ hiddenNewPassword: !this.state.hiddenNewPassword });
    };

    handleInputChangeCurrent = (e) => {
        const currentpassword = e.target.value;

        this.setState({
            currentpassword,
        });
    };

    handleInputChangeNewPassword = (e) => {
        const newpassword = e.target.value;

        this.setState({
            newpassword,
        });
    };

    handleInputChangeConfirmPassword = (e) => {
        const confirmpassword = e.target.value;

        this.setState({
            confirmpassword,
        });
    };

    onFlagChange = (index) => {
        let array = this.state.messageList;
        let msg = array[index];
        msg.isFlagChecked = !msg.isFlagChecked;
        let messageObj = {
            id: msg.id,
            isFlag: msg.isFlagChecked,
            status: msg.status,
        };
        array[index] = msg;
        window.SPL_Member.readAndFlagMessage(messageObj, () => {
            this.setState({ messageList: array });
        });
    };

    onClickMessage = (index, page) => {
        let subIndex = index;
        let i = 1;
        if (page > i) {
            for (let j = 0; j < i; j++) {
                subIndex = subIndex + 10;
            }
        }

        let array = this.state.messageList;
        let msg = array[subIndex];
        let messageObj = {
            id: msg.id,
            isFlag: msg.isFlagChecked,
            status: 'R',
        };
        array[subIndex] = msg;
        this.setState({ selectedMessage: msg, openMessageModal: true });
        window.SPL_Member.readAndFlagMessage(messageObj, () => {
            if (this.timeOut) {
                clearTimeout(this.timeOut);
            }
            this.timeOut = setTimeout(() => {
                if (this.isMount) {
                    this.getMessages();
                }
            }, 1000);
        });
    };

    getMessages() {
        window.SPL_Member.getMessages().then((msgObj) => {
            if (this.isMount) {
                let messageArr = msgObj.messages;
                for (let i = 0; i < messageArr.length; i++) {
                    messageArr[i].isChecked = false;
                    if (messageArr[i].status.includes('F')) {
                        messageArr[i].isFlagChecked = true;
                    } else {
                        messageArr[i].isFlagChecked = false;
                    }
                }
            }
        });
    }

    closeModal = () => {
        this.setState({ selectedMessage: null, openMessageModal: false });
    };

    isChecked = (index) => {
        return this.state.checkAll || this.state.messageList[index].isChecked;
    };

    isFlagChecked = (index) => {
        return this.state.messageList[index].isFlagChecked;
    };

    onFlagChange = (index) => {
        let array = this.state.messageList;
        let msg = array[index];
        msg.isFlagChecked = !msg.isFlagChecked;
        let messageObj = {
            id: msg.id,
            isFlag: msg.isFlagChecked,
            status: msg.status,
        };
        array[index] = msg;
        window.SPL_Member.readAndFlagMessage(messageObj, () => {
            this.setState({ messageList: array });
        });
    };

    deleteMessageByBatch = () => {
        this.setState(
            { isLoading: true },
            () => {
                let listOfCheckedId = [];
                let messageList = this.state.messageList;
                for (let i = 0; i < messageList.length; i++) {
                    const msg = messageList[i];
                    if (msg.isChecked) {
                        listOfCheckedId.push(msg.id);
                    }
                }
                this.setState({ listOfCheckedId: listOfCheckedId }, () => {
                    window.SPL_Member.deleteMessage(listOfCheckedId).then((data) => {
                        this.timeOut = setTimeout(() => {
                            if (this.isMount) {
                                this.getMessages();
                            }
                        }, 1000);
                        this.setState({ listOfCheckedId: [] });
                    });
                });
            },
            setTimeout(() => {
                this.setState({ isLoading: false });
            }, 1800)
        );
    };

    initCompSettings() {
        return new Promise((resolve) => {
            // Step 1: Standard way to get from portal settings
            let registerConfig = commonUtil.getSettingFromPortalSettings({
                vm: this,
                settingName: 'registerConfiguration',
                notViewType: true,
                notFeatures: true,
            });

            let settingsConfiguration = commonUtil.getSettingFromPortalSettings({
                vm: this,
                settingName: 'settingsConfiguration',
                isCurrencyLang: true,
            });

            // Step 2: Massage again to get the specific data you need (Skip if not needed)
            let _memberVerifyOptions = mu_getMemberVerifyOptions(this, settingsConfiguration);

            // Step 3: Initial all into compSettings
            let compSettings = {
                registerConfig: registerConfig,
                registerAge: registerConfig.registerAge ? registerConfig.registerAge : 0,
            };

            this.setState({ compSettings, memberVerifyOptions: _memberVerifyOptions }, () => {
                resolve();
            });
        });
    }

    checkContactsVerification() {
        mu_getContactVerification(this);
    }

    togglePopup(component) {
        gu_togglePopup(this, component);
    }

    submitEditDOB() {
        let { fields, tempFieldDob } = this.state;
        const { user } = this.props;
        // passed props func
        const { checkVerification } = this.props;

        this.setState({ isSubmitting: true });
        let param = {
            id: user.account.id,
            dob: tempFieldDob,
        };

        window.SPL_Member.updateMemberDetails(param).then((res) => {
            if (res.status === 'S') {
                fields.dob = tempFieldDob;
                let _account = user.account;
                _account.dob = fields.dob;
                this.props.dispatch(userAction.updateCertainUser(_account));
                this.setState({ fields, isSubmitting: false });
                checkVerification && checkVerification();
            } else {
                this.setState({ isSubmitting: false });
            }
        });
    }

    onDateChanged(type, field, value) {
        const vm = this;
        let fields = vm.state.fields;

        const dob = fields[field];
        const splitDobData = dob.split('-');

        const tempFieldDob = vm.state.tempFieldDob;
        const splitTempDobData = tempFieldDob && tempFieldDob.split('-');

        // splitTempDobData is the temporarily variable during popup
        let year = splitTempDobData && splitTempDobData[0] ? splitTempDobData[0] : splitDobData[0];
        let month = splitTempDobData && splitTempDobData[1] ? splitTempDobData[1] : splitDobData[1];
        let day = splitTempDobData && splitTempDobData[2] ? splitTempDobData[2] : splitDobData[2];

        if (!value || value === -1) {
            return;
        }

        value = parseInt(value);
        let stateToUpdate = {};
        if (type === 'month') {
            month = value;
            if (month < 10) {
                month = '0' + month;
            }
            stateToUpdate = {
                month: month,
            };
        } else if (type === 'year') {
            year = value;
            stateToUpdate = {
                year: year,
            };
        } else if (type === 'day') {
            day = value;
            if (day < 10) {
                day = '0' + day;
            }
            stateToUpdate = {
                day: day,
            };
        }
        this.setState({ ...stateToUpdate }, () => {
            const { year, month, day } = this.state;
            this.setState({ tempFieldDob: year + '-' + month + '-' + day });
        });
        // this.setState({
        //     // month: Number(month),
        //     // year: Number(year),
        //     // day: Number(day),
        //     tempFieldDob: year + '-' + month + '-' + day, // NOTE: don't want set the main field 1st it will update non-pop up place
        // });
    }

    onEditEmailClick = () => {
        const _config = {
            selectedAuthContact: OCT.EMAIL,
        };
        this.props.dispatch(popupAction.togglePopUp(POPUP_TYPE.EDIT_VERIFY_AUTH, true));
        this.props.dispatch(popupAction.setItemConfig(POPUP_TYPE.EDIT_VERIFY_AUTH, _config));
    };

    onEditPhoneClick = () => {
        const _config = {
            selectedAuthContact: OCT.PHONE,
        };
        this.props.dispatch(popupAction.togglePopUp(POPUP_TYPE.EDIT_VERIFY_AUTH, true));
        this.props.dispatch(popupAction.setItemConfig(POPUP_TYPE.EDIT_VERIFY_AUTH, _config));
    };

    render() {
        const { email, phone, login, name, dob, currency } = this.state.fields || { email: '', phone: '', login: '', name: '', dob: '', currency: '' };
        const { popupCollection, compSettings, day, month, year, isSubmitting } = this.state;
        const { registerAge } = compSettings;

        const { authSettingsReducer } = this.props;
        const _msUpdateMemberInfoModule = authSettingsReducer?.processedData?.[MODULE_TYPE.UPDATE_MEMBER_INFO];
        const { verifications } = this.props.user;

        let editDOBPopupProps = {
            isOpen: popupCollection && popupCollection.editDob,
            registerAge: registerAge,
            fieldName: 'dob',
            day,
            month,
            year,
            isSubmitting: isSubmitting,

            onDateChanged: this.onDateChanged.bind(this),
            submitEditDOB: this.submitEditDOB.bind(this),
            togglePopup: this.togglePopup.bind(this),
        };

        return (
            <Translation>
                {(t) => (
                    <div className='profile'>
                        <AuthSettings module={MODULE_TYPE?.UPDATE_MEMBER_INFO} isPublic={false} />
                        {popupCollection && popupCollection.editDob && <EditDOBPopup {...editDOBPopupProps} />}

                        <h1>{t('settings:settings.profiletab.myprofile', 'My Profile')}</h1>
                        <ul className='profile-top'>
                            <li className='profile-item'>
                                <div className='form-item user-detail1'>
                                    <div className='top'>
                                        <div className='top-title'>
                                            <span>{t('global:global.form.email', 'Email')}</span>
                                        </div>
                                        <div className='bottom'>
                                            {email}
                                            {_msUpdateMemberInfoModule?.gaEnabled && <EditProfileDetailIcon onClick={this.onEditEmailClick} />}
                                        </div>
                                    </div>

                                    <div className='top'>
                                        <div className='top-title'>
                                            <span>{t('global:global.form.username-placeholder', 'Username')}</span>
                                        </div>
                                        <div className='bottom'>{login.toLowerCase()}</div>
                                    </div>

                                    <div className='top'>
                                        <div className='top-title'>
                                            <span>{t('member:PortalApp.member.dob', 'Date of Birth')}</span>
                                        </div>
                                        <div className='bottom'>
                                            {Object.keys(dob).length > 0 && dob ? dob : '-'}
                                            {verifications && !verifications.birthdayVerified && (
                                                <div className='icon-wrapper' onClick={() => gu_togglePopup(this, 'editDob')}>
                                                    <ImageHelper displaySVG={'st-icon-edit'} className={`st-icon-edit`} svgType='SVGElements' isSVG={true} />
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li className='profile-item'>
                                <div className='form-item user-detail1'>
                                    <div className='top'>
                                        <div className='top-title'>
                                            <span>{t('global:global.form.contact', 'Contact')}</span>
                                        </div>
                                        <div className='bottom'>
                                            {phone} {_msUpdateMemberInfoModule?.gaEnabled && <EditProfileDetailIcon onClick={this.onEditPhoneClick} />}
                                        </div>
                                    </div>

                                    <div className='top'>
                                        <div className='top-title'>
                                            <span>{t('global:global.form.fullname', 'Full Name')}</span>
                                        </div>
                                        <div className='bottom'>{name}</div>
                                    </div>

                                    <div className='top'>
                                        <div className='top-title'>
                                            <span>{t('member:PortalApp.member.currency', 'Currency')}</span>
                                        </div>
                                        <div className='bottom'>{currency}</div>
                                    </div>
                                </div>
                            </li>
                            <li className='profile-item'>
                                <div className='form-item bank-detail'>
                                    <div className='top'>
                                        <div className='top-title'>
                                            <span>{t('settings:settings.tabs.heading.banking', 'Bank Details')}</span>
                                        </div>
                                    </div>
                                    <div className='bank-list'>
                                        {this.state.memberBankList.length > 0 && (
                                            <ul>
                                                {this.state.memberBankList.map((bank, index) => {
                                                    return (
                                                        <li key={bank.bankId}>
                                                            <img
                                                                alt='/public/html/images/bank_img/otherBank-icon.png'
                                                                src={bank.iconPath ? bank.iconPath : '/public/html/images/bank_img/otherBank-icon.png'}
                                                                className='bank-logo'
                                                            />
                                                            <div className='bank-acc'>
                                                                <div className='bank-accno'>
                                                                    <span>{bank.accountNumber}</span>
                                                                    <div className='bank-verified'>
                                                                        <BsCheck />
                                                                    </div>
                                                                </div>
                                                                <div className='bank-holder'>{bank.accountHolder}</div>
                                                            </div>
                                                        </li>
                                                    );
                                                })}
                                            </ul>
                                        )}
                                    </div>
                                </div>
                            </li>
                        </ul>

                        <ul className='profile-updateform'>
                            <li>
                                <div className='form-profile'></div>
                            </li>
                        </ul>
                    </div>
                )}
            </Translation>
        );
    }
}

export default connect(mapStateToProps, null)(withTranslation(['global'])(withRouter(Profile)));

export const EditProfileDetailIcon = ({ onClick }) => {
    const { handleSetup } = useAuthSettingsSetup({ module: MODULE_TYPE.UPDATE_MEMBER_INFO, callback: onClick });

    const onEditIconClick = () => {
        handleSetup();
    };

    return (
        <>
            <div className='icon-wrapper' onClick={onEditIconClick}>
                <ImageHelper displaySVG={'st-icon-edit'} className={`st-icon-edit`} svgType='SVGElements' isSVG={true} />
            </div>
        </>
    );
};
