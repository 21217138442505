// react / library
import React, { Component } from 'react';
import { Translation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { mapStateToProps } from 'root/redux/selector/selector';
import { withRouter } from 'react-router-dom';
import { MdClose } from 'react-icons/md';

// components / selector
import PopupWrapper from '../../../../Share/popup/popup-wrapper/popup-wrapper';
import SelectDropdown from 'root/pages/Share/select-dropdown/select-dropdown';
import Option from 'root/pages/Share/select-dropdown/sub-component/option';
import { userVerificationData } from 'root/pages/16MyAccount/161_MyAccount_Items_Data';

class UserVerificationDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.setState = () => {
            return;
        };
        if (!window.isAgent && !window.isAffiliate) {
            if (window.SPL_LiteApp.isLiteApp() || window.SPL_LiteApp.isZT828LiteApp()) {
                window.SPL_LiteApp.onRegisterPageDisableRefresh(true);
            }
        }
    }

    componentWillUnmount() {
        if (!window.isAgent && !window.isAffiliate) {
            if (window.SPL_LiteApp.isLiteApp() || window.SPL_LiteApp.isZT828LiteApp()) {
                window.SPL_LiteApp.onRegisterPageDisableRefresh(false);
            }
        }
    }

    render() {
        // redux
        const { screen } = this.props;
        // passed props
        const { isOpen, filteredVerifyOptions, selectedVerifyType } = this.props;
        // passed props function
        const { closeFunc, onVerifyOptionChange, handleVerify } = this.props;

        let selectedType = selectedVerifyType ? userVerificationData[selectedVerifyType] : filteredVerifyOptions && filteredVerifyOptions[0];
        return (
            <Translation>
                {(t) => (
                    <PopupWrapper
                        isOpen={isOpen}
                        overlayClassName={`${screen.isMobile ? 'm-' : ''}user-verify-popup-overlay`}
                        className={`${screen.isMobile ? 'm-' : ''}user-verify-popup-container`}
                        closeFunc={closeFunc && closeFunc}
                    >
                        <div className='standard-popup-modal-header user-verify-popup-header'>
                            <div className='standard-modal-title'>{t('settings:settings.userVerification', 'User Verification')}</div>
                            <div className='standard-modal-close' onClick={closeFunc && closeFunc}>
                                <MdClose />
                            </div>
                        </div>
                        <div className='standard-popup-modal-body user-verify-popup-body'>
                            <div className='popup-body-content'>
                                <div className='standard-form-field verify-dropdown-field'>
                                    <SelectDropdown
                                        name={`verify-dropdow`}
                                        selectedLabel={
                                            selectedVerifyType
                                                ? userVerificationData[selectedVerifyType] && userVerificationData[selectedVerifyType].i18n
                                                : filteredVerifyOptions[0] && filteredVerifyOptions[0].i18n
                                        }
                                    >
                                        {(filteredVerifyOptions || []).map((a, i) => {
                                            const { key, i18n } = a;
                                            return (
                                                <Option
                                                    key={i}
                                                    className={`${key === selectedType.key ? 'active' : ''} `}
                                                    optionLabel={t(i18n)}
                                                    onClickFunction={() => {
                                                        onVerifyOptionChange && onVerifyOptionChange(a);
                                                    }}
                                                />
                                            );
                                        })}
                                    </SelectDropdown>
                                </div>
                                <div className='verify-button-container'>
                                    <button className='standard-button standard-modal-submit-button verify-button' onClick={() => handleVerify && handleVerify()}>
                                        <span>{t('settings:settings.memberVerificationForm.verifyNow', 'Verify Now')}</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </PopupWrapper>
                )}
            </Translation>
        );
    }
}
export default connect(mapStateToProps)(withTranslation(['settings'])(withRouter(UserVerificationDialog)));
