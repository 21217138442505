import React, { Suspense } from 'react';
import SmallLoading from 'root/components/Loading/SmallLoading';

const MiniLazyComponent = (Component, props) => {
    return () => (
        <Suspense fallback={<SmallLoading></SmallLoading>}>
            <Component {...props}></Component>
        </Suspense>
    );
};

export default MiniLazyComponent;
