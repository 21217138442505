import React from 'react';
import { DebounceInput } from 'react-debounce-input';
import { Col, Row, Modal, ModalBody, ModalHeader } from 'reactstrap';
import Select from 'react-dropdown-select';
import { userAction } from '../../../redux/action';
import { mapStateToProps } from '../../../redux/selector/selector';
import { Trans, Translation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import FlagsSelect from '../052_Flag_Select';

class Step2 extends React.Component {
    isMount = true;
    constructor(props) {
        super(props);
        this.state = {
            username: '',
            password: '',
            confirmpassword: '',
            fullname: '',
            email: '',
            affiliateId: '',
            phone: '',
            usernameError: [],
            passwordError: [],
            confirmpasswordError: [],
            fullnameError: [],
            emailError: [],
            affiliateError: [],
            phoneError: [],
            telCode: '',
            telCodeList: [],
            ageTerms: false,
            disabledButton: false,
            disabledAffiliate: false,
            hideAff: false,
            currencies: [],
            currency: [],
            modal: false,
            termContent: '',
        };
        this.toggle = this.toggle.bind(this);
    }

    componentDidMount() {
        this.getAffiliateId();
        this.initRegisterSettings();
        this.isMount = true;
        this.getTerms();
    }

    toggle() {
        this.setState({ modal: !this.state.modal });
    }

    initRegisterSettings = () => {
        const { portal } = this.props;
        if (portal && portal.settings) {
            if (portal.settings.hasOwnProperty('registerHideAff')) {
                let hideAff = portal.settings.registerHideAff;
                this.setState({ hideAff });
            }

            if (portal.settings.hasOwnProperty('currencies')) {
                let currencies = portal.settings.currencies.split(',').map((c) => ({ value: c, label: c }));
                this.setState({ currencies }, () => {
                    this.getCountryInfo();
                });
            }
        }
    };

    getCountryInfo() {
        window.SPL_Content.getCountryInfo().then((telCodeList) => {
            if (this.isMount) {
                const { language } = this.props;
                let selectedObj = this.getSelectedObj(telCodeList, language.country);
                this.setState({
                    telCodeList: telCodeList,
                    telCode: selectedObj.telCode,
                    countryCode: selectedObj.countryCode,
                    currency: selectedObj.currency,
                });
            }
        });
    }

    getAffiliateId = () => {
        let queryParams = window.SPL_Other.getQueryStringParams(this.props.location.search);
        if (queryParams.hasOwnProperty('affid')) {
            this.setState({ affiliateId: queryParams.affid, disabledAffiliate: true });
        }
    };

    getSelectedObj(telCodeList, country) {
        let returnObj = {};
        for (let i = 0; i < telCodeList.length; i++) {
            const telCodeObj = telCodeList[i];
            if (telCodeObj.country === country) {
                let currency = this.state.currencies.filter((c) => c.value === telCodeObj.currency);
                returnObj = {
                    telCode: `+${telCodeObj.telCode}`,
                    countryCode: telCodeObj.country,
                    currency: currency,
                };
            }
        }
        return returnObj;
    }

    componentWillUnmount() {
        this.isMount = false;
    }

    handleUserNameChange = (event) => {
        this.setState({ username: event.target.value }, () => {
            this.validateUserName();
        });
    };

    handlePasswordChange = (event) => {
        this.setState({ password: event.target.value }, () => {
            this.validatePassword();
        });
    };

    handleconfirmPasswordChange = (event) => {
        this.setState({ confirmpassword: event.target.value }, () => {
            this.validateConfirmPassword();
        });
    };

    handleFullnameChange = (event) => {
        this.setState({ fullname: event.target.value }, () => {
            this.validateFullname();
        });
    };

    handleCurrencyChange = (event) => {
        let currency = this.state.currencies.filter((c) => c.value === event[0].value);
        this.setState({ currency: currency });
    };

    handleEmailChange = (event) => {
        this.setState({ email: event.target.value }, () => {
            this.validateEmail();
        });
    };

    handlePhoneChange = (event) => {
        let phoneNumber = event.target.value;

        this.setState({ phone: phoneNumber }, () => {
            this.validatePhone();
        });
    };

    handleAffiliateChange = (event) => {
        this.setState({ affiliateId: event.target.value }, () => {});
    };

    isNumber = (event) => {
        return /^\d*$/.test(event.target.value);
    };

    isError = (errMsg) => {
        return errMsg.length > 0;
    };

    validateUserName = () => {
        const { username } = this.state;
        let thisObj = this;
        return new Promise(function (resolve, reject) {
            window.SPL_Register.validateUsername(username, window.merchantCode).then((errMsg) => {
                thisObj.setState({ usernameError: errMsg });
                resolve(thisObj.isError(errMsg));
            });
        });
    };

    validatePassword = () => {
        const { password, confirmpassword } = this.state;
        let thisObj = this;
        return new Promise(function (resolve, reject) {
            window.SPL_Register.validatePassword(password, confirmpassword).then((errMsg) => {
                thisObj.setState({ passwordError: errMsg });
                resolve(thisObj.isError(errMsg));
            });
        });
    };

    validateConfirmPassword = () => {
        const { password, confirmpassword } = this.state;
        let thisObj = this;
        return new Promise(function (resolve, reject) {
            window.SPL_Register.validateConfirmPassword(password, confirmpassword).then((errMsg) => {
                thisObj.setState({ confirmpasswordError: errMsg });
                resolve(thisObj.isError(errMsg));
            });
        });
    };

    validateFullname = () => {
        const { fullname } = this.state;
        let thisObj = this;
        return new Promise(function (resolve, reject) {
            window.SPL_Register.validateFullName(fullname, window.merchantCode).then((errMsg) => {
                thisObj.setState({ fullnameError: errMsg });
                resolve(thisObj.isError(errMsg));
            });
        });
    };

    validateEmail = () => {
        const { email } = this.state;
        let thisObj = this;
        return new Promise(function (resolve, reject) {
            window.SPL_Register.validateEmail(email, window.merchantCode).then((errMsg) => {
                thisObj.setState({ emailError: errMsg });
                resolve(thisObj.isError(errMsg));
            });
        });
    };

    validateAffiliate = () => {
        const { affiliateId } = this.state;
        let thisObj = this;
        return new Promise(function (resolve, reject) {
            window.SPL_Register.validateAffiliate(affiliateId, window.merchantCode).then((errMsg) => {
                thisObj.setState({ affiliateError: errMsg });
                resolve(thisObj.isError(errMsg));
            });
        });
    };

    validatePhone = () => {
        const { phone, telCode } = this.state;
        let thisObj = this;
        return new Promise(function (resolve, reject) {
            let amendPhone = phone.toString();
            if (amendPhone.startsWith('0')) {
                amendPhone = amendPhone.substring(1, amendPhone.length);
            }
            let tempTelCode = telCode.toString();
            if (tempTelCode.startsWith('+')) {
                tempTelCode = tempTelCode.substring(1, tempTelCode.length);
            }
            window.SPL_Register.validatePhone(phone, window.merchantCode, tempTelCode).then((errMsg) => {
                thisObj.setState({ phoneError: errMsg });
                resolve(thisObj.isError(errMsg));
            });
        });
    };

    selectCode = (countryCode) => {
        let selectedObj = this.getSelectedObj(this.state.telCodeList, countryCode);

        this.setState({ telCode: selectedObj.telCode, countryCode: selectedObj.countryCode, currency: selectedObj.currency });
    };

    ageTermCheckBoxOnChange = (e) => {
        this.setState({ ageTerms: e.target.checked });
    };

    handleSubmit = (event) => {
        event.preventDefault();
        const { username, password, fullname, email, phone, affiliateId, currency } = this.state;
        const { language } = this.props;
        this.setState({ disabledButton: true }, () => {
            Promise.all([
                this.validateUserName(),
                this.validatePassword(),
                this.validateConfirmPassword(),
                this.validateEmail(),
                this.validatePhone(),
                this.validateFullname(),
                this.validateAffiliate(),
            ]).then((data) => {
                // true mean having error
                if (data.includes(true)) {
                    this.setState({ disabledButton: false });
                    return;
                } else {
                    let languageKey = language.key;
                    let registerObj = {
                        login: username,
                        password: password,
                        email: email,
                        name: fullname,
                        langKey: languageKey,
                        language: languageKey.toUpperCase(),
                        phone: phone,
                        merchantCode: window.merchantCode,
                        currency: currency[0].value,
                        affiliateName: affiliateId === '' ? null : affiliateId,
                    };
                    window.SPL_Register.registerAccount(registerObj).then((data) => {
                        if (data.status === 'S') {
                            this.setState({ disabledButton: false });

                            this.loginAndNavigate();
                            // this.props.history.push('/home');
                        }
                    });
                }
            });
        });
    };

    loginAndNavigate = () => {
        let loginObj = {
            domain: '',
            isAffiliate: false,
            isVipLogin: false,
            language: this.props.language.key,
            merchantCode: window.merchantCode,
            login: this.state.username,
            password: this.state.password,
            rememberMe: false,
        };

        this.props.dispatch(userAction.login(loginObj)).then(() => {
            this.props.dispatch(userAction.isAuthenticated());
            this.props.history.push('/Step3');
        });
    };

    customNoDataRenderer = ({ props, state, methods }) => (
        <Translation>{(t) => <div className='select-noData'> {t('reports:reports.noDataAvailable', 'No Data Available')}</div>}</Translation>
    );

    getTerms() {
        const { language } = this.props;
        window.SPL_Content.getTermPage(language.countryLanguageKey).then((res) => {
            if (this.isMount) {
                this.setState({
                    termContent: res.content,
                });
            }
        });
    }

    render() {
        if (this.props.currentStep !== 2) {
            return null;
        }
        return (
            <Translation>
                {(t) => (
                    <div>
                        <Row>
                            <Modal className='tncModal modal-lg' isOpen={this.state.modal} toggle={this.toggle}>
                                <ModalHeader className='w-100 d-flex' toggle={this.toggle}>
                                    <Col xs='12' sm='12' className='d-flex justify-content-start align-items-center'>
                                        <div className='text-nowrap'>{t('terms:terms.termsandconditions')}</div>
                                    </Col>
                                </ModalHeader>
                                <ModalBody className='text-left w-100'>
                                    <div className='p-10' dangerouslySetInnerHTML={{ __html: this.state.termContent }}>
                                        {/*t('terms:terms.main-content')*/}
                                    </div>
                                    {/*Terms.map((term) => {
                    return (
                      <div key={term.id} className='p-l-15 p-r-15 '>
                        <Row className='font-weight-bold'>
                          {term.id}. {t(term.translation)}
                        </Row>
                        {term.subcontent.map((sub) => {
                          return (
                            <Row key={sub.id}>
                              <Col xs='12' className='p-l-10 d-flex font-weight-medium'>
                                <div className='p-r-5'>{sub.id}</div>
                                <div>{t(sub.translation, { merchant: 'Fadawin' })}</div>
                              </Col>
                              <div className='d-flex flex-column'>
                                {sub.details.map((detail) => {
                                  return (
                                    <Row key={detail.id} className=' p-l-40 font-weight-normal'>
                                      <div>{t(detail.translation)}</div>
                                    </Row>
                                  );
                                })}
                              </div>
                            </Row>
                          );
                        })}
                      </div>
                    );
                  })*/}
                                </ModalBody>
                            </Modal>
                            <div className='d-none d-md-block'>
                                {/* Full Name */}
                                <Row className='d-flex form-group'>
                                    <Col xs={12} md={12} lg={12} className='form-spacing'>
                                        <div className='d-flex'>
                                            <Col xs={12} md={4}>
                                                <label htmlFor='fullname' className='register-form-label'>
                                                    {t('global:global.form.fullname')}
                                                    <span className='text-danger m-r-5'>*</span>
                                                </label>
                                            </Col>
                                            <Col xs={12} md={8}>
                                                <DebounceInput
                                                    name='fullname'
                                                    className={`form-control-inner ${this.state.fullnameError.length > 0 ? 'is-invalid' : ''}`}
                                                    id='fullname'
                                                    debounceTimeout={1000}
                                                    placeholder={t('global:global.form.fullname')}
                                                    value={this.state.fullname}
                                                    onChange={this.props.handleFullnameChange}
                                                    onBlur={(this.validateFullname, this.handleFullnameChange)}
                                                />

                                                {this.state.fullnameError.map((errMsg, index) => {
                                                    return (
                                                        <div key={index} className='invalid-feedback text-danger'>
                                                            <Trans i18nKey={errMsg}></Trans>
                                                        </div>
                                                    );
                                                })}
                                            </Col>
                                        </div>
                                    </Col>
                                </Row>
                                {/* Email */}
                                <Row className='d-flex form-group'>
                                    <Col xs={12} md={12} lg={12} className='form-spacing'>
                                        <div className='d-flex'>
                                            <Col xs={12} md={4}>
                                                <label htmlFor='email' className='register-form-label'>
                                                    {t('global:global.form.email-placeholder')}
                                                    <span className='text-danger m-r-5 '>*</span>
                                                </label>
                                            </Col>
                                            <Col xs={12} md={8}>
                                                <DebounceInput
                                                    name='email'
                                                    className={`form-control-inner ${this.state.emailError.length > 0 ? 'is-invalid' : ''}`}
                                                    debounceTimeout={1000}
                                                    id='email'
                                                    placeholder={t('global:global.form.email-placeholder')}
                                                    value={this.props.email}
                                                    onChange={this.props.handleEmailChange}
                                                    onBlur={(this.validateEmail, this.handleEmailChange)}
                                                />

                                                {this.state.emailError.map((errMsg, index) => {
                                                    return (
                                                        <div key={index} className='invalid-feedback text-danger'>
                                                            <Trans i18nKey={errMsg}></Trans>
                                                        </div>
                                                    );
                                                })}
                                            </Col>
                                        </div>
                                    </Col>
                                </Row>
                                {/* Currency */}
                                <Row className='d-flex form-group'>
                                    <Col xs={12} md={12} lg={12} className='form-spacing'>
                                        <div className='d-flex'>
                                            <Col xs={12} md={4}>
                                                <label htmlFor='currency' className='register-form-label'>
                                                    {t('global:global.form.currency')}
                                                    <span className='text-danger m-r-5 '>*</span>
                                                </label>
                                            </Col>
                                            <Col xs={12} md={8}>
                                                <Select
                                                    noDataRenderer={this.customNoDataRenderer}
                                                    className='form-control-inner'
                                                    options={this.props.currencies}
                                                    value={this.props.currency}
                                                    placeholder={t('global:global.form.Select')}
                                                    key='value'
                                                    labelField='label'
                                                    valueField='value'
                                                    onChange={(value) => this.props.handleCurrencyChange(value)}
                                                    searchable={false}
                                                />
                                            </Col>
                                        </div>
                                    </Col>
                                </Row>
                                {/* phone */}
                                <Row className='d-flex form-group'>
                                    <Col xs={12} md={12} lg={12} className='form-spacing'>
                                        <div className='d-flex'>
                                            <Col xs={12} md={4}>
                                                <label htmlFor='phone' className='register-form-label'>
                                                    {t('global:global.form.phone')} <span className='text-danger m-r-5'>*</span>
                                                </label>
                                            </Col>
                                            <Col xs={12} md={8}>
                                                <Row className='d-flex align-items-center m-l-0 m-r-0'>
                                                    <Col xs='4' sm='4' className={`p-l-0 p-r-0 ${this.state.phoneError.length > 0 ? 'm-t--20' : ''}`}>
                                                        <FlagsSelect
                                                            telCodeList={this.props.telCodeList}
                                                            onSelect={this.props.selectCode}
                                                            countryCode={this.props.countryCode}
                                                            className='form-control-inner'
                                                        />
                                                    </Col>
                                                    <Col xs='8' sm='8' className='p-l-0 p-r-0 d-flex justify-content-end'>
                                                        <Row className='m-l-10 w-100'>
                                                            <DebounceInput
                                                                name='phone'
                                                                className={`col-12 p-l-5 p-r-5 form-control-inner register-field ${
                                                                    this.state.phoneError.length > 0 ? 'is-invalid' : ''
                                                                }`}
                                                                id='phone'
                                                                debounceTimeout={1000}
                                                                placeholder={t('global:global.form.phone')}
                                                                value={this.props.phone}
                                                                onChange={this.props.handlePhoneChange}
                                                                onBlur={(this.validatePhone, this.handlePhoneChange)}
                                                            />
                                                            {this.state.phoneError.map((errMsg, index) => {
                                                                return (
                                                                    <div key={index} className='col-12 invalid-feedback text-danger'>
                                                                        <Trans i18nKey={errMsg}></Trans>
                                                                    </div>
                                                                );
                                                            })}
                                                        </Row>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </div>
                                    </Col>
                                </Row>

                                <Row className='d-flex form-group'>
                                    <Col xs={12} md={12} lg={12} className='form-spacing'>
                                        <div className='d-flex'>
                                            <Col xs={12} md={4}></Col>
                                            <Col xs={12} md={8}>
                                                <div className='cntr form-group' onChange={(this.ageTermCheckBoxOnChange, this.props.ageTermCheckBoxOnChange)}>
                                                    <label htmlFor='cbx' className='label-cbx'>
                                                        <input id='cbx' type='checkbox' className='invisible'></input>
                                                        <div className='checkbox'>
                                                            <svg width='20px' height='20px' viewBox='0 0 20 20'>
                                                                <path d='M3,1 L17,1 L17,1 C18.1045695,1 19,1.8954305 19,3 L19,17 L19,17 C19,18.1045695 18.1045695,19 17,19 L3,19 L3,19 C1.8954305,19 1,18.1045695 1,17 L1,3 L1,3 C1,1.8954305 1.8954305,1 3,1 Z'></path>
                                                                <polyline points='4 11 8 15 16 6'></polyline>
                                                            </svg>
                                                        </div>
                                                        <div className='lh-1.2 d-webkit-box tnc-text'>
                                                            {t(
                                                                'register:register.condition.line',
                                                                `I confirm that I am at least 18 years old and have read and accepted the Website's Term and Conditions and Privacy Policy.`
                                                            )}
                                                            <Link className='text-underline  m-l-5 m-r-5' onClick={this.toggle}>
                                                                {t('register:register.condition.tnc', 'Terms & Conditions')}
                                                            </Link>
                                                            {t('register:register.condition.and', 'and')}
                                                            <Link className='text-underline m-l-5 m-r-5' onClick={this.toggle}>
                                                                {t('register:register.condition.privacypolicy', 'Privacy Policy.')}
                                                            </Link>
                                                            .
                                                        </div>
                                                    </label>
                                                </div>
                                            </Col>
                                        </div>
                                    </Col>
                                </Row>
                            </div>

                            <div className='d-block d-md-none '>
                                {/* Full Name */}
                                <Row className='d-flex form-group '>
                                    <Col xs={12} md={12} lg={12} className='form-spacing'>
                                        <div className=''>
                                            <Col xs={12} md={4}>
                                                <label htmlFor='fullname' className='register-form-label'>
                                                    {t('global:global.form.fullname')}
                                                    <span className='text-danger m-r-5'>*</span>
                                                </label>
                                            </Col>
                                            <Col xs={12} md={8}>
                                                <DebounceInput
                                                    name='fullname'
                                                    className={`form-control-inner ${this.state.fullnameError.length > 0 ? 'is-invalid' : ''}`}
                                                    id='fullname'
                                                    debounceTimeout={1000}
                                                    placeholder={t('global:global.form.fullname')}
                                                    value={this.state.fullname}
                                                    onChange={this.props.handleFullnameChange}
                                                    onBlur={(this.validateFullname, this.handleFullnameChange)}
                                                />

                                                {this.state.fullnameError.map((errMsg, index) => {
                                                    return (
                                                        <div key={index} className='invalid-feedback text-danger'>
                                                            <Trans i18nKey={errMsg}></Trans>
                                                        </div>
                                                    );
                                                })}
                                            </Col>
                                        </div>
                                    </Col>
                                </Row>
                                {/* Email */}
                                <Row className='d-flex form-group'>
                                    <Col xs={12} md={12} lg={12} className='form-spacing'>
                                        <div className=''>
                                            <Col xs={12} md={4}>
                                                <label htmlFor='email' className='register-form-label'>
                                                    {t('global:global.form.email-placeholder')}
                                                    <span className='text-danger m-r-5'>*</span>
                                                </label>
                                            </Col>
                                            <Col xs={12} md={8}>
                                                <DebounceInput
                                                    name='email'
                                                    className={`form-control-inner ${this.state.emailError.length > 0 ? 'is-invalid' : ''}`}
                                                    debounceTimeout={1000}
                                                    id='email'
                                                    placeholder={t('global:global.form.email-placeholder')}
                                                    value={this.props.email}
                                                    onChange={this.props.handleEmailChange}
                                                    onBlur={(this.validateEmail, this.handleEmailChange)}
                                                />

                                                {this.state.emailError.map((errMsg, index) => {
                                                    return (
                                                        <div key={index} className='invalid-feedback text-danger'>
                                                            <Trans i18nKey={errMsg}></Trans>
                                                        </div>
                                                    );
                                                })}
                                            </Col>
                                        </div>
                                    </Col>
                                </Row>
                                {/* Currency */}
                                <Row className='d-flex form-group '>
                                    <Col xs={12} md={12} lg={12} className='form-spacing'>
                                        <div className=''>
                                            <Col xs={12} md={4}>
                                                <label htmlFor='currency' className='register-form-label'>
                                                    {t('global:global.form.currency')}
                                                    <span className='text-danger m-r-5 '>*</span>
                                                </label>
                                            </Col>
                                            <Col xs={12} md={8}>
                                                <Select
                                                    noDataRenderer={this.customNoDataRenderer}
                                                    className='form-control-inner'
                                                    options={this.props.currencies}
                                                    value={this.props.currency}
                                                    placeholder={t('global:global.form.Select')}
                                                    key='value'
                                                    labelField='label'
                                                    valueField='value'
                                                    onChange={(value) => this.props.handleCurrencyChange(value)}
                                                    searchable={false}
                                                />
                                            </Col>
                                        </div>
                                    </Col>
                                </Row>
                                {/* phone */}
                                <Row className='d-flex form-group'>
                                    <Col xs={12} md={12} lg={12} className='form-spacing'>
                                        <div className=''>
                                            <Col xs={12} md={4}>
                                                <label htmlFor='phone' className='register-form-label'>
                                                    {t('global:global.form.phone')} <span className='text-danger m-r-5'>*</span>
                                                </label>
                                            </Col>
                                            <Col xs={12} md={8}>
                                                <Row className='d-flex align-items-center m-l-0 m-r-0'>
                                                    <Col xs='4' sm='4' className={`p-l-0 p-r-0 ${this.state.phoneError.length > 0 ? 'm-t--20' : ''}`}>
                                                        <FlagsSelect
                                                            telCodeList={this.props.telCodeList}
                                                            onSelect={this.props.selectCode}
                                                            countryCode={this.props.countryCode}
                                                            className='form-control-inner'
                                                        />
                                                    </Col>
                                                    <Col xs='8' sm='8' className='p-l-0 p-r-0 d-flex justify-content-end'>
                                                        <Row className='m-l-10 w-100'>
                                                            <DebounceInput
                                                                name='phone'
                                                                className={`col-12 p-l-5 p-r-5 form-control-inner register-field ${
                                                                    this.state.phoneError.length > 0 ? 'is-invalid' : ''
                                                                }`}
                                                                id='phone'
                                                                debounceTimeout={1000}
                                                                placeholder={t('global:global.form.phone')}
                                                                value={this.props.phone}
                                                                onChange={this.props.handlePhoneChange}
                                                                onBlur={(this.validatePhone, this.handlePhoneChange)}
                                                            />
                                                            {this.state.phoneError.map((errMsg, index) => {
                                                                return (
                                                                    <div key={index} className='col-12 invalid-feedback text-danger'>
                                                                        <Trans i18nKey={errMsg}></Trans>
                                                                    </div>
                                                                );
                                                            })}
                                                        </Row>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </div>
                                    </Col>
                                </Row>

                                <Row className='d-flex form-group'>
                                    <Col xs={12} md={12} lg={12} className='form-spacing'>
                                        <div className='cntr form-group' onChange={(this.ageTermCheckBoxOnChange, this.props.ageTermCheckBoxOnChange)}>
                                            <label htmlFor='mobilecbx' className='label-cbx'>
                                                <input id='mobilecbx' type='checkbox' className='invisible'></input>
                                                <div className='checkbox'>
                                                    <svg width='20px' height='20px' viewBox='0 0 20 20'>
                                                        <path d='M3,1 L17,1 L17,1 C18.1045695,1 19,1.8954305 19,3 L19,17 L19,17 C19,18.1045695 18.1045695,19 17,19 L3,19 L3,19 C1.8954305,19 1,18.1045695 1,17 L1,3 L1,3 C1,1.8954305 1.8954305,1 3,1 Z'></path>
                                                        <polyline points='4 11 8 15 16 6'></polyline>
                                                    </svg>
                                                </div>
                                                <div className='lh-1.2 d-webkit-box tnc-text'>
                                                    {t(
                                                        'register:register.condition.line',
                                                        `I confirm that I am at least 18 years old and have read and accepted the Website's Term and Conditions and Privacy Policy.`
                                                    )}
                                                    <Link className='text-underline  m-l-5 m-r-5' onClick={this.toggle}>
                                                        {t('register:register.condition.tnc', 'Terms & Conditions')}
                                                    </Link>
                                                    {t('register:register.condition.and', 'and')}
                                                    <Link className='text-underline m-l-5 m-r-5' onClick={this.toggle}>
                                                        {t('register:register.condition.privacypolicy', 'Privacy Policy.')}
                                                    </Link>
                                                    .
                                                </div>
                                            </label>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Row>
                    </div>
                )}
            </Translation>
        );
    }
}
export default withRouter(connect(mapStateToProps, null)(withTranslation(['password', 'terms'])(Step2)));
