import React, { Suspense } from 'react';
import { Trans, Translation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { mapStateToProps } from 'root/redux/selector/selector';
import classnames from 'classnames';
import { MdClose } from 'react-icons/md';
import SVGElements from 'root/components/SVGElements';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { bankIcon, cryptoIcon, qrpay } from '../../16MyAccount/161_MyAccount_Items_Data';
import { Fragment } from 'react';
import Crypto from '../../08Deposit/087_Crypto';
import Ewallet from '../../11Withdraw/ewallet/component/mobile/Ewallet';
import SmallLoading from '../../../components/Loading/SmallLoading';
import { trans_massageHideAddBank } from 'root/utils/transaction-util';
import commonUtil from 'root/utils/common-util';
import Button from '../../Share/button/button';
import { gu_handleUploadReceiptButtonOnClick, gu_filterCommonKey } from 'root/utils/general-util';
import { wd_handleReceiptImageChange, wd_handleClearReceipt } from 'root/utils/withdrawal-util';
import SVG from 'react-inlinesvg';
import BankIcon from '../../../components/Molecules/bank-icon';
import { MODULE_TYPE as MT, POPUP_TYPE, OTP_VERIFICATION_TYPE as OVT } from '@utils/constants';
import AuthSettings from '@components/molecules/auth-settings/auth-settings';
import { AMOUNT_DEPOSIT_METHOD_CODE } from 'root/utils/constants';
import { SetupSixDigitPinNotice } from '@components/organisms/set-up-pin-popup/set-up-pin-popup';
import { popupAction } from '@redux/action/popup.action';
import notification from '@utils/notification';
import { authSettingsAction } from '@redux/action';
class Withdraw extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: 'onlinetransfer',
            mainWallet: {},
            bankList: [],
            memberBankList: [],
            unOwnedBankList: [],
            filteredBankList: [],
            bankBranch: '',
            bankName: '',
            accountName: '',
            accountNumber: '',
            bankId: null,
            memberBankId: '',
            amount: '',
            disabledBankBranch: false,
            disabledBankName: false,
            disabledAccountName: false,
            disabledAccountNumber: false,
            disabledOtherBankBranch: false,
            iconLoading: false,
            showLoading: false,
            limit: {},
            cryptoMinmaxLimit: {},
            bankNameErrMsg: [],
            accountNumberErrMsg: [],
            amountErrMsg: [],
            bankBranchErrMsg: [],
            disabledButton: true,
            showOtherBankName: false,
            message: '',
            status: '',
            bankActive: 0,
            bankHover: '',
            selectedBank: [],
            disableDecimalWithdraw: false,
            showFirstMemberBank: false,
            disableOtherBanks: true,
            getTncReferral: false,
            transactionDetails: '',
            remainingAmt: '',
            showTurnoverAmount: false,
            cryptoEnabled: false,
            ewalletEnabled: false,
            withdrawalTab: [
                { code: 'B', translation: 'global:global.menu.account.bankTransfer' },
                { code: 'CT', translation: 'Crypto' },
                { code: 'R', translation: 'E-Wallet' },
            ],
            errors: [],
            receiptImg: [],
            receiptImgFile: null,
            isInvalidUploadFileType: false,
            isShowUnownedBankList: false,
            compSettings: {},
            selectedBankIndex: null,
            amountOptions: [],
            withdrawAmountOptions: {},
        };
        this.toggle = this.toggle.bind(this);
    }

    toggle(tab, optionCode) {
        if (this.state.activeTab !== tab) {
            let hideAddBankAccount = trans_massageHideAddBank(this, 'withdraw', optionCode);

            this.setState({ activeTab: tab, hideAddBankAccount }, () => {
                this.setState({ amount: '', bankNameErrMsg: [], accountNumberErrMsg: [], amountErrMsg: [], bankBranchErrMsg: [] });
            });
        }
        const code = tab === 'ewallet' ? 'R' : optionCode;

        this.getSelectedMethodWithdrawAmountOptions(code);
    }

    methodoption = [
        { label: 'EEZIEPAY', value: 'EEZIEPAY' },
        { label: 'HELP2PAY', value: 'HELP2PAY' },
    ];
    bankoption = [
        { label: 'MAYBANK', value: 'MAYBANK' },
        { label: 'CIMB BANK', value: 'CIMB BANK' },
    ];

    selectBank(bankId, index) {
        if (this.state.selectedBankIndex !== index) {
            this.setState(() => ({
                bankActive: bankId,
                selectedBankIndex: index,
            }));
            this.handleClearReceipt();
        }
    }

    getSelectedMethodWithdrawAmountOptions(method) {
        const { withdrawAmountOptions } = this.state;

        switch (method) {
            case AMOUNT_DEPOSIT_METHOD_CODE.B:
                this.setState({
                    amountOptions: withdrawAmountOptions?.quickAmounts?.find((el) => el?.method === AMOUNT_DEPOSIT_METHOD_CODE.B)?.quickAmount?.split(',') || [],
                });
                break;

            case AMOUNT_DEPOSIT_METHOD_CODE.CT:
                this.setState({
                    amountOptions: withdrawAmountOptions?.quickAmounts?.find((el) => el?.method === AMOUNT_DEPOSIT_METHOD_CODE.CT)?.quickAmount?.split(',') || [],
                });
                break;

            case AMOUNT_DEPOSIT_METHOD_CODE.R:
                this.setState({
                    amountOptions: withdrawAmountOptions?.quickAmounts?.find((el) => el?.method === AMOUNT_DEPOSIT_METHOD_CODE.R)?.quickAmount?.split(',') || [],
                });
                break;
            default:
                break;
        }
    }

    getMerchantWithdrawAmountOptions() {
        window.SPL_Member.getMerchantWithdrawAmountOptions().then((withdrawAmount) => {
            this.setState({
                withdrawAmountOptions: withdrawAmount,
            });
        });
    }

    handleBankChange = (value) => {
        if (this.state.selectedBank === value) {
            return;
        }
        value = [value];
        this.setState({ selectedBank: value });
        let bankBranch = '';
        let disabledBankBranch = false;
        let disabledAccountName = false;
        let disabledBankName = false;
        let disabledAccountNumber = false;
        let disabledOtherBankBranch = false;
        let accountName = '';
        let bankName = '';
        let accountNumber = '';
        let memberBankId = '';
        let bankId = null;
        if (value.length > 0) {
            this.setState({ showOtherBankName: false });
            if (value[0] && value[0].bankId !== null) {
                this.setState({ bankActive: value[0].bankId });
                for (let i = 0; i < this.state.bankList.length; i++) {
                    const bank = this.state.bankList[i];
                    if ((bank.bankId === value[0].bankId && bank.memberBankId === value[0].memberBankId) || value[0].bankId === null) {
                        // bankBranch = bank.bankBranch;
                        bankBranch = ' ';
                        accountName = updateAccountNumber(bank, this.props);
                        bankName = value[0].bankName;
                        accountNumber = value[0].accountNumber || '';
                        memberBankId = value[0].memberBankId;
                        bankId = value[0].bankId;
                        if (bank.haveBank) {
                            disabledBankBranch = true;
                            disabledAccountName = true;
                            disabledAccountNumber = true;
                            disabledOtherBankBranch = true;
                            disabledBankName = true;
                        }
                        break;
                    }
                }
            } else {
                bankName = '';
                bankId = null;
                accountName = this.props.user && this.props.user.account && this.props.user.account.name;
                let memberBank = this.state.memberBankList.filter((bank) => {
                    return bank.bankId === null;
                })[0];
                if (memberBank) {
                    bankId = memberBank.bankId;
                    accountName = memberBank.accountHolder;
                    accountNumber = memberBank.accountNumber;
                    bankName = memberBank.bankName || '';
                    bankBranch = memberBank.bankBranch;
                    disabledBankBranch = true;
                    disabledAccountName = true;
                    disabledAccountNumber = true;
                    disabledBankName = true;
                    disabledOtherBankBranch = true;
                }
                this.setState({ showOtherBankName: true });
            }
        }

        this.setState(
            {
                bankId: bankId,
                memberBankId: memberBankId,
                bankBranch: bankBranch,
                accountName: accountName,
                accountNumber: accountNumber,
                bankName: bankName,
                disabledBankBranch: disabledBankBranch,
                disabledAccountName: disabledAccountName,
                disabledBankName: disabledBankName,
                disabledAccountNumber: disabledAccountNumber,
                disabledOtherBankBranch: disabledOtherBankBranch,
            },
            () => {
                this.validateAccountNumber();
                this.handleClearReceipt();
            }
        );

        function updateAccountNumber(bank, props) {
            if (bank.status === 'R') {
                return bank.accountName;
            } else {
                return props.user.account.name;
            }
        }
    };

    handleBankNameChange = (event) => {
        this.setState({ bankName: event.target.value }, () => {
            this.validateBankName();
        });
    };

    handleBankBranchChange = (event) => {
        this.setState({ bankBranch: event.target.value }, () => {
            this.validateBankBranch();
        });
    };

    handleAccountNumberChange = (event) => {
        if (this.isNumber(event)) {
            this.setState({ accountNumber: event.target.value }, () => {
                this.validateAccountNumber();
            });
        }
    };

    handleAmountChange = (event) => {
        this.setState({ amount: event.target.value }, () => {
            this.validateAmount();
        });
    };

    handleWithdrawAmountChange = (amount) => {
        this.setState({ amount: amount }, () => {
            this.validateAmount();
        });
    };

    isNumber = (event) => {
        return /^\d*$/.test(event.target.value);
    };

    handleSubmit = () => {
        this.setState({ showLoading: false, disabledButton: true }, () => {
            Promise.all([this.validateAmount(), this.validateAccountNumber()]).then(() => {
                let error = this.checkError();
                if (error === false) {
                    this.getMainWallet().then((mainWallet) => {
                        if (this.state.amount > mainWallet.balance) {
                            this.setState({
                                showLoading: false,
                                disabledButton: false,
                                status: 'F',
                                message: this.props.t('transaction:transaction.transfer.insuffficientMainWallet'),
                                visible: true,
                            });
                            return;
                        }

                        if (this.checkError()) {
                            this.setState({ showLoading: false, disabledButton: false });
                            return;
                        }

                        const { authSettingsReducer, popupReducer, dispatch } = this.props;
                        const _withdrawalBankAuthSettings = authSettingsReducer?.processedData?.[MT.WITHDRAWAL_BANK];
                        const _config = {
                            gaEnabled: _withdrawalBankAuthSettings?.gaEnabled,
                            sixPinEnabled: _withdrawalBankAuthSettings?.sixPinEnabled,
                        };

                        // if enable Pin, but haven't got enter pin number for GA yet, then will trigger pin popup
                        if (_withdrawalBankAuthSettings?.sixPinEnabled && !popupReducer?.enteredPin) {
                            dispatch(popupAction.setItemConfig(POPUP_TYPE.PIN_ENTRY, _config));
                            dispatch(popupAction.togglePopUp(POPUP_TYPE.PIN_ENTRY, true));
                            dispatch(popupAction.setSubmitSuccessCb(this.continueSubmitWithdraw.bind(this)));
                            this.setState({ showLoading: false, disabledButton: false });
                        } else {
                            this.continueSubmitWithdraw();
                        }
                    });
                } else {
                    this.setState({ showLoading: false, disabledButton: false });
                }
            });
        });
    };

    continueSubmitWithdraw = () => {
        this.setState({ disabledButton: true });

        const { popupReducer, dispatch } = this.props;
        const { accountName, accountNumber, bankId, memberBankId, bankName, amount, receiptImgFile } = this.state;
        let memberBankIdInit = 0;

        if (bankId === 'o' || bankId === 0 || bankId === null) {
            let memberBankFinal = this.state.memberBankList.filter((m) => m.bankId === null)[0];
            if (memberBankFinal != null) {
                memberBankIdInit = memberBankFinal.memberBankId;
            }
        } else {
            // NOTE: bankId wasn't unique enough, use memberBankId instead.
            let memberBankFinal = this.state.memberBankList.filter((m) => m.memberBankId === memberBankId)[0];
            if (memberBankFinal != null) {
                memberBankIdInit = memberBankFinal.memberBankId;
            }
        }

        let withdrawObj = {
            accountName: accountName,
            accountNumber: accountNumber,
            memberBankId: memberBankIdInit,
            memberBankBankId: bankId,
            amount: amount,
            bankName: bankName,
            merchantCode: window.merchantCode,
            bankStatementPath: receiptImgFile,
            transactionOption: 'B',
            sixDigitPin: popupReducer?.enteredPin,
            verificationType: OVT.WITHDRAWAL_BANK,
        };

        window.SPL_Transaction.requestWithdraw(withdrawObj)
            .then((data) => {
                if (data.status === 'Fail') {
                    if (data.errorCode === 2717) {
                        dispatch(authSettingsAction.setSixPinLocked()); // this will trigger affiliate-hook setModuleSixPinLocked
                    }
                    if (data.errorCode === 2716) {
                        notification.showNotification('error', this.props.t(data?.errMsg));
                    }
                    this.setState({ status: 'F', message: this.props.t(data.errMsg), visible: true });
                } else {
                    this.setState(
                        {
                            status: 'S',
                            message: this.props.t('transaction:transaction.withdrawal.request-success'),
                            amount: '',
                            accountNumber: '',
                            bankBranch: '',
                            bankName: '',
                            bankId: null,
                            visible: true,
                        },
                        () => {
                            setTimeout(() => {
                                this.setState({ message: '', visible: false });
                            }, 5000);
                        }
                    );
                    this.handleClearReceipt();
                    this.getMainWallet();
                    this.loadBankAccounts();
                    dispatch(popupAction.togglePopUp(POPUP_TYPE.PIN_ENTRY, false));
                    dispatch(popupAction.setEnteredPin(''));
                }
                this.setState({ showLoading: false, disabledButton: false });
            })
            .catch((err) => {
                this.setState({ showLoading: false, disabledButton: false });
            });
    };

    componentDidMount() {
        this.initCompSettings().then(() => {
            this.loadEwallet();
        });
        this.loadAdditionalWithdrawSetting();
        this.isIdentity();
        this.getMainWallet();
        this.loadBankAccounts();
        this.loadTransactionDetails();
        this.loadCryptoWithdraw();
        this.getMerchantWithdrawAmountOptions();
        if (this.props.screen.viewType === 'web' && this.props.location.pathname === '/withdraw') {
            this.props.history.push('/myaccount/withdraw');
        } else if (this.props.screen.viewType === 'mobile' && this.props.location.pathname === '/myaccount/withdraw') {
            this.props.history.push('/fund');
            this.props.history.push('/withdraw');
        }
    }

    initCompSettings = () => {
        return new Promise((resolve) => {
            const { user } = this.props;
            const { currency } = user.account;
            this.setState({}, () => {
                const { withdrawalTab } = this.state;
                window.SPL_Content.getBankNameJson(this.props);
                // Step 1: Standard way to get from portal settings
                let tempSettings = commonUtil.getSettingFromPortalSettings({
                    vm: this,
                    settingName: 'withdrawalSettings',
                });

                let optionCode = withdrawalTab && withdrawalTab[0] && withdrawalTab[0].code;
                let hideAddBankAccount = trans_massageHideAddBank(this, 'withdraw', optionCode);

                let reqBankStatementByCurrencies = false;
                if (tempSettings.reqBankStatementByCurrencies) {
                    reqBankStatementByCurrencies = tempSettings.reqBankStatementByCurrencies.indexOf(currency) !== -1;
                }

                // Step 3: Initial all into compSettings
                let compSettings = {
                    seperateTngToEwallet: tempSettings && tempSettings.seperateTngToEwallet,
                    reqBankStatementByCurrencies,
                };

                this.setState(
                    {
                        hideAddBankAccount,
                        compSettings,
                    },
                    () => {
                        resolve();
                    }
                );
            });
        });
    };

    loadTransactionDetails = () => {
        window.SPL_Transaction.loadTransactionDetails().then((data) => {
            if (data) {
                this.setState({ transactionDetails: data });
            }
        });
    };

    loadCryptoWithdraw = () => {
        window.SPL_Transaction.getCryptWithdrawBank().then((data) => {
            if (data) {
                this.setState({ cryptoEnabled: data.showCrypto }, () => {
                    this.getRemainingAvailableWithdrawDetails('CT');
                });
            }
        });
    };

    loadEwallet = () => {
        const { compSettings } = this.state;

        if (compSettings && compSettings.seperateTngToEwallet) {
            window.SPL_Transaction.getWithdrawalBankList().then((data) => {
                if (data && data.R && data.R.R.length) {
                    this.setState({ ewalletEnabled: true, ewalletList: data.R.R });
                }
            });
        }
    };

    loadAdditionalWithdrawSetting = () => {
        const { portal } = this.props;
        if (portal && portal.settings) {
            if (portal.settings.hasOwnProperty('disableDecimalWithdraw')) {
                let disable = portal.settings.disableDecimalWithdraw;
                this.setState({ disableDecimalWithdraw: disable });
            }

            if (portal.settings.hasOwnProperty('showFirstMemberBank')) {
                let show = portal.settings.showFirstMemberBank;
                this.setState({ showFirstMemberBank: show }, () => {
                    this.loadBankAccounts();
                });
            }

            if (portal.settings.hasOwnProperty('disableOtherBanks')) {
                let disable = portal.settings.disableOtherBanks;
                this.setState({ disableOtherBanks: disable }, () => {
                    this.loadBankAccounts();
                });
            }
        }
    };

    getMainWallet() {
        this.setState({ iconLoading: true });
        let component = this;
        return new Promise(function (resolve, reject) {
            window.SPL_Member.getMainWallet()
                .then((mainWallet) => {
                    component.setState({ mainWallet: mainWallet.data, iconLoading: false });
                    resolve(mainWallet.data);
                })
                .catch((err) => {
                    component.setState({ iconLoading: false });
                });
        });
    }

    mapBankImg = (banks, isSvg) => {
        let currency = null;
        if (this.props.user && this.props.user.account) {
            currency = this.props.user.account.currency;
        }
        if (currency && banks && banks.length > 0) {
            for (let i = 0; i < banks.length; i++) {
                const bank = banks[i];
                if (isSvg) {
                    bank.imgOn = '/public/html/images/bank_img/' + bank.code + '.svg';
                    bank.imgOff = '/public/html/images/bank_img/' + bank.code + '-off.svg';
                } else {
                    bank.imgOn = window.SPL_Content.mapBankHoverImg(bank.bankCode, currency, true);
                    bank.imgOff = window.SPL_Content.mapBankHoverImg(bank.bankCode, currency, false);
                }
            }
        }

        return banks;
    };

    loadBankAccounts() {
        const { t, user } = this.props;
        const { showFirstMemberBank, disableOtherBanks, ewalletList, isShowUnownedBankList } = this.state;
        this.setState({ showLoading: true });
        window.SPL_Transaction.loadAllOwnedAndUnOwnedBank().then((data) => {
            // To show latest added bank account
            let filteredBankList = data.memberBankList.reverse();
            let filteredUnOwnedBankList = data.bankList.filter((bank) => data.unOwnedBankList.find((unownedbank) => unownedbank.bankId === bank.bankId));
            const isNotIncludeOtherBankYet = data.unOwnedBankList.find((bank) => Number(bank.bankId) === 0);

            if (user.account.currency === 'VND') {
                let nonVietBank = [];
                let vietBank = [];
                filteredBankList.forEach((e) => {
                    const { country } = e;
                    if (country !== 'VN') {
                        nonVietBank.push(e);
                    } else {
                        vietBank.push(e);
                    }
                });
                filteredBankList = [...vietBank, ...nonVietBank];
            }

            let otherBanks = {
                bankId: null,
                bankName: t('transaction:transaction.withdrawal.otherBanks'),
                bankNameOnly: t('transaction:transaction.withdrawal.otherBanks'),
                bankBranch: null,
                memberBankId: null,
            };

            if (isShowUnownedBankList) {
                filteredBankList = filteredBankList.concat(filteredUnOwnedBankList);
            }

            if (!disableOtherBanks && isNotIncludeOtherBankYet && isShowUnownedBankList) {
                filteredBankList.push(otherBanks);
            }

            // load bank tab
            window.SPL_Content.getBankName(this.state.withdrawalTab, 'option');
            filteredBankList = this.mapBankImg(filteredBankList);
            if (ewalletList && ewalletList.length > 0) {
                for (let i = 0; i < ewalletList.length; i++) {
                    filteredBankList = filteredBankList.filter((data) => data.code !== ewalletList[i].code);
                }
            }

            for (let i = 0; i < filteredBankList.length; i++) {
                let targetBank = filteredBankList[i];
                targetBank.optId = targetBank.memberBankId ? `${targetBank.bankId}-${targetBank.memberBankId}` : targetBank.bankId;
            }

            this.setState(
                {
                    showLoading: false,
                    bankList: data.bankList,
                    filteredBankList: filteredBankList,
                    memberBankList: data.memberBankList,
                    unOwnedBankList: data.unOwnedBankList,
                },
                () => {
                    let list = this.state.bankList;

                    if (showFirstMemberBank) {
                        this.setState({ selectedBank: [list[0]] });
                    }
                    this.handleBankChange(filteredBankList[0]);
                    this.getRemainingAvailableWithdrawDetails('B');
                }
            );
        });
    }

    getRemainingAvailableWithdrawDetails(bankType) {
        window.SPL_Transaction.getRemainingAvailableWithdrawDetails(null, bankType).then((limit) => {
            let { cryptoMinmaxLimit } = this.state;
            for (const key in limit) {
                if (typeof limit[key] === 'number') {
                    // do nothing
                } else {
                    limit[key] = this.props.t(limit[key]);
                }
            }
            if (bankType === 'CT') {
                cryptoMinmaxLimit = limit;
            }
            this.setState({ limit: limit, cryptoMinmaxLimit });
        });
    }

    validateBankName = () => {
        window.SPL_Transaction.validateBankName(this.state.bankName).then((errMsg) => {
            this.setState({ bankNameErrMsg: errMsg });

            this.checkError();
        });
    };

    validateBankBranch = () => {
        window.SPL_Transaction.validateBankBranch(this.state.bankBranch).then((errMsg) => {
            for (let i = 0; i < errMsg.length; i++) {
                if (errMsg[i] === 'BANK_BRANCH_IS_REQUIRED') {
                    errMsg[i] = `inputHelpBlock:inputHelpBlock.transfer.bankBranchRequired`;
                }
            }
            this.setState({ bankBranchErrMsg: errMsg });

            this.checkError();
        });
    };

    validateAccountNumber = () => {
        window.SPL_Transaction.validateAccountNumber(this.state.accountNumber).then((errMsg) => {
            this.setState({ accountNumberErrMsg: errMsg });
            this.checkError();
        });
    };

    validateAmount = () => {
        window.SPL_Transaction.validateAmount(this.state.amount, this.state.limit).then((errMsg) => {
            this.setState({ amountErrMsg: errMsg });
            this.checkError();
        });
    };

    isIdentity = () => {
        this.setState({ accountName: this.props.user && this.props.user.account && this.props.user.account.name });
    };

    closeModal = () => {
        this.setState({ visible: false });
    };

    checkError() {
        if (this.state.showOtherBankName) {
            if (
                this.state.amountErrMsg.length > 0 ||
                this.state.accountNumberErrMsg.length > 0 ||
                this.state.accountNumber === '' ||
                this.state.amount === '' ||
                this.state.bankName === '' ||
                (this.state.bankActive && !this.state.bankActive)
            ) {
                this.setState({ disabledButton: true });
                return true;
            } else {
                this.setState({ disabledButton: false });
                return false;
            }
        } else {
            if (
                this.state.amountErrMsg.length > 0 ||
                this.state.accountNumberErrMsg.length > 0 ||
                this.state.accountNumber === '' ||
                this.state.amount === '' ||
                (this.state.bankActive && !this.state.bankActive)
            ) {
                this.setState({ disabledButton: true });
                return true;
            } else {
                this.setState({ disabledButton: false });
                return false;
            }
        }
    }

    hoverBank = (id) => {
        this.setState({ bankHover: id });
    };

    getTncReferral = () => {
        this.setState({ getTncReferral: !this.state.getTncReferral });
    };

    handleReceiptImageChange(e) {
        wd_handleReceiptImageChange(this, e);
    }

    handleClearReceipt() {
        const { reqBankStatementByCurrencies } = this.state.compSettings;
        if (reqBankStatementByCurrencies) {
            wd_handleClearReceipt(this);
        }
    }

    _isShowUploadBankStatementField() {
        const { compSettings, selectedBank, memberBankList } = this.state;
        const { reqBankStatementByCurrencies } = compSettings;

        let checkBankRejectedStatus =
            memberBankList &&
            memberBankList.length > 0 &&
            memberBankList.some((bank) => {
                return selectedBank && selectedBank[0] && bank && bank.bankCode === selectedBank[0].bankCode && bank.verifiedDisplayStatus === 'rejected';
            });

        if (reqBankStatementByCurrencies) {
            if (!(selectedBank && selectedBank[0] && selectedBank[0].accountNumber) || checkBankRejectedStatus) {
                return true;
            } else {
                return false;
            }
        }
    }

    renderUploadBankStatementField() {
        const { t } = this.props;
        const { receiptImg, receiptImgFile } = this.state;

        return (
            <>
                {this._isShowUploadBankStatementField() && (
                    <div className='withdraw-item-box'>
                        <div className='title-item'>
                            <span>
                                {t('transaction:transaction.withdrawal.bankStatement', 'Bank Statement')} <span className='asterisk'>*</span>
                            </span>
                        </div>
                        <div className='withdraw-bank-statement'>
                            <Button
                                className='standard-button standard-st-upload-button'
                                buttonText={t('transaction:transaction.upload')}
                                onClickFunction={gu_handleUploadReceiptButtonOnClick.bind(this)}
                                handleReceiptFunction={this.handleReceiptImageChange.bind(this)}
                                isReceiptInput={true}
                            />

                            {receiptImg.length > 0 &&
                                (receiptImgFile && receiptImgFile.type === 'application/pdf' ? (
                                    <div className='receipt-image-box'>
                                        <label>{t('transaction:transaction.deposit.pdf-file-name', 'PDF File Name: ')}</label>
                                        <span className='pdf-name'>{receiptImgFile.name}</span>
                                        <img src='/public/images/icon/close-red.png' className='pdf-clear' onClick={() => this.handleClearReceipt()} alt='' />
                                    </div>
                                ) : (
                                    <div className='receipt-image-box'>
                                        <img src={receiptImg} alt='receipt.png'></img>
                                    </div>
                                ))}

                            <p className='daily-limit'>
                                <i className='icon-tip'></i>
                                <span>
                                    <span>{t('transaction:transaction:withdrawal.bankStatementRequirement')}</span>
                                </span>
                            </p>
                        </div>
                    </div>
                )}
            </>
        );
    }

    _showUnownedBankList() {
        const { isShowUnownedBankList } = this.state;
        if (!isShowUnownedBankList) {
            this.setState({ isShowUnownedBankList: true }, () => {
                this.loadBankAccounts();
            });
        }
    }

    render() {
        const { account } = this.props.user;
        const { key, country } = this.props.language;
        const {
            disableDecimalWithdraw,
            transactionDetails,
            cryptoEnabled,
            ewalletEnabled,
            receiptImg,
            selectedBank,
            disableOtherBanks,
            isShowUnownedBankList,
            showLoading,
            amountOptions,
        } = this.state;
        const showBankBranch = gu_filterCommonKey(this.props?.portal?.settings?.showBankBranch);

        const { authSettingsReducer } = this.props;
        const _authSettingsBankDetailModule = authSettingsReducer?.processedData?.[MT.WITHDRAWAL_BANK];
        const _authSettingsBankDetailEWalletModule = authSettingsReducer?.processedData?.[MT.WITHDRAWAL_EWALLET];

        const chkBankDetailSixPinStatus =
            (_authSettingsBankDetailModule?.sixPinEnabled && _authSettingsBankDetailModule?.memberSixPinInfo?.sixPinLocked) ||
            (_authSettingsBankDetailModule?.sixPinEnabled && !_authSettingsBankDetailModule?.memberSixPinInfo?.sixPinRegistered) ||
            false;

        const chkBankDetailEWalletSixPinStatus =
            (_authSettingsBankDetailEWalletModule?.sixPinEnabled && _authSettingsBankDetailEWalletModule?.memberSixPinInfo?.sixPinLocked) ||
            (_authSettingsBankDetailEWalletModule?.sixPinEnabled && !_authSettingsBankDetailEWalletModule?.memberSixPinInfo?.sixPinRegistered) ||
            false;

        return (
            <Translation>
                {(t) => (
                    <div className='wap-withdraw-page'>
                        <AuthSettings module={MT?.CRYPTO_ADDRESS} isPublic={false} />
                        <AuthSettings module={MT?.WITHDRAWAL_CRYPTO} isPublic={false} />
                        <AuthSettings module={MT?.WITHDRAWAL_BANK} isPublic={false} />
                        <AuthSettings module={MT?.WITHDRAWAL_EWALLET} isPublic={false} />

                        {this.state.showTurnoverAmount && (
                            <div className='messaging-popup'>
                                <div className='popup'>
                                    <div className='popup-body'>
                                        <div className='btn-close'>
                                            <MdClose onClick={() => this.setState({ showTurnoverAmount: false })} />
                                        </div>

                                        <p className='errTurnover-msg text-danger'>
                                            {t('transaction:transaction.withdrawal.turnoverAmountInsufficient', { remainingAmt: this.state.remainingAmt })}
                                        </p>
                                    </div>
                                </div>
                                <div className='popup-overlay' onClick={() => this.setState({ showTurnoverAmount: false })}></div>
                            </div>
                        )}

                        <div className='page-content'>
                            <div className='withdraw-item-box'>
                                <div className='title-item'>
                                    <span>{t('settings:settings.tabs.heading.withdrawOptions', 'Withdraw Options')}</span>
                                </div>

                                <Nav tabs className='withdraw-mobile-tab'>
                                    <NavItem
                                        className='withdraw-tab-click mobile-withdraw-transfer'
                                        onClick={() => {
                                            this.toggle('onlinetransfer', 'B');
                                            this.loadBankAccounts();
                                        }}
                                    >
                                        <NavLink
                                            className={classnames({
                                                active: this.state.activeTab === 'onlinetransfer',
                                                navlinkForm: true,
                                                withdrawLink: true,
                                            })}
                                        >
                                            <div className='withdraw-banktransfer'>
                                                <img src={bankIcon} alt='Bank Transfer' className={`${this.state.activeTab === 'onlinetransfer' ? 'active' : ''}`} />
                                            </div>
                                            {this.state.withdrawalTab.map((data, index) => (
                                                <div className='withdraw-options-text'>{data.code === 'B' && t(data.translation, data.translation)}</div>
                                            ))}
                                        </NavLink>
                                    </NavItem>

                                    {/* crypto */}
                                    {cryptoEnabled && (
                                        <NavItem
                                            className='withdraw-tab-click mobile-withdraw-transfer'
                                            onClick={() => {
                                                this.toggle('crypto', 'CT');
                                            }}
                                        >
                                            <NavLink
                                                className={classnames({
                                                    active: this.state.activeTab === 'crypto',
                                                    navlinkForm: true,
                                                    withdrawLink: true,
                                                })}
                                            >
                                                <div className='withdraw-banktransfer'>
                                                    <img src={cryptoIcon} alt='Bank Apps' className={`crypto ${this.state.activeTab === 'crypto' ? 'active' : ''}`} />
                                                </div>
                                                {this.state.withdrawalTab.map((data, index) => (
                                                    <div className='withdraw-options-text'>{data.code === 'CT' && t(data.translation, data.translation)}</div>
                                                ))}
                                            </NavLink>
                                        </NavItem>
                                    )}

                                    {ewalletEnabled && (
                                        <NavItem
                                            className='withdraw-tab-click mobile-withdraw-transfer'
                                            onClick={() => {
                                                this.toggle('ewallet');
                                            }}
                                        >
                                            <NavLink
                                                className={classnames({
                                                    active: this.state.activeTab === 'ewallet',
                                                    navlinkForm: true,
                                                    withdrawLink: true,
                                                })}
                                            >
                                                <div className='withdraw-banktransfer'>
                                                    <img src={qrpay} alt='Bank Apps' className={`ewallet ${this.state.activeTab === 'ewallet' ? 'active' : ''}`} />
                                                </div>
                                                {this.state.withdrawalTab.map((data, index) => (
                                                    <div className='withdraw-options-text'>{data.code === 'R' && t(data.translation, data.translation)}</div>
                                                ))}
                                            </NavLink>
                                        </NavItem>
                                    )}
                                </Nav>
                            </div>

                            <div className='withdraw-item-box'>
                                <TabContent activeTab={this.state.activeTab} className='withdraw-tab-content'>
                                    {/* Online Trans */}
                                    {this.state.activeTab === 'onlinetransfer' && (
                                        <Fragment>
                                            {chkBankDetailSixPinStatus && (
                                                <Suspense fallback={<div></div>}>
                                                    <SetupSixDigitPinNotice module={MT.WITHDRAWAL_BANK} />
                                                </Suspense>
                                            )}

                                            {!chkBankDetailSixPinStatus && (
                                                <>
                                                    <div className='title-item'>
                                                        <span>
                                                            {t('transaction:transaction.withdrawal.bank')} <span className='asterisk'>*</span>
                                                        </span>
                                                    </div>
                                                    {(this.state.memberBankList.length > 0 || isShowUnownedBankList) && (
                                                        <ul className='small-banklist'>
                                                            <li>
                                                                <div className='item'>
                                                                    <p className='deposit-options'></p>
                                                                    {showLoading && <SmallLoading></SmallLoading>}
                                                                    <div className='bank-selector'>
                                                                        {this.state.filteredBankList && (
                                                                            <ul className='bank-list'>
                                                                                {(this.state.filteredBankList || []).map((bank, index) => {
                                                                                    const isActive =
                                                                                        (bank.bankId === this.state.bankActive &&
                                                                                            bank.memberBankId === this.state.memberBankId) ||
                                                                                        (bank.bankId === null && this.state.bankId === null);
                                                                                    return true ? (
                                                                                        <li key={index} className={`small-bank ${isActive ? 'on' : ''}`}>
                                                                                            <div
                                                                                                className='banklist-group member-bankacc'
                                                                                                onClick={() => {
                                                                                                    this.selectBank(bank.bankId, index);
                                                                                                    this.handleBankChange(bank);
                                                                                                }}
                                                                                            >
                                                                                                <label>
                                                                                                    <input
                                                                                                        type='radio'
                                                                                                        name='bank-account'
                                                                                                        onChange={() => {
                                                                                                            this.selectBank(bank.bankId, index);
                                                                                                            this.handleBankChange(bank);
                                                                                                        }}
                                                                                                        checked={isActive ? true : false}
                                                                                                    />
                                                                                                    <span className='checkmark'></span>
                                                                                                    <div className='banklist-group'>
                                                                                                        <div className='group-box'>
                                                                                                            {' '}
                                                                                                            <div className={`bank-img ${isActive ? 'bank-on' : ''}`}>
                                                                                                                {(bank.bankId === this.state.bankActive &&
                                                                                                                    bank.memberBankId === this.state.memberBankId) ||
                                                                                                                (bank.bankId === null && this.state.bankId === null) ? (
                                                                                                                    bank.bankId === null && this.state.bankId === null ? (
                                                                                                                        <SVG
                                                                                                                            src={`/public/html/images/bank_img/otherBank-on.svg`}
                                                                                                                        ></SVG>
                                                                                                                    ) : (
                                                                                                                        <BankIcon bankCode={bank.code} isActive={isActive} />
                                                                                                                    )
                                                                                                                ) : bank.bankId === null ? (
                                                                                                                    <SVG
                                                                                                                        src={`/public/html/images/bank_img/otherBank-off.svg`}
                                                                                                                    ></SVG>
                                                                                                                ) : (
                                                                                                                    <BankIcon bankCode={bank.code} isActive={isActive} />
                                                                                                                )}
                                                                                                            </div>
                                                                                                            <div
                                                                                                                className={`bank-name ${
                                                                                                                    selectedBank &&
                                                                                                                    selectedBank[0] &&
                                                                                                                    selectedBank[0].bankId === bank.bankId
                                                                                                                        ? 'bank-selected'
                                                                                                                        : ''
                                                                                                                }`}
                                                                                                            >
                                                                                                                {bank.bankNameOnly || bank.bankName}
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </label>
                                                                                            </div>
                                                                                        </li>
                                                                                    ) : (
                                                                                        !disableOtherBanks && isShowUnownedBankList && (
                                                                                            <li
                                                                                                key={index}
                                                                                                className='other-bank'
                                                                                                onClick={() => {
                                                                                                    this.selectBank(bank.bankId, index);
                                                                                                    this.handleBankChange(bank);
                                                                                                }}
                                                                                            >
                                                                                                <div
                                                                                                    className='banklist-group member-bankacc'
                                                                                                    onClick={() => {
                                                                                                        this.selectBank(bank.bankId, index);
                                                                                                        this.handleBankChange(bank);
                                                                                                    }}
                                                                                                >
                                                                                                    <label>
                                                                                                        <input
                                                                                                            type='radio'
                                                                                                            name='bank-account'
                                                                                                            onChange={() => {
                                                                                                                this.selectBank(bank.bankId, index);
                                                                                                                this.handleBankChange(bank);
                                                                                                            }}
                                                                                                            checked={
                                                                                                                (bank.bankId === this.state.bankActive &&
                                                                                                                    bank.memberBankId === this.state.memberBankId) ||
                                                                                                                (bank.bankId === null && this.state.bankId === null)
                                                                                                                    ? true
                                                                                                                    : false
                                                                                                            }
                                                                                                        />
                                                                                                        <span className='checkmark'></span>
                                                                                                        <div className='banklist-group'>
                                                                                                            <div className='group-box'>
                                                                                                                {''}
                                                                                                                <div
                                                                                                                    className={`bank-name ${
                                                                                                                        selectedBank &&
                                                                                                                        selectedBank[0] &&
                                                                                                                        selectedBank[0].bankId === bank.bankId
                                                                                                                            ? 'bank-selected'
                                                                                                                            : ''
                                                                                                                    }`}
                                                                                                                >
                                                                                                                    {t('transaction:transaction.withdrawal.otherBanks')}
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </label>
                                                                                                </div>
                                                                                            </li>
                                                                                        )
                                                                                    );
                                                                                })}
                                                                            </ul>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    )}
                                                    <div className='withdraw-item-box'>
                                                        <button className='submit-btn' onClick={() => this._showUnownedBankList()}>
                                                            {t('bank:bank.addBank', 'Add Bank')}
                                                        </button>
                                                    </div>

                                                    <div className={`withdraw-item-box ${this.state.showOtherBankName ? '' : 'd-none'}`}>
                                                        <div className='title-item'>
                                                            <span>
                                                                {t('bank:bank.newBank.bankName')} <span className='asterisk'>*</span>
                                                            </span>
                                                        </div>
                                                        <div className='withdraw-bankName'>
                                                            <input
                                                                name='bankName'
                                                                className={` ${this.state.bankNameErrMsg.length > 0 ? 'is-invalid' : ''}`}
                                                                id='bankName'
                                                                placeholder={t('bank:bank.newBank.bankName')}
                                                                value={this.state.bankName}
                                                                onChange={this.handleBankNameChange}
                                                                onBlur={this.validateBankName}
                                                                disabled={this.state.disabledBankName}
                                                            />
                                                            {this.state.bankNameErrMsg.map((errMsg, index) => {
                                                                return (
                                                                    <div key={index} className='invalid-feedback text-danger'>
                                                                        <Trans i18nKey={errMsg}></Trans>
                                                                    </div>
                                                                );
                                                            })}
                                                        </div>
                                                    </div>
                                                    {showBankBranch && (
                                                        <div className='withdraw-item-box'>
                                                            <div className='title-item'>
                                                                <span>{t('bank:bank.newBank.bankBranch')}</span>
                                                            </div>
                                                            <div className='withdraw-branch'>
                                                                <input
                                                                    name='bankbranch'
                                                                    className={` form-control-inner ${this.state.bankBranchErrMsg.length > 0 ? 'form-contol is-invalid' : ''}`}
                                                                    id='bankbranch'
                                                                    placeholder={t('bank:bank.newBank.bankBranch')}
                                                                    value={this.state.bankBranch}
                                                                    onChange={this.handleBankBranchChange}
                                                                    onBlur={this.validateBankBranch}
                                                                    disabled={this.state.disabledBankBranch}
                                                                />
                                                                {/* {this.state.bankBranchErrMsg.map((errMsg, index) => {
                            return (
                              <div key={index} className='invalid-feedback text-danger'>
                                <Trans i18nKey={errMsg}></Trans>
                              </div>
                            );
                          })} */}
                                                            </div>
                                                        </div>
                                                    )}
                                                    <div className='withdraw-item-box'>
                                                        <div className='title-item'>
                                                            <span>
                                                                {t('bank:bank.newBank.accountName')} <span className='asterisk'>*</span>
                                                            </span>
                                                        </div>
                                                        <div className='withdraw-accname'>
                                                            <input name='account name' value={this.state.accountName} disabled={true} />
                                                        </div>
                                                    </div>

                                                    <div className='withdraw-item-box'>
                                                        <div className='title-item'>
                                                            <span>
                                                                {t('transaction:transaction.withdrawal.accountNumber')} <span className='asterisk'>*</span>
                                                            </span>
                                                        </div>
                                                        <div className='withdraw-accno'>
                                                            <input
                                                                name='accountnumber'
                                                                className={`${this.state.accountNumberErrMsg.length > 0 ? 'is-invalid' : ''}`}
                                                                id='accountnumber'
                                                                type='number'
                                                                placeholder='Exp : 1234567'
                                                                value={this.state.accountNumber}
                                                                onChange={this.handleAccountNumberChange}
                                                                onBlur={this.validateAccountNumber}
                                                                disabled={this.state.disabledAccountNumber}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className='withdraw-item-box'>
                                                        <div className='title-item'>
                                                            <span>
                                                                {t('transaction:transaction.withdrawal.amount')} <span className='asterisk'>*</span>
                                                            </span>
                                                        </div>
                                                        <div className='withdraw-amount'>
                                                            {disableDecimalWithdraw ? (
                                                                <input
                                                                    name='amount'
                                                                    className={`form-control-inner ${
                                                                        this.state.amountErrMsg.length > 0 ? 'form-control-inner is-invalid-custom' : ''
                                                                    }`}
                                                                    id='amountInput'
                                                                    placeholder={t('global:global.form.online-transfer-amount-placeholder', {
                                                                        min: window.SPL_Other.formatAmountNoDecimal(this.state.limit.minLimit),
                                                                        max: window.SPL_Other.formatAmountNoDecimal(this.state.limit.maxLimit),
                                                                    })}
                                                                    type='number'
                                                                    min='0'
                                                                    value={this.state.amount}
                                                                    onChange={this.handleAmountChange}
                                                                    onBlur={this.validateAmount}
                                                                />
                                                            ) : (
                                                                <input
                                                                    name='amount'
                                                                    className={`form-control-inner ${
                                                                        this.state.amountErrMsg.length > 0 ? 'form-control-inner is-invalid-custom' : ''
                                                                    }`}
                                                                    id='amountInput'
                                                                    placeholder={t('global:global.form.online-transfer-amount-placeholder', {
                                                                        min: window.SPL_Other.formatAmount(this.state.limit.minLimit),
                                                                        max: window.SPL_Other.formatAmount(this.state.limit.maxLimit),
                                                                    })}
                                                                    type='number'
                                                                    min='0'
                                                                    value={this.state.amount}
                                                                    onChange={this.handleAmountChange}
                                                                    onBlur={this.validateAmount}
                                                                />
                                                            )}
                                                        </div>

                                                        {this.state.amountErrMsg.map((errMsg, index) => {
                                                            if (errMsg.includes('min_maxAmount')) {
                                                                return (
                                                                    <div key={index} className='invalid-feedback text-danger'>
                                                                        <Trans i18nKey={errMsg}></Trans> {account.currency} {this.state.limit.minLimit} - {account.currency}{' '}
                                                                        {this.state.limit.maxLimit}
                                                                    </div>
                                                                );
                                                            } else {
                                                                return (
                                                                    <div key={index} className='invalid-feedback text-danger'>
                                                                        <Trans i18nKey={errMsg}></Trans>
                                                                    </div>
                                                                );
                                                            }
                                                        })}

                                                        {country === 'VN' && (
                                                            <p className='daily-limit'>
                                                                <span>
                                                                    <span>1 = 1000 đồng</span>
                                                                </span>
                                                            </p>
                                                        )}

                                                        <div className='withdraw-amount-list'>
                                                            <p className='deposit-options'></p>
                                                            <div className='amount-select-btn'>
                                                                {amountOptions?.map((item) => (
                                                                    <button className='btn-amount' onClick={() => this.handleWithdrawAmountChange(item)}>
                                                                        {item}
                                                                    </button>
                                                                ))}
                                                            </div>
                                                        </div>

                                                        <p className='daily-limit'>
                                                            <i className='icon-tip'></i>
                                                            <span>
                                                                {disableDecimalWithdraw ? (
                                                                    <span>
                                                                        {t('transaction:transaction.withdrawal.dailyLimitBalance', 'Daily Limit Balance')} :{' '}
                                                                        {window.SPL_Other.formatAmountNoDecimal(this.state.limit.dailyMaxAmount)} /{' '}
                                                                    </span>
                                                                ) : (
                                                                    <span>
                                                                        {t('transaction:transaction.withdrawal.dailyLimitBalance', 'Daily Limit Balance')} :{' '}
                                                                        {window.SPL_Other.formatAmount(this.state.limit.dailyMaxAmount)} /{' '}
                                                                    </span>
                                                                )}
                                                                {disableDecimalWithdraw ? (
                                                                    <span>
                                                                        {t('transaction:transaction.withdrawal.dailyCountBalance', 'Daily Count Balance')} :{' '}
                                                                        {window.SPL_Other.formatAmountNoDecimal(this.state.limit.maxCount)}{' '}
                                                                    </span>
                                                                ) : (
                                                                    <span>
                                                                        {t('transaction:transaction.withdrawal.dailyCountBalance', 'Daily Count Balance')} :{' '}
                                                                        {window.SPL_Other.formatAmount(this.state.limit.maxCount)}{' '}
                                                                    </span>
                                                                )}
                                                            </span>
                                                        </p>
                                                    </div>

                                                    {/* Bank Statement */}
                                                    {this.renderUploadBankStatementField()}

                                                    {this.state.status !== '' && this.state.status === 'F' ? (
                                                        <div className='invalid-feedback text-danger m-l-5'>{this.state.message}</div>
                                                    ) : this.state.status !== '' && this.state.status === 'S' ? (
                                                        <div className='invalid-feedback text-success  m-l-5'>{this.state.message}</div>
                                                    ) : (
                                                        void 0
                                                    )}

                                                    <div className='withdraw-item-box'>
                                                        <div className='withdraw-submit'>
                                                            <button
                                                                type='submit'
                                                                className='btn-normal btn-withdraw'
                                                                onClick={this.handleSubmit}
                                                                disabled={this.state.disabledButton || (this._isShowUploadBankStatementField() && receiptImg.length <= 0)}
                                                            >
                                                                <span>{t('transaction:transaction.withdrawal.withdraw')}</span>
                                                            </button>

                                                            <span className='tnc-apply' onClick={() => this.getTncReferral()}>
                                                                * {t('slot:slot.tnc-apply', 'Terms & Conditions Applied')}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                        </Fragment>
                                    )}

                                    {/* Crypto */}
                                    {this.state.activeTab === 'crypto' && (
                                        <TabPane tabId='crypto' className='d-flex form-content'>
                                            <Crypto
                                                amountOptions={amountOptions}
                                                type='withdraw'
                                                limit={this.state.cryptoMinmaxLimit}
                                                getTncReferral={this.getTncReferral}
                                                hideAddBankAccount={this.state.hideAddBankAccount}
                                            />
                                        </TabPane>
                                    )}

                                    {/* E-walltet */}
                                    {this.state.activeTab === 'ewallet' && (
                                        <TabPane tabId='ewallet' className='d-flex form-content withdraw-bankTransfer'>
                                            {chkBankDetailEWalletSixPinStatus && (
                                                <Suspense fallback={<div></div>}>
                                                    <SetupSixDigitPinNotice module={MT.WITHDRAWAL_EWALLET} />
                                                </Suspense>
                                            )}

                                            {!chkBankDetailEWalletSixPinStatus && (
                                                <Ewallet
                                                    amountOptions={amountOptions}
                                                    type='ewallet'
                                                    limit={this.state.limit}
                                                    account={account}
                                                    ewalletList={this.state.ewalletList}
                                                    memberBankList={this.state.memberBankList}
                                                    showFirstMemberBank={this.state.showFirstMemberBank}
                                                />
                                            )}
                                        </TabPane>
                                    )}
                                </TabContent>
                            </div>

                            {this.state.getTncReferral && (
                                <li className='messaging-popup mobile-notice'>
                                    <div className='popup notice-box'>
                                        <div className='popup-header'>
                                            <div className='popup-header-left'>
                                                <div className='popup-title'>{t('slot:slot.tnc-apply', 'Terms & Conditions Applied')}</div>
                                            </div>
                                            <div className='popup-header-right'>
                                                <SVGElements name='close-icon' onClick={() => this.getTncReferral()} />
                                                {/* <MdClose onClick={() => this.getTncReferral()} /> */}
                                            </div>
                                        </div>

                                        {transactionDetails && transactionDetails.withdrawal && transactionDetails.withdrawal[key.toLowerCase()] && (
                                            <div className='popup-body'>
                                                <div className='title'>
                                                    <i className='icon-notice-important'></i>
                                                    <span>{transactionDetails.withdrawal[key.toLowerCase()].noteTitle}</span>
                                                </div>
                                                <ul>
                                                    {(transactionDetails.withdrawal[key.toLowerCase()].stepNote || []).map((step, stepIndex) => (
                                                        <li key={step.id}>
                                                            <span>{stepIndex + 1}</span>
                                                            <p>{step.note}</p>
                                                        </li>
                                                    ))}
                                                    <li>
                                                        <p>{transactionDetails.withdrawal[key.toLowerCase()].noteText_1}</p>
                                                    </li>
                                                </ul>
                                            </div>
                                        )}
                                    </div>
                                    <div className='popup-overlay notice-overlay' onClick={() => this.getTncReferral()}></div>
                                </li>
                            )}
                        </div>
                    </div>
                )}
            </Translation>
        );
    }
}

export default connect(mapStateToProps, null)(withTranslation(['transaction', 'bank', 'settings', 'inputHelpBlock'])(withRouter(Withdraw)));
