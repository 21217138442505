import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import SVG from 'react-inlinesvg';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import React from 'react';
import { CMS_BANK_IMAGE_PATH } from '../../utils/constants';

const BankIcon = ({ bankCode, ...props }) => {
    const { isActive } = props;
    const userRedux = useSelector((state) => state.user);
    const currency = userRedux?.account?.currency;
    const screenRedux = useSelector((state) => state.screen);

    const defaultIcon = screenRedux?.isMobile ? '/public/html/images/bank_img/mobile-otherBank-icon.png' : '/public/html/images/bank_img/otherBank-icon.png';
    return (
        <SVG src={`${CMS_BANK_IMAGE_PATH}/${currency}/${bankCode}.svg`}>
            <LazyLoadImage
                alt={bankCode}
                src={`/public/html/images/bank_img/${currency}/${bankCode}${isActive ? '' : '_off'}.png`}
                onError={(e) => {
                    e.target.src = defaultIcon;
                    e.target.onError = null;
                }}
            />
        </SVG>
    );
};

BankIcon.propTypes = {
    bankCode: PropTypes.string.isRequired,
    isSelected: PropTypes.bool,
};

export default BankIcon;

export const CryptoBankIcon = ({ bankCode, coin, isActive }) => {
    const userRedux = useSelector((state) => state.user);
    const currency = userRedux?.account?.currency;

    return (
        <SVG src={`${CMS_BANK_IMAGE_PATH}/${currency}/${bankCode}.svg`}>
            <SVG src={`${CMS_BANK_IMAGE_PATH}/${currency}/${coin}.svg`}>
                <LazyLoadImage src={`/public/html/images/bank_img/${coin}${isActive ? '-on' : ''}.png`} />{' '}
            </SVG>
        </SVG>
    );
};
