import React, { Component } from 'react';
import { Trans, Translation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { mapStateToProps } from '../../../redux/selector/selector';
import { withRouter } from 'react-router-dom';

class ChangePassword extends Component {
    isMount = true;
    constructor(props) {
        super(props);
        this.state = {
            currentpassword: '',
            newpassword: '',
            confirmpassword: '',
            samePassword: false,
            newPasswordErrMsg: [],
            confirmpasswordError: [],
            message: '',
            disabledButton: true,

            passwordReveal: {
                current: false,
                newPassword: false,
                confirmpassword: false,
            },
            showSpecialCharsErrMsg: false,
        };
    }

    toggleShowCurrent = () => {
        this.setState({ hiddenCurrent: !this.state.hiddenCurrent });
    };

    handleCurrentPasswordChange = (e) => {
        const currentpassword = e.target.value;

        this.setState({ currentpassword: currentpassword, message: '' });
        this.valdiate();
    };

    handleNewPasswordChange = (e) => {
        const newpassword = e.target.value;
        if (!this.state.newpassword && newpassword.length > 1) return null;

        this.setState({ newpassword: newpassword }, () => {
            this.validateNewPassword();
            this.valdiate();
        });
    };

    handleConfirmNewPasswordChange = (e) => {
        const confirmpassword = e.target.value;
        if (!this.state.confirmpassword && confirmpassword.length > 1) return null;

        this.setState({ confirmpassword: confirmpassword, message: '' }, () => {
            this.validateConfirmPassword();
            this.valdiate();
        });
    };

    onToogleHideShowPasswordOnClickButton(field) {
        let passwordReveal = this.state.passwordReveal;
        passwordReveal[field] = !passwordReveal[field];
        this.setState({ passwordReveal });
    }

    onPasswordValidationChecking(skipCheckSpecialChars = false) {
        const { newPasswordErrMsg, showSpecialCharsErrMsg } = this.state;

        let status = true;
        let checkErr = (newPasswordErrMsg || []).filter((params) => !params.status && (skipCheckSpecialChars && !showSpecialCharsErrMsg ? !params.specialCharsFormat : true));
        if (checkErr.length > 0) {
            status = false;
        }

        if (checkErr.length > 0 && !skipCheckSpecialChars && !showSpecialCharsErrMsg) {
            this.setState(
                {
                    showSpecialCharsErrMsg: true,
                },
                () => {
                    return status;
                }
            );
        } else {
            return status;
        }
    }

    componentDidMount() {
        this.isMount = true;
        if (this.props.newpassword) {
            this.setState({ newpassword: this.props.newpassword });
        }

        if (this.props.screen.viewType === 'web' && this.props.location.pathname === '/changepassword') {
            this.props.history.push('/myaccount/changepassword');
        } else if (this.props.screen.viewType === 'mobile' && this.props.location.pathname === '/myaccount/changepassword') {
            this.props.history.push('/myaccount/myprofile');
            this.props.history.push('/changepassword');
        }
    }

    componentWillUnmount() {
        this.isMount = false;
    }

    validateNewPassword = () => {
        const { newpassword, currentpassword, confirmpassword } = this.state;

        if (newpassword === currentpassword) {
            this.setState({ newPasswordErrMsg: ['global:global.messages.validate.newpassword.samePassword'] });
            return;
        }

        window.SPL_Register.validatePassword(newpassword, confirmpassword).then((errMsg) => {
            const specialCharError = errMsg?.find((errObj) => errObj?.specialCharsFormat) || null;

            if (specialCharError) {
                this.setState({
                    showSpecialCharsErrMsg: !specialCharError?.status,
                });
            }

            if (this.isMount) {
                this.setState({ newPasswordErrMsg: errMsg }, () => {
                    this.valdiate();
                    if (confirmpassword) this.validateConfirmPassword();
                });
            }
        });
    };

    validateConfirmPassword = () => {
        window.SPL_Register.validateConfirmPassword(this.state.newpassword, this.state.confirmpassword).then((errMsg) => {
            if (this.isMount) {
                this.setState({ confirmpasswordError: errMsg });
                this.valdiate();
            }
        });
    };

    handleSubmit = () => {
        if ((this.state.newPasswordErrMsg.length > 0 && !this.onPasswordValidationChecking()) || this.state.confirmpasswordError.length > 0) {
            return;
        }
        if (this.state.currentpassword.length <= 0 || this.state.confirmpassword.length <= 0 || this.state.newpassword.length <= 0) {
            return;
        }
        // if (this.state.currentpassword === this.state.newpassword) {
        //   this.setState({
        //     message: 'password:password.messages.error',
        //     status: 'F'
        //   });

        //   return;
        // }

        window.SPL_Member.changePassword(this.state.newpassword, this.state.currentpassword).then((data) => {
            if (this.isMount) {
                if (data.msg === 'success') {
                    this.setState(
                        {
                            message: 'password:password.messages.success',
                            status: 'S',
                            currentpassword: '',
                            newpassword: '',
                            confirmpassword: '',
                            newPasswordErrMsg: [],
                        },
                        () => {
                            setTimeout(() => {
                                this.setState({ message: '' });
                            }, 2000);
                        }
                    );
                } else if (data.msg === 'incorrect') {
                    this.setState({
                        message: 'global:global.messages.error.invalidcurrentpass',
                        status: 'F',
                    });
                } else {
                    this.setState({
                        message: 'password:password.messages.error',
                        status: 'F',
                    });
                }
            }
        });
    };

    valdiate = () => {
        const { currentpassword, confirmpassword, newpassword, newPasswordErrMsg, confirmpasswordError } = this.state;

        if (
            currentpassword === '' ||
            confirmpassword === '' ||
            newpassword === '' ||
            newpassword === currentpassword ||
            (newPasswordErrMsg.length > 0 && !this.onPasswordValidationChecking(true)) ||
            confirmpasswordError.length > 0
        ) {
            this.setState({ disabledButton: true });
        } else {
            this.setState({ disabledButton: false });
        }
    };

    render() {
        return (
            <Translation>
                {(t) => (
                    <div className='changepassword'>
                        <h1>{t('settings:settings.profiletab.changepass', 'Change Password')}</h1>
                        <ul>
                            <li className='changepass-item'>
                                <div className='form-changepass'>
                                    <div className='form-item currentPass'>
                                        <div className='top'>
                                            <div className='top-title'>
                                                <span>{t('global:global.form.currentpassword', 'Current Password')}</span>
                                                <span className='asterisk'>*</span>
                                            </div>
                                            <div>
                                                <input
                                                    className=''
                                                    autoComplete='off'
                                                    value={this.state.currentpassword}
                                                    onChange={this.handleCurrentPasswordChange}
                                                    type={this.state.passwordReveal['current'] ? 'text' : 'password'}
                                                />

                                                <img
                                                    className={`standard-password-eye`}
                                                    src={`/public/html/images/icon/${this.state.passwordReveal['current'] ? 'password-show' : 'password-hide'}.png`}
                                                    alt='password-eye.png'
                                                    onClick={() => this.onToogleHideShowPasswordOnClickButton('current')}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className='form-item newPass'>
                                        <div className='top'>
                                            <div className='top-title'>
                                                <span> {t('global.form.newpassword', 'Password')}</span>
                                                <span className='asterisk'>*</span>
                                            </div>
                                            <div>
                                                <input
                                                    className=''
                                                    autoComplete='off'
                                                    value={this.state.newpassword}
                                                    onChange={this.handleNewPasswordChange}
                                                    onBlur={this.validateNewPassword}
                                                    type={this.state.passwordReveal['password'] ? 'text' : 'password'}
                                                />

                                                <img
                                                    className={`standard-password-eye`}
                                                    src={`/public/html/images/icon/${this.state.passwordReveal['password'] ? 'password-show' : 'password-hide'}.png`}
                                                    alt='password-eye.png'
                                                    onClick={() => this.onToogleHideShowPasswordOnClickButton('password')}
                                                />
                                            </div>

                                            {(this.state.newPasswordErrMsg || []).map((errMsg, index) => {
                                                if (!errMsg || (!this.state.showSpecialCharsErrMsg && errMsg.specialCharsFormat)) return null;

                                                return (
                                                    <div
                                                        key={index}
                                                        className={`invalid-feedback change-password-invalid-feedback ${errMsg.status ? 'text-success' : 'text-danger'}`}
                                                    >
                                                        {errMsg.status !== undefined && (
                                                            <img
                                                                className={`change-password-form-error-message-status-img`}
                                                                src={`/public/html/default_whitelabel/shared-image/icons/${errMsg.status ? 'tick' : 'close'}.png`}
                                                                alt='error-status-icon.png'
                                                            />
                                                        )}
                                                        <span>
                                                            {t(errMsg.error || errMsg, {
                                                                min: errMsg.minLength || 6,
                                                                max: errMsg.maxLength || 12,
                                                                specialCharsFormat: errMsg.specialCharsFormat,
                                                            })}
                                                        </span>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>

                                    <div className='form-item confirmNewPass'>
                                        <div className='top'>
                                            <div className='top-title'>
                                                <span>{t('global.form.confirmpassword', 'Confirm Password')}</span>
                                                <span className='asterisk'>*</span>
                                            </div>
                                            <div>
                                                <input
                                                    className=''
                                                    autoComplete='off'
                                                    value={this.state.confirmpassword}
                                                    onChange={this.handleConfirmNewPasswordChange}
                                                    onBlur={this.validateConfirmPassword}
                                                    type={this.state.passwordReveal['confirmPassword'] ? 'text' : 'password'}
                                                />

                                                <img
                                                    className={`standard-password-eye`}
                                                    src={`/public/html/images/icon/${this.state.passwordReveal['confirmPassword'] ? 'password-show' : 'password-hide'}.png`}
                                                    alt='password-eye.png'
                                                    onClick={() => this.onToogleHideShowPasswordOnClickButton('confirmPassword')}
                                                />

                                                {this.state.confirmpasswordError.map((errMsg, index) => {
                                                    return (
                                                        <div key={index} className='invalid-feedback'>
                                                            <Trans i18nKey={errMsg}></Trans>
                                                        </div>
                                                    );
                                                })}
                                                {this.state.status === 'F' && (
                                                    <div className='invalid-feedback'>
                                                        <Trans i18nKey={this.state.message}></Trans>
                                                    </div>
                                                )}
                                                {this.state.status === 'S' && (
                                                    <div className='valid-feedback'>
                                                        <Trans i18nKey={this.state.message}></Trans>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>

                                    <div className='form-item confirmNewPass'>
                                        <div className='top'>
                                            <div className='top-title'></div>
                                            <div>
                                                <button className='btn-normal btn-changepass' type='submit' disabled={this.state.disabledButton} onClick={this.handleSubmit}>
                                                    {t('global:entity.action.save', 'Save')}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                )}
            </Translation>
        );
    }
}

export default connect(mapStateToProps, null)(withTranslation(['password'])(withRouter(ChangePassword)));
