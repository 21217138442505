export const contentAction = [
  {
    id: 'D',
    svgname: 'funddeposit',
    text: 'Deposit',
    route: '/deposit',
    desp: 'home:home.mobile.deposit',
  },
  {
    id: 'T',
    svgname: 'fundtransfer',
    text: 'Transfer',
    route: '/transfer',
    desp: 'home:home.mobile.transfer',
  },
  {
    id: 'W',
    svgname: 'fundwithdraw',
    text: 'Withdraw',
    route: '/withdraw',
    desp: 'home:home.mobile.withdraw',
  },
  {
    id: 'H',
    svgname: 'fundhistory',
    text: 'History',
    route: '/history',
    desp: 'home:home.mobile.history',
  },
];

export const myAccountMobile = [
  {
    id: 'A',
    svgname: 'editprofile',
    text: 'My Profile',
    route: '/profile',
    desp: 'home:home.mobile.account',
  },
  {
    id: 'C',
    svgname: 'changepw',
    text: 'Change Password',
    route: '/changepassword',
    desp: 'home:home.mobile.change-password',
  },

  {
    id: 'G',
    svgname: 'changegamepw',
    text: 'Change Game Password',
    route: '/changegamepassword',
    desp: 'home:home.mobile.change-provider-password',
  },
  {
    id: 'B',
    svgname: 'bankdetail',
    text: 'Bank Detail',
    route: '/bankdetail',
    desp: 'transaction:transaction.deposit.depositBankDetails',
  },
  {
    id: 'M',
    svgname: 'messaging',
    text: 'Messaging',
    route: '/messaging',
    desp: 'home:home.mobile.messaging',
  },
];

export const bannerList = [
  {
    id: 'homebanner1',
    imggame: '/public/html/images/home_img/home-bn-1.jpg',
  },
  {
    id: 'homebanner2',
    imggame: '/public/html/images/home_img/home-bn-additional.jpg',
  },
  {
    id: 'homebanner3',
    imggame: '/public/html/images/home_img/home-bn-cashout.jpg',
  },

  {
    id: 'homebanner4',
    imggame: '/public/html/images/home_img/home-bn-freelive.jpg',
  },
];

export const recomendList = [
  {
    id: 'fafafa',
    imggame: '/public/html/images/customSG/S-LY01.jpg',
    text: 'FaFaFa',
    imgprovider: '/public/html/images/provider_logo/icon-sg.png',
  },
  {
    id: 'junglejim',
    imggame: '/public/html/images/customMG/jungleJimElDorado.jpg',
    text: 'Jungle Jim',
    imgprovider: '/public/html/images/provider_logo/icon-mg.png',
  },

  {
    id: 'luckybomber',
    imggame: '/public/html/images/customGP/luckybomber.jpg',
    text: 'Jungle Jim',
    imgprovider: '/public/html/images/provider_logo/icon-gp.png',
  },

  {
    id: 'triplemonkey',
    imggame: '/public/html/images/customPT/trpmnk.jpg',
    text: 'Triple Monkey',
    imgprovider: '/public/html/images/provider_logo/icon-pt.png',
  },
  {
    id: 'underwaterworld',
    imggame: '/public/html/images/customGP/underwaterworld.jpg',
    text: 'Underwater World',
    imgprovider: '/public/html/images/provider_logo/icon-sa.png',
  },
  {
    id: 'fafafa',
    imggame: '/public/html/images/customSG/S-LY01.jpg',
    text: 'FaFaFa',
    imgprovider: '/public/html/images/provider_logo/icon-sg.png',
  },
  {
    id: 'junglejim',
    imggame: '/public/html/images/customMG/jungleJimElDorado.jpg',
    text: 'Jungle Jim',
    imgprovider: '/public/html/images/provider_logo/icon-mg.png',
  },

  {
    id: 'luckybomber',
    imggame: '/public/html/images/customGP/luckybomber.jpg',
    text: 'Jungle Jim',
    imgprovider: '/public/html/images/provider_logo/icon-gp.png',
  },

  {
    id: 'triplemonkey',
    imggame: '/public/html/images/customPT/trpmnk.jpg',
    text: 'Triple Monkey',
    imgprovider: '/public/html/images/provider_logo/icon-pt.png',
  },
  {
    id: 'underwaterworld',
    imggame: '/public/html/images/customGP/underwaterworld.jpg',
    text: 'Underwater World',
    imgprovider: '/public/html/images/provider_logo/icon-sa.png',
  },
];
export const jackpotList = [
  {
    id: 'fafafa',
    imggame1: '/public/html/images/customSG/S-BF02.jpg',
    textgame1: 'SA Gaming',
    imgprovider1: '/public/html/images/cat_img/cat-casino-logo-sa.png',
    amount1: 'MYR 8,888,888.00',
    imggame2: '/public/html/images/customPT/frtf.jpg',
    textgame2: 'Playtech',
    imgprovider2: '/public/html/images/cat_img/cat-casino-logo-pt.png',
    amount2: 'MYR 8,888,888.00',
    imggame3: '/public/html/images/customGP/goldeneggs.jpg',
    textgame3: '918 Kiss',
    imgprovider3: '/public/html/images/cat_img/cat-casino-logo-918kiss.png',
    amount3: 'MYR 8,888,888.00',
  },
  {
    id: 'fafafa',
    imggame1: '/public/html/images/customSG/S-BF02.jpg',
    textgame1: 'SA Gaming',
    imgprovider1: '/public/html/images/cat_img/cat-casino-logo-sa.png',
    amount1: 'MYR 8,888,888.00',
    imggame2: '/public/html/images/customPT/frtf.jpg',
    textgame2: 'Playtech',
    imgprovider2: '/public/html/images/cat_img/cat-casino-logo-pt.png',
    amount2: 'MYR 8,888,888.00',
    imggame3: '/public/html/images/customGP/goldeneggs.jpg',
    textgame3: '918 Kiss',
    imgprovider3: '/public/html/images/cat_img/cat-casino-logo-918kiss.png',
    amount3: 'MYR 8,888,888.00',
  },
];
export const promoSlider = [
  {
    id: 'promo1',
    image: '/public/html/images/promotion_img/promotion-1.jpg',
    title: 'slidebar:slidebar.promo.item_1_content',
    description: 'Claim your 100% welcome bonus with low turnover!',
    route: '/promotion',
  },
  {
    id: 'promo2',
    image: '/public/html/images/promotion_img/promotion-2.jpg',
    title: 'slidebar:slidebar.promo.item_2_content',
    description: 'Deposit RM100 or above in single ticket!',
    route: '/promotion',
  },

  {
    id: 'promo3',
    image: '/public/html/images/promotion_img/promotion-3.jpg',
    title: 'slidebar:slidebar.promo.item_3_content',
    description: 'Fast approval for deposit and withdrawal!',
    route: '/promotion',
  },
];

export const infoItems = [
  {
    id: '1',
    name: 'affiliate',
    imgprovider: 'affiliate',
    // imgproviderOnHover: '/public/html/images/home_img/home_special_affiliate_ov.png',
    title: 'global:footer.affiliate-program.header',
    text: 'global:footer.joinNow',
    route: '/affiliate',
  },
  {
    id: '2',
    name: '',
    imgprovider: 'vip',
    // imgproviderOnHover: '/public/html/images/home_img/home_special_vip_ov.png',
    title: 'global:footer.vip-program.header',
    text: 'global:footer.applyNow',
    route: '/vip',
  },
  {
    id: '3',
    name: 'promotion',
    imgprovider: 'specialpromo',
    // imgproviderOnHover: '/public/html/images/home_img/home_special_promo_ov.png',
    title: 'global:footer.special-promotion.header',
    text: 'global:footer.playNow',
    route: '/promotion',
  },
  {
    id: '4',
    name: 'contact',
    imgprovider: 'customerservice',
    // imgproviderOnHover: '/public/html/images/home_img/home_special_cs_ov.png',
    title: 'global:footer.customer-service.header',
    text: 'global:footer.chatNow',
    route: '',
  },
  {
    id: '5',
    name: 'livetv',
    imgprovider: 'live',
    // imgproviderOnHover: '/public/html/images/home_img/home_special_live_ov.png',
    title: 'global:footer.live-streaming.header',
    text: 'global:footer.watchNow',
    route: '/livetv',
  },
];

export const euroTeamList = [
  {
    id: '1',
    imgPath: '/public/html/images/home_img/euro-team-austria.png',
    name: 'global:teamnames.AUSTRIA',
  },
  {
    id: '2',
    imgPath: '/public/html/images/home_img/euro-team-belgium.png',
    name: 'global:teamnames.BELGIUM',
  },
  {
    id: '3',
    imgPath: '/public/html/images/home_img/euro-team-croatia.png',
    name: 'global:teamnames.CROATIA',
  },
  {
    id: '4',
    imgPath: '/public/html/images/home_img/euro-team-czechrepublic.png',
    name: 'global:teamnames.CZECHREPUBLIC',
  },
  {
    id: '5',
    imgPath: '/public/html/images/home_img/euro-team-denmark.png',
    name: 'global:teamnames.DENMARK',
  },
  {
    id: '6',
    imgPath: '/public/html/images/home_img/euro-team-england.png',
    name: 'global:teamnames.ENGLAND',
  },
  {
    id: '7',
    imgPath: '/public/html/images/home_img/euro-team-finland.png',
    name: 'global:teamnames.FINLAND',
  },
  {
    id: '8',
    imgPath: '/public/html/images/home_img/euro-team-france.png',
    name: 'global:teamnames.FRANCE',
  },
  {
    id: '9',
    imgPath: '/public/html/images/home_img/euro-team-germany.png',
    name: 'global:teamnames.GERMANY',
  },
  {
    id: '10',
    imgPath: '/public/html/images/home_img/euro-team-italy.png',
    name: 'global:teamnames.ITALY',
  },
  {
    id: '11',
    imgPath: '/public/html/images/home_img/euro-team-netherland.png',
    name: 'global:teamnames.NETHERLAND',
  },
  {
    id: '12',
    imgPath: '/public/html/images/home_img/euro-team-poland.png',
    name: 'global:teamnames.POLAND',
  },
  {
    id: '13',
    imgPath: '/public/html/images/home_img/euro-team-portugal.png',
    name: 'global:teamnames.PORTUGAL',
  },
  {
    id: '14',
    imgPath: '/public/html/images/home_img/euro-team-russia.png',
    name: 'global:teamnames.RUSSIA',
  },
  {
    id: '15',
    imgPath: '/public/html/images/home_img/euro-team-spain.png',
    name: 'global:teamnames.SPAIN',
  },
  {
    id: '16',
    imgPath: '/public/html/images/home_img/euro-team-sweden.png',
    name: 'global:teamnames.SWEDEN',
  },
  {
    id: '17',
    imgPath: '/public/html/images/home_img/euro-team-switzerland.png',
    name: 'global:teamnames.SWITZERLAND',
  },
  {
    id: '18',
    imgPath: '/public/html/images/home_img/euro-team-turkey.png',
    name: 'global:teamnames.TURKEY',
  },
  {
    id: '19',
    imgPath: '/public/html/images/home_img/euro-team-ukraine.png',
    name: 'global:teamnames.UKRAINE',
  },
  {
    id: '20',
    imgPath: '/public/html/images/home_img/euro-team-wales.png',
    name: 'global:teamnames.WALES',
  },
];

export const hotCasino = [
  {
    id: '1',
    imgPath: '/public/html/images/home_img/casino-sa.png',
    imgAlt: 'casino-sa',
    route: '/casino/sa-gaming'
  },
  {
    id: '2',
    imgPath: '/public/html/images/home_img/casino-ug.png',
    imgAlt: 'casino-ug',
    route: '/casino/sexy-baccarat'
  },
  {
    id: '3',
    imgPath: '/public/html/images/home_img/casino-sg.png',
    imgAlt: 'slots-sg',
    route: '/slots/spadeGaming'
  },
  {
    id: '4',
    imgPath: '/public/html/images/home_img/casino-pp.png',
    imgAlt: 'slots-pp',
    route: '/slots/pragmaticPlay'
  },
]

export const liveCasino = [
  {
    id: '1',
    imgPath: '/public/html/images/home_img/home-casino-gd-of.jpg',
    imgAlt: 'casino-golddeluxe',
    route: '/casino/gold-deluxe',
  },
  {
    id: '2',
    imgPath: '/public/html/images/home_img/home-casino-sb-of.jpg',
    imgAlt: 'casino-aesexy',
    route: '/casino/sexy-baccarat',
  },
  {
    id: '3',
    imgPath: '/public/html/images/home_img/home-casino-pt-of.jpg',
    imgAlt: 'casino-playtech',
    route: '/casino/playtech',
  },
  {
    id: '4',
    imgPath: '/public/html/images/home_img/home-casino-allbet-of.jpg',
    imgAlt: 'casino-allbet',
    route: '/casino/allbet',
  },
  {
    id: '5',
    imgPath: '/public/html/images/home_img/home-casino-gp-of.jpg',
    imgAlt: 'casino-gameplay',
    route: '/casino/gameplay',
  },
  {
    id: '6',
    imgPath: '/public/html/images/home_img/home-casino-sa-of.jpg',
    imgAlt: 'casino-sa',
    route: '/casino/sa-gaming',
  },
];

export const Hotgames = [
  {
    id: '1',
    imgPath: '/public/html/images/home_img/home_hot_918kiss.png',
    imgPathHover: '/public/html/images/home_img/home_hot_918kiss-ov.png',
    imgAlt: '918kiss',
    route: '/918kiss',
  },
  {
    id: '2',
    imgPath: '/public/html/images/home_img/home_hot_sg.png',
    imgPathHover: '/public/html/images/home_img/home_hot_sg-ov.png',
    imgAlt: 'slots-spadegaming',
    route: '/slots/spadeGaming',
  },
  {
    id: '3',
    imgPath: '/public/html/images/home_img/home_hot_pp.png',
    imgPathHover: '/public/html/images/home_img/home_hot_pp-ov.png',
    imgAlt: 'slots-pragmaticplay',
    route: '/slots/pragmaticPlay',
  },
  {
    id: '4',
    imgPath: '/public/html/images/home_img/home_hot_sa.png',
    imgPathHover: '/public/html/images/home_img/home_hot_sa-ov.png',
    imgAlt: 'slots-sagaming',
    route: '/slots/saGaming',
  }
];

export const v99HotGames = [
  {
    id: '1',
    imgPath: '/public/html/images/home_img/home_hot_aesexy.jpg',
    imgPathHover: '/public/html/images/home_img/home_hot_aesexy-ov.jpg',
    imgAlt: 'AEsexy',
    route: '/casino/sexy-baccarat',
    name: 'AE SEXY',
  },
  {
    id: '2',
    imgPath: '/public/html/images/home_img/home_hot_dream.jpg',
    imgPathHover: '/public/html/images/home_img/home_hot_dream-ov.jpg',
    imgAlt: 'dream-gaming',
    route: '/casino/dream-gaming',
    name: 'DREAM GAMING',
  },
  {
    id: '3',
    imgPath: '/public/html/images/home_img/home_hot_918kiss.jpg',
    imgPathHover: '/public/html/images/home_img/home_hot_918kiss-ov.jpg',
    imgAlt: '918kiss',
    route: '/918kiss',
    name: '918KISS',
  },
  {
    id: '4',
    imgPath: '/public/html/images/home_img/home_hot_spade.jpg',
    imgPathHover: '/public/html/images/home_img/home_hot_spade-ov.jpg',
    imgAlt: 'slot-spadeGaming',
    route: '/slots/spadeGaming',
    name: 'SPADEGAMING',
  }
];

export const v99HomeSlot = [
  {
    id: '1',
    imgPath: '/public/html/images/home_img/home-slots-sg.jpg',
    imgAlt: 'slot-spadeGaming',
    route: '/slots/spadeGaming',
  },
  {
    id: '2',
    imgPath: '/public/html/images/home_img/home-slots-mega.jpg',
    imgAlt: 'Mega888',
    route: '/mega888',
  }
];

export const SportMenu = [
  {
    id : '1',
    imgPath: '/public/html/images/sports/sport-icon-01.png',
    imgPathHover: '/public/html/images/sports/sport-icon-hover.png',
    imgAlt: 'cmd',
    route: '/cmd368',
    class: 'w-100 index-banner-cmd',
  
  },
  {
    id : '2',
    imgPath: '/public/html/images/sports/sport-icon-03.png',
    imgPathHover: '/public/html/images/sports/sport-icon-hover.png',
    imgAlt: 'mb',
    route: '/home',
    class: 'w-100 index-banner-mb',
  },
  {
    id : '3',
    imgPath: '/public/html/images/sports/sport-icon-02.png',
    imgPathHover: '/public/html/images/sports/sport-icon-hover.png',
    imgAlt: 'sbo',
    route: '/sbo',
    class: 'w-100 index-banner-sbo',
  },
];

export const login_PanelTab = [
  {
    id: 'registerTab',
    name: 'register:register.createAccount',
  },
  {
    id: 'loginTab',
    name: 'register:register.messages.success_register_2', 
  }
]

export const RaffleDraw = [
  {
    id: '1',
    playerName: 'p*b*6*6*6',
    gameName: 'God of Gambler',
    winAmount: '32,042,00',
    rankIcon: '/public/html/images/home_img/096-meda.png',
  },
  {
    id: '2',
    playerName: 'H*H*6',
    gameName: 'FaFaFa',
    winAmount: '12,522,00',
    rankIcon: '/public/html/images/home_img/096-meda.png',
  },
  {
    id: '3',
    playerName: 'P*K*M',
    gameName: 'Monkey',
    winAmount: '8,342,00',
    rankIcon: '/public/html/images/home_img/096-meda.png',
  }
]

export const miniJackpot = [
  {
    id: 1,
    providerImg: '/public/html/images/home_img/096-918kiss_bg.png',
    providerIcon: '/public/html/images/drawer_img/918_menu.png',
    provider: '918Kiss',
  },
  {
    id: 2,
    providerImg: '/public/html/images/home_img/096-sa_bg.png',
    providerIcon: '/public/html/images/home_img/096-sa.png',
    provider: 'SA Gaming',
  },
  {
    id: 3,
    providerImg: '/public/html/images/home_img/096-pt_bg.png',
    providerIcon: '/public/html/images/home_img/096-pt.png',
    provider: 'PT Gaming',
  }
]

export const hotNews1 = ['/public/html/images/home_img/home-news-1.jpg'];
export const hotNews2 = ['/public/html/images/home_img/home-news-2.jpg'];
export const hotMatch1 = ['/public/html/images/home_img/home-matches-1.jpg'];
export const hotMatch2 = ['/public/html/images/home_img/home-matches-2.jpg'];
export const Dice = ['/public/html/images/home_img/Dice.svg'];
export const Fire = ['/public/html/images/home_img/fire.svg'];
// export const jackpotbg = ['/public/html/images/home_img/progressjackpot_en.gif'];
export const contactBg = ['/public/html/images/home_img/contact_dd_50.png'];
export const img24x7 = ['/public/html/images/home_img/contact_dd_247.png'];
export const iconContact1 = ['/public/html/images/home_img/wc.svg'];
export const iconContact2 = ['/public/html/images/home_img/mobile.svg'];
export const iconContact2QR = ['/public/html/images/home_img/qrcode_055_20190425131136.png'];
export const iconContact3 = ['/public/html/images/home_img/whatsapp.svg'];
export const iconContact4 = ['/public/html/images/home_img/livechat.svg'];
export const downloadBg = ['/public/html/images/home_img/download_dd_50.png'];
export const iconAndriod = ['/public/html/images/home_img/android.svg'];
export const iconApple = ['/public/html/images/home_img/apple.svg'];
export const iconPC = ['/public/html/images/home_img/pc.svg'];
export const imgFavourite = ['/public/html/images/home_img/home_favourite.svg'];
export const imgPromo = ['/public/html/images/home_img/home_promo.svg'];
export const announcementBannerLeft = ['/public/html/images/new/announcement_banner_left.jpg'];
export const announcementBannerRight = ['/public/html/images/new/announcement_banner_right.jpg'];
export const announcementIcon = ['/public/html/images/common_img/icon_notice.png'];
export const jackpotBg = ['/public/html/images/home_img/progressive_jackpot.gif'];
export const hottestgame = ['/public/html/images/home_img/home_title_hottest-game.png'];
export const hottestgame_th = ['/public/html/images/home_img/home_title_hottestgame-thai.png'];
export const jackpotTitle = ['/public/html/images/home_img/home_icon_jackpot.png'];
export const miniJackpotBg = ['/public/html/images/home_img/SideBox_Jackpot_Box.jpg'];
export const imgRealtime = ['/public/html/images/home_img/home-info-realtime.jpg'];
export const imgLiveStream = ['/public/html/images/home_img/home-info-free.jpg'];
export const imgTopMatch = ['/public/html/images/home_img/home-info-top.jpg'];
export const Pacor = ['/public/html/images/home_img/home-service-pagcor.jpg'];
export const videoThumbnails = ['/public/html/images/video/poker_thumbnail_1.png'];
export const guideBanner = ['/public/html/images/home_img/home_registration_steps.jpg'];
export const guideThBanner = ['/public/html/images/home_img/home_registration_steps-th.jpg'];
export const hotGameSticker = ['/public/html/images/home_img/sg-game-zeus.png'];
export const newGameSticker = ['/public/html/images/home_img/fw_EN_MY.png'];
export const liveCasinoBanner = ['/public/html/images/casino/casino_logo_gd_2.png'];
export const liveCasinoTitle = ['/public/html/images/home_img/home_icon_livecasino.png'];
export const highlightVideoTittle = ['/public/html/images/home_img/home_icon_highlightvid.png'];
export const liveCasinoPlay = ['/public/html/images/casino/btn_play_en.png'];
export const contactBanner = ['/public/html/images/home_img/contact_dd_speed.png'];
export const imgContact = ['/public/html/images/home_img/contact_dd_icon.png'];
export const imgDownload = ['/public/html/images/home_img/download_dd_icon.png'];
export const merchantLogo = ['/public/html/images/home_img/merchant_logo.svg'];
export const fbMobile = ['/public/html/images/footer/ftr_social_fb_ov.png'];
export const igMobile = ['/public/html/images/footer/ftr_social_ig_ov.png'];
export const ytMobile = ['/public/html/images/footer/ftr_social_yt_ov.png'];
export const twitterMobile = ['/public/html/images/footer/ftr_social_twt_ov.png'];
export const lineMobile = ['/public/html/images/footer/ftr_social_line_ov.png'];
export const pagcorLicense = ['/public/html/images/footer/ftr_reco_pagcor_of.png'];
export const pagcorMobile = ['/public/html/images/footer/pagcor_mobile.png'];
export const icon18only = ['/public/html/images/footer/18_only.png'];
export const playTechLogo = ['/public/html/images/home_img/playtech-logo.svg'];
export const Scr918 = ['/public/html/images/home_img/918kiss-logo.png'];
export const saLogo = ['/public/html/images/home_img/sa-logo.png'];
export const homeUEFA = ['/public/html/images/home_img/home-icon-euro.png'];
export const register = ['/public/html/images/home_img/home_title_register.png'];
export const register_thai = ['/public/html/images/home_img/home_title_register-thai.png'];
export const bankAcc_thai = ['/public/html/images/home_img/home_title_bank-acc-thai.png'];
export const registerBg = ['/public/html/images/home_img/home_register_bg.png'];
export const Pagcor = ['/public/html/images/home_img/home-service-pagcor.png'];
export const homeSlot = ['/public/html/images/home_img/home_title_slot-game.png'];
export const homeSlot_th = ['/public/html/images/home_img/home_title_slot-game-thai.png'];
export const s99_thai = ['/public/html/images/home_img/title-sobet.png'];
export const bankAcc = ['/public/html/images/home_img/title-bank.png'];

//bodabet
export const liveMatchTitle = ['/public/html/images/home_img/home_icon_hotmatch.png'];
export const hotgametitle = ['/public/html/images/home_img/home_icon_hotgames.png'];
export const liveMatchBg = ['/public/html/images/home_img/home-soccer-matches-bg.jpg'];
export const serviceadvTitle = ['/public/html/images/home_img/home_icon_serviceadv.png'];
export const infoTitle = ['/public/html/images/home_img/home_icon_infocenter.png'];
export const memberservTitle = ['/public/html/images/home_img/home_icon_memberservice.png'];
export const homebtmBanner = ['/public/html/images/home_img/home-bottom-banner.jpg'];

//cow88
export const slotsBg = ['/public/html/images/home_img/home-banner-slot.jpg'];
export const promoBg = ['/public/html/images/home_img/home-banner-deposit.jpg'];
export const birthdayBg = ['/public/html/images/home_img/home-banner-promo.jpg'];

//simba88
export const c7b_liveCasinoTitle = ['/public/html/images/home_img/home_icon_livecasino.png'];
export const c7b_liveMatchTitle = ['/public/html/images/home_img/home_icon_hotmatch.png'];
export const liveTvTitle = ['/public/html/images/home_img/home_icon_livetv.png'];
export const liveTvPlyBtn = ['/public/html/images/home_img/home_livetv_play.png'];
export const announcementImgLeft = ['/public/html/images/home_img/announcement-left.png'];
export const announcementImgRight = ['/public/html/images/home_img/announcement-right.png'];

export const love = ['/public/html/images/home_img/096-love.png'];
export const providerSG = ['/public/html/images/home_img/096-sg.png'];
export const providerPT = ['/public/html/images/home_img/096-pt.png'];
export const providerTTG = ['/public/html/images/home_img/096-ttg.png'];
export const providerGP = ['/public/html/images/home_img/096-gp.png'];
export const providerSA = ['/public/html/images/home_img/096-sa.png'];
export const providerPP = ['/public/html/images/home_img/096-pp.png'];
export const providerAG = ['/public/html/images/home_img/096-ag.png'];
export const providerMG = ['/public/html/images/home_img/096-mg.png'];
export const providerPG = ['/public/html/images/home_img/096-pg.png'];
export const countdownBg = ['/public/html/images/home_img/096-countdown_bg.png'];
export const angle = ['/public/html/images/home_img/096-angle.png'];