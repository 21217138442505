import React, { Component } from 'react';
import { Col, ModalBody, Row } from 'reactstrap';

class HistoryDetailsModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }

  render() {
    const { isOpen, closeModal, historyItems, t } = this.props;
    let openClass = isOpen ? `open` : ``;
    if (isOpen) {
      return (
        <div>
          <div id='open-modal' className={`modal-window ${openClass}`}>
            <div
              className='background-overlay d-flex justify-content-center align-items-center'
              onClick={() => this.setState({ isOpen: false })}></div>
            <div className='modal-container'>
              <div className='modal-lg'>
                <div className='modal-header w-100'>
                  <Row className='w-100'>
                    <Col xs='10' sm='10' className='d-flex align-items-center'>
                      <div className='d-block'>
                        <div className='text-nowrap'> {t('home:home.mobile.history')}</div>
                      </div>
                    </Col>
                    <Col xs='2' sm='2'>
                      <button className='close' onClick={() => closeModal()}>
                        <span className='inner-cancel'>x</span>
                      </button>
                    </Col>
                  </Row>
                </div>
                <ModalBody>
                  <div>
                    {historyItems.map((historyItem, index) => {
                      if (historyItem.rewardType && historyItem.rewardType === 'ANGPOW') {
                        return (
                          <Row key={index} className='d-flex align-items-center border-bottom p-10 m-l-0 m-r-0'>
                            <Col className='history-label d-flex align-items-center'>{historyItem.label}</Col>
                            <Col className=' h5 w-100'>{`${historyItem.currency} ${historyItem.detail}`}</Col>
                          </Row>
                        );
                      } else if (historyItem.rewardType && historyItem.rewardType === 'FREE_SPIN') {
                        return (
                          <Row key={index} className='d-flex align-items-center border-bottom p-10 m-l-0 m-r-0'>
                            <Col className='history-label d-flex align-items-center'>{historyItem.label}</Col>
                            <Col className='h5 w-100'>{` ${historyItem.detail}`}</Col>
                          </Row>
                        );
                      } else if (historyItem.rewardType) {
                        return <Row key={index} className='hide'></Row>;
                      } else {
                        return (
                          <Row key={index} className='d-flex align-items-center border-bottom p-10 m-l-0 m-r-0'>
                            <Col className='history-label d-flex align-items-center'>{historyItem.label}</Col>
                            <Col className='h5 w-100'>{historyItem.detail}</Col>
                          </Row>
                        );
                      }
                    })}
                  </div>
                </ModalBody>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return <div></div>;
    }
  }
}

export default HistoryDetailsModal;
