import React from 'react';
import { Translation, withTranslation } from 'react-i18next';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import { mapStateToProps } from 'root/redux/selector/selector';

class AnnouncementPopup extends React.Component {
    props = null;

    constructor(props) {
        super();
        this.props = props;
        this.state = {
            slideValue: "0",
            slideMaxValue: "100",
            debouncedInputMS: 1000,
            showSliderCaptcha: this.props.showSliderCaptcha
        };
    }

    componentDidMount() {
    }

    componentWillUnmount() {
        this.setState = () => {
            return;
        };
    }

    sliderRecaptchaOnChange(e) {
        if (e.target && e.target.value) {
            this.setState({ slideValue: e.target.value })
        }
    }

    sliderRecaptchaOnTouchEnd() {
        const { slideMaxValue } = this.state;
        
        let _slideValue = this.state.slideValue;
        if (_slideValue === slideMaxValue) {
            this.closeRecaptchaPopUp();
        } else {
            let sliderInterval;
            sliderInterval = setInterval(()=> {
                if (_slideValue > 0) {
                    _slideValue--;
                    this.setState({ slideValue: _slideValue })
                } else if (_slideValue === 0) {
                    clearInterval(sliderInterval);
                    this.setState({ slideValue: _slideValue })
                }
            }, 1);
        }
    }

    closeRecaptchaPopUp() {
        this.setState({ showSliderCaptcha: false});
        this.props.triggerV3token();
    }

    render() {
        return (
            <Translation>
                {(t) => (
                    <Modal
                        overlayClassName='m-standard-popup-overlay m-slider-recaptcha-popup-overlay'
                        className='standard-popup-modal-container slider-recaptcha-modal-container'
                        isOpen={this.state.showSliderCaptcha}
                        shouldCloseOnOverlayClick={false}
                        shouldCloseOnEsc={false}
                        ariaHideApp={false}
                        onRequestClose={() => this.closeRecaptchaPopUp()}
                    >
                        <div className='standard-popup-modal-header slideHeader'>
                            <span className='standard-modal-title'>{t('register:register.form.slideCaptcha', 'Slide to continue')}</span>
                        </div>
                        <div className='standard-popup-modal-body slider-recaptcha'>
                            <input type='range' min='0' max={this.state.slideMaxValue} value={this.state.slideValue} 
                                onChange={(e) => this.sliderRecaptchaOnChange(e)}
                                onTouchEnd={() => this.sliderRecaptchaOnTouchEnd()}
                            />
                            <span>{t('register:register.form.slideCaptcha', 'Slide to continue')}</span>
                        </div>
                        </Modal>
                )}
            </Translation>
        );
    }
}

export default connect(mapStateToProps)(withTranslation(['register'])(AnnouncementPopup));
