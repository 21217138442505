// library
import React from 'react';
import { connect } from 'react-redux';
import { mapStateToProps } from 'root/redux/selector/selector';
import { withRouter } from 'react-router-dom';

// selector / component
import NotificationList from './notification-list';

// controller / util
import vm from './floating-notification.controller';
import commonUtil from 'root/utils/common-util';

class FloatingNotification extends React.Component {
    constructor() {
        super();
        vm.init(this);
    }

    componentDidMount() {
        vm.initFloatingNotificationData(true);
        commonUtil.loadCustomTranslation('floatingNotification', this);
    }

    componentDidUpdate(prevProps) {
        if (
            prevProps.floatingNotification.beforeLoginNotification !== this.props.floatingNotification.beforeLoginNotification ||
            prevProps.floatingNotification.afterLoginNotification !== this.props.floatingNotification.afterLoginNotification ||
            prevProps.floatingNotification.closeNotification !== this.props.floatingNotification.closeNotification ||
            prevProps.location.pathname !== this.props.location.pathname
        ) {
            vm.initFloatingNotificationData();
        }
    }

    render() {
        const { floatingNotificationList, closedFloatingNotification, customTranslationObj } = this.state;
        return (
            <div>
                {floatingNotificationList &&
                    floatingNotificationList.map((data, index) => {
                        return (
                            <NotificationList
                                key={index}
                                notificationList={data?.floatingNotifications}
                                filterNotificationContent={vm.filterNotificationContent}
                                closeNotification={vm.closeNotification}
                                notificationType={data.displayNotification}
                                notificationClick={vm.notificationClick}
                                displayFrequencyType={data?.displayFrequencyType}
                                closedFloatingNotification={closedFloatingNotification}
                                customTranslationObj={customTranslationObj}
                            />
                        );
                    })}
            </div>
        );
    }
}

export default connect(mapStateToProps)(withRouter(FloatingNotification));
