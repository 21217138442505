import React, { Fragment } from 'react';
import { Translation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Col, ModalBody, ModalFooter, Modal, ModalHeader, Row } from 'reactstrap';
import { mapStateToProps } from '../../redux/selector/selector';
import { userAction } from '../../redux/action';
import { withRouter } from 'react-router-dom';
import { MdClose } from 'react-icons/md';
import SVGElements from 'root/components/SVGElements';

class HomeAnnoucementModal extends React.Component {
    isMount = true;
    constructor(props) {
        super();
        this.state = {
            isMount: true,
            AnnoucementList: [],
            modal: false,
            isDontShow: false,
            popupVersion: null,
            locationState: false,
        };
        this.toggleClose = this.toggleClose.bind(this);
    }

    componentDidMount() {
        this.isMount = true;
        setTimeout(() => {
            this.getAnnoucementHome();
        }, 500);
        if (this.props.location.state && this.props.location.state.disableHomeAnnouncement) {
            this.setState({ locationState: this.props.location.state.disableHomeAnnouncement }, () => {
                this.props.history.push({ state: { disableHomeAnnouncement: false } });
            });
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.location.pathname !== this.props.location.pathname) {
            this.getAnnoucementHome();
        }
    }

    toggleClose() {
        const popupVersion = this.state.popupVersion;
        const list = this.state.AnnoucementList;
        const isDontShow = this.state.isDontShow;
        const { countryLanguageKey } = this.props.language;

        if (popupVersion === 1) {
            if (isDontShow) {
                window.SPL_Content.setHideAnnouncement(list);
            }
        } else if (popupVersion === 2) {
            let announcement = list[0];
            if (isDontShow) {
                announcement.frequencyType = 'once';
            }
            window.SPL_Content.setHideImgAnnouncement(announcement.id, announcement.frequencyType, announcement.frequency, countryLanguageKey);
        }
        this.setState({ modal: false });
    }

    getAnnoucementHome() {
        const { countryLanguageKey } = this.props.language;
        const { isLogin } = this.props.user;
        const { viewType } = this.props.screen;

        let page = '';
        if (this.props.location.pathname.includes('/home')) {
            page = 'Home';
        } else if (this.props.location.pathname.includes('/deposit')) {
            page = 'Deposit';
        } else if (this.props.location.pathname.includes('/withdraw')) {
            page = 'Withdrawal';
        }

        let screen = '';
        if (viewType === 'web') {
            screen = 'desktop';
        } else {
            screen = 'mobile';
        }

        window.SPL_Content.getAnnoucementListWithType(isLogin, countryLanguageKey, page, screen).then((data) => {
            if (data) {
                let popUpList = data.announcementsWithPopUpDisplay;

                if (this.isMount) {
                    if (popUpList.length > 0) {
                        this.setState({ AnnoucementList: popUpList, modal: true, popupVersion: data.popupVersion });
                    }
                }
            }
        });
    }

    dontShow = (e) => {
        let check = e.target.checked;
        if (check === 'true') {
            this.setState({ isDontShow: !this.state.isDontShow });
        } else {
            this.setState({ isDontShow: !this.state.isDontShow });
        }
    };

    checkDontShow() {
        this.setState({ modal: false });
        let check = this.state.isDontShow;
        let list = this.state.AnnoucementList;
        if (check === true) {
            window.SPL_Content.setHideAnnouncement(list);
        }
    }

    onDontShowAgainClick = (e) => {
        e.stopPropagation();
        let isDontShow = this.state.isDontShow;
        isDontShow = !isDontShow;
        this.setState({ isDontShow: isDontShow });

        if (e.target.tagName.toLowerCase() !== 'input') {
            // Check if the clicked element is not an input element (checkbox)
            if (this.state.announcementList[0] && this.state.announcementList[0].href) {
                window.open(this.state.AnnoucementList[0].href, '_blank');
            }
        }
    };

    announcementHrefClick(data) {
        if (!data || data.length === 0 || !data[0].href || !data[0].hrefType) {
            return;
        }
        const { language } = this.props;
        const pathLang = language?.countryLanguageKey?.toLowerCase().replace('_', '-');
        const { href, hrefType } = data[0];

        if (hrefType === 'new') {
            window.open(href, '_blank');
        } else if (hrefType === 'current') {
            let redirectLink = href;
            const domainPath = `${window.location.origin}/${pathLang}`;

            if (redirectLink.startsWith(domainPath)) {
                // Remove the domain path if it's part of the redirect link
                redirectLink = redirectLink.replace(domainPath, '');
            }
            window.location.replace(redirectLink);
        }
    }

    render() {
        return (
            <Translation>
                {(t) =>
                    this.state.modal &&
                    !this.state.locationState && (
                        <div className='messaging-popup'>
                            <div className='popup'>
                                {this.state.popupVersion === 1 ? (
                                    <Fragment>
                                        {/* ****************************** Modal Header ******************************** */}
                                        <div className='popup-header'>
                                            <div className='popup-header-left'>
                                                <div className='popup-title'>{t('global:global.announcement2', 'Annoucement')}</div>
                                            </div>
                                            <div className='popup-header-right'>
                                                <SVGElements name='close-icon' onClick={this.toggleClose} />
                                                {/* <MdClose onClick={this.toggleClose} /> */}
                                            </div>
                                        </div>
                                        {/* ************************** Modal Body *************************** */}
                                        <div className='popup-body'>
                                            {this.state.AnnoucementList.map((annoucement) => {
                                                return (
                                                    <Fragment key={annoucement.id}>
                                                        {this.props.user.isLogin && annoucement.isLogin === '1' ? (
                                                            <div className='annoucement-content'>
                                                                <div dangerouslySetInnerHTML={{ __html: annoucement.contentLanguage }}></div>
                                                            </div>
                                                        ) : (
                                                            !this.props.user.isLogin &&
                                                            annoucement.isLogin === '0' && (
                                                                <div className='annoucement-content'>
                                                                    <div dangerouslySetInnerHTML={{ __html: annoucement.contentLanguage }}></div>
                                                                </div>
                                                            )
                                                        )}
                                                    </Fragment>
                                                );
                                            })}
                                        </div>
                                        {/* ************************** Modal Footer *************************** */}
                                        <div className='popup-footer'>
                                            <div className='annoucement-footer'>
                                                <div className='annoucement-footer-left'>
                                                    <input id='dontShow' type='checkbox' className='pointer' onChange={(event) => this.dontShow(event)}></input>
                                                    <label htmlFor='dontShow'>{t('global:global.dontshowagain', `Don't show again`)}</label>
                                                </div>

                                                <button type='submit' onClick={() => this.checkDontShow()} className='btn-normal btn-submit-popup'>
                                                    <span>{t('global:global.verification.close', 'Close')}</span>
                                                </button>
                                            </div>
                                        </div>
                                    </Fragment>
                                ) : (
                                    this.state.popupVersion === 2 && (
                                        <div className='img-popup'>
                                            <SVGElements name='close-icon' onClick={this.toggleClose} />
                                            {/* <MdClose onClick={this.toggleClose} /> */}
                                            <div className='popup-img-container'>
                                                <picture>
                                                    <source srcSet={this.state.AnnoucementList[0].webPUrl} />
                                                    <img
                                                        className='popup-img'
                                                        src={this.state.AnnoucementList[0].src}
                                                        alt=''
                                                        onClick={() => this.announcementHrefClick(this.state.AnnoucementList)}
                                                    />
                                                    <div className='btn-item'>
                                                        <div className='dont-show-again'>
                                                            <input
                                                                type='checkbox'
                                                                value={this.state.isDontShow}
                                                                onClick={(e) => {
                                                                    this.onDontShowAgainClick(e);
                                                                }}
                                                            />
                                                            <span>{t('global:global.dontshowagain', `Don't show again`)}</span>
                                                        </div>
                                                    </div>
                                                </picture>
                                            </div>
                                        </div>
                                    )
                                )}
                            </div>
                            <div className='popup-overlay' onClick={this.toggleClose}></div>
                        </div>
                    )
                }
            </Translation>
        );
    }
}

export default connect(mapStateToProps)(withTranslation(['promotion', 'settings'])(withRouter(HomeAnnoucementModal)));
