import th from 'date-fns/locale/th';
import zhCN from 'date-fns/locale/zh-CN';
import moment from 'moment';
import 'rc-datetime-picker/dist/picker.css';
import React, { Component, Fragment } from 'react';
import { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Trans, Translation, withTranslation } from 'react-i18next';
import { AiOutlineCopy } from 'react-icons/ai';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import SmallLoading from '../../components/Loading/SmallLoading';
import Popup from '../../components/Modal/Popup';
import { mapStateToProps } from '../../redux/selector/selector';
import depositMaterial from './deposit-method';
import SVGElements from 'root/components/SVGElements';
import commonUtil from '../../utils/common-util';
import { trans_handlePGDepositCountDown, trans_getDepositAmountOptionByMethod } from 'root/utils/transaction-util';
import notification from 'root/utils/notification';
import { AMOUNT_DEPOSIT_METHOD_CODE } from 'root/utils/constants';
import BankIcon from '../../components/Molecules/bank-icon';
import { scrollToDepositStep2 } from './deposit-util';

registerLocale('th', th);
registerLocale('zhCN', zhCN);

class BankApps extends Component {
    isMount = true;
    constructor(props) {
        super(props);
        let currentDate = new Date();
        let currentDateTime = moment(Date.now()).format('YYYY-MM-DD hh:mm a');
        const disablePreselectBank = !!this.props.portal?.settings?.features?.depositSettings?.disablePreselectBank;

        this.state = {
            activeTab: 'bankApps',
            iconLoading: false,
            depositDetails: null,
            selectedMethod: [],
            selectedBank: [],
            amount: '',
            amountErrMsg: [],
            disabledDepositButton: true,
            limitAmount: '',
            currency: '',
            referenceCode: '',
            depositOptions: [],
            bankList: [],
            bankNameErrMsg: [],
            selectedDepositChannel: [],
            receiptImg: [],
            depositDate: moment(currentDateTime, 'YYYY-MM-DD hh:mm a'),
            // depositDateString: moment(currentDateTime, 'YYYY-MM-DD hh:mm a').format('YYYY-MM-DDTHH:mm'),
            selectedDepositBank: null,
            radioValue: disablePreselectBank ? null : 0,
            status: '',
            message: '',
            moment: moment(),
            histories: [],
            start: new Date(new Date().setMonth(currentDate.getMonth() - 1)),
            end: currentDate,
            showLoading: false,
            isLoading: false,
            loading: false,
            // merchantBankList: [],
            thisDate: new Date(),
            language: '',
            visible: false,
            bankAppBankList: [],
            receiptMandatoryBankCodes: [],
            requiredReceiptForOnlineBanking: false,
            getTncReferral: false,
            depositNotice: '',

            depositAllSettings: this.props.depositAllSettings,
            filteredDepositApiJson: this.props.filteredDepositApiJson,
            depositMinMaxLimit: null,
            depositSubmitCountdown: null,
            depositAmountOptions: trans_getDepositAmountOptionByMethod(this.props.depositAmountOptions, AMOUNT_DEPOSIT_METHOD_CODE.BA),
            customTranslationObj: {},
        };
    }

    initCompSettings() {
        const { filteredDepositApiJson } = this.state;
        const paymentCode = filteredDepositApiJson && filteredDepositApiJson.optionCode;

        return new Promise((resolve) => {
            const depositSettings = commonUtil.getSettingFromPortalSettings({
                vm: this,
                settingName: 'depositSettings',
            });

            const compSettings = {
                customCountdown: depositSettings?.customCountdown,
            };

            this.setState({ compSettings }, () => {
                trans_handlePGDepositCountDown(this, false, compSettings.customCountdown, paymentCode, Date.now().valueOf(), { disabledDepositButton: true });
                resolve();
            });
        });
    }

    setDepositOptions() {
        const { t } = this.props;
        let depositOptions = [
            {
                label: t('settings:settings.dropdown.bankApps'),
                value: 'BA',
            },
        ];

        this.setState({ depositOptions: depositOptions });
    }

    handleDepositChannelChange = (value) => {
        this.setState({ selectedDepositChannel: value }, () => {
            this.checkError();
        });
    };

    handleReceiptImageChange = (event) => {
        if (event && event.target && event.target.files.length > 0) {
            this.setState(
                {
                    receiptImg: URL.createObjectURL(event.target.files[0]),
                    receiptImgFile: event.target.files[0],
                },
                () => {
                    if (this.state.receiptImg.length > 0) {
                        this.setState({
                            status: '',
                            message: '',
                            visible: false,
                        });
                    }

                    this.checkError();
                },
            );
        }
    };

    handlereferenceCodeChange = (event) => {
        this.setState({ referenceCode: event.target.value }, () => {
            this.checkError();
        });
    };

    onDepositBankAcc = (index) => {
        const oldSelectedDepositBank = this.state.selectedDepositBank;
        this.setState(
            {
                selectedDepositBank: this.state.bankAppBankList[index],
                radioValue: index,
            },
            () => {
                const {
                    enableScrollToDepositStep2 = false,
                    scrollToDepositStep2Attempts = 15,
                    scrollToDepositStep2Delay = 200,
                } = this.props.portal?.settings?.features?.depositSettings || {};

                scrollToDepositStep2(
                    enableScrollToDepositStep2,
                    oldSelectedDepositBank,
                    this.state.selectedDepositBank,
                    scrollToDepositStep2Attempts,
                    scrollToDepositStep2Delay,
                );

                commonUtil.getDepositMinMax(this, 'BA', this.props.user.account.currency, this.state.selectedDepositBank.id);
                this.checkError();
            },
        );
    };

    componentDidMount() {
        this.initCompSettings();
        this.setDepositOptions();
        this.loadDepositDetails();
        // this.loadBankAccounts();
        // this.loadBankAccountsForDeposit();
        this.checkCountryLanguage();
        this.loadBankAppsSettingsAndData();
        this.loadAdditionalDepositSettings();
        this.getDepositDetails();
        commonUtil.loadCustomTranslation('bank-apps', this);
    }

    componentWillUnmount() {
        this.isMount = false;
    }

    // componentDidUpdate(prevProps) {
    //   if (prevProps != this.props) {
    //     this.loadBankAccounts();
    //   }
    // }

    getDepositDetails = () => {
        window.SPL_Transaction.loadDepositRelatedSettings().then((data) => {
            if (data) {
                this.setState({
                    depositNotice: data.depositJson.onlinetransfer.custom_article,
                });
            }
        });
    };

    checkCountryLanguage = () => {
        if (this.props.language.countryLanguageKey === 'TH_TH') {
            this.setState({ language: 'th' });
        } else if (this.props.language.countryLanguageKey === 'ZH_MY') {
            this.setState({ language: 'zhCN' });
        } else {
            this.setState({ language: '' });
        }
    };

    loadDepositDetails = () => {
        this.setState({ isLoading: true });
        const { account } = this.props.user;
        const { filteredDepositApiJson } = this.state;
        let currency = '';
        let activeTab = 'bankApps';

        if (account) {
            currency = account.currency;
        }

        // if (filteredDepositApiJson) {
        //     this.getCorrectDepositMinMaxLimit();
        // } else {
        // selectedMethod dont know for what purpose, because is quick-pay data lai de
        if (Object.keys(depositMaterial.depositDetails).length > 0) {
            this.setState(
                {
                    depositDetails: depositMaterial.getDepositDetails(),
                    selectedMethod: [depositMaterial.getDepositDetails().defaultMethod],
                    isLoading: false,
                    activeTab: activeTab,
                },
                () => {
                    this.validateDepositAmount();
                },
            );
        } else {
            window.SPL_Transaction.loadDepositDetails(currency, this.state.activeTab, false).then((depositDetails) => {
                if (this.isMount) {
                    this.setState(
                        {
                            depositDetails: depositDetails,
                            selectedMethod: [depositDetails.defaultMethod],
                            isLoading: false,
                            activeTab: activeTab,
                        },
                        () => {
                            this.validateDepositAmount();
                            depositMaterial.setDepositDetails(depositDetails);
                        },
                    );
                }
            });
        }
        // }
    };

    getCorrectDepositMinMaxLimit = () => {
        const { depositAllSettings } = this.state;
        const currency = this.props.user.account.currency;
        let _depositMinMaxLimit = { min: 0, max: 0 };

        // using online transfer minmax
        if (depositAllSettings && depositAllSettings.depositJson && depositAllSettings.depositJson.onlinetransfer && depositAllSettings.depositJson.onlinetransfer[currency]) {
            let depositLimit = depositAllSettings.depositJson.onlinetransfer[currency];
            _depositMinMaxLimit.minLimit = depositLimit.min;
            _depositMinMaxLimit.maxLimit = depositLimit.max;
        }
        this.setState({ depositMinMaxLimit: _depositMinMaxLimit });
    };

    loadBankAppsSettingsAndData = () => {
        this.setState({ loading: true });

        const { filteredDepositApiJson } = this.state;
        const disablePreselectBank = !!this.props.portal?.settings?.features?.depositSettings?.disablePreselectBank;
        if (filteredDepositApiJson && filteredDepositApiJson.channel && Object.values(filteredDepositApiJson.channel) && Object.values(filteredDepositApiJson.channel)[0]) {
            // always get first one channel, because bank transfer currently only has 1 channel
            let _bankAppBankList = Object.values(filteredDepositApiJson.channel)[0];
            for (let i in _bankAppBankList) {
                _bankAppBankList[i].bankAppsPhone = _bankAppBankList[i].phoneNumber;
                _bankAppBankList[i].accountHolder = _bankAppBankList[i].accountName;
            }
            _bankAppBankList = this.mapBankImg(_bankAppBankList);
            depositMaterial.setBankAppData(_bankAppBankList);
            this.setState(
                {
                    bankAppBankList: _bankAppBankList,
                    selectedDepositBank: disablePreselectBank ? null : _bankAppBankList[this.state.radioValue],
                    loading: false,
                },
                () => {
                    commonUtil.getDepositMinMax(this, 'BA', this.props.user.account.currency, this.state.selectedDepositBank?.id);
                },
            );
        } else {
            if (this.props.user.account && this.props.user.account.currency) {
                const { currency } = this.props.user.account;

                if (depositMaterial.bankAppData.length > 0) {
                    this.setState(
                        {
                            bankAppBankList: depositMaterial.getBankAppData(),
                            selectedDepositBank: disablePreselectBank ? null : depositMaterial.getBankAppData()[this.state.radioValue],
                            loading: false,
                        },
                        () => {
                            commonUtil.getDepositMinMax(this, 'BA', this.props.user.account.currency, this.state.selectedDepositBank?.id);
                        },
                    );
                } else {
                    window.SPL_Transaction.loadBankAppsSettingsAndData(currency).then((bankListRes) => {
                        if (this.isMount) {
                            bankListRes.bankAppBankList = this.mapBankImg(bankListRes.bankAppBankList);
                            this.setState(
                                {
                                    bankAppBankList: bankListRes.bankAppBankList,
                                    selectedDepositBank: disablePreselectBank ? null : bankListRes.bankAppBankList[this.state.radioValue],
                                    loading: false,
                                },
                                () => {
                                    depositMaterial.setBankAppData(bankListRes.bankAppBankList);
                                    commonUtil.getDepositMinMax(this, 'BA', this.props.user.account.currency, this.state.selectedDepositBank?.id);
                                },
                            );
                        }
                    });
                }
            }
        }
    };

    mapBankImg = (banks) => {
        let currency = null;
        if (this.props.user && this.props.user.account) {
            currency = this.props.user.account.currency;
        }

        if (currency && banks && banks.length > 0) {
            for (let i = 0; i < banks.length; i++) {
                const bank = banks[i];
                let bankCode = bank.bankCode && bank.bankCode;
                bankCode = bank.code && bank.code;
                bank.imgSVGName = bankCode + '_' + currency;
                // bank.imgOn = window.SPL_Content.mapBankAppsHoverImg(bank.bankCode, currency, true);
                // bank.imgOff = window.SPL_Content.mapBankAppsHoverImg(bank.bankCode, currency, false);
            }
        }

        return banks;
    };

    // isBankUnderMaintenanceCheck = (start, end) => {
    //   if (start === null) {
    //     return false;
    //   }

    //   let now = new Date();
    //   let startDate = new Date(start);
    //   let endDate = new Date(end);

    //   if (now.getTime() >= startDate.getTime()) {
    //     if (endDate === null) {
    //       return true;
    //     }

    //     if (now.getTime() < endDate.getTime()) {
    //       return true;
    //     } else {
    //       return false;
    //     }
    //   } else {
    //     return false;
    //   }
    // };

    // loadBankAccounts = (callback) => {
    //   window.SPL_Transaction.loadAllOwnedAndUnOwnedBank().then((data) => {
    //     let memberBankList = data.memberBankList;

    //     for (let i = 0; i < memberBankList.length; i++) {
    //       const memberBank = memberBankList[i];
    //       memberBank.displayName = `${memberBank.bankName} ${memberBank.accountNumber} ${memberBank.accountHolder}`;
    //     }

    //     if (this.isMount) {
    //       this.setState({ bankList: data.bankList, memberBankList: memberBankList, unOwnedBankList: data.unOwnedBankList }, () => {
    //         if (callback) {
    //           callback();
    //         }
    //       });
    //     }
    //   });
    // };

    handleAmountChange = (value) => {
        if (value) {
            let regex = '';
            // if (this.props.memberDecimalSettings?.[AMOUNT_DEPOSIT_METHOD_CODE.BA]) {
            //     regex = /^[0-9]+$/;
            // } else {
            regex = /^(\d*)\.{0,1}(\d){0,2}$/;
            // }
            if (value.toString().match(regex)) {
                this.setState({ amount: value }, () => {
                    this.validateDepositAmount();
                });
            }
        } else {
            this.setState({ amount: '' }, () => {
                this.validateDepositAmount();
            });
        }
    };

    checkError = () => {
        const { activeTab, amountErrMsg, selectedMethod, selectedBank } = this.state;

        if (activeTab === 'quickpay') {
            if (amountErrMsg.length > 0 || selectedMethod.length <= 0 || selectedBank.length <= 0) {
                this.setState({ disabledDepositButton: true });
            } else {
                this.setState({ disabledDepositButton: false });
            }
        } else {
            const { amount, selectedDepositBank, receiptImg, selectedBank, referenceCode, receiptMandatoryBankCodes, requiredReceiptForOnlineBanking, depositOptions } =
                this.state;
            let selectedDepositChannel = depositOptions[0];

            if (
                !amount ||
                amountErrMsg.length > 0 ||
                selectedDepositChannel.length <= 0 ||
                (selectedDepositChannel.length > 0 &&
                    ((selectedDepositChannel[0].value === 'O' && requiredReceiptForOnlineBanking) ||
                        selectedDepositChannel[0].value === 'D' ||
                        selectedDepositChannel[0].value === 'A' ||
                        selectedDepositChannel[0].value === 'SE' ||
                        (receiptMandatoryBankCodes && receiptMandatoryBankCodes.indexOf(selectedDepositBank.bankCode) !== -1))) ||
                receiptImg.length <= 0
            ) {
                this.setState({ selectedDepositChannel: selectedDepositChannel, disabledDepositButton: true });
            } else {
                this.setState({ disabledDepositButton: false });
            }
        }
    };

    handleSubmit(type) {
        const { depositSubmission, t } = this.props;
        const { filteredDepositApiJson, depositSubmitCountdown } = this.state;
        const paymentCode = filteredDepositApiJson && filteredDepositApiJson.optionCode;
        const submissionData = depositSubmission && depositSubmission.submissionData;
        const paymentObj = submissionData && submissionData[paymentCode];

        if (paymentObj && paymentObj.submissionRetryTime > Date.now().valueOf()) {
            // this.setState({ disabledDepositButton: true });
            notification.showNotification('error', t('transaction:transaction.deposit.resubmitError', { retryTime: depositSubmitCountdown }));
        } else {
            if (type === 'quickpay') {
                this.quickPaySubmit(paymentCode);
            } else {
                this.localPaySubmit();
            }
        }
    }

    validateDepositAmount = () => {
        if (this.state.depositMinMaxLimit) {
            const { amount, depositMinMaxLimit } = this.state;
            const depositLimitObj = { minLimit: depositMinMaxLimit.min, maxLimit: depositMinMaxLimit.max };
            window.SPL_Transaction.validateDepositAmount(amount, depositLimitObj)
                .then((errMsg) => {
                    if (this.props.memberDecimalSettings?.[AMOUNT_DEPOSIT_METHOD_CODE.BA] && !this.state.amount.toString().match(/^[0-9]+$/)) {
                        const newErrMsg = window.SPL_Other.deepCopy(errMsg);
                        newErrMsg.push('transaction:transaction.withdrawal.disableDecimalError');
                        return newErrMsg;
                    }

                    return errMsg;
                })
                .then((errMsg) => {
                    if (this.isMount) {
                        let errMsgLimitAmount = this.getLimitAmountForErrMsg(errMsg[0]);
                        this.setState(
                            {
                                amountErrMsg: errMsg,
                                limitAmount: errMsgLimitAmount.limitAmount,
                                currency: errMsgLimitAmount.currency,
                            },
                            () => {
                                this.checkError();
                            },
                        );
                    }
                });
        }
    };

    getLimitAmountForErrMsg(errMsg) {
        let limitAmount = '';
        let currency = this.props.user.account.currency;

        if (errMsg) {
            if (errMsg.includes('invalidMinAmount')) {
                if (this.state.activeTab === 'quickPay') {
                    limitAmount = window.SPL_Other.formatAmount(this.state.depositMinMaxLimit.minLimitQuick);
                } else {
                    limitAmount = window.SPL_Other.formatAmount(this.state.depositMinMaxLimit.min);
                }
            } else if (errMsg.includes('invalidMaxAmount')) {
                if (this.state.activeTab === 'quickPay') {
                    limitAmount = window.SPL_Other.formatAmount(this.state.depositMinMaxLimit.maxLimitQuick);
                } else {
                    limitAmount = window.SPL_Other.formatAmount(this.state.depositMinMaxLimit.max);
                }
            }
        }

        return {
            limitAmount: limitAmount,
            currency: currency,
        };
    }

    quickPaySubmit = (paymentCode) => {
        if (this.state.amountErrMsg.length > 0 || this.state.selectedMethod.length <= 0 || this.state.selectedBank.length <= 0) {
            return;
        }

        let method = this.state.selectedMethod[0];
        let selectedBankCode = this.state.selectedBank[0] ? this.state.selectedBank[0].code : null;
        let amount = this.state.amount;
        let preferredWallet = this.state.depositDetails.preferredWalletDetails.preferredWallet;
        let hidePWallet = this.state.depositDetails.preferredWalletDetails.hidePWallet;
        let callBackUrl = '/myaccount/deposit';
        let stateUrl = `/${this.props.langPath}/quickpayredirect`;
        // window.open(stateUrl);
        const { customCountdown } = this.state.compSettings;
        const countdown = customCountdown || typeof customCountdown === 'number' ? customCountdown : 30;

        window.SPL_Transaction.requestDepositOnlineTransfer(
            method,
            selectedBankCode,
            amount,
            preferredWallet,
            hidePWallet,
            stateUrl,
            this.props.language.key,
            callBackUrl,
        ).then((data) => {
            if (data.errorCode) {
                notification.showNotification('error', data.message);
            } else {
                trans_handlePGDepositCountDown(this.vm, true, countdown, paymentCode, Date.now().valueOf(), { disabledDepositButton: false });
            }
        });
    };

    localPaySubmit = () => {
        const { preferredWalletDetails } = this.state.depositDetails;
        const {
            amount,
            selectedDepositBank,
            receiptImgFile,
            receiptImg,
            referenceCode,
            amountErrMsg,
            selectedDepositChannel,
            receiptMandatoryBankCodes,
            requiredReceiptForOnlineBanking,
            depositOptions,
        } = this.state;

        if (
            !amount ||
            amountErrMsg.length > 0 ||
            selectedDepositChannel.length <= 0 ||
            (selectedDepositChannel.length > 0 &&
                ((selectedDepositChannel[0].value === 'O' && requiredReceiptForOnlineBanking) ||
                    selectedDepositChannel[0].value === 'D' ||
                    selectedDepositChannel[0].value === 'A' ||
                    selectedDepositChannel[0].value === 'SE' ||
                    (receiptMandatoryBankCodes && receiptMandatoryBankCodes.indexOf(selectedDepositBank.bankCode) !== -1))) ||
            receiptImg.length <= 0
        ) {
            return;
        }

        let preferredWallet = preferredWalletDetails.preferredWallet;
        let hidePWallet = preferredWalletDetails.hidePWallet;
        let depositAmount = amount;
        let depositChannel = depositOptions[0].value;
        let merchantBankId = selectedDepositBank.id;
        let memberBankId = null;
        let picFile = receiptImgFile;

        // preferredWallet, hidePWallet, merchantBankId, depositAmount, depositChannel, depositDateTime, memberBankId, picFile, referenceCode
        window.SPL_Transaction.localPaySubmit(preferredWallet, hidePWallet, merchantBankId, depositAmount, depositChannel, null, memberBankId, picFile, referenceCode).then(
            (data) => {
                if (this.isMount) {
                    if (data.status === 'S') {
                        this.setState({ disabledDepositButton: false });

                        let depositAmount = '';
                        let depositChannel = [];
                        let receiptImgFile = null;
                        let referenceCode = '';
                        let selectedBank = [];

                        this.setState(
                            {
                                status: 'S',
                                message: 'transaction:transaction.deposit.request-success',
                                amount: depositAmount,
                                selectedDepositChannel: depositChannel,
                                receiptImgFile: receiptImgFile,
                                referenceCode: referenceCode,
                                selectedBank: selectedBank,
                                receiptImg: [],
                                visible: true,
                            },
                            () => {
                                let status = this.state.status;

                                if (status === 'S') {
                                    setTimeout(() => {
                                        this.setState({
                                            message: '',
                                            visible: false,
                                        });
                                    }, 5000);
                                }
                            },
                        );
                    } else {
                        this.setState({ disabledDepositButton: false });

                        if (data.code === 'receipt_fail') {
                            this.setState({
                                status: 'F',
                                message: 'transaction:transaction.withdrawal.request-uploadReceiptError',
                                visible: true,
                            });
                        } else if (data.msg.data.status === 409) {
                            this.setState({
                                status: 'F',
                                message: 'transaction:transaction.withdrawal.request-pending',
                                visible: true,
                            });
                        } else if (data.msg.data.status === 500) {
                            this.setState({
                                status: 'F',
                                message: 'transaction:transaction.withdrawal.request-pending',
                            });
                        } else if (data.msg.status === 400) {
                            this.setState({
                                status: 'F',
                                message: 'transaction:transaction.withdrawal.request-pending',
                            });
                        } else {
                            this.setState({
                                status: 'F',
                                message: 'transaction:transaction.withdrawal.request-error',
                                visible: true,
                            });
                        }
                    }
                }
            },
        );
    };

    copy(text, t) {
        let successful = window.SPL_Other.copyToClipboard(text);
        if (successful) {
            notification.showNotification('info', t('transaction:transaction.withdrawal.copied', 'Copied'), { autoClose: 2000 });
        }
    }

    loadAdditionalDepositSettings = () => {
        const { account } = this.props.user;
        let currency = '';

        if (account) {
            currency = account.currency;
        }

        window.SPL_Transaction.loadAdditionalDepositSettings(currency).then((data) => {
            if (this.isMount) {
                this.setState({
                    receiptMandatoryBankCodes: data.receiptMandatoryBankCodes,
                    requiredReceiptForOnlineBanking: data.requiredReceiptForOnlineBanking,
                });
            }
        });
    };

    // toggleLoading = (toggle) => {
    //   this.setState({ showLoading: toggle });
    // };

    // loadBankAccountsForDeposit = () => {
    //   window.SPL_Transaction.loadAllOwnedAndUnOwnedBank().then((data) => {
    //     if (this.isMount) {
    //       this.setState({ unOwnedBankList: data.unOwnedBankList });
    //     }

    //     this.toggleLoading(false);
    //   })
    //   .catch((err) => {
    //     this.toggleLoading(false);
    //   });
    // };

    getTncReferral = () => {
        this.setState({ getTncReferral: !this.state.getTncReferral });
    };

    onOpenQrCodeDialogClick() {
        this.setState({ isQrPopupOpen: !this.state.isQrPopupOpen });
    }

    render() {
        const { paymentGatewaySetting } = this.props;
        const { depositNotice, depositMinMaxLimit, bankAppBankList, radioValue, depositAmountOptions, customTranslationObj, selectedDepositBank } = this.state;
        const { key } = this.props.language;
        return (
            this.props.user.account && (
                <Translation>
                    {(t) => (
                        <div className='app-onlinetransfer'>
                            <Popup className={'qrcode-popup-modal-container'} show={this.state.isQrPopupOpen} onClose={() => this.setState({ isQrPopupOpen: false })}>
                                <img src={selectedDepositBank?.image} className='qr-code-img' alt='' />
                            </Popup>
                            {/* Online Trans */}
                            <ul>
                                <li>
                                    <div className='item onlinetransfer-bankacc'>
                                        <p>
                                            {customTranslationObj?.bankOptions
                                                ? t(customTranslationObj?.bankOptions)
                                                : t('transaction:transaction.deposit.cashdeposit.bankaccount')}{' '}
                                            <span className='text-danger asterisk'>*</span>
                                        </p>
                                        <div className='bank-selector'>
                                            {this.state.bankAppBankList.length > 0 ? (
                                                <ul className='bank-list'>
                                                    {this.state.bankAppBankList.map((account, index) => {
                                                        const isActive = this.state.radioValue === index;
                                                        return (
                                                            <li key={index} className='small-bank bank-acc-list'>
                                                                <div key={index} className='member-bankacc'>
                                                                    <label>
                                                                        <input
                                                                            type='radio'
                                                                            name='lightt'
                                                                            onChange={() => this.onDepositBankAcc(index)}
                                                                            value={this.state.radioValue}
                                                                            checked={isActive}
                                                                        />
                                                                        <div key={account.id} className='banklist-group'>
                                                                            <div className='group-box'>
                                                                                <div className={`bank-img ${isActive ? 'bank-on' : 'bank-off'}`}>
                                                                                    <BankIcon bankCode={account?.bankCode || account?.code} isActive={isActive} />
                                                                                </div>
                                                                                <div className={`bank-name ${this.state.radioValue === index ? 'bank-selected' : ''}`}>
                                                                                    {account.name}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </label>
                                                                </div>
                                                            </li>
                                                        );
                                                    })}
                                                </ul>
                                            ) : this.state.loading ? (
                                                <SmallLoading></SmallLoading>
                                            ) : (
                                                <div className='no-Data'>No Data</div>
                                            )}
                                        </div>
                                    </div>

                                    {selectedDepositBank?.image && (
                                        <div className='item bank-qrcode-container'>
                                            <div className='frame'>
                                                <LazyLoadImage
                                                    src={selectedDepositBank?.image}
                                                    alt='bank-qrcode'
                                                    onClick={() => this.onOpenQrCodeDialogClick()}
                                                ></LazyLoadImage>
                                            </div>
                                            <div className='guide'>
                                                <h5>{selectedDepositBank?.name}</h5>
                                                <ul>
                                                    <h6>{t('bank:bank.guide')}</h6>
                                                    <li>{t('bank:bank.qrGuideStep1')}</li>
                                                    <li>{t('bank:bank.qrGuideStep2')}</li>
                                                    <li>{t('bank:bank.qrGuideStep3')}</li>
                                                </ul>
                                                <p>
                                                    <Trans i18nKey='bank:bank.qrGuideNotice'>
                                                        Please use your own account. Account <strong>{{}}</strong> be the same as your account holder name or the deposit will
                                                        be rejected.
                                                    </Trans>
                                                </p>
                                            </div>
                                        </div>
                                    )}

                                    {selectedDepositBank ? (
                                        <>
                                            <div className='item onlinetransfer-bankacc'>
                                                <p>
                                                    {customTranslationObj?.bankAccount
                                                        ? t(customTranslationObj?.bankAccount)
                                                        : t('transaction:transaction.deposit.cashdeposit.bankaccount')}{' '}
                                                    <span className='text-danger asterisk'>*</span>
                                                </p>
                                                <div className='bank-selector'>
                                                    <ul className='bank-list'>
                                                        <li className='small-bank bank-acc-list'>
                                                            <div className='member-bankacc'>
                                                                {this.state.selectedDepositBank && this.state.selectedDepositBank ? (
                                                                    <div className='radio-form'>
                                                                        {this.state.selectedDepositBank && this.state.selectedDepositBank && (
                                                                            <div className='account-holder'>
                                                                                {this.state.selectedDepositBank.accountHolder}
                                                                                <AiOutlineCopy
                                                                                    className='icon-copy'
                                                                                    onClick={() => this.copy(this.state.selectedDepositBank.accountHolder, t)}
                                                                                />
                                                                            </div>
                                                                        )}
                                                                        {this.state.selectedDepositBank && this.state.selectedDepositBank && (
                                                                            <div className='account-holder'>
                                                                                {this.state.selectedDepositBank.bankAppsPhone}
                                                                                <AiOutlineCopy
                                                                                    className='icon-copy'
                                                                                    onClick={() => this.copy(this.state.selectedDepositBank.bankAppsPhone, t)}
                                                                                />
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                ) : this.state.loading ? (
                                                                    <SmallLoading></SmallLoading>
                                                                ) : (
                                                                    <div className='no-Data'>No Data</div>
                                                                )}
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>

                                            {/* <div className='item deposit-channel'>
                                        <p>
                                            {t('transaction:transaction.deposit.cashdeposit.channel')} <span className='text-danger asterisk'>*</span>
                                        </p>
                                        <div className='deposit-amount'>
                                            {depositOptions ? (
                                                <Select
                                                    styles={{
                                                        singleValue: (base) => ({
                                                            ...base,
                                                            padding: 5,
                                                            borderRadius: 5,
                                                            background: this.state.selectedOption.value,
                                                        }),
                                                    }}
                                                    placeholder={t('settings:settings.dropdown.pleaseselect')}
                                                    options={this.state.depositOptions}
                                                    values={this.state.selectedDepositChannel}
                                                    onChange={(value) => this.handleDepositChannelChange(value)}
                                                    searchable={false}
                                                />
                                            ) : (
                                                <SmallLoading></SmallLoading>
                                            )}
                                        </div>
                                    </div> */}

                                            {(!paymentGatewaySetting ||
                                                (paymentGatewaySetting &&
                                                    !paymentGatewaySetting.amountFieldNotRequired.includes(
                                                        bankAppBankList.length > 0 && bankAppBankList[radioValue]?.code,
                                                    ))) && (
                                                <Fragment>
                                                    <div className='item'>
                                                        <p>
                                                            {t('transaction:transaction.deposit.cashdeposit.depositamount')} <span className='text-danger asterisk'>*</span>
                                                        </p>
                                                        <div className='deposit-amount'>
                                                            {depositMinMaxLimit ? (
                                                                <input
                                                                    name='depositamount'
                                                                    className={`form-control-inner ${this.state.depositamountError ? 'is-invalid' : ''}`}
                                                                    id='name'
                                                                    placeholder={t('global:global.form.online-transfer-amount-placeholder', {
                                                                        min: window.SPL_Other.formatAmount(depositMinMaxLimit.min),
                                                                        max: window.SPL_Other.formatAmount(depositMinMaxLimit.max),
                                                                    })}
                                                                    min='0'
                                                                    value={this.state.amount}
                                                                    onChange={(e) => this.handleAmountChange(e.target.value)}
                                                                    onBlur={this.validateDepositAmount}
                                                                />
                                                            ) : (
                                                                <SmallLoading></SmallLoading>
                                                            )}

                                                            {this.state.amountErrMsg.map((errMsg, index) => {
                                                                return (
                                                                    <div key={index} className='invalid-feedback text-danger'>
                                                                        <Trans i18nKey={errMsg}></Trans>{' '}
                                                                        {`${this.state.limitAmount.length > 0 ? this.state.currency + ' ' + this.state.limitAmount : ''} `}
                                                                    </div>
                                                                );
                                                            })}
                                                        </div>
                                                    </div>

                                                    {/* var can be removed if needed, just want to load done tgt with minmax */}
                                                    {depositMinMaxLimit ? (
                                                        <div className='item amount-select'>
                                                            <p className='deposit-options'></p>
                                                            <div className='amount-select-btn'>
                                                                {depositAmountOptions.map((item) => (
                                                                    <button className='btn-amount' onClick={() => this.handleAmountChange(item)}>
                                                                        {item}
                                                                    </button>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <SmallLoading></SmallLoading>
                                                    )}
                                                </Fragment>
                                            )}

                                            <div className='item'>
                                                <p>{t('transaction:transaction.deposit.cashdeposit.referenceid')}</p>
                                                <div className='deposit-amount'>
                                                    {this.state.bankAppBankList ? (
                                                        <input
                                                            name='referenceCode'
                                                            className={`form-control-inner`}
                                                            id='reference'
                                                            placeholder={t('transaction:transaction.deposit.cashdeposit.referenceid')}
                                                            value={this.state.referenceCode}
                                                            onChange={(value) => this.handlereferenceCodeChange(value)}
                                                        />
                                                    ) : (
                                                        <SmallLoading></SmallLoading>
                                                    )}
                                                </div>
                                            </div>

                                            <div className='item'>
                                                <p></p>
                                                <div className='deposit-amount'>
                                                    {this.state.receiptImg.length > 0 && <LazyLoadImage src={`${this.state.receiptImg}`} className='w-100' alt='receipt' />}
                                                </div>
                                            </div>

                                            <span className='tnc-apply mb-only' onClick={() => this.getTncReferral()}>
                                                * {t('slot:slot.tnc-apply', 'Terms & Conditions Applied')}
                                            </span>

                                            <div className='item button-row'>
                                                <p></p>
                                                <div className='online-transfer-button'>
                                                    <label className='btn-normal btn-receipt'>
                                                        {t('transaction:transaction.deposit.cashdeposit.upload').toLocaleLowerCase()}

                                                        <input
                                                            style={{ display: 'none' }}
                                                            name='receiptImg'
                                                            className={`form-control w-100`}
                                                            id='receiptImg'
                                                            type='file'
                                                            accept='image/*'
                                                            placeholder='receiptImg'
                                                            onChange={(event) => this.handleReceiptImageChange(event)}
                                                        />
                                                    </label>
                                                    <button
                                                        type='submit'
                                                        className='btn-normal btn-deposit'
                                                        disabled={this.state.disabledDepositButton}
                                                        onClick={() => this.handleSubmit('online')}
                                                    >
                                                        {t('transaction:transaction.deposit.onlinetransfer.depositbtn').toLowerCase()}
                                                    </button>
                                                </div>
                                            </div>

                                            <div className='item'>
                                                <p></p>
                                                <div>
                                                    {this.state.status === 'F' && (
                                                        <div className='invalid-feedback text-danger'>
                                                            <Trans i18nKey={this.state.message}></Trans>
                                                        </div>
                                                    )}

                                                    {this.state.status === 'S' && (
                                                        <div className='invalid-feedback text-success'>
                                                            <Trans i18nKey={this.state.message}></Trans>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </>
                                    ) : null}
                                </li>

                                {!this.props.screen.isMobile && depositNotice && depositNotice[key.toLowerCase()] && (
                                    <div className='notice-box'>
                                        <div className='title'>
                                            <i className='icon-notice-important'></i>
                                            <span>{t('transaction:transaction.importantTitle', 'Important Notice')}</span>
                                        </div>
                                        <ul>
                                            {Object.keys(depositNotice[key.toLowerCase()]).map((noteKey) => (
                                                <li key={noteKey}>{depositNotice[key.toLowerCase()][noteKey]}</li>
                                            ))}
                                        </ul>
                                    </div>
                                )}
                            </ul>

                            {this.state.getTncReferral && (
                                <li className='messaging-popup mobile-notice'>
                                    <div className='popup notice-box'>
                                        <div className='popup-header'>
                                            <div className='popup-header-left'>
                                                <div className='popup-title'>{t('slot:slot.tnc-apply', 'Terms & Conditions Applied')}</div>
                                            </div>
                                            <div className='popup-header-right'>
                                                <SVGElements name='close-icon' onClick={() => this.getTncReferral()} />
                                                {/* <MdClose onClick={() => this.getTncReferral()} /> */}
                                            </div>
                                        </div>

                                        {depositNotice && depositNotice[key.toLowerCase()] && (
                                            <div className='popup-body'>
                                                <div className='title'>
                                                    <i className='icon-notice-important'></i>
                                                    <span>{t('transaction:transaction.importantTitle', 'Important Notice')}</span>
                                                </div>
                                                <ul>
                                                    <li>{depositNotice[key.toLowerCase()].note_1}</li>
                                                    <li>{depositNotice[key.toLowerCase()].note_2}</li>
                                                    <li>{depositNotice[key.toLowerCase()].note_3}</li>
                                                    <li>{depositNotice[key.toLowerCase()].note_4}</li>
                                                    <li>{depositNotice[key.toLowerCase()].note_5}</li>
                                                    <li>{depositNotice[key.toLowerCase()].note_6}</li>
                                                    <li>{depositNotice[key.toLowerCase()].note_7}</li>
                                                </ul>
                                            </div>
                                        )}
                                    </div>
                                    <div className='popup-overlay notice-overlay' onClick={() => this.getTncReferral()}></div>
                                </li>
                            )}

                            <div className='row d-flex justify-content-center'></div>
                        </div>
                    )}
                </Translation>
            )
        );
    }
}

export default connect(mapStateToProps)(withTranslation(['transaction', 'settings', 'bank'])(withRouter(BankApps)));
