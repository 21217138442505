import React from 'react';
import { Translation, withTranslation } from 'react-i18next';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { mapStateToProps } from '../../../../redux/selector/selector';
import PropTypes from 'prop-types';

class UserName extends React.Component {
  isMount = true;
  constructor(props) {
    super(props);
    this.state = {
      account: null,
      isHovered: false,
    };
  }

  componentDidMount() {
    this.identity();
  }

  componentWillUnmount() {
    this.isMount = false;
  }

  toggleHover = (e) => {
    this.setState({ isHovered: e });
  };

  identity = () => {
    const { account } = this.props.user;
    if (this.isMount) {
      if (this.props.user.userName !== '') {
        this.setState({ account: this.props.user.userName });
      } else {
        this.setState({ account: this.props.user.account.login.toLowerCase() });
      }
    }
  };
  render() {
    const {
      account,
    } = this.state;
    return (
      <Translation>
        {(t) => (
          <div className='header-username d-flex'>
            <div className=' d-flex align-items-center'>
              <div className='d-flex align-items-center'>
                [{/* <LazyLoadImage src={userstatusImg} className='position-relative img-xs m-l-5 m-r-5  ' /> */}
                <span className='p-l-5 p-r-5 '>
                  <div>{account}</div>
                </span>
                ]
              </div>
            </div>
          </div>
        )}
      </Translation>
    );
  }
}
// UserName.propTypes = {
//   requireFuntion: PropTypes.object.isRequired,

// };
export default connect(mapStateToProps)(withTranslation(['casino', 'member'])(withRouter(UserName)));
