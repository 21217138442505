import { productBannerConstants } from '../constant/';

export const productBannerAction = {
    setProductBannerData,
};

function setProductBannerData(productBannerObj) {
    return (dispatch) => {
        dispatch({ type: productBannerConstants.GET_PRODUCT_BANNER_BY_PRODUCT_PAGE, payload: productBannerObj });
    };
}
