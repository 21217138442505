import React from 'react';
import { ModalHeader, ModalFooter, Col } from 'reactstrap';
import { Translation, withTranslation } from 'react-i18next';
import SVGMessage from '../../components/Icon/SVGMessage';

const MessagingModal = ({ handleClose, show, deleteMessageByBatch, countCheck }) => {
  const showHideClassName = show ? 'modal fade show d-block' : 'modal display-none';
  return (
    <Translation>
      {(t) => (
        <div className={showHideClassName} onClick={handleClose}>
          {countCheck < 1 ? (
            <div className='modal-container delete-msg-modal'>
              <div className='modal-sm'>
                <ModalHeader className='w-100 d-flex'>
                  <Col xs='10' sm='10' className='d-block'>
                    <div>{t('message:message.removeMessage')}</div>
                  </Col>
                  <Col xs='2' sm='2' className='d-flex justify-content-end align-items-center'>
                    <button type='button' onClick={handleClose} className='close'>
                      <span className='inner-cancel'>x</span>
                    </button>
                  </Col>
                </ModalHeader>
              </div>
            </div>
          ) : (
            <div className='modal-container delete-msg-modal'>
              <div className='modal-sm'>
                <ModalHeader className='w-100 d-flex'>
                  <Col xs='10' sm='10' className='d-flex'>
                    <svg className=' icon-sm  m-r-10'>
                      <SVGMessage name='trashbin' />
                    </svg>
                    <div className='h4 '>{t('message:message.confirm-subject')}</div>
                  </Col>
                  <Col xs='2' sm='2' className='d-flex justify-content-end align-items-center'>
                    <button type='button' onClick={handleClose} className='close'>
                      <span className='inner-cancel'>x</span>
                    </button>
                  </Col>
                </ModalHeader>
                <ModalFooter className='w-100 h-auto'>
                  <button
                    className='primary-btn button-35px'
                    onClick={() => {
                      handleClose();
                      deleteMessageByBatch();
                    }}>
                    <span>{t('global:entity.action.yes')}</span>
                  </button>
                </ModalFooter>
              </div>
            </div>
          )}
        </div>
      )}
    </Translation>
  );
};

export default withTranslation(['global'])(MessagingModal);
