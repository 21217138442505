import classnames from 'classnames';
import { Component, default as React } from 'react';
import { Translation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { mapStateToProps } from '../../redux/selector/selector';
import { Col, Nav, NavItem, NavLink, Row, TabContent, TabPane, Spinner } from 'reactstrap';
import SmallLoading from '../../components/Loading/SmallLoading';
import { AiOutlineSync } from 'react-icons/ai';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import {
    EBET,
    SCR918,
    AeSexy,
    AgGaming,
    Allbet,
    BetRadar,
    BK8,
    CMD368,
    DreamGaming,
    Evolution,
    GamePlay,
    GoldDeluxe,
    GgGaming,
    IdnPoker,
    IM,
    MaxBet,
    Mega888,
    MicroGaming,
    NextSpin,
    PlaynGo,
    PragramaticPlay,
    PlayTech,
    QQKeno,
    SaGaming,
    SBO,
    SpadeGaming,
    TopTrendGaming,
    Ultimate,
} from './101_Transfer_Items_Data';

class TransferTab extends Component {
    isMount = true;
    constructor(props, context, images) {
        super(props);
        this.state = {
            transferTab: 'ProviderBalance',
            isLoading: true,
            restoreOnLoad: false,
            providerList: [],
            sportbook: ['CMD', 'IBC', 'BR'],
            casinoSlotFishing: ['DG', 'UG', 'AES', 'AES2', 'EVO', 'GP', 'AG2', 'MGP', 'EBET', 'PT2', 'SA', 'GD', 'AB', 'SG', 'UL', 'PP', 'TTG', 'PG', 'MEGA', 'GG'],
            others: ['IDN', 'QQK'],
        };
    }

    componentDidMount() {
        this.isMount = true;
        this.getProviderList();
    }

    componentWillUnmount() {
        this.isMount = false;
    }

    getProviderList = () => {
        setTimeout(() => {
            const { providerList } = this.props;
            this.setState({ providerList: providerList, isLoading: false });
        }, 1000);
    };

    onTabClick = (tab) => {
        this.setState({ transferTab: tab });
    };

    render = () => {
        const { restoreOnLoad } = this.state;
        return (
            <Translation>
                {(t) => (
                    <div className='app-transfer-tab w-100'>
                        <Nav tabs className='deposit-tab'>
                            <div className='d-flex'>
                                <NavItem
                                    className='deposit-tab-click m-l-3 m-r-3'
                                    onClick={() => {
                                        this.onTabClick('ProviderBalance');
                                    }}
                                >
                                    <NavLink
                                        className={classnames({
                                            active: this.state.transferTab === 'ProviderBalance',
                                            navlinkForm: true,
                                        })}
                                    >
                                        {t('settings:settings.providerbalance')}
                                    </NavLink>
                                </NavItem>
                                <NavItem
                                    className='deposit-tab-click m-l-3 m-r-3'
                                    onClick={() => {
                                        this.onTabClick('WeeklyTurnover');
                                    }}
                                >
                                    <NavLink
                                        className={classnames({
                                            active: this.state.transferTab === 'WeeklyTurnover',
                                            navlinkForm: true,
                                        })}
                                    >
                                        {t('settings:settings.weeklyturnover')}
                                    </NavLink>
                                </NavItem>
                            </div>
                            <div className='weekly-turnover-div'>
                                <span className='weekly-turnover-content'>Weekly Turnover Total</span> <span className='weekly-turnover-amount'>5,888,888.88</span>
                                <a className='bg-transparent p-l-0 p-r-0 d-inline-block justify-content-center align-items-center'>
                                    <AiOutlineSync className={`  ${!restoreOnLoad ? 'd-sm-block myAcc-icon' : 'd-none'}`} />
                                    <Spinner animation='border' className={` ${restoreOnLoad ? 'd-sm-block myAcc-icon' : 'd-none'}`} />
                                </a>
                            </div>
                        </Nav>
                        <TabContent activeTab={this.state.transferTab} className='deposit-tab-content w-100'>
                            {this.state.isLoading && <SmallLoading></SmallLoading>}
                            {this.state.transferTab === 'ProviderBalance' && (
                                <TabPane tabId='ProviderBalance' className='d-flex transfer-tab-spacing'>
                                    {!this.state.isLoading && (
                                        <div className='w-100 transfer-tab-container'>
                                            <div className='transfer-tab-div'>
                                                <div className='transfer-content-title'>{t('global:global.menu.sportsbook2')}</div>
                                                <Row className='transfer-provider-container w-100'>
                                                    {this.state.sportbook.map((sport, index) => {
                                                        return (
                                                            <Col xs='3' className='d-flex transfer-provider-spacing' key={index}>
                                                                {this.state.providerList.map((provider, index) => {
                                                                    return provider.provider === sport ? (
                                                                        <div className='d-flex align-items-center' key={index}>
                                                                            <div className='transfer-provider-img-container'>
                                                                                {provider.provider === 'CMD' ? (
                                                                                    <LazyLoadImage src={CMD368} className='transfer-provider' />
                                                                                ) : provider.provider === 'IBC' ? (
                                                                                    <LazyLoadImage src={MaxBet} className='transfer-provider' />
                                                                                ) : provider.provider === 'BR' ? (
                                                                                    <LazyLoadImage src={BetRadar} className='transfer-provider' />
                                                                                ) : (
                                                                                    void 0
                                                                                )}
                                                                            </div>
                                                                            <div className='transfer-provider-details-container'>
                                                                                <span>{provider.name}</span>
                                                                                <span>{window.SPL_Other.formatAmount(provider.balance)}</span>
                                                                            </div>
                                                                        </div>
                                                                    ) : (
                                                                        void 0
                                                                    );
                                                                })}
                                                            </Col>
                                                        );
                                                    })}
                                                </Row>
                                            </div>
                                            <div className='transfer-tab-div'>
                                                <div className='transfer-content-title'>
                                                    {t('global:global.menu.casino2') + ' / ' + t('global:global.menu.slot2') + ' / ' + t('global:global.menu.fishing2')}
                                                </div>
                                                <Row className='transfer-provider-container w-100'>
                                                    {this.state.casinoSlotFishing.map((casinoSlotFishing, index) => {
                                                        return (
                                                            <Col xs='3' className='d-flex transfer-provider-spacing' key={index}>
                                                                {this.state.providerList.map((provider, index) => {
                                                                    return (
                                                                        provider.provider === casinoSlotFishing && (
                                                                            <div className='d-flex align-items-center' key={index}>
                                                                                <div className='transfer-provider-img-container'>
                                                                                    {provider.provider === 'EBET' ? (
                                                                                        <LazyLoadImage src={EBET} className='transfer-provider' />
                                                                                    ) : provider.provider === 'DG' ? (
                                                                                        <LazyLoadImage src={DreamGaming} className='transfer-provider' />
                                                                                    ) : provider.provider === 'UG' ||
                                                                                      provider.provider === 'AES' ||
                                                                                      provider.provider === 'AES2' ? (
                                                                                        <LazyLoadImage src={AeSexy} className='transfer-provider' />
                                                                                    ) : provider.provider === 'EVO' ? (
                                                                                        <LazyLoadImage src={Evolution} className='transfer-provider' />
                                                                                    ) : provider.provider === 'GP' ? (
                                                                                        <LazyLoadImage src={GamePlay} className='transfer-provider' />
                                                                                    ) : provider.provider === 'AG' || provider.provider === 'AG2' ? (
                                                                                        <LazyLoadImage src={AgGaming} className='transfer-provider' />
                                                                                    ) : provider.provider === 'MGP' ? (
                                                                                        <LazyLoadImage src={MicroGaming} className='transfer-provider' />
                                                                                    ) : provider.provider === 'PT' || provider.provider === 'PT2' ? (
                                                                                        <LazyLoadImage src={PlayTech} className='transfer-provider' />
                                                                                    ) : provider.provider === 'SA' ? (
                                                                                        <LazyLoadImage src={SaGaming} className='transfer-provider' />
                                                                                    ) : provider.provider === 'GD' ? (
                                                                                        <LazyLoadImage src={GoldDeluxe} className='transfer-provider' />
                                                                                    ) : provider.provider === 'AB' ? (
                                                                                        <LazyLoadImage src={Allbet} className='transfer-provider' />
                                                                                    ) : provider.provider === 'SG' ? (
                                                                                        <LazyLoadImage src={SpadeGaming} className='transfer-provider' />
                                                                                    ) : provider.provider === 'UL' ? (
                                                                                        <LazyLoadImage src={Ultimate} className='transfer-provider' />
                                                                                    ) : provider.provider === 'PP' ? (
                                                                                        <LazyLoadImage src={PragramaticPlay} className='transfer-provider' />
                                                                                    ) : provider.provider === 'TTG' ? (
                                                                                        <LazyLoadImage src={TopTrendGaming} className='transfer-provider' />
                                                                                    ) : provider.provider === 'PG' ? (
                                                                                        <LazyLoadImage src={PlaynGo} className='transfer-provider' />
                                                                                    ) : provider.provider === 'MEGA' ? (
                                                                                        <LazyLoadImage src={Mega888} className='transfer-provider' />
                                                                                    ) : provider.provider === 'GG' ? (
                                                                                        <LazyLoadImage src={GgGaming} className='transfer-provider' />
                                                                                    ) : (
                                                                                        void 0
                                                                                    )}
                                                                                </div>

                                                                                <div className='transfer-provider-details-container'>
                                                                                    <span>{provider.name}</span>
                                                                                    <span>{window.SPL_Other.formatAmount(provider.balance)}</span>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    );
                                                                })}
                                                            </Col>
                                                        );
                                                    })}
                                                </Row>
                                            </div>

                                            <div className='transfer-tab-div'>
                                                <div className='transfer-content-title'>{t('global:global.others')}</div>
                                                <Row className='transfer-provider-container w-100'>
                                                    {this.state.others.map((others, index) => {
                                                        return (
                                                            <Col xs='3' className='d-flex transfer-provider-spacing' key={index}>
                                                                {this.state.providerList.map((provider, index) => {
                                                                    return (
                                                                        provider.provider === others && (
                                                                            <div className='d-flex align-items-center' key={index}>
                                                                                <div className='transfer-provider-img-container'>
                                                                                    {provider.provider === 'IDN' ? (
                                                                                        <LazyLoadImage src={IdnPoker} className='transfer-provider' />
                                                                                    ) : (
                                                                                        void 0
                                                                                    )}
                                                                                </div>
                                                                                <div className='transfer-provider-details-container'>
                                                                                    <span>{provider.name}</span>
                                                                                    <span>{window.SPL_Other.formatAmount(provider.balance)}</span>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    );
                                                                })}
                                                            </Col>
                                                        );
                                                    })}
                                                </Row>
                                            </div>
                                        </div>
                                    )}
                                </TabPane>
                            )}
                            {/* Online Trans */}
                            {this.state.transferTab === 'WeeklyTurnover' && (
                                <TabPane tabId='WeeklyTurnover' className='d-flex transfer-tab-spacing'>
                                    {!this.state.isLoading && (
                                        <div className='w-100 transfer-tab-container'>
                                            <div className='transfer-tab-div'>
                                                <div className='transfer-content-title'>{t('global:global.menu.sportsbook2')}</div>
                                                <Row className='transfer-provider-container w-100'>
                                                    {this.state.sportbook.map((sport, index) => {
                                                        return (
                                                            <Col xs='3' className='d-flex transfer-provider-spacing' key={index}>
                                                                {this.state.providerList.map((provider, index) => {
                                                                    return (
                                                                        provider.provider === sport && (
                                                                            <div className='d-flex align-items-center' key={index}>
                                                                                <div className='transfer-provider-img-container'>
                                                                                    {provider.provider === 'CMD' ? (
                                                                                        <LazyLoadImage src={CMD368} className='transfer-provider' />
                                                                                    ) : provider.provider === 'IBC' ? (
                                                                                        <LazyLoadImage src={MaxBet} className='transfer-provider' />
                                                                                    ) : provider.provider === 'BR' ? (
                                                                                        <LazyLoadImage src={BetRadar} className='transfer-provider' />
                                                                                    ) : (
                                                                                        void 0
                                                                                    )}
                                                                                </div>
                                                                                <div className='transfer-provider-details-container'>
                                                                                    <span>{provider.name}</span>
                                                                                    <span>{window.SPL_Other.formatAmount(provider.turnover)}</span>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    );
                                                                })}
                                                            </Col>
                                                        );
                                                    })}
                                                </Row>
                                            </div>
                                            <div className='transfer-tab-div'>
                                                <div className='transfer-content-title'>
                                                    {t('global:global.menu.casino2') + ' / ' + t('global:global.menu.slot2') + ' / ' + t('global:global.menu.fishing2')}
                                                </div>
                                                <Row className='transfer-provider-container w-100'>
                                                    {this.state.casinoSlotFishing.map((casinoSlotFishing, index) => {
                                                        return (
                                                            <Col xs='3' className='d-flex transfer-provider-spacing' key={index}>
                                                                {this.state.providerList.map((provider, index) => {
                                                                    return provider.provider === casinoSlotFishing ? (
                                                                        <div className='d-flex align-items-center' key={index}>
                                                                            <div className='transfer-provider-img-container'>
                                                                                {provider.provider === 'EBET' ? (
                                                                                    <LazyLoadImage src={EBET} className='transfer-provider' />
                                                                                ) : provider.provider === 'DG' ? (
                                                                                    <LazyLoadImage src={DreamGaming} className='transfer-provider' />
                                                                                ) : provider.provider === 'UG' ||
                                                                                  provider.provider === 'AES' ||
                                                                                  provider.provider === 'AES2' ? (
                                                                                    <LazyLoadImage src={AeSexy} className='transfer-provider' />
                                                                                ) : provider.provider === 'EVO' ? (
                                                                                    <LazyLoadImage src={Evolution} className='transfer-provider' />
                                                                                ) : provider.provider === 'GP' ? (
                                                                                    <LazyLoadImage src={GamePlay} className='transfer-provider' />
                                                                                ) : provider.provider === 'AG' || provider.provider === 'AG2' ? (
                                                                                    <LazyLoadImage src={AgGaming} className='transfer-provider' />
                                                                                ) : provider.provider === 'MGP' ? (
                                                                                    <LazyLoadImage src={MicroGaming} className='transfer-provider' />
                                                                                ) : provider.provider === 'PT' || provider.provider === 'PT2' ? (
                                                                                    <LazyLoadImage src={PlayTech} className='transfer-provider' />
                                                                                ) : provider.provider === 'SA' ? (
                                                                                    <LazyLoadImage src={SaGaming} className='transfer-provider' />
                                                                                ) : provider.provider === 'GD' ? (
                                                                                    <LazyLoadImage src={GoldDeluxe} className='transfer-provider' />
                                                                                ) : provider.provider === 'AB' ? (
                                                                                    <LazyLoadImage src={Allbet} className='transfer-provider' />
                                                                                ) : provider.provider === 'SG' ? (
                                                                                    <LazyLoadImage src={SpadeGaming} className='transfer-provider' />
                                                                                ) : provider.provider === 'UL' ? (
                                                                                    <LazyLoadImage src={Ultimate} className='transfer-provider' />
                                                                                ) : provider.provider === 'PP' ? (
                                                                                    <LazyLoadImage src={PragramaticPlay} className='transfer-provider' />
                                                                                ) : provider.provider === 'TTG' ? (
                                                                                    <LazyLoadImage src={TopTrendGaming} className='transfer-provider' />
                                                                                ) : provider.provider === 'PG' ? (
                                                                                    <LazyLoadImage src={PlaynGo} className='transfer-provider' />
                                                                                ) : provider.provider === 'MEGA' ? (
                                                                                    <LazyLoadImage src={Mega888} className='transfer-provider' />
                                                                                ) : provider.provider === 'GG' ? (
                                                                                    <LazyLoadImage src={GgGaming} className='transfer-provider' />
                                                                                ) : (
                                                                                    void 0
                                                                                )}
                                                                            </div>
                                                                            <div className='transfer-provider-details-container'>
                                                                                <span>{provider.name}</span>
                                                                                <span>{window.SPL_Other.formatAmount(provider.turnover)}</span>
                                                                            </div>
                                                                        </div>
                                                                    ) : provider.provider !== casinoSlotFishing ? (
                                                                        void 0
                                                                    ) : (
                                                                        void 0
                                                                    );
                                                                })}
                                                            </Col>
                                                        );
                                                    })}
                                                </Row>
                                            </div>
                                            <div className='transfer-tab-div'>
                                                <div className='transfer-content-title'>{t('global:global.others')}</div>
                                                <Row className='transfer-provider-container w-100'>
                                                    {this.state.others.map((others, index) => {
                                                        return (
                                                            <Col xs='3' className='d-flex transfer-provider-spacing' key={index}>
                                                                {this.state.providerList.map((provider, index) => {
                                                                    return (
                                                                        provider.provider === others && (
                                                                            <div className='d-flex align-items-center' key={index}>
                                                                                <div className='transfer-provider-img-container'>
                                                                                    {provider.provider === 'IDN' ? (
                                                                                        <LazyLoadImage src={IdnPoker} className='transfer-provider' />
                                                                                    ) : (
                                                                                        void 0
                                                                                    )}
                                                                                </div>
                                                                                <div className='transfer-provider-details-container'>
                                                                                    <span>{provider.name}</span>
                                                                                    <span>{window.SPL_Other.formatAmount(provider.turnover)}</span>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    );
                                                                })}
                                                            </Col>
                                                        );
                                                    })}
                                                </Row>
                                            </div>
                                        </div>
                                    )}
                                </TabPane>
                            )}

                            {/* {!this.state.depositDetails && <Loading className='vh-50'></Loading>}s */}
                        </TabContent>
                    </div>
                )}
            </Translation>
        );
    };
}
export default connect(mapStateToProps, null)(withTranslation(['error'])(TransferTab));
