import React from "react";
import { CSSTransition } from "react-transition-group";
import { TweenMax } from "gsap";

export default class Slider extends React.Component {
  timeout = 500;
  constructor(props) {
    super(props);

    this.state = {
      index: -1,
      direction: "next",
      dur: null,
      timeout: null,
      allowPause: null,
      transionName: null,
      autoPlay: null,
      items: [],
      forceTransionName: null,
      forceStart: false
    };
  }

  componentDidMount() {
    this.setState({
      dur: this.props.dur || 6000,
      timeout: this.props.timeout || 500,
      allowPause: this.props.allowPause ?? true,
      transionName: this.props.transionName || "fade",
      autoPlay: this.props.autoPlay ?? true,
      items: this.props.items,
      forceTransionName: this.props.forceTransionName || "",
      forceStart: this.props.forceStart ?? false
    });
    requestAnimationFrame(() => {
      this.setState({
        ...this.state,
        index: 0
      });
      this.start();

      if (this.props.forceStart) {
        this.props.onChange && this.props.onChange(0);
      }

      this.initEvent();
    })
  }

  componentWillUnmount() {
    clearInterval(this.timer);
    this.stop();
    this.initEvent(true);
  }

  tick() {
    this.next();
  }

  start() {
    this.stop();

    this.state.autoPlay && (this.timer = TweenMax.delayedCall(this.state.dur / 1000, () => { this.tick() }));

  }

  stop() {
    this.state.autoPlay && this.timer && this.timer.kill();
  }

  next() {
    const size = this.props.items.length;
    const index = this.state.index === size - 1 ? 0 : this.state.index + 1;
    this.slideTo(index, "next");
  }

  prev() {

    const size = this.props.items.length;
    const index = this.state.index === 0 ? size - 1 : this.state.index - 1;
    this.slideTo(index, "prev");
  }

  slideTo = (index, direction) => {
    if (index == this.state.index) return;
    direction = direction ?? (index > this.state.index ? "next" : "prev");
    this.setState((state) => ({
      ...state,
      index,
      direction,
    }));
    this.start();
    this.props.onChange && this.props.onChange(index);
  };

  onStart(e) {
    if (this.initiated) return;
    try {
      let point = e.touches ? e.touches[0] : e;
      let target = this.refs.elRef;
      target.distX = 0;
      target.pointX = point.pageX;
      target.startTime = +new Date();
      this.initiated = true;
    } catch(err) {
      console.log('onStart: ', err);
    }
  }

  onMove(e) {
    if (!this.initiated) return;
    try {
      // e.preventDefault();
      let point = (e).touches ? (e).touches[0] : e;
      let target = this.refs.elRef;
      const deltaX = point.pageX - target.pointX;
      target.pointX = point.pageX;
      target.distX += deltaX;
    } catch (err) {
      console.log('onMove: ', err);
    }
  }

  onEnd() {
    if (!this.initiated) return;
    this.initiated = false;
    let target = this.refs.elRef;
    target.endTime = +new Date();
    let distance = target.distX,
      speed = distance / (target.endTime - target.startTime);
    if (Math.abs(distance) < 10) return this.props.moveByClick && this.next();
    speed < 0 ? this.next() : this.prev();
  }

  initEvent(remove) {
    const eventType = remove
      ? (el, type, fn, options) => {
        el.removeEventListener(type, fn, options);
      }
      : (el, type, fn, options) => {
        if (el) {
          el.addEventListener(type, fn, options);
        }
      };

    const target = this.props.eventTarget ? document.querySelector(this.props.eventTarget) : this.refs.elRef;

    eventType(target, 'touchstart', this, { passive: true });
    eventType(target, 'touchmove', this, { passive: true });
    eventType(target, 'touchend', this, { passive: true });
    eventType(target, 'touchcancel', this, { passive: true });
    eventType(target, 'mousedown', this);
    eventType(target, 'mousemove', this);
    eventType(target, 'mouseup', this);
    eventType(target, 'pointerover', this);
    eventType(target, 'pointerout', this);
    // if (this.props.moveByClick) {
    //   eventType(target, 'click', this);
    // }
  }

  handleEvent(e) {
    switch (e.type) {
      case "touchstart":
        this.onStart(e);
        break;
      case "touchmove":
        this.onMove(e);
        break;
      case "touchend":
        this.onEnd(e)
        break;
      case "touchcancel":
        this.onEnd(e)
        break;
      case "mousedown":
        this.onStart(e);
        break;
      case "mousemove":
        this.onMove(e);
        break;
      case "mouseup":
        this.onEnd(e);
        break;
      case "pointerover":
        this.state.allowPause && this.stop();
        break;
      case "pointerout":
        this.state.allowPause && this.start();
        break;
      case "click":
        this.next();
        break;

    }
  }

  render() {
    const items = this.props.items, size = items.length;
    const timeout = this.props.timeout || this.timeout;
    return (
      <div ref={"elRef"} className="slider">
        <ul className={"sli " + this.state.direction}>
          {items.map((node, i) => {
            let index = size - 1 - i;
            return (
              <CSSTransition
                key={"item-" + i}
                classNames={this.props.className || "slide"}
                in={this.state.index === index}
                timeout={timeout}>
                <li>
                  {items[index]}
                </li>
              </CSSTransition>
            );
          })
          }
        </ul>

        {this.props.items.length > 1 &&
          <ul className="tab">
            {items.map((node, i) => (
              <li key={i} onClick={() => this.slideTo(i)} className={this.state.index === i ? "on" : ""}></li>
            ))}
          </ul>
        }
      </div>
    );
  }
}