import React from 'react';
import { Trans, Translation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import 'react-datepicker/dist/react-datepicker.css';
import { mapStateToProps } from 'root/redux/selector/selector';
import { withRouter } from 'react-router-dom';
import vm from '../../Ewallet.controller';
import SmallLoading from 'root/components/Loading/SmallLoading';
import BankIcon from '../../../../../components/Molecules/bank-icon';
import BankStatementUploadSection from '@components/organisms/bank-statement-upload-section/bank-statement-upload-section';
import { gu_handleUploadReceiptButtonOnClick } from '@utils/general-util';

// This component shows the items user checked out from the cart.
class Ewallet extends React.Component {
    constructor(props) {
        super();
        this.props = props;
        vm.init(this);
    }

    componentDidMount() {
        vm.initCompSettings().then(() => {
            vm.checkFirstMemberBank();
        });
    }

    componentWillUnmount() {
        this.setState = () => {
            return;
        };
    }

    _isBankStatementFieldValid() {
        const { compSettings, filteredBankList, selectedMemberBank } = this.state;
        const { reqBankStatementByCurrencies } = compSettings || {};

        if (!selectedMemberBank) return true;

        const isBankStatusRejected = filteredBankList.some((bank) => bank?.code === selectedMemberBank?.code && bank?.verifiedDisplayStatus === 'rejected');

        return reqBankStatementByCurrencies ? !selectedMemberBank?.accountNumber || isBankStatusRejected : false;
    }

    checkValidation() {
        const { receiptImg } = this.state;

        if (this._isBankStatementFieldValid() && receiptImg?.length <= 0) {
            return true;
        } else {
            return false;
        }
    }

    render() {
        const { account, limit, amountOptions } = this.props;
        const { fields, disabledFields, filteredBankList, showLoading, receiptImg, receiptImgFile } = this.state;

        return (
            <Translation>
                {(t) => (
                    <>
                        <li className='withdrawal-ewallet'>
                            <div className='item'>
                                <p className='bank-title'></p>
                                <div className='bank-selector'>
                                    {showLoading ? (
                                        <SmallLoading></SmallLoading>
                                    ) : (
                                        filteredBankList.length > 0 && (
                                            <ul>
                                                {filteredBankList.map((bank, index) => {
                                                    const isActive = index === this.state.backActive;
                                                    return (
                                                        <li
                                                            key={index}
                                                            onClick={() => {
                                                                vm.selectBank(index);
                                                            }}
                                                        >
                                                            <div className={`icon-box bank-img ${isActive ? 'active' : ''}`}>
                                                                <BankIcon bankCode={bank.code} isActive={isActive} />
                                                            </div>
                                                            <span>{bank.name}</span>
                                                        </li>
                                                    );
                                                })}
                                            </ul>
                                        )
                                    )}
                                </div>
                            </div>
                        </li>

                        <li>
                            <div className='item'>
                                <p>
                                    {t('bank:bank.newBank.walletName', 'Wallet Name')}
                                    <span> *</span>
                                </p>
                                <div className='input-box'>
                                    <input type='text' value={account.name} disabled />
                                </div>
                            </div>
                            <div className='item'>
                                <p>
                                    {t('bank:bank.newBank.walletNumber', 'Wallet No')}
                                    <span> *</span>
                                </p>
                                <div className='input-box'>
                                    <input
                                        name='accountnumber'
                                        className={`form-control-inner ${this.state.accountNumberErrMsg.length > 0 ? 'is-invalid' : ''}`}
                                        id='accountnumber'
                                        placeholder='Exp : 1234567'
                                        value={fields['accountNumber']}
                                        onChange={(e) => vm.handleFieldChange(e, 'accountNumber')}
                                        onBlur={vm.validateAccountNumber}
                                        disabled={disabledFields['accountNumber']}
                                    />
                                </div>
                            </div>
                            <div className='item'>
                                <p>
                                    {t('transaction:transaction.withdrawal.amount')}
                                    <span> *</span>
                                </p>
                                <div className='input-box'>
                                    <input
                                        placeholder={t('global:global.form.online-transfer-amount-placeholder', {
                                            min: window.SPL_Other.formatAmount(limit.minLimit),
                                            max: window.SPL_Other.formatAmount(limit.maxLimit),
                                        })}
                                        type='number'
                                        min='0'
                                        value={fields['amount']}
                                        onChange={(e) => vm.handleFieldChange(e, 'amount')}
                                        onBlur={vm.validateAmount}
                                    />

                                    {this.state.amountErrMsg.map((errMsg, index) => {
                                        if (errMsg.includes('min_maxAmount')) {
                                            return (
                                                <div key={index} className='invalid-feedback text-danger'>
                                                    <Trans i18nKey={errMsg}></Trans> {account.currency} {window.SPL_Other.formatAmount(limit.minLimit)} - {account.currency}{' '}
                                                    {window.SPL_Other.formatAmount(limit.maxLimit)}
                                                </div>
                                            );
                                        } else {
                                            return (
                                                <div key={index} className='invalid-feedback text-danger'>
                                                    <Trans i18nKey={errMsg}></Trans>
                                                </div>
                                            );
                                        }
                                    })}

                                    <div className='withdraw-amount-list'>
                                        <p className='deposit-options'></p>
                                        <div className='amount-select-btn'>
                                            {amountOptions?.map((item) => (
                                                <button className='btn-amount' onClick={() => vm.handleAmountChange(item)}>
                                                    {item}
                                                </button>
                                            ))}
                                        </div>
                                    </div>
                                    <p>
                                        <i className='icon-tip'></i>
                                        <span>
                                            <span>
                                                {t('transaction:transaction.withdrawal.dailyLimitBalance', 'Daily Limit Balance')} :{' '}
                                                {window.SPL_Other.formatAmount(limit.dailyMaxAmount)} /{' '}
                                            </span>
                                            <span>
                                                {t('transaction:transaction.withdrawal.dailyCountBalance', 'Daily Count Balance')} :{' '}
                                                {window.SPL_Other.formatAmount(limit.maxCount)}{' '}
                                            </span>
                                        </span>
                                    </p>
                                </div>
                            </div>

                            {this._isBankStatementFieldValid() && (
                                <BankStatementUploadSection
                                    className={'bank-statement-upload-section'}
                                    buttonText={t('transaction:transaction.upload', 'Upload')}
                                    onClickFunction={gu_handleUploadReceiptButtonOnClick}
                                    handleReceiptImageChange={(e) => {
                                        vm.handleReceiptImageChange(e);
                                    }}
                                    isReceiptInput={true}
                                    receiptImg={receiptImg}
                                    receiptImgFile={receiptImgFile}
                                    handleClearReceipt={vm.handleClearReceipt}
                                />
                            )}

                            {this.state.status !== '' && (
                                <div className='item'>
                                    {this.state.status !== '' && this.state.status === 'F' ? (
                                        <div className='errormsg invalid-feedback text-danger m-l-5'>{this.state.message}</div>
                                    ) : this.state.status !== '' && this.state.status === 'S' ? (
                                        <div className='errormsg invalid-feedback text-success  m-l-5'>{this.state.message}</div>
                                    ) : (
                                        void 0
                                    )}
                                </div>
                            )}

                            <div className='item'>
                                <button className='submit-btn' onClick={vm.handleSubmit} disabled={this.state.disabledButton || this.checkValidation()}>
                                    {t('global:global.menu.account.withdraw', 'Withdraw')}
                                </button>
                            </div>
                        </li>
                    </>
                )}
            </Translation>
        );
    }
}

export default connect(mapStateToProps, null)(withTranslation([''])(withRouter(Ewallet)));
