import React from 'react';
import Header from '../components/header/Header';
import ToolBar from '../components/Toolbar';
import Chat from '../components/Chat';
import NewGame from '../components/NewGame';
import Ad from '../components/Ad';
import RouterChangeHandler from 'root/layout/MainNavigation/RouterChangeHandler/RouterChangeHandler';
import Navbar from 'root/components/wap/Navbar';
import 'react-toastify/dist/ReactToastify.min.css';
import { mapStateToProps } from 'root/redux/selector/selector';
import { connect } from 'react-redux';

function MainLayout(props) {
    return (
        <div className='main-layout'>
            <RouterChangeHandler></RouterChangeHandler>
            {props.screen.viewType !== 'mobile' && <ToolBar />}
            {/* <Ad /> */}
            {/* <NewGame /> */}

            <div className='header-section'>
                <Header />
            </div>
            <div className='bg-content-section'>{props.children}</div>
            {/* <Menu /> */}
            <div className='wap'>
                <div className='wap'>
                    <Navbar />
                </div>
            </div>
        </div>
    );
}

export default connect(mapStateToProps)(MainLayout);
