import React, { Component } from 'react';

export default class ItemEditable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.item.text
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSave = this.handleSave.bind(this);
  }
  handleChange(event) {
    this.setState({
      value: event.target.value
    });
  }
  handleSave() {
    this.props.onSave(this.props.item.id, this.state.value);
    this.props.onSkipEditing();
  }
  render() {
    return (
      <li className='list-group-item d-flex justify-content-between align-items-center'>
        <div className='input-group'>
          <input type='text' className='form-control' aria-describedby='basic-addon2' value={this.state.value} onChange={this.handleChange} />
          <div className='input-group-append'>
            <button className='btn btn-outline-success' type='button' onClick={this.handleSave}>
              Save
            </button>
            <button className='btn btn-outline-danger' type='button' onClick={this.props.onSkipEditing}>
              Cancel
            </button>
          </div>
        </div>
      </li>
    );
  }
}
