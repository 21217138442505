import React, { Component, Fragment } from "react";
import { CSSTransition } from "react-transition-group";
import { userAction } from 'root/redux/action/user.action';
import { Translation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { mapStateToProps } from 'root/redux/selector/selector';
import { withRouter } from 'react-router-dom';
import MessagePopUp from "root/components/MessagePopUp";
import SVGElements from "root/components/SVGElements";

class Notice extends Component {
  isMount = true;
  constructor(props) {
    super(props);
    this.state = {
      detailModal: false,
      announceModal: false,
      msgTitle: '',
      msgContent: '',
      in: false,
      annoucementList: [],
      isOpen: false,
      announcementSpeed: '',
      announcementStartDelay: 0,
      announcementExitDelay: 0,
      currentNotice: '',
      currentNoticeIndex: 0,
      noticeContent: true
    };
    this.toggleDetailModal = this.toggleDetailModal.bind(this);
  }

  componentDidMount() {
    this.isMount = true;
    this.getAnnoucement();
  }

  componentWillUnmount() {
    this.isMount = false;
  }

  toggleDetailModal = (info) => {
    this.setState({ detailModal: !this.state.detailModal, announceModal: true }, () => {
      this.announceMsg();
    });
  }

  announceMsg = () => {
    if (this.state.announceModal) {
      this.setState({ msgTitle: 'global:global.announcement2' })
    }
  }

  getAnnoucement() {
    const { countryLanguageKey } = this.props.language;
    const { isLogin } = this.props.user;
    const { viewType } = this.props.screen;

    let screen = '';
    if (viewType === 'web') {
      screen = 'desktop';
    } else {
      screen = 'mobile';
    }

    window.SPL_Content.getAnnoucementListWithType(isLogin, countryLanguageKey, 'Home', screen).then((data) => {
      if (this.isMount && data) {
        this.setState({ annoucementList: data.announcementsDisplay }, () => {
          let noticeText = [];

          for (let i = 0; i < data.announcementsDisplay.length; i++) {
            if (data.announcementsDisplay[i][screen]) {
              noticeText.push((data.announcementsDisplay[i][screen].text));
            } else {
              noticeText.push((data.announcementsDisplay[i]));
            }
          }

          this.setState({ msgContent: noticeText });
          this.getAnnoucementStyle();
        });
      }
    });
  }

  getAnnoucementStyle = () => {
    const { settings } = this.props.portal;
    const { isMobile } = this.props.screen;
    const { annoucementList, currentNoticeIndex } = this.state;
    let contentType = '';
    let currentList = '';

    if (this.props.screen.viewType === 'web') {
      contentType = 'desktop';
    } else {
      contentType = 'mobile';
    }

    if (annoucementList.length > 0 && annoucementList[currentNoticeIndex]) {
      if (annoucementList[currentNoticeIndex][contentType]) {
        currentList = annoucementList[currentNoticeIndex][contentType].text;
      } else {
        currentList = annoucementList[currentNoticeIndex];
      }

      this.setState({ currentNotice: currentList });
    }

    if (settings && settings.getAnnoucementStyle) {
      window.SPL_Content.getAnnoucementStyle().then((style) => {
        if (style) {
          let contentDiv = document.getElementById('msg-content');
          let textDiv = '';
          let textSpeed = 60;
          let linearTime = 0;
          let contentWidth = 0;
          let textWidth = 0;
          let rateOfSpeed = 0;

          if (isMobile) {
            textSpeed = 60;
          }

          rateOfSpeed = (textSpeed / 1710.4);

          textDiv = document.getElementById('msgAnimate');

          if (textDiv && contentDiv) {
            contentWidth = contentDiv.offsetWidth;
            textWidth = textDiv.offsetWidth;

            if (textWidth < contentWidth) {
              textWidth = contentWidth;
            }

            linearTime = textWidth * rateOfSpeed;
            this.setState({ announcementSpeed: linearTime, in: true });
          }
        }
      });
    }
  }

  onUpdate = () => {
    const { annoucementList } = this.state;
    let currentNoticeIndex = this.state.currentNoticeIndex;
    let contentType = '';
    let currentList = '';

    if (this.props.screen.viewType === 'web') {
      contentType = 'desktop';
    } else {
      contentType = 'mobile';
    }

    if (currentNoticeIndex + 1 >= annoucementList.length) {
      currentNoticeIndex = 0;
    } else {
      currentNoticeIndex = currentNoticeIndex + 1;
    }

    if (annoucementList.length > 0 && annoucementList[currentNoticeIndex]) {
      if (annoucementList[currentNoticeIndex][contentType]) {
        currentList = annoucementList[currentNoticeIndex][contentType].text;
      } else {
        currentList = annoucementList[currentNoticeIndex];
      }
    }

    this.setState({ in: false, currentNoticeIndex: currentNoticeIndex, currentNotice: currentList }, () => {
      this.getAnnoucementStyle();
    });
  }

  render() {
    const {
      detailModal,
      loginModal,
      msgTitle,
      msgContent,
      noticeContent
    } = this.state;

    let modalMsg = {
      detailModal: detailModal,
      loginModal: loginModal,
      msgTitle: msgTitle,
      msgContent: msgContent,
      noticeContent: noticeContent,
      toggleDetailModal: this.toggleDetailModal,
    }

    const duration = {
      // appear: (this.state.announcementStartDelay * 1000),
      appear: 0,
      enter: (this.state.announcementSpeed * 1000),
      // exit: (this.state.announcementExitDelay * 1000)
      exit: 5
    };

    return (
      <Translation>
        {(t) => (
          <div className="notice" onClick={() => this.toggleDetailModal(this.state.currentNotice)}>
            {this.state.detailModal &&
              <MessagePopUp modalMsg={modalMsg} />
            }

            {/* <i className="icon-notice"></i> */}
            <SVGElements className="icon-notice" name='notice-icon' />

            <div className="content" id='msg-content'>
              <CSSTransition in={this.state.in} classNames="msg" timeout={duration}
                onEntered={() => this.onUpdate()}
                onExited={() => this.onUpdate()}
              >
                <span id='msgAnimate' style={{ animation: this.state.in && `noticeFade ${this.state.announcementSpeed}s linear` }}  dangerouslySetInnerHTML={{ __html: this.state.currentNotice }}>
                  {/* {this.state.currentNotice} */}
                </span>
                {/* {this.state.annoucementList.map((annoucement, index) => {
                  return (
                    <Fragment key={index}>
                      {annoucement[contentType] ?
                        <span onClick={() => this.toggleDetailModal(annoucement[contentType].text)}>
                          {annoucement[contentType].text && annoucement[contentType].text}
                        </span>
                        :
                        <span onClick={() => this.toggleDetailModal(annoucement)}>
                          {annoucement}
                        </span>
                      }
                    </Fragment>
                  );
                })} */}
              </CSSTransition>
            </div>
          </div>
        )}
      </Translation>
    );
  }
}

export default connect(mapStateToProps)(withTranslation()(withRouter(Notice)));