export const fixedItems = [
  {
    label: 'Allbet',
    svgname: 'bonus',
    amount: '0.00'
  },

  {
    label: 'Spadegaming',
    svgname: 'bonus',
    amount: '999.00'
  }
];

export const turnoverAmount = ['5,999,999.00'];
export const sportsbookItem = [
  {
    title: 'Playtech',
    id: 'playtech',
    likeBtn: 'playtech-like',
    likeStatus: 'deactive',
    amount: '8,888,888.88',
    weeklyamount: '888,888.88'
  },
  {
    title: 'iSports',
    id: 'isports',
    likeBtn: 'isports-like',
    likeStatus: 'active',
    amount: '8,888,888.88',
    weeklyamount: '888,888.88'
  },
  {
    title: 's-Sports',
    id: 'ssports',
    likeBtn: 'ssports-like',
    likeStatus: 'active',
    amount: '8,888,888.88',
    weeklyamount: '888,888.88'
  },
  {
    title: 'Virtual Sports',
    id: 'virtualsports',
    likeBtn: 'virtualsports-like',
    likeStatus: 'deactive',
    amount: '8,888,888.88',
    weeklyamount: '888,888.88'
  },
  {
    title: 'M8bet',
    id: 'm8bet',
    likeBtn: 'm8bet-like',
    likeStatus: 'deactive',
    amount: '8,888,888.88',
    weeklyamount: '888,888.88'
  }
];

export const casinoslotsfishItem = [
  {
    title: 'GD Gaming',
    id: 'golddeluxe',
    likeBtn: 'golddeluxe-like',
    likeStatus: 'deactive',
    amount: '8,888,888.88',
    weeklyamount: '888,888.88'
  },
  {
    title: 'Dream Gaming',
    id: 'dreamgaming',
    likeBtn: 'dreamgaming-like',
    likeStatus: 'deactive',
    amount: '8,888,888.88',
    weeklyamount: '888,888.88'
  },
  {
    title: 'Allbet',
    id: 'allbet',
    likeBtn: 'allbet-like',
    likeStatus: 'deactive',
    amount: '8,888,888.88',
    weeklyamount: '888,888.88'
  },
  {
    title: 'Evolution Gaming',
    id: 'evolutiongaming',
    likeBtn: 'evolutiongaming-like',
    likeStatus: 'deactive',
    amount: '8,888,888.88',
    weeklyamount: '888,888.88'
  },
  {
    title: 'Asia Gaming',
    id: 'asiagaming',
    likeBtn: 'asiagaming-like',
    likeStatus: 'deactive',
    amount: '8,888,888.88',
    weeklyamount: '888,888.88'
  },
  {
    title: 'Playtech',
    id: 'playtech',
    likeBtn: 'playtech-like',
    likeStatus: 'deactive',
    amount: '8,888,888.88',
    weeklyamount: '888,888.88'
  },
  {
    title: 'Game Play',
    id: 'gameplay',
    likeBtn: 'gameplay-like',
    likeStatus: 'deactive',
    amount: '8,888,888.88',
    weeklyamount: '888,888.88'
  },
  {
    title: 'Sexy Baccarat',
    id: 'sexybaccarat',
    likeBtn: 'sexybaccarat-like',
    likeStatus: 'deactive',
    amount: '8,888,888.88',
    weeklyamount: '888,888.88'
  },
  {
    title: 'SpadeGaming',
    id: 'spadegaming',
    likeBtn: 'spadegaming-like',
    likeStatus: 'deactive',
    amount: '8,888,888.88',
    weeklyamount: '888,888.88'
  },
  {
    title: 'MicroGaming',
    id: 'microgaming',
    likeBtn: 'microgaming-like',
    likeStatus: 'deactive',
    amount: '8,888,888.88',
    weeklyamount: '888,888.88'
  },
  {
    title: 'Top Trend Gaming',
    id: 'ttg',
    likeBtn: 'ttg-like',
    likeStatus: 'deactive',
    amount: '8,888,888.88',
    weeklyamount: '888,888.88'
  },
  {
    title: '918 Kiss',
    id: '918kiss',
    likeBtn: '918kiss-like',
    likeStatus: 'deactive',
    amount: '8,888,888.88',
    weeklyamount: '888,888.88'
  },
  {
    title: 'GG Fishing',
    id: 'ggfishing',
    likeBtn: 'ggfishing-like',
    likeStatus: 'deactive',
    amount: '8,888,888.88',
    weeklyamount: '888,888.88'
  },
  {
    title: 'Play n Go',
    id: 'playngo',
    likeBtn: 'playngo-like',
    likeStatus: 'deactive',
    amount: '8,888,888.88',
    weeklyamount: '888,888.88'
  },
  {
    title: 'Ultimate',
    id: 'ultimate',
    likeBtn: 'ultimate-like',
    likeStatus: 'deactive',
    amount: '8,888,888.88',
    weeklyamount: '888,888.88'
  },
  {
    title: 'Mega 888',
    id: 'mega888',
    likeBtn: 'mega888-like',
    likeStatus: 'deactive',
    amount: '8,888,888.88',
    weeklyamount: '888,888.88'
  },
  {
    title: 'IDN Poker',
    id: 'idnpoker',
    likeBtn: 'idnpoker-like',
    likeStatus: 'deactive',
    amount: '8,888,888.88',
    weeklyamount: '888,888.88'
  }
];
export const providerList = [
  {
    title: 'Playtech',
    amount: '0.00'
  },
  {
    title: 'GamePlay',
    amount: '0.00'
  },
  {
    title: "Play'n Go",
    amount: '0.00'
  },
  {
    title: 'GD Gaming',
    amount: '0.00'
  },
  {
    title: 'Dream Gaming',
    amount: '0.00'
  }
];

export const imgRestoreDark = ['/public/html/images/account/restore-dark.svg'];


export const EBET = ['/public/html/images/account/096-ebet.png'];
export const SCR918 = ['/public/html/images/account/096-918kiss.png'];
export const AeSexy = ['/public/html/images/account/096-ae-sexy.png'];
export const AgGaming = ['/public/html/images/account/096-ag-gaming.png'];
export const Allbet = ['/public/html/images/account/096-allbet.png'];
export const BetRadar = ['/public/html/images/account/096-betrader.png'];
export const BK8 = ['/public/html/images/account/096-bk8.png'];
export const CMD368 = ['/public/html/images/account/096-cmd368.png'];
export const DreamGaming = ['/public/html/images/account/096-dreamgaming.png'];
export const Evolution = ['/public/html/images/account/096-evolution.png'];
export const GamePlay = ['/public/html/images/account/096-gameplay.png'];
export const GoldDeluxe = ['/public/html/images/account/096-gd.png'];
export const GgGaming = ['/public/html/images/account/096-gg-gaming.png'];
export const IdnPoker = ['/public/html/images/account/096-idn-poker.png'];
export const IM = ['/public/html/images/account/096-im.png'];
export const MaxBet = ['/public/html/images/account/096-maxbet.png'];
export const Mega888 = ['/public/html/images/account/096-mega888.png'];
export const MicroGaming = ['/public/html/images/account/096-mg.png'];
export const NextSpin = ['/public/html/images/account/096-nextspin.png'];
export const PlaynGo = ['/public/html/images/account/096-pngo.png'];
export const PragramaticPlay = ['/public/html/images/account/096-pp.png'];
export const PlayTech = ['/public/html/images/account/096-pt-logo.png'];
export const QQKeno = ['/public/html/images/account/096-qq.png'];
export const SaGaming = ['/public/html/images/account/096-sa.png'];
export const SBO = ['/public/html/images/account/096-sbo.png'];
export const SpadeGaming = ['/public/html/images/account/096-spade.png'];
export const TopTrendGaming = ['/public/html/images/account/096-ttg.png'];
export const Ultimate = ['/public/html/images/account/096-ultimate.png'];
