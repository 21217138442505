import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import toLower from 'lodash/toLower';
import isEmpty from 'lodash/isEmpty';

const LodashUtils = Object.freeze({
    get,
    isEqual,
    toLower,
    isEmpty,
});

export { LodashUtils };
export default LodashUtils;
