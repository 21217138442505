import commonUtil from '@utils/common-util';
import { wd_handleReceiptImageChange, wd_handleClearReceipt } from '@utils/withdrawal-util';

const controller = {
    vm: null,

    init(vm) {
        controller.vm = vm;
        controller.vm.state = {
            backActive: null,
            fields: {
                bankBranch: '',
                accountNumber: '',
                amount: '',
                bankName: '',
            },
            accountNumberErrMsg: [],
            amountErrMsg: [],
            bankBranchErrMsg: [],
            disabledButton: true,
            disabledFields: {
                accountNumber: false,
            },
            selectedMemberBank: [],
            getTncReferral: false,
            transactionDetails: '',
            selectedBank: [],
            filteredBankList: [],
            ewalletList: [],
            showLoading: true,
            receiptImg: [],
            receiptImgFile: null,
            compSettings: {},
            errors: [],
        };
    },

    initCompSettings() {
        return new Promise((resolve) => {
            const { user } = controller.vm.props;
            const { currency } = user.account;
            // Step 1: Standard way to get from portal settings
            const tempSettings = commonUtil.getSettingFromPortalSettings({
                vm: controller.vm,
                settingName: 'eWalletSettings',
            });

            // Step 2: Massage again to get the specific data you need (Skip if not needed)
            const reqBankStatementByCurrencies = tempSettings?.reqBankStatementByCurrencies?.includes(currency) || false;

            // Step 3: Initial all into compSettings
            let compSettings = {
                reqBankStatementByCurrencies,
            };

            controller.vm.setState({ compSettings }, () => {
                window.SPL_Content.getBankNameJson(controller.vm.props);
                resolve();
            });
        });
    },

    selectBank(index, bankObj) {
        let { fields, disabledFields, filteredBankList, ewalletList, selectedMemberBank } = controller.vm.state;
        if (filteredBankList && ewalletList && ewalletList.length > 0 && filteredBankList.length > 0) {
            //filter memberbank in bank list only take the first
            if (bankObj && bankObj.length > 0) {
                let filterBankObj = bankObj.filter((data) => data)[0];
                if (filterBankObj.name && filterBankObj.accountNumber) {
                    fields['bankName'] = filterBankObj.name;
                    fields['accountNumber'] = filterBankObj.accountNumber;
                    disabledFields['accountNumber'] = true;
                    selectedMemberBank = filterBankObj;
                } else {
                    fields['bankName'] = filterBankObj.name;
                    fields['accountNumber'] = '';
                    disabledFields['accountNumber'] = false;
                    selectedMemberBank = filterBankObj;
                }
            } else {
                if (filteredBankList[index]) {
                    filteredBankList = filteredBankList[index];

                    if (filteredBankList && filteredBankList.name && filteredBankList.accountNumber) {
                        fields['bankName'] = filteredBankList.name;
                        fields['accountNumber'] = filteredBankList.accountNumber;
                        disabledFields['accountNumber'] = true;
                        selectedMemberBank = filteredBankList;
                    } else {
                        fields['bankName'] = filteredBankList.name;
                        fields['accountNumber'] = '';
                        disabledFields['accountNumber'] = false;
                        selectedMemberBank = filteredBankList;
                    }
                }
            }
        }
        controller.vm.setState({ backActive: index, disabledFields, selectedMemberBank: selectedMemberBank, fields }, () => {
            controller.checkError();
            controller.handleClearReceipt();
        });
    },

    checkFirstMemberBank() {
        Promise.all([window.SPL_Transaction.getWithdrawalBankList(), window.SPL_Member.getMemberBanksByType('R', '', controller.vm.props)]).then((response) => {
            // list of withdrawal bank available, list of member owned bank
            const [ewalletList, memberBankList] = response;
            let { filteredBankList, filterMemberBankList } = controller.vm.state;

            if (ewalletList && ewalletList.R && ewalletList.R.R.length > 0) {
                let filterEwalletList = ewalletList.R.R;
                filterEwalletList = controller.mapBankImg(filterEwalletList);

                for (let i = 0; i < filterEwalletList.length; i++) {
                    const currentunOwnedBank = filterEwalletList[i];
                    if (memberBankList && memberBankList.length > 0) {
                        filterMemberBankList = memberBankList.filter((e) => e.bankCode === filterEwalletList[i].code);
                        if (filterMemberBankList.length > 0) {
                            for (let j = 0; j < filterMemberBankList.length; j++) {
                                const currentMemberBank = filterMemberBankList[j];
                                if (currentunOwnedBank.code === currentMemberBank.bankCode) {
                                    currentunOwnedBank.accountName = currentMemberBank.accountName;
                                    currentunOwnedBank.accountNumber = currentMemberBank.accountNumber;
                                    currentunOwnedBank.memberBankId = currentMemberBank.memberBankId;
                                    currentunOwnedBank.name = currentunOwnedBank.name;

                                    filteredBankList.push(currentunOwnedBank);
                                }
                            }
                        } else {
                            filteredBankList.push(currentunOwnedBank);
                        }
                    } else {
                        filteredBankList.push(currentunOwnedBank);
                    }
                }
            }
            controller.vm.setState({ filteredBankList, showLoading: false, ewalletList: ewalletList.R.R }, () => {
                controller.selectBank(0);
            });
        });
    },

    mapBankImg(banks) {
        let currency = null;
        if (controller.vm.props.user && controller.vm.props.user.account) {
            currency = controller.vm.props.user.account.currency;
        }
        if (currency && banks && banks.length > 0) {
            for (let i = 0; i < banks.length; i++) {
                const bank = banks[i];
                bank.imgOn = '/public/html/images/bank_img/' + bank.code + '.svg';
                bank.imgOff = '/public/html/images/bank_img/' + bank.code + '-off.svg';
            }
        }

        return banks;
    },

    isNumber(event) {
        return /^\d*$/.test(event.target.value);
    },

    getTncReferral() {
        controller.vm.setState({ getTncReferral: !controller.vm.state.getTncReferral });
    },

    loadTransactionDetails() {
        window.SPL_Transaction.loadTransactionDetails().then((data) => {
            if (data) {
                controller.vm.setState({ transactionDetails: data });
            }
        });
    },

    getMainWallet() {
        controller.vm.setState({ iconLoading: true });
        let component = controller.vm;
        return new Promise(function (resolve, reject) {
            window.SPL_Member.getMainWallet()
                .then((mainWallet) => {
                    if (component.isMount) {
                        component.setState({ mainWallet: mainWallet.data, iconLoading: false });
                        resolve(mainWallet.data);
                    }
                })
                .catch((err) => {
                    if (component.isMount) {
                        component.setState({ iconLoading: false });
                    }
                });
        });
    },

    handleSubmit() {
        controller.vm.setState({ showLoading: true, disabledButton: true }, () => {
            Promise.all([controller.validateAmount(), controller.validateAccountNumber()]).then(() => {
                let error = controller.checkError();

                const { fields, selectedMemberBank, receiptImgFile } = controller.vm.state;
                const { amount, bankBranch, accountNumber } = fields;
                const { account } = controller.vm.props;
                if (!error) {
                    // get main wallet amount again and do validation before proceed
                    window.SPL_Member.getMainWallet().then((mainWallet) => {
                        if (amount > mainWallet.data.balance) {
                            controller.vm.setState({
                                disabledButton: false,
                                status: 'F',
                                message: controller.vm.props.t('transaction:transaction.transfer.insuffficientMainWallet'),
                                visible: true,
                                showLoading: false,
                            });
                        } else {
                            let _memberBankId = 0;
                            let _bankId = 0;
                            let _bankName = '';
                            let _bankBranch = bankBranch;

                            let _bankObj = selectedMemberBank;
                            if (_bankObj) {
                                const { memberBankId, bankId, id, bankName } = _bankObj;
                                if (memberBankId) _memberBankId = memberBankId;

                                if (bankId) _bankId = bankId;

                                if (id) _bankName = bankName;
                                else _bankName = fields['bankName'];
                            }
                            const withdrawObj = {
                                accountName: account.name,
                                accountNumber: accountNumber,
                                memberBankId: _memberBankId,
                                memberBankBankId: _bankId,
                                amount: amount,
                                bankName: _bankName,
                                bankBranch: _bankBranch,
                                merchantCode: window.merchantCode,
                                bankStatementPath: receiptImgFile,
                                transactionOption: 'R',
                            };
                            window.SPL_Transaction.requestWithdraw(withdrawObj).then((data) => {
                                if (data.status === 'Fail') {
                                    controller.vm.setState({ status: 'F', message: controller.vm.props.t(data.errMsg), visible: true });
                                } else {
                                    controller.vm.setState(
                                        {
                                            status: 'S',
                                            message: controller.vm.props.t('transaction:transaction.withdrawal.request-success'),
                                            fields: {
                                                bankBranch: '',
                                                accountNumber: '',
                                                amount: '',
                                            },
                                            disabledButton: true,
                                            showLoading: false,
                                            filteredBankList: [],
                                        },
                                        () => {
                                            controller.checkFirstMemberBank();
                                            controller.getMainWallet();
                                        }
                                    );
                                }
                                controller.vm.setState({ showLoading: false, disabledButton: false });
                            });
                        }
                    });
                }
            });
        });
    },

    handleFieldChange(event, field) {
        const { fields } = controller.vm.state;

        if (field === 'bankBranch') {
            fields[field] = event.target.value;
            controller.validateBankBranch();
        }

        if (field === 'accountNumber') {
            fields[field] = event.target.value;
            controller.validateAccountNumber();
        }

        if (field === 'amount') {
            fields[field] = event.target.value;
            controller.validateAmount();
        }
        controller.vm.setState({ fields });
    },

    handleAmountChange(value) {
        const { fields } = controller.vm.state;
        fields.amount = value;
        controller.vm.setState({ fields });
        controller.validateAmount();
    },

    validateAmount() {
        const { fields } = controller.vm.state;
        window.SPL_Transaction.validateAmount(fields.amount, controller.vm.props.limit).then((errMsg) => {
            if (errMsg) {
                controller.vm.setState({ amountErrMsg: errMsg });
                controller.checkError();
            }
        });
    },

    validateAccountNumber() {
        const { fields } = controller.vm.state;
        window.SPL_Transaction.validateAccountNumber(fields.accountNumber).then((errMsg) => {
            if (errMsg) {
                controller.vm.setState({ accountNumberErrMsg: errMsg });
                controller.checkError();
            }
        });
    },

    validateBankBranch() {
        const { fields } = controller.vm.state;
        window.SPL_Transaction.validateBankBranch(fields.bankBranch).then((errMsg) => {
            if (errMsg) {
                for (let i = 0; i < errMsg.length; i++) {
                    if (errMsg[i] === 'BANK_BRANCH_IS_REQUIRED') {
                        errMsg[i] = `inputHelpBlock:inputHelpBlock.transfer.bankBranchRequired`;
                    }
                }
                controller.vm.setState({ bankBranchErrMsg: errMsg });

                controller.checkError();
            }
        });
    },

    checkError() {
        const { fields } = controller.vm.state;
        if (controller.vm.state.amountErrMsg.length > 0 || controller.vm.state.accountNumberErrMsg.length > 0 || fields.accountNumber === '' || fields.amount === '') {
            controller.vm.setState({ disabledButton: true });
            return true;
        } else {
            controller.vm.setState({ disabledButton: false });
            return false;
        }
    },

    handleReceiptImageChange(e) {
        wd_handleReceiptImageChange(controller.vm, e);
    },

    handleClearReceipt() {
        wd_handleClearReceipt(controller.vm);
    },
};

export default controller;
