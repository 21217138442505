import { authSettingsConstant } from '../constant';
import { portalAction } from '../../redux/action';
import { MODULE_TYPE, OTP_VERIFICATION_METHOD } from '@constants';

export const authSettingsAction = {
    fetchAuthSettings,
    setTurnstileEnabled,
    setGoogleMemberRegistered,
    setModuleGoogleRegistered,
    setModuleSixPinRegistered,
    setSixPinMemberRegistered,
    setSixPinLocked,
    setModuleSixPinLocked,
};

function fetchAuthSettings(splConfig, reqUpdate) {
    return async (dispatch, getState) => {
        const { portal, authSettingsReducer, user } = getState();

        if (!splConfig) return;

        if (!splConfig?.isPublic) {
            splConfig = {
                ...splConfig,
                isPublic: false,
            };
        }

        const authType = splConfig?.otpOptions?.otpType;

        // v1 data caching
        if (authSettingsReducer?.rawData?.[authType] && !reqUpdate) {
            // return { [authType]: authSettingsReducer.rawData[authType] };

            return {
                authType,
                rawData: authSettingsReducer.rawData[authType],
                processedData: authSettingsReducer.processedData[authType],
            };
        }
        // v2 data caching
        if (splConfig?.version === 'v2' && authSettingsReducer?.processedData?.[authType] && !reqUpdate) {
            return {
                authType,
                processedData: authSettingsReducer.processedData[authType],
            };
        }

        const getCategorizedAuthSettings = splConfig?.version === 'v2' ? window.SPL_Member.getCategorizedAuthSettingsV2 : window.SPL_Member.getCategorizedAuthSettings;

        try {
            let response = await getCategorizedAuthSettings(splConfig);
            // old code
            // const processedAuthMethods = (response?.data?.authMethod || []).filter((method) => {
            //     if (method.type === 'PHONE' && !user?.account?.phone) {
            //         return false;
            //     }
            //     if (method.type === 'EMAIL' && !user?.account?.email) {
            //         return false;
            //     }
            //     return true;
            // });

            // const processedData = {
            //     ...((!splConfig?.version === 'v2' && response?.data) || response),
            //     authMethod: processedAuthMethods,
            // };

            // dispatch({ type: authSettingsConstant.FETCH_AUTH_SETTINGS, payload: { authType: authType, rawData: response?.data, processedData: processedData } });
            if (splConfig?.version === 'v2') {
                const disableTurnstile = (response) => {
                    response.otpTurnstileEnabled = false;
                    response.turnstileEnabled = false;
                };
                const skipTurnstileForLiteApp = window.SPL_LiteApp.isLiteApp() && !portal?.settings?.isAllowTurnstileForLiteApp;
                // if skip site key early exist the process
                if (!skipTurnstileForLiteApp) {
                    try {
                        const siteKeyResponse = await window.SPL_Member.getCloudflareTurnstileSetting();
                        // const passkey = '1x00000000000000000000AA';
                        // const blockkey = '2x00000000000000000000AB';
                        // const forcekey = '3x00000000000000000000FF';
                        const siteKey = siteKeyResponse?.site_key || '';
                        // Set turnstile flags only if siteKey exists
                        if (siteKey) {
                            dispatch(portalAction.fetchCloudFlareTurnstileSetting(siteKey));
                            response.turnstileEnabled = !!response.turnstileEnabled;
                            response.otpTurnstileEnabled = !!response.otpTurnstileEnabled;
                        } else {
                            disableTurnstile(response);
                        }
                    } catch (error) {
                        console.error('Error fetching Cloudflare Turnstile setting:', error);
                        disableTurnstile(response);
                    }
                } else {
                    disableTurnstile(response);
                }

                const processedData = {
                    ...response,
                };

                dispatch({ type: authSettingsConstant.FETCH_AUTH_SETTINGS, payload: { authType: authType, rawData: response?.data, processedData: processedData } });
            } else {
                let processedAuthMethods = null;
                if (response?.data?.[OTP_VERIFICATION_METHOD.OTP]?.authMethod) {
                    processedAuthMethods = (response?.data?.[OTP_VERIFICATION_METHOD.OTP]?.authMethod || []).filter((method) => {
                        if ((method === 'PHONE' && !user?.account?.phone) || (method === 'EMAIL' && !user?.account?.email)) {
                            return false;
                        }
                        return true;
                    });
                    // handle userverification module enable or not
                    if (authType === MODULE_TYPE.USER_VERIFICATION) {
                        response.data.userVerificationEmailEnabled = response?.data?.[OTP_VERIFICATION_METHOD.OTP]?.authMethod.includes('EMAIL');
                        response.data.userVerificationPhoneEnabled =
                            response?.data?.[OTP_VERIFICATION_METHOD.OTP]?.authMethod.includes('PHONE') ||
                            response?.data?.[OTP_VERIFICATION_METHOD.OTP]?.authMethod.includes('WHATSAPP');
                    }
                } else {
                    processedAuthMethods = (response?.data?.authMethod || []).filter((method) => {
                        if (method.type === 'PHONE' && !user?.account?.phone) {
                            return false;
                        }
                        if (method.type === 'EMAIL' && !user?.account?.email) {
                            return false;
                        }
                        return true;
                    });
                }

                const processedData = {
                    ...response?.data,
                    authMethod: processedAuthMethods,
                };

                // return { [authType]: response?.data };
                dispatch({ type: authSettingsConstant.FETCH_AUTH_SETTINGS, payload: { authType: authType, rawData: response?.data, processedData: processedData } });
            }
        } catch (err) {
            console.error(err);
        }
    };
}

function setTurnstileEnabled(getPayload) {
    return (dispatch) => {
        return new Promise((resolve) => {
            dispatch({ type: authSettingsConstant.SET_TURNSTILE_ENABLE, payload: { module: getPayload?.module, moduleEnableTurnstile: getPayload?.moduleEnableTurnstile } });
            resolve();
        });
    };
}

// only update on BIND_AUTHENTICATION module
function setGoogleMemberRegistered(isGaRegistered) {
    return (dispatch) => {
        return new Promise((resolve) => {
            // transitionFromUnbindToBind indicate user just from unbind status become binded. To control some logic e.g. detect this and update module auth-settings again.
            dispatch({ type: authSettingsConstant.SET_GOOGLE_MEMBER_REGISTERED, payload: { gaRegistered: isGaRegistered, transitionFromUnbindToBindGA: true } });
            resolve();
        });
    };
}

// update on the module member entered, (dependency on setGoogleMemberRegistered scenario only).
// only will set true, hence don't need pass flag
function setModuleGoogleRegistered(module) {
    return (dispatch) => {
        return new Promise((resolve) => {
            // transitionFromUnbindToBind indicate user just from unbind status become binded. To control some logic e.g. detect this and update module auth-settings again.
            dispatch({ type: authSettingsConstant.SET_MODULE_GOOGLE_REGISTERED, payload: { module: module } });
            resolve();
        });
    };
}

// only update on SIX_PIN module
function setSixPinMemberRegistered() {
    return (dispatch) => {
        return new Promise((resolve) => {
            // transitionFromUnbindToBindSixPin indicate user just from unbind status become binded. To control some logic e.g. detect this and update module auth-settings again.
            dispatch({ type: authSettingsConstant.SET_SIX_PIN_MEMBER_REGISTERED, payload: {} });
            resolve();
        });
    };
}

function setModuleSixPinRegistered(module) {
    return (dispatch) => {
        return new Promise((resolve) => {
            // transitionFromUnbindToBind indicate user just from unbind status become binded. To control some logic e.g. detect this and update module auth-settings again.
            dispatch({ type: authSettingsConstant.SET_MODULE_SIX_PIN_REGISTERED, payload: { module: module } });
            resolve();
        });
    };
}

// set pin locked, when member exceed limit, set lock status to trigger rerender for sixPinNotice component
function setSixPinLocked() {
    return (dispatch) => {
        return new Promise((resolve) => {
            // transitionFromUnbindToBindSixPin indicate user just from unbind status become binded. To control some logic e.g. detect this and update module auth-settings again.
            dispatch({ type: authSettingsConstant.SET_SIX_PIN_LOCKED, payload: {} });
            resolve();
        });
    };
}

// when setSixPinLocked trigger, useEffect will auto trigger setModuleSixPinLocked by module
function setModuleSixPinLocked(module) {
    return (dispatch) => {
        return new Promise((resolve) => {
            // transitionFromUnbindToBindSixPin indicate user just from unbind status become binded. To control some logic e.g. detect this and update module auth-settings again.
            dispatch({ type: authSettingsConstant.SET_MODULE_SIX_PIN_LOCKED, payload: { module: module } });
            resolve();
        });
    };
}
