import React from 'react';
import { userAction } from 'root/redux/action';
import { Translation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { mapStateToProps } from 'root/redux/selector/selector';
import { withRouter } from 'react-router-dom';
import SVGElements from 'root/components/SVGElements';
import ImageHelper from 'root/pages/Share/image-helper';
import { LodashUtils } from 'root/utils/lodash-util';

class Menu extends React.Component {
    isMount = true;
    constructor(props) {
        super(props);
        const href = window.location.href;
        const route = href.substr(href.lastIndexOf('/') + 1) || 'index.html';
        this.state = {
            route: route,
            isSticky: false,
            menus: [],
        };
    }

    componentDidMount() {
        this.isMount = true;
        this.getDisplayMenu();
        window.addEventListener('scroll', () => {
            let scrollTop = document.body.scrollTop || document.documentElement.scrollTop;
            const top = window.innerWidth > 1104 ? 243 : 50;
            this.setState(() => ({
                isSticky: scrollTop > top ? true : false,
            }));
        });
    }

    componentWillUnmount() {
        this.isMount = false;
    }

    getDisplayMenu = () => {
        const { language } = this.props;

        window.SPL_Content.getDisplayMenu(language.countryLanguageKey, 'web').then((data) => {
            if (this.isMount) {
                this.setState({ menus: data }, () => {
                    this.addMenu();
                });
            }
        });
    };

    addMenu = () => {
        const { user, language } = this.props;
        const countryLanguageKey = LodashUtils.get(language, 'countryLanguageKey', '');
        const currencyLang = LodashUtils.get(language, 'currencyLang', '');
        let menu = this.state.menus;

        const favourite = {
            id: 20,
            name: 'FAVOURITE',
            sref: 'favourite',
            content: 'global:global.menu.favourite',
            route: '/favourite',
            imageMenu: '/public/html/images/drawer_img/favourite.png',
            imageMenu_ov: '/public/html/images/drawer_img/favourite-ov.png',
        };

        const mobileMenu = [
            // {
            //   id: 15,
            //   name: "DOWNLOAD",
            //   sref: "download",
            //   class: "mb-only",
            //   content: "global:global.menu.download",
            //   route: "/download",
            //   imageMenu: "/public/html/images/drawer_img/download.png",
            //   imageMenu_ov: "/public/html/images/drawer_img/download-ov.png"
            // },
            // {
            //   id: 16,
            //   name: "CONTACT",
            //   sref: "contact",
            //   class: "mb-only",
            //   content: "global:global.menu.contact",
            //   route: "/contact",
            //   imageMenu: "/public/html/images/drawer_img/contact.png",
            //   imageMenu_ov: "/public/html/images/drawer_img/contact-ov.png"
            // },
            // {
            //   id: 16,
            //   name: "LANGUAGE",
            //   sref: "lan-white",
            //   class: "mb-only",
            //   content: "global:global.menu.lang",
            //   route: "/language",
            //   imageMenu: "/public/html/images/drawer_img/lang.png",
            //   imageMenu_ov: "/public/html/images/drawer_img/lang-ov.png"
            // },
            // {
            //   id: 16,
            //   name: "LOGOUT",
            //   sref: "logout",
            //   class: `mb-only ${!this.props.user.isLogin && 'd-none'}`,
            //   content: "global:global.menu.logout",
            //   route: "/logout",
            //   imageMenu: "/public/html/images/drawer_img/logout.png",
            //   imageMenu_ov: "/public/html/images/drawer_img/logout-ov.png"
            // }
        ];

        for (let i = 0; i < menu.length; i++) {
            if (menu[i].sref === 'home') {
                menu[i].route = '/home';
            }

            if (menu[i].imageMenu) {
                let imgPath = menu[i].imageMenu.substr(0, menu[i].imageMenu.lastIndexOf('.') + 1);

                menu[i].imageMenu = imgPath + 'svg';
            }

            if (menu[i].imageMenu_ov) {
                let imgPath_ov = menu[i].imageMenu_ov.substr(0, menu[i].imageMenu_ov.lastIndexOf('.') + 1);

                menu[i].imageMenu_ov = imgPath_ov + 'svg';
            }

            if (i === 0) {
                if (this.props.language.country === 'MY') {
                    menu.splice(10, 0, favourite);
                } else if (this.props.language.country === 'SG') {
                    menu.splice(6, 0, favourite);
                } else if (this.props.language.country === 'VN') {
                    menu.splice(9, 0, favourite);
                }
            }
        }

        for (let k = 0; k < mobileMenu.length; k++) {
            menu.push(mobileMenu[k]);

            if (mobileMenu[k].imageMenu) {
                let imgPath = mobileMenu[k].imageMenu.substr(0, mobileMenu[k].imageMenu.lastIndexOf('.') + 1);

                mobileMenu[k].imageMenu = imgPath + 'svg';
            }

            if (mobileMenu[k].imageMenu_ov) {
                let imgPath_ov = mobileMenu[k].imageMenu_ov.substr(0, mobileMenu[k].imageMenu_ov.lastIndexOf('.') + 1);

                mobileMenu[k].imageMenu_ov = imgPath_ov + 'svg';
            }
        }

        const { screen, portal } = this.props;
        if (portal.settings && portal.settings.menuSportImage && portal.settings.menuSportImage[screen.viewType]) {
            for (let i = 0; i < menu.length; i++) {
                if (menu[i].name === 'Sportsbook') {
                    menu[i].imageMenuSvg = portal.settings.menuSportImage[screen.viewType];
                }
            }
        }

        const ignoreMenuHOC = (name) => (menu) => LodashUtils.toLower(menu.name) !== LodashUtils.toLower(name);
        const hideCockFight = !LodashUtils.isEqual(currencyLang, 'MYR') || !LodashUtils.get(user, 'isLogin');
        if (hideCockFight) {
            menu = menu.filter(ignoreMenuHOC('Cockfight'));
        }

        this.setState({ menus: menu }, () => {
            // function to auto scroll menu to view
            setTimeout(function () {
                let elems = document.getElementsByClassName('menu-item-container');
                let tabScroll = document.getElementById('tabScroll');
                let foundElem = null;
                let foundElemIndex = null;
                for (let i = 0; i < elems.length; i++) {
                    if (elems[i].classList.contains('current')) {
                        foundElem = elems[i];
                        foundElemIndex = i;
                        break;
                    }
                }
                if (tabScroll && foundElem && foundElemIndex) {
                    let itemWidth = foundElem.clientWidth;
                    let scrollToLeft = itemWidth * foundElemIndex;

                    // only scroll to view when item index > 4
                    if (foundElemIndex > 4) {
                        tabScroll.scrollLeft = scrollToLeft;
                    }
                    tabScroll = null;
                }
            }, 200);
        });
    };

    handle(info) {
        if (!window.isAgent && !window.isAffiliate) {
            if (window.SPL_LiteApp.isZT828LiteApp()) {
                window.SPL_LiteApp.onMenuCategoryToggle(info.submenu);
            }
        }

        if (/^#/.test(info.route)) {
            switch (info.route) {
                case '#login':
                    //"login logic"
                    break;
                case '#logout':
                    break;

                default:
                    break;
            }
            return;
        }

        if (info.route === '/logout') {
            this.props.dispatch(userAction.logout());
            this.props.history.push('/home');
        }

        this.props.history.push(info.route);
    }

    render() {
        const { portal } = this.props;
        let isCurrentlyOlympic = false;
        if (portal && portal.settings && portal.settings.isOlympic) {
            isCurrentlyOlympic = portal.settings.isOlympic;
        }

        return (
            <Translation>
                {(t) => (
                    <div
                        className={`bg-footer-bottom ${
                            this.props.screen.isMobile && this.props.user.isLogin && this.state.isSticky ? 'add-fix-top' : this.state.isSticky && 'fix-top'
                        }`}
                    >
                        <div className='content-container'>
                            <div className={`footer-bottom ${this.state.isSticky && 'menuChd'}`}>
                                <ul id='tabScroll' className='nav-menu'>
                                    {this.state.menus.map(
                                        (info) =>
                                            // this.props.screen.isMobile ?
                                            !['RAFFLE'].some((menuName) => info.name.includes(menuName)) && (
                                                <div
                                                    key={info.name}
                                                    className={
                                                        'menu-item-container ' +
                                                        info.class +
                                                        ' ' +
                                                        (this.props.history.location.pathname.includes(info.route) ? 'current' : '')
                                                    }
                                                    onClick={this.handle.bind(this, info)}
                                                >
                                                    <span className={`icon-box ${this.state.isSticky && 'menuChd-icon'}`}>
                                                        {info.imageMenuSvg ? (
                                                            <ImageHelper displayImg={info.imageMenuSvg} className='fifa-icon' />
                                                        ) : (
                                                            <SVGElements
                                                                className={`icon-menu menu-${info.name === 'Sportsbook' && isCurrentlyOlympic ? 'olympic' : info.sref} ${
                                                                    this.props.history.location.pathname.includes(info.route) ? 'on' : 'off'
                                                                }`}
                                                                name={(info.name === 'Sportsbook' && isCurrentlyOlympic ? 'olympic' : info.name.toLowerCase()) + '-nav-icon'}
                                                            />
                                                        )}
                                                    </span>
                                                    <span className={`menu-name ${this.state.isSticky && 'menuChd-text'}`}>{t(info.content)}</span>
                                                </div>
                                            )
                                    )}
                                </ul>
                            </div>
                        </div>
                    </div>
                )}
            </Translation>
        );
    }
}

export default connect(mapStateToProps)(withTranslation(['slot'])(withRouter(Menu)));
