// function
import { userAction } from 'root/redux/action';
// import { userVerificationData } from '../../16MyAccount/161_MyAccount_Items_Data';
import { userVerificationData } from 'root/pages/16MyAccount/161_MyAccount_Items_Data';
import { authSettingsAction } from '@redux/action';
import commonUtil from 'root/utils/common-util';

// NOTE: This file is same as commmon-util
// MAIN PURPOSE: enhancement project performance, without import all the unused function
// The way is to export function 1 by 1 for this file
// All function name start from mu (member util) to indicate the function come from here

export function mu_getContactVerification(vm) {
    window.SPL_Member.loadContactsVerification().then((res) => {
        if (res) {
            const { verifications } = vm.props.user;
            let { filteredVerifyOptions, memberVerifyOptions } = vm.state;
            filteredVerifyOptions = [];

            let userVerificationStep = verifications.userVerificationStep;
            let userFullyVerified = false;

            // process verification bar
            // some merchant may dont have some verification, step+ only when supported verification and the value from api is matched
            for (let i = 0; i < memberVerifyOptions.length; i++) {
                let verifyOption = memberVerifyOptions[i];
                const { splKey } = verifyOption;
                if (res[splKey]) {
                    userVerificationStep++;
                    verifyOption.isVerified = true;
                } else {
                    filteredVerifyOptions.push(verifyOption);
                }
            }
            userFullyVerified = userVerificationStep === memberVerifyOptions.length ? true : false;

            mu_updateUserVerifications(vm, { ...res, userVerificationStep });
            vm.setState({
                userFullyVerified: userFullyVerified,
                filteredVerifyOptions,
            });
        }
    });
}

export function mu_getContactVerificationStatus(vm) {
    const { user } = vm.props;
    let param = {
        email: user.account.email,
    };

    window.SPL_Member.getContactVerificationStatus(param).then((status) => {
        if (status && status !== 'error') {
            vm.setState({ checkEmailStatus: 'ENTER_OTP' });
        } else {
            vm.setState({ checkEmailStatus: '' });
        }
    });
}

export function mu_updateUserVerifications(vm, verifications) {
    vm.props.dispatch(userAction.updateUserVerifications(verifications));
}

export function mu_getMemberVerifyOptions(vm, settingsConfiguration = {}) {
    const { user, t, authSettingsReducer } = vm.props;
    const { memberVerificationSettings } = settingsConfiguration;

    const filterAuthMethod = commonUtil.filterAuthMethod(authSettingsReducer, 'USER_VERIFICATION');

    let _memberVerifyOptions = [];

    if (memberVerificationSettings) {
        const { phoneVerification, emailVerification, birthdateVerification, bankVerification } = memberVerificationSettings;
        if (birthdateVerification && birthdateVerification.includes(user.account.currency)) {
            _memberVerifyOptions.push(userVerificationData.birthday);
        }
        if (filterAuthMethod?.includes('EMAIL')) {
            _memberVerifyOptions.push(userVerificationData.email);
        }
        if (filterAuthMethod?.includes('PHONE')) {
            _memberVerifyOptions.push(userVerificationData.phone);
        }
        if (bankVerification && bankVerification.includes(user.account.currency)) {
            _memberVerifyOptions.push(userVerificationData.withdrawal);
        }

        for (let i = 0; i < _memberVerifyOptions.length; i++) {
            let option = _memberVerifyOptions[i];
            if (option && option.i18n) {
                option.i18n = t(option.i18n);
            }
        }
    }
    return _memberVerifyOptions;
}

export function mu_fetchAuthSettings(dispatch, module, reqUpdate) {
    const splConfig = {
        otpOptions: {
            isPublic: false,
            otpType: module,
        },
    };
    dispatch(authSettingsAction.fetchAuthSettings(splConfig, reqUpdate));
}
