/*globals playerjs:true*/
import { dropdownAction } from 'root/redux/action';
import { gu_getByViewType, gu_getContentByCurrencyLanguage, gu_getSettingFromPortalSettings } from './general-util';

let scheduledTimeout;
const commonUtil = {
    getAppDownloadLink(vm) {
        window.SPL_JsonSettings.getAppDownloadLinkJson((data) => {
            if (data) vm.setState({ appDownloadLinkData: data });
        });
    },

    // NOTE: must pass string else error in lib
    getQRCodeValue(value) {
        if (!value) return '';

        let check = value.charAt(0);
        let checkHttp = value.substring(0, 4);
        if (check === '/' && checkHttp !== 'http') {
            return window.location.origin + value;
        } else {
            return value;
        }
    },

    onAppDownloadClick(device, downloadLink) {
        if (device === 'android') {
            let link = document.createElement('a');
            link.download = `${window.globMerchantName}.apk`;
            link.href = downloadLink;
            link.target = '_blank';

            document.body.appendChild(link);
            link.click();

            document.body.removeChild(link);
            link = null;
        } else {
            window.open(downloadLink, '');
        }
    },

    getIsZT828LiteApp() {
        if (!window.isAgent && !window.isAffiliate && window.SPL_LiteApp.isZT828LiteApp()) return true;
        return false;
    },

    redirectToDeposit(history, timer = 4000) {
        if (scheduledTimeout) {
            clearTimeout(scheduledTimeout);
        }

        scheduledTimeout = setTimeout(() => {
            if (!commonUtil.getIsZT828LiteApp()) {
                history.push('/myaccount/deposit');
                document.location.reload();
            } else {
                window.SPL_LiteApp.onRegisterCompletedRedirect();
            }
        }, timer);
    },

    loadCustomTranslation(currentPage, vm, callback) {
        let customTranslationObj = {};

        let countryLanguageKey = null;
        let combinedPagesTranslation = {};

        if (vm.props.language && vm.props.language.countryLanguageKey) {
            countryLanguageKey = vm.props.language.countryLanguageKey.toLowerCase();
        } else if (vm.props.countryLanguageKey) {
            countryLanguageKey = vm.props.countryLanguageKey.toLowerCase();
        }

        if (countryLanguageKey === null) {
            return;
        }

        if (vm.props && vm.props.portal.settings && vm.props.portal.settings.features && vm.props.portal.settings.features.loadCustomTranslation) {
            window.SPL_Content.getCustomTranslation().then((data) => {
                if (Array.isArray(currentPage)) {
                    let tempPageKeyi18n = [];
                    for (let i = 0; i < currentPage.length; i++) {
                        // pageKey = currentPageKey
                        let pageKey = currentPage[i];
                        if (data && data[countryLanguageKey] && data[countryLanguageKey][pageKey]) {
                            tempPageKeyi18n.push(commonUtil.getByViewType(data[countryLanguageKey][pageKey], vm));
                            if (i === 0) {
                                combinedPagesTranslation = tempPageKeyi18n[0];
                            } else if (i > 0 && tempPageKeyi18n[i - 1]) {
                                combinedPagesTranslation = commonUtil.jsonConcat(combinedPagesTranslation, tempPageKeyi18n[i]);
                            }
                        } else if (data && data[pageKey]) {
                            let filteredData = commonUtil.getContentByCurrencyLanguage(vm, data[pageKey], { showUnderScore: true });
                            filteredData = commonUtil.getByViewType(filteredData, vm);
                            if (filteredData) {
                                for (let key in filteredData) {
                                    if (typeof filteredData[key] === 'object') {
                                        filteredData[key] = commonUtil.getContentByCurrencyLanguage(vm, filteredData[key], { showUnderScore: true });
                                    }
                                }
                                tempPageKeyi18n.push(filteredData);
                                if (i === 0) {
                                    combinedPagesTranslation = tempPageKeyi18n[0];
                                } else if (i > 0 && tempPageKeyi18n[i - 1]) {
                                    combinedPagesTranslation = commonUtil.jsonConcat(combinedPagesTranslation, tempPageKeyi18n[i]);
                                }
                            }
                        }
                    }

                    customTranslationObj = combinedPagesTranslation;
                } else {
                    if (data && data[countryLanguageKey] && data[countryLanguageKey][currentPage]) {
                        customTranslationObj = commonUtil.getByViewType(data[countryLanguageKey][currentPage], vm);
                    } else if (data && data[currentPage]) {
                        let filteredData = commonUtil.getContentByCurrencyLanguage(vm, data[currentPage], { showUnderScore: true });
                        filteredData = commonUtil.getByViewType(filteredData, vm);
                        if (filteredData) {
                            for (let key in filteredData) {
                                if (typeof filteredData[key] === 'object') {
                                    filteredData[key] = commonUtil.getContentByCurrencyLanguage(vm, filteredData[key], { showUnderScore: true });
                                }
                            }
                            customTranslationObj = filteredData;
                        }
                    }
                }

                vm.setState({ customTranslationObj }, () => {
                    callback && callback();
                });
            });
        } else {
            vm.setState({ customTranslationObj }, () => {
                callback && callback();
            });
        }
    },

    // Params
    // vm (Required) -> this
    // settingName (Required) -> the name of setting is getting
    // defaultValue -> return specific value if setting not found
    // notFeatures -> if the setting not inside features set true
    // notViewType -> if the setting not contain 'web' and 'mobile' set true
    // isCurrencyLang -> if the setting is define by CurrencyLanguage or countryLanguageKey
    getSettingFromPortalSettings(params) {
        return gu_getSettingFromPortalSettings(params);
    },

    pauseEmbedVideo(iframe) {
        const player = new playerjs.Player(iframe);
        player.on('ready'); // not sure why have to subscfribe ready
        player.pause();
    },

    subscribeEmbedVideoEvent(iframe) {
        const player = new playerjs.Player(iframe);
        player.on('ready', () => {
            player.on('play');
            player.on('pause');
            player.on('mute');
        });
    },

    unsubscribeEmbedVideoEvent(iframe) {
        const player = new playerjs.Player(iframe);
        player.off('ready');
        player.off('play');
        player.off('pause');
        player.off('mute');
    },

    getDepositImgPath() {
        return '/public/html/images/deposit/';
    },

    getIsFormattedImageSetting(vm) {
        const { depositPagesSettings } = vm.props.portal.settings.features;
        if (depositPagesSettings) return depositPagesSettings.isFormattedImage;
        return null;
    },

    getDepositMinMax(vm, transactionOption, currency, bank, coin = null, bankType, callback) {
        vm.setState({ isLoading: true });
        let param = {};
        if (transactionOption === 'CT') {
            param = {
                transactionOption: transactionOption,
                currency: currency,
                bankCode: bank,
                cryptoCoin: coin,
                merchantCode: window.merchantCode,
                bankType: bankType,
            };
        } else {
            if (isNaN(bank)) {
                param = {
                    transactionOption: transactionOption,
                    currency: currency,
                    bankCode: bank,
                    merchantCode: window.merchantCode,
                };
            } else {
                param = {
                    transactionOption: transactionOption,
                    currency: currency,
                    id: bank,
                    merchantCode: window.merchantCode,
                };
            }
        }
        window.SPL_Transaction.getDepositMinMax(param).then((data) => {
            if (data) {
                let insertData = null;
                if (transactionOption === 'QP') {
                    insertData = { minLimitQuick: null, maxLimitQuick: null };
                    insertData.minLimitQuick = data.min;
                    insertData.maxLimitQuick = data.max;
                } else {
                    insertData = data;
                }
                vm.setState({ depositMinMaxLimit: insertData });
            }
            vm.setState({ isLoading: false });
            callback && callback();
        });
    },

    // combine 2 json into 1
    jsonConcat(json1, json2, vm) {
        let jsonResult = window.SPL_Other.deepCopy(json1);

        for (var key in json2) {
            let certainTranslate = commonUtil.getContentByCurrencyLanguage(vm, json2[key]);

            if (!(json2 && json2[key] && json2[key].disableDefaultTranslate && !certainTranslate)) {
                jsonResult[key] = json2[key];
            }
        }

        return jsonResult;
    },
    // Simple function to get JSON by view type, will fallback return original obj if view type not found
    // obj -> the obj/json needed to handle, viewType is obj['web'] / obj['mobile']
    // vm -> this
    getByViewType(obj, vm) {
        return gu_getByViewType(obj, vm); // moved to general-util.js
    },

    getContentByCurrencyLanguage(vm, content, countryLanguageKeySettings = {}) {
        return gu_getContentByCurrencyLanguage(vm, content, countryLanguageKeySettings); // moved to general-util.js
    },

    readCustomDepositArticle(vm, fileNameKey = '', callback = (content) => content) {
        const { enableCustomArticleJSON } = vm.state.compSettings;
        window.SPL_Content.readCustomDepositArticle(vm.props.language.countryLanguageKey, fileNameKey, enableCustomArticleJSON).then((data) => {
            if (data) {
                let content = null;
                let tempContent = null;
                if (enableCustomArticleJSON) {
                    tempContent = commonUtil.getByViewType(data, vm);
                    content = commonUtil.getContentByCurrencyLanguage(vm, tempContent.content);
                } else {
                    content = data;
                }

                vm.setState({
                    showCustomDepositArticle: true,
                    showCustomDepositArticleContent: callback(content),
                    customArticleIsJson: enableCustomArticleJSON ? true : false,
                    customContentStyle: tempContent && tempContent.style,
                });
            }
        });
    },

    //dropdownDispatch
    dropdownUpdate(e = false, item = false, props = false, state = null, hide = null) {
        let dropdownObj = {};

        if (
            e &&
            e.target &&
            (e.target.id === 'dropdownBtn' ||
                e.target.id === 'dropdownContainer' ||
                (e.target.parentElement && (e.target.parentElement.id === 'dropdownBtn' || e.target.parentElement.id === 'dropdownContainer')))
        ) {
            if (item) {
                const dropDownObj = props.dropdown ? props.dropdown : props.oriProps.dropdown;
                dropdownObj = {
                    item: item,
                    isToggle: true,
                    footerLanguageDropdown: dropDownObj.footerLanguageDropdown,
                    languageDropdown: dropDownObj.languageDropdown,
                    myAccountDropdown: dropDownObj.myAccountDropdown,
                    telcodeDropdown: dropDownObj.telcodeDropdown,
                    providerDropdown: dropDownObj.providerDropdown,
                    certainElementDropdown: hide ? null : state,
                    guideDD: props.dropdown.guideDD,
                    depositProgressDD: props.dropdown.depositProgressDD,
                };

                if (props.dropdown.certainElementDropdown && state === props.dropdown.certainElementDropdown) {
                    dropdownObj = false;
                }

                props.dispatch(dropdownAction.dropdownUpdate(dropdownObj));
            }
        } else {
            dropdownObj = false;
            props.dispatch(dropdownAction.dropdownUpdate(dropdownObj));
        }
    },

    filterAuthMethod(authSettingsReducer, groupType) {
        const method = authSettingsReducer?.processedData?.[groupType]?.authMethod;

        return (method || []).map((item) => item.type);
    },

    // getCustomProviderName(provider) {
    //     let providerName = null;
    //     // custom-translation.json

    //     if (provider === 'PP') {
    //         providerName = 'Pragmatic Slot';
    //     } else if (provider === 'PPL') {
    //         providerName = 'Pragmatic Casino';
    //     } else if (provider === 'EBET') {
    //         providerName = 'Maxim88 Casino';
    //     }

    //     return providerName;
    // },

    // getCustomProvider(provider, fromTo) {
    //     let newProvider = null;

    //     if (provider === 'EBET') {
    //         newProvider = 'MAXIM88';
    //     } else if (provider === 'M8') {
    //         newProvider = 'M8 Sports';
    //     } else if (['SCR2', 'SCR'].includes(provider)) {
    //         newProvider = '918Kiss';
    //     }

    //     return newProvider;
    // },
};

export default commonUtil;
