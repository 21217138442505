import moment from 'moment';
import 'rc-datetime-picker/dist/picker.css';
import React, { Component } from 'react';
import { Trans, Translation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { mapStateToProps } from '../../redux/selector/selector';
import SmallLoading from '../../components/Loading/SmallLoading';
import depositMaterial from './deposit-method';
import SVGElements from 'root/components/SVGElements';
import { Nav, NavLink, NavItem } from 'reactstrap';
import classnames from 'classnames';
import Select from 'react-dropdown-select';
import TransactionNote from '../../pages/Share/transaction-note/component/transaction-note';
import { trans_handlePGDepositCountDown, trans_getDepositAmountOptionByMethod } from 'root/utils/transaction-util';
import notification from 'root/utils/notification';
import { AMOUNT_DEPOSIT_METHOD_CODE } from 'root/utils/constants';
import PaymentGatewayIcon from '../../components/Molecules/payment-icon';
import BankIcon from '../../components/Molecules/bank-icon';
import { scrollToDepositStep2 } from './deposit-util';
import commonUtil from '@utils/common-util';

class QRPay extends Component {
    isMount = true;
    constructor(props) {
        super(props);
        let currentDate = new Date();
        let currentDateTime = moment(Date.now()).format('YYYY-MM-DD hh:mm a');
        this.toggle = this.toggle.bind(this);
        this.state = {
            isLoading: true,
            activeTab: 'qrpay',
            depositDetails: null,
            selectedMethod: [],
            selectedBank: [], // why is this an array..
            amount: '',
            amountErrMsg: [],
            disabledDepositButton: true,
            limitAmount: '',
            currency: '',
            bankList: [],
            depositDate: moment(currentDateTime, 'YYYY-MM-DD hh:mm a'),
            depositDateString: moment(currentDateTime, 'YYYY-MM-DD hh:mm a').format('YYYY-MM-DDTHH:mm'),
            selectedDepositBank: null,
            status: '',
            message: '',
            moment: moment(),
            start: new Date(new Date().setMonth(currentDate.getMonth() - 1)),
            end: currentDate,
            getTncReferral: false,
            depositNotice: '',
            depositMinMaxLimit: { min: 0, max: 0 },
            qrPayDepositChannelObjs: [],
            filteredQRPayList: [],
            selectedChannel: '',
            depositSubmitCountdown: null,
            depositAmountOptions: trans_getDepositAmountOptionByMethod(this.props.depositAmountOptions, AMOUNT_DEPOSIT_METHOD_CODE.QR),
        };
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({ activeTab: tab }, () => {
                this.setState({ amount: '', errMsgLimitAmount: [], amountErrMsg: [] });
            });
        }
    }

    handleBankChange = (value) => {
        const { selectedMethod } = this.props;
        const cachedData = depositMaterial.getDepositMethod(selectedMethod[0].methodKey);
        const oldSelectedBank = this.state.selectedBank;
        this.getCorrectDepositMinMaxLimit(cachedData.depositSettings);

        this.setState({ selectedBank: [value] }, () => {
            const {
                enableScrollToDepositStep2 = false,
                scrollToDepositStep2Attempts = 15,
                scrollToDepositStep2Delay = 200,
            } = this.props.portal?.settings?.features?.depositSettings || {};

            scrollToDepositStep2(enableScrollToDepositStep2, oldSelectedBank, this.state.selectedBank, scrollToDepositStep2Attempts, scrollToDepositStep2Delay);

            this.checkError();
        });
    };

    componentDidMount() {
        this.initCompSettings();
        this.getDepositDetailsOnMethodChange();
    }

    componentWillUnmount() {
        this.isMount = false;
    }

    componentDidUpdate(prevProps) {
        if (prevProps.selectedMethod !== this.props.selectedMethod) {
            this.getDepositDetailsOnMethodChange();
            this.setState({ message: '' });
        }
    }

    initCompSettings() {
        const { filteredDepositApiJson } = this.state;
        const paymentCode = filteredDepositApiJson && filteredDepositApiJson.optionCode;

        return new Promise((resolve) => {
            const depositSettings = commonUtil.getSettingFromPortalSettings({
                vm: this,
                settingName: 'depositSettings',
            });

            const compSettings = {
                customCountdown: depositSettings?.customCountdown,
            };

            this.setState({ compSettings }, () => {
                trans_handlePGDepositCountDown(this, false, compSettings.customCountdown, paymentCode, Date.now().valueOf(), { disabledDepositButton: true });
                resolve();
            });
        });
    }

    getDepositDetails = () => {
        window.SPL_Transaction.loadDepositRelatedSettings().then((data) => {
            if (data) {
                this.setState({ depositNotice: data.depositJson.quickPay.custom_article });
            }
        });
    };

    getDepositDetailsOnMethodChange = () => {
        this.setState({ isLoading: true }, () => {
            const { depositDetails, selectedMethod, user, isFirstTimeInit, screen } = this.props;

            const cachedData = depositMaterial.getDepositMethod(selectedMethod[0].methodKey);
            if (cachedData) {
                this.getCorrectDepositMinMaxLimit(cachedData.depositSettings);
                this.setState(
                    {
                        depositDetails: cachedData,
                        isLoading: false,
                        filteredQRPayList: cachedData.qrPayList,
                        selectedChannel: cachedData.qrPayDepositChannelObjs[0].channel,
                    },
                    () => {
                        this.validateDepositAmount();
                    },
                );
            } else {
                // if first time init, then no need getDepositDetailsOnMethodChange, striahgt use props to avoid duplicate api
                if (isFirstTimeInit) {
                    if (this.isMount) {
                        this.setState(
                            {
                                depositDetails: depositDetails,
                                isLoading: false,
                                filteredQRPayList: depositDetails.qrPayList,
                            },
                            () => {
                                this.validateDepositAmount();
                                depositMaterial.setDepositMethod(selectedMethod[0].methodKey, depositDetails);
                            },
                        );
                    }
                } else {
                    window.SPL_Transaction.loadDepositRelatedSettings(user.account.currency).then((depositSettings) => {
                        if (this.isMount) {
                            const { qrPayList } = this.props;

                            let qrPayDepositChannelObjs = []; //[{ channel: 'test', channelName: 'dummy' }];
                            let qrPayDepositChannels = []; //['test'];
                            for (let i = 0; i < qrPayList.length; i++) {
                                const currentQRPay = qrPayList[i];
                                currentQRPay.depositChannelName = window.SPL_Transaction.getQRDepositChannelName(currentQRPay.bank);
                                currentQRPay.isQRPay = true;

                                if (currentQRPay.bank.channel) {
                                    let qrPayDepositChannelObj = { channel: currentQRPay.bank.channel, channelName: currentQRPay.depositChannelName };
                                    qrPayDepositChannelObjs.push(qrPayDepositChannelObj);
                                    qrPayDepositChannels.push(currentQRPay.bank.channel);
                                }
                            }

                            for (let j = qrPayDepositChannels.length - 1, l = 0; j > l; j--) {
                                if (qrPayDepositChannels.indexOf(qrPayDepositChannels[j]) !== j) {
                                    qrPayDepositChannelObjs.splice(j, 1);
                                }
                            }

                            let currQRPayList = qrPayList;
                            currQRPayList = qrPayList.filter((e) => e.bank && e.bank.channel === qrPayDepositChannelObjs[0].channel);

                            this.getCorrectDepositMinMaxLimit(depositSettings);

                            // qrpaylist, channellist that's all
                            const qrPayData = Object.assign(
                                {},
                                {
                                    depositSettings,
                                    qrPayList: currQRPayList,
                                    qrPayDepositChannelObjs,
                                    selectedChannel: qrPayDepositChannelObjs[0].channel,
                                },
                            );
                            this.setState(
                                {
                                    isLoading: false,
                                    qrPayDepositChannelObjs,
                                    filteredQRPayList: currQRPayList,
                                    depositDetails: qrPayData,
                                    selectedChannel: qrPayDepositChannelObjs[0].channel,
                                },
                                () => {
                                    this.validateDepositAmount();
                                    depositMaterial.setDepositMethod(selectedMethod[0].methodKey, qrPayData);
                                },
                            );
                        }
                    });
                }
            }
        });
    };

    mapBankImg = (banks) => {
        let currency = null;

        if (this.props.user && this.props.user.account) {
            currency = this.props.user.account.currency;
        }

        if (currency && banks && banks.length > 0) {
            for (let i = 0; i < banks.length; i++) {
                const bank = banks[i];
                bank.imgOn = window.SPL_Content.mapBankHoverImg(bank.code, currency, true);
                bank.imgOff = window.SPL_Content.mapBankHoverImg(bank.code, currency, false);
            }
        }

        return banks;
    };

    loadBankAccounts = (callback) => {
        window.SPL_Transaction.loadAllOwnedAndUnOwnedBank().then((data) => {
            let memberBankList = data.memberBankList;

            for (let i = 0; i < memberBankList.length; i++) {
                const memberBank = memberBankList[i];
                memberBank.displayName = `${memberBank.bankName}`;
            }

            if (this.isMount) {
                this.setState({ bankList: data.bankList, memberBankList: memberBankList, unOwnedBankList: data.unOwnedBankList }, () => {
                    if (callback) {
                        callback();
                    }
                });
            }
        });
    };

    // handleAmountChange = (value) => {
    //     if (value) {
    //         if (value.toString().match(/^(\d*)\.{0,1}(\d){0,2}$/)) {
    //             this.setState({ amount: value, message: '' }, () => {
    //                 this.validateDepositAmount();
    //             });
    //         }
    //     } else {
    //         this.setState({ amount: '', message: '' }, () => {
    //             this.validateDepositAmount();
    //         });
    //     }
    // };

    disableDot = (e) => {
        if (e.keyCode == '46' || e.charCode == '46') {
            e.preventDefault();
        }
    };

    checkError = () => {
        const { amount, activeTab, amountErrMsg, selectedBank } = this.state;
        const { selectedMethod } = this.props;

        if (activeTab === 'qrpay') {
            if (!amount || amountErrMsg.length > 0 || selectedMethod.length <= 0 || selectedBank.length <= 0) {
                this.setState({ disabledDepositButton: true });
            } else {
                this.setState({ disabledDepositButton: false });
            }
        } else {
            const { amount, depositDateString, selectedDepositBank, selectedBank } = this.state;

            if (
                !amount ||
                selectedBank.length <= 0 || // (han added)
                !selectedDepositBank ||
                !depositDateString
            ) {
                this.setState({ disabledDepositButton: true });
            } else {
                this.setState({ disabledDepositButton: false });
            }
        }
    };

    handleSubmit() {
        const { amountErrMsg, selectedBank, amount, filteredDepositApiJson, depositSubmitCountdown, compSettings } = this.state;
        const { selectedMethod, language, depositSubmission, t } = this.props;
        const { customCountdown } = compSettings;

        const countdown = customCountdown || typeof customCountdown === 'number' ? customCountdown : 30;
        const paymentCode = filteredDepositApiJson && filteredDepositApiJson.optionCode;
        const submissionData = depositSubmission && depositSubmission.submissionData;
        const paymentObj = submissionData && submissionData[paymentCode];

        if (paymentObj && paymentObj.submissionRetryTime > Date.now().valueOf()) {
            notification.showNotification('error', t('transaction:transaction.deposit.resubmitError', { retryTime: depositSubmitCountdown }));
        } else {
            proceedSubmit();
        }

        function proceedSubmit() {
            if (amountErrMsg.length > 0 || selectedMethod.length <= 0 || selectedBank.length <= 0) {
                return;
            }

            window.SPL_Content.getPortalSettings().then((settings) => {
                let hideLangPathUrl = false;
                if (settings && settings.features && settings.features.hideLangPathUrl) {
                    hideLangPathUrl = settings.features.hideLangPathUrl;
                }

                let stateUrl = '';
                const langPath = language.countryLanguageKey.replace('_', '-').toLowerCase();
                if (hideLangPathUrl) {
                    stateUrl = '/quickpayredirect';
                } else {
                    stateUrl = `/${langPath}/quickpayredirect`;
                }

                const methodParam = {
                    id: selectedBank[0].id,
                    code: selectedBank[0] ? selectedBank[0].code : null,
                };
                const callbackUrl = `/${langPath}/myaccount/deposit`;
                window.SPL_Transaction.requestDepositOnlineTransfer(methodParam, methodParam.code, amount, null, null, stateUrl, language.key, callbackUrl).then((data) => {
                    if (data.errorCode) {
                        notification.showNotification('error', data.message);
                    } else {
                        trans_handlePGDepositCountDown(this.vm, true, countdown, paymentCode, Date.now().valueOf(), { disabledDepositButton: true });
                    }
                });
            });
        }
    }

    getCorrectDepositMinMaxLimit(depositAllSettings) {
        const { selectedBank } = this.state;
        const { qrPayList, user } = this.props;
        const currency = user.account.currency;

        const target = selectedBank.code || qrPayList[0].code;
        let depositMinMaxLimit = { min: 0, max: 0 };
        depositMinMaxLimit = depositAllSettings.depositJson.quickPay[target][currency];

        this.setState({ depositMinMaxLimit: depositMinMaxLimit });
    }

    validateDepositAmount = () => {
        const { amount, depositMinMaxLimit } = this.state;
        const depositLimitOBj = { minLimitQuick: depositMinMaxLimit.min, maxLimitQuick: depositMinMaxLimit.max };
        window.SPL_Transaction.validateDepositAmount(amount, depositLimitOBj, 'quickPay').then((errMsg) => {
            if (this.isMount) {
                let errMsgLimitAmount = this.getLimitAmountForErrMsg(errMsg[0]);

                this.setState({ amountErrMsg: errMsg, limitAmount: errMsgLimitAmount.limitAmount, currency: errMsgLimitAmount.currency }, () => {
                    this.checkError();
                });
            }
        });
    };

    getLimitAmountForErrMsg(errMsg) {
        let limitAmount = '';
        let currency = this.props.user.account.currency;

        if (errMsg) {
            if (errMsg.includes('invalidMinAmount')) {
                limitAmount = window.SPL_Other.formatAmount(this.state.depositMinMaxLimit.min);
            } else if (errMsg.includes('invalidMaxAmount')) {
                limitAmount = window.SPL_Other.formatAmount(this.state.depositMinMaxLimit.max);
            }
        }

        return {
            limitAmount: limitAmount,
            currency: currency,
        };
    }

    getTncReferral = () => {
        this.setState({ getTncReferral: !this.state.getTncReferral });
    };

    onChannelChanged = (channel) => {
        const currChannel = typeof channel === 'string' ? channel : channel[0].channel;

        const { qrPayList } = this.props;
        let currQRPayList = qrPayList;
        currQRPayList = qrPayList.filter((e) => e.bank && e.bank.channel === currChannel);

        this.setState({ selectedChannel: currChannel, filteredQRPayList: currQRPayList }, () => {
            this.checkError();
        });
    };

    getDepositChannelImage(channel) {
        const { selectedChannel } = this.state;
        const isActive = selectedChannel === channel;

        return (
            <div name={channel} className={`img-container channel-img ${isActive ? 'active' : ''}`}>
                <PaymentGatewayIcon pgCode={channel} isActive={isActive} />
            </div>
        );
    }

    render() {
        const { depositDetails, depositNotice, isLoading, selectedBank, depositMinMaxLimit, selectedChannel, filteredQRPayList, depositAmountOptions } = this.state;
        const { language, screen } = this.props;
        const { key } = language;

        const isMobile = screen.viewType === 'mobile';

        let transactionNoteProps = {
            showCustomArticle: this.state.showCustomDepositArticle,
            customArticleContent: this.state.showCustomDepositArticleContent,
            customArticleIsJson: this.state.customArticleIsJson,
            customContentStyle: this.state.customContentStyle,
            hideDefaultNote: [!this.state.showDefaultArticle, true, true],
        };

        return (
            this.props.user.account && (
                <Translation>
                    {(t) => (
                        <div className='qrpay'>
                            {!isLoading && isMobile && (
                                <div className='item deposit-channel'>
                                    <div className='title-item'>
                                        <span> {t('transaction:transaction.deposit.cashdeposit.channel')}</span>
                                        <span className='text-danger asterisk'>*</span>
                                    </div>
                                    <div className='deposit-amount'>
                                        {depositDetails && depositDetails.qrPayDepositChannelObjs && depositDetails.qrPayDepositChannelObjs.length > 0 ? (
                                            <Select
                                                styles={{
                                                    singleValue: (base) => ({
                                                        ...base,
                                                        padding: 5,
                                                        borderRadius: 5,
                                                    }),
                                                }}
                                                placeholder={t('settings:settings.dropdown.pleaseselect')}
                                                options={depositDetails.qrPayDepositChannelObjs}
                                                values={depositDetails.qrPayDepositChannelObjs}
                                                labelField='channelName'
                                                onChange={(value) => this.onChannelChanged(value)}
                                                searchable={false}
                                            />
                                        ) : (
                                            <SmallLoading></SmallLoading>
                                        )}
                                    </div>
                                </div>
                            )}

                            <ul className='small-banklist'>
                                <li>
                                    <div className='item'>
                                        {!isLoading && !isMobile && (
                                            <p className='deposit-options'>
                                                {t('transaction:transaction.deposit.cashdeposit.depositChannel', 'Deposit Channel')}
                                                <span className='text-danger asterisk'>*</span>
                                            </p>
                                        )}
                                        <div className='bank-selector'>
                                            {!isLoading && !isMobile && (
                                                <Nav tabs className='qr-deposit-channel-tab'>
                                                    {depositDetails && depositDetails.qrPayDepositChannelObjs && (
                                                        <div className='display-flex'>
                                                            {depositDetails.qrPayDepositChannelObjs.map((method, index) => {
                                                                return (
                                                                    <NavItem
                                                                        key={`channel-${index}`}
                                                                        className='deposit-tab-click'
                                                                        onClick={() => {
                                                                            this.onChannelChanged(method.channel);
                                                                        }}
                                                                    >
                                                                        <NavLink
                                                                            className={classnames({
                                                                                active: selectedChannel === method.channel,
                                                                                navlinkForm: true,
                                                                                depositLink: true,
                                                                            })}
                                                                        >
                                                                            <div className='deposit-banktransfer'>{this.getDepositChannelImage(method.channel)}</div>
                                                                            <div className='deposit-options-text'>{method.channelName}</div>
                                                                        </NavLink>
                                                                    </NavItem>
                                                                );
                                                            })}
                                                        </div>
                                                    )}
                                                </Nav>
                                            )}

                                            {!isLoading && (
                                                <ul className='bank-list'>
                                                    {((selectedChannel && filteredQRPayList) || []).map((bank, index) => {
                                                        const isActive = selectedBank?.[0]?.name === bank.name;
                                                        return (
                                                            <li className='small-bank' key={index}>
                                                                <div key={bank.id} className='banklist-group' onClick={() => this.handleBankChange(bank)}>
                                                                    <input type='radio' name='bank-account' onChange={() => this.handleBankChange(bank)} checked={isActive} />
                                                                    <span className='checkmark'></span>

                                                                    <div className={`bank-img ${isActive ? 'bank-on' : 'bank-off'}`}>
                                                                        <BankIcon bankCode={bank.code} isActive={isActive} />
                                                                    </div>

                                                                    <div
                                                                        className={`bank-name ${
                                                                            selectedBank.length > 0 && selectedBank[0].name === bank.name ? 'bank-selected' : ''
                                                                        }`}
                                                                    >
                                                                        {bank.displayName}
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        );
                                                    })}
                                                </ul>
                                            )}
                                        </div>
                                    </div>
                                </li>

                                {!this.props.screen.isMobile && <TransactionNote {...transactionNoteProps} />}
                            </ul>

                            {selectedBank?.length ? (
                                <ul className='deposit-bottom' id='deposit-bottom'>
                                    <li>
                                        <div className='item'>
                                            <p className='deposit-options'>
                                                {t('transaction:transaction.deposit.onlinetransfer.amount')} <span className='text-danger asterisk'>*</span>
                                            </p>

                                            <div className='deposit-amount'>
                                                <input
                                                    name='amount'
                                                    className={`form-control-inner ${this.state.amountError ? 'is-invalid' : ''}`}
                                                    id='name'
                                                    min='0'
                                                    value={this.state.amount}
                                                    placeholder={t('global:global.form.online-transfer-amount-placeholder', {
                                                        min: window.SPL_Other.formatAmount(depositMinMaxLimit.min),
                                                        max: window.SPL_Other.formatAmount(depositMinMaxLimit.max),
                                                    })}
                                                    onChange={(e) => this.handleAmountChange(e.target.value)}
                                                    // onKeyPress={(e) => this.disableDot(e)}
                                                    onBlur={this.validateDepositAmount}
                                                />

                                                {this.state.amountErrMsg.map((errMsg, index) => {
                                                    return (
                                                        <div key={index} className='invalid-feedback important-notice'>
                                                            {this.props.screen.isMobile && <i className='icon-tip'></i>}
                                                            <Trans i18nKey={errMsg}></Trans> {`${this.state.currency} ${this.state.limitAmount}`}
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    </li>

                                    <li>
                                        <div className='item amount-select'>
                                            <p className='deposit-options'></p>
                                            <div className='amount-select-btn'>
                                                {depositAmountOptions.map((item) => (
                                                    <button className='btn-amount' onClick={() => this.handleAmountChange(item)}>
                                                        {item}
                                                    </button>
                                                ))}
                                            </div>
                                        </div>
                                    </li>

                                    <li>
                                        <div className='item'>
                                            <p className='deposit-options'></p>
                                            <div className='deposit-qrpay-button'>
                                                <button
                                                    type='submit'
                                                    className='btn-normal btn-deposit'
                                                    disabled={this.state.disabledDepositButton}
                                                    onClick={() => this.handleSubmit()}
                                                >
                                                    <span>{t('transaction:transaction.deposit.onlinetransfer.depositbtn')}</span>
                                                </button>

                                                <span className='tnc-apply mb-only' onClick={() => this.getTncReferral()}>
                                                    * {t('slot:slot.tnc-apply', 'Terms & Conditions Applied')}
                                                </span>
                                            </div>
                                        </div>
                                    </li>

                                    <li>
                                        <div className='item'>
                                            <p className='deposit-options'></p>
                                            <div className='deposit-quickpay-button'>
                                                {this.state.status === 'F' && (
                                                    <div className='invalid-feedback text-danger'>
                                                        <Trans i18nKey={this.state.message}></Trans>
                                                    </div>
                                                )}

                                                {this.state.status === 'S' && (
                                                    <div className='invalid-feedback text-success'>
                                                        <Trans i18nKey={this.state.message}></Trans>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            ) : null}

                            {this.state.getTncReferral && (
                                <li className='messaging-popup mobile-notice'>
                                    <div className='popup notice-box'>
                                        <div className='popup-header'>
                                            <div className='popup-header-left'>
                                                <div className='popup-title'>{t('slot:slot.tnc-apply', 'Terms & Conditions Applied')}</div>
                                            </div>

                                            <div className='popup-header-right'>
                                                <SVGElements name='close-icon' onClick={() => this.getTncReferral()} />
                                            </div>
                                        </div>

                                        <TransactionNote {...transactionNoteProps} />
                                    </div>
                                    <div className='popup-overlay notice-overlay' onClick={() => this.getTncReferral()}></div>
                                </li>
                            )}
                        </div>
                    )}
                </Translation>
            )
        );
    }
}

export default connect(mapStateToProps)(withTranslation(['transaction', 'settings', 'bank'])(withRouter(QRPay)));
