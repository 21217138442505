import { combineReducers } from 'redux';
import { language } from './language.reducer';
import { user } from './user.reducer';
import { screen } from './screen.reducer';
import { game } from './game.reducer';
import { register } from './register.reducer';
import { portal } from './portal.reducer';
import { wallet } from './wallet.reducer';
import { modal } from './modal.reducer';
import { layout } from './layout.reducer';
import { dropdown } from './dropdown.reducer';
import { depositSubmission } from './deposit.reducer';
import { floatingNotification } from './floatingNotification.reducer';
import { password } from './password.reducer';
import { systemFeatureReducer } from './system-feature.reducer';
import { authSettingsReducer } from './auth-settings.reducer';
import { popupReducer } from './popup.reducer';
import { regionReducer } from './region.reducer';
import { productBannerReducer } from './product-banner.reducer';

// If multiple components need access to some data, in that case we store such data in redux.

const rootReducer = combineReducers({
    // authentication,
    user,
    register,
    language,
    game,
    screen,
    portal,
    wallet,
    modal,
    layout,
    dropdown,
    depositSubmission,
    floatingNotification,
    password,
    systemFeatureReducer,
    authSettingsReducer,
    popupReducer,
    regionReducer,
    productBannerReducer,
});

export default rootReducer;
