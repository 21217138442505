import React, { Component } from 'react';
import { Translation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import 'react-datepicker/dist/react-datepicker.css';
import { mapStateToProps } from 'root/redux/selector/selector';
import { withRouter } from 'react-router-dom';
import vm from '../instant-rebate.controller';
import commonUtil from 'root/utils/common-util';

// This component shows the items user checked out from the cart.
class Rebate extends React.Component {
    constructor(props) {
        super();
        this.props = props;
        vm.init(this);
    }

    componentDidMount() {
        commonUtil.loadCustomTranslation('Rebate', this, function () {});
        vm.getPendingRebateTransaction();
    }

    componentWillUnmount() {
        this.setState = () => {
            return;
        };
    }

    render() {
        const {
            provider,
            pendingRebateTransaction,
            totalPendingClaim,
            lastRebateDate,
            lastClaimDate,
            customTranslationObj,
            isGenerating,
            disableClaim,
            isCompleted,
            isError,
        } = this.state;
        return (
            <Translation>
                {(t) => (
                    <div className='rebate'>
                        <h1>{t('global:global.sidebar.funds.rebate', 'Instant Rebate')}</h1>
                        <div className='rebate-details'>
                            <ul>
                                <li>
                                    <span className='rebate-details-title'>{t('rebate:rebate.totalPendingClaim', 'Total Pending Claim')}:</span>
                                    <span>{totalPendingClaim && totalPendingClaim.toFixed(2)}</span>
                                </li>
                                <li>
                                    <span className='rebate-details-title'>{t('rebate:rebate.lastUpdate', 'Last Update Date')}:</span>
                                    <span>{lastRebateDate}</span>
                                </li>
                                <li>
                                    <span className='rebate-details-title'>{t('rebate:rebate.lastClaim', 'Last Claimed Date')}:</span>
                                    <span>{lastClaimDate}</span>
                                </li>
                                <div className='claim-processing'>
                                    <button
                                        type='submit'
                                        onClick={() => {
                                            vm.handleClaimSubmit();
                                        }}
                                        className='search-btn'
                                        disabled={totalPendingClaim === 0 || isGenerating || disableClaim}
                                    >
                                        <span>{t('transaction:transaction.deposit.cashdeposit.submitbtn').toLowerCase()}</span>
                                    </button>

                                    <div className='claim-processing'>
                                        {isGenerating ? (
                                            <span>{t('rebate:rebate.claimInProgress', 'claimInProgress')}</span>
                                        ) : isCompleted ? (
                                            <span>{t('rebate:rebate.claimCompleted', 'claimCompleted')}</span>
                                        ) : (
                                            isError && <span>{t('rebate:rebate.reclaim', 'reclaim')}</span>
                                        )}
                                    </div>
                                </div>
                            </ul>
                        </div>
                        {/* do the control part */}
                        <div className='provider-table'>
                            {pendingRebateTransaction && pendingRebateTransaction.length > 0 && (
                                <>
                                    {pendingRebateTransaction.map((list, i) => (
                                        <>
                                            <div className='provider-table-header'>
                                                {customTranslationObj && customTranslationObj[list.product] ? t(customTranslationObj[list.product]) : list.product}
                                            </div>
                                            <div className='provider-table-list'>
                                                <ul>
                                                    <li>
                                                        <span>{t('rebate:rebate.memberLevel', 'Membership Level')}</span>
                                                    </li>
                                                    <li>
                                                        <span>{t('rebate:rebate.provider', 'Provider')}</span>
                                                    </li>
                                                    <li>
                                                        <span>{t('rebate:rebate.rebate', 'Rebate %')}</span>
                                                    </li>
                                                    <li>
                                                        <span>{t('rebate:rebate.validBet', 'Valid Bet')}</span>
                                                    </li>
                                                    <li>
                                                        <span>{t('rebate:rebate.rebateEarned', 'Rebate Earn')}</span>
                                                    </li>
                                                </ul>
                                            </div>
                                            {list.productHistories.map((hist, i) => (
                                                <div className='provider-table-data'>
                                                    <ul>
                                                        <li>
                                                            <span className='list'>{hist.memberGroupCode}</span>
                                                        </li>
                                                        <li>
                                                            <span className='list'>
                                                                {customTranslationObj && customTranslationObj[hist.provider]
                                                                    ? t(customTranslationObj[hist.provider])
                                                                    : hist.provider}
                                                            </span>
                                                        </li>
                                                        <li>
                                                            <span className='list'>{hist.rate}%</span>
                                                        </li>
                                                        <li>
                                                            <span className='list'>{hist.totalEligibleTurnOver}</span>
                                                        </li>
                                                        <li>
                                                            <span className='list rebate-earn'>{hist.rebateAmount.toFixed(2)}</span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            ))}
                                        </>
                                    ))}
                                </>
                            )}
                        </div>
                    </div>
                )}
            </Translation>
        );
    }
}

export default connect(mapStateToProps, null)(withTranslation(['rebate', 'transaction'])(withRouter(Rebate)));
