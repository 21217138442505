import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { mapStateToProps } from 'root/redux/selector/selector';
import Modal from 'react-modal';

// component / files

// function

class PopupWrapper extends React.Component {
    props = null;

    constructor(props) {
        super();
        this.props = props;
    }

    componentDidMount() {}

    componentWillUnmount() {
        this.setState = () => {
            return;
        };
    }

    renderChildren = () => {
        if (!this.props.isOpen) {
            throw new Error('Popup must have isOpen flag!');
        }
        return this.props.children;
    };

    render() {
        {
            /* 
            Standard PopupWrapper structure
            Params:
            overlayClassName    - (String) append new overlay classname
            className           - (String) append new classname
            pageTitle           - (String) custom navbar title
            contentTitle        - (String) title of the content
            isOpen              - (Boolean)
            closeFunc           - (Function) function to close poupp 
            version             - (Num) version of popup wrapper
        */
        }
        // redux
        const { screen } = this.props;
        // passed props
        const { overlayClassName, className, isOpen, closeFunc } = this.props;
        // local var

        return (
            <Modal
                overlayClassName={`${screen.isMobile ? 'm-' : ''}standard-popup-overlay ${overlayClassName ? overlayClassName : ''}`}
                className={`standard-popup-modal-container ${className ? className : ''}`}
                isOpen={isOpen}
                shouldCloseOnOverlayClick={false}
                shouldCloseOnEsc={false}
                onRequestClose={closeFunc && closeFunc}
            >
                {this.renderChildren()}
            </Modal>
        );
    }
}

export default connect(mapStateToProps)(withTranslation(['settings', 'transaction'])(withRouter(PopupWrapper)));
