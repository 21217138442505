import React, { Suspense, lazy } from 'react';
import { Trans, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { mapStateToProps } from 'root/redux/selector/selector';
import { withRouter } from 'react-router-dom';
import MainLayout from 'root/layout/MainLayout';
import HomeSlider from './HomeSlider';
import Notice from 'root/components/Notice';
import HomeAnnoucementModal from '../HomeAnnoucementModal';
import SVGElements from 'root/components/SVGElements';
import ScrollToLoad from 'root/utils/ScrollToLoad';

const Menu = lazy(() => import('root/components/Menu'));
const RecommendCasino = lazy(() => import('root/pages/01Home/HomeComponents/RecommendCasino'));
const Reffle = lazy(() => import('root/pages/01Home/home/Reffle'));
const JustForYouGame = lazy(() => import('root/pages/01Home/HomeComponents/JustForYouGame'));
const AppDownload = lazy(() => import('root/components/AppDownload'));
const Promotions = lazy(() => import('root/components/Promotions'));
const VideoSlider = lazy(() => import('root/components/Video/video-slider'));

const STAR = [1, 2, 3, 4, 5];
class Home extends React.Component {
    render() {
        const { t, language, screen } = this.props;
        const { settings } = this.props.portal;
        let langKey = this.props.language.countryLanguageKey.toLowerCase().replace('_', '-');

        const lazyComponents = [
            {
                key: 'recommend-casino',
                className: 'content-recommended home-content-recommendCasino',
                component: <RecommendCasino />,
                componentStyle: {
                    minHeight: '2.9247rem',
                },
            },
            {
                key: 'reffle',
                className: 'home-content-raffle',
                component: <Reffle />,
                componentStyle: {
                    minHeight: '5.7rem',
                },
            },
            {
                key: 'just-for-you-game',
                className: 'home-content-recommend',
                component: <JustForYouGame />,
                componentStyle: {
                    minHeight: '5.7rem',
                },
            },
            {
                key: !settings?.hideLiteAppContent && !window.SPL_LiteApp.isZT828LiteApp() ? 'app-download' : 'promotions',
                className: 'home-content-app',
                component: !settings?.hideLiteAppContent && !window.SPL_LiteApp.isZT828LiteApp() ? <AppDownload /> : <Promotions />,
                componentStyle: {
                    minHeight: !settings?.hideLiteAppContent && !window.SPL_LiteApp.isZT828LiteApp() && '5.19rem',
                },
            },
            {
                key: 'video-slider',
                className: `${this.props.screen.isMobile ? 'm-' : ''}content-maxim88`,
                component: (
                    <div className='content-container'>
                        <div className='content-top'>
                            <span className='content-title'>{t('home:home.testimonials-title', 'Trustworthy, Value, Efficient')}</span>
                        </div>
                        <div className='content-center-box'>
                            <div className='content-center'>
                                <div className='maxim88-box'>
                                    <p className='box-title'>
                                        <Trans i18nKey={'home:home.testimonials-subTitle'}></Trans>
                                    </p>
                                    <div className='box-content'>
                                        {this.props.portal.settings?.testimonials?.[langKey]?.map((info, index) => {
                                            const key = `index-${index}`;
                                            return (
                                                <div key={key} className={'content-' + index}>
                                                    <p className='content-title'>
                                                        {info.name}
                                                        <span>
                                                            {STAR.map((index) => (
                                                                <SVGElements key={index} className='star-icon' name='star-icon' />
                                                            ))}
                                                        </span>
                                                    </p>
                                                    <span>{info.content}</span>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                                <div className='maxim88-video'>
                                    <VideoSlider />
                                </div>
                                {settings?.showTestimonialBanner?.[screen.viewType] && (
                                    <img className='img-signature' src={`/public/html/images/home_img/096-testimonial-${language.key}.jpg`} alt='' />
                                )}
                            </div>
                        </div>
                    </div>
                ),
                componentStyle: {
                    minHeight: '5.45rem',
                },
            },
        ];

        const renderLazyComponents = () => {
            return lazyComponents.map((item) => {
                return (
                    <li key={item.key} style={item.componentStyle} className={item.className}>
                        <ScrollToLoad offset={30}>{item.component}</ScrollToLoad>
                    </li>
                );
            });
        };

        return (
            <div className='home'>
                <MainLayout>
                    <HomeSlider />
                    {this.props.screen.isMobile && (
                        <Suspense fallback={<></>}>
                            <Menu />
                        </Suspense>
                    )}
                    <HomeAnnoucementModal />

                    <div className='content-container'>
                        <div className='content-section'>
                            <Notice />
                            <ul className='home-content-section'>{renderLazyComponents()}</ul>
                        </div>
                    </div>
                </MainLayout>
            </div>
        );
    }
}

export default connect(mapStateToProps)(withTranslation()(withRouter(Home)));
