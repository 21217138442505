import React from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Modal from 'react-modal';
import CloseButton from './close-button';

const FloatingFreeSpinModal = ({
    modalIsOpen,
    handleCloseModal,
    popupImage,
    textMessageColor,
    textProviderColor,
    popupClaimDataList,
    claimExpired,
    hideCTA,
    ctaLink,
    provider,
}) => {
    const { t } = useTranslation(['freespin', 'providerName']);
    const location = useLocation();
    const language = useSelector((state) => state?.language);

    const formatCurrentLanguage = (lng) => {
        return lng ? lng.toLowerCase().replace('_', '-') : 'en-my';
    };

    const handleButtonClaim = () => {
        const pathSegments = location.pathname.split('/');
        const languageSegment = pathSegments[0] || formatCurrentLanguage(language?.countryLanguageKey);
        const linkHref = ctaLink ? `/${languageSegment}${ctaLink}` : `/${languageSegment}${provider?.route}`;
        handleCloseModal();
        window.location.href = linkHref;
    };

    if (!popupImage) return null;
    const popupClaimData = popupClaimDataList?.[0];

    const providerName = t(`providerName:ProviderName.${provider?.provider}`, provider?.name);

    return (
        <Modal
            ariaHideApp={false}
            overlayClassName='standard-popup-overlay'
            className='standard-popup-modal-container freespin-popup-modal-container'
            isOpen={modalIsOpen}
            onRequestClose={handleCloseModal}
            shouldCloseOnOverlayClick={true}
        >
            <div className='freespin-popup-modal-body'>
                <div className='freespin-close-btn' onClick={handleCloseModal}>
                    <CloseButton />
                </div>
                <LazyLoadImage src={popupImage} />
                <div className='freespin-message'>
                    {/* this free spin just show 1 msg only */}
                    {popupClaimData && (
                        <div
                            dangerouslySetInnerHTML={{
                                __html: t('freeSpinContent', {
                                    claimExpired,
                                    providerCode: providerName || popupClaimData?.provider,
                                    freeSpinCount: popupClaimData?.freeSpinCount,
                                    textMessageColor,
                                    textProviderColor,
                                }),
                            }}
                        />
                    )}
                    {!hideCTA && (
                        <button className='freespin-claim-cta' onClick={handleButtonClaim}>
                            {t('claimBtn', 'Claim Now!')}
                        </button>
                    )}
                </div>
            </div>
        </Modal>
    );
};

export default FloatingFreeSpinModal;
