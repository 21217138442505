import React from 'react';
import { BrowserRouter as Router, Route, Link, Redirect } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import { TweenLite } from 'gsap';
import ChangePassword from './ChangePassword';
import ChangeGamePassword from './ChangeGamePassword';
import Profile from './Profile';
import Message from './Message';
import BankDetails from './BankDetails';
import Referral from './Referral';
import ProfileLayout from 'root/layout/ProfileLayout';
import { connect } from 'react-redux';
import { mapStateToProps } from 'root/redux/selector/selector';
import { withRouter } from 'react-router-dom';
import { Translation, withTranslation } from 'react-i18next';
import Loading from 'root/components/Loading/Loading';
import Moment from 'moment';
import { userAction } from 'root/redux/action';
import SVGElements from 'root/components/SVGElements';
import UserVerificationDialog from '../../16MyAccount/shared/dialog/user-verification-dialog/user-verification-dialog';
import SmsVerifyModal from '../../../components/SmsVerifyModal';

import ImageHelper from 'root/pages/Share/image-helper';
import commonUtil from 'root/utils/common-util';
import { mu_getContactVerification, mu_getContactVerificationStatus, mu_getMemberVerifyOptions } from '../../../utils/member-util';
import { gu_getContentByCurrencyLanguage, gu_togglePopup } from '../../../utils/general-util';
import EmailVerificationDialog from '../../16MyAccount/shared/dialog/email-verification-dialog/component/email-verification-dialog';
import AuthSettings from '@components/molecules/auth-settings/auth-settings';
import { MODULE_TYPE } from '@utils/constants';
import { authSettingsAction } from '@redux/action';

const routes = [
    {
        path: '/myprofile/profile',
        key: 'profile',
        name: 'settings:settings.profiletab.myprofile',
        Component: Profile,
    },
    {
        path: '/myprofile/changepassword',
        key: 'changepassword',
        name: 'settings:settings.profiletab.pass',
        Component: ChangePassword,
    },
    {
        path: '/myprofile/changegamepassword',
        key: 'changegamepassword',
        name: 'settings:settings.profiletab.providerpass',
        Component: ChangeGamePassword,
    },
    {
        path: '/myprofile/bankdetail',
        key: 'bankdetail',
        name: 'settings:settings.profiletab.bank',
        Component: BankDetails,
    },
    {
        path: '/myprofile/messaging',
        key: 'messaging',
        name: 'settings:settings.tabs.heading.messaging',
        Component: Message,
    },
    {
        path: '/myprofile/referral',
        key: 'referral',
        name: 'settings:settings.profiletab.referral',
        Component: Referral,
    },
];
class Funds extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            route: '',
            showWallet: true,
            autoTranfer: true,
            path: '',
            mainWallet: '',
            seamLessModal: false,
            hasFakeSeamLess: true,
            disabledSeamless: false,
            disableSeamlessButton: false,
            liveChatUrl: '',
            currency: '',
            progressBarPercentage: 0,
            depositProgress: '',
            memberVerifyOptions: [],
            filteredVerifyOptions: [],
            selectedVerifyType: null,
            smsVerify: false,
            upgradeGroupName: '',
            compSettings: [],
            routes,
        };
    }

    componentDidMount() {
        this.isMount = true;
        commonUtil.loadCustomTranslation('accountGroupName', this);
        const current = this.state.routes.find((it) => it.key === this.props.location.pathname.replace('/myprofile/', ''));

        this.setState({
            route: current,
            path: (current && current.path) || '/myprofile/profile',
        });

        if (this.props.screen.viewType === 'web' && this.props.location.pathname.includes('/myprofile/')) {
            this.props.history.push('/');
        }

        this.loadMainWallet();
        this.getUserSeamlessStatus();
        this.intervalId = setInterval(this.contactGet, 2000);
        this.loadCurrency();
        this.getProgressBarData();
        this.countMsg();
        this.loadAuthSettings().then(() => {
            this.initCompSettings().then(() => {
                this.checkVerification();
                this.checkContactVerificationStatus();
                this.checkHideMenu();
            });
        });

        this.msgId = setInterval(() => {
            this.countMsg();
        }, 60000);

        if (!window.isAgent && !window.isAffiliate) {
            if (window.SPL_LiteApp.isLiteApp() || window.SPL_LiteApp.isZT828LiteApp()) {
                window.SPL_LiteApp.onRegisterPageDisableRefresh(true);
            }
        }
    }

    checkHideMenu() {
        const { settingsConfiguration } = this.state;
        const hideReferral = !!gu_getContentByCurrencyLanguage(this, settingsConfiguration?.hideReferral);
        const routes = this.state.routes.filter((x) => x.key !== 'referral' || !hideReferral);

        this.setState({ routes });
    }

    countMsg = () => {
        window.SPL_Member.getUnreadMessageCount().then((data) => {
            if (this.isMount) {
                this.setState({ unreadMsgCount: data.unreadCount }, () => {
                    let count = this.state.unreadMsgCount;

                    this.props.dispatch(userAction.getUnreadMsg(count));
                });
            }
        });
    };

    getProgressBarData = () => {
        const { account } = this.props.user;
        const { key } = this.props.language;
        const { isMobile } = this.props.screen;

        if (key) {
            let lang_key = key.toLowerCase();

            window.SPL_Member.loadMemberGroupAutomationSummary(isMobile, account.currency, account.groupName, account.imagePath, lang_key).then((data) => {
                this.setState({
                    progressBarPercentage: data.progressBarPercentage,
                    memberGroupLevelMessages: data.memberGroupLevelMessages,
                    userGroupImage: data.userGroupImage,
                    userReviewDate: data.firstDayOfMonth,
                    depositProgress: data.depositProgress,
                    upgradeGroupName: data?.upgradeGroupName,
                });
            });
        }
    };

    loadCurrency() {
        const { currency } = this.props.user.account || { currency: '' };
        this.setState({ currency: currency });
    }

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            // this.getUserSeamlessStatus();
        }

        if (prevProps.location.pathname !== this.props.location.pathname) {
            const current = this.state.routes.find((it) => it.key == this.props.location.pathname.replace('/myprofile/', ''));

            this.setState({
                route: current,
                path: (current && current.path) || '/myprofile/profile',
            });
        }
    }

    componentWillUnmount() {
        this.isMount = false;
        clearInterval(this.intervalId);
        clearInterval(this.msgId);
        if (!window.isAgent && !window.isAffiliate) {
            if (window.SPL_LiteApp.isLiteApp() || window.SPL_LiteApp.isZT828LiteApp()) {
                window.SPL_LiteApp.onRegisterPageDisableRefresh(false);
            }
        }
    }

    contactGet = () => {
        const { language, screen } = this.props;
        window.SPL_Content.getContact(language.countryLanguageKey, screen.viewType).then((data) => {
            if (this.isMount) {
                if (data) {
                    for (let i = 0; i < data.length; i++) {
                        const contact = data[i];
                        if (contact.name === 'LiveChat') {
                            this.setState({ liveChatUrl: contact.desc });
                        }
                    }
                }
            }
        });
    };

    contactOnClick = (url) => {
        window.open(url, '', 'width=500,height=700,toolbar=no,location=no,directories=no,status=yes,menubar=no,scrollbars=no');
    };

    getUserSeamlessStatus() {
        const { isLogin } = this.props.user;
        if (isLogin === true) {
            window.SPL_Member.loadMemberSeamlessStatus().then((res) => {
                if (this.isMount) {
                    this.setState({
                        hasFakeSeamLess: res.hasFakeSeamless,
                        disabledSeamless: res.isFakeSeamlessDisabled,
                    });
                }
            });
        }
    }

    handleSeamlessChange = () => {
        this.setState({ disableSeamlessButton: true });
        window.SPL_Member.updateMemberSeamlessStatus(this.state.disabledSeamless, this.state.hasFakeSeamLess).then((res) => {
            if (res.hasFakeSeamless) {
                this.setState(
                    {
                        disabledSeamless: res.isFakeSeamlessDisabled,
                        message: res.message,
                        disableSeamlessButton: false,
                    },
                    () => {
                        this.getSeamlessModal();
                    }
                );
            }
        });
    };

    getSeamlessModal = () => {
        this.setState({ seamLessModal: !this.state.seamLessModal });
    };

    loadMainWallet = () => {
        window.SPL_Member.getMainWallet().then((mainWallet) => {
            this.setState({
                mainWallet: mainWallet.data.balance,
                restoreOnLoad: false,
            });
        });
    };

    restoreWallet = () => {
        this.setState({ restoreOnLoad: true });
        window.SPL_Transaction.transferAllBackMainWallet()
            .then(() => {
                setTimeout(() => {
                    this.loadMainWallet();
                }, 5000);
            })
            .catch(() => {
                setTimeout(() => {
                    this.loadMainWallet();
                }, 5000);
            });
    };

    onReload(target) {
        this.loadMainWallet();
        TweenLite.fromTo(
            target,
            1,
            {
                rotate: 0,
            },
            {
                rotate: 360,
            }
        );
    }

    handleLink(route) {
        //this.props.history.push(route.path);
        this.setState(
            {
                route,
                path: route.path,
            },
            () => {
                this.props.history.push(route.path);
            }
        );
    }

    redirectMessaging = () => {
        const { screen } = this.props;
        if (screen.viewType === 'web') {
            this.props.history.push('/myaccount/messaging');
        } else {
            this.props.history.push('/myprofile/messaging');
        }
    };

    isStartTransition(key, path) {}

    loadAuthSettings = async () => {
        const _splConfig = {
            otpOptions: {
                otpType: 'USER_VERIFICATION',
            },
        };

        await this.props.dispatch(authSettingsAction.fetchAuthSettings(_splConfig, true));
    };

    initCompSettings() {
        return new Promise((resolve) => {
            const { user, t } = this.props;

            // Step 1: Standard way to get from portal settings
            let settingsConfiguration = commonUtil.getSettingFromPortalSettings({
                vm: this,
                settingName: 'settingsConfiguration',
                isCurrencyLang: true,
            });

            // Step 2: Massage again to get the specific data you need (Skip if not needed)
            let _memberVerifyOptions = mu_getMemberVerifyOptions(this, settingsConfiguration);

            // Step 3: Initial all into compSettings
            let compSettings = {};

            this.setState({ compSettings, memberVerifyOptions: _memberVerifyOptions, settingsConfiguration }, () => {
                resolve();
            });
        });
    }

    checkVerification() {
        mu_getContactVerification(this);
    }

    checkContactVerificationStatus() {
        mu_getContactVerificationStatus(this);
    }

    onVerifyOptionChange(option) {
        if (option) {
            this.setState({ selectedVerifyType: option.key });
        }
    }

    handleVerify() {
        const { history } = this.props;
        const { selectedVerifyType, filteredVerifyOptions } = this.state;
        let defaultVerifyType = filteredVerifyOptions[0] && filteredVerifyOptions[0].key;
        let verifyType = selectedVerifyType ? selectedVerifyType : defaultVerifyType;

        gu_togglePopup(this, 'userVerify');
        switch (verifyType) {
            case 'birthday':
                history.push('/myprofile/profile');
                break;
            case 'email':
                this.togglePopup(verifyType);
                break;
            case 'phone':
                this.getSmsVerifyModal();
                break;
            case 'withdrawal':
                history.push('/myprofile/bankdetail');
                break;
            default:
                break;
        }
    }

    getSmsVerifyModal = () => {
        this.setState({ smsVerify: !this.state.smsVerify });
    };

    checkVerification() {
        mu_getContactVerification(this);
    }

    checkContactVerificationStatus() {
        mu_getContactVerificationStatus(this);
    }

    togglePopup(component) {
        gu_togglePopup(this, component);
    }

    render() {
        const { user, t } = this.props;
        const { verifications } = user;
        const { memberVerifyOptions, filteredVerifyOptions, smsVerify, popupCollection, userFullyVerified, selectedVerifyType, userReviewDate, customTranslationObj } =
            this.state;

        const styles = {
            progressbarWidth: {
                width: this.state.progressBarPercentage + '%',
            },
        };
        const { progressbarWidth } = styles;

        let componentProps = {
            checkVerification: this.checkVerification.bind(this),
        };

        return (
            <div className='wap'>
                <AuthSettings module={MODULE_TYPE?.BIND_AUTHENTICATOR} isPublic={false} />
                <AuthSettings module={MODULE_TYPE?.SIX_PIN} isPublic={false} />

                {this.state.restoreOnLoad && <Loading />}

                {this.state.seamLessModal && (
                    <div className='messaging-popup'>
                        <div className='popup'>
                            <div className='popup-header'>
                                <div className='popup-header-left'>
                                    <div className='popup-title'>{t('global:global.menu.account.autoTransfer', 'Main Wallet Auto Transfer')}</div>
                                </div>
                                <div className='popup-header-right'>
                                    <SVGElements name='close-icon' onClick={() => this.getSeamlessModal()} />
                                    {/* <MdClose onClick={() => this.getSeamlessModal()} /> */}
                                </div>
                            </div>

                            <div className='popup-body'>
                                {t('global:global.messages.autoTransfer.content1', 'You have')}{' '}
                                {!this.state.disabledSeamless
                                    ? t('global:global.messages.autoTransfer.enable', 'enable')
                                    : t('global:global.messages.autoTransfer.disable', 'disable')}{' '}
                                {t(
                                    'global:global.messages.autoTransfer.content2',
                                    'Auto Transfer. Please allow up to 5 minutes for the system to update your preferences. Thank you.'
                                )}
                            </div>

                            <div className='popup-footer'>
                                <span className='btn-normal btn-body' onClick={() => this.getSeamlessModal()}>
                                    {t('login:login.ok', 'ok')}
                                </span>
                            </div>
                        </div>
                        <div className='popup-overlay' onClick={() => this.getSeamlessModal()}></div>
                    </div>
                )}

                {/* const { isOpen, closeFunc, filteredVerifyOptions, onVerifyOptionChange } = this.props; */}
                {popupCollection && popupCollection['userVerify'] && (
                    <UserVerificationDialog
                        isOpen={popupCollection['userVerify']}
                        filteredVerifyOptions={filteredVerifyOptions}
                        selectedVerifyType={selectedVerifyType}
                        closeFunc={() => gu_togglePopup(this, 'userVerify')}
                        onVerifyOptionChange={this.onVerifyOptionChange.bind(this)}
                        handleVerify={this.handleVerify.bind(this)}
                    />
                )}

                {smsVerify && (
                    <SmsVerifyModal isOpen={smsVerify} getSmsVerifyModal={this.getSmsVerifyModal.bind(this)} checkVerification={this.checkVerification.bind(this)} />
                )}

                {popupCollection && popupCollection.email && (
                    <EmailVerificationDialog
                        checkEmailStatus={this.state.checkEmailStatus}
                        isOpen={popupCollection.email}
                        togglePopup={this.togglePopup.bind(this)}
                        checkVerification={this.checkVerification.bind(this)}
                    />
                )}

                <ProfileLayout name={t(this.state.route.name)}>
                    <div className='wap-pages-out'>
                        <div className='fixed-box mAccTopPadding'>
                            {/* <div className="title">{this.state.route.name}</div> */}
                            <div className='auto-tranfer-box profile-box'>
                                <div className='left'>
                                    <div className='member-info'>
                                        <div className='member-name-level'>
                                            <img src={this.state.userGroupImage} className='icon-sapphire' />
                                            <span>{customTranslationObj?.[user?.account?.groupName?.toUpperCase()] || user.account.groupName}</span>
                                        </div>
                                        <div className='member-name-info'>
                                            <span className='highlight-text'>{user.account.login}</span>
                                            <span className='info-progress'>{this.state.depositProgress}</span>
                                            <div className='line-out'>
                                                <div className='line-inner' style={progressbarWidth}></div>
                                                {/* <span>{this.state.progressBarPercentage}%</span> */}
                                            </div>
                                            <div
                                                className='rank-condition'
                                                dangerouslySetInnerHTML={{
                                                    __html: t(this.state.memberGroupLevelMessages, this.state.memberGroupLevelMessages, {
                                                        upgradeGroupName: customTranslationObj?.[this.state.upgradeGroupName?.toUpperCase()] || this.state.upgradeGroupName,
                                                    }),
                                                }}
                                            />
                                            {user.account.groupName.toUpperCase() !== 'NORMAL' && userReviewDate && userReviewDate !== 'INVALID DATE' && (
                                                <span className='memberReview-date'>
                                                    {t('member:PortalApp.member.reviewDate', 'Review Date')}: {Moment(userReviewDate).format('DD/MM/YYYY')}
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className='right'>
                                    <div className='auto-transfer'>
                                        <span className='highlight-text'>{t('global:global.menu.account.autoTransfer', 'Main Wallet Auto Transfer')}</span>
                                        <div
                                            className={['icon-wap-auto', !this.state.disabledSeamless ? '' : 'off'].join(' ')}
                                            onClick={() => this.handleSeamlessChange()}
                                            disabled={this.state.disableSeamlessButton}
                                        ></div>
                                    </div>
                                    <div className='message-box'>
                                        {/* {this.state.liveChatUrl &&
                      <i className="icon-wap-chat" onClick={() => this.contactOnClick(this.state.liveChatUrl)}></i>
                    } */}
                                        <i className='icon-wap-message' onClick={() => this.redirectMessaging()}>
                                            <SVGElements name='message-icon' />
                                            {user.unreadMsg > 0 && (
                                                <div className='msg-count-box'>
                                                    <div className='tag'>{user.unreadMsg}</div>
                                                </div>
                                            )}
                                        </i>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <section className='st-shortcut-menu-section'>
                            <Router>
                                <ul className='wap-funds-list'>
                                    {this.state.routes.map((route) => (
                                        <li key={route.key} className={this.state.route.key == route.key ? 'on' : ''}>
                                            <p
                                                onClick={() => {
                                                    this.handleLink(route);
                                                }}
                                            >
                                                <span className='icon-box'>
                                                    <SVGElements
                                                        className={'icon-wap-' + route.key}
                                                        name={this.state.route.key == route.key ? 'm-' + route.key + '-icon-on' : 'm-' + route.key + '-icon'}
                                                    />
                                                </span>
                                                <span>{t(route.name)}</span>
                                            </p>
                                        </li>
                                    ))}
                                </ul>
                            </Router>
                            <div className='user-verification-container'>
                                <p className='container-label'>{t('settings:settings.userVerification', 'User Verification')}</p>
                                <div className='icons-and-button-container'>
                                    <div className='verification-icons-container'>
                                        {(memberVerifyOptions || []).map((option, i) => {
                                            const { key, svg, splKey } = option;
                                            return (
                                                <div className='icon-wrapper' key={i}>
                                                    <ImageHelper
                                                        displaySVG={`${verifications && verifications[splKey] ? `${svg}-ov` : `${svg}`}`}
                                                        className={`verify-icon ${verifications && verifications[splKey] ? `icon-verify-${key}-ov` : ` icon-verify-${key}`}`}
                                                        svgType='SVGElements'
                                                        isSVG={true}
                                                    />
                                                </div>
                                            );
                                        })}
                                    </div>
                                    {!userFullyVerified && (
                                        <div className='verify-button-container'>
                                            {/* <button className='standard-button standard-submit-button verify-button' onClick={() => this.handleVerify()}> */}
                                            <button className='standard-button standard-submit-button verify-button' onClick={() => gu_togglePopup(this, 'userVerify')}>
                                                <span>{t('settings:settings.memberVerificationForm.verifyNow', 'Verify Now')}</span>
                                            </button>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </section>

                        <div className='wap-pages'>
                            {this.state.routes.map(({ key, path, Component }) => (
                                <CSSTransition
                                    key={path}
                                    in={this.state.path === path}
                                    timeout={300}
                                    //classNames="page-slide"
                                    unmountOnExit
                                >
                                    <Component checkVerification={this.checkVerification.bind(this)} />
                                </CSSTransition>
                            ))}
                        </div>
                    </div>
                </ProfileLayout>
            </div>
        );
    }
}

export default connect(mapStateToProps)(withTranslation('member')(withRouter(Funds)));
