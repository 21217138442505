// library
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import SVG from 'react-inlinesvg';
import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

// util
import { CMS_PAYMENT_GATEWAY_IMAGE_PATH } from '../../utils/constants';

/**
 *
 * @returns
 */
const PaymentGatewayIcon = ({ pgCode, ...props }) => {
    const { isActive } = props;
    const userRedux = useSelector((state) => state.user);
    const currency = userRedux?.account?.currency;

    const imageSrc = `/public/html/images/bank_img/${currency}/${pgCode}${isActive ? '' : '_off'}.png`;

    return (
        <SVG src={`${CMS_PAYMENT_GATEWAY_IMAGE_PATH}/${currency}/${pgCode}.svg`}>
            <SVG src={`/public/html/images/deposit/${pgCode}.svg`}>
                <LazyLoadImage alt={pgCode} src={imageSrc} />
            </SVG>
        </SVG>
    );
};

PaymentGatewayIcon.propTypes = {
    pgCode: PropTypes.string.isRequired, // payment gateway code
};

export default PaymentGatewayIcon;
