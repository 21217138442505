import React, { useEffect, useState } from 'react';
import { Translation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import SVGMaster from '../../../components/Icon/SVGMaster';
import { contentFooter } from '../Common_Items_Data';

const MobileFooterAfterLogin = (props) => {
  const { location } = props;
  const [liveChatUrl, setLiveChatUrl] = useState('');
  const [footerContent, setFooterContent] = useState([]);
  const languageState = useSelector((state) => state.language);
  const userState = useSelector((state) => state.user);
  const [isHomePage, setIsHomePage] = useState(false);
  const [isLogin, setLogin] = useState(false);
  const [pageInitialize, setPageInitialize] = useState(false);

  useEffect(() => {
    let interval = setInterval(() => {
      if (window.pageInitialize) {
        setPageInitialize(true);
        clearInterval(interval);
      }
    }, 100);
  }, [setPageInitialize]);

  useEffect(() => {
    getContact();
    let isMount = true;
    function getContact() {
      if (window.SPL_Content) {
        window.SPL_Content.getContact(languageState.countryLanguageKey, 'mobile').then((data) => {
          if (isMount) {
            for (let i = 0; i < data.length; i++) {
              const contact = data[i];
              if (contact.name === 'LiveChat') {
                setLiveChatUrl(contact.desc);
              }
            }
          }
        });
      }
    }

    return () => {
      isMount = false;
    };
  }, [languageState.countryLanguageKey]);

  useEffect(() => {
    if (props.history.location.pathname.includes('/home')) {
      setIsHomePage(true);
    } else {
      setIsHomePage(false);
    }
  });

  useEffect(() => {
    let footerList = [];

    let contentFooterListCopy = [...contentFooter];
    setTimeout(() => {
      for (let i = 0; i < contentFooterListCopy.length; i++) {
        const element = contentFooterListCopy[i];
        if (!userState.isLogin) {
          setLogin(false);
          if (element.login === false) {
            element.toRoute = element.route;
            footerList.push(element);
          } else {
            element.toRoute = '/login';
            footerList.push(element);
          }
        } else {
          setLogin(true);
          element.toRoute = element.route;
          footerList.push(element);
        }
      }

      setFooterContent(footerList);
    }, 400);
  }, [userState]);

  function openLiveChat() {
    window.open(liveChatUrl);
  }

  if (location.pathname === '/game') {
    return null;
  } else {
    return (
      <Translation>
        {(t) => (
          <div className='' >
            {isLogin && (
              <footer className={`app-footer mobile-btm-nav ${props.showSidebar ? 'fixed-bottom' : 'fixed-bottom'}  `}>
                <div className='vw-100 h-100 d-flex align-items-center justify-content-around'>
                  {footerContent.map((footer) => {
                    return (
                      <div key={footer.id} className='d-flex align-items-center justify-content-around footer-item-col'>
                        {footer.type === 'button' ? (
                          <button
                            key={footer.id}
                            onClick={() => {
                              openLiveChat();
                            }}
                            className='col footer-item btn-footer text-center p-0 p-l-0 p-r-0 bg-transparent'>
                            <svg
                              className={`icon-sm footer-icon w-100  ${
                                props.history.location.pathname.includes(footer.toRoute) ? 'mFooter-icon' : 'mFooter-icon'
                              }`}
                              aria-hidden='true'>
                              <SVGMaster name={footer.svgname} />
                            </svg>
                            <div
                              className={`d-block text-center footer-text text-uppercase lh-1  ${
                                props.history.location.pathname.includes(footer.toRoute) ? 'mFooter-text' : 'mFooter-text'
                              }`}>
                              {t(footer.translate)}
                            </div>
                          </button>
                        ) : (
                          <Link key={footer.id} to={footer.toRoute} className='col footer-item btn-footer text-center p-l-0 p-r-0'>
                            <svg
                              className={`icon-sm footer-icon w-100  ${
                                props.history.location.pathname.includes(footer.toRoute) && !props.history.location.pathname.includes('/login')
                                  ? 'mFooter-icon-active'
                                  : 'mFooter-icon'
                              }`}
                              aria-hidden='true'>
                              <SVGMaster name={footer.svgname} />
                            </svg>
                            <div
                              className={`d-block text-center footer-text extra-small lh-1  ${
                                props.history.location.pathname.includes(footer.toRoute) && !props.history.location.pathname.includes('/login')
                                  ? 'mFooter-text-active'
                                  : 'mFooter-text'
                              }`}>
                              {t(footer.translate)}
                            </div>
                          </Link>
                        )}
                      </div>
                    );
                  })}
                </div>
              </footer>
            )}
          </div>
        )}
      </Translation>
    );
  }
};

export default withRouter(MobileFooterAfterLogin);
