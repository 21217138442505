import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Translation, withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { mapStateToProps } from 'root/redux/selector/selector';
import Modal from 'react-modal';

import SmallLoading from 'root/components/Loading/SmallLoading';

import vm from '../transaction-note.controller';
class TransactionNote extends React.Component {
    isMount = false;
    props = {};

    constructor(props) {
        super();
        this.props = props;
        vm.init(this);
        this.state = {
            isModalOpen: false,
            isFaqModalOpen: false,
        };
    }

    componentWillUnmount() {
        this.setState = () => {
            return;
        };
    }

    toggleModal() {
        this.setState({ isModalOpen: !this.state.isModalOpen });
    }

    toggleFaqModal() {
        this.setState({ isFaqModalOpen: !this.state.isFaqModalOpen });
    }

    render() {
        const {
            // NAMING DISPLAY
            transactionMethodTitle,
            // ARTICLE
            customArticleContent,
            bottomImage,
            customArticleIsJson,
        } = this.props;

        return (
            <Translation>
                {(t) => (
                    <div className='notice-box popup-body'>
                        {transactionMethodTitle ? (
                            <div className='title'>
                                <i className='icon-notice-important'></i>
                                <span>{transactionMethodTitle}</span>
                            </div>
                        ) : (
                            <div className='title'>
                                <i className='icon-notice-important'></i>
                                <span>{t('transaction:transaction.importantTitle', 'IMPORTANT NOTICE')}</span>
                            </div>
                        )}

                        {/************************ NOTES MODAL ***********************/}
                        <ul className='display-info'>
                            {/************************ TRANSACTION DEFAULT / CUSTOM ARTICLE ***********************/}
                            {customArticleIsJson ? (
                                <div
                                    className='note-info-detail'
                                    style={customArticleContent && customArticleContent.noteInfoDetailStyle ? customArticleContent.noteInfoDetailStyle : {}}
                                >
                                    {customArticleContent && (
                                        <>
                                            {(customArticleContent.content || []).map((content, i) => (
                                                <Fragment key={i}>{vm.renderCustomContent(customArticleContent, content)}</Fragment>
                                            ))}
                                        </>
                                    )}
                                </div>
                            ) : (
                                <div
                                    className='note-info-detail'
                                    dangerouslySetInnerHTML={{
                                        __html: customArticleContent,
                                    }}
                                ></div>
                            )}

                            {/************************ BOTTOM IMAGE ***********************/}
                            {bottomImage && <img src={bottomImage}></img>}
                        </ul>
                    </div>
                )}
            </Translation>
        );
    }
}

export default connect(mapStateToProps, null)(withTranslation()(withRouter(TransactionNote)));
