// library
import React, { Fragment } from 'react';

// components
import ImageHelper from 'root/pages/Share/image-helper';

// function/logic
import commonUtil from 'root/utils/common-util';
import core from 'root/utils/core';

const controller = {
    vm: null,

    init(vm) {
        controller.vm = vm;
        controller.vm.state = {
            isModalOpen: false,
            popupCollection: {
                tutorial: false,
            },

            compSettings: {},
        };
    },

    renderCustomContent(currentComp, display) {
        const { t, language, customContentStyle } = controller.vm.props;

        return (
            <Fragment>
                {Object.keys(display).map((key, i) => (
                    <Fragment key={i}>
                        {key === 'img' && (
                            controller.continueRenderContentByKey(customContentStyle, key, display[key])
                        )}
                        {key === 'svg' && (
                            controller.continueRenderContentByKey(customContentStyle, key, display[key])
                        )}
                        {key === 'div' && (
                            <>
                                {display[key].items && Array.isArray(display[key].items) ? (
                                    <>
                                        <div 
                                            id={display[key].id}
                                            style={controller.getContentStyle(customContentStyle, display[key])}
                                            onClick={()=> controller.handleItemOnClick(display[key])}
                                            onMouseEnter={() => {
                                                if (display[key].id) {
                                                    commonUtil.handleHoverOrUnhoverStyleFromJson({
                                                        div: display[key].id ? document.getElementById(display[key].id) : '',
                                                        oriStyles: controller.getContentStyle(customContentStyle, display[key]),
                                                        newStyles: controller.getContentStyle(customContentStyle, display[key], true),
                                                        isHover: true,
                                                    })
                                                }
                                            }}
                                            onMouseLeave={()=> {
                                                if (display[key].id) {
                                                    commonUtil.handleHoverOrUnhoverStyleFromJson({
                                                        div: display[key].id ? document.getElementById(display[key].id) : '',
                                                        oriStyles: controller.getContentStyle(customContentStyle, display[key]),
                                                        newStyles: controller.getContentStyle(customContentStyle, display[key], true),
                                                    })
                                                }
                                            }}
                                        >
                                            {(display.div.items || []).map((subDisplay, j) => (
                                                <Fragment key={j}>
                                                    {controller.renderCustomContent(currentComp, subDisplay)}
                                                </Fragment>
                                            ))}
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        {controller.continueRenderContentByKey(customContentStyle, key, display[key])}
                                    </>
                                )}
                            </>
                            
                        )}
                        {key === 'p' && (
                             <>
                                {Array.isArray(display[key].items) ? (
                                    <>
                                        <p style={controller.getContentStyle(customContentStyle, display[key])}>
                                            {(display.p.items || []).map((subDisplay, j) => (
                                                <Fragment key={j}>
                                                    {controller.renderCustomContent(currentComp, subDisplay)}
                                                </Fragment>
                                            ))}
                                        </p>
                                    </>
                                ) : (
                                    <> 
                                        {controller.continueRenderContentByKey(customContentStyle, key, display[key])}
                                    </>
                                )}
                            </>
                        )}
                        {key === 'span' && (
                            <>
                                {controller.continueRenderContentByKey(customContentStyle, key, display[key])}
                            </>
                        )}
                        {key === 'ul' && (
                            <ul style={controller.getContentStyle(customContentStyle, display[key])} className={display.ul.type}>
                                {(display.ul.items || []).map((item, k) => (
                                    <Fragment key={k}>
                                        {controller.renderCustomContent(currentComp, item)}
                                    </Fragment>
                                ))}
                            </ul>
                        )}

                        {key === 'li' && (
                             <>
                                {Array.isArray(display[key].items) ? (
                                    <>
                                        <li style={controller.getContentStyle(customContentStyle, display[key])}>
                                            {(display.li.items || []).map((subDisplay, j) => (
                                                <Fragment key={j}>
                                                    {controller.renderCustomContent(currentComp, subDisplay)}
                                                </Fragment>
                                            ))}
                                        </li>
                                    </>
                                ) : (
                                    <>
                                        {controller.continueRenderContentByKey(customContentStyle, key, display[key])}
                                    </>
                                   
                                )}
                            </>
                        )}
                    </Fragment>
                ))}
            </Fragment>
        )
    },

    continueRenderContentByKey(customContentStyle, key, display) {
        const { t } = controller.vm.props;
        display = commonUtil.getContentByCurrencyLanguage(controller.vm, display);
        let desc = commonUtil.getContentByCurrencyLanguage(controller.vm, display.desc);
        if (typeof desc === 'object') {
            return null;
        }
        switch (key) {
            case 'img':
                return (
                    <ImageHelper 
                        displayImg={display.src}
                        className={display.className}
                        style={controller.getContentStyle(customContentStyle, display)}
                    />
                )
                break;
            case 'svg':
                return (
                    <>
                    <ImageHelper 
                        displaySVG={display.name}
                        className={display.className}
                        isSVG={true}
                        svgType={display.svgType}
                        style={controller.getContentStyle(customContentStyle, display)}
                    />
                    </>
                )
                break;
            case 'div':
                return (
                    <div style={controller.getContentStyle(customContentStyle, display)} dangerouslySetInnerHTML={{ __html: t(desc, desc) }} />                  
                )
                break;
            case 'p':
                return (
                    <p style={controller.getContentStyle(customContentStyle, display)} dangerouslySetInnerHTML={{ __html: t(desc, desc) }} />
                )
                break;
            case 'span':
                return (
                    <span style={controller.getContentStyle(customContentStyle, display)} dangerouslySetInnerHTML={{ __html: t(desc, desc) }} />
                )
                break;
            case 'li':
                return (
                    <li style={controller.getContentStyle(customContentStyle, display)} dangerouslySetInnerHTML={{ __html: t(desc, desc) }} />
                )
                break;
            default:
                break;

        }
    },

    getContentStyle(customContentStyle, display, isHover) {
        let style = null;
        let tempStyle = commonUtil.getContentByCurrencyLanguage(controller.vm, display.style) ? commonUtil.getContentByCurrencyLanguage(controller.vm, display.style): {};
        let tempPresetStyle = commonUtil.getContentByCurrencyLanguage(controller.vm, display.presetStyle) ? commonUtil.getContentByCurrencyLanguage(controller.vm, display.presetStyle): {};
        let presetStyle = tempPresetStyle ? customContentStyle[tempPresetStyle] : {};

        let hoverStyle = isHover && commonUtil.getContentByCurrencyLanguage(controller.vm, display.hoverStyle) ? commonUtil.getContentByCurrencyLanguage(controller.vm, display.hoverStyle) : {};
        let tempPresetHoverStyle = isHover && commonUtil.getContentByCurrencyLanguage(controller.vm, display.presetHoverStyle) ? commonUtil.getContentByCurrencyLanguage(controller.vm, display.presetHoverStyle): {};
        let presetHoverStyle = isHover && tempPresetHoverStyle ? customContentStyle[tempPresetHoverStyle] : {};

        if (isHover) {
            style = commonUtil.jsonConcat(presetHoverStyle, hoverStyle);
        } else {
            style = commonUtil.jsonConcat(presetStyle, tempStyle);
        }
        return style;
    },

    handleItemOnClick(item) {
        const { language, screen } = controller.vm.props;

        if (item) {
            // action
            if (item.onClickFunction) {
                let functionParams = item.param && Object.values(item.param).length > 0 ? Object.values(item.param) : '';
                if (functionParams) {
                    controller[item.onClickFunction](functionParams.toString());
                }
            } else if (item.scrollTo) {
                let filterElement = item.scrollTo && item.scrollTo.replace('#', '');
                let element = document.getElementById(filterElement);
                if (element) {
                    commonUtil.scrollElementIntoView(controller.vm, element, 'smooth');
                }
            } else if (item.href) {
                controller.vm.props.history.push(item.href);
            } else if (item.openNewPageHref) {
                window.open(item.openNewPageHref, item.params && item.params.behavior, item.params && item.params.specs);
            } else if (item.openLiveChat) {
                core.openLivechat(language.countryLanguageKey, screen.viewType, false);
            }
        }
    },

    togglePopup(item) {
        commonUtil.togglePopup(controller.vm, item);
    },
    
}

export default controller;