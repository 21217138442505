import { Component, default as React } from 'react';
import { Col, Row, Spinner } from 'react-bootstrap';
import { ModalBody, ModalFooter, Modal, ModalHeader } from 'reactstrap';
import { Translation, withTranslation } from 'react-i18next';
import { AiOutlineSync } from 'react-icons/ai';
import { MdFavorite } from 'react-icons/md';
import { connect } from 'react-redux';
import { Tab, TabList, TabPanel, Tabs } from 'react-web-tabs';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-web-tabs/dist/react-web-tabs.css';
import SmallLoading from '../../components/Loading/SmallLoading';
import Loading from '../../components/Loading/Loading';
import { mapStateToProps } from '../../redux/selector/selector';
import LoginModal from '../02Login/LoginModal';
import Deposit from '../08Deposit/08_Deposit';
import { casinoslotsfishItem, imgRestoreDark, sportsbookItem } from '../10Transfer/101_Transfer_Items_Data';
import Transfer from '../10Transfer/10_Transfer';
import Withdraw from '../11Withdraw/11_Withdraw';
import MessagingDetailModal from '../12Messaging/122_MessagingDetailModal';
import Messaging from '../12Messaging/12_Messaging';
import EditProfile from '../14EditProfile/14_EditProfile';
import MyProfile from '../14EditProfile/14_MyProfile';
import History from '../15History/15_History';
import ChangeGamePassword from '../19ChangeGamePassword/19_ChangeGamePassword';
import ChangePassword from '../13ChangePassword/13_ChangePassword';
import BankDetail from '../17BankDetail/17_BankDetail';
import { accountMenu, imgRestore, userstatusImg, bankFooter, userProfile } from './161_MyAccount_Items_Data';
import SVGMyAcc from '../../components/Icon/SVGMyAcc';
import MyAccountMobile from './16_MyAccount_Mobile';
import MemberHeader from './16_MemberHeader';
import AnnouncementSection from '../../layout/MainNavigation/AppHeader/Announcement';
import 'react-step-progress-bar/styles.css';
import { ProgressBar } from 'react-step-progress-bar';
import Member from './member/Member';
import MainLayout from 'root/layout/MainLayout';
import notification from 'root/utils/notification';

class MyAccount extends Component {
    isMount = true;
    constructor(props, context, images) {
        super(props);
        this.state = {
            menus: [],
            submenus: [],
            loginModalVisible: false,
            itemIdOnclick: '',
            messageList: [],
            listOfCheckedId: [],
            allChecked: false,
            show: false,
            checkAll: false,
            mainWallet: null,
            restoreOnLoad: false,
            likeBtn: true,
            sportsArr: sportsbookItem,
            casinoArr: casinoslotsfishItem,
            test: [],
            providerList: [],
            toUpdate: false,
            isLoading: false,
            withDrawAnnoucementList: [],
            withdrawModal: false,
            dontShowWithdrawal: false,
            depositAnnoucementList: [],
            depositModal: false,
            dontShowDeposit: false,
        };
        this.toggleWithdraw = this.toggleWithdraw.bind(this);
        this.toggleDeposit = this.toggleDeposit.bind(this);
    }

    componentDidMount() {
        this.isMount = true;
        // this.getMainWallet();
        this.getMessages();
        // this.loadProviders(true);
        this.tabRedirect();
        this.getAnnoucementWithdrawal();
        this.getAnnoucementDeposit();
    }

    // withdraw Annoucement Modal
    toggleWithdraw() {
        this.setState({ withdrawModal: false });
        let check = this.state.dontShowWithdrawal;
        let list = this.state.withDrawAnnoucementList;
        if (check === true) {
            window.SPL_Content.setHideAnnouncement(list);
        }
    }

    getAnnoucementWithdrawal() {
        const { countryLanguageKey } = this.props.language;
        const { isLogin } = this.props.user;

        window.SPL_Content.getAnnoucementList(isLogin, countryLanguageKey, 'Withdrawal').then((data) => {
            let popUpList = data.announcementsWithPopUpDisplay;
            if (this.isMount) {
                if (popUpList.length > 0 && this.state.itemIdOnclick === 'withdraw') {
                    this.setState({ withDrawAnnoucementList: popUpList, withdrawModal: true });
                }
            }
        });
    }

    dontShowWithdraw = (e) => {
        let check = e.target.checked;
        if (check === 'true') {
            this.setState({ dontShowWithdrawal: false });
        } else {
            this.setState({ dontShowWithdrawal: true });
        }
    };

    checkDontShowWithdraw() {
        this.setState({ withdrawModal: false });
        let check = this.state.dontShowWithdrawal;
        let list = this.state.withDrawAnnoucementList;
        if (check === true) {
            window.SPL_Content.setHideAnnouncement(list);
        }
    }

    // Deposit Annoucement Modal
    toggleDeposit() {
        this.setState({ depositModal: false });
        let check = this.state.dontShowDeposit;
        let list = this.state.depositAnnoucementList;
        if (check === true) {
            window.SPL_Content.setHideAnnouncement(list);
        }
    }

    getAnnoucementDeposit() {
        const { language } = this.props;
        const { isLogin } = this.props.user;

        window.SPL_Content.getAnnoucementList(isLogin, language.countryLanguageKey, 'Deposit').then((data) => {
            let popUpList = data.announcementsWithPopUpDisplay;
            if (this.isMount) {
                if (popUpList.length > 0 && this.state.itemIdOnclick === 'deposit') {
                    this.setState({ depositAnnoucementList: popUpList, depositModal: true });
                }
            }
        });
    }

    dontShowDepositCheckBox = (e) => {
        let check = e.target.checked;
        if (check === 'true') {
            this.setState({ dontShowDeposit: !this.state.dontShowDeposit });
        } else {
            this.setState({ dontShowDeposit: !this.state.dontShowDeposit });
        }
    };

    checkDontShowDeposit() {
        this.setState({ depositModal: false });
        let check = this.state.dontShowDeposit;
        let list = this.state.depositAnnoucementList;
        if (check === true) {
            window.SPL_Content.setHideAnnouncement(list);
        }
    }

    tabRedirect = () => {
        if (this.isMount) {
            let path = this.props.location.pathname;
            if (path === '/myaccount/myprofile') {
                this.setState({ itemIdOnclick: 'myprofile' });
            } else if (path === '/myaccount/deposit') {
                this.setState({ itemIdOnclick: 'deposit' });
                if (this.state.itemIdOnclick === 'deposit' && this.state.dontShowDeposit === false && this.state.depositAnnoucementList.length > 0) {
                    this.setState({ depositModal: true });
                } else {
                    this.setState({ depositModal: false });
                }
                // } else if (path === '/myaccount/quickpay') {
                //   this.setState({ itemIdOnclick: 'deposit' });
                //   if (this.state.itemIdOnclick === 'deposit' && this.state.dontShowDeposit === false && this.state.depositAnnoucementList.length > 0) {
                //     this.setState({ depositModal: true });
                //   } else {
                //     this.setState({ depositModal: false });
                //   }
                // } else if (path === '/myaccount/onlinetransfer') {
                //   this.setState({ itemIdOnclick: 'deposit' });
                //   if (this.state.itemIdOnclick === 'deposit' && this.state.dontShowDeposit === false && this.state.depositAnnoucementList.length > 0) {
                //     this.setState({ depositModal: true });
                //   } else {
                //     this.setState({ depositModal: false });
                //   }
            } else if (path === '/myaccount/transfer') {
                this.setState({ itemIdOnclick: 'transfer' });
            } else if (path === '/myaccount/withdraw') {
                this.setState({ itemIdOnclick: 'withdraw' });
                if (this.state.itemIdOnclick === 'withdraw' && this.state.dontShowWithdrawal === false && this.state.withDrawAnnoucementList.length > 0) {
                    this.setState({ withdrawModal: true });
                } else {
                    this.setState({ withdrawModal: false });
                }
            } else if (path === '/myaccount/report') {
                this.setState({ itemIdOnclick: 'report' });
            } else if (path === '/myaccount/messaging') {
                this.setState({ itemIdOnclick: 'messaging' });
            } else if (path === '/myaccount/changepassword') {
                this.setState({ itemIdOnclick: 'changepassword' });
            } else if (path === '/myaccount/changegamepassword') {
                this.setState({ itemIdOnclick: 'changegamepassword' });
            } else if (path === '/myaccount/bankdetails') {
                this.setState({ itemIdOnclick: 'bankdetails' });
            }
        }
    };

    isChecked = (index) => {
        return this.state.checkAll || this.state.messageList[index].isChecked;
    };

    componentWillUnmount() {
        this.isMount = false;
    }

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            this.tabRedirect();
        }
        if (prevProps !== this.props) {
            this.getAnnoucementWithdrawal();
        }
        if (prevProps !== this.props) {
            this.getAnnoucementDeposit();
        }
    }

    enterLoading = () => {
        this.setState({ loading: !this.state.loading });
    };

    enterIconLoading = () => {
        this.setState({ iconLoading: !this.state.iconLoading });
    };

    loadProviders = (isLoadAsyncProviderWallet) => {
        this.setState({ iconLoading: true });
        window.SPL_Provider.getAllProviderWalletList(null, false, this.props)
            .then((data) => {
                if (this.isMount) {
                    let providerList = data.providerList;
                    let fromProvider;
                    let toProvider;
                    const { currency } = this.props.user.account || { currency: '' };
                    const { t } = this.props;
                    for (let i = 0; i < providerList.length; i++) {
                        let balance = providerList[i].balance;
                        if (balance) {
                            balance = window.SPL_Other.formatAmount(providerList[i].balance);
                        } else {
                            balance = 0;
                        }
                        // providerList[i].provider = `${providerList[i].provider === null ? null : providerList[i].provider}`;
                        providerList[i].displayName = `${providerList[i].provider === null ? t('settings:settings.mywallet') : providerList[i].provider}`;
                        providerList[i].display = `${providerList[i].name ? providerList[i].name : 'My Wallet'} (${balance} ${currency}) 
                                         ${t(providerList[i].serverMaintenanceStr)}`;

                        if (providerList[i].provider === null) {
                            fromProvider = [providerList[i]];
                            toProvider = [providerList[i]];
                        }
                    }
                    this.setState({
                        providerList: data.providerList,
                        totalvalidturnoveramount: data.totalvalidturnoveramount,
                        transferAmount: fromProvider[0].balance,
                        fromProvider: fromProvider,
                        toProvider: toProvider,
                        iconLoading: false,
                    });
                    this.toUpdateProviderInfo(true);
                }
            })
            .then(() => {
                if (isLoadAsyncProviderWallet) {
                    this.loadAsyncProviderWallet();
                }
            });
    };

    loadPromotions = (provider) => {
        const { countryLanguageKey } = this.props.language;
        const { portal, screen } = this.props;
        
        if (provider) {
            let platform = null;

            if (portal && portal.settings && portal.settings.getPromoCodeByPlatform) {
                if (window.SPL_LiteApp.isZT828LiteApp()) {
                  platform = 'A';
                } else if (screen.viewType === 'web') {
                  platform = 'W';
                } else {
                  platform = 'M';
                }
            }

            window.SPL_Provider.loadPromotions(provider, null, countryLanguageKey, platform).then((promoCodeList) => {
                if (this.isMount) {
                    promoCodeList.unshift(this.defaultPromo);
                    this.setState({
                        promoCodeList: promoCodeList,
                        selectedPromotionCode: [promoCodeList[0]],
                    });
                }
            });
        } else {
            let promoCodeList = [];
            promoCodeList.unshift(this.defaultPromo);
            if (this.isMount) {
                this.setState(
                    {
                        promoCodeList: promoCodeList,
                        selectedPromotionCode: [promoCodeList[0]],
                    },
                    () => {}
                );
            }
        }
    };

    requestTransferAllInToProvider = (provider) => {
        const { t } = this.props;
        let mainWallet = this.state.providerList.filter((e) => e.provider === null)[0];
        let transferAmount = mainWallet.balance;
        if (transferAmount <= '0') {
            notification.showNotification('error', t('transaction:transaction.transfer.insuffficientMainWallet'));
            return;
        }
        let remark = 'Member Deposit';

        this.setState({ showLoading: true, iconLoading: true, restoreOnLoad: true }, () => {
            window.SPL_Transaction.requestTransfer(transferAmount, 0, remark, provider, null).then((data) => {
                this.setState({ showLoading: false, iconLoading: false });
                if (data.status === 'F') {
                    if (data.msg && data.msg.data && data.msg.data.detail) {
                        notification.showNotification('error', t(data.msg.data.detail));
                    } else {
                        notification.showNotification('error', t(data.msg));
                    }
                } else {
                    this.setState({
                        transferAmount: 0,
                        selectedPromotionCode: [this.state.promoCodeList && this.state.promoCodeList.length > 0 ? this.state.promoCodeList[0] : ''],
                        refreshMainWallet: true,
                        providerList: [],
                    });
                    this.loadPromotions();
                    this.getMainWallet();
                }
            });
        });
    };

    loadAsyncProviderWallet = () => {
        if (this.isMount) {
            this.setState({ isLoad: true }, () => {
                window.SPL_Provider.loadAsyncProviderWallet(null, this.props).then((providerWallet) => {
                    let providerList = this.state.providerList;
                    for (let i = 0; i < providerList.length; i++) {
                        let results = providerWallet.providerList.filter(function (e) {
                            return e.provider === providerList[i].provider;
                        })[0];

                        if (results) {
                            providerList[i].balance = results.balance ? results.balance : 0;
                            providerList[i].serverIsUnderMaintenance = results.serverIsUnderMaintenance;
                            providerList[i].isdisplay = true;
                            providerList[i].serverMaintenanceStr = results.serverIsUnderMaintenance ? 'error:error.underMaintainance' : '';
                        } else {
                            providerList[i].serverMaintenanceStr = '';
                        }
                        if (this.isMount) {
                            this.setState({ providerList: providerList });
                        }
                    }
                });
            });
        }
    };

    imageClick = (menu) => {
        if (this.isMount) {
            this.setState({ itemIdOnclick: menu.submenu });
        }
        this.props.history.push(menu.route);
    };

    getImage = (menu) => {
        if (menu.submenu === this.state.itemIdOnclick) {
            return menu.pathOn;
        } else {
            return menu.path;
        }
    };

    menuTabOnclick(id) {
        this.setState({ itemIdOnclick: id });
        // if (menu.submenu === 'TV') {
        // 	this.props.history.push('/livetv');
        // } else if (menu.submenu === 'P') {
        // 	this.props.history.push(`/casino/${menu.provider.toLowerCase()}`);
        // } else {
        // 	if (menu.name.toLowerCase() === 'sports') {
        // 		menu.name = 'sportsbook';
        // 	}
        // 	this.props.history.push(`/home/${menu.name.toLowerCase()}`);
        // }
    }

    handleLoginModalOk = () => {
        this.setState({ loginModalVisible: false });
    };

    getMainWallet = () => {
        this.setState({ iconLoading: true, restoreOnLoad: true });
        let component = this;
        return new Promise(function (resolve, reject) {
            window.SPL_Member.getMainWallet()
                .then((mainWallet) => {
                    if (component.isMount) {
                        component.setState({
                            mainWallet: mainWallet.data,
                            iconLoading: false,
                            restoreOnLoad: false,
                        });
                        component.loadProviders(true);
                        resolve(mainWallet.data);
                    }
                })
                .catch((err) => {
                    if (component.isMount) {
                        component.setState({ iconLoading: false, restoreOnLoad: false });
                    }
                });
        });
    };

    restoreWallet = () => {
        this.setState({ restoreOnLoad: true });
        window.SPL_Transaction.transferAllBackMainWallet()
            .then(() => {
                setTimeout(() => {
                    this.getMainWallet();
                    this.setState({ restoreOnLoad: false });
                }, 5000);
            })
            .catch(() => {
                setTimeout(() => {
                    this.setState({ restoreOnLoad: false });
                    this.getMainWallet();
                }, 5000);
            });
    };

    getMessages() {
        window.SPL_Member.getMessages().then((msgObj) => {
            if (this.isMount) {
                let messageArr = msgObj.messages;
                for (let i = 0; i < messageArr.length; i++) {
                    messageArr[i].isChecked = false;
                    if (messageArr[i].status.includes('F')) {
                        messageArr[i].isFlagChecked = true;
                    } else {
                        messageArr[i].isFlagChecked = false;
                    }
                }
                this.setState({ messageList: messageArr });
            }
        });
    }

    onClickMessage = (index, page) => {
        let subIndex = index;
        let i = 1;
        if (page > i) {
            for (let j = 0; j < i; j++) {
                subIndex = subIndex + 10;
            }
        }

        let array = this.state.messageList;
        let msg = array[subIndex];
        let messageObj = {
            id: msg.id,
            isFlag: msg.isFlagChecked,
            status: 'R',
        };
        array[subIndex] = msg;
        this.setState({ selectedMessage: msg, openMessageModal: true });
        window.SPL_Member.readAndFlagMessage(messageObj, () => {
            if (this.timeOut) {
                clearTimeout(this.timeOut);
            }
            this.timeOut = setTimeout(() => {
                if (this.isMount) {
                    this.getMessages();
                }
            }, 1000);
        });
    };

    deleteMessageByBatch = () => {
        this.setState(
            { isLoading: true },
            () => {
                let listOfCheckedId = [];
                let messageList = this.state.messageList;
                for (let i = 0; i < messageList.length; i++) {
                    const msg = messageList[i];
                    if (msg.isChecked) {
                        listOfCheckedId.push(msg.id);
                    }
                }
                this.setState({ listOfCheckedId: listOfCheckedId }, () => {
                    window.SPL_Member.deleteMessage(listOfCheckedId).then((data) => {
                        this.timeOut = setTimeout(() => {
                            if (this.isMount) {
                                this.getMessages();
                            }
                        }, 1000);
                        this.setState({ listOfCheckedId: [] });
                    });
                });
            },
            setTimeout(() => {
                this.setState({ isLoading: false });
            }, 1800)
        );
    };

    onChange = (index) => {
        let array = this.state.messageList;
        array[index].isChecked = !array[index].isChecked;
        this.setState({ messageList: array, checkAll: false });
    };

    onFlagChange = (index) => {
        let array = this.state.messageList;
        let msg = array[index];
        msg.isFlagChecked = !msg.isFlagChecked;
        let messageObj = {
            id: msg.id,
            isFlag: msg.isFlagChecked,
            status: msg.status,
        };
        array[index] = msg;
        window.SPL_Member.readAndFlagMessage(messageObj, () => {
            this.setState({ messageList: array });
        });
    };

    updateFavorite = (providerObj, index) => {
        let isFavorite = true;
        if (providerObj.favorite) {
            isFavorite = false;
        }

        let tempProvider = this.state.providerList;
        tempProvider[index].favorite = isFavorite;
        this.setState({ providerList: tempProvider });

        let req = { provider: providerObj.provider, isFavorite: isFavorite };
        window.SPL_Provider.updateMemberFavoriteTransactionProvider(req).then(() => {
            this.loadProviders(true);
        });
    };

    closeModal = () => {
        this.setState({ selectedMessage: null, openMessageModal: false });
    };

    isFlagChecked = (index) => {
        return this.state.messageList[index].isFlagChecked;
    };

    tabScrollRight = () => {
        document.getElementById('tabScroll').scrollLeft += 50;
    };

    tabScrollLeft = () => {
        document.getElementById('tabScroll').scrollLeft += -50;
    };

    toUpdateProviderInfo = (toUpdate) => {
        this.setState({ toUpdate: toUpdate });
    };

    render = () => {
        const { restoreOnLoad } = this.state;
        // const { history } = this.props;
        // const { iconLoading } = this.state;
        // let btn_class = this.state.likeBtn ? this.props.color : this.props.color2;
        return (
            <Translation>
                {(t) =>
                    this.props.user &&
                    this.props.user.account && (
                        <MainLayout>
                            <Member />
                        </MainLayout>
                        // <div>
                        //   <MyAccountMobile />
                        //   <div className='d-none d-md-block'>
                        //     {/* Withdraw Annoucement Modal */}
                        //     <Modal className='modal-lg h-auto annoucement-modal-dialog' isOpen={this.state.withdrawModal} toggle={this.toggleWithdraw}>
                        //       <ModalHeader className='w-100 border-bottom-0'>
                        //         <Col xs='12' sm='12' className='d-flex justify-content-start align-items-center'>
                        //           <div className='d-block'>
                        //             <div className='text-nowrap'>{t('global:global.announcement2', 'Annoucement')}</div>
                        //           </div>
                        //         </Col>
                        //         <button type='submit' onClick={() => this.checkDontShowWithdraw()} className='close'>
                        //           <span className='announcement-cancel'>x</span>
                        //         </button>
                        //       </ModalHeader>
                        //       <ModalBody className='text-left w-100'>
                        //         <div>
                        //           {/* Before Login Content */}
                        //           {this.state.withDrawAnnoucementList.map((annoucement) => {
                        //             return (
                        //               <div key={annoucement.id}>
                        //                 <span>{annoucement.contentLanguage}</span>
                        //               </div>
                        //             );
                        //           })}
                        //         </div>
                        //       </ModalBody>
                        //       <ModalFooter className='w-100 d-flex justify-content-between align-items-center'>
                        //         <Row className='w-100'>
                        //           <Col xs='9' className='d-flex justify-content-start align-items-center'>
                        //             <input id='dontShow' type='checkbox' className='m-0 pointer' onChange={(event) => this.dontShowWithdraw(event)}></input>
                        //             <label htmlFor='dontShow' className='small m-l-10 m-b-0 pointer'>
                        //               {t('global:global.dontshowagain', `Don't show again`)}
                        //             </label>
                        //           </Col>

                        //           <Col xs='3' className='d-flex justify-content-end align-items-center'>
                        //             <button type='submit' onClick={() => this.checkDontShowWithdraw()} className='primary-btn button-35px'>
                        //               <span>{t('global:global.verification.close', 'Close')}</span>
                        //             </button>
                        //           </Col>
                        //         </Row>
                        //       </ModalFooter>
                        //     </Modal>
                        //     {/* Withdraw Annoucement Modal end */}

                        //     {/* Deposit Annoucement Modal */}
                        //     <Modal className='modal-lg h-auto annoucement-modal-dialog' isOpen={this.state.depositModal} toggle={this.toggleDeposit}>
                        //       <ModalHeader className='w-100 d-flex'>
                        //         <Col xs='12' sm='12' className='d-flex justify-content-start align-items-center'>
                        //           <div className=' text-nowrap'>{t('global:global.announcement2', 'Annoucement')}</div>
                        //         </Col>

                        //         <button type='submit' onClick={() => this.checkDontShowDeposit()} className='close'>
                        //           <span className='announcement-cancel'>x</span>
                        //         </button>
                        //       </ModalHeader>
                        //       <ModalBody className='text-left w-100'>
                        //         <div>
                        //           {/* Before Login Content */}
                        //           {this.state.depositAnnoucementList.map((annoucement) => {
                        //             return (
                        //               <div key={annoucement.id}>
                        //                 <span>{annoucement.contentLanguage}</span>
                        //               </div>
                        //             );
                        //           })}
                        //         </div>
                        //       </ModalBody>
                        //       <ModalFooter className='w-100 d-flex justify-content-between align-items-center'>
                        //         <Row className='w-100 '>
                        //           <Col xs='9' className='d-flex justify-content-start align-items-center'>
                        //             <input
                        //               id='dontShow'
                        //               type='checkbox'
                        //               className='m-0 pointer'
                        //               onChange={(event) => this.dontShowDepositCheckBox(event)}></input>
                        //             <label htmlFor='dontShow' className='small m-l-10 m-b-0 pointer'>
                        //               {t('global:global.dontshowagain', `Don't show again`)}
                        //             </label>
                        //           </Col>

                        //           <Col xs='3' className='d-flex justify-content-end align-items-center'>
                        //             <button type='submit' onClick={() => this.checkDontShowDeposit()} className='primary-btn button-35px'>
                        //               <span>{t('global:global.verification.close', 'Close')}</span>
                        //             </button>
                        //           </Col>
                        //         </Row>
                        //       </ModalFooter>
                        //     </Modal>
                        //     {/* Deposit Annoucement Modal End */}

                        //     {this.state.isLoading && <Loading className='vw-100 vh-100'></Loading>}
                        //     <div className='justify-content-center account-content'>
                        //       {this.state.loginModalVisible}
                        //       <LoginModal handleOk={this.handleLoginModalOk} visible={this.state.loginModalVisible}></LoginModal>

                        //       <MessagingDetailModal
                        //         messaging={this.state.selectedMessage}
                        //         isOpen={this.state.openMessageModal}
                        //         closeModal={this.closeModal}
                        //         buttonText='DETAILS'
                        //         buttonClass={'btn-secondary btn-lg'}
                        //         onFlagChange={this.onFlagChange}>
                        //       </MessagingDetailModal>
                        //       {/* Mobile wallet */}
                        //       <div className='mobile-mainWallet d-block d-sm-none p-10'>
                        //         <Row className='d-flex justify-content-center'>
                        //           <div className='d-flex align-items-center justify-content-center'>
                        //             <h5 className='mainWallet-text'>{t('global:global.form.mainWallet', 'Main Wallet')}</h5>
                        //           </div>
                        //         </Row>

                        //         <Row className='d-flex justify-content-center h-50px'>
                        //           <div className='col-8 d-flex justify-content-center align-items-center'>
                        //             <div className={`${!this.state.restoreOnLoad ? 'd-flex text-primary align-items-center' : 'd-none '}`}>
                        //               {this.props.user && this.props.user.account && this.props.user.account.currency && (
                        //                 <h5 className='title'>{this.props.user.account.currency}</h5>
                        //               )}
                        //               <div className='h2 m-l-5 m-r-5 title'>
                        //                 {(this.state.mainWallet && window.SPL_Other.formatAmount(this.state.mainWallet.balance)) ||
                        //                   window.SPL_Other.formatAmount('0')}
                        //               </div>
                        //             </div>
                        //             <div className={`${this.state.restoreOnLoad ? 'd-flex align-items-center' : 'd-none'}`}>
                        //               <SmallLoading></SmallLoading>
                        //             </div>
                        //           </div>
                        //           <div className='col-4 d-flex justify-content-start align-items-center'>
                        //             <button
                        //               className='icon-primary bg-transparent p-l-0 p-r-25 d-flex justify-content-center align-items-center'
                        //               onClick={this.getMainWallet}>
                        //               <AiOutlineSync className={`icon-xs  ${!restoreOnLoad ? 'd-sm-block icon-xs icon-primary' : 'd-none'}`} />
                        //               <Spinner animation='border' className={`icon-xs  ${restoreOnLoad ? 'd-sm-block icon-xs icon-primary' : 'd-none'}`} />
                        //             </button>
                        //           </div>
                        //         </Row>
                        //         <Row className='d-flex justify-content-center'>
                        //           <div>
                        //             <button
                        //               type='submit'
                        //               disabled={this.state.restoreOnLoad}
                        //               onClick={this.restoreWallet}
                        //               className='d-flex justify-content-center align-items-center button-restore-mobile uppercase'>
                        //               <div className='p-l-10 p-r-10 p-t-10 p-b-10 d-flex align-items-center'>
                        //                 <LazyLoadImage src={imgRestore} className='img-sm m-r-5' alt='restore' />

                        //                 {t('settings:settings.transferwallet')}
                        //               </div>
                        //             </button>
                        //           </div>
                        //         </Row>
                        //       </div>

                        //       <div className='container-fluid account-action p-l-0 p-r-0 p-b-1'>
                        //         <div className='container account-action-container p-l-0 p-r-0'>
                        //           <Row className='w-100'>
                        //             <Col xs='12' className='myacc-header d-flex'>
                        //               <div className='myacc-left-container'></div>
                        //               <div className='myacc-right-container'>
                        //                 <MemberHeader
                        //                   itemIdOnclick={this.state.itemIdOnclick}
                        //                   pathname={this.props.location.pathname}>
                        //                 </MemberHeader>
                        //               </div>

                        //             </Col>
                        //             <Col xs={12}>
                        //               {this.state
                        //                 .itemIdOnclick && (
                        //                   <Tabs
                        //                     defaultTab={
                        //                       this.state
                        //                         .itemIdOnclick
                        //                     }
                        //                     className=''

                        //                     vertical
                        //                   >
                        //                     <div className='row setting-page'>
                        //                       <div className='d-none d-md-block myacc-left-container'>
                        //                         <div>
                        //                           <div className='acc-member-container'>
                        //                             <Row>  <LazyLoadImage src={userstatusImg} className='member-img' alt='user-img' />
                        //                               <div className='member-detail'>
                        //                                 <div className='member-name'>cch3993</div>
                        //                                 <div className='membership-level'>Sapphire</div>
                        //                               </div></Row>

                        //                             <div className='progress-container'>
                        //                               <div className='progress-bar'><ProgressBar percent={20} /></div>
                        //                               <div className='progress-text'>200,000 turnover to Ruby</div>
                        //                             </div>

                        //                           </div>
                        //                           {/* {this.props.user.account.membershipLevel && (<div className='membership-level myAcc-text'>{this.props.user.account.membershipLevel}</div>)} */}

                        //                           {/* <div className='text-center'>
                        //                             <svg className=' icon-md myAcc-icon' aria-hidden='true'>
                        //                               <SVGMyAcc name='myr' />
                        //                             </svg>
                        //                             <div className='myAcc-currency h6 m-t-5 font-weight-bold'>{this.props.user.account.currency}</div>
                        //                           </div>
                        //                           <a className='bg-transparent p-l-0 p-r-0 d-flex justify-content-center align-items-center'
                        //                             onClick={this.getMainWallet}>
                        //                             <AiOutlineSync className={`icon-xs  ${!restoreOnLoad ? 'd-sm-block icon-xs myAcc-icon' : 'd-none'}`} />
                        //                             <Spinner animation='border' className={`icon-xs  ${restoreOnLoad ? 'd-sm-block icon-xs myAcc-icon' : 'd-none'}`}
                        //                             />
                        //                           </a>
                        //                           <div className={`${!this.state.restoreOnLoad ? 'd-flex myAcc-text align-items-center justify-content-center w-100' : 'd-none '}`}>
                        //                             {this.props.user && this.props.user.account && this.props.user.account.currency && (<h5 className='title'>{this.props.user.account.currency}</h5>)}
                        //                             {(this.state.mainWallet && window.SPL_Other.formatAmount(this.state.mainWallet.balance)) || window.SPL_Other.formatAmount('0')}
                        //                           </div>
                        //                           <div className={`${this.state.restoreOnLoad ? 'd-flex align-items-center justify-content-center w-100' : 'd-none'}`}><SmallLoading></SmallLoading></div>
                        //                           <h6 className='myAcc-mainWallet'>{t('global:global.form.mainWallet', 'Main Wallet')}</h6>
                        //                           <button type='submit' disabled={this.state.restoreOnLoad} onClick={this.restoreWallet} className='primary-btn btn-block d-flex justify-content-center align-items-center rounded-pill text-capitalize button-submit button-transferAll'>
                        //                             <span>{t('global:global.menu.account.transferback')}</span>
                        //                           </button> */}
                        //                         </div>

                        //                         <TabList className='setting-tab-group '>
                        //                           <div className='setting-tab-title '>

                        //                             {t(
                        //                               'global:global.menu.account.banking'
                        //                             )}
                        //                           </div>
                        //                           {accountMenu.map(
                        //                             (
                        //                               menu,
                        //                               index
                        //                             ) => {
                        //                               return (
                        //                                 menu.text !==
                        //                                 'Account' &&
                        //                                 menu.text !==
                        //                                 'messaging' && (
                        //                                   <div
                        //                                     key={
                        //                                       menu.submenu
                        //                                     }
                        //                                     id={
                        //                                       menu.text
                        //                                     }
                        //                                     className='setting-tab-item'
                        //                                     onClick={() =>
                        //                                       this.imageClick(
                        //                                         menu
                        //                                       )
                        //                                     }
                        //                                   // onClick={() => this.triggerArrow(menu.submenu)}
                        //                                   >
                        //                                     <Tab tabFor={menu.submenu} key={index} className='h-100 w-100'>
                        //                                       <Row className='setting-tab-container'>
                        //                                         <div className='setting-tab-icon-section'>
                        //                                           {/* <SVGMyAcc
                        //                                             name={
                        //                                               menu.icon
                        //                                             }
                        //                                             className='setting-tab-icon '
                        //                                           /> */}
                        //                                           <img src={menu.img} alt={menu.text} className='setting-tab-icon' />
                        //                                         </div>
                        //                                         <div className='setting-tab-text-section'>
                        //                                           <div className='setting-tab-text'>
                        //                                             {menu.text}
                        //                                           </div>
                        //                                         </div>

                        //                                       </Row>
                        //                                     </Tab>
                        //                                   </div>
                        //                                 )
                        //                               );
                        //                             }
                        //                           )}

                        //                           <div className='setting-tab-title '>
                        //                             {t(
                        //                               'settings:settings.userProfile'
                        //                             )}
                        //                           </div>
                        //                           {userProfile.map(
                        //                             (
                        //                               menu,
                        //                               index
                        //                             ) => {
                        //                               return (
                        //                                 <div
                        //                                   key={
                        //                                     menu.submenu
                        //                                   }
                        //                                   id={
                        //                                     menu.text
                        //                                   }
                        //                                   className='setting-tab-item'
                        //                                   onClick={() =>
                        //                                     this.imageClick(
                        //                                       menu
                        //                                     )
                        //                                   }

                        //                                 >
                        //                                   <Tab tabFor={menu.submenu} key={index} className='h-100 w-100'>
                        //                                     <Row className='setting-tab-container'>
                        //                                       <div className='setting-tab-icon-section'>
                        //                                         {/* <SVGMyAcc
                        //                                           name={menu.icon} className='setting-tab-icon'
                        //                                         /> */}
                        //                                         <img src={menu.img} alt={menu.text} className='setting-tab-icon' />
                        //                                       </div>
                        //                                       <div className='setting-tab-text-section'>
                        //                                         <div className='setting-tab-text'>
                        //                                           {menu.text}
                        //                                         </div>
                        //                                       </div>

                        //                                     </Row>
                        //                                   </Tab>
                        //                                 </div>
                        //                               );
                        //                             }
                        //                           )}
                        //                         </TabList>
                        //                       </div>

                        //                       <Col className='d-block d-md-none'>
                        //                         <TabList>
                        //                           {accountMenu.map(
                        //                             (
                        //                               menu,
                        //                               index
                        //                             ) => {
                        //                               return (
                        //                                 menu.submenu !==
                        //                                 'messaging' && (
                        //                                   <div
                        //                                     key={
                        //                                       menu.submenu
                        //                                     }
                        //                                     className='vertical-tab-items p-l-0 p-r-0 '
                        //                                     onClick={() =>
                        //                                       this.imageClick(
                        //                                         menu
                        //                                       )
                        //                                     }
                        //                                   >
                        //                                     <Tab
                        //                                       className=' w-100 p-10 '
                        //                                       tabFor={
                        //                                         menu.submenu
                        //                                       }
                        //                                       key={
                        //                                         index
                        //                                       }
                        //                                     >
                        //                                       <div className='d-flex justify-content-center'>
                        //                                         <img
                        //                                           src={this.getImage(
                        //                                             menu
                        //                                           )}
                        //                                           className='img-sm d-block '
                        //                                           alt={
                        //                                             menu.name
                        //                                           }
                        //                                         />
                        //                                       </div>
                        //                                       <div className='text-center text-capitalize h6 work-sans'>
                        //                                         {
                        //                                           menu.text
                        //                                         }
                        //                                       </div>
                        //                                     </Tab>
                        //                                   </div>
                        //                                 )
                        //                               );
                        //                             }
                        //                           )}
                        //                         </TabList>
                        //                       </Col>

                        //                       <div className='myacc-right-container'>
                        //                         <Row className=' account-container'>
                        //                           <TabPanel
                        //                             tabId='transfer'
                        //                             className='col-12 p-0'
                        //                           >
                        //                             <Transfer></Transfer>
                        //                           </TabPanel>
                        //                           <TabPanel
                        //                             tabId='deposit'
                        //                             className='col-12 p-0'
                        //                           >
                        //                             <Deposit></Deposit>
                        //                           </TabPanel>

                        //                           <TabPanel
                        //                             tabId='withdraw'
                        //                             className='col-12 p-0'
                        //                           >
                        //                             <Withdraw></Withdraw>
                        //                           </TabPanel>
                        //                           <TabPanel
                        //                             tabId='history'
                        //                             className='col-12 p-0'
                        //                           >
                        //                             <History></History>
                        //                           </TabPanel>
                        //                           <TabPanel
                        //                             tabId='messaging'
                        //                             className='col-12 p-0'
                        //                           >
                        //                             <Messaging
                        //                               onClickMessage={
                        //                                 this
                        //                                   .onClickMessage
                        //                               }
                        //                               getMessages={
                        //                                 this
                        //                                   .getMessages
                        //                               }
                        //                               messageList={
                        //                                 this
                        //                                   .state
                        //                                   .messageList
                        //                               }
                        //                               isChecked={
                        //                                 this
                        //                                   .isChecked
                        //                               }
                        //                               isFlagChecked={
                        //                                 this
                        //                                   .isFlagChecked
                        //                               }
                        //                               onFlagChange={
                        //                                 this
                        //                                   .onFlagChange
                        //                               }
                        //                               deleteMessageByBatch={
                        //                                 this
                        //                                   .deleteMessageByBatch
                        //                               }
                        //                             ></Messaging>
                        //                           </TabPanel>
                        //                           <TabPanel
                        //                             tabId='changegamepassword'
                        //                             className='col-12 p-0'
                        //                           >
                        //                             <ChangeGamePassword></ChangeGamePassword>
                        //                           </TabPanel>
                        //                           <TabPanel
                        //                             tabId='accountgroup'
                        //                             className='col-12 p-0'
                        //                           >
                        //                             <EditProfile></EditProfile>
                        //                           </TabPanel>
                        //                           <TabPanel
                        //                             tabId='myprofile'
                        //                             className='col-12 p-0'
                        //                           >
                        //                             <MyProfile></MyProfile>
                        //                           </TabPanel>
                        //                           <TabPanel
                        //                             tabId='changepassword'
                        //                             className='col-12 p-0'
                        //                           >
                        //                             <ChangePassword></ChangePassword>
                        //                           </TabPanel>
                        //                           <TabPanel
                        //                             tabId='bankdetails'
                        //                             className='col-12 p-0'
                        //                           >
                        //                             <BankDetail></BankDetail>
                        //                           </TabPanel>
                        //                         </Row>
                        //                       </div>
                        //                     </div>
                        //                   </Tabs>
                        //                 )}
                        //             </Col>
                        //           </Row>

                        //         </div>
                        //       </div>
                        //     </div>
                        //   </div>
                        // </div>
                    )
                }
            </Translation>
        );
    };
}
export default connect(mapStateToProps, null)(withTranslation(['error'])(MyAccount));
