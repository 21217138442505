import moment from 'moment';

export const FreeSpinColumnConfigs = [
    {
        key: 'createdDate',
        sortable: true,
        render: (history) => moment(history.createdDate).format('YYYY-MM-DD HH:mm'),
    },
    {
        key: 'provider',
        render: (history, t) => t(`providerName:ProviderName.${history.provider}`),
    },
    {
        key: 'promotionId',
        render: (history) => history.promotionId,
    },
    {
        key: 'totalWinLoss',
        render: (history) => (typeof history.totalWinLoss === 'number' ? `${history['currency']} ${history.totalWinLoss}` : '-'),
    },
    {
        key: 'freeSpinCount',
        render: (history) => history.freeSpinCount,
    },
    {
        key: 'status',
        sortable: true,
        render: (history, t) => t(`transaction:transaction.freeSpinHistory.statusValue.${history.status}`),
    },
    {
        key: 'startTime',
        render: (history) => moment(history.startTime).format('YYYY-MM-DD HH:mm'),
    },
    {
        key: 'endTime',
        render: (history) => moment(history.endTime).format('YYYY-MM-DD HH:mm'),
    },
];
