import React from 'react';
import { Translation, withTranslation } from 'react-i18next';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { mapStateToProps } from '../../../redux/selector/selector';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { FaCaretDown } from 'react-icons/fa';
import SVGMyAcc from '../../../components/Icon/SVGMyAcc';

class Setting extends React.Component {
  isMount = true;
  constructor(props) {
    super(props);
    this.state = {
      account: null,
      isHovered: false,
    };
  }

  componentDidMount() {
    this.identity();
  }

  componentWillUnmount() {
    this.isMount = false;
  }

  toggleHover = (e) => {
    this.setState({ isHovered: e });
  };

  identity = () => {
    const { account } = this.props.user;
    if (this.isMount) {
      if (this.props.user.userName !== '') {
        this.setState({ account: this.props.user.userName });
      } else {
        this.setState({ account: this.props.user.account.login.toLowerCase() });
      }
    }
  };

  render() {
    return (
      <Translation>
        {(t) => (
          <div className='justify-content-center d-flex align-items-center m-r-10'>
            <div className='position-relative'>
              <div className='z-index-9999' onMouseEnter={() => this.toggleHover(true)} onMouseLeave={() => this.toggleHover(false)}>
                <div className='button-35px button-header d-flex justify-content-center align-items-center myacc-btn'>
                  <div>
                    {window.merchantCode === 'c7b' ? 
                      <SVGMyAcc name='setting' className='setting-icon' /> : '' }
                    {t('global:global.sidebar.account.heading', 'MY ACCOUNT')}
                    <FaCaretDown className='icon-xxs m-l-2 setting-dropdown' />
                  </div>
                </div>
              </div>
              <div
                className={`position-absolute t-0-pct l-0-pct myaccount-dropdown dropdown-menu ${this.state.isHovered ? 'show' : ''}`}
                onMouseEnter={() => this.toggleHover(true)}
                onMouseLeave={() => this.toggleHover(false)}>
                <Link to='/myaccount/myprofile' className='dropdown-item'>
                  {t('global:global.menu.account.settings', 'Settings').toUpperCase()}
                </Link>

                <Link to='/myaccount/deposit' className='dropdown-item'>
                  {t('global:global.sidebar.funds.deposit', 'DEPOSIT')}
                </Link>

                <Link to='/myaccount/transfer' className='dropdown-item'>
                  {t('global:global.sidebar.funds.transfer', 'TRANSFER')}
                </Link>

                <Link to='/myaccount/withdraw' className='dropdown-item'>
                  {t('global:global.sidebar.funds.withdraw', 'WITHDRAW')}
                </Link>
              </div>
            </div>
          </div>
        )}
      </Translation>
    );
  }
}

export default connect(mapStateToProps)(withRouter(Setting));
