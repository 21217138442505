import React, { useState } from "react";
// import "";
import Toolbar from "root/components/wap/Toolbar";
import Navbar from "root/components/wap/Navbar";
import MenuDrop from "root/components/header/MenuDrop";
import RouterChangeHandler from 'root/layout/MainNavigation/RouterChangeHandler/RouterChangeHandler';

export default function WapLayout(props) {
  const [show, setShow] = useState(false);
  const toggleShow = () => {
    setShow(!show);
  }
  return (
    <div className="wap">
      <RouterChangeHandler></RouterChangeHandler>
      <Toolbar onToogleMenu={toggleShow} name={props.name} />
      <MenuDrop menuIn={show} toggleShow={toggleShow}/>
      <div>{props.children}</div>
      <Navbar />
    </div>
  );
}
