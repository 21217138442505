import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { mapStateToProps } from 'root/redux/selector/selector';
// import MiniLazyComponent from 'root/webapp/shared/miniLazyComponent';
import SVGIndex from 'root/components/Icon/SVGIndex';
import MiniLazyComponent from '../miniLazyComponent';

class Button extends React.Component {
    props = null;

    constructor(props) {
        super();
        this.props = props;
        this.state = {
            SVGComponentType: null,
        };
    }

    componentDidMount() {
        const { buttonLeftImg } = this.props;
        if (buttonLeftImg) {
            let type = buttonLeftImg.type;
            this.setState({
                SVGComponentType: SVGIndex[type],
            });
        }
    }

    componentWillUnmount() {
        this.setState = () => {
            return;
        };
    }

    /*
        Standard button structure
        Params:
        className       - (String) button class name
        disabled        - (Boolean) determine if button disabled
        onClickFunction - (Function) button on click function
        param1          - function parameter 1 for onClickFunction, if needed
        param2          - function parameter 2 for onClickFunction, if needed
        buttonText      - (String) button content / text
        buttonLeftImg   - (HTML Element) img on left
        buttonRightImg  - (HTML Element) img on right
        isReceiptInput  - (Boolean) add input section for receipt file input
        handleReceiptFunction - (Function) function for receipt file input
        appendId - (String) to make id unique
    */
    render() {
        const {
            className,
            disabled,
            onClickFunction,
            buttonLeftImg,
            buttonText,
            buttonRightImg,
            param1,
            param2,
            param3,
            param4,
            isReceiptInput,
            handleReceiptFunction,
            svgStyle,
            id,
            appendId,
        } = this.props;
        const { SVGComponentType } = this.state;

        return (
            <button
                id={id ? id + (appendId ? appendId : '') : ''}
                className={className ? className : 'standard-submit-button'}
                disabled={disabled}
                onClick={() => (onClickFunction ? onClickFunction(param1, param2, param3, param4) : null)}
            >
                {buttonLeftImg && SVGComponentType && (
                    <>{buttonLeftImg.type && MiniLazyComponent(SVGComponentType, { name: buttonLeftImg.name ? buttonLeftImg.name : '', style: svgStyle ? svgStyle : {} })()}</>
                )}
                {buttonText && <>{buttonText}</>}
                {isReceiptInput && (
                    <input
                        className='standard-input'
                        id='hiddenFileInput'
                        style={{ display: 'none' }}
                        type='file'
                        accept='image/*,.pdf'
                        onChange={(e) => (handleReceiptFunction ? handleReceiptFunction(e) : null)}
                    ></input>
                )}
                {buttonRightImg && <buttonRightImg />}
            </button>
        );
    }
}

export default connect(mapStateToProps, null)(withRouter(Button));
