import React, { Component } from 'react';
import { Col, Row, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { AiOutlineDelete } from 'react-icons/ai';
import { Trans, Translation, withTranslation } from 'react-i18next';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import SVGMessage from '../../components/Icon/SVGMessage';
export default class ItemDefault extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState({ isOpen: !this.state.isOpen });
  }

  handleChange() {
    this.props.onChecked(this.props.item.id, !this.props.item.checked);
  }
  render() {
    return (
      <Translation>
        {(t) => (
          <div className='bankDetail-item form-group'>
            <Modal className='modal-sm' isOpen={this.state.isOpen} toggle={this.toggle}>
              <ModalHeader className='w-100 border-bottom-0'>
                <Col xs='12' sm='12' className='d-flex justify-content-start align-items-center'>
                  <div className='d-flex'>
                    <svg className='icon-xs m-r-5'>
                      <SVGMessage name='trashbin' />
                    </svg>
                    <div className='text-nowrap'>{t('bank:bank.deleteConfirm')}</div>
                  </div>
                </Col>

                <button type='submit' onClick={() => this.setState({ isOpen: false })} className='close'>
                  <span className='announcement-cancel'>x</span>
                </button>
              </ModalHeader>
              <ModalBody className='text-left'>{t('bank:bank.newBank.removeConfirm')}</ModalBody>

              <ModalFooter className='w-100 d-flex align-items-center justify-content-end'>
                <Col xs='12' className='d-flex justify-content-center'>
                  <Col className='d-flex align-items-center' sm='4' md='4' lg='4'>
                    <button className='primary-btn button-100pct' onClick={() => this.props.onDelete(this.props.item.id)}>
                      <span>{t('global:entity.action.delete')}</span>
                    </button>
                  </Col>
                </Col>
              </ModalFooter>
            </Modal>
            <div className='bankingDetails-Table d-none d-md-flex'>
              <Row className='d-flex align-items-center m-l-0 m-r-0 w-100'>
                {/* <Col xs='1' sm='1' className='d-flex align-items-center justify-content-center '>
									{!this.props.item.checked && (
										<div>
											<label class='custom-checkbox'>
												<input
													type='checkbox'
													// value={} checked={this.props.isChecked()} onChange={() => this.onChange()}
												/>
												<span class='checkmark'></span>
											</label>
										</div>
									)}
								</Col> */}

                <Col xs='4' sm='4' className='d-flex align-items-center justify-content-start'>
                  <LazyLoadImage src={this.props.item.iconPath} className='bankDetail-info img-sm' alt={this.props.item.id} />
                  <div className='bankDetail-info'>{this.props.item.bankBranch}</div>
                </Col>

                <Col xs='3' sm='3' className='  '>
                  <div className='bankDetail-info'> {this.props.item.accountHolder}</div>
                </Col>
                <Col xs='3' sm='3' className=' '>
                  <div className='bankDetail-info'>{`${this.props.item.bankCode} - ${this.props.item.accountNumber}`}</div>
                </Col>
                <Col xs='2' sm='2' className='d-flex align-items-center justify-content-center bank-table-10'>
                  {!this.props.item.checked && (
                    <div>
                      <button className='btn-link' type='button' onClick={this.toggle}>
                        {t('global:entity.action.delete')}
                      </button>
                    </div>
                  )}
                </Col>
              </Row>
            </div>
            <div className='d-block d-md-none mobile-bank-detail'>
              <div className=' '>
                <Row className='d-flex align-items-center justify-content-center'>
                  <Col xs={12} sm={12} className='d-flex align-items-center form-spacing'>
                    <Col xs={6} sm={6} className='d-flex'>
                      <div>{t('bank:bank.bank')}</div>
                    </Col>
                    <Col xs={6} sm={6} className='d-flex'>
                      <LazyLoadImage src={this.props.item.iconPath} className='bankDetail-info img-md' alt={this.props.item.id} />
                    </Col>
                  </Col>
                  <Col xs={12} sm={12} className='d-flex form-spacing'>
                    <Col xs={6} sm={6} className='d-flex'>
                      <div>{t('bank:bank.branch')}</div>
                    </Col>{' '}
                    <Col xs={6} sm={6} className='bankDetail-info'>
                      {this.props.item.bankBranch}
                    </Col>
                  </Col>
                  <Col xs={12} sm={12} className='d-flex form-spacing'>
                    <Col xs={6} sm={6} className='d-flex'>
                      <div>{t('bank:bank.account.holder')}</div>
                    </Col>
                    <Col xs={6} sm={6} className='bankDetail-info'>
                      {' '}
                      {this.props.item.accountHolder}
                    </Col>
                  </Col>
                  <Col xs={12} sm={12} className='d-flex form-spacing'>
                    <Col xs={6} sm={6} className='d-flex'>
                      <div>{t('bank:bank.account.number')}</div>
                    </Col>
                    <Col xs={6} sm={6} className='bankDetail-info'>{`${this.props.item.bankCode} - ${this.props.item.accountNumber}`}</Col>
                  </Col>
                  <Col xs={12} md={12} lg={12} className='d-flex justify-content-end button-group form-spacing'>
                    {!this.props.item.checked && (
                      <div className='w-100 d-flex'>
                        <Col xs={12} sm={!2}>
                          <button className='button-red button-submit border-0' type='button' onClick={this.toggle}>
                            {t('global:entity.action.delete')}
                          </button>
                        </Col>
                      </div>
                    )}
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        )}
      </Translation>
    );
  }
}
