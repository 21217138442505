import classnames from 'classnames';
import moment from 'moment';
import 'rc-datetime-picker/dist/picker.css';
import React, { Component, Fragment } from 'react';
import { Translation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Nav, NavLink, NavItem, TabContent, TabPane } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { mapStateToProps } from '../../../redux/selector/selector';
import SmallLoading from '../../../components/Loading/SmallLoading';
import QuickPay from '../../08Deposit/084_QuickPay';
import OnlineTransfer from '../../08Deposit/085_OnlineTransfer';
import BankApps from '../../08Deposit/086_BankApps';
import Crypto from '../../08Deposit/087_Crypto';
import QRPay from '../../08Deposit/088_QRPay';
import QRPayV2 from '../../08Deposit/088_QRPayV2';
import P2P from '../../08Deposit/089_P2P';
import BankQr from '../../08Deposit/bank-qr/bank-qr';
import EWalletApps from '../../08Deposit/088_QRPayV2';
import { bankIcon, bankAppIcon, ezzpay, h2pay, fpy, ip4u, cryptoIcon, yespay, qrpay, depositTabs } from '../161_MyAccount_Items_Data';
import depositMaterial from '../../08Deposit/deposit-method';

import commonUtil from 'root/utils/common-util';
import ImageHelper from 'root/pages/Share/image-helper';
import { trans_massageHideAddBank } from 'root/utils/transaction-util';
import { gu_getNestedValue } from 'root/utils/general-util';
import AuthSettings from '@components/molecules/auth-settings/auth-settings';
import { MODULE_TYPE as MT } from '@constants';

const IMG_ALT_STRING = 'Bank Transfer';

class Deposit extends Component {
    isMount = true;
    constructor(props) {
        super(props);
        let currentDate = new Date();
        let currentDateTime = moment(Date.now()).format('YYYY-MM-DD hh:mm a');
        this.toggle = this.toggle.bind(this);
        this.state = {
            activeTab: 'QP',
            activeTabActualCode: 'QP',
            depositDetails: null,
            selectedMethod: [],
            selectedBank: [],
            amount: '',
            amountErrMsg: [],
            disabledDepositButton: true,
            limitAmount: '',
            currency: '',
            depositOptions: [],
            bankList: [],
            selectedDepositChannel: [],
            receiptImg: [],
            depositDateString: moment(currentDateTime, 'YYYY-MM-DD hh:mm a').format('YYYY-MM-DDTHH:mm'),
            selectedDepositBank: null,
            radioValue: 0,
            status: '',
            moment: moment(),
            histories: [],
            start: new Date(new Date().setMonth(currentDate.getMonth() - 1)),
            end: currentDate,
            showLoading: false,
            bankAppsEnabled: false,
            // isFirstTimeInit is to pass to quickpay component to avoid deuplicate api call
            isFirstTimeInit: true,
            cryptoEnabled: false,

            //deposit new structure
            depositApiJson: null,
            filteredDepositApiJson: null,
            displayTab: [],
            displayTabData: [],
            defaultTab: null,
            customTranslationObj: {},

            compSettings: {},
            paymentGatewaySetting: null,

            paymentOption: [],
            paymentTranslation: [],
            selectedPaymentOption: 0,

            isLoading: true,
            isSubLoading: false,

            // bank offline
            showBankStatus: false,
            bankStatusModal: false,
            bankMaintenanceDisplay: [],
            depositAmountOptions: [],
            memberDecimalSettings: {},
        };
    }

    toggle(tab) {
        const { depositApiJson, displayTab } = this.state;
        let depositAllSettings = this.state.depositAllSettings;

        let _filteredDepositApiJson = null;
        let code = null;

        this.setState({ isSubLoading: true }, () => {
            if (depositApiJson) {
                code = displayTab.find((e) => e.actualCode === tab).code;
                if (code) {
                    _filteredDepositApiJson = { channel: gu_getNestedValue(depositApiJson, `${tab}.bank`) || depositApiJson[tab], optionCode: code };
                }
            }

            if (depositAllSettings) {
                depositAllSettings.hideAddBankAccount = trans_massageHideAddBank(this, 'deposit', code);
            }

            if (this.state.activeTabActualCode !== tab) {
                this.setState({ activeTab: code, activeTabActualCode: tab, filteredDepositApiJson: _filteredDepositApiJson, depositAllSettings, isSubLoading: false }, () => {
                    this.setState({
                        amount: '',
                        errMsgLimitAmount: [],
                        amountErrMsg: [],
                    });
                });

                // if (this.props.screen.viewType === 'web') {
                //   if (tab === 'quickPay') {
                //     this.props.history.push('/myaccount/deposit');
                //   } else if (tab === 'onlinetransfer') {
                //     this.props.history.push('/myaccount/deposit');
                //   }
                // }
            } else {
                this.setState({ isSubLoading: false });
            }
        });
    }

    // setDepositOptions() {
    //   const { t } = this.props;
    //   let depositOptions = [
    //     { label: t('settings:settings.dropdown.onlineBanking'), value: 'O' },
    //     { label: t('settings:settings.dropdown.cashDepositMachine'), value: 'D' },
    //     { label: t('settings:settings.dropdown.atmTransfer'), value: 'A' },
    //   ];
    //   this.setState({ depositOptions: depositOptions });
    // }

    handleMethodChange = (value) => {
        value = [value];
        this.setState({ selectedMethod: value, isFirstTimeInit: false }, () => {
            this.checkError();
        });
    };

    componentDidMount() {
        this.initPaymentGatewaySetting();
        this.initCompSettings();
        this.loadMerchantBank();
        this.loadDepositOptions();
        this.getMemberDepositWithdrawDecimalSettings();
        this.loadPaymentOptionCategory().then(() => {
            this.loadDepositDetails(); // 1st
            this.loadBankAppsEnabled(); // 2nd
            this.loadCryptoDeposit(); //3rd
        });

        commonUtil.loadCustomTranslation('Deposit', this);
        // this.setDepositOptions();
        // this.loadBankAccounts();
        // this.loadHistories();
        // this.loadBankAccountsForDeposit();

        if (this.props.screen.viewType === 'web' && this.props.location.pathname === '/deposit') {
            this.props.history.push('/myaccount/deposit');
        } else if (this.props.screen.viewType === 'web' && this.props.location.pathname === '/onlinetransfer') {
            this.props.history.push('/myaccount/deposit');
        } else if (this.props.screen.viewType === 'web' && this.props.location.pathname === '/quickpay') {
            this.props.history.push('/myaccount/deposit');
        } else if (this.props.screen.viewType === 'mobile' && this.props.location.pathname === '/myaccount/deposit') {
            // to able to back to home when switch from desktop deposit page to mobile deposit page
            this.props.history.push('/home');
            this.props.history.push('/fund');
            this.props.history.push('/deposit');
        }
    }

    componentWillUnmount() {
        this.isMount = false;
    }

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            // this.loadBankAccounts();
            this.getPathForActiveTab();
        }
    }

    loadDepositOptions() {
        window.SPL_Transaction.loadDepositOptions().then((data) => {
            this.setState({ depositAmountOptions: data });
        });
    }

    loadMerchantBank() {
        window.SPL_Transaction.loadMerchantBank(this.props).then((merchantBankList) => {
            this.setState({ merchantBankList: merchantBankList }, () => {
                this.getBankStatus();
            });
        });
    }

    initPaymentGatewaySetting() {
        window.SPL_JsonSettings.getPaymentGatewaySetting((paymentGatewaySetting) => {
            this.setState({ paymentGatewaySetting });
        });
    }

    initCompSettings = () => {
        return new Promise((resolve) => {
            // Step 1: Standard way to get from portal settings
            let _depositPagesSettings = commonUtil.getSettingFromPortalSettings({
                vm: this,
                settingName: 'depositPagesSettings',
                notViewType: true,
            });

            // Step 2: Massage again to get the specific data you need (Skip if not needed)

            let compSettings = {
                depositPagesSettings: _depositPagesSettings,
            };

            this.setState({ compSettings }, () => {
                window.SPL_Content.getBankNameJson(this.props);
                resolve();
            });
        });
    };

    getMemberDepositWithdrawDecimalSettings = () => {
        window.SPL_Member.getMemberDepositWithdrawDecimalSettings().then((data) => {
            if (data) {
                this.setState({ memberDecimalSettings: data?.deposit || {} });
            }
        });
    };

    getPathForActiveTab = () => {
        if (this.props.screen && this.props.screen.viewType && this.props.screen.viewType === 'web') {
            if (window.location.pathname.includes('onlinetransfer')) {
                this.toggle('B');
            } else if (window.location.pathname.includes('quickpay')) {
                this.toggle('QP');
            }
        }
    };

    loadDepositDetails = () => {
        this.setState({ isLoading: true }, () => {
            const { account } = this.props.user;
            const { depositPagesSettings } = this.state.compSettings;
            let currency = '';
            let activeTab = 'QP';

            if (account) {
                currency = account.currency;
            }

            if (depositPagesSettings && depositPagesSettings.enableJsonApi) {
                this.loadNewDepositLandingStructure();
            } else {
                if (Object.keys(depositMaterial.depositDetails).length > 0 && currency === depositMaterial.currentCurrency) {
                    if (!depositMaterial.getDepositDetails().defaultMethod) {
                        activeTab = 'B';
                    }

                    this.setState(
                        {
                            depositDetails: depositMaterial.getDepositDetails(),
                            selectedMethod: [depositMaterial.getDepositDetails().defaultMethod],
                            isLoading: false,
                            activeTab: activeTab,
                        },
                        () => {
                            this.validateDepositAmount();
                        }
                    );
                } else {
                    window.SPL_Transaction.loadDepositDetails(currency, this.state.activeTab, false).then((depositDetails) => {
                        if (this.isMount) {
                            if (!depositDetails.defaultMethod) {
                                activeTab = 'B';
                            }

                            depositDetails.banks = this.mapBankImg(depositDetails.banks);

                            if (depositDetails.qrPayList && depositDetails.qrPayList.length > 0) {
                                let qrpay = {
                                    name: 'transaction:transaction.deposit.cashdeposit.qrpay',
                                    methodKey: 'qrpay',
                                    isQRPay: true,
                                };
                                depositDetails.methodList.push(qrpay);
                            }

                            this.setState(
                                {
                                    depositDetails: depositDetails,
                                    selectedMethod: [depositDetails.defaultMethod],
                                    isLoading: false,
                                    activeTab: activeTab,
                                },
                                () => {
                                    this.validateDepositAmount();
                                    depositMaterial.setDepositDetails(depositDetails);
                                    depositMaterial.setCurrentCurrency(currency);
                                }
                            );
                        }
                    });
                }
            }
        });
    };

    loadNewDepositLandingStructure = () => {
        const { user } = this.props;
        let getMerchantBanksPromise = new Promise((resolve) => {
            window.SPL_Member.getMerchantBanks().then((depositData) => resolve(depositData));
        });
        let getDefaultTabPromise = this.loadDefaultTab();
        let loadDepositRelatedSettingsPromise = new Promise((resolve) => {
            window.SPL_Transaction.loadDepositRelatedSettings(user.account.currency).then((depositSettings) => resolve(depositSettings));
        });

        Promise.all([getMerchantBanksPromise, getDefaultTabPromise, loadDepositRelatedSettingsPromise]).then(async (result) => {
            const { depositPagesSettings } = this.state.compSettings;
            const { selectedPaymentOption, paymentOption } = this.state;
            let depositApiJson = result[0];
            let defaultTab = result[1];
            let depositAllSettings = result[2];
            let filterOption = [];

            let depositSettings = commonUtil.getSettingFromPortalSettings({
                vm: this,
                settingName: 'depositSettings',
            });

            let _displayTab = this.state.displayTab;
            let orderedTabFromApi = depositApiJson ? Object.keys(depositApiJson) : [];
            let newOrderDisplayTab = [];

            for (let i = 0; i < orderedTabFromApi.length; i++) {
                let code = orderedTabFromApi[i];
                if (gu_getNestedValue(depositApiJson, `${code}.type`)) {
                    code = depositApiJson[code]['type'];
                }

                let foundTab = window.SPL_Other.deepCopy(depositTabs.filter((tab) => tab.code === code)[0]);
                if (foundTab) {
                    foundTab.translation = window.SPL_Translation.getTranslatedTransactionMsg(foundTab.code, 'depositTabs');
                    foundTab.actualCode = orderedTabFromApi[i];
                    if (gu_getNestedValue(depositSettings, `customDepositOptions.${orderedTabFromApi[i]}.img`)) {
                        foundTab.icon = depositSettings.customDepositOptions[orderedTabFromApi[i]].img;
                    }
                    newOrderDisplayTab.push(foundTab);
                }
            }
            _displayTab = newOrderDisplayTab;

            for (const tab of _displayTab) {
                tab.svgData = await window.SPL_Other.renderTemplateSVG(`${commonUtil.getDepositImgPath()}${tab.actualCode}.svg`, `${tab.actualCode}`);
            }

            if (_displayTab && _displayTab.length > 0) {
                _displayTab[0].selected = true;
            }
            // arrange sequence if found setting
            _displayTab = this.customTabArrangement(_displayTab, depositPagesSettings);

            //catogorise deposit tab
            if (paymentOption && paymentOption.length > 0) {
                //to filter empty payment option
                filterOption = this.filteredPaymentDepositTab(paymentOption, _displayTab, selectedPaymentOption);
                _displayTab = filterOption;
            }

            let defaultTabObj = _displayTab.filter((tab) => {
                return tab.key === defaultTab;
            });

            let _activeTab = _displayTab && _displayTab[0] && _displayTab[0].code;
            if (defaultTabObj && defaultTabObj.length > 0) {
                _activeTab = (_displayTab || []).some((tab) => tab && tab.actualCode === defaultTabObj[0].actualCode) && defaultTabObj[0].code;
            }
            let filterDefaultTab = _displayTab && (_displayTab || []).find((tab) => tab.actualCode === _activeTab);
            let defaultTabCode = filterDefaultTab && filterDefaultTab.actualCode;
            let filteredDepositApiJson = {
                channel: gu_getNestedValue(depositApiJson, `${defaultTabCode}.bank`) || (depositApiJson && depositApiJson[defaultTabCode]),
                optionCode: filterDefaultTab && filterDefaultTab.code,
            };

            window.SPL_Content.getBankName(_displayTab, 'option');

            depositAllSettings.hideAddBankAccount = trans_massageHideAddBank(this, 'deposit', defaultTabCode);

            this.setState({
                depositApiJson: depositApiJson,
                filteredDepositApiJson: filteredDepositApiJson,
                depositAllSettings: depositAllSettings,
                activeTab: _activeTab,
                activeTabActualCode: defaultTabCode,
                displayTab: _displayTab,
                displayTabData: newOrderDisplayTab,
                defaultTab: defaultTab,
                isLoading: false,
            });
        });
    };

    customTabArrangement = (displayTab, depositSettings) => {
        if (depositSettings.depositTabOrdering && depositSettings.depositTabOrdering.length > 0) {
            let orderedMenuList = [];
            let nonOrderList = displayTab.filter(function (el) {
                return depositSettings.depositTabOrdering.indexOf(el.id) < 0;
            });

            for (let i = 0; i < depositSettings.depositTabOrdering.length; i++) {
                let menu = displayTab.filter(function (e) {
                    return e.id === depositSettings.depositTabOrdering[i];
                })[0];
                if (menu !== undefined && menu !== null) {
                    orderedMenuList.push(menu);
                }
            }

            if (depositSettings.swapDepositOrderTab) {
                displayTab = nonOrderList.concat(orderedMenuList);
            } else {
                displayTab = orderedMenuList.concat(nonOrderList);
            }
        }
        return displayTab;
    };

    loadDefaultTab = () => {
        const { user } = this.props;
        let currency = user.account.currency;
        return new Promise((resolve) => {
            window.SPL_JsonSettings.getDepositJson(function (settings) {
                if (settings && settings['defaultDepositMethod'] && settings['defaultDepositMethod'][currency] && settings['defaultDepositMethod'][currency] !== '') {
                    resolve(settings['defaultDepositMethod'][currency].toLowerCase());
                } else {
                    resolve();
                }
            });
        });
    };

    mapBankImg = (banks) => {
        let currency = null;

        if (this.props.user && this.props.user.account) {
            currency = this.props.user.account.currency;
        }

        if (currency && banks && banks.length > 0) {
            for (let i = 0; i < banks.length; i++) {
                const bank = banks[i];
                bank.imgOn = window.SPL_Content.mapBankHoverImg(bank.code, currency, true);
                bank.imgOff = window.SPL_Content.mapBankHoverImg(bank.code, currency, false);
            }
        }

        return banks;
    };

    loadBankAccounts = (callback) => {
        window.SPL_Transaction.loadAllOwnedAndUnOwnedBank().then((data) => {
            let memberBankList = data.memberBankList;
            for (let i = 0; i < memberBankList.length; i++) {
                const memberBank = memberBankList[i];
                memberBank.displayName = `${memberBank.bankName} ${memberBank.accountNumber} ${memberBank.accountHolder}`;
            }
            if (this.isMount) {
                this.setState(
                    {
                        bankList: data.bankList,
                        memberBankList: memberBankList,
                        unOwnedBankList: data.unOwnedBankList,
                    },
                    () => {
                        if (callback) {
                            callback();
                        }
                    }
                );
            }
        });
    };

    checkError = () => {
        const { activeTab, amountErrMsg, selectedMethod, selectedBank } = this.state;
        if (activeTab === 'QP') {
            if (amountErrMsg.length > 0 || selectedMethod.length <= 0 || selectedBank.length <= 0) {
                this.setState({ disabledDepositButton: true });
            } else {
                this.setState({ disabledDepositButton: false });
            }
        } else {
            const { amount, depositDateString, selectedDepositChannel, selectedDepositBank, receiptImg, selectedBank } = this.state;

            if (
                !amount ||
                selectedDepositChannel.length <= 0 ||
                (selectedBank.length <= 0 && selectedDepositChannel[0].value !== 'D') ||
                !selectedDepositBank ||
                (receiptImg.length <= 0 && selectedDepositChannel[0].value === 'O') ||
                (receiptImg.length <= 0 && selectedDepositChannel[0].value === 'D') ||
                (receiptImg.length <= 0 && selectedDepositChannel[0].value === 'A') ||
                !depositDateString
            ) {
                this.setState({ disabledDepositButton: true });
            } else {
                this.setState({ disabledDepositButton: false });
            }
        }
    };

    validateDepositAmount = () => {
        window.SPL_Transaction.validateDepositAmount(this.state.amount, this.state.depositDetails.depositLimit).then((errMsg) => {
            if (this.isMount) {
                let errMsgLimitAmount = this.getLimitAmountForErrMsg(errMsg[0]);
                this.setState(
                    {
                        amountErrMsg: errMsg,
                        limitAmount: errMsgLimitAmount.limitAmount,
                        currency: errMsgLimitAmount.currency,
                    },
                    () => {
                        this.checkError();
                    }
                );
            }
        });
    };

    getLimitAmountForErrMsg(errMsg) {
        let limitAmount = '';
        let currency = this.props.user.account.currency;
        if (errMsg) {
            if (errMsg.includes('invalidMinAmount')) {
                if (this.state.activeTab === 'QP') {
                    limitAmount = window.SPL_Other.formatAmount(this.state.depositDetails.depositLimit.minLimitQuick);
                } else {
                    limitAmount = window.SPL_Other.formatAmount(this.state.depositDetails.depositLimit.minLimit);
                }
            } else if (errMsg.includes('invalidMaxAmount')) {
                if (this.state.activeTab === 'QP') {
                    limitAmount = window.SPL_Other.formatAmount(this.state.depositDetails.depositLimit.maxLimitQuick);
                } else {
                    limitAmount = window.SPL_Other.formatAmount(this.state.depositDetails.depositLimit.maxLimit);
                }
            }
        }

        return {
            limitAmount: limitAmount,
            currency: currency,
        };
    }

    //payment getway = pg
    filteredPaymentDepositTab(option, displayTab, index) {
        if (!option[index] || !displayTab) {
            return displayTab; // Return displayTab if option[index] or displayTab is undefined
        }

        const paymentOption = option[index];
        if (!paymentOption.paymentOption) {
            return displayTab; // Return displayTab if paymentOption.paymentOption is undefined
        }

        const filterAllOptionCode = option
            .filter((d) => d && d.paymentOption)
            .map((d) => Object.keys(d.paymentOption))
            .flat();

        const filteredOption = displayTab.filter((item2) => filterAllOptionCode.includes(item2.actualCode));

        const filterSelectedOption = filteredOption.filter((filteredItem) => Object.keys(paymentOption.paymentOption).includes(filteredItem.actualCode));

        return filterSelectedOption.length ? filterSelectedOption : displayTab;
    }

    loadBankAppsEnabled = () => {
        const { currency } = this.props.user.account;
        window.SPL_Transaction.loadBankAppsEnabled(currency).then((bankAppsRes) => {
            this.setState({ bankAppsEnabled: bankAppsRes.bankAppsEnabled });
        });
    };

    loadCryptoDeposit = () => {
        if (this.props.portal.settings.enableCryptoTab) {
            let params = {
                purpose: 'D',
                option: 'CT',
            };

            window.SPL_Transaction.getMerchantBankGroupWithOption(params).then((data) => {
                if (data && data.channelOptions && data.channelOptions.length > 0) {
                    this.setState({ cryptoEnabled: true });
                }
            });
        }
    };

    renderMethod(methodName) {
        const { selectedMethod, activeTab } = this.state;
        const method = selectedMethod[0];

        let activeClassDeterminer;
        if (method.isQRPay) {
            activeClassDeterminer = method.methodKey === methodName && !['B', 'BA', 'CT'].includes(activeTab);
        } else {
            activeClassDeterminer = method.name === methodName && !['B', 'BA', 'CT'].includes(activeTab);
        }
        const activeClass = activeClassDeterminer ? 'active' : '';

        switch (methodName.toUpperCase()) {
            case 'FASTPAY':
            case 'FASTPAY2':
                return <img src={fpy} alt={IMG_ALT_STRING} className={`fpy ${activeClass}`} />;
            case 'EEZIEPAY':
                return <img src={ezzpay} alt={IMG_ALT_STRING} className={`ezpay ${activeClass}`} />;
            case 'HELP2PAY':
                return <img src={h2pay} alt={IMG_ALT_STRING} className={`h2pay ${activeClass}`} />;
            case 'IP4U':
                return <img src={ip4u} alt={IMG_ALT_STRING} className={`ip4u ${activeClass}`} />;
            case 'YESPAY':
                return <img src={yespay} alt={IMG_ALT_STRING} className={`yespay ${activeClass}`} />;
            case 'QRPAY':
                return <img src={qrpay} alt={IMG_ALT_STRING} className={`qrpay ${activeClass}`} />;
            default:
                return null;
        }
    }
    loadPaymentOptionCategory = () => {
        return new Promise((resolve) => {
            window.SPL_Content.loadPaymentOptionCategory(this.props).then((paymentCategory) => {
                this.setState({ paymentOption: paymentCategory }, () => {
                    resolve();
                });
            });
        });
    };

    paymentOptionSelection(index) {
        const { paymentOption, defaultTab, depositApiJson } = this.state;
        let { displayTabData, depositAllSettings } = this.state;
        displayTabData = this.filteredPaymentDepositTab(paymentOption, displayTabData, index);

        let _activeTab = displayTabData.some((tab) => tab.key === defaultTab) ? displayTabData.filter((tab) => tab.key === defaultTab)[0] : displayTabData[0];
        let defaultTabCode = _activeTab && _activeTab.actualCode;
        let filteredDepositApiJson = {
            channel: gu_getNestedValue(depositApiJson, `${defaultTabCode}.bank`) || depositApiJson[defaultTabCode],
            optionCode: _activeTab && _activeTab.code,
        };
        window.SPL_Content.getBankName(displayTabData, 'option');

        if (depositAllSettings) {
            depositAllSettings.hideAddBankAccount = trans_massageHideAddBank(this, 'deposit', _activeTab.code);
        }

        this.setState({
            displayTab: displayTabData,
            filteredDepositApiJson: filteredDepositApiJson,
            activeTab: _activeTab.code,
            activeTabActualCode: defaultTabCode,
            selectedPaymentOption: index,
            depositAllSettings,
        });
    }

    // bank offline
    getBankStatus() {
        const { merchantBankList } = this.state;
        let currency = this.props.user.account.currency;

        window.SPL_Transaction.processBankMaintenanceDisplay(merchantBankList, currency).then(async (data) => {
            let info = data;
            let foundFlag = false;
            let showBankStatus = false;

            for (let i = 0; i < info.length; i++) {
                if (info[i].displayMaintenances.length > 0) {
                    for (let j = 0; j < info[i].displayMaintenances.length; j++) {
                        if (info[i].displayMaintenances[j].prefix === 'DAILY') {
                            info[i].displayMaintenances[j].prefix = 'transaction:transaction.deposit.bankMaintenance.daily';
                        } else if (info[i].displayMaintenances[j].prefix === 'WEEKDAY') {
                            info[i].displayMaintenances[j].prefix = 'transaction:transaction.deposit.bankMaintenance.weekday';
                        } else if (info[i].displayMaintenances[j].prefix === 'WEEKEND') {
                            info[i].displayMaintenances[j].prefix = 'transaction:transaction.deposit.bankMaintenance.weekend';
                        } else if (info[i].displayMaintenances[j].prefix === 'SAT') {
                            info[i].displayMaintenances[j].prefix = 'transaction:transaction.deposit.bankMaintenance.sat';
                        } else if (info[i].displayMaintenances[j].prefix === 'SUN') {
                            info[i].displayMaintenances[j].prefix = 'transaction:transaction.deposit.bankMaintenance.sun';
                        } else if (info[i].displayMaintenances[j].prefix === 'EMERGENCY') {
                            info[i].displayMaintenances[j].prefix = 'transaction:transaction.deposit.bankMaintenance.emergency';
                        }
                    }
                    showBankStatus = true;
                }

                if (!foundFlag && info[i].displayMaintenances.length > 0) {
                    this.setState({ hasMaintenanceWithPrefix: true });
                }
            }

            this.setState({ showBankStatus: showBankStatus, bankMaintenanceDisplay: info });
        });
    }

    toggleBankStatus() {
        let bankStatusModal = this.state.bankStatusModal;

        if (bankStatusModal) {
            this.setState({
                bankStatusModal: false,
            });
        } else {
            this.setState({
                bankStatusModal: true,
            });
        }
    }

    renderBankMaintenanceContent() {
        const { user, t } = this.props;
        const { bankMaintenanceDisplay } = this.state;

        return (bankMaintenanceDisplay || []).map(
            (bank, i) =>
                bank.displayMaintenances &&
                bank.displayMaintenances.length > 0 && (
                    <div className='bank-container' key={i}>
                        <div className='img-container'>
                            <ImageHelper displayImg={`/public/images/bankImg/${user.account.currency}/${bank.bankCode}.png`} alt={`${bank.bankCode}.png`} />
                        </div>
                        <div className='bank-detail'>
                            <p className='title'>{` ${bank.name} `}</p>
                            {bank.displayMaintenances.map((status, j) => (
                                <div className='content' key={j}>
                                    <p>{t(status.prefix)}</p>
                                    <p>{status.interval}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                )
        );
    }

    render() {
        const {
            depositDetails,
            bankAppsEnabled,
            cryptoEnabled,
            selectedMethod,
            displayTab,
            activeTab,
            activeTabActualCode,
            customTranslationObj,
            filteredDepositApiJson,
            depositAllSettings,
            paymentGatewaySetting,
            selectedPaymentOption,
            paymentOption,
        } = this.state;
        const { depositPagesSettings } = this.state.compSettings;
        let depositValue = {
            depositDetails: depositDetails,
            selectedMethod: selectedMethod,
        };

        let depositDataProps = {
            filteredDepositApiJson: this.state.filteredDepositApiJson,
            depositAllSettings: depositAllSettings,
            paymentGatewaySetting,
            merchantBankList: this.state.merchantBankList,
            depositAmountOptions: this.state.depositAmountOptions,
            memberDecimalSettings: this.state.memberDecimalSettings,
            parentvm: this,
        };

        return (
            this.props.user.account && (
                <Translation>
                    {(t) => (
                        <div className='deposit'>
                            <AuthSettings module={MT?.BANK_DETAIL_BANK} isPublic={false} />

                            <div className='deposit-payment-option'>
                                <div className='payment-option-container'>
                                    {paymentOption &&
                                        paymentOption.map((option, i) => (
                                            <div
                                                key={i}
                                                className={`payment-option ${i === selectedPaymentOption ? 'active' : ''}`}
                                                onClick={() => this.paymentOptionSelection(i)}
                                            >
                                                {option &&
                                                    option.translation &&
                                                    option.translation.length > 0 &&
                                                    option.translation.map((trans, i) => (
                                                        <Fragment key={i}>
                                                            {trans ? (
                                                                trans
                                                            ) : (
                                                                <ImageHelper
                                                                    displaySVG={'deposit-time-icon'}
                                                                    className='deposit-time-icon'
                                                                    svgType='SVGElements'
                                                                    isSVG={true}
                                                                />
                                                            )}
                                                        </Fragment>
                                                    ))}
                                            </div>
                                        ))}
                                </div>

                                {/* bank offline */}
                                {displayTab.find((e) => this.props.portal.settings.features.showBankOfflineList.includes(e.code) && activeTab === e.code) && (
                                    <div className='bank-offline-container'>
                                        <div className={`bankoff-btn ${this.state.bankStatusModal ? 'active' : ''}`} onClick={() => this.toggleBankStatus()}>
                                            <div className='img-container'>
                                                <img src='/public/html/images/account/096-bank.png' alt='bank-offline-img' />
                                                {this.state.showBankStatus && <div className='red-dot'>!</div>}
                                            </div>
                                            <span>{t('transaction:transaction.deposit.bankOfflineStatus')}</span>
                                        </div>

                                        {this.state.bankStatusModal && this.state.hasMaintenanceWithPrefix && (
                                            <div className='floating-bank-offline-container'>{this.renderBankMaintenanceContent()}</div>
                                        )}
                                    </div>
                                )}
                            </div>
                            <h1>{t('global:global.menu.account.deposit', 'Deposit')}</h1>

                            <ul>
                                <li>
                                    <div className='item'>
                                        <p className='deposit-options'>
                                            {t('settings:settings.tabs.heading.depositOptions', 'Deposit Options')} <span className='text-danger asterisk'>*</span>
                                        </p>
                                        <div className='bank-selector'>
                                            <Nav tabs className='deposit-tab'>
                                                {depositDetails && depositDetails.methodList && (
                                                    <div className='display-flex'>
                                                        {depositDetails.methodList.map((method, index) => {
                                                            return (
                                                                <NavItem
                                                                    key={index}
                                                                    className='deposit-tab-click'
                                                                    onClick={() => {
                                                                        if (method.isQRPay) {
                                                                            let getActualCode = displayTab.filter((tab) => tab.key === method.methodKey)[0].actualCode;
                                                                            this.toggle(getActualCode);
                                                                        } else {
                                                                            this.toggle('QP');
                                                                        }
                                                                        this.handleMethodChange(method);
                                                                    }}
                                                                >
                                                                    <NavLink
                                                                        className={classnames({
                                                                            active:
                                                                                this.state.selectedMethod[0].name === method.name &&
                                                                                !['B', 'BA', 'CT'].includes(this.state.activeTab),
                                                                            navlinkForm: true,
                                                                            depositLink: true,
                                                                        })}
                                                                    >
                                                                        <div className='deposit-banktransfer'>
                                                                            {this.renderMethod(method.isQRPay ? method.methodKey : method.name)}
                                                                        </div>
                                                                        <div className='deposit-options-text'>{t(method.name)}</div>
                                                                    </NavLink>
                                                                </NavItem>
                                                            );
                                                        })}
                                                    </div>
                                                )}

                                                {depositPagesSettings && depositPagesSettings.enableJsonApi ? (
                                                    <>
                                                        {displayTab.map((tab, i) => (
                                                            <NavItem
                                                                key={i}
                                                                className='deposit-tab-click'
                                                                onClick={() => {
                                                                    this.toggle(tab.actualCode);
                                                                    this.loadBankAccounts();
                                                                }}
                                                            >
                                                                <NavLink
                                                                    className={classnames({
                                                                        active: activeTab === tab.code && activeTabActualCode === tab.actualCode,
                                                                        navlinkForm: true,
                                                                        depositLink: true,
                                                                    })}
                                                                >
                                                                    <div className='deposit-banktransfer'>
                                                                        {commonUtil.getIsFormattedImageSetting(this) && tab.svgData ? (
                                                                            <div
                                                                                className={`${
                                                                                    activeTab === tab.code && activeTabActualCode === tab.actualCode ? 'active' : ''
                                                                                }`}
                                                                                dangerouslySetInnerHTML={{
                                                                                    __html: tab.svgData,
                                                                                }}
                                                                            ></div>
                                                                        ) : (
                                                                            <Fragment>
                                                                                <img
                                                                                    src={tab.icon}
                                                                                    alt={tab.key}
                                                                                    className={`${
                                                                                        activeTab === tab.code && activeTabActualCode === tab.actualCode ? 'active' : ''
                                                                                    }`}
                                                                                />
                                                                                {tab.code === 'B' && <div>Bank</div>}
                                                                            </Fragment>
                                                                        )}
                                                                    </div>
                                                                    <div className='deposit-options-text'>
                                                                        {customTranslationObj && customTranslationObj[tab.code]
                                                                            ? customTranslationObj[tab.code]
                                                                            : tab.translation}
                                                                    </div>
                                                                </NavLink>
                                                            </NavItem>
                                                        ))}
                                                    </>
                                                ) : (
                                                    <>
                                                        <NavItem
                                                            className='deposit-tab-click'
                                                            onClick={() => {
                                                                this.toggle('B');
                                                                this.loadBankAccounts();
                                                            }}
                                                        >
                                                            <NavLink
                                                                className={classnames({
                                                                    active: this.state.activeTab === 'B',
                                                                    navlinkForm: true,
                                                                    depositLink: true,
                                                                })}
                                                            >
                                                                <div className='deposit-banktransfer'>
                                                                    <img src={bankIcon} alt='Bank Transfer' className={`${this.state.activeTab === 'B' ? 'active' : ''}`} />
                                                                    <div>Bank</div>
                                                                </div>
                                                                <div className='deposit-options-text'>{t('global:global.menu.account.bankTransfer', 'Bank Transfer')}</div>
                                                            </NavLink>
                                                        </NavItem>

                                                        {/* bank apps */}
                                                        {bankAppsEnabled && (
                                                            <NavItem
                                                                className='deposit-tab-click'
                                                                onClick={() => {
                                                                    this.toggle('BA');
                                                                    this.loadBankAccounts();
                                                                }}
                                                            >
                                                                <NavLink
                                                                    className={classnames({
                                                                        active: this.state.activeTab === 'BA',
                                                                        navlinkForm: true,
                                                                        depositLink: true,
                                                                    })}
                                                                >
                                                                    <div className='deposit-banktransfer'>
                                                                        <img
                                                                            src={bankAppIcon}
                                                                            alt='Bank Apps'
                                                                            className={`bankapp ${this.state.activeTab === 'BA' ? 'active' : ''}`}
                                                                        />
                                                                    </div>
                                                                    <div className='deposit-options-text'>Paynow</div>
                                                                </NavLink>
                                                            </NavItem>
                                                        )}

                                                        {/* crypto */}
                                                        {cryptoEnabled && (
                                                            <NavItem
                                                                className='deposit-tab-click'
                                                                onClick={() => {
                                                                    this.toggle('CT');
                                                                    this.loadBankAccounts();
                                                                }}
                                                            >
                                                                <NavLink
                                                                    className={classnames({
                                                                        active: this.state.activeTab === 'CT',
                                                                        navlinkForm: true,
                                                                        depositLink: true,
                                                                    })}
                                                                >
                                                                    <div className='deposit-banktransfer'>
                                                                        <img
                                                                            src={cryptoIcon}
                                                                            alt='Bank Apps'
                                                                            className={`crypto ${this.state.activeTab === 'CT' ? 'active' : ''}`}
                                                                        />
                                                                    </div>
                                                                    <div className='deposit-options-text'>Crypto</div>
                                                                </NavLink>
                                                            </NavItem>
                                                        )}
                                                    </>
                                                )}
                                            </Nav>
                                        </div>
                                    </div>
                                </li>
                            </ul>

                            <TabContent activeTab={this.state.activeTab} className='deposit-tab-content'>
                                {this.state.isLoading && <SmallLoading></SmallLoading>}

                                {this.state.isSubLoading ? (
                                    <SmallLoading />
                                ) : (
                                    <Fragment>
                                        {this.state.activeTab === 'QP' && (depositDetails || filteredDepositApiJson) && (
                                            <TabPane tabId='quickPay' className='d-flex form-content'>
                                                {(depositDetails || filteredDepositApiJson) && (
                                                    <QuickPay {...depositValue} isFirstTimeInit={this.state.isFirstTimeInit} {...depositDataProps} />
                                                )}
                                                {depositDetails && !depositDetails.defaultMethod && <div className='coming-soon'>Coming Soon...</div>}
                                            </TabPane>
                                        )}

                                        {/* Online Trans */}
                                        {this.state.activeTab === 'B' && (depositDetails || filteredDepositApiJson) && (
                                            <TabPane tabId='onlinetransfer' className='d-flex form-content'>
                                                {this.props.screen.viewType === 'web' && <OnlineTransfer {...depositDataProps} />}
                                            </TabPane>
                                        )}

                                        {/* Bank Apps */}
                                        {this.state.activeTab === 'BA' && (depositDetails || filteredDepositApiJson) && (
                                            <TabPane tabId='bankApps' className='d-flex form-content'>
                                                {this.props.screen.viewType === 'web' && <BankApps {...depositDataProps} />}
                                            </TabPane>
                                        )}

                                        {/* Crypto */}
                                        {this.state.activeTab === 'CT' && (depositDetails || filteredDepositApiJson) && (
                                            <TabPane tabId='crypto' className='d-flex form-content'>
                                                {this.props.screen.viewType === 'web' && <Crypto type='deposit' {...depositDataProps} />}
                                            </TabPane>
                                        )}

                                        {/* QRPay */}
                                        {this.state.activeTab === 'QR' && (depositDetails || filteredDepositApiJson) && (
                                            <TabPane tabId='qrpay' className='d-flex form-content'>
                                                {depositAllSettings && depositAllSettings.enableJsonApi ? (
                                                    <QRPayV2 {...depositDataProps} />
                                                ) : (
                                                    <>
                                                        <QRPay
                                                            {...depositValue}
                                                            isFirstTimeInit={this.state.isFirstTimeInit}
                                                            qrPayList={depositDetails && depositDetails.qrPayList}
                                                        />
                                                        {depositDetails && !depositDetails.defaultMethod && <div className='coming-soon'>Coming Soon...</div>}
                                                    </>
                                                )}
                                            </TabPane>
                                        )}

                                        {/* p2p */}
                                        {this.state.activeTab === 'P2P' && (depositDetails || filteredDepositApiJson) && (
                                            <TabPane tabId='p2p' className='d-flex form-content'>
                                                {this.props.screen.viewType === 'web' && <P2P type='deposit' {...depositDataProps} />}
                                            </TabPane>
                                        )}

                                        {this.state.activeTab === 'BQR' && (depositDetails || filteredDepositApiJson) && (
                                            <TabPane tabId='bqr' className='d-flex form content'>
                                                <BankQr type='deposit' {...depositDataProps} />
                                            </TabPane>
                                        )}
                                    </Fragment>
                                )}

                                {this.state.activeTab === 'EA' && (depositDetails || filteredDepositApiJson) && (
                                    <TabPane tabId='ewalletapps' className='d-flex form-content'>
                                        <EWalletApps {...depositDataProps} />
                                    </TabPane>
                                )}
                            </TabContent>
                        </div>
                    )}
                </Translation>
            )
        );
    }
}

export default connect(mapStateToProps)(withTranslation(['transaction', 'settings', 'bank'])(withRouter(Deposit)));
