import { productBannerConstants } from '../constant/';

const initialState = {
    productBannerByProductPage: null,
};

export function productBannerReducer(state = initialState, action) {
    switch (action.type) {
        case productBannerConstants.GET_PRODUCT_BANNER_BY_PRODUCT_PAGE:
            return {
                ...state,
                productBannerByProductPage: action.payload,
            };
        default:
            return state;
    }
}
