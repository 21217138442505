import React, { Suspense, useEffect } from 'react';
import { Translation, withTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Loading from '../../components/Loading/Loading';
import LazyComponent from '../../components/LazyComponent';
import { portalAction, userAction, layoutAction, systemFeatureAction } from '../../redux/action';
import { languageAction } from '../../redux/action/language.action';
import { mapStateToProps } from '../../redux/selector/selector';
import commonUtil from '../../utils/common-util';
import { POPUP_TYPE, MODULE_TYPE as MT } from '@constants';

// import GoogleAuthPopup from '@components/organisms/google-auth-popup/google-auth-popup';
// import EnterPinPopup from '@components/organisms/enter-pin-popup/enter-pin-popup';
// import EditVerifyAuthPopup from '@components/organisms/edit-verify-auth-popup/edit-verify-auth-popup';
// import SetupPinPopup from '@components/organisms/set-up-pin-popup/set-up-pin-popup'; // lazy import bu dao due to, password expect inside <form tag>, need time change and test
const GoogleAuthPopup = React.lazy(() => import('@components/organisms/google-auth-popup/google-auth-popup'));
const EnterPinPopup = React.lazy(() => import('@components/organisms/enter-pin-popup/enter-pin-popup'));
const EditVerifyAuthPopup = React.lazy(() => import('@components/organisms/edit-verify-auth-popup/edit-verify-auth-popup'));
const SetupPinPopup = React.lazy(() => import('@components/organisms/set-up-pin-popup/set-up-pin-popup'));

class App extends React.Component {
    langPath = '';
    interval;
    constructor(props) {
        super();

        this.state = {
            showMenu: true,
            initialize: false,
            showSidebar: false,
            sideBarSubMenuShow: false,
            isLoading: false,
            languageLoaded: false,
        };
    }

    componentDidMount() {
        this.interval = this.splLibrary();
        window.addEventListener('scroll', this.resizeFooterOnScroll);
        this.updateDomainURL();
    }

    // Lanugage to set in url
    getLanguage = () => {
        let path = this.props.location.pathname;
        let languageList = window.languageList;
        let pathArray = path.split('/');
        let countryLanguageKey = '';
        let urlPath = '';
        let localStorageLanguage = window.localStorage.getItem('LANGUAGE_CURRENCY');

        if (pathArray[1].includes('-')) {
            urlPath = pathArray[1];
            countryLanguageKey = pathArray[1].toUpperCase().replace('-', '_');

            if (!languageList.includes(urlPath)) {
                if (localStorageLanguage) {
                    countryLanguageKey = localStorageLanguage;
                    urlPath = this.convertToUrlFormat(countryLanguageKey);
                } else {
                    countryLanguageKey = window.countryLanguageKey;
                    urlPath = this.convertToUrlFormat(countryLanguageKey);
                }
            }
        } else if (localStorageLanguage) {
            countryLanguageKey = localStorageLanguage;
            urlPath = this.convertToUrlFormat(countryLanguageKey);
        } else {
            countryLanguageKey = window.countryLanguageKey;
            urlPath = this.convertToUrlFormat(countryLanguageKey);
            urlPath = '/';
        }
        // check if url path is in the list
        if (!(window.languageList && window.languageList.length > 0 && window.languageList.filter((a) => a === urlPath).length > 0)) {
            countryLanguageKey = window.countryLanguageKey;
            urlPath = this.convertToUrlFormat(countryLanguageKey);
        }
        this.langPath = urlPath;
        this.setLanguageKeyAndCountryLanguage(countryLanguageKey);
    };

    initializePortalSettings = () => {
        window.SPL_Content.getPortalSettings().then((json) => {
            this.props.dispatch(portalAction.setSettings(json));
        });
    };

    initializeLayoutJson = () => {
        window.SPL_Content.getLayoutJson().then((json) => {
            this.props.dispatch(layoutAction.setLayout(json));
        });
    };

    setLanguageKeyAndCountryLanguage = (countryLanguageKey) => {
        let key = countryLanguageKey.split('_')[0].toLowerCase();
        if (key === 'zh') key = 'zh-CN';

        window.SPL_Translation.initCurrentLanguage(key);
        this.props.i18n.changeLanguage(key);
        this.props.dispatch(languageAction.changeLanguage({ key, countryLanguageKey }));
        let vm = this;
        setTimeout(() => {
            vm.setState({
                languageLoaded: true,
            });
        }, 500);
    };

    // convert format from 'EN_MY' to en-my
    convertToUrlFormat = (value) => {
        return value.toLowerCase().replace('_', '-');
    };

    splLibrary = () => {
        return setInterval(() => {
            this.isIdentity();
            this.getCaptchaSetting();
        }, 100);
    };

    isIdentity = () => {
        if (
            window.SPL_Auth &&
            window.SPL_Content &&
            window.SPL_Member &&
            window.SPL_Transaction &&
            window.SPL_Other &&
            window.SPL_Referral &&
            window.SPL_Reward &&
            window.SPL_Translation
        ) {
            clearInterval(this.interval);
            this.initializePortalSettings();
            this.initializeLayoutJson();

            if (window.SPL_Auth.isAuthenticated()) {
                this.props.dispatch(userAction.isAuthenticated()).then(() => {
                    this._getLoggedInLanguage();
                    this.setState({ initialize: true });
                });
            } else {
                this.getLanguage();
                this.setState({ initialize: true });
            }

            window.SPL_Handler.validateAffiliateParameters();

            let mobile = false;
            if (this.props.screen.viewType === 'mobile') {
                mobile = true;
            } else {
                mobile = false;
            }

            setTimeout(() => {
                let liveChat = {
                    languageCurrency: this.props.language.countryLanguageKey,
                    isAffiliate: false,
                    isMobile: mobile,
                    isLogin: this.props.user.isLogin,
                };

                window.SPL_Other.appendLivechatScriptBody(liveChat).then((data) => {
                    console.log('liveChat', data);
                });

                let comm100Params = {
                    isLogin: this.props.user.isLogin,
                    login: this.props.user.account && this.props.user.account.login ? this.props.user.account.login : '',
                    groupType: this.props.user.account && this.props.user.account.membershipLevel ? this.props.user.account.membershipLevel : '',
                    groupName: this.props.user.account && this.props.user.account.groupName ? this.props.user.account.groupName : '',
                    isAgent: window.isAgent,
                    isAffiliate: window.isAffiliate,
                    merchantName: window.globMerchantName,
                };
                window.SPL_Other.initComm100(comm100Params);
            }, 3000);
        }
    };

    handleSideBar = (item) => {
        if (item && item.name && item.name.toLowerCase() === 'logout') {
            this.props.dispatch(userAction.logout());
            setTimeout(() => {
                document.location.reload();
            }, 500);
        }
        this.setState({ showSidebar: !this.state.showSidebar, sideBarSubMenuShow: false }, () => {
            let body = document.getElementsByTagName('body')[0];
            if (this.state.showSidebar) {
                body.style.overflow = 'hidden';
            } else {
                body.style.overflow = 'auto';
            }
        });
    };

    getCaptchaSetting = () => {
        if (window.SPL_Content) {
            window.SPL_Content.getCaptchaSetting().then((data) => {
                if (data && data.publicKey) {
                    injectGoogleRecaptchaScript(data.publicKey);
                }
            });
        }

        function injectGoogleRecaptchaScript(publicKey) {
            let googleScriptLoaded = document.getElementById('googleScriptID');
            if (!googleScriptLoaded) {
                let googleScript = document.createElement('script');
                googleScript.id = 'googleScriptID';
                googleScript.defer = true;
                googleScript.src = 'https://www.recaptcha.net/recaptcha/api.js?render=' + publicKey;
                document.head.appendChild(googleScript);
            }
        }
    };

    LazyComponent = (Component) => {
        return (props) => <Suspense fallback={<Loading></Loading>}>{<Component langPath={this.langPath} {...props} />}</Suspense>;
    };

    showSideBarSubMenuMenu = (bool) => {
        this.setState({ sideBarSubMenuShow: bool });
    };

    _getLoggedInLanguage() {
        window.SPL_Content.getLanguageList().then((data) => {
            const { user, /*location,*/ language } = this.props;

            for (let i = 0, l = data.length; i < l; i++) {
                const currentLanguage = data[i];
                if (currentLanguage.currency === user.account.currency) {
                    let countryLanguageKey = currentLanguage.languages[0].countryLanguageKey;
                    currentLanguage.languages.forEach((e) => {
                        if (e.countryLanguageKey === language.countryLanguageKey) countryLanguageKey = e.countryLanguageKey;
                    });
                    let urlPath = this.convertToUrlFormat(countryLanguageKey);

                    // const pathArray = location.pathname.split('/');
                    // if (pathArray[1].includes('-')) urlPath = pathArray[1];

                    // if (urlPath) {
                    //     const matchingUrlPath = urlPath.replace('-', '_').toUpperCase();
                    //     currentLanguage.languages.forEach((e) => {
                    //         if (e.countryLanguageKey === matchingUrlPath) countryLanguageKey = e.countryLanguageKey;
                    //     });
                    // }

                    this.langPath = urlPath;
                    this.setLanguageKeyAndCountryLanguage(countryLanguageKey);
                    break;
                }
            }
        });
    }

    updateDomainURL = () => {
        const { currencyLang, LANGUAGE_CURRENCY } = window.localStorage;
        if (currencyLang !== 'MYR') return;

        const { pathname } = window.location;
        const countryLanguageKey = LANGUAGE_CURRENCY ? LANGUAGE_CURRENCY.replace('_', '-').toLowerCase() : 'en-my';

        const pathMapping = {
            '/scr888': '918kiss',
            '/ultimate-slots': 'slots',
            '/gggaming': 'fishing',
            'games/KM2': 'home',
        };

        Object.entries(pathMapping).forEach(([key, value]) => {
            if (pathname.includes(key)) {
                this.props.history.push(`/${countryLanguageKey}/${value}`);
            }
        });
    };

    render() {
        const { popupReducer } = this.props;

        const { showSidebar, sideBarSubMenuShow, initialize, languageLoaded } = this.state;

        let MerchantLayout = this.props.component;
        if (this.langPath === '' || languageLoaded === false) {
            return <Loading></Loading>;
        } else {
            return (
                initialize && (
                    <Translation>
                        {(t) => (
                            <div className='screen'>
                                <AppComponent />

                                <PopupComponents />
                                <MerchantLayout
                                    showSideBarSubMenuMenu={this.showSideBarSubMenuMenu}
                                    handleSideBar={this.handleSideBar}
                                    showSidebar={showSidebar}
                                    sideBarSubMenuShow={sideBarSubMenuShow}
                                    initialize={initialize}
                                    langPath={this.langPath}
                                    onClickFunc={(e) => commonUtil.dropdownUpdate(e, false, this.props)}
                                />
                                {window.SPL_Other.initViewPort()}
                                {(window.initApp = true)}
                            </div>
                        )}
                    </Translation>
                )
            );
        }
    }
}

export default connect(mapStateToProps, null)(withTranslation(['home', 'global', 'transaction'])(withRouter((props) => <App {...props} />)));

export const AppComponent = () => {
    const dispatch = useDispatch();
    const language = useSelector((state) => state.language);

    useEffect(() => {
        if (language?.currencyLang) {
            dispatch(systemFeatureAction.loadSystemFeature());
        }
    }, [language.currencyLang, dispatch]);

    return <></>;
};

export const PopupComponents = () => {
    const popupReducer = useSelector((state) => state.popupReducer);
    const popupConfigs = [
      { type: POPUP_TYPE.GA, Component: GoogleAuthPopup },
      { type: POPUP_TYPE.PIN_ENTRY, Component: EnterPinPopup },
      { type: POPUP_TYPE.EDIT_VERIFY_AUTH, Component: EditVerifyAuthPopup },
      { type: POPUP_TYPE.SIX_PIN_SETUP, Component: SetupPinPopup },
    ];
    
    return <>{popupConfigs.map(({ type, Component }) => (popupReducer?.items?.[type] ? LazyComponent(Component) : null))}</>;
};
