import moment from 'moment';
import 'rc-datetime-picker/dist/picker.css';
import React, { Component, Fragment } from 'react';
import { Trans, Translation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { mapStateToProps } from '../../redux/selector/selector';
import SmallLoading from '../../components/Loading/SmallLoading';
import depositMaterial from './deposit-method';
import SVGElements from 'root/components/SVGElements';
import Select from 'react-dropdown-select';
import commonUtil from 'root/utils/common-util';
import classnames from 'classnames';
import { Nav, NavLink, NavItem } from 'reactstrap';
// import { ezzpay, h2pay, itpy, fpy, ip4u, yespay, qrpay, surepay, upy, truepay, dgpay, all, heng } from 'root/pages/16MyAccount/161_MyAccount_Items_Data';
import TransactionNote from '../../pages/Share/transaction-note/component/transaction-note';
import { trans_handlePGDepositCountDown } from 'root/utils/transaction-util';
import notification from 'root/utils/notification';
import { trans_getDepositAmountOptionByMethod } from 'root/utils/transaction-util';
import { AMOUNT_DEPOSIT_METHOD_CODE } from 'root/utils/constants';
import { sumUp } from './deposit-method';
import PaymentGatewayIcon from '../../components/Molecules/payment-icon';
import BankICon from '../../components/Molecules/bank-icon';
import { scrollToDepositStep2 } from './deposit-util';

class QuickPay extends Component {
    isMount = true;
    constructor(props) {
        super(props);
        let currentDate = new Date();
        let currentDateTime = moment(Date.now()).format('YYYY-MM-DD hh:mm a');
        this.toggle = this.toggle.bind(this);
        this.state = {
            activeTab: 'quickPay',
            depositDetails: null,
            selectedMethod: this.props.selectedMethod ? this.props.selectedMethod : [],
            selectedBank: [],
            amount: '',
            amountErrMsg: [],
            disabledDepositButton: true,
            limitAmount: '',
            currency: '',
            referenceCode: '',
            depositOptions: [],
            bankList: [],
            bankNameErrMsg: [],
            // selectedDepositChannel: [],
            // receiptImg: [],
            depositDate: moment(currentDateTime, 'YYYY-MM-DD hh:mm a'),
            depositDateString: moment(currentDateTime, 'YYYY-MM-DD hh:mm a').format('YYYY-MM-DDTHH:mm'),
            selectedDepositBank: null,
            radioValue: 0,
            status: '',
            message: '',
            moment: moment(),
            histories: [],
            start: new Date(new Date().setMonth(currentDate.getMonth() - 1)),
            end: currentDate,
            showLoading: false,
            getTncReferral: false,
            depositNotice: '',

            methodList: [],
            methodBankList: [],
            isFirstTimeInit: this.props.isFirstTimeInit,
            selectedMethodCache: {},

            depositAllSettings: this.props.depositAllSettings,
            filteredDepositApiJson: this.props.filteredDepositApiJson,
            depositMinMaxLimit: null,

            //notice artical settings
            showDefaultArticle: false,
            showCustomDepositArticle: false,
            showCustomDepositArticleContent: '',
            depositAmountOptions: trans_getDepositAmountOptionByMethod(this.props.depositAmountOptions, AMOUNT_DEPOSIT_METHOD_CODE.QP),

            enableCustomArticleJSON: false,
            compSettings: {},
            depositSubmitCountdown: null,
        };
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({ activeTab: tab }, () => {
                this.setState({ amount: '', errMsgLimitAmount: [], amountErrMsg: [] });
            });
        }
    }

    initCompSettings() {
        const { filteredDepositApiJson } = this.state;

        const paymentCode = filteredDepositApiJson && filteredDepositApiJson.optionCode;

        return new Promise((resolve) => {
            commonUtil.loadCustomTranslation('QuickPay', this);
            // Step 1: Standard way to get from portal settings
            let tempSettings = commonUtil.getSettingFromPortalSettings({
                vm: this,
                settingName: 'quickPaySettings',
            });

            const depositSettings = commonUtil.getSettingFromPortalSettings({
                vm: this,
                settingName: 'depositSettings',
            });

            // Step 2: Massage again to get the specific data you need (Skip if not needed)

            // Step 3: Initial all into compSettings
            let compSettings = {
                hideDisplayInfo: tempSettings.hideDisplayInfo,
                showCustomArticle: tempSettings.showCustomArticle,
                enableCustomArticleJSON: tempSettings.enableCustomArticleJSON,
                showOnlineTransactionMode: tempSettings.showOnlineTransactionMode,
                isCustomArticlePath: tempSettings.isCustomArticlePath,
                customCountdown: depositSettings?.customCountdown,
            };

            this.setState({ compSettings }, () => {
                const { showCustomArticle } = this.state.compSettings;
                if (showCustomArticle) {
                    this.readCustomDepositArticle();
                }
                trans_handlePGDepositCountDown(this, false, compSettings.customCountdown, paymentCode, Date.now().valueOf(), { disabledDepositButton: true });
                resolve();
            });
        });
    }

    readCustomDepositArticle() {
        const { compSettings } = this.state;
        const customFilename = compSettings && compSettings.isCustomArticlePath ? 'qp' : '';
        commonUtil.readCustomDepositArticle(this, customFilename);
    }

    handleMethodChange = (value) => {
        const { screen } = this.props;
        value = screen.isMobile ? value : [value];
        let _selectedMethodCache = this.state.selectedMethodCache;

        this.setState({ selectedMethod: value, isFirstTimeInit: false, isLoading: true, message: '' }, () => {
            const { selectedMethod } = this.state;
            const { user } = this.props;
            const disablePreselectBank = !!this.props.portal?.settings?.features?.depositSettings?.disablePreselectBank;
            if (_selectedMethodCache[value[0].id]) {
                this.setState(
                    {
                        methodBankList: _selectedMethodCache[value[0].id],
                        isLoading: false,
                        selectedBank: disablePreselectBank ? [] : [_selectedMethodCache[value[0].id][0]],
                    },
                    () => {
                        this.getCorrectDepositMinMaxLimit();
                    },
                );
            } else {
                window.SPL_Transaction.getDepositDetailsOnMethodChange([], selectedMethod[0], user.account.currency).then(async (data) => {
                    let methodBankList = data.banks ? data.banks : [];
                    methodBankList = await this.mapBankImg(methodBankList);
                    // cache the selected new methodlist bank list
                    _selectedMethodCache[selectedMethod[0].id] = methodBankList;

                    this.setState(
                        {
                            methodBankList: methodBankList,
                            isLoading: false,
                            selectedMethodCache: _selectedMethodCache,
                            selectedBank: disablePreselectBank ? [] : [methodBankList[0]],
                        },
                        () => {
                            this.getCorrectDepositMinMaxLimit();
                        },
                    );
                });
            }
        });
    };

    handleBankChange = (value) => {
        value = [value];
        const oldValue = this.state.selectedBank;

        this.setState({ selectedBank: value }, () => {
            const {
                enableScrollToDepositStep2 = false,
                scrollToDepositStep2Attempts = 15,
                scrollToDepositStep2Delay = 200,
            } = this.props.portal?.settings?.features?.depositSettings || {};

            scrollToDepositStep2(enableScrollToDepositStep2, oldValue, this.state.selectedBank, scrollToDepositStep2Attempts, scrollToDepositStep2Delay);

            this.checkError();
        });
    };

    // handleDepositChannelChange = (value) => {
    //   this.setState({ selectedDepositChannel: value }, () => {
    //     const { selectedDepositChannel, receiptImg } = this.state;
    //     if (
    //       selectedDepositChannel.length > 0
    //       ((receiptImg.length <= 0 && selectedDepositChannel[0].value === 'O') ||
    //         (receiptImg.length <= 0 && selectedDepositChannel[0].value === 'D') ||
    //         (receiptImg.length <= 0 && selectedDepositChannel[0].value === 'A'))
    //     ) {
    //       this.setState({ status: 'F', message: 'transaction:transaction.reload.receiptIsRequired' });
    //     }
    //     this.checkError();
    //   });
    // };

    // handleReceiptImageChange = (event) => {
    //   if (event && event.target && event.target.files.length > 0) {
    //     this.setState({ receiptImg: URL.createObjectURL(event.target.files[0]), receiptImgFile: event.target.files[0] }, () => {
    //       if (this.state.receiptImg.length > 0) {
    //         this.setState({ status: '', message: '' });
    //       }
    //       this.checkError();
    //     });
    //   }
    // };

    handlereferenceCodeChange = (event) => {
        this.setState({ referenceCode: event.target.value }, () => {
            this.checkError();
        });
    };

    handleDepositDateChanged = (date, dateString) => {
        this.setState({ depositDate: date, depositDateString: date.format('YYYY-MM-DDTHH:ss') }, () => {
            this.checkError();
        });
    };

    onDepositBankAcc = (index) => {
        this.setState({ selectedDepositBank: this.state.merchantBankList[index], radioValue: index }, () => {
            this.checkError();
        });
    };

    componentDidMount() {
        this.initCompSettings().then(() => {
            this.getDepositDetailsOnMethodChange();
        });

        if (this.props.screen.viewType === 'web' && window.location.pathname.includes('/quickpay')) {
            this.props.history.push('/myaccount/deposit');
        } else if (this.props.screen.viewType === 'mobile' && window.location.pathname.includes('/quickpay')) {
            this.props.history.push('/quickpay');
        }
    }

    componentWillUnmount() {
        this.isMount = false;
    }

    componentDidUpdate(prevProps) {
        if (prevProps.selectedMethod !== this.props.selectedMethod) {
            this.getDepositDetailsOnMethodChange();
            this.setState({ message: '' });
        }
    }

    getDepositDetails = () => {
        window.SPL_Transaction.loadDepositRelatedSettings().then((data) => {
            if (data) {
                this.setState({ depositNotice: data.depositJson.quickPay.custom_article });
            }
        });
    };

    getDepositDetailsOnMethodChange = () => {
        this.setState({ isLoading: true }, async () => {
            const { depositDetails, selectedMethod, user } = this.props;
            const { filteredDepositApiJson, customTranslationObj } = this.state;
            let currency = null;
            let methodList = [];
            let selectedMethodObj = [];
            let _selectedMethodCache = {};

            if (this.props.user && this.props.user.account) {
                currency = this.props.user.account.currency;
            }

            if (depositDetails && depositDetails.methodList) {
                methodList = depositDetails.methodList;
            }

            // if enableJsonAPi true from deposit-landing
            if (filteredDepositApiJson && filteredDepositApiJson.channel) {
                // new deposit method func
                for (let channel in filteredDepositApiJson.channel) {
                    if (filteredDepositApiJson.channel[channel]) {
                        let method = filteredDepositApiJson.channel[channel][0];
                        method.name = method.name.replace(' ', '');
                        method.displayName = method.displayName
                            ? method.displayName
                            : customTranslationObj && customTranslationObj[method.code]
                              ? customTranslationObj[method.code]
                              : window.SPL_Translation.getBankChannelTranslation(method, 'channelCode');
                        // method.svgData = await window.SPL_Other.renderTemplateSVG(`${commonUtil.getDepositImgPath()}${method.code}.svg`, `${method.code}`);
                        methodList.push(method);
                    }
                }
                if (methodList.length > 0) {
                    selectedMethodObj.push(methodList[0]);
                    window.SPL_Transaction.getDepositDetailsOnMethodChange([], selectedMethodObj[0], user.account.currency).then(async (data) => {
                        let methodBankList = data.banks ? data.banks : [];
                        methodBankList = await this.mapBankImg(methodBankList);
                        // cache the selected new methodlist bank list
                        _selectedMethodCache[selectedMethodObj[0].id] = methodBankList;

                        const disablePreselectBank = !!this.props.portal?.settings?.features?.depositSettings?.disablePreselectBank;
                        this.setState(
                            {
                                methodBankList: methodBankList,
                                isLoading: false,
                                methodList,
                                selectedMethod: selectedMethodObj,
                                selectedMethodCache: _selectedMethodCache,
                                selectedBank: disablePreselectBank ? [] : [methodBankList[0]],
                            },
                            () => {
                                this.validateDepositAmount();
                                this.getCorrectDepositMinMaxLimit();
                            },
                        );
                    });
                } else {
                    this.setState({ isLoading: false, methodList, selectedMethod: selectedMethodObj });
                }
            } else {
                this.setState({ selectedMethod: selectedMethod });

                // existing deposit method func
                if (selectedMethod[0] && depositMaterial.getDepositMethod(selectedMethod[0].name)) {
                    this.setState({ depositDetails: depositMaterial.getDepositMethod(selectedMethod[0].name), isLoading: false }, () => {
                        this.validateDepositAmount();
                    });
                } else {
                    // if first time init, then no need getDepositDetailsOnMethodChange, striahgt use props to avoid duplicate api
                    if (depositDetails) {
                        if (this.props.isFirstTimeInit) {
                            if (this.isMount) {
                                depositDetails.banks = await this.mapBankImg(depositDetails.banks);
                                depositDetails.methodList = methodList;

                                this.setState({ depositDetails: depositDetails, isLoading: false, depositMinMaxLimit: depositDetails.depositLimit }, () => {
                                    this.validateDepositAmount();
                                    depositMaterial.setDepositMethod(selectedMethod[0].name, depositDetails);
                                });
                            }
                        } else {
                            window.SPL_Transaction.getDepositDetailsOnMethodChange(methodList, selectedMethod[0], currency).then(async (depositMethod) => {
                                if (this.isMount) {
                                    depositMethod.banks = await this.mapBankImg(depositMethod.banks);
                                    depositMethod.methodList = methodList;

                                    this.setState({ depositDetails: depositMethod, isLoading: false, depositMinMaxLimit: depositMethod.depositLimit }, () => {
                                        this.validateDepositAmount();
                                        depositMaterial.setDepositMethod(selectedMethod[0].name, depositMethod);
                                    });
                                }
                            });
                        }
                    }
                }
            }
        });
    };

    // getCorrectDepositMinMaxLimit = () => {
    //     const { depositAllSettings, selectedMethod } = this.state;
    //     const currency = this.props.user.account.currency;
    //     let _depositMinMaxLimit = { minLimitQuick: 0, maxLimitQuick: 0 };

    //     if (
    //         selectedMethod &&
    //         selectedMethod[0] &&
    //         depositAllSettings &&
    //         depositAllSettings.depositJson &&
    //         depositAllSettings.depositJson.quickPay &&
    //         depositAllSettings.depositJson.quickPay[selectedMethod[0].code]
    //     ) {
    //         let depositLimit = depositAllSettings.depositJson.quickPay[selectedMethod[0].code][currency];
    //         _depositMinMaxLimit.minLimitQuick = depositLimit.min;
    //         _depositMinMaxLimit.maxLimitQuick = depositLimit.max;
    //     }
    //     this.setState({ depositMinMaxLimit: _depositMinMaxLimit });
    // };

    getCorrectDepositMinMaxLimit = () => {
        commonUtil.getDepositMinMax(this, 'QP', this.props.user.account.currency, this.state.selectedMethod[0].code, null, null, this.validateDepositAmount);
    };

    loadDepositDetails = () => {
        this.setState({ isLoading: true });
        const { account } = this.props.user;
        let currency = '';
        let activeTab = 'quickPay';

        if (account) {
            currency = account.currency;
        }

        if (Object.keys(depositMaterial.depositDetails).length > 0) {
            if (!depositMaterial.getDepositDetails().defaultMethod) {
                activeTab = 'onlinetransfer';
            }

            this.setState(
                {
                    depositDetails: depositMaterial.getDepositDetails(),
                    selectedMethod: [depositMaterial.getDepositDetails().defaultMethod],
                    isLoading: false,
                    activeTab: activeTab,
                },
                () => {
                    this.validateDepositAmount();
                    this.getDepositDetailsOnMethodChange();
                },
            );
        } else {
            window.SPL_Transaction.loadDepositDetails(currency, this.state.activeTab, false).then(async (depositDetails) => {
                if (this.isMount) {
                    if (!depositDetails.defaultMethod) {
                        activeTab = 'onlinetransfer';
                    }

                    depositDetails.banks = await this.mapBankImg(depositDetails.banks);

                    this.setState({ depositDetails: depositDetails, selectedMethod: [depositDetails.defaultMethod], isLoading: false, activeTab: activeTab }, () => {
                        this.validateDepositAmount();
                        depositMaterial.setDepositDetails(depositDetails);
                        this.getDepositDetailsOnMethodChange();
                    });
                }
            });
        }
    };

    async mapBankImg(banks) {
        let currency = null;

        if (this.props.user && this.props.user.account) {
            currency = this.props.user.account.currency;
        }

        if (currency && banks && banks.length > 0) {
            for (const bank of banks) {
                bank.imgOn = window.SPL_Content.mapBankHoverImg(bank.code, currency, true);
                bank.imgOff = window.SPL_Content.mapBankHoverImg(bank.code, currency, false);

                // bank.svgData = await window.SPL_Other.renderTemplateSVG(`${commonUtil.getDepositImgPath()}${bank.code}.svg`, `${bank.code}`);
            }
        }

        return banks;
    }

    loadMerchantBank = () => {
        let merchantBankList = this.props.merchantBankList;
        // window.SPL_Transaction.loadMerchantBank().then((merchantBankList) => {
        let currency = null;

        if (this.props.user && this.props.user.account) {
            currency = this.props.user.account.currency;
        }

        if (this.isMount) {
            if (currency) {
                for (let i = 0; i < merchantBankList.length; i++) {
                    const merchantBank = merchantBankList[i];
                    merchantBank.imgOn = window.SPL_Content.mapBankHoverImg(merchantBank.bankCode, currency, true);
                    merchantBank.imgOff = window.SPL_Content.mapBankHoverImg(merchantBank.bankCode, currency, false);
                }
            }

            this.setState({ merchantBankList: merchantBankList, selectedDepositBank: merchantBankList[this.state.radioValue] });
        }
        // });
    };

    loadBankAccounts = (callback) => {
        window.SPL_Transaction.loadAllOwnedAndUnOwnedBank().then((data) => {
            let memberBankList = data.memberBankList;

            for (let i = 0; i < memberBankList.length; i++) {
                const memberBank = memberBankList[i];
                //memberBank.displayName = `${memberBank.bankName} ${memberBank.accountNumber} ${memberBank.accountHolder}`;
                memberBank.displayName = `${memberBank.bankName}`;
            }

            if (this.isMount) {
                this.setState({ bankList: data.bankList, memberBankList: memberBankList, unOwnedBankList: data.unOwnedBankList }, () => {
                    if (callback) {
                        callback();
                    }
                });
            }
        });
    };

    handleAmountChange = (value) => {
        if (value) {
            let regex = '';
            // if (this.props.memberDecimalSettings?.[AMOUNT_DEPOSIT_METHOD_CODE.QP]) {
            //     regex = /^[0-9]+$/;
            // } else {
            regex = /^(\d*)\.{0,1}(\d){0,2}$/;
            // }
            if (value.toString().match(regex)) {
                this.setState({ amount: value, message: '' }, () => {
                    this.validateDepositAmount();
                });
            }
        } else {
            this.setState({ amount: '', message: '' }, () => {
                this.validateDepositAmount();
            });
        }
    };

    handleButtonAmountChange = (value) => {
        const { enableAccValueButton } = this.props.portal.settings.features.depositPagesSettings;
        const totalAmount = enableAccValueButton ? sumUp(this.state.amount, value) : value;
        this.setState(
            {
                amount: totalAmount,
                message: '',
            },
            () => {
                this.validateDepositAmount();
            },
        );
    };

    clearInputFieldAmount = () => {
        this.setState({ amount: '' });
    };

    disableDot = (e) => {
        if (e.keyCode == '46' || e.charCode == '46') {
            e.preventDefault();
        }
    };

    checkError = () => {
        const { amount, amountErrMsg, selectedBank, selectedMethod } = this.state;

        if (!amount || amountErrMsg.length > 0 || selectedMethod.length <= 0 || (!selectedBank && selectedBank.length <= 0)) {
            this.setState({ disabledDepositButton: true });
        } else {
            this.setState({ disabledDepositButton: false });
        }
    };

    handleSubmit(type) {
        const { depositSubmission, t } = this.props;
        const { filteredDepositApiJson, depositSubmitCountdown } = this.state;
        const paymentCode = filteredDepositApiJson && filteredDepositApiJson.optionCode;
        const submissionData = depositSubmission && depositSubmission.submissionData;
        const paymentObj = submissionData && submissionData[paymentCode];

        if (paymentObj && paymentObj.submissionRetryTime > Date.now().valueOf()) {
            // this.setState({ disabledDepositButton: true });
            notification.showNotification('error', t('transaction:transaction.deposit.resubmitError', { retryTime: depositSubmitCountdown }));
        } else {
            this.quickPaySubmit(paymentCode);
        }
    }

    validateDepositAmount = () => {
        window.SPL_Transaction.validateDepositAmount(this.state.amount, this.state.depositMinMaxLimit, 'quickPay')
            .then((errMsg) => {
                if (this.props.memberDecimalSettings?.[AMOUNT_DEPOSIT_METHOD_CODE.QP] && !this.state.amount.toString().match(/^[0-9]+$/)) {
                    const newErrMsg = window.SPL_Other.deepCopy(errMsg);
                    newErrMsg.push('transaction:transaction.withdrawal.disableDecimalError');
                    return newErrMsg;
                }

                return errMsg;
            })
            .then((errMsg) => {
                if (this.isMount) {
                    let errMsgLimitAmount = this.getLimitAmountForErrMsg(errMsg[0]);

                    this.setState({ amountErrMsg: errMsg, limitAmount: errMsgLimitAmount.limitAmount, currency: errMsgLimitAmount.currency }, () => {
                        this.checkError();
                    });
                }
            });
    };

    getLimitAmountForErrMsg(errMsg) {
        let limitAmount = '';
        let currency = this.props.user.account.currency;

        if (errMsg) {
            if (errMsg.includes('invalidMinAmount')) {
                if (this.state.activeTab === 'quickPay') {
                    limitAmount = window.SPL_Other.formatAmount(this.state.depositMinMaxLimit.minLimitQuick);
                } else {
                    limitAmount = window.SPL_Other.formatAmount(this.state.depositMinMaxLimit.minLimit);
                }
            } else if (errMsg.includes('invalidMaxAmount')) {
                if (this.state.activeTab === 'quickPay') {
                    limitAmount = window.SPL_Other.formatAmount(this.state.depositMinMaxLimit.maxLimitQuick);
                } else {
                    limitAmount = window.SPL_Other.formatAmount(this.state.depositMinMaxLimit.maxLimit);
                }
            }
        }

        return {
            limitAmount: limitAmount,
            currency: currency,
        };
    }

    quickPaySubmit = (paymentCode) => {
        const vm = this;
        const { language } = this.props;
        const { compSettings } = this.state;
        const { customCountdown } = compSettings;

        if (this.state.amountErrMsg.length > 0 || this.state.selectedMethod.length <= 0 || (!this.state.selectedBank && this.state.selectedBank.length <= 0)) {
            return;
        }

        const countdown = customCountdown || typeof customCountdown === 'number' ? customCountdown : 30;
        let method = this.state.selectedMethod[0];
        let selectedBankCode = this.state.selectedBank[0] ? this.state.selectedBank[0].code : null;
        let amount = this.state.amount;
        // sp2,bk8 set these as null
        // let preferredWallet = depositDetails.preferredWalletDetails.preferredWallet;
        // let hidePWallet = depositDetails.preferredWalletDetails.hidePWallet;
        let callBackUrl = '/myaccount/deposit';
        let langPath = language.countryLanguageKey.replace('_', '-').toLowerCase();
        let stateUrl = `/${langPath}/quickpayredirect`;
        // window.open(stateUrl);

        window.SPL_Transaction.requestDepositOnlineTransfer(method, selectedBankCode, amount, null, null, stateUrl, this.props.language.key, callBackUrl).then((data) => {
            if (data.errorCode) {
                notification.showNotification('error', data.message);
            } else {
                trans_handlePGDepositCountDown(vm, true, countdown, paymentCode, Date.now().valueOf(), { disabledDepositButton: true });
                this.setState({ amount: '', status: 'S', message: 'transaction:transaction.deposit.onlinetransfer.redirectVendor' });
            }
        });
    };

    // localPaySubmit = () => {
    //   const { preferredWalletDetails } = this.state.depositDetails;
    //   const {
    //     amount,
    //     depositDateString,
    //     // selectedDepositChannel,
    //     selectedDepositBank,
    //     receiptImgFile,
    //     receiptImg,
    //     referenceCode,
    //     selectedBank,
    //   } = this.state;

    //   if (
    //     !amount ||
    //     // selectedDepositChannel.length <= 0 ||
    //     // (selectedBank.length <= 0 && selectedDepositChannel[0].value !== 'D') ||
    //     !selectedDepositBank ||
    //     // (receiptImg.length <= 0 && selectedDepositChannel[0].value === 'O') ||
    //     // (receiptImg.length <= 0 && selectedDepositChannel[0].value === 'D') ||
    //     // (receiptImg.length <= 0 && selectedDepositChannel[0].value === 'A') ||
    //     !depositDateString
    //   ) {
    //     return;
    //   }

    //   let preferredWallet = preferredWalletDetails.preferredWallet;
    //   let hidePWallet = preferredWalletDetails.hidePWallet;
    //   let depositAmount = amount;
    //   // let depositChannel = selectedDepositChannel[0].value;
    //   let depositDateTime = depositDateString;
    //   let merchantBankId = selectedDepositBank.id;
    //   // let memberBankId = (selectedDepositChannel[0].value !== 'D' && selectedBank[0].id) || null;
    //   // let picFile = receiptImgFile;

    //   // preferredWallet, hidePWallet, merchantBankId, depositAmount, depositChannel, depositDateTime, memberBankId, picFile, referenceCode
    //   window.SPL_Transaction.localPaySubmit(
    //     preferredWallet,
    //     hidePWallet,
    //     merchantBankId,
    //     depositAmount,
    //     // depositChannel,
    //     depositDateTime,
    //     // memberBankId,
    //     // picFile,
    //     referenceCode
    //   ).then((data) => {
    //     if (this.isMount) {
    //       if (data.status === 'S') {
    //         this.setState({ disabledDepositButton: false });

    //         let depositAmount = '';
    //         let depositChannel = [];
    //         // let receiptImgFile = null;
    //         let referenceCode = '';
    //         let selectedBank = [];
    //         this.setState({
    //           status: 'S',
    //           message: 'transaction:transaction.deposit.request-success',
    //           amount: depositAmount,
    //           // selectedDepositChannel: depositChannel,
    //           // receiptImgFile: receiptImgFile,
    //           referenceCode: referenceCode,
    //           selectedBank: selectedBank,
    //           // receiptImg: '',
    //         }, () => {
    //           let status = this.state.status;

    //           if (status === 'S') {
    //             setTimeout(() => {
    //               this.setState({ message: '' });
    //             }, 5000);
    //           }
    //         }
    //         );
    //       } else {
    //         this.setState({ disabledDepositButton: false });

    //         if (data.code === 'receipt_fail') {
    //           this.setState({ status: 'F', message: 'transaction:transaction.withdrawal.request-uploadReceiptError' });
    //         } else if (data.msg.data.status === 409) {
    //           this.setState({ status: 'F', message: 'transaction:transaction.withdrawal.request-pending' });
    //         } else if (data.msg.status === 400) {
    //           this.setState({ status: 'F', message: 'transaction:transaction.withdrawal.request-pending' });
    //         } else {
    //           this.setState({ status: 'F', message: 'transaction:transaction.withdrawal.request-error' });
    //         }
    //       }
    //     }
    //   });
    // };

    copy(text) {
        window.SPL_Other.copyToClipboard(text);
    }

    openBankDetailModal = () => {
        this.setState({ isOpenBankModal: true });
    };

    selectAddedBank = (bankId) => {
        this.loadBankAccounts(() => {
            let memberBankList = this.state.memberBankList;
            let autoSelectedAddedBank = memberBankList.filter((e) => e.bankId === bankId);

            this.setState({ selectedBank: autoSelectedAddedBank });
        });
    };

    closeBankModal = () => {
        this.setState({ isOpenBankModal: false });
    };

    handleChange = (moment) => {
        this.setState({ moment });
    };

    onDateChanged = (date, type) => {
        if (type === 'start') {
            this.setState({ start: date });
        } else {
            this.setState({ end: date });
        }
    };

    showDetails = (type, history) => {
        this.setState({ type: type, historySelected: history, isOpenHistoryDetailsModal: true });
    };

    // loadHistories = () => {
    //   let params = {
    //     start: this.state.start,
    //     end: this.state.end,
    //   };

    //   window.SPL_Transaction.loadHistories('depositWithdraw', params).then((histories) => {
    //     if (this.isMount) {
    //       let historiess = [
    //         {
    //           id: 1,
    //           from: 'GD',
    //           to: 'SA',
    //           createdDate: '2020-03-03',
    //           currency: 'MYR',
    //           resultClass: 'text-success',
    //           result: 'transaction:transaction.status.success',
    //           amount: 50,
    //         },
    //         {
    //           id: 2,
    //           from: 'GD',
    //           to: 'Haha',
    //           createdDate: '2020-03-04',
    //           currency: 'MYR',
    //           resultClass: 'text-success',
    //           result: 'transaction:transaction.status.success',
    //           amount: 40,
    //         },
    //         {
    //           id: 3,
    //           from: 'GD',
    //           to: 'hehe',
    //           createdDate: '2020-03-05',
    //           currency: 'MYR',
    //           resultClass: 'text-success',
    //           result: 'transaction:transaction.status.success',
    //           amount: 30,
    //         },
    //         {
    //           id: 4,
    //           from: 'GD',
    //           to: 'huhu',
    //           createdDate: '2020-03-06',
    //           currency: 'MYR',
    //           resultClass: 'text-success',
    //           result: 'transaction:transaction.status.success',
    //           amount: 10,
    //         },
    //         {
    //           id: 5,
    //           from: 'GD',
    //           to: 'wwwwhhheeennn',
    //           createdDate: '2020-03-07',
    //           currency: 'MYR',
    //           resultClass: 'text-success',
    //           result: 'transaction:transaction.status.success',
    //           amount: 500,
    //         },
    //         {
    //           id: 6,
    //           from: 'GD',
    //           to: 'wwwwwhhhhyyyyy',
    //           createdDate: '2020-03-08',
    //           currency: 'MYR',
    //           resultClass: 'text-success',
    //           result: 'transaction:transaction.status.success',
    //           amount: 600,
    //         },
    //         {
    //           id: 7,
    //           from: 'GD',
    //           to: 'hhhooowww',
    //           createdDate: '2020-03-09',
    //           currency: 'MYR',
    //           resultClass: 'text-success',
    //           result: 'transaction:transaction.status.success',
    //           amount: 1000,
    //         },
    //         {
    //           id: 8,
    //           from: 'GD',
    //           to: 'gdgddgd',
    //           createdDate: '2020-03-10',
    //           currency: 'MYR',
    //           resultClass: 'text-success',
    //           result: 'transaction:transaction.status.success',
    //           amount: 50,
    //         },
    //         {
    //           id: 9,
    //           from: 'GD',
    //           to: 'gdgdgd',
    //           createdDate: '2020-03-11',
    //           currency: 'MYR',
    //           resultClass: 'text-success',
    //           result: 'transaction:transaction.status.success',
    //           amount: 50,
    //         },
    //       ];

    //       this.setState({ histories: histories.histories });
    //     }
    //   });
    // };

    toggleLoading = (toggle) => {
        this.setState({ showLoading: toggle });
    };

    loadBankAccountsForDeposit = () => {
        window.SPL_Transaction.loadAllOwnedAndUnOwnedBank()
            .then((data) => {
                if (this.isMount) {
                    this.setState({ unOwnedBankList: data.unOwnedBankList });
                }

                this.toggleLoading(false);
            })
            .catch((err) => {
                this.toggleLoading(false);
            });
    };

    closeBankModal = () => {
        this.setState({ isOpenBankModal: false });
    };

    getTncReferral = () => {
        this.setState({ getTncReferral: !this.state.getTncReferral });
    };

    renderMethod(method) {
        const { selectedMethod } = this.state;
        const { name } = method;
        const currentMethod = selectedMethod[0];
        const isActive = currentMethod.name === name;

        return (
            <div name={method.code} className={`img-container channel-img ${isActive ? 'active' : ''}`}>
                <PaymentGatewayIcon pgCode={method.code} isActive={isActive} />
            </div>
        );
    }

    render() {
        const { depositDetails, isLoading, filteredDepositApiJson, methodList, methodBankList, depositMinMaxLimit, selectedMethod, depositAmountOptions, selectedBank } =
            this.state;

        const { country } = this.props.language;
        const { screen, paymentGatewaySetting, portal } = this.props;

        let transactionNoteProps = {
            showCustomArticle: this.state.showCustomDepositArticle,
            customArticleContent: this.state.showCustomDepositArticleContent,
            customArticleIsJson: this.state.customArticleIsJson,
            customContentStyle: this.state.customContentStyle,
            hideDefaultNote: [!this.state.showDefaultArticle, true, true],
        };

        return (
            this.props.user.account && (
                <Translation>
                    {(t) => (
                        <div className='quickpay'>
                            {!isLoading && screen.isMobile && filteredDepositApiJson && (
                                <div className='item deposit-channel'>
                                    <div className='title-item'>
                                        <span> {t('transaction:transaction.deposit.cashdeposit.channel')}</span>
                                        <span className='text-danger asterisk'>*</span>
                                    </div>
                                    <div className='deposit-amount'>
                                        {methodList.length > 0 ? (
                                            <Select
                                                className='form-control-inner'
                                                placeholder={t('settings:settings.dropdown.pleaseselect')}
                                                options={methodList}
                                                labelField={t('displayName')}
                                                valueField='bankId'
                                                key='bankId'
                                                searchable={false}
                                                values={selectedMethod}
                                                onChange={(value) => {
                                                    this.handleMethodChange(value);
                                                }}
                                            />
                                        ) : (
                                            <SmallLoading></SmallLoading>
                                        )}
                                    </div>
                                </div>
                            )}
                            <ul
                                className={`${
                                    (screen.isMobile && depositDetails && depositDetails.banks && depositDetails.banks.length <= 0) || (methodBankList && methodBankList <= 0)
                                        ? 'm-small-banklist'
                                        : 'small-banklist'
                                } `}
                            >
                                <li>
                                    <div className='item'>
                                        {!isLoading && !screen.isMobile && (
                                            <p className='deposit-options'>
                                                {t('transaction:transaction.deposit.cashdeposit.depositChannel', 'Deposit Channel')}
                                                <span className='text-danger asterisk'>*</span>
                                            </p>
                                        )}
                                        <div className='bank-selector'>
                                            {filteredDepositApiJson && !isLoading && !screen.isMobile && (
                                                <Nav tabs className='qr-deposit-channel-tab'>
                                                    {methodList && methodList.length > 0 && (
                                                        <div className='display-flex'>
                                                            {methodList.map((method, index) => {
                                                                return (
                                                                    <NavItem
                                                                        key={index}
                                                                        className='deposit-tab-click'
                                                                        onClick={() => {
                                                                            this.handleMethodChange(method);
                                                                        }}
                                                                    >
                                                                        <NavLink
                                                                            className={classnames({
                                                                                active: this.state.selectedMethod[0].name === method.name,
                                                                                navlinkForm: true,
                                                                                depositLink: true,
                                                                            })}
                                                                        >
                                                                            <div className='deposit-banktransfer'>{this.renderMethod(method)}</div>
                                                                            <div className='deposit-options-text'>{method.displayName}</div>
                                                                        </NavLink>
                                                                    </NavItem>
                                                                );
                                                            })}
                                                        </div>
                                                    )}
                                                </Nav>
                                            )}

                                            {(depositDetails || filteredDepositApiJson) && !isLoading ? (
                                                <ul className='bank-list'>
                                                    {((depositDetails && depositDetails.banks) || methodBankList || []).map((bank, index) => {
                                                        const isActive = this.state.selectedBank?.[0]?.name === bank.name;
                                                        return (
                                                            <li className='small-bank' key={index}>
                                                                <div key={bank.id} className='banklist-group' onClick={() => this.handleBankChange(bank)}>
                                                                    <input
                                                                        type='radio'
                                                                        name='bank-account'
                                                                        onChange={() => this.handleBankChange(bank)}
                                                                        checked={this.state.selectedBank.length > 0 && this.state.selectedBank[0].name === bank.name}
                                                                    />
                                                                    <span className='checkmark'></span>
                                                                    <div className={`bank-img ${isActive ? 'bank-on' : ''}`}>
                                                                        <BankICon bankCode={bank.code} isActive={isActive} />
                                                                    </div>

                                                                    <div
                                                                        className={`bank-name ${
                                                                            this.state.selectedBank.length > 0 && this.state.selectedBank[0].name === bank.name
                                                                                ? 'bank-selected'
                                                                                : ''
                                                                        }`}
                                                                    >
                                                                        {bank.name}
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        );
                                                    })}
                                                </ul>
                                            ) : (
                                                <SmallLoading></SmallLoading>
                                            )}
                                        </div>
                                    </div>
                                </li>

                                {!this.props.screen.isMobile && <TransactionNote {...transactionNoteProps} />}
                            </ul>

                            {(selectedBank?.length || !methodBankList?.length) && !isLoading ? (
                                <ul className='deposit-bottom' id='deposit-bottom'>
                                    {(!paymentGatewaySetting ||
                                        (paymentGatewaySetting && !paymentGatewaySetting.amountFieldNotRequired.includes(selectedMethod && selectedMethod.code))) && (
                                        <Fragment>
                                            <li>
                                                <div className='item'>
                                                    <p className='deposit-options'>
                                                        {t('transaction:transaction.deposit.onlinetransfer.amount')} <span className='text-danger asterisk'>*</span>
                                                    </p>

                                                    <div className='deposit-amount'>
                                                        <div className='deposit-amount-input'>
                                                            <input
                                                                name='amount'
                                                                className={`form-control-inner ${this.state.amountError ? 'is-invalid' : ''}`}
                                                                id='name'
                                                                min='0'
                                                                value={this.state.amount}
                                                                placeholder={t('global:global.form.online-transfer-amount-placeholder', {
                                                                    min: window.SPL_Other.formatAmount(depositMinMaxLimit && depositMinMaxLimit.minLimitQuick),
                                                                    max: window.SPL_Other.formatAmount(depositMinMaxLimit && depositMinMaxLimit.maxLimitQuick),
                                                                })}
                                                                onChange={(e) => this.handleAmountChange(e.target.value)}
                                                                onBlur={this.validateDepositAmount}
                                                            />
                                                            {portal?.settings?.features?.depositPagesSettings?.enableAccValueButton && depositAmountOptions?.length > 0 && (
                                                                <div className='deposit-amount-clear-button' onClick={this.clearInputFieldAmount}>
                                                                    <img src='/public/html/images/settings/clear-button.svg' alt='clear-button-img' />
                                                                </div>
                                                            )}
                                                        </div>

                                                        {country === 'VN' && (
                                                            <div className='invalid-feedback important-notice'>
                                                                <span>1 = 1000 đồng</span>
                                                            </div>
                                                        )}

                                                        {this.state.amountErrMsg.map((errMsg, index) => {
                                                            return (
                                                                <div key={index} className='invalid-feedback important-notice'>
                                                                    {this.props.screen.isMobile && <i className='icon-tip'></i>}
                                                                    <Trans i18nKey={errMsg}></Trans> {`${this.state.currency} ${this.state.limitAmount}`}
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                </div>
                                            </li>

                                            <li>
                                                <div className='item amount-select'>
                                                    <p className='deposit-options'></p>
                                                    <div className='amount-select-btn'>
                                                        {depositAmountOptions.map((item, index) => (
                                                            <button key={index} className='btn-amount' onClick={() => this.handleButtonAmountChange(item)}>
                                                                {item}
                                                            </button>
                                                        ))}
                                                    </div>
                                                </div>
                                            </li>
                                        </Fragment>
                                    )}

                                    <li>
                                        <div className='item'>
                                            <p className='deposit-options'></p>
                                            <div className='deposit-quickpay-button'>
                                                <button
                                                    type='submit'
                                                    className='btn-normal btn-deposit'
                                                    disabled={this.state.disabledDepositButton}
                                                    onClick={() => this.handleSubmit('quickpay')}
                                                >
                                                    <span>{t('transaction:transaction.deposit.onlinetransfer.depositbtn')}</span>
                                                </button>

                                                <span className='tnc-apply mb-only' onClick={() => this.getTncReferral()}>
                                                    * {t('slot:slot.tnc-apply', 'Terms & Conditions Applied')}
                                                </span>
                                            </div>
                                        </div>
                                    </li>

                                    <li>
                                        <div className='item'>
                                            <p className='deposit-options'></p>
                                            <div className='deposit-quickpay-button'>
                                                {this.state.status === 'F' && (
                                                    <div className='invalid-feedback text-danger'>
                                                        <Trans i18nKey={this.state.message}></Trans>
                                                    </div>
                                                )}

                                                {this.state.status === 'S' && (
                                                    <div className='invalid-feedback text-success'>
                                                        <Trans i18nKey={this.state.message}></Trans>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            ) : null}

                            {this.state.getTncReferral && (
                                <li className='messaging-popup mobile-notice'>
                                    <div className='popup notice-box'>
                                        <div className='popup-header'>
                                            <div className='popup-header-left'>
                                                <div className='popup-title'>{t('slot:slot.tnc-apply', 'Terms & Conditions Applied')}</div>
                                            </div>

                                            <div className='popup-header-right'>
                                                <SVGElements name='close-icon' onClick={() => this.getTncReferral()} />
                                                {/* <MdClose onClick={() => this.getTncReferral()} /> */}
                                            </div>
                                        </div>

                                        <TransactionNote {...transactionNoteProps} />
                                    </div>
                                    <div className='popup-overlay notice-overlay' onClick={() => this.getTncReferral()}></div>
                                </li>
                            )}
                        </div>
                    )}
                </Translation>
            )
        );
    }
}

export default connect(mapStateToProps)(withTranslation(['transaction', 'settings', 'bank'])(withRouter(QuickPay)));
