import _ from 'lodash';
import React, { Component } from 'react';
import { Translation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { mapStateToProps } from '../../redux/selector/selector';
import { withRouter } from 'react-router-dom';
import Item from './171_BankDetail_Items';
import BankDetailModal from './174_BankDetail_Modal';
import { Col } from 'reactstrap';

class BankDetail extends Component {
  isMount = true;
  constructor(props) {
    super(props);
    this.state = {
      memberBankList: [],
      showLoading: false,
    };
    this.MAX_ITEMS_LENGTH = 30;
    this.handleChange = this.handleChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleChecked = this.handleChecked.bind(this);
  }

  componentDidMount() {
    this.loadBankAccounts();

    if (this.props.screen.viewType === 'web' && this.props.location.pathname === '/bankdetail') {
      this.props.history.push('/myaccount/bankdetails');
    } else if (this.props.screen.viewType === 'mobile' && this.props.location.pathname === '/myaccount/bankdetails') {
      this.props.history.push('/myaccount/myprofile');
      this.props.history.push('/bankdetail');
    }
  }

  componentWillUnmount() {
    this.isMount = false;
  }

  loadBankAccounts = () => {
    window.SPL_Transaction.loadAllOwnedAndUnOwnedBank()
      .then((data) => {
        if (this.isMount) {
          this.setState({
            memberBankList: data.memberBankList,
            unOwnedBankList: data.unOwnedBankList,
          });
        }
        this.toggleLoading(false);
      })
      .catch((err) => {
        this.toggleLoading(false);
      });
  };
  handleSave(id, text) {
    let items = this.state.items;
    let index = _.findIndex(items, { id });
    items[index].text = text;
    this.setState({
      items,
    });
  }

  handleChecked(id, checked) {
    let items = this.state.items;
    let index = _.findIndex(items, { id });
    items[index].checked = checked;
    this.setState({
      items,
    });
  }

  calculateID(items) {
    if (items.length === 0) {
      return 0;
    }
    return _.maxBy(items, 'id').id + 1;
  }

  handleClick() {
    if (this.state.value.length === 0) {
      return;
    }
    let items = this.state.items;
    items.push({ id: this.calculateID(items), text: this.state.value });
    this.setState({
      items,
      value: '',
    });
  }
  handleChange(event) {
    this.setState({
      value: event.target.value,
    });
  }

  handleDelete = (id) => {
    this.toggleLoading(true);
    window.SPL_Member.updateBankAccStatus(id)
      .then((data) => {
        if (data.status === 'S') {
          this.loadBankAccounts();
        } else {
          if (this.isMount) {
            this.setState({ errMsg: data.errMsg });
          }
          this.toggleLoading(false);
        }
      })
      .catch((err) => {
        this.toggleLoading(false);
      });
  };

  toggleLoading = (toggle) => {
    this.setState({ showLoading: toggle });
  };

  closeBankModal = () => {
    this.setState({ isOpenBankModal: false });
  };

  doNothing = () => {
    return 0;
  };

  render() {
    if (this.state.unOwnedBankList !== undefined) {
      const { t } = this.props;
      let bankList = this.state.unOwnedBankList;
      for (let i = 0; i < bankList.length; i++) {
        const bank = this.state.unOwnedBankList[i];
        if (bank.bankName === 'Other Bank') {
          bank.bankName = t('transaction:transaction.withdrawal.otherBanks')
        } else {

        }
      }
    } else {
      return null
    }

    return (
      <Translation>
        {(t) => (
          <div className='app-bankDetail w-100'>
            {/* {this.state.showLoading && <Loading className='vh-50'></Loading>} */}
            <div className='row d-none d-md-flex justify-content-center'>
              <div className='account-col'>
                <div className='form-content d-flex justify-content-center'>
                  <Col xs={12} md={8}>
                    <div>
                      <Col xs={12} md={12} lg={12} className='d-flex justify-content-center'>
                        <div className='w-100 d-flex align-items-center'>
                          <Col xs={5} md={5} className='p-l-0 p-r-0'>
                            <h5 className='bankDetail-text text-uppercase'>{t('transaction:transaction.withdrawal.account')}</h5>
                          </Col>
                          <Col xs={7} md={7} className='d-flex justify-content-end'>
                            <button
                              type='submit'
                              className='primary-btn button-submit border-0 h6 button-account'
                              onClick={() => this.setState({ isOpenBankModal: true })}>
                              <span> {t('bank:bank.newBank.title')}</span>
                            </button>
                          </Col>
                        </div>
                      </Col>
                      <BankDetailModal
                        buttonText={t('transaction:transaction.deposit.addMemberBankDetails')}
                        className='btn-secondary'
                        isOpen={this.state.isOpenBankModal}
                        closeBankModal={this.closeBankModal}
                        accountName={this.props.user && this.props.user.account && this.props.user.account.name}
                        unOwnedBankList={this.state.unOwnedBankList}
                        callback={this.loadBankAccounts}
                        reloadDeposit={this.doNothing}></BankDetailModal>
                    </div>

                    <div>
                      <div className='bankDetail-table'>
                        {this.state.memberBankList.map((memberBank) => (
                          <Item
                            key={memberBank.memberBankId}
                            item={memberBank}
                            onDelete={() => {
                              this.handleDelete(memberBank.memberBankId);
                            }}
                            onSave={this.handleSave}
                            onChecked={this.handleChecked}
                          />
                        ))}
                      </div>
                    </div>
                  </Col>
                </div>
              </div>
            </div>
            <div className='content-mobile d-block d-md-none p-l-0 p-r-0 content'>
              <div className='row d-flex justify-content-center content-inner'>
                <div className=''>
                  <div className='d-flex justify-content-center'>
                    <Col xs={12} md={8}>
                      <div>
                        <Col xs={12} md={12} lg={12} className='d-flex justify-content-center'>
                          <div className='w-100 d-flex align-items-center'>
                            <Col xs={12} md={12} className='d-flex justify-content-end'>
                              <button
                                type='submit'
                                className='primary-btn button-submit border-0'
                                onClick={() => this.setState({ isOpenBankModal: true })}>
                                <span> {t('bank:bank.newBank.title')}</span>
                              </button>
                            </Col>
                          </div>
                        </Col>
                        <BankDetailModal
                          buttonText={t('transaction:transaction.deposit.addMemberBankDetails')}
                          className='btn-secondary'
                          isOpen={this.state.isOpenBankModal}
                          closeBankModal={this.closeBankModal}
                          accountName={this.props.user && this.props.user.account && this.props.user.account.name}
                          unOwnedBankList={this.state.unOwnedBankList}
                          callback={this.loadBankAccounts}
                          reloadDeposit={this.doNothing}></BankDetailModal>
                      </div>

                      <div>
                        <div className='bankDetail-table'>
                          {this.state.memberBankList.map((memberBank) => (
                            <Item
                              key={memberBank.memberBankId}
                              item={memberBank}
                              onDelete={() => {
                                this.handleDelete(memberBank.memberBankId);
                              }}
                              onSave={this.handleSave}
                              onChecked={this.handleChecked}
                            />
                          ))}
                        </div>
                      </div>
                    </Col>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </Translation>
    );
  }
}
export default connect(mapStateToProps)(withTranslation(['transaction', 'bank'])(withRouter(BankDetail)));
