import moment from 'moment';
import React, { Component } from 'react';
import { userAction } from '../../redux/action';
import { Translation, withTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';
import SVGMessage from '../../components/Icon/SVGMessage';
import MessagingModal from './121_MessagingModal';
import Loading from '../../components/Loading/Loading';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import MessagingDetailModal from '../../pages/12Messaging/122_MessagingDetailModal';
import { MdFlag } from 'react-icons/md';
import { connect } from 'react-redux';
import { mapStateToProps } from '../../redux/selector/selector';
import { withRouter } from 'react-router-dom';
import UnreadMsg from './UnreadMsgCount';

class Messaging extends Component {
  isMount = true;
  timeOut = null;

  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      messageList: [],
      listOfCheckedId: [],
      allChecked: false,
      show: false,
      checkAll: false,
      currentPage: 1,
      todosPerPage: 5,
      isLoading: false,
      checkBool: false,
      countCheck: 0,
      getId: [],
      unreadMsgCount: 0,
    };
  }

  componentDidMount() {
    this.isMount = true;
    this.getMessages();
    this.countMsg();

    if (this.props.screen.viewType === 'web' && this.props.location.pathname === '/messaging') {
      this.props.history.push('/myaccount/messaging');
    } else if (this.props.screen.viewType === 'mobile' && this.props.location.pathname === '/myaccount/messaging') {
      this.props.history.push('/myaccount/myprofile');
      this.props.history.push('/messaging');
    }
  }

  // componentDidUpdate() {
  //   setInterval(() => {
  //     this.countMsg();
  //   }, 60000);
  // }

  componentWillUnmount() {
    this.isMount = false;
    if (this.timeOut) {
      clearTimeout(this.timeOut);
    }
  }

  handleClick = (event) => {
    this.setState(
      {
        currentPage: Number(event.target.id),
      },
      () => {
        this.clearCheck();
      }
    );
  };

  backwards = () => {
    let check = this.state.currentPage;
    if (check > 1 && check !== 1) {
      this.setState(
        {
          currentPage: check - 1,
        },
        () => {
          this.clearCheck();
        }
      );
    }
  };

  forwards = (e) => {
    let check = this.state.currentPage;
    if (e > check && e !== check) {
      this.setState(
        {
          currentPage: check + 1,
        },
        () => {
          this.clearCheck();
        }
      );
    }
  };

  getMessages() {
    window.SPL_Member.getMessages().then((msgObj) => {
      if (this.isMount) {
        let messageArr = msgObj.messages;
        for (let i = 0; i < messageArr.length; i++) {
          messageArr[i].isChecked = false;
          if (messageArr[i].status.includes('F')) {
            messageArr[i].isFlagChecked = true;
          } else {
            messageArr[i].isFlagChecked = false;
          }
        }
        this.setState({ messageList: messageArr });
      }
    });
  }

  deleteMessageByBatch = () => {
    this.setState(
      { isLoading: true },
      () => {
        let listOfCheckedId = [];
        let messageList = this.state.messageList;
        for (let i = 0; i < messageList.length; i++) {
          const msg = messageList[i];
          if (msg.isChecked) {
            listOfCheckedId.push(msg.id);
          }
        }
        this.setState({ listOfCheckedId: listOfCheckedId }, () => {
          window.SPL_Member.deleteMessage(listOfCheckedId).then((data) => {
            this.timeOut = setTimeout(() => {
              if (this.isMount) {
                this.getMessages();
              }
            }, 1000);
            this.setState({ listOfCheckedId: [] });
          });
        });
      },
      setTimeout(() => {
        this.setState({ isLoading: false });
      }, 1800)
    );
  };

  onChange = (index) => {
    let array = this.state.messageList;
    array[index].isChecked = !array[index].isChecked;
    let id = this.state.getId;
    // if (array.length > 0) {
    //   let checked = array[index].isChecked;
    //   let page = this.state.currentPage;
    //   let z = 1;
    //   let a = index;
    //   let idIndex = null;
    //   if (page > z) {
    //     for (z; z < page; z++) {
    //       a = a + 5;
    //     }
    //   }
    //   if (checked) {
    //     id.push(array[a].id);
    //   } else {
    //     for (let x = 0; x < id.length; x++) {
    //       if (id[x] === array[a].id) {
    //         idIndex = x;
    //       }
    //     }
    //     id.splice(idIndex, 1);
    //   }
    // }
    this.setState({ messageList: array, getId: id, checkAll: false }, () => {
      let msgList = this.state.messageList;
      for (let i = 0; i < msgList.length; i++) {
        const contact = msgList[i];
        if (contact.isChecked === true) {
          this.setState({ countCheck: 1 });
          break;
        } else {
          this.setState({ countCheck: 0 });
        }
      }
    });
  };

  onFlagChange = (index) => {
    let array = this.state.messageList;
    let msg = array[index];
    msg.isFlagChecked = !msg.isFlagChecked;
    let messageObj = {
      id: msg.id,
      isFlag: msg.isFlagChecked,
      status: msg.status,
    };
    array[index] = msg;
    window.SPL_Member.readAndFlagMessage(messageObj, ()=>{
      this.setState({ messageList: array });
    });
  };

  onClickMessage(index) {
    let counter = 1;
    let page = this.state.currentPage;
    if (page > counter) {
      let i = page - counter;
      let j = 0;
      for (j; j > i; j++) {
        index = index + 10;
      }
    }
    let array = this.state.messageList;
    let msg = array[index];
    let messageObj = {
      id: msg.id,
      isFlag: msg.isFlagChecked,
      status: 'R',
    };
    array[index] = msg;
    this.setState({ selectedMessage: msg, openMessageModal: true });
    window.SPL_Member.readAndFlagMessage(messageObj, ()=>{
      if (this.timeOut) {
        clearTimeout(this.timeOut);
      }
      this.timeOut = setTimeout(() => {
        if (this.isMount) {
          this.getMessages();
        }
      }, 1000);
    });
  }

  countMsg = () => {
    window.SPL_Member.getUnreadMessageCount().then((data) => {
      if (this.isMount) {
        this.setState({unreadMsgCount: data.unreadCount}, () => {
          let count = this.state.unreadMsgCount;
          this.props.dispatch(userAction.getUnreadMsg(count));
        });
      }
    });
  }

  isChecked(index) {
    return this.state.checkAll || this.state.messageList[index].isChecked;
  }

  isFlagChecked(index) {
    return this.state.messageList[index].isFlagChecked;
  }

  onCheckAllChange = (e) => {
    let checked = e.target.checked;
    let messageList = this.state.messageList;
    for (let i = 0; i < messageList.length; i++) {
      messageList[i].isChecked = checked;
    }
    let id = this.state.getId;
    // if (messageList.length > 0) {
    //   let page = this.state.currentPage;
    //   let z = 1;
    //   let a = 0;
    //   let b = 5;
    //   if (page > z) {
    //     for (z; z < page; z++) {
    //       a = a + 5;
    //       b = b + a;
    //     }
    //   }
    //   if (checked) {
    //     for (a; a < b; a++) {
    //       let temp = messageList[a].id;
    //       let checkID = false;
    //       for (let x = 0; x < id.length; x++) {
    //         if (id[x] === temp) {
    //           checkID = true;
    //         }
    //       }
    //       if (checkID === false) {
    //         id.push(messageList[a].id);
    //       }
    //     }
    //   } else {
    //     id = [];
    //   }
    // }
    this.setState({ messageList: messageList, getId: id, checkAll: checked, checkBool: !this.state.checkBool }, () => {
      let check = this.state.checkBool;
      if (check) {
        this.setState({ countCheck: 1 });
      } else {
        this.setState({ countCheck: 0 });
      }
    });
  };

  uncheck() {
    let messageList = this.state.messageList;
    for (let i = 0; i < messageList.length; i++) {
      messageList[i].isChecked = this.state.checkAll;
    }
    this.setState({ messageList: messageList });
  }

  clearCheck = () => {
    let messageList = this.state.messageList;
    for (let i = 0; i < messageList.length; i++) {
      messageList[i].isChecked = false;
    }
    this.setState({ messageList: messageList, checkAll: false, checkBool: false, countCheck: 0, getId: [] });
  };

  showModal = () => {
    this.setState({ show: true });
  };

  hideModal = () => {
    this.setState({ show: false });
  };
  closeModal = () => {
    this.setState({ selectedMessage: null, openMessageModal: false });
  };
  render() {
    const { currentPage, todosPerPage } = this.state;
    const indexOfLastTodo = currentPage * todosPerPage;
    const indexOfFirstTodo = indexOfLastTodo - todosPerPage;
    const currentTodos = this.state.messageList.slice(indexOfFirstTodo, indexOfLastTodo);

    const renderTodos = currentTodos.map((msg, index) => {
      return (
        <div key={index} className='w-100 messaging-list-detail'>
          {this.state.isLoading && <Loading></Loading>}
          <Row key={index} className='d-flex align-items-center message-divider'>
            <Col xs='1' sm='1' className='d-flex align-items-center justify-content-center p-l-0 p-r-0'>
              <label className='custom-checkbox'>
                <input type='checkbox' value={index} checked={this.isChecked(index)} onChange={() => this.onChange(index)} className='w-auto' />
                <span className='checkmark'></span>
              </label>
            </Col>
            <Col xs='11' sm='11' className='d-flex align-items-center p-l-0 p-r-0'>
              <label className='p-l-10 p-r-10'>
                <input
                  type='checkbox'
                  checked={this.isFlagChecked(index)}
                  onChange={() => this.onFlagChange(index)}
                  style={{ display: 'none' }}></input>
                <MdFlag className={`${this.isFlagChecked(index) ? 'icon-primary icon-sm' : 'icon-gray icon-sm'}`}></MdFlag>
              </label>

              <div className='' id={index} onClick={() => {this.onClickMessage(index, this.state.currentPage); this.countMsg();}}>
                <div className={`${msg.status.includes('N') ? 'font-weight-bold table-title' : 'table-title'} `}>{msg.title}</div>
                <div className={`${msg.status.includes('N') ? 'font-weight-bold ' : ''} noMessage-text table-date`}>
                  {moment(msg.createdDate).format('DD-MM-YYYY HH:mm').toString()}
                </div>
              </div>
            </Col>
          </Row>
        </div>
      );
    });

    // Logic for displaying page numbers
    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(this.state.messageList.length / todosPerPage); i++) {
      pageNumbers.push(i);
    }

    const renderPageNumbers = pageNumbers.map((number) => {
      return (
        <li key={number} id={number} onClick={this.handleClick} className='pagination-number'>
          {number}
        </li>
      );
    });
    if (this.state.messageList.length > 0) {
      return (
        <Translation>
          {(t) => (
            <div className='content container web-messaging'>
            <div className='d-none'><UnreadMsg /></div>
              {this.state.loading && <Loading></Loading>}
              <MessagingDetailModal
                messaging={this.state.selectedMessage}
                isOpen={this.state.openMessageModal}
                closeModal={this.closeModal}
                buttonText='DETAILS'
                buttonClass={'btn-secondary btn-lg'}
                onFlagChange={this.onFlagChange}>
                <div>
                  <div></div>
                </div>
              </MessagingDetailModal>

              <div className='row d-none d-md-flex justify-content-center'>
                <div className='account-col form-content'>
                  <div className='d-flex justify-content-center'>
                    <Col xs={12} md={12}>
                      <div>
                        <Row
                          className={`p-l-10 p-r-10 d-flex messaging-action ${
                            this.props.screen.viewType === 'web' ? ' p-t-5 p-b-5' : ''
                            }`}>
                          <Col xs='1' sm='1' className='d-flex align-items-center justify-content-center p-t-5 p-l-0 p-r-0'>
                            <label className='custom-checkbox'>
                              <input type='checkbox' className='check-color' onChange={this.onCheckAllChange} checked={this.state.checkAll} />
                              <span className='checkmark'></span>
                            </label>
                          </Col>
                          <Col xs='6' sm='6' className='d-flex align-items-center p-l-0 p-r-0'>
                            <div className='account-check check-color text-nowrap h5 m-l-10 n-r-10'>{t('message:message.selectAll')}</div>
                          </Col>

                          <Col xs='5' sm='5' className='d-flex align-items-center justify-content-end p-l-0 p-r-0'>
                            <div>
                              <MessagingModal
                                show={this.state.show}
                                handleClose={this.hideModal}
                                deleteMessageByBatch={this.deleteMessageByBatch}
                                msgList={this.state.countCheck}></MessagingModal>
                              <button
                                type='button'
                                onClick={this.showModal}
                                className='btn-link d-flex justify-content-center align-items-center pointer'>
                                <svg className='account-icon icon-xs'>
                                  <SVGMessage name='trashbin' />
                                </svg>
                              </button>
                            </div>
                          </Col>
                        </Row>
                        <Row className='messaging-list-part form-content'>
                          <Col xs='12' className='messaging-list'>
                            {renderTodos}
                          </Col>
                          <Col xs='12' className=''>
                            <ul id='page-numbers' className='page-numbers d-flex justify-content-center'>
                              <li onClick={() => this.backwards()} className='p-10 page-arrow-left'>
                                <IoIosArrowBack className='icon-xs account-icon' />
                              </li>
                              {renderPageNumbers}
                              <li onClick={() => this.forwards(pageNumbers.length)} className='p-10 page-arrow-right'>
                                <IoIosArrowForward className='icon-xs account-icon' />
                              </li>
                            </ul>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  </div>
                </div>
              </div>

              <div className='row d-flex d-md-none justify-content-center'>
                <div className='w-100'>
                  <div className='row d-flex justify-content-center'>
                    <div className='mobile-content-width'>
                      <Col xs={12} md={12}>
                        <div>
                          <Row className='d-flex messaging-action'>
                            <Col xs='1' sm='1' className='d-flex align-items-center justify-content-center p-t-5 p-l-0 p-r-0'>
                              <label className='custom-checkbox'>
                                <input type='checkbox' className='check-color' onChange={this.onCheckAllChange} checked={this.state.checkAll} />
                                <span className='checkmark'></span>
                              </label>
                            </Col>
                            <Col xs='6' sm='6' className='d-flex align-items-center p-l-15 p-r-0'>
                              <div className='table-title'>{t('message:message.selectAll', 'Select All')}</div>
                            </Col>

                            <Col xs='5' sm='5' className='d-flex align-items-center justify-content-end p-l-0 p-r-0'>
                              <div>
                                <MessagingModal
                                  show={this.state.show}
                                  handleClose={this.hideModal}
                                  deleteMessageByBatch={this.deleteMessageByBatch}
                                  msgList={this.state.countCheck}></MessagingModal>
                                <button
                                  type='button'
                                  onClick={this.showModal}
                                  className='btn-link d-flex justify-content-center align-items-center pointer'>
                                  <svg className='account-icon icon-xs'>
                                    <SVGMessage name='trashbin' />
                                  </svg>
                                </button>
                              </div>
                            </Col>
                          </Row>
                          <Row className='messaging-list-part'>
                            <Col xs='12' className='messaging-list'>
                              {renderTodos}
                            </Col>
                            <Col xs='12' className=''>
                              <ul id='page-numbers' className='page-numbers d-flex justify-content-center'>
                                <li onClick={() => this.backwards()} className='p-10 page-arrow-left'>
                                  <IoIosArrowBack className='icon-xs account-icon' />
                                </li>
                                {renderPageNumbers}
                                <li onClick={() => this.forwards(pageNumbers.length)} className='p-10 page-arrow-right'>
                                  <IoIosArrowForward className='icon-xs account-icon' />
                                </li>
                              </ul>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </Translation>
      );
    } else {
      return (
							<Translation>
								{(t) => (
									<div className=''>
										<div className='content container form-content d-none d-md-block message-bg'>
											<Row
												className={`p-l-10 p-r-10 messaging-action d-flex justify-content-center p-b-5 ${this.props.screen.viewType === 'web' ? ' p-t-5' : 'p-t-65'}`}>
												<Col xs='1' sm='1' className='d-flex align-items-center justify-content-center p-t-5 p-l-0 p-r-0'>
                          <label className='custom-checkbox'>
                              <input type='checkbox' className='check-color' onChange={this.onCheckAllChange} checked={this.state.checkAll} />
                              <span className='checkmark'></span>
                            </label>
												</Col>
												<Col xs='1' sm='1' className='d-flex align-items-center p-l-0 p-r-0'>
													<div className='account-check text-nowrap h5 m-l-10 n-r-10'>{t('message:message.selectAll')}</div>
												</Col>

												<Col xs='10' sm='10' className='d-flex align-items-center justify-content-end p-l-0 p-r-0'>
													<div>
														<MessagingModal show={this.state.show} handleClose={this.hideModal} deleteMessageByBatch={this.deleteMessageByBatch}></MessagingModal>
														<button type='button' onClick={this.showModal} className='btn-link d-flex justify-content-center align-items-center'>
															<svg className='account-icon icon-xs'>
																<SVGMessage name='trashbin' />
															</svg>
														</button>
													</div>
												</Col>
											</Row>
											<Row className='no-Data'>
												{this.state.messageList.length} {t('global:global.menu.account.message', 'message(s)')}
											</Row>
										</div>
										<div className='content container d-block d-md-none'>
											<div className='content-inner'>
												<Row className={`messaging-action d-flex justify-content-center  ${this.props.screen.viewType === 'web' ? ' ' : ''}`}>
													<Col xs='1' sm='1' className='d-flex align-items-center p-l-0 p-r-0'>
														<input type='checkbox' className='account-check text-no-wrap' onChange={this.onCheckAllChange} checked={this.state.checkAll} />
													</Col>
													<Col xs='9' sm='9' className='d-flex align-items-center p-l-0 p-r-0'>
														<div className='account-check h5 m-l-10 n-r-10'>{t('message:message.selectAll')}</div>
													</Col>

													<Col xs='2' sm='2' className='d-flex align-items-center justify-content-end p-l-0 p-r-0'>
														<div>
															<MessagingModal show={this.state.show} handleClose={this.hideModal} deleteMessageByBatch={this.deleteMessageByBatch}></MessagingModal>
															<button type='button' onClick={this.showModal} className='btn-link d-flex justify-content-center align-items-center'>
																<svg className='account-icon icon-xs'>
																	<SVGMessage name='trashbin' />
																</svg>
															</button>
														</div>
													</Col>
												</Row>
												<Row className='no-Data '>
													{this.state.messageList.length} {t('global:global.menu.account.message', 'message(s)')}
												</Row>
											</div>
										</div>
									</div>
								)}
							</Translation>
						);
    }
  }
}

export default connect(mapStateToProps, null)(withTranslation(['message'])(withRouter(Messaging)));
