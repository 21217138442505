// react / library
import React, { Component } from 'react';
import { Translation, withTranslation, Trans, useTranslation } from 'react-i18next';
import { connect, useSelector } from 'react-redux';
import { mapStateToProps } from 'root/redux/selector/selector';
import { withRouter } from 'react-router-dom';
import { MdClose } from 'react-icons/md';

// components / selector
import PopupWrapper from '../../../../../Share/popup/popup-wrapper/popup-wrapper';

// util / controller
import vm from '../email-verification-dialog.controller';
import AuthSettings from '@components/molecules/auth-settings/auth-settings';
import { gu_contactOnClick } from 'root/utils/general-util';
import { MODULE_TYPE as MT } from '@utils/constants';
import commonUtil from 'root/utils/common-util';

class UserVerificationDialog extends Component {
    constructor(props) {
        super(props);
        vm.init(this);
    }

    componentDidMount() {
        vm.getMemberEmail();
        vm.checkEmailStatus();
        vm.getMemberContact();
    }

    componentWillUnmount() {
        this.setState = () => {
            return;
        };
    }

    render() {
        // redux
        const { screen, authSettingsReducer } = this.props;
        // passed props
        const { isOpen } = this.props;
        // passed props function
        const { togglePopup, checkVerification } = this.props;
        // state
        const { step, inputFields, submitInProgress, errors, sendOtpCount } = this.state;

        const filterAuthMethod = commonUtil.filterAuthMethod(authSettingsReducer, 'USER_VERIFICATION');

        return (
            <Translation>
                {(t) => (
                    <PopupWrapper
                        isOpen={isOpen}
                        overlayClassName={`${screen.isMobile ? 'm-' : ''}email-verify-popup-overlay`}
                        className={`${screen.isMobile ? 'm-' : ''}email-verify-popup-container`}
                        closeFunc={() => {
                            if (step === 'SUCCEED' && checkVerification) {
                                checkVerification();
                            }
                            if (togglePopup) {
                                togglePopup('email');
                            }
                        }}
                    >
                        <AuthSettings module={MT?.USER_VERIFICATION} isPublic={false} isNew={true} />
                        <div className='standard-popup-modal-header email-verify-popup-header'>
                            <div className='standard-modal-title'>{t('global:global.verification.verifyYourEmail', 'Verify Your Email Address')}</div>
                            <div
                                className='standard-modal-close'
                                onClick={() => {
                                    if (step === 'SUCCEED' && checkVerification) {
                                        checkVerification();
                                    }
                                    if (togglePopup) {
                                        togglePopup('email');
                                    }
                                }}
                            >
                                <MdClose />
                            </div>
                        </div>
                        <div className={`standard-popup-modal-body email-verify-popup-body ${step === 'SUCCEED' || step === 'FAILED' ? 'status-body' : ''}`}>
                            <div className='popup-body-content'>
                                {/***********  INPUT EMAIL ***********/}
                                {step === 'INPUT_EMAIL' && (
                                    <div className='content-wrapper email-content-wrapper'>
                                        <div className='modal-desc'>{t('global:global.verification.verifyEmaildesc')}</div>
                                        <div className='standard-form-field email-field'>
                                            <input
                                                className='standard-input email-input'
                                                type='text'
                                                autoComplete='off'
                                                placeholder={t('global:global.form.email-placeholder')}
                                                value={this.state.inputEmail}
                                                onChange={(e) => vm.handleFieldChange(e.target.value, 'email')}
                                            />
                                        </div>
                                        <div className='verify-email-button-container'>
                                            <button
                                                className='standard-button standard-modal-submit-button'
                                                onClick={() => vm.preSendVerificationCode('email')}
                                                disabled={!inputFields['email'] || submitInProgress || errors['email']}
                                            >
                                                <span> {t('settings:settings.memberVerificationForm.submit', 'Submit')}</span>
                                            </button>
                                        </div>

                                        {errors['email'] && (
                                            <div className='failed-container'>
                                                <div className='icon-wrapper'>
                                                    <i className='icon-notice-important'></i>
                                                </div>
                                                <div className='standard-error-validation'>{t(errors['email'], errors['email'])}</div>
                                                <div className='modal-desc'>
                                                    {t(
                                                        'settings:settings.memberVerificationForm.emailForm.contactCS',
                                                        'Please contact customer service to verify your email address.'
                                                    )}
                                                </div>
                                                <div className='contact-button-container'>
                                                    <button className='standard-button contact-button' onClick={() => gu_contactOnClick(this.state.liveChatUrl)}>
                                                        <span>{t('global:global.verification.contactCS', 'Contact Customer Service')}</span>
                                                    </button>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                )}

                                {/***********  ENTER OTP ***********/}
                                {step === 'ENTER_OTP' && (
                                    <>
                                        {/********** OTP FIELD ***********/}
                                        <div className='content-wrapper otp-content-wrapper'>
                                            <div className='modal-desc'>{t('global:global.verification.enterEmailOTPCodeDescription', { email: this.state.maskedEmail })}</div>

                                            <div className='standard-form-field otp-field'>
                                                <input
                                                    type='text'
                                                    className='standard-input otp-input'
                                                    placeholder={t('settings:settings.memberVerificationForm.enterOTPCode')}
                                                    value={inputFields['otp'] || ''}
                                                    onChange={(e) => vm.handleFieldChange(e.target.value, 'otp')}
                                                />
                                            </div>

                                            <div className='send-and-resend-button-container'>
                                                <button
                                                    className='standard-button standard-modal-submit-button'
                                                    onClick={() => vm.submitEmailVerification()}
                                                    disabled={!inputFields['otp'] || submitInProgress}
                                                >
                                                    <span>{t('transaction:transaction.deposit.localPay.submit', 'Submit')}</span>
                                                </button>
                                            </div>

                                            {errors['otp'] && <div className='standard-error-validation'>{t(errors['otp'])}</div>}

                                            <OtpNote verifyAttemptCount={this.state.verifyAttemptCount} sendOtpCount={sendOtpCount} />

                                            {errors['email'] && (
                                                <div className='standard-error-validation'>
                                                    <span>{t(errors['email'], errors['email']) + ' '}</span>
                                                    <span>
                                                        {t(
                                                            'settings:settings.memberVerificationForm.emailForm.contactCS',
                                                            'Please contact customer service to verify your email address.'
                                                        )}
                                                    </span>
                                                </div>
                                            )}
                                        </div>
                                    </>
                                )}

                                {/***********  SUCCEED ***********/}
                                {step === 'SUCCEED' && (
                                    <div className='content-wrapper success-content-wrapper'>
                                        <div className='standard-modal-title success-title'>
                                            {t('global:global.verification.yourEmailVerified', 'Your email has been verified.')}
                                        </div>
                                        <div className='modal-desc'>
                                            <Trans i18nKey='global:global.verification.verifiedEmailSuccessMsg' />
                                            <Trans i18nKey='global:global.verification.verifiedEmailSuccessMsg2' />
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </PopupWrapper>
                )}
            </Translation>
        );
    }
}
export default connect(mapStateToProps)(withTranslation(['global', 'settings', 'otp'])(withRouter(UserVerificationDialog)));

/**
 *
 * @param {String} sendOtpCount
 * @returns
 */
export const OtpNote = ({ sendOtpCount, verifyAttemptCount }) => {
    const { t } = useTranslation('otp'); //TODO: convert all to use specific page (e.g. bank-detail.json)

    // redux
    const authSettingsReducer = useSelector((state) => state.authSettingsReducer);
    const filterAuthMethod = commonUtil.filterAuthMethod(authSettingsReducer, 'USER_VERIFICATION');
    const _isEnableNewOtp = filterAuthMethod?.includes('EMAIL');

    return (
        <>
            <div className='otp-note'>
                {!_isEnableNewOtp && (
                    <>{sendOtpCount > 3 && <span>{t('global:global.verification.maxAttempts', 'Error validate the number because too many attempts today.')}</span>}</>
                )}
                {_isEnableNewOtp && <span>{t('otp:otp.request.message.requestAnotherCode', { attemptCount: verifyAttemptCount })}</span>}
            </div>
        </>
    );
};
