import React from 'react';

const getViewBox = name => {
  switch (name) {
    // Header
    case 'copy':
      return '0 0 32.39 40';
    case 'hide':
      return '0 0 40 32';

    default:
      return '0 0 40 40';
  }
};

const getPath = (name, props) => {
  switch (name) {
    // Header
    case 'copy':
      return (
        <path
          {...props}
          d='M26.38 14.33l-7.85-7.84a1.12 1.12 0 0 0-.53-.32H1.2A1.2 1.2 0 0 0 0 7.35V38.8A1.2 1.2 0 0 0 1.2 40h24.31a1.21 1.21 0 0 0 1.21-1.2V15.15a1.15 1.15 0 0 0-.34-.82zm-7.64-4.82l4.66 4.65h-4.66zm6 28.5H2V8.13h14.76v7a1 1 0 0 0 1 1h7zm7.31-29.83L24.21.34a1.19 1.19 0 0 0-.57-.34 1 1 0 0 0-.25 0H6.88a1.21 1.21 0 0 0-1.21 1.2v6.93h2V2h14.76v7a1 1 0 0 0 1 1h7v21.87h-5.7v2h6.46a1.2 1.2 0 0 0 1.2-1.2V9a1.16 1.16 0 0 0-.34-.82zM24.42 8V3.36L29.07 8z'
        />
      );
    case 'hide':
      return (
        <path
          {...props}
          d='M35.56.43a1.47 1.47 0 0 0-2.08 0l-5.22 5.23c-6.48-2.94-13.38-2.22-20 2.08a33.56 33.56 0 0 0-8 7.39 1.5 1.5 0 0 0 0 1.8 36.18 36.18 0 0 0 8.71 8l-4.6 4.59a1.48 1.48 0 0 0 0 2.08 1.46 1.46 0 0 0 1 .44 1.44 1.44 0 0 0 1-.44l29.06-29a1.48 1.48 0 0 0 0-2.08zm-19.2 17.12a3.87 3.87 0 0 1-.31-1.55 3.92 3.92 0 0 1 1.15-2.8 4 4 0 0 1 4.36-.84zm7.37-7.36a6.91 6.91 0 0 0-8.6.93 6.86 6.86 0 0 0-.92 8.59l-3 3A30.76 30.76 0 0 1 3.38 16a31.47 31.47 0 0 1 6.52-5.81C15.33 6.65 20.75 5.88 26 7.9zM39.68 15.07a39.82 39.82 0 0 0-6.23-6.23 1.47 1.47 0 1 0-1.82 2.32 34.73 34.73 0 0 1 5 4.85 32.37 32.37 0 0 1-5.38 5c-4.91 3.56-9.86 4.85-14.72 3.83a1.47 1.47 0 0 0-.61 2.87 18.29 18.29 0 0 0 3.82.4 19.29 19.29 0 0 0 5.81-.91A25.8 25.8 0 0 0 33 23.34a34.19 34.19 0 0 0 6.68-6.48 1.49 1.49 0 0 0 0-1.79z'
        />
      );

    default:
      return <path />;
  }
};

const SVGPassword = ({
  name = '',
  style = {},
  fill = '',
  viewBox = '',
  width = '100%',
  className = '',
  height = '100%'
}) => (
  <svg
    width={width}
    style={style}
    height={height}
    className={className}
    xmlns='http://www.w3.org/2000/svg'
    viewBox={viewBox || getViewBox(name)}
    xmlnsXlink='http://www.w3.org/1999/xlink'
  >
    {getPath(name, { fill })}
  </svg>
);

export default SVGPassword;
