import React, { Component } from 'react';
import { Translation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { mapStateToProps } from 'root/redux/selector/selector';
import { withRouter } from 'react-router-dom';
import SVGElements from 'root/components/SVGElements';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import moment from 'moment';
import Countdown, { zeroPad } from 'react-countdown-now';
import commonUtil from 'root/utils/common-util';

class MsgPopUp extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        commonUtil.loadCustomTranslation('MessagePopUp', this, function () {});
    }

    render() {
        const { toggleDetailModal, toggleFormModal, msgTitle, msgContent, noticeContent, loginModal, promoContent, renderCountdown } = this.props.modalMsg;

        return (
            <Translation>
                {(t) => (
                    <div className='messaging-popup'>
                        <div className='popup'>
                            <div className='popup-header'>
                                <div className='popup-header-left'>
                                    <div className='popup-title'>{t(msgTitle)}</div>
                                </div>
                                <div className='popup-header-right'>
                                    <SVGElements name='close-icon' onClick={toggleDetailModal} />
                                </div>
                            </div>
                            <div className={`popup-body ${this.props.history.location.pathname.includes('/promotion') && 'body-flow'}`}>
                                {!loginModal && noticeContent ? (
                                    <ol className='noticeText-box'>
                                        {msgContent.map((info) => (
                                            <li key={info} dangerouslySetInnerHTML={{ __html: `${info} <hr />` }}></li>
                                        ))}
                                    </ol>
                                ) : (
                                    msgContent && (
                                        <>
                                            {promoContent && (
                                                <div className='top-content'>
                                                    <div className='promotion-container' key={promoContent.id}>
                                                        {promoContent && promoContent.image && promoContent.image.length > 0 && (
                                                            <div className='promotion-image'>
                                                                <LazyLoadImage src={promoContent.image} alt='promotion' />
                                                            </div>
                                                        )}
                                                        {!(promoContent && promoContent.image && promoContent.image.length > 0) && (
                                                            <div className='promotion-image'>
                                                                <div className='w-100'></div>
                                                            </div>
                                                        )}
                                                        <div className='promotion-content-detail'>
                                                            <div className='promotion-text-container'>
                                                                <div className='promotion-content-title'>{promoContent.title}</div>
                                                                <span className=' promotion-content-detail-desc'>{promoContent.description}</span>
                                                            </div>
                                                            <div className='promotion-btn-container'>
                                                                <div className='promotion-btn-group'>
                                                                    <button
                                                                        className={`btn-normal btn-promoapply ${promoContent.isAllowApply ? 'apply-show' : 'apply-hide'} `}
                                                                        onClick={() => toggleFormModal(promoContent)}
                                                                    >
                                                                        {t('promotion:promotion.apply')}
                                                                    </button>
                                                                </div>

                                                                {promoContent.isRemainingTime && (
                                                                    <div className='countdown-container'>
                                                                        <p className='remaining-time'>{t('promotion:promotion.remainingTime', 'Remaining Time')} : </p>

                                                                        {promoContent.remainingUnlimited ? (
                                                                            <div className='countdown-container unlimited-text'>
                                                                                {t('promotion:promotion.unlimited', 'Unlimited')}
                                                                            </div>
                                                                        ) : (
                                                                            <Countdown
                                                                                props={this}
                                                                                date={Date.now() + moment(new Date(promoContent.endDate)).diff(moment(Date.now()))}
                                                                                renderer={(props) => renderCountdown(props)}
                                                                            />
                                                                        )}
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                            <div className='bottom-content' dangerouslySetInnerHTML={{ __html: msgContent }}></div>
                                        </>
                                    )
                                )}
                                {loginModal && (
                                    <span className='btn-normal btn-body' onClick={toggleDetailModal}>
                                        {t('login:login.ok', 'ok')}
                                    </span>
                                )}
                            </div>
                        </div>
                        <div className='popup-overlay' onClick={toggleDetailModal}></div>
                    </div>
                )}
            </Translation>
        );
    }
}

export default connect(mapStateToProps)(withTranslation('login')(withRouter(MsgPopUp)));
