import React, { Component } from 'react';
import { Modal, ModalHeader, ModalFooter } from 'reactstrap';
import { Translation, withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { mapStateToProps } from '../../redux/selector/selector';

class LoginModal extends Component {
  isMount = true;
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
    };
  }

  handleOpenModal = () => {
    this.setState({ showModal: true });
  };

  render() {
    // const overlayClassName = this.props.visible ? 'modal fade show' : 'modal fade';
    return (
      <Translation>
        {(t) => (
          <div className='overflow-hidden'>
            <Modal
              className='d-flex justify-content-center align-items-center flex-column'
              bodyopenclassname='modal-open'
              isOpen={this.props.visible}>
              <ModalHeader className='modal-header w-100'>
                <div className='text-nowrap'>{t('login:login.proceed')}</div>
              </ModalHeader>
              <ModalFooter className='w-100 h-auto p-15 modal-login-content d-flex justify-content-center'>
                <button className='primary-btn' onClick={this.props.handleOk}>
                  <span>{t('login:login.ok')}</span>
                </button>
              </ModalFooter>
            </Modal>
          </div>
        )}
      </Translation>
    );
  }
}

export default connect(mapStateToProps)(withTranslation(['login'])(withRouter(LoginModal)));
