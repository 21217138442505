import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { mapStateToProps } from 'root/redux/selector/selector';
import { Translation, withTranslation } from 'react-i18next';

// component / file
import ImageHelper from 'root/pages/Share/image-helper';

// util / controller

class SelectDropdown extends React.Component {
    props = null;

    constructor(props) {
        super();
        this.props = props;
        this.state = {};
    }

    componentWillUnmount() {
        this.setState = () => {
            return;
        };
    }

    render() {
        {
            /* 
            Standard select-dropdown structure
            Params:
            classname       - (String) append classname
            optionLabel     - (String) displayed option

            onClickFunction - function on each item selected 
        */
        }
        // global props
        // passed props
        const { className, optionLabel, underMaintenance, svg, customStyle } = this.props;
        // passed props func
        const { onClickFunction } = this.props;

        return (
            <Translation>
                {(t) => (
                    <div
                        className={`standard-select-options ${className} ${svg ? 'include-svg' : ''}`}
                        onClick={onClickFunction && onClickFunction}
                        style={customStyle && customStyle}
                    >
                        {svg && <ImageHelper displaySVG={svg.toUpperCase()} className={`${svg.toUpperCase()} sub-icon`} isSVG={true} svgType='SVGProviderLandingBeta' />}

                        <p>{optionLabel}</p>

                        {underMaintenance && <ImageHelper displaySVG='beta-maintenance-bg-icon' className='beta-maintenance-bg-icon' svgType='SVGCommon' isSVG={true} />}
                    </div>
                )}
            </Translation>
        );
    }
}

export default connect(mapStateToProps, null)(withTranslation()(withRouter(SelectDropdown)));
