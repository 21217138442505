import React from "react";
import { CSSTransition } from "react-transition-group";
import SVGElements from 'root/components/SVGElements';

export default class NewGame extends React.Component{
    timer;
    constructor(props){
        super(props);

        this.state = {in: true};
    }

    handle(){
        clearTimeout(this.timer);
        this.setState({in: false});
    }

    componentDidMount(){
        this.timer = setTimeout(() => {
            this.setState({in: false});
        }, 10000);
    }

    componentWillUnmount(){
        clearTimeout(this.timer);
    }

    render(){
        return (
            <CSSTransition
                classNames="new-games"
                in={this.state.in}
                timeout={300}
                unmountOnExit
                >
                <div className="new-games-released">
                    <div className="top">
                        <div className="icon-box">
                          <i className="icon-start"></i>
                        </div>
                        <span>New Games Released</span>
                        <SVGElements className="icon-close" name='close-icon' onClick={this.handle.bind(this)} />
                        {/* <i className="icon-close" onClick={this.handle.bind(this)}></i> */}
                    </div>
                    <div className="content">
                        <img src={"assets/new_game.png"} alt=""/>
                        <div className="right">
                            <p>Grim Muerto</p>
                            <div>
                                
                                The special features and 
                                <br/>colourful graphic in 
                                <br/>this game promises an 
                                <br/>incredible journey.
                                
                            </div>
                        </div>
                    </div>
                </div>
            </CSSTransition>
        );
    }
}