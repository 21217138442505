const controller = {
    vm: null,

    init(vm) {
        this.vm = vm;

        let currentDate = new Date();
        let startDate = new Date(currentDate.getTime() - 6 * 24 * 3600000);
        let endDate = currentDate;

        this.vm.state = {
            method: 'rebate',
            provider: [],
            pendingRebate: [],
            pendingRebateTransaction: [],
            lastRebateDate: '-',
            lastClaimDate: '-',
            isLoading: false,
            histories: [],

            sorting: {
                createdDate: 'desc',
            },
            currentSort: 'createdDate,desc',

            fields: {
                startDate: startDate,
                endDate: endDate,
            },

            currentPage: 1,
            itemPerPage: 5,
            totalCount: 0,
            totalPage: 0,
            totalPendingClaim: 0,
            pagingList: [],

            searchDialogIsShow: false,
            historyDetailDialogIsShow: false,
            displayList: [],
            date: {
                active: 2,
                list: [
                    'transaction:transaction.history.filter.today',
                    'transaction:transaction.history.filter.3days',
                    'transaction:transaction.history.filter.aweek',
                    'transaction:transaction.history.filter.amonth',
                ],
            },

            //claim all status
            isCompleted: false,
            isGenerating: false,
            isError: false,
            disableClaim: false,
            showHistoryPopup: false,
            showMore: false,
            showMoreData: [],
            selectedHistory: [],
        };
    },

    onMethodChange(method) {
        controller.vm.setState({ method: method });
    },

    getPendingRebateTransaction() {
        let loadPendingMemberRebateTransaction = new Promise((resolve) => {
            window.SPL_Transaction.getPendingMemberRebateTransaction().then((data) => resolve(data));
        });
        let loadMemberLastRebateTransaction = new Promise((resolve) => {
            window.SPL_Transaction.getLastTransactionDateTime().then((time) => resolve(time));
        });
        let loadInstantRebateProviderCategory = new Promise((resolve) => {
            window.SPL_JsonSettings.getInstantRebateProviderCategoryJson((cat) => resolve(cat));
        });

        Promise.all([loadPendingMemberRebateTransaction, loadMemberLastRebateTransaction, loadInstantRebateProviderCategory]).then((result) => {
            let [pendingRebate, lastRebateTransaction, providerCategory] = result;
            let { totalPendingClaim } = controller.vm.state;
            let { pendingRebateTransaction } = controller.vm.state;
            let lastRebateDate = '-';
            let lastClaimDate = '-';
            let historyKey = [];

            if (pendingRebate) {
                if (providerCategory && providerCategory.providerCategory) {
                    pendingRebate = controller.massageCorrectProvider(pendingRebate, providerCategory.providerCategory);
                }

                let rebateProvider = pendingRebate.provider;
                //for hide when rebate earn is 0
                pendingRebateTransaction = pendingRebate.pendingRebateData.filter((rebate) => {
                    return Number(rebate.rebateAmount) - Number(rebate.releasedAmount) > 0;
                });

                //total claim amount
                totalPendingClaim = pendingRebateTransaction.map((data) => data.rebateAmount).reduce((prev, curr) => prev + curr, 0);

                //to total up duplicate product, provider and rate
                for (let i = 0; i < pendingRebateTransaction.length; i++) {
                    let res = {
                        memberGroupCode: pendingRebateTransaction[i].memberGroupCode,
                        product: pendingRebateTransaction[i].product,
                        provider: pendingRebateTransaction[i].provider,
                        rate: pendingRebateTransaction[i].rate,
                        rebateAmount: 0,
                        totalEligibleTurnOver: 0,
                    };

                    for (let j = 0; j < pendingRebateTransaction.length; j++) {
                        if (
                            pendingRebateTransaction[i].memberGroupCode === pendingRebateTransaction[j].memberGroupCode &&
                            pendingRebateTransaction[i].product === pendingRebateTransaction[j].product &&
                            pendingRebateTransaction[i].rate === pendingRebateTransaction[j].rate &&
                            pendingRebateTransaction[i].provider === pendingRebateTransaction[j].provider
                        ) {
                            res.rebateAmount = res.rebateAmount + pendingRebateTransaction[j].rebateAmount;

                            if (pendingRebateTransaction[j].includePromoTurnover) {
                                res.totalEligibleTurnOver += pendingRebateTransaction[j].totalValidTurnOver;
                            } else {
                                res.totalEligibleTurnOver += pendingRebateTransaction[j].totalValidTurnOver - pendingRebateTransaction[j].totalPromoTurnOver;
                            }
                        }
                    }
                    historyKey.push(res);
                }

                //massage history duplicate data
                pendingRebateTransaction = [...new Set(historyKey.map((d) => d.product))].map((product) => {
                    let filterProduct = historyKey.filter((d) => d.product === product).map((d) => d);
                    let filterProvider = filterProduct.filter((current, index) => {
                        return (
                            filterProduct.findIndex(
                                (user) => user.provider === current.provider && user.memberGroupCode === current.memberGroupCode && user.rate === current.rate
                            ) === index
                        );
                    });
                    return {
                        product: product,
                        productHistories: filterProvider,
                    };
                });

                //massage date
                if (lastRebateTransaction) {
                    lastRebateTransaction = JSON.parse(lastRebateTransaction);
                    lastRebateDate =
                        new Date(lastRebateTransaction.lastGenerateTime).toLocaleDateString('zh-Hans-CN') +
                        ' ' +
                        new Date(lastRebateTransaction.lastGenerateTime).toLocaleTimeString('it-IT');
                    if (lastRebateTransaction.lastClaimTime) {
                        lastClaimDate =
                            new Date(lastRebateTransaction.lastClaimTime).toLocaleDateString('zh-Hans-CN') +
                            ' ' +
                            new Date(lastRebateTransaction.lastClaimTime).toLocaleTimeString('it-IT');
                    }
                }

                controller.vm.setState({
                    provider: rebateProvider,
                    pendingRebate: pendingRebate.pendingRebateData,
                    pendingRebateTransaction: pendingRebateTransaction,
                    totalPendingClaim,
                    lastRebateDate: lastRebateDate,
                    lastClaimDate: lastClaimDate,
                });
            }
        });
    },

    massageCorrectProvider(providerList, categoryList) {
        if (providerList && providerList.pendingRebateData && categoryList) {
            let rebateData = providerList.pendingRebateData;
            for (let i = 0; i < rebateData.length; i++) {
                if (categoryList[rebateData[i].provider] && categoryList[rebateData[i].provider][rebateData[i].product]) {
                    rebateData[i].product = categoryList[rebateData[i].provider][rebateData[i].product]
                        ? categoryList[rebateData[i].provider][rebateData[i].product]
                        : rebateData[i].product;
                }
            }
        }
        return providerList;
    },

    handleShowMore(historyList, product) {
        controller.vm.setState({ showMore: product, showMoreData: historyList });
    },

    handleClaimSubmit() {
        const { pendingRebate } = controller.vm.state;
        let arrayList = [];

        for (let i = 0; i < pendingRebate.length; i++) {
            let item = {};
            item.id = pendingRebate[i].id;
            item.memberRebateTransactionId = pendingRebate[i].memberRebateTransactionId;
            arrayList.push(item);
        }
        let param = {
            jsonData: JSON.stringify(arrayList),
        };

        controller.vm.setState({ disableClaim: true }, () => {
            window.SPL_Transaction.claimAllRebateTransactions(param).then((data) => {
                if (data) {
                    controller.getMemberInstantClaimStatus();
                }
            });
        });
    },

    getMemberInstantClaimStatus() {
        const onSuccess = (response) => {
            const vm = controller.vm;
            const { status, error } = response.data;

            const statusAction = (timer, trueState, callback) => {
                vm.setState(trueState);
                setTimeout(() => {
                    callback();
                }, timer);
            };

            if (status === 'C') {
                const timer = 10000;
                if (error) {
                    statusAction(timer, { isError: true }, () => {
                        vm.setState({ isError: false });
                    });
                } else {
                    statusAction(timer, { isCompleted: true, disableClaim: false }, () => {
                        vm.setState({ isCompleted: false });
                    });
                }

                window.location.reload();
            } else {
                statusAction(20000, { isGenerating: true }, () => {
                    controller.getMemberInstantClaimStatus();
                });
            }
        };
        window.SPL_Transaction.getMemberInstantClaimStatus(onSuccess);
    },
};

export default controller;
