import React from "react";
import { CSSTransition } from "react-transition-group";
import SVGElements from 'root/components/SVGElements';

export default class Ad extends React.Component {
  timer = null
  constructor(props) {
    super(props);

    this.state = { in: true };
  }

  componentDidMount() {
    this.timer = setTimeout(() => {
      this.setState({ in: false });
    }, 10000);
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  handle() {
    clearTimeout(this.timer);
    this.setState({ in: false });
  }
  render() {
    return (
      <CSSTransition
        classNames="fade"
        in={this.state.in}
        timeout={300}
        unmountOnExit
      >
        <div className="ad-box">
          <div className="content-container">
            <SVGElements className="icon-close" name='close-icon' onClick={this.handle.bind(this)} />
            {/* <i className="icon-close" onClick={this.handle.bind(this)}></i> */}
          </div>

          <img src={"assets/banner/banner4.png"} alt="" />
        </div>
      </CSSTransition>
    );
  }
}