import { trans_handlePGDepositCountDown } from 'root/utils/transaction-util';
import notification from 'root/utils/notification';
import { gu_getNestedValue, gu_getSettingFromPortalSettings } from '../../../utils/general-util';
import commonUtil from '../../../utils/common-util';
import { scrollToDepositStep2 } from '../deposit-util';

const controller = {
    vm: null,
    optionCode: 'BQR',

    init(vm) {
        controller.vm = vm;

        controller.vm.state = {
            depositAllSettings: controller.vm.props.depositAllSettings,
            filteredDepositApiJson: controller.vm.props.filteredDepositApiJson,
            isLoading: false,
            channels: [],
            banks: [],
            selectedChannel: {},
            selectedBank: null,
            fields: {
                amount: '',
            },
            errors: {},
            limitAmount: '',
            depositMinMaxLimit: null,
            depositSubmitCountdown: 0,

            compSettings: {},
        };
    },

    initCompSettings() {
        trans_handlePGDepositCountDown(controller.vm, false, 60, controller.optionCode, Date.now().valueOf());

        return new Promise((resolve) => {
            // Step 1: Standard way to get from portal settings
            let tempSettings = gu_getSettingFromPortalSettings({
                vm: controller.vm,
                settingName: 'bankQRSettings',
                isCurrencyLang: true,
            });

            let compSettings = {
                hideDisplayInfo: tempSettings.hideDisplayInfo,
                showCustomArticle: tempSettings.showCustomArticle,
                enableCustomArticleJSON: tempSettings.enableCustomArticleJSON,
                isCustomArticlePath: tempSettings.isCustomArticlePath,
            };

            controller.vm.setState({ compSettings }, () => {
                const { showCustomArticle } = controller.vm.state.compSettings;
                if (showCustomArticle) {
                    controller.readCustomDepositArticle();
                }
                resolve();
            });
        });
    },

    readCustomDepositArticle() {
        const { compSettings } = controller.vm.state;
        const customFilename = compSettings && compSettings.isCustomArticlePath ? controller.optionCode : '';
        commonUtil.readCustomDepositArticle(controller.vm, customFilename);
    },

    getBankQRChannels() {
        return new Promise(function (resolve) {
            const vm = controller.vm;
            const { filteredDepositApiJson } = vm.state;

            if (filteredDepositApiJson && filteredDepositApiJson.optionCode === controller.optionCode && filteredDepositApiJson.channel) {
                const supportedChannels = filteredDepositApiJson.channel;
                let channels = [];
                let selectedChannel = {};

                // For loop the channel list
                // get channel's detail by index 0, currently only have 1 bank inside the channel
                for (let channel in supportedChannels) {
                    let channelDetail = supportedChannels[channel] && supportedChannels[channel][0];
                    channelDetail.displayName = window.SPL_Content.getBankName(channelDetail, 'channel');
                    channels.push(channelDetail);
                }

                if (channels.length > 0) {
                    // get default channel
                    selectedChannel = channels[0];
                }

                controller.vm.setState({ channels: channels, selectedChannel: selectedChannel }, () => {
                    resolve();
                });
            }
        });
    },

    getSupportedBanks(isInit = false) {
        const vm = controller.vm;
        const { paymentGatewaySetting } = vm.props;
        const { selectedChannel } = vm.state;

        // member-banks/third-party (filter by member withdrawal banks, need member have related withdrawal bank only got results)
        // member/get-supported-banks (direct show all supported banks)
        controller.vm.setState({ isLoading: true });

        let stateToUpdate = {
            isLoading: false,
        };
        if (paymentGatewaySetting && paymentGatewaySetting.bankQRWithMemberBank && paymentGatewaySetting.bankQRWithMemberBank.includes(selectedChannel.code)) {
            window.SPL_Transaction.getMemberThirdPartyBank(selectedChannel.id).then((data) => {
                if (data && data.length > 0) {
                    const massageBanks = data.map((bank) => {
                        return {
                            ...bank,
                            displayName: window.SPL_Content.getBankName(bank.bankCode, 'bank', bank.bankName),
                        };
                    });
                    stateToUpdate = {
                        ...stateToUpdate,
                        banks: massageBanks,
                    };
                    if (isInit) {
                        const disablePreselectBank = !!controller.vm.props.portal?.settings?.features?.depositSettings?.disablePreselectBank;
                        stateToUpdate = {
                            ...stateToUpdate,
                            selectedBank: disablePreselectBank ? null : massageBanks && massageBanks[0],
                        };
                    }
                }
                vm.setState(stateToUpdate);
            });
        } else {
            window.SPL_Transaction.loadSupportedBanks(selectedChannel, vm.props).then((data) => {
                if (data && data.banks) {
                    const massageBanks = data.banks.map((bank) => {
                        return {
                            ...bank,
                            displayName: window.SPL_Content.getBankName(bank.code, 'bank', bank.name),
                        };
                    });

                    stateToUpdate = {
                        ...stateToUpdate,
                        banks: massageBanks,
                    };
                    if (isInit) {
                        const disablePreselectBank = !!controller.vm.props.portal?.settings?.features?.depositSettings?.disablePreselectBank;
                        stateToUpdate = {
                            ...stateToUpdate,
                            selectedBank: disablePreselectBank ? null : massageBanks && massageBanks[0],
                        };
                    }
                }
                vm.setState(stateToUpdate);
            });
        }
    },

    getCorrectDepositMinMaxLimit() {
        const { selectedChannel } = controller.vm.state;

        // set depositMinMaxLimit: {}
        commonUtil.getDepositMinMax(
            controller.vm,
            controller.optionCode,
            controller.vm.props.user.account.currency,
            selectedChannel && selectedChannel.code,
            null,
            null,
            controller.validateDepositAmount
        );
    },

    onChannelChange(channel) {
        const { screen } = controller.vm.props;
        const { selectedChannel } = controller.vm.state;

        // mobile's select Tag make it become array
        channel = screen.isMobile ? channel[0] : channel;

        if (selectedChannel !== channel) {
            controller.vm.setState({ selectedChannel: channel }, () => {
                controller.getCorrectDepositMinMaxLimit();
                controller.getSupportedBanks();
            });
        }
    },

    onBankChange(bank) {
        const { selectedBank } = controller.vm.state;
        if (selectedBank !== bank) {
            controller.vm.setState({ selectedBank: bank }, () => {
                const {
                    enableScrollToDepositStep2 = false,
                    scrollToDepositStep2Attempts = 15,
                    scrollToDepositStep2Delay = 200,
                } = controller.vm.props.portal?.settings?.features?.depositSettings || {};

                scrollToDepositStep2(enableScrollToDepositStep2, selectedBank, bank, scrollToDepositStep2Attempts, scrollToDepositStep2Delay);
            });
        }
    },

    handleAmountChange(value) {
        let { fields } = controller.vm.state;

        if (value && value.toString().match(/^(\d*)\.{0,1}(\d){0,2}$/)) {
            fields['amount'] = value;
        } else {
            fields['amount'] = '';
        }
        controller.vm.setState({ fields: fields }, () => {
            controller.validateDepositAmount();
        });
    },

    validateDepositAmount() {
        const { depositMinMaxLimit } = controller.vm.state;
        let { fields, limitAmount } = controller.vm.state;

        let errors = {};
        if (!fields['amount']) {
            errors['amount'] = 'transaction:transaction.deposit.onlinetransfer.invalidAmount';
        }

        if (fields['amount']) {
            let amount = Number(fields['amount']);
            if (amount >= 0) {
                const minLimit = depositMinMaxLimit ? depositMinMaxLimit.min : 0;
                const maxLimit = depositMinMaxLimit ? depositMinMaxLimit.max : 0;
                if (amount < minLimit) {
                    errors['amount'] = 'transaction:transaction.deposit.onlinetransfer.invalidMinAmount';
                    limitAmount = minLimit;
                } else if (amount > maxLimit) {
                    errors['amount'] = 'transaction:transaction.deposit.onlinetransfer.invalidMaxAmount';
                    limitAmount = maxLimit;
                }
            } else {
                errors['amount'] = 'transaction:transaction.deposit.onlinetransfer.invalidAmount';
            }
        }
        controller.vm.setState({ errors: errors, limitAmount: limitAmount });
    },

    isSubmitBtnDisabled() {
        const { fields, errors, depositSubmitCountdown } = controller.vm.state;
        if (!fields['amount'] || errors['amount'] || depositSubmitCountdown > 0) {
            return true;
        } else {
            return false;
        }
    },

    handleSubmit() {
        const { depositSubmission, t, portal, language, paymentGatewaySetting } = controller.vm.props;
        const { fields, selectedChannel, selectedBank, depositSubmitCountdown } = controller.vm.state;

        if (gu_getNestedValue(depositSubmission, `submissionData.${controller.optionCode}.submissionRetryTime`) > Date.now().valueOf()) {
            notification.showNotification('error', t('transaction:transaction.deposit.resubmitError', { retryTime: depositSubmitCountdown }));
        } else {
            proceedSubmit();
        }

        function proceedSubmit() {
            const langPath = language.countryLanguageKey.replace('_', '-').toLowerCase();
            const selectedBankCode = selectedBank && selectedBank.bankCode ? selectedBank.bankCode : selectedBank.code;

            let hideLangPathUrl = gu_getNestedValue(portal, 'settings.features.hideLangPathUrl') ? true : false;
            let callbackUrl = '/myaccount/deposit';
            let stateUrl = hideLangPathUrl ? '/quickpayredirect' : `/${langPath}/quickpayredirect`;

            // don't be confused, this parameter is need selectedChannel's id and selectedBank's code
            let params = {
                id: selectedChannel && selectedChannel.id,
                code: selectedBankCode,
            };

            let selectedThirdPartyBank = null;
            if (paymentGatewaySetting && paymentGatewaySetting.bankQRWithMemberBank && paymentGatewaySetting.bankQRWithMemberBank.includes(selectedChannel.code)) {
                selectedThirdPartyBank = selectedBank;
            }

            window.SPL_Transaction.requestDepositOnlineTransfer(
                params,
                selectedBankCode,
                Number(fields['amount']),
                null,
                null,
                stateUrl,
                language.key,
                callbackUrl,
                null,
                selectedThirdPartyBank // if using bank from third-party only need pass it
            ).then((data) => {
                if (data.errorCode) {
                    notification.showNotification('error', data.message);
                } else {
                    trans_handlePGDepositCountDown(controller.vm, true, 60, controller.optionCode, Date.now().valueOf());
                }
            });
        }
    },
};

export default controller;
