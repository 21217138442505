import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { languageAction } from '../../../redux/action/language.action';
import { mapStateToProps } from '../../../redux/selector/selector';
import { withRouter } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Translation, withTranslation } from 'react-i18next';
import { MdArrowDropDown } from 'react-icons/md';
import { GoKebabVertical } from "react-icons/go";
import { language_dropdown, global_language_icon } from '../Common_Items_Data';

class LanguageModal extends Component {
  isMount = true;
  constructor(props) {
    super();
    this.state = {
      modal: false,
      isTrans: 'my-en',
      activeId: 'my-en',
      languageList: [],
      arrowRotate: false,
    };
    this.languageClicked = this.languageClicked.bind(this);
  }

  onChangeLanguage = (lang) => {
    this.props.i18n.changeLanguage(lang.key);
    this.props.dispatch(languageAction.changeLanguage(lang));
    this.changeUrl(lang.countryLanguageKey);
  };

  changeUrl = (countryLanguageKey) => {
    const {history} = this.props;
    let path = countryLanguageKey.replace('_', '-').toLowerCase();
    if (history && history.location && history.location.pathname) {
      window.location.pathname = `/${path}` + history.location.pathname ;
    } else {
      window.location.pathname = `/${path}`;
    }
  };

  componentDidMount() {
    this.getLanguageList();
    document.addEventListener('mousedown', this.handleClick, false);
  }

  componentWillUnmount() {
    this.isMount = false;
    document.removeEventListener('mousedown', this.handleClick, false);
  }

  handleClick = (e) => {
    if (this.node.contains(e.target)) {
      return
    }

    this.handleClickOutside();
  }

  handleClickOutside() {
    this.setState({arrowRotate: false});
  }

  getLanguageList = () => {
    window.SPL_Content.getLanguageList().then((data) => {
      if (this.isMount) {
        this.setState({ languageList: data });
      }
    });
  };

  languageClicked(e) {
    let key = e.currentTarget.getAttribute('data-key');
    this.setState({ modal: !this.state.modal, isTrans: key, activeId: key });
  }
  getLanguageLayout = () => {
    let layout = window.layoutGroup;
    if (["L04"].includes(layout)) {
      return "LanguageModal_04" 
    }
     else {
      // default or no special requirement go to else
      return "LanguageModal_01"
    }

  }

  render() {
    let layout = window.layoutGroup;
    return (
      <Translation>
        {(t) => (
          <div className='position-relative'>
            <div className='d-flex align-items-center'>
              <LazyLoadImage className='global-language-icon' src={global_language_icon} />
              <span className='langauge-text'>{t('member:PortalApp.member.language')}</span>
              <button id='translateBtn' className=' d-flex align-items-center'
                onClick = {() => this.setState({arrowRotate: !this.state.arrowRotate})}>
                <LazyLoadImage className='icon-xs language-icon-size' src={this.props.language.src} alt='MY-flag' />
                <span className='d-none d-lg-block language-text'>{this.props.language.display}</span>
                <LazyLoadImage src={language_dropdown} 
                  className={`language-dropdown-icon ${this.state.arrowRotate && 'arrowRotate'}`} />
              </button>
            </div>

            <div className={`${this.state.arrowRotate ? 'open-languageBar' : 'close-languageBar'}`} ref={node => this.node = node}>
              {this.state.languageList.map((languageObj) => {
                return (
                  <div key={languageObj.countryName} className='d-flex'>
                    <LazyLoadImage className='language-icon' src={languageObj.languages[0].src} alt='MY-flag' />
                    {languageObj.languages.map((lang) => (
                      <div
                        key={lang.countryLanguageKey}
                        className={`language-select ${this.props.language.countryLanguageKey === lang.countryLanguageKey && 'is-active'}`}
                        data-key='my-en'
                        onClick={() => {
                          this.onChangeLanguage(lang);
                        }}>
                        {lang.display}
                      </div>
                    ))}
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </Translation>
    );
  }
}

export default connect(mapStateToProps)(withTranslation('member')(withRouter(LanguageModal)));
