import React from 'react';
import ReactLoading from 'react-loading';
import {  list } from '../Generic/generic';


 const SmallLoading = () => (
		<div className='small-loading'>
			{list.map((l) => (
				<div key={l.prop} >
					<ReactLoading type={l.prop} color='secondary' style={{width:'32px', height:'32px'}} />
				</div>
			))}
		</div>
	);
export default SmallLoading;
