import 'rc-datetime-picker/dist/picker.css';
import React, { Component, Fragment } from 'react';
import { Translation, withTranslation, Trans } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { mapStateToProps } from '../../redux/selector/selector';
import Select from 'react-dropdown-select';

import { Nav, NavLink, NavItem } from 'reactstrap';
import classnames from 'classnames';
import SmallLoading from '../../components/Loading/SmallLoading';
import SVGElements from 'root/components/SVGElements';

import vm from './089_P2P.controller';
import TransactionNote from '../Share/transaction-note/component/transaction-note';
import PaymentGatewayIcon from '../../components/Molecules/payment-icon';
import BankIcon from '../../components/Molecules/bank-icon';

class P2P extends Component {
    isMount = true;
    props = null;

    constructor(props) {
        super(props);
        this.props = props;
        vm.init(this);
    }

    componentDidMount() {
        vm.initCompSettings();
        if (this.state.depositAllSettings !== null) {
            vm.loadP2Pbank();
        }
    }

    componentWillUnmount() {
        this.setState = () => {
            return;
        };
    }

    componentDidUpdate() {}

    render() {
        const {
            isLoading,
            methodList,
            selectedMethod,
            bankList,
            selectedBank,
            depositMinMaxLimit,
            amount,
            amountErrMsg,
            limitAmount,
            disabledDepositButton,
            getTncReferral,
            depositAmountOptions,
        } = this.state;
        const { screen, language, user, paymentGatewaySetting } = this.props;
        const { country } = language;

        let transactionNoteProps = {
            showCustomArticle: this.state.showCustomDepositArticle,
            customArticleContent: this.state.showCustomDepositArticleContent,
            customArticleIsJson: this.state.customArticleIsJson,
            customContentStyle: this.state.customContentStyle,
            hideDefaultNote: [!this.state.showDefaultArticle, true, true],
        };

        return (
            <Translation>
                {(t) => (
                    <div className='p2p'>
                        {!isLoading && screen.isMobile && (
                            <div className='item deposit-channel'>
                                <div className='title-item'>
                                    <span> {t('transaction:transaction.deposit.cashdeposit.channel')}</span>
                                    <span className='text-danger asterisk'>*</span>
                                </div>
                                <div className='deposit-amount'>
                                    {methodList.length > 0 ? (
                                        <Select
                                            placeholder={t('settings:settings.dropdown.pleaseselect')}
                                            options={methodList}
                                            values={[selectedMethod]} // react select need to be array
                                            labelField={t('displayName')}
                                            valueField='channel'
                                            key='channel'
                                            onChange={(value) => vm.handleMethodChanged(value)}
                                            searchable={false}
                                        />
                                    ) : (
                                        <SmallLoading></SmallLoading>
                                    )}
                                </div>
                            </div>
                        )}
                        <ul className='small-banklist'>
                            <li>
                                <div className='item'>
                                    {!isLoading && !screen.isMobile && (
                                        <p className='deposit-options'>
                                            {t('transaction:transaction.deposit.cashdeposit.depositChannel', 'Deposit Channel')}
                                            <span className='text-danger asterisk'>*</span>
                                        </p>
                                    )}
                                    <div className='bank-selector'>
                                        {!isLoading && !screen.isMobile && (
                                            <Nav tabs className='p2p-deposit-channel-tab'>
                                                <div className='display-flex'>
                                                    {methodList.map((method, index) => {
                                                        const isActive = selectedMethod.code === method.code;
                                                        return (
                                                            <NavItem
                                                                key={index}
                                                                className='deposit-tab-click'
                                                                onClick={() => {
                                                                    vm.handleMethodChanged(method);
                                                                }}
                                                            >
                                                                <NavLink
                                                                    className={classnames({
                                                                        active: isActive,
                                                                        navlinkForm: true,
                                                                        depositLink: true,
                                                                    })}
                                                                >
                                                                    <div className='deposit-banktransfer'>
                                                                        <div className={isActive ? 'active' : ''}>
                                                                            <PaymentGatewayIcon pgCode={method.code} isActive={isActive} />
                                                                        </div>
                                                                    </div>
                                                                    <div className='deposit-options-text'>{method.displayName}</div>
                                                                </NavLink>
                                                            </NavItem>
                                                        );
                                                    })}
                                                </div>
                                            </Nav>
                                        )}

                                        {!isLoading && (
                                            <ul className='bank-list'>
                                                {bankList.map((bank, index) => {
                                                    const isActive = selectedBank?.bankCode === bank?.bankCode;
                                                    return (
                                                        <li className='small-bank' key={index}>
                                                            <div className='banklist-group' onClick={() => vm.handleBankChange(bank)}>
                                                                <input type='radio' name='bank-account' onChange={() => vm.handleBankChange(bank)} checked={isActive} />
                                                                <span className='checkmark'></span>
                                                                <div className={`bank-img bank-${isActive ? 'on' : 'off'}`}>
                                                                    <BankIcon bankCode={bank.bankCode} isActive={isActive} />
                                                                </div>

                                                                <div className={`bank-name ${isActive ? 'bank-selected' : ''}`}>{bank.name}</div>
                                                            </div>
                                                        </li>
                                                    );
                                                })}
                                            </ul>
                                        )}
                                    </div>
                                </div>
                            </li>

                            {!screen.isMobile && <TransactionNote {...transactionNoteProps} />}
                        </ul>

                        {selectedBank?.bankCode ? (
                            <ul className='deposit-bottom' id='deposit-bottom'>
                                {(!paymentGatewaySetting ||
                                    (paymentGatewaySetting && !paymentGatewaySetting.amountFieldNotRequired.includes(selectedMethod && selectedMethod.code))) && (
                                    <Fragment>
                                        <li>
                                            <div className='item'>
                                                <p className='deposit-options'>
                                                    {t('transaction:transaction.deposit.onlinetransfer.amount')} <span className='text-danger asterisk'>*</span>
                                                </p>

                                                <div className='deposit-amount'>
                                                    {depositMinMaxLimit ? (
                                                        <input
                                                            name='amount'
                                                            className={`form-control-inner ${amountErrMsg.length > 0 ? 'is-invalid' : ''}`}
                                                            id='name'
                                                            min='0'
                                                            value={amount}
                                                            placeholder={t('global:global.form.online-transfer-amount-placeholder', {
                                                                min: window.SPL_Other.formatAmount(depositMinMaxLimit.min),
                                                                max: window.SPL_Other.formatAmount(depositMinMaxLimit.max),
                                                            })}
                                                            onChange={(e) => vm.handleAmountChange(e.target.value)}
                                                            // onKeyPress={(e) => vm.disableDot(e)}
                                                            onBlur={() => vm.validateDepositAmount()}
                                                        />
                                                    ) : (
                                                        <SmallLoading></SmallLoading>
                                                    )}

                                                    {country === 'VN' && (
                                                        <div className='invalid-feedback important-notice'>
                                                            <span>1 = 1000 đồng</span>
                                                        </div>
                                                    )}

                                                    {amountErrMsg.map((errMsg, index) => {
                                                        return (
                                                            <div key={index} className='invalid-feedback important-notice'>
                                                                {screen.isMobile && <i className='icon-tip'></i>}
                                                                <Trans i18nKey={errMsg}></Trans> {`${user.account.currency} ${limitAmount}`}
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </div>
                                        </li>

                                        <li>
                                            <div className='item amount-select'>
                                                <p className='deposit-options'></p>
                                                <div className='amount-select-btn'>
                                                    {depositAmountOptions.map((item) => (
                                                        <button className='btn-amount' onClick={() => this.handleAmountChange(item)}>
                                                            {item}
                                                        </button>
                                                    ))}
                                                </div>
                                            </div>
                                        </li>
                                    </Fragment>
                                )}

                                <li>
                                    <div className='item'>
                                        <p className='deposit-options'></p>
                                        <div className='deposit-p2p-button'>
                                            <button type='submit' className='btn-normal btn-deposit' disabled={disabledDepositButton} onClick={() => vm.handleSubmit()}>
                                                <span>{t('transaction:transaction.deposit.onlinetransfer.depositbtn')}</span>
                                            </button>

                                            {/* currently this section hided by css, dont know what purpose */}
                                            <span className='tnc-apply mb-only' onClick={() => vm.getTncReferral()}>
                                                * {t('slot:slot.tnc-apply', 'Terms & Conditions Applied')}
                                            </span>
                                        </div>
                                    </div>
                                </li>

                                {getTncReferral && (
                                    <li className='messaging-popup mobile-notice'>
                                        <div className='popup notice-box'>
                                            <div className='popup-header'>
                                                <div className='popup-header-left'>
                                                    <div className='popup-title'>{t('slot:slot.tnc-apply', 'Terms & Conditions Applied')}</div>
                                                </div>

                                                <div className='popup-header-right'>
                                                    <SVGElements name='close-icon' onClick={() => vm.getTncReferral()} />
                                                </div>
                                            </div>

                                            <TransactionNote {...transactionNoteProps} />
                                        </div>
                                        <div className='popup-overlay notice-overlay' onClick={() => vm.getTncReferral()}></div>
                                    </li>
                                )}
                            </ul>
                        ) : null}
                    </div>
                )}
            </Translation>
        );
    }
}

export default connect(mapStateToProps)(withTranslation(['transaction', 'settings', 'bank'])(withRouter(P2P)));
