import React from 'react';
import { Translation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { mapStateToProps } from '../../../redux/selector/selector';
import { userAction } from '../../../redux/action';

class Step3 extends React.Component {
  isMount = true;
  timeOut = null;
  componentDidMount() {
    this.isMount = true;
  }

  componentDidUpdate() {
    if (this.props.currentStep === '3' || this.props.currentStep === 3) {
      // if this is affiliate , please change below to true
      let component = this;
      window.SPL_Register.injectScript(false, () => {
        if (this.props && this.props.user && this.props.user.account) {
          component.redirectToDeposit();
        }
      });
    } else {
      if (this.timeOut) {
        clearTimeout(this.timeOut);
      }
    }
  }

  redirectToDeposit() {
    this.timeOut = setTimeout(() => {
      this.navigate();
    }, 5000);
  }

  navigate = () => {
    if (this.timeOut) {
      clearTimeout(this.timeOut);
    }
    // this.props.history.push('/myaccount/deposit');
  };

  componentWillUnmount() {
    this.isMount = false;
  }
  render() {
    if (this.props.currentStep !== 3) {
      return null;
    }

    return (
      <Translation>
        {(t) => (
          <div className=' '>
            <div className='container-fluid p-l-0 p-r-0 success-bg'>
              <div className='container d-flex justify-content-center'>
                <div className='padding-5px text-center'>
                  <div className='success-animation w-100'>
                    <svg id='successAnimation' className='animated' xmlns='http://www.w3.org/2000/svg' width='70' height='70' viewBox='0 0 70 70'>
                      <path
                        id='successAnimationResult'
                        fill='#D8D8D8'
                        d='M35,60 C21.1928813,60 10,48.8071187 10,35 C10,21.1928813 21.1928813,10 35,10 C48.8071187,10 60,21.1928813 60,35 C60,48.8071187 48.8071187,60 35,60 Z M23.6332378,33.2260427 L22.3667622,34.7739573 L34.1433655,44.40936 L47.776114,27.6305926 L46.223886,26.3694074 L33.8566345,41.59064 L23.6332378,33.2260427 Z'
                      />
                      <circle id='successAnimationCircle' cx='35' cy='35' roke='#979797' strokeWidth='2' strokeLinecap='round' fill='transparent' />
                      <polyline id='successAnimationCheck' stroke='#979797' strokeWidth='2' points='23 34 34 43 47 27' fill='transparent' />
                    </svg>
                  </div>

                  <div className='success-text'>
                    <h3 className='font-weight-semibold'>{t('register:register.congratulation')}</h3>
                    <h5>{t('register:register.createSuccess')}</h5>
                    <h5>{t('register:register.messages.deposit')}</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </Translation>
    );
  }
}
export default withRouter(connect(mapStateToProps, null)(withTranslation(['password'])(Step3)));
