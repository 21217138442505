import React, { useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { mapStateToProps } from 'root/redux/selector/selector';

function RouterChangeHandler({ t, user, screen }) {
    let history = useHistory();
    const languageState = useSelector((state) => state.language);

    // useEffect(() => {
    //     let seoFooter = document.getElementById('seo-footer-container');
    //     let copyrightFooterTop = document.getElementById('copyright-container-top');
    //     let copyrightFooterBtm = document.getElementById('copyright-container-btm');
    //     let footerBtm = document.getElementById('footer-btm');

    //     if (seoFooter) {
    //         if (history.location.pathname.includes('home') && window.innerWidth > 768) {
    //             seoFooter.style.display = 'block';
    //         } else {
    //             seoFooter.style.display = 'none';
    //         }

    //         if (copyrightFooterTop) {
    //             if (history.location.pathname.includes('home')) {
    //                 copyrightFooterTop.style.display = 'none';
    //             } else {
    //                 copyrightFooterTop.style.display = 'flex';
    //             }
    //         }

    //         if (copyrightFooterBtm) {
    //             if (history.location.pathname.includes('home')) {
    //                 copyrightFooterBtm.classList.add('show');
    //             } else {
    //                 copyrightFooterBtm.classList.remove('show');
    //             }
    //         }

    //         if (footerBtm) {
    //             if (history.location.pathname.includes('home')) {
    //                 footerBtm.style.display = 'block';
    //             } else {
    //                 footerBtm.style.display = 'none';
    //             }
    //         }

    //         if (screen.isMobile) {
    //             if (copyrightFooterTop) {
    //                 copyrightFooterTop.style.display = 'none';
    //             }

    //             if (copyrightFooterBtm) {
    //                 copyrightFooterBtm.classList.add('show');
    //             }

    //             if (footerBtm) {
    //                 footerBtm.style.display = 'block';
    //             }
    //         }
    //     } else {
    //         if (screen.isMobile) {
    //             if (copyrightFooterTop) {
    //                 copyrightFooterTop.style.display = 'none';
    //             }

    //             if (copyrightFooterBtm) {
    //                 copyrightFooterBtm.classList.add('show');
    //             }

    //             if (footerBtm) {
    //                 footerBtm.style.display = 'block';
    //             }
    //         } else {
    //             if (copyrightFooterTop) {
    //                 copyrightFooterTop.style.display = 'flex';
    //             }

    //             if (copyrightFooterBtm) {
    //                 copyrightFooterBtm.classList.remove('show');
    //             }

    //             if (footerBtm) {
    //                 footerBtm.style.display = 'none';
    //             }
    //         }
    //     }

    //     return () => {};
    // }, [history.location.pathname, window.innerWidth]);

    useEffect(() => {
        let seoContent = document.getElementById('seo-content');

        let copyrightFooterTop = document.getElementById('copyright-container-top');
        let copyrightFooterBtm = document.getElementById('copyright-container-btm');
        let footerBtm = document.getElementById('footer-btm');
        let hostname = window.location.hostname;
        let path = history.location.pathname;

        if (hostname === 'localhost') {
            hostname = window.tempDomain;
        }

        if (seoContent && window.innerWidth > 768) {
            seoContent.innerHTML = '';

            window.SPL_Content.getSubSeoFooter(hostname, path, languageState.countryLanguageKey, 'footer').then((data) => {
                if (data.data) {
                    copyrightFooterTop.style.display = 'none';
                    copyrightFooterBtm.classList.add('show');
                    if (footerBtm) footerBtm.style.display = 'block';
                    seoContent.innerHTML = data.data;
                } else {
                    copyrightFooterTop.style.display = 'flex';
                    copyrightFooterBtm.classList.remove('show');
                    if (footerBtm) footerBtm.style.display = 'none';
                    seoContent.innerHTML = '';
                }
            });
        }

        return () => {};
    }, [history.location.pathname, window.innerWidth]);

    useEffect(() => {
        let rawPageTitle = window.SPL_Other.getPageTitle(history.location.pathname);
        let titleKey = '';
        let count = 0;
        // to make sure the language json able to load and get correct translate
        let interval = setInterval(() => {
            if (count > 10) {
                clearInterval(interval);
            } else {
                if (rawPageTitle !== '') {
                    titleKey = t(rawPageTitle, { merchant: window.globMerchantName });
                    // if translate file dont have translation
                    if (rawPageTitle.includes(titleKey)) {
                        titleKey = t('global:global.title', { merchant: window.globMerchantName });
                    }
                } else {
                    titleKey = t('global:global.title', { merchant: window.globMerchantName });
                }
                window.SPL_Other.updateTitle(titleKey, languageState.countryLanguageKey, history.location.pathname);
                count++;
            }
        }, 100);

        return () => {
            count = 99;
        };
    }, [history.location.pathname, window.innerWidth]);

    useEffect(() => {
        let mTopPadding = document.getElementById('mTopPadding');
        if (mTopPadding) {
            if (window.innerWidth <= 768 && user.isLogin && !['/account', '/myprofile'].some((pathName) => history.location.pathname.includes(pathName))) {
                mTopPadding.classList.add('mTopPadding');
            } else {
                mTopPadding.classList.remove('mTopPadding');
            }
        }
        return () => {};
    }, [user.isLogin, history.location.pathname, window.innerWidth]);

    return <div></div>;
}
let listOfLanguageFile = [
    'register',
    'promotion',
    'slot',
    'poker',
    'sport',
    'casino',
    'slot',
    'ultimate',
    'fishing',
    'cockfight',
    'scr888',
    'mega',
    'common',
    'contactus',
    'affiliate',
    'terms',
    'global',
    'message',
    'settings',
];
export default connect(mapStateToProps, null)(withTranslation(listOfLanguageFile)(withRouter(RouterChangeHandler)));
