import React from 'react';
import styles from './reminder-note.module.scss';

const ReminderNote = ({ msg }) => {
    return (
        <div className={styles.reminderNote}>
            <img className={styles.warningIcon} src={'/public/html/images/common_img/tip.png'} alt='warning-icon' />
            <span>{msg}</span>
        </div>
    );
};

export default ReminderNote;
