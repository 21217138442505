import React from 'react';
import { Fragment } from 'react';

const getViewBox = (name) => {
  switch (name) {
    case 'restore':
      return '0 0 40 40';
    case 'menu':
      return '0 0 46.44 45.58';
    case 'announcement':
      return '0 0 40 37.5';
    case 'deposit':
      return '0 0 40 40';
    case 'transfer':
      return '0 0 40 33.15';
    case 'withdraw':
      return '0 0 40 40';
    case 'history':
      return '0 0 35.16 40';
    case 'notice':
      return '0 0 40 30.57';
    case 'maxim-logo':
      return '0 0 200 71.3';
    default:
      return '0 0 40 40';
  }
};

const getPath = (name, props) => {
  switch (name) {
    case 'home':
      return (
        <path
          {...props}
          d='M38.83 37.65h-3.6V18.71a1.18 1.18 0 0 0 1.61-.25l2.89-3.75a1.18 1.18 0 0 0-.25-1.67L22.1.69a3.54 3.54 0 0 0-4.2 0L.49 13.06a1.24 1.24 0 0 0-.48.78 1.2 1.2 0 0 0 .22.88l2.82 3.75a1.17 1.17 0 0 0 1.59.27l.13-.09v19h-3.6a1.17 1.17 0 0 0 0 2.34h37.66a1.17 1.17 0 0 0 0-2.34zm-5.94-21.21v21.21H7.11V16.44a1.18 1.18 0 0 0-1.83-1l-1 .7-1.45-1.86L19.27 2.59a1.17 1.17 0 0 1 1.4 0l16.49 11.68-1.46 1.89-1-.67a1.17 1.17 0 0 0-1.81.95z'
        />
      );
    case 'contactus':
      return (
        <path
          {...props}
          d='M3.7 7.4h39a3.7 3.7 0 0 0 0-7.4H3.7a3.7 3.7 0 0 0 0 7.4zM42.74 19.09H3.7a3.7 3.7 0 0 0 0 7.4h39a3.7 3.7 0 0 0 0-7.4zM42.74 38.18H3.7a3.7 3.7 0 0 0 0 7.4h39a3.7 3.7 0 0 0 0-7.4z'
        />
      );
    case 'language':
      return (
        <path
          {...props}
          d='M11.72 15.23a1.16 1.16 0 0 1-1.17 1.16H5.86a1.16 1.16 0 0 1-1.17-1.16 1.17 1.17 0 0 1 1.17-1.17h4.69a1.18 1.18 0 0 1 1.17 1.17zm-1.17 3.52H5.86a1.17 1.17 0 0 0-1.17 1.17 1.16 1.16 0 0 0 1.17 1.16h4.69a1.16 1.16 0 0 0 1.17-1.16 1.18 1.18 0 0 0-1.17-1.17zM40 17.58a5.87 5.87 0 0 1-4.69 5.74v8.32a3.51 3.51 0 0 1-7 .64L23 28.37a12.9 12.9 0 0 0-4.22-2.08V27a3.53 3.53 0 0 1-3.52 3.52h-1.2V34a3.52 3.52 0 1 1-7 0v-8.23h-1.2A5.87 5.87 0 0 1 0 19.92v-4.69a5.86 5.86 0 0 1 5.86-5.85h9.41A13.05 13.05 0 0 0 23 6.84L28.33 3a3.51 3.51 0 0 1 7 .56v8.32a5.87 5.87 0 0 1 4.67 5.7zm-28.28 8.19H9.37V34a1.18 1.18 0 1 0 2.35 0zm2.34-2.33V11.7h-8.2a3.54 3.54 0 0 0-3.52 3.53v4.69a3.53 3.53 0 0 0 3.52 3.52zm2.35 2.4a21.09 21.09 0 0 0-2.35-.07v2.36h1.17A1.18 1.18 0 0 0 16.41 27zm11.89-20l-3.95 2.88a15.4 15.4 0 0 1-7.94 3v11.8a15.45 15.45 0 0 1 8 3l3.93 2.86zM33 3.52a1.18 1.18 0 1 0-2.36 0v28.12a1.18 1.18 0 0 0 2.36 0zm4.68 14.06a3.53 3.53 0 0 0-2.35-3.32v6.63a3.51 3.51 0 0 0 2.33-3.31z'
        />
      );
    case 'desktop':
      return (
        <path
          {...props}
          d='M36.48 18.75a3.51 3.51 0 0 0-1.17.2v-1.37A3.53 3.53 0 0 0 33 14.26V1.17A1.17 1.17 0 0 0 31.8 0H1.17A1.17 1.17 0 0 0 0 1.17v21.88a1.17 1.17 0 0 0 1.17 1.17h17.74v5.91a3.49 3.49 0 0 0-2.35-.89 3.52 3.52 0 0 0-3.51 3.51 3.49 3.49 0 0 0 1 2.49l3.5 3.5a4.27 4.27 0 0 0 3 1.26H35.7a4.31 4.31 0 0 0 4.3-4.3V22.27a3.53 3.53 0 0 0-3.52-3.52zm-7-3.79a3.48 3.48 0 0 0-2.34-.9 3.53 3.53 0 0 0-3.52 3.52V19a3.46 3.46 0 0 0-1.17-.2 3.52 3.52 0 0 0-3.49 3.13h-12a5.84 5.84 0 0 0-4.57-4.57V6.91a5.84 5.84 0 0 0 4.52-4.57h19.15a5.84 5.84 0 0 0 4.57 4.57v7.35a3.69 3.69 0 0 0-1.18.7zm1.15-10.47a3.57 3.57 0 0 1-2.15-2.15h2.15zM4.49 2.34a3.57 3.57 0 0 1-2.15 2.15V2.34zM2.34 19.73a3.57 3.57 0 0 1 2.15 2.15H2.34zm35.32 16a2 2 0 0 1-2 2H20.62a2 2 0 0 1-1.38-.58l-3.51-3.5a1.18 1.18 0 0 1 0-1.66 1.19 1.19 0 0 1 .83-.34 1.16 1.16 0 0 1 .83.34l1.86 1.86a1.17 1.17 0 0 0 1.28.26 1.19 1.19 0 0 0 .72-1.11V22.27a1.17 1.17 0 1 1 2.34 0V27a1.18 1.18 0 1 0 2.35 0v-9.42a1.17 1.17 0 0 1 2.34 0V27a1.18 1.18 0 1 0 2.35 0v-9.42a1.17 1.17 0 0 1 2.34 0V27a1.17 1.17 0 1 0 2.34 0v-4.73a1.18 1.18 0 1 1 2.35 0zM16.48 8.59h2.35a1.17 1.17 0 1 0 0-2.34h-1.17v-.39a1.18 1.18 0 0 0-2.35 0v.59a3.52 3.52 0 0 0 1.17 6.83 1.18 1.18 0 1 1 0 2.35h-2.34a1.17 1.17 0 0 0 0 2.34h1.17v.39a1.18 1.18 0 0 0 2.35 0v-.59a3.52 3.52 0 0 0-1.18-6.83 1.18 1.18 0 0 1 0-2.35z'
        />
      );
    case 'download':
      return (
        <path
          {...props}
          d='M21 15.4h-2a1.91 1.91 0 1 1 0-3.81h4.11a1.18 1.18 0 0 0 0-2.35h-1.94V7.33a1.17 1.17 0 0 0-2.34 0v1.92a4.25 4.25 0 0 0 .14 8.5H21a1.91 1.91 0 0 1 0 3.82h-4.08a1.17 1.17 0 1 0 0 2.34h1.91v1.91a1.17 1.17 0 1 0 2.34 0v-1.91A4.26 4.26 0 0 0 21 15.4zm13.56 6.26a1.18 1.18 0 0 0-1.54.61A14.23 14.23 0 0 1 6 18.9L9.07 21a1.17 1.17 0 0 0 1.3-2l-5.12-3.4a1.19 1.19 0 0 0-.88-.17 1.16 1.16 0 0 0-.75.5L.2 21.05a1.16 1.16 0 0 0 .32 1.62 1.13 1.13 0 0 0 .65.2 1.18 1.18 0 0 0 1-.52l1.61-2.42a16.59 16.59 0 0 0 31.42 3.28 1.19 1.19 0 0 0-.61-1.55zm4.89-11.18a1.17 1.17 0 0 0-1.63.32l-1.61 2.42A16.58 16.58 0 0 0 4.8 10a1.18 1.18 0 0 0 .61 1.54A1.17 1.17 0 0 0 7 10.88a14.23 14.23 0 0 1 27 3.38l-3.11-2.08a1.16 1.16 0 0 0-1.62.32 1.17 1.17 0 0 0 .32 1.63l5.12 3.42a1.21 1.21 0 0 0 .65.2 1.18 1.18 0 0 0 1-.52l3.44-5.13a1.16 1.16 0 0 0-.32-1.62z'
        />
      );
    case 'logout':
      return (
        <path
          {...props}
          d='M40 27.58a1.21 1.21 0 0 0-.3-.87 10.88 10.88 0 0 0-7.38-3.48 11.26 11.26 0 0 0-7.88 2.4l-.87.7h-5.76a3.51 3.51 0 0 0-3.11 1.89l-9.46-4.17a3.73 3.73 0 0 0-3.41 6.63l12.58 7.42a13.7 13.7 0 0 0 7 1.9h17.42A1.17 1.17 0 0 0 40 38.83V27.58zM21.36 37.66a11.37 11.37 0 0 1-5.76-1.58L3 28.66a1.41 1.41 0 0 1-.69-1.2 1.39 1.39 0 0 1 2-1.27l10.1 4.46a3.53 3.53 0 0 0 3.42 2.71h9.61a1.17 1.17 0 0 0 0-2.34h-9.63a1.18 1.18 0 0 1 0-2.35H24a1.17 1.17 0 0 0 .74-.26l1.19-1a9 9 0 0 1 6.24-1.88A8.62 8.62 0 0 1 37.66 28v9.69zm-6.67-22h-2.35a1.18 1.18 0 0 0 0 2.35h1.18v.39a1.17 1.17 0 0 0 2.34 0v-.59a3.52 3.52 0 0 0-1.17-6.83 1.18 1.18 0 0 1 0-2.35H17a1.17 1.17 0 0 0 0-2.34h-1.14v-.43a1.17 1.17 0 1 0-2.34 0v.59a3.51 3.51 0 0 0 1.17 6.83 1.17 1.17 0 0 1 0 2.34zm0 8.6A12.11 12.11 0 1 0 2.58 12.11a12.13 12.13 0 0 0 12.11 12.11zm0-21.88a9.77 9.77 0 1 1-9.77 9.77 9.78 9.78 0 0 1 9.77-9.77z'
        />
      );
    case 'notice':
      return (
        <path
          {...props}
          d='M14.74 29.36a.86.86 0 01-.74 1.21h-.77a.84.84 0 01-.76-.47l-4.89-9.8H6.07C2.7 20.18 0 17 0 13s2.7-7.13 6.07-7.24h8.45V20.3h-3.89zM29.77 0l-6.25 3.87a.16.16 0 01-.09 0h-7.88v18.29H23.58L29.77 26a.26.26 0 00.39-.22V.26a.26.26 0 00-.39-.26zm2.61 5.67h-1.07a.17.17 0 00-.17.17v14.29a.17.17 0 00.17.17h1.07a.17.17 0 00.17-.17V5.88a.17.17 0 00-.17-.17zm7.28 6h-4.45a.34.34 0 00-.34.34V14a.35.35 0 00.34.35h4.45A.35.35 0 0040 14v-1.92a.34.34 0 00-.34-.34zm-.13-8l-4.45 1.95a.34.34 0 00-.21.31v1.83a.34.34 0 00.47.31l4.45-1.85a.34.34 0 00.21-.31V4.08a.34.34 0 00-.47-.31zm.26 16l-4.45-1.85a.34.34 0 00-.47.31v1.83a.34.34 0 00.21.31l4.45 1.85a.34.34 0 00.47-.31V20.1a.34.34 0 00-.21-.31z'
        />
      );
    case 'maxim-logo':
      return (
        <Fragment>
          <g>
            <g>
              <path {...props} style={{ fill: '#3DB54A' }}
                d='M27.2,30.1l5.5,6.2c1.4-1.6,2.7-3.1,4-4.5c-3.7-4.1-6.7-7.5-7-7.9c-1.1-1.2-2.2-2.4-4.6-3
                c-1.6-0.4-3.3-0.8-3.3-0.8L20,49l6.7-7.5L27.2,30.1z'
              />
              <path {...props} style={{ fill: '#019547' }}
                d='M39.1,39.4l8.4-9.3L48,41.4l6.7,7.5L52.8,20c0,0-1.6,0.4-3.3,0.8c-2.3,0.6-3.5,1.8-4.6,3S20.5,51.3,20.5,51.3
                l11-3.3l5.8-6.6l5.8,6.6l11,3.3c0,0-6.5-7.3-12.4-14L39.1,39.4z'
              />
            </g>
            <g>
              <g>
                <g>
                  <polygon {...props} style={{ fill: '#019547' }} points='132.2,25.9 133.7,25.9 133.7,22.3 124.6,22.3 124.6,23.4 132.2,23.4' />
                  <rect {...props} style={{ fill: '#019547' }} x="124.1" y="28.1" width="7.8" height="1.1" />
                  <path {...props} style={{ fill: '#019547' }} 
                    d='M126.9,26.1c-0.2,0-0.3,0-0.3-0.1c0,0-0.1-0.1-0.1-0.2v-1.9H125v2.3c0,0.2,0,0.3,0.1,0.4
                    c0,0.1,0.1,0.2,0.3,0.4c0.1,0.1,0.4,0.2,0.7,0.2h6.3v2.7c0,0.3-0.2,0.4-0.4,0.4h-4.8v1.1h5.5c0.6,0,1.2-0.5,1.2-1.1
                    c0-0.4,0-4.1,0-4.1h-7V26.1z'/>
                </g>
                <path {...props} style={{ fill: '#019547' }}
                  d='M136,22.1h3.5v8.2c0,0.4-0.2,0.8-0.8,0.8h-0.9V30h0.2c0.2,0,0.2-0.1,0.2-0.2v-1.1h-0.9v1.6
                  c0,0.6-0.4,0.9-1,0.9h-0.8v-1.1h0.2c0.2,0,0.2-0.1,0.2-0.2v-7.8H136z M138.3,23.2h-0.9v1.6h0.9V23.2z M138.3,26h-0.9v1.7h0.9V26z
                   M139.8,25.4v-1h0.1c0.1,0,0.2,0,0.2-0.2v-2h1.2v1h0.7V22h1.4v1.2h1.8v1.2h-1.8v2.1h1.8v1.2h-1.8v2.4h1.8v1.2h-5.3v-1.2h2.2v-2.4
                  H140v-1.2h2.1v-2.1h-0.8v0.3c0,0.4-0.4,0.7-0.7,0.7H139.8z' />
              </g>
              <path {...props} style={{ fill: '#019547' }}
                d='M156.9,49.1c-3.8,0-7.3-2-7.3-6c0-2.4,1.6-4,3.5-4.6v-0.1c-1.7-0.6-3-2.1-3-4.2c0-3.6,3.2-5.6,6.8-5.6
                s6.8,1.9,6.8,5.5c0,2.1-1.2,3.7-3,4.3v0.1c2,0.5,3.5,2.3,3.5,4.6C164.2,47.1,160.7,49.1,156.9,49.1z M156.9,40.2
                c-1.6,0-2.7,1.1-2.7,2.6c0,1.6,1.1,2.6,2.7,2.6c1.6,0,2.7-1,2.7-2.6C159.5,41.3,158.4,40.2,156.9,40.2z M156.9,32.1
                c-1.4,0-2.3,1-2.3,2.2s0.9,2.3,2.3,2.3c1.3,0,2.3-1.1,2.3-2.3S158.1,32.1,156.9,32.1z' />
              <path {...props} style={{ fill: '#019547' }}
                d='M172.7,49.1c-3.8,0-7.3-2-7.3-6c0-2.4,1.6-4,3.5-4.6v-0.1c-1.7-0.6-3-2.1-3-4.2c0-3.6,3.2-5.6,6.8-5.6
                s6.8,1.9,6.8,5.5c0,2.1-1.2,3.7-3,4.3v0.1c2,0.5,3.5,2.3,3.5,4.6C180,47.1,176.5,49.1,172.7,49.1z M172.7,40.2
                c-1.6,0-2.7,1.1-2.7,2.6c0,1.6,1.1,2.6,2.7,2.6c1.6,0,2.7-1,2.7-2.6C175.4,41.3,174.3,40.2,172.7,40.2z M172.7,32.1
                c-1.4,0-2.3,1-2.3,2.2s0.9,2.3,2.3,2.3c1.3,0,2.3-1.1,2.3-2.3S174,32.1,172.7,32.1z' />
              <g>
                <path {...props} style={{ fill: '#13242C' }}
                  d='M76.6,47.5V40c0-1.6-0.4-2.9-2-2.9c-1.5,0-2.3,1.4-2.3,3v7.4h-4.5v-7.8c0-1.6-0.6-2.6-2-2.6
                  s-2.3,1.3-2.3,2.9v7.4H59V33.8h4.4v1.9h0.1c0.6-1.2,2-2.3,4.1-2.3c2,0,3.4,0.9,4.1,2.3c0.9-1.4,2.3-2.3,4.4-2.3c3.7,0,5,3,5,5.6
                  v8.5H76.6z' />
                <path {...props} style={{ fill: '#13242C' }}
                  d='M84.3,35.6c1.6-1.5,3.9-2.3,6.1-2.3c4.6,0,6.3,2.3,6.3,7.2v6.9h-4.1v-1.5h-0.1c-0.7,1.1-2.3,1.8-3.9,1.8
                  c-2.2,0-5-1.1-5-4.3c0-4,4.8-4.6,8.8-4.6v-0.1c0-1.3-1.1-2-2.5-2c-1.3,0-2.6,0.6-3.4,1.4L84.3,35.6z M92.6,41.5H92
                  c-2,0-4.2,0.2-4.2,1.9c0,1,1,1.4,2,1.4c1.8,0,2.8-1.1,2.8-2.8C92.6,42,92.6,41.5,92.6,41.5z' />
                <path {...props} style={{ fill: '#13242C' }}
                  d='M109.6,47.5l-2.9-4.2l-3,4.2h-5.2l5.5-7.2l-4.9-6.5h5.2l2.6,3.7l2.6-3.7h5.1l-4.9,6.5l5.4,7.2H109.6z' />
                <path {...props} style={{ fill: '#13242C' }}
                  d='M116.8,47.5V33.8l4.5-2.4v16.1C121.3,47.5,116.8,47.5,116.8,47.5z' />
                <path {...props} style={{ fill: '#13242C' }}
                  d='M141.6,47.5V40c0-1.6-0.4-2.9-2-2.9c-1.5,0-2.3,1.4-2.3,3v7.4h-4.5v-7.8c0-1.6-0.6-2.6-2-2.6
                  s-2.3,1.3-2.3,2.9v7.4H124V33.8h4.4v1.9h0.1c0.6-1.2,2-2.3,4.1-2.3c2,0,3.4,0.9,4.1,2.3c0.9-1.4,2.3-2.3,4.4-2.3c3.7,0,5,3,5,5.6
                  v8.5H141.6z' />
              </g>
            </g>
          </g>
        </Fragment>
      )
    default:
      return <path />;
  }
};

const SVGCommon = ({ name = '', style = {}, fill = '', viewBox = '', width = '100%', className = '', height = '100%' }) => (
  <svg
    width={width}
    style={style}
    height={height}
    className={className}
    xmlns='http://www.w3.org/2000/svg'
    viewBox={viewBox || getViewBox(name)}
    xmlnsXlink='http://www.w3.org/1999/xlink'>
    {getPath(name, { fill })}
  </svg>
);

export default SVGCommon;
