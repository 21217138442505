import React from 'react';

const getViewBox = name => {
  switch (name) {
    case 'trashbin':
      return '0 0 35.58 40';
    case 'tick':
      return '0 0 40 32.52';
    case 'location':
      return '0 0 28.12 40';
    case 'openedMsg':
      return '0 0 512 512';

    default:
      return '0 0 40 40';
  }
};

const getPath = (name, props) => {
  switch (name) {
    case 'trashbin':
      return (
        <path
          {...props}
          d='M34.47 5.76h-8.33V4.4a4.4 4.4 0 0 0-4.4-4.4h-7.91a4.41 4.41 0 0 0-4.4 4.4v1.36H1.11a1.11 1.11 0 0 0 0 2.24h2v26.06A5.94 5.94 0 0 0 9.05 40h17.47a6 6 0 0 0 5.94-5.94V8h2a1.11 1.11 0 0 0 0-2.22zM11.65 4.4a2.19 2.19 0 0 1 2.18-2.18h7.91a2.19 2.19 0 0 1 2.18 2.18v1.36H11.65zm18.59 29.66a3.73 3.73 0 0 1-3.72 3.72H9.05a3.72 3.72 0 0 1-3.71-3.72V8h24.91v26.06zm-12.45-.26a1.11 1.11 0 0 0 1.11-1.11V13.07a1.11 1.11 0 0 0-2.22 0v19.61a1.11 1.11 0 0 0 1.11 1.12zm-7.25-1.23a1.1 1.1 0 0 0 1.11-1.11V14.28a1.11 1.11 0 0 0-2.22 0v17.18a1.11 1.11 0 0 0 1.11 1.11zm14.49 0a1.1 1.1 0 0 0 1.11-1.11V14.28a1.11 1.11 0 0 0-2.22 0v17.18A1.11 1.11 0 0 0 25 32.57z'
        />
      );
    case 'tick':
      return (
        <path
          {...props}
          d='M0 20.66l4.11-4.1 7.71 7.71L36.09 0 40 4.24 11.72 32.52 0 20.66z'
        />
      );
    case 'location':
      return (
        <path
          {...props}
          d='M14.06 0a14.07 14.07 0 0 0-12 21.46l11.16 18a1.19 1.19 0 0 0 1 .55 1.15 1.15 0 0 0 1-.57l10.92-18.17A14.07 14.07 0 0 0 14.06 0zm10.07 20.06l-9.89 16.52L4.09 20.22a11.73 11.73 0 1 1 20-.16zM14.06 7a7 7 0 1 0 7 7 7 7 0 0 0-7-7zm0 11.74a4.71 4.71 0 1 1 4.7-4.71 4.71 4.71 0 0 1-4.7 4.71z'
        />
      );
    case 'openedMsg':
      return (
        <path
          {...props}
          d='M505.3,163.2L264.3,2.5c-5-3.4-11.6-3.4-16.6,0L6.7,163.2C2.5,166,0,170.6,0,175.7V497c0,8.3,6.7,15,15,15h482  c8.3,0,15-6.7,15-15V175.7C512,170.6,509.5,166,505.3,163.2z M256,33l215.1,143.4L256,318.4L40.9,176.4L256,33z M482,482H30V205.2  l217.7,143.7c2.5,1.7,5.4,2.5,8.3,2.5s5.8-0.8,8.3-2.5L482,205.2V482z'
        />
      );

    default:
      return <path />;
  }
};

const SVGMessage = ({
  name = '',
  style = {},
  fill = '',
  viewBox = '',
  width = '100%',
  className = '',
  height = '100%'
}) => (
  <svg
    width={width}
    style={style}
    height={height}
    className={className}
    xmlns='http://www.w3.org/2000/svg'
    viewBox={viewBox || getViewBox(name)}
    xmlnsXlink='http://www.w3.org/1999/xlink'
  >
    {getPath(name, { fill })}
  </svg>
);

export default SVGMessage;
