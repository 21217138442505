import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { mapStateToProps } from 'root/redux/selector/selector';
import { MdClose } from 'react-icons/md';

// component / files

// function

class PopupHeader extends React.Component {
    props = null;

    constructor(props) {
        super();
        this.props = props;
    }

    componentDidMount() {}

    componentWillUnmount() {
        this.setState = () => {
            return;
        };
    }

    renderChildren = () => {
        return this.props.children;
    };

    render() {
        {
            /* 
            Standard PopupHeader structure
            Params:
        */
        }
        // redux
        // passed props
        const { className, title, closeFunc } = this.props;
        // local var

        return (
            <div className={`standard-popup-modal-header ${className ? className : ''}`}>
                <div className='standard-modal-title'>{title}</div>
                {closeFunc && (
                    <div className='standard-modal-close' onClick={closeFunc && closeFunc}>
                        <MdClose />
                    </div>
                )}
            </div>
        );
    }
}

export default connect(mapStateToProps)(withTranslation()(withRouter(PopupHeader)));
