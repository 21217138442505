import React, { Component } from 'react';
import { mapStateToProps } from '../../../redux/selector/selector';
import { withRouter } from 'react-router-dom';
import { Translation } from 'react-i18next';
import { connect } from 'react-redux';

// components
import ImageHelper from 'root/pages/Share/image-helper';
import EditDOBPopup from '../../16MyAccount/shared/dialog/edit-dob-popup/component/edit-dob-popup';
import AuthSettings from '@components/molecules/auth-settings/auth-settings';
import { EditProfileDetailIcon } from '../../16MyAccount/member/Profile'; // =x temp use desktop de file first, since both code same

// functions
import { gu_togglePopup } from 'root/utils/general-util';
import commonUtil from 'root/utils/common-util';
import { mu_getContactVerification } from 'root/utils/member-util';
import { userAction } from 'root/redux/action';
import { MODULE_TYPE, POPUP_TYPE, OTP_CONTACT_TYPE as OCT } from '@utils/constants';
import { popupAction } from '@redux/action/popup.action';

class Profile extends Component {
    isMount = true;

    constructor(props) {
        super(props);
        this.state = {
            fields: {
                email: '',
                phone: '',
                login: '',
                name: '',
                dob: '',
                currency: '',
            },
            memberVerifyOptions: [],
            tempFieldDob: null,
            isSubmitting: false,

            compSettings: {},
        };
    }

    identity = () => {
        const { account } = this.props.user;
        const { registerAge } = this.state.compSettings;
        let tempFieldDob = null;
        let day = null,
            month = null,
            year = null;

        if (this.isMount && account) {
            let duplicateAccount = window.SPL_Other.deepCopy(account);
            for (var key in duplicateAccount) {
                if (Object.keys(duplicateAccount[key]).length === 0 && duplicateAccount[key].constructor === Object) {
                    duplicateAccount[key] = '';
                }
            }
            if (duplicateAccount && duplicateAccount.phone) {
                duplicateAccount.phone = window.SPL_Other.censored(duplicateAccount.phone, 'phone');
            }
            if (duplicateAccount && duplicateAccount.email) {
                duplicateAccount.email = window.SPL_Other.censored(duplicateAccount.email, 'email');
            }

            const splittedDOB = duplicateAccount.dob.split('-');
            if (duplicateAccount.dob && splittedDOB && splittedDOB.length > 0) {
                tempFieldDob = splittedDOB[0] + '-' + splittedDOB[1] + '-' + splittedDOB[2]; // used in submitEditDOB only
                day = splittedDOB[2];
                month = splittedDOB[1];
                year = splittedDOB[0];
            } else {
                let currentDate = new Date();
                day = currentDate.getDate();
                month = currentDate.getMonth() + 1;
                year = currentDate.getFullYear() - registerAge;
                if (day < 10) {
                    day = '0' + day.toString();
                }
                if (month < 10) {
                    month = '0' + month.toString();
                }
                tempFieldDob = year + '-' + month + '-' + day;
            }

            this.setState({ fields: duplicateAccount, year, month, day, tempFieldDob });
        }
    };

    componentWillUnmount() {
        this.isMount = false;
    }
    componentDidMount() {
        this.initCompSettings().then(() => {
            this.identity();
        });
        // if (this.props.screen.viewType === 'web' && this.props.location.pathname === '/profile') {
        //   this.props.history.push('/myaccount/myprofile');
        // }
    }
    componentDidUpdate(prevProps) {
        if (prevProps?.user?.account !== this.props?.user?.account) {
            this.identity();
        }
    }

    initCompSettings() {
        return new Promise((resolve) => {
            // Step 1: Standard way to get from portal settings
            let registerConfig = commonUtil.getSettingFromPortalSettings({
                vm: this,
                settingName: 'registerConfiguration',
                notViewType: true,
                notFeatures: true,
            });

            // Step 2: Massage again to get the specific data you need (Skip if not needed)

            // Step 3: Initial all into compSettings
            let compSettings = {
                registerAge: registerConfig.registerAge ? registerConfig.registerAge : 0,
            };

            this.setState({ compSettings }, () => {
                resolve();
            });
        });
    }

    checkContactsVerification() {
        mu_getContactVerification(this);
    }

    togglePopup(component) {
        gu_togglePopup(this, component);
    }

    submitEditDOB() {
        let { fields, tempFieldDob } = this.state;
        const { user } = this.props;
        // passed props func
        const { checkVerification } = this.props;

        this.setState({ isSubmitting: true });
        let param = {
            id: user.account.id,
            dob: tempFieldDob,
        };

        window.SPL_Member.updateMemberDetails(param).then((res) => {
            if (res.status === 'S') {
                fields.dob = tempFieldDob;
                let _account = user.account;
                _account.dob = fields.dob;
                this.props.dispatch(userAction.updateCertainUser(_account));
                this.setState({ fields, isSubmitting: false });
                checkVerification && checkVerification();
            } else {
                this.setState({ isSubmitting: false });
            }
        });
    }

    onDateChanged(type, field, value) {
        const vm = this;
        let fields = vm.state.fields;

        const dob = fields[field];
        const splitDobData = dob.split('-');

        const tempFieldDob = vm.state.tempFieldDob;
        const splitTempDobData = tempFieldDob && tempFieldDob.split('-');

        // splitTempDobData is the temporarily variable during popup
        let year = splitTempDobData && splitTempDobData[0] ? splitTempDobData[0] : splitDobData[0];
        let month = splitTempDobData && splitTempDobData[1] ? splitTempDobData[1] : splitDobData[1];
        let day = splitTempDobData && splitTempDobData[2] ? splitTempDobData[2] : splitDobData[2];

        if (!value || value === -1) {
            return;
        }

        value = parseInt(value);
        let stateToUpdate = {};
        if (type === 'month') {
            month = value;
            if (month < 10) {
                month = '0' + month;
            }
            stateToUpdate = {
                month: month,
            };
        } else if (type === 'year') {
            year = value;
            stateToUpdate = {
                year: year,
            };
        } else if (type === 'day') {
            day = value;
            if (day < 10) {
                day = '0' + day;
            }
            stateToUpdate = {
                day: day,
            };
        }
        this.setState({ ...stateToUpdate }, () => {
            const { year, month, day } = this.state;
            this.setState({ tempFieldDob: year + '-' + month + '-' + day });
        });
        // this.setState({
        //     // month: Number(month),
        //     // year: Number(year),
        //     // day: Number(day),
        //     tempFieldDob: year + '-' + month + '-' + day, // NOTE: don't want set the main field 1st it will update non-pop up place
        // });
    }

    onEditEmailClick = () => {
        const _config = {
            selectedAuthContact: OCT.EMAIL,
        };
        this.props.dispatch(popupAction.togglePopUp(POPUP_TYPE.EDIT_VERIFY_AUTH, true));
        this.props.dispatch(popupAction.setItemConfig(POPUP_TYPE.EDIT_VERIFY_AUTH, _config));
    };

    onEditPhoneClick = () => {
        const _config = {
            selectedAuthContact: OCT.PHONE,
        };
        this.props.dispatch(popupAction.togglePopUp(POPUP_TYPE.EDIT_VERIFY_AUTH, true));
        this.props.dispatch(popupAction.setItemConfig(POPUP_TYPE.EDIT_VERIFY_AUTH, _config));
    };

    render() {
        const { email, phone, login, name, dob, currency } = this.state.fields || { email: '', phone: '', login: '', name: '', dob: '', currency: '' };
        const { popupCollection, compSettings, day, month, year, isSubmitting } = this.state;
        const { registerAge } = compSettings;
        const { verifications } = this.props.user;
        const { authSettingsReducer } = this.props;
        const _msUpdateMemberInfoModule = authSettingsReducer?.processedData?.[MODULE_TYPE.UPDATE_MEMBER_INFO];

        let editDOBPopupProps = {
            isOpen: popupCollection && popupCollection.editDob,
            registerAge: registerAge,
            fieldName: 'dob',
            day,
            month,
            year,
            isSubmitting: isSubmitting,

            onDateChanged: this.onDateChanged.bind(this),
            submitEditDOB: this.submitEditDOB.bind(this),
            togglePopup: this.togglePopup.bind(this),
        };

        return (
            <Translation>
                {(t) => (
                    <div className='wap-profile-page'>
                        <AuthSettings module={MODULE_TYPE?.UPDATE_MEMBER_INFO} isPublic={false} />

                        {popupCollection && popupCollection.editDob && <EditDOBPopup {...editDOBPopupProps} />}

                        <div className='page-content'>
                            <div className='profile-item-box'>
                                <div className='title-item'>
                                    <span>{t('global:global.form.email')}</span>
                                </div>
                                <div className='profile-details'>
                                    {email}
                                    {_msUpdateMemberInfoModule?.gaEnabled && <EditProfileDetailIcon onClick={this.onEditEmailClick} />}
                                </div>
                            </div>

                            <div className='profile-item-box'>
                                <div className='title-item'>
                                    <span>{t('global:global.form.username')}</span>
                                </div>
                                <div className='profile-details'>{login.toLowerCase()}</div>
                            </div>

                            <div className='profile-item-box'>
                                <div className='title-item'>
                                    <span>{t('global:global.form.dob')}</span>
                                </div>
                                <div className='profile-details'>
                                    {Object.keys(dob).length > 0 && dob}
                                    {verifications && !verifications.birthdayVerified && (
                                        <div className='icon-wrapper' onClick={() => gu_togglePopup(this, 'editDob')}>
                                            <ImageHelper displaySVG={'st-icon-edit'} className={`st-icon-edit`} svgType='SVGElements' isSVG={true} />
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div className='profile-item-box'>
                                <div className='title-item'>
                                    <span>{t('global:global.form.contact')}</span>
                                </div>
                                <div className='profile-details'>
                                    {phone}
                                    {_msUpdateMemberInfoModule?.gaEnabled && <EditProfileDetailIcon onClick={this.onEditPhoneClick} />}
                                </div>
                            </div>

                            <div className='profile-item-box'>
                                <div className='title-item'>
                                    <span>{t('global:global.form.fullname')}</span>
                                </div>
                                <div className='profile-details'>{name}</div>
                            </div>

                            <div className='profile-item-box'>
                                <div className='title-item'>
                                    <span>{t('global:global.form.currency')}</span>
                                </div>
                                <div className='profile-details'>{currency}</div>
                            </div>
                        </div>
                    </div>
                )}
            </Translation>
        );
    }
}
export default connect(mapStateToProps)(withRouter(Profile));
