import React, { Component } from 'react';
import { Translation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import 'react-datepicker/dist/react-datepicker.css';
import { mapStateToProps } from 'root/redux/selector/selector';
import { withRouter } from 'react-router-dom';
import vm from '../../../../16MyAccount/member/instant-rebate/instant-rebate.controller';
import commonUtil from 'root/utils/common-util';

// This component shows the items user checked out from the cart.
class Rebate extends React.Component {
    constructor(props) {
        super();
        this.props = props;
        vm.init(this);
        this.state = {
            isOpen: false,
            product: null,
        };
    }

    componentDidMount() {
        commonUtil.loadCustomTranslation('Rebate', this, function () {});
        vm.getPendingRebateTransaction();
    }

    componentWillUnmount() {
        this.setState = () => {
            return;
        };
    }

    toggle(product) {
        this.setState({ isOpen: !this.state.isOpen, product: product });
    }

    getShowMoreList(data, provider) {
        if (this.state.isOpen && this.state.product === provider) {
            return data;
        }
        return data.slice(0, 1);
    }

    render() {
        const {
            provider,
            pendingRebateTransaction,
            totalPendingClaim,
            lastRebateDate,
            lastClaimDate,
            customTranslationObj,
            isGenerating,
            isCompleted,
            isError,
            disableClaim,
        } = this.state;
        return (
            <Translation>
                {(t) => (
                    <div className='wap-rebase-page'>
                        <div className='page-content'>
                            <div className='rebate-item-box'>
                                <div className='rebate-details'>
                                    <ul>
                                        <li>
                                            <span className='rebate-details-title'>{t('rebate:rebate.totalPendingClaim', 'Total Pending Claim')}</span>
                                            <span className='rebate-details-data'>{totalPendingClaim && totalPendingClaim.toFixed(2)}</span>
                                        </li>
                                        <li>
                                            <span className='rebate-details-title'>{t('rebate:rebate.lastUpdate', 'Last Update Date')}</span>
                                            <span className='rebate-details-data'>{lastRebateDate}</span>
                                        </li>
                                        <li>
                                            <span className='rebate-details-title'>{t('rebate:rebate.lastClaim', 'Last Claimed Date')}</span>
                                            <span className='rebate-details-data'>{lastClaimDate}</span>
                                        </li>
                                        <button
                                            type='submit'
                                            onClick={() => {
                                                vm.handleClaimSubmit();
                                            }}
                                            className='rebate-claim-btn'
                                            disabled={totalPendingClaim === 0 || isGenerating || disableClaim}
                                        >
                                            <span>{t('transaction:transaction.deposit.cashdeposit.submitbtn').toLowerCase()}</span>
                                        </button>

                                        <div className='claim-processing'>
                                            {isGenerating ? (
                                                <span>{t('rebate:rebate.claimInProgress', 'claimInProgress')}</span>
                                            ) : isCompleted ? (
                                                <span>{t('rebate:rebate.claimCompleted', 'claimCompleted')}</span>
                                            ) : (
                                                isError && <span>{t('rebate:rebate.reclaim', 'reclaim')}</span>
                                            )}
                                        </div>
                                    </ul>
                                </div>
                            </div>

                            <div className='rebate-provider-box'>
                                {pendingRebateTransaction && pendingRebateTransaction.length > 0 && (
                                    <>
                                        {pendingRebateTransaction.map((list, i) => (
                                            <>
                                                <div className='rebate-provider-title'>
                                                    {customTranslationObj && customTranslationObj[list.product] ? t(customTranslationObj[list.product]) : list.product}
                                                </div>

                                                {this.getShowMoreList(list.productHistories, list.product).map((hist, i) => (
                                                    <ul>
                                                        <div className='rebate-provider-table'>
                                                            <li>
                                                                <span className='title'>{t('rebate:rebate.memberLevel', 'Membership Level')}</span>
                                                                <span>{hist.memberGroupCode}</span>
                                                            </li>
                                                            <li>
                                                                <span className='title'>{t('rebate:rebate.provider', 'Provider')}</span>
                                                                <span>
                                                                    {customTranslationObj && customTranslationObj[hist.provider]
                                                                        ? t(customTranslationObj[hist.provider])
                                                                        : hist.provider}
                                                                </span>
                                                            </li>
                                                            <li>
                                                                <span className='title'>{t('rebate:rebate.rebate', 'Rebate (%)')}</span>
                                                                <span>{hist.rate}%</span>
                                                            </li>
                                                            <li>
                                                                <span className='title'>{t('rebate:rebate.validBet', 'Valid Bet')}</span>
                                                                <span>{hist.totalEligibleTurnOver}</span>
                                                            </li>
                                                            <li>
                                                                <span className='title'>{t('rebate:rebate.rebateEarned', 'Rebate Earn')}</span>
                                                                <span>{hist.rebateAmount.toFixed(2)}</span>
                                                            </li>
                                                        </div>
                                                    </ul>
                                                ))}
                                                {list.productHistories.length > 1 && (
                                                    <div onClick={() => this.toggle(list.product)}>
                                                        {/* {t('', 'ShowMore')} */}
                                                        <span className='rebate-show-button'>
                                                            {this.state.isOpen && this.state.product === list.product ? t('', 'ShowLess') : t('', 'ShowMore')}
                                                        </span>
                                                    </div>
                                                )}
                                            </>
                                        ))}
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                )}
            </Translation>
        );
    }
}

export default connect(mapStateToProps, null)(withTranslation(['transaction', 'rebate'])(withRouter(Rebate)));
