import React, { Component } from 'react';
import Select from 'react-dropdown-select';
import { Trans, Translation, withTranslation } from 'react-i18next';
import { mapStateToProps } from '../../redux/selector/selector';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import SVGElements from 'root/components/SVGElements';
import Button from '../Share/button/button';
import { gu_handleUploadReceiptButtonOnClick, gu_filterCommonKey } from 'root/utils/general-util';
import { wd_handleReceiptImageChange, wd_handleClearReceipt } from 'root/utils/withdrawal-util';
import commonUtil from 'root/utils/common-util';
import { popupAction } from '@redux/action/popup.action';
import { portalAction, authSettingsAction } from '@redux/action';
import { MODULE_TYPE as MT, POPUP_TYPE, OTP_VERIFICATION_TYPE as OVT } from '@constants';
import notification from '@utils/notification';

class BankDetailModal extends Component {
    isMount = true;
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            bankList: [],
            disabledButton: true,
            selectedUnOwned: [],
            accountNumber: '',
            bankBranch: '',
            bankName: '',
            bankNameErrMsg: [],
            statusMsg: [],
            errors: [],
            receiptImg: [],
            receiptImgFile: null,
            isInvalidUploadFileType: false,

            compSettings: {},
        };
    }

    componentDidMount() {
        this.isMount = true;
        this.initCompSettings();
    }

    componentWillUnmount() {
        this.isMount = false;
        this.setState = () => {
            return;
        };
    }

    initCompSettings = () => {
        return new Promise((resolve) => {
            const { user } = this.props;
            const { currency } = user.account;
            this.setState({}, () => {
                // Step 1: Standard way to get from portal settings
                let tempSettings = commonUtil.getSettingFromPortalSettings({
                    vm: this,
                    settingName: 'withdrawalSettings',
                });
                let reqBankStatementByCurrencies = false;
                if (tempSettings.reqBankStatementByCurrencies) {
                    reqBankStatementByCurrencies = tempSettings.reqBankStatementByCurrencies.indexOf(currency) !== -1;
                }

                // Step 3: Initial all into compSettings
                let compSettings = {
                    reqBankStatementByCurrencies,
                };

                this.setState(
                    {
                        compSettings,
                    },
                    () => {
                        resolve();
                    }
                );
            });
        });
    };

    valdiate = () => {
        const { selectedUnOwned, accountNumber } = this.state;

        if (accountNumber === '' || selectedUnOwned.length === 0) {
            this.setState({ disabledButton: true });
        } else {
            this.setState({ disabledButton: false });
        }
    };
    componentDidUpdate() {}

    isNumber = (event) => {
        return /^\d*$/.test(event.target.value);
    };

    handleBankNameChange = (event) => {
        this.setState({ bankName: event.target.value }, () => {
            this.valdiate();
            this.validateBankName();
        });
    };

    handleBankBranchChange = (event) => {
        this.setState({ bankBranch: event.target.value }, () => {
            this.valdiate();
        });
    };

    handleAccountNumberChange = (event) => {
        if (this.isNumber(event)) {
            this.setState({ accountNumber: event.target.value }, () => {
                this.valdiate();
            });
        }
    };

    handleBankChange = (value) => {
        let showBankName = false;
        if (value.length > 0) {
            if (value[0].bankId === '0' || value[0].bankId === 0 || value[0].bankId === 'o') {
                showBankName = true;
            } else {
                showBankName = false;
            }
        }

        this.setState({ selectedUnOwned: value, showBankName: showBankName, bankName: '' }, () => {
            this.valdiate();
        });
    };

    validateBankName = () => {
        window.SPL_Transaction.validateBankName(this.state.bankName).then((errMsg) => {
            if (this.isMount) {
                this.setState({ bankNameErrMsg: errMsg });
            }
        });
    };

    handleReceiptImageChange(e) {
        wd_handleReceiptImageChange(this, e);
    }

    handleClearReceipt() {
        wd_handleClearReceipt(this);
    }

    isSubmitButtonDisabled() {
        const { disabledButton, compSettings, receiptImg } = this.state;
        const { reqBankStatementByCurrencies } = compSettings;
        const isReceiptValid = reqBankStatementByCurrencies && receiptImg.length <= 0 ? false : true;

        if (disabledButton || !isReceiptValid) {
            return false;
        } else {
            return true;
        }
    }

    handleSubmit = () => {
        // accountName, accountNumber, bankBranch, bankId, bankName

        // used for check if required trigger six_pin
        const handlePreSubmit = () => {
            const { dispatch, authSettingsReducer, popupReducer } = this.props;
            const bankDetailBankAuthSettings = authSettingsReducer?.processedData?.[MT.BANK_DETAIL_BANK];
            const _config = {
                gaEnabled: bankDetailBankAuthSettings?.gaEnabled,
                sixPinEnabled: bankDetailBankAuthSettings?.sixPinEnabled,
            };

            if (bankDetailBankAuthSettings?.sixPinEnabled && !popupReducer?.enteredPin) {
                dispatch(popupAction.setItemConfig(POPUP_TYPE.PIN_ENTRY, _config));
                dispatch(popupAction.togglePopUp(POPUP_TYPE.PIN_ENTRY, true));
                dispatch(popupAction.setSubmitSuccessCb(handleSubmit.bind(this)));
            } else {
                handleSubmit();
            }
        };

        const handleSubmit = () => {
            const { selectedUnOwned, accountNumber, bankBranch, bankName, receiptImgFile } = this.state;
            const { t, dispatch, authSettingsReducer, popupReducer } = this.props;
            const bankDetailBankAuthSettings = authSettingsReducer?.processedData?.[MT.BANK_DETAIL_BANK];

            dispatch(portalAction.setIsSubmitting(true));
            window.SPL_Member.addBankAcc(
                this.props.accountName,
                accountNumber,
                bankBranch,
                selectedUnOwned[0].bankId,
                bankName,
                receiptImgFile,
                popupReducer?.enteredPin,
                OVT.SIX_PIN
            )
                .then((data) => {
                    if (data.status === 'S') {
                        if (this.isMount) {
                            this.setState({ selectedUnOwned: [], accountNumber: '', bankBranch: '', bankName: '' });
                        }
                        this.props.callback(selectedUnOwned[0].bankId);
                        this.props.closeBankModal();
                        this.props.reloadDeposit();
                        this.handleClearReceipt();

                        if (bankDetailBankAuthSettings?.sixPinEnabled) {
                            dispatch(popupAction.togglePopUp(POPUP_TYPE.PIN_ENTRY, false));
                            dispatch(popupAction.setEnteredPin(''));
                        }
                    } else {
                        if (this.isMount) {
                            if (data.errorCode === 2717) {
                                dispatch(authSettingsAction.setSixPinLocked()); // this will trigger affiliate-hook setModuleSixPinLocked
                            }

                            if (data.detail === 'Bank account already in use') {
                                data.detail = t('transaction:transaction.duplicateBankAccount');
                                notification.showNotification('error', t('transaction:transaction.duplicateBankAccount'));
                            } else {
                                notification.showNotification('error', t(data?.errMsg));
                            }

                            this.setState({ statusMsg: [data.detail], disabledButton: false });
                        }
                    }
                    dispatch(portalAction.setIsSubmitting(false));
                })
                .catch(() => {
                    dispatch(portalAction.setIsSubmitting(false));
                });
        };
        handlePreSubmit();
    };

    customNoDataRenderer = ({ props, state, methods }) => (
        <Translation>{(t) => <div className='select-noData'> {t('reports:reports.noDataAvailable', 'No Data Available')}</div>}</Translation>
    );

    render() {
        const { unOwnedBankList, accountName, isOpen, portal } = this.props;
        const { receiptImg, receiptImgFile, compSettings } = this.state;
        const { reqBankStatementByCurrencies } = compSettings;
        const showBankBranch = gu_filterCommonKey(portal?.settings?.showBankBranch);

        return (
            <Translation>
                {(t) => (
                    <div className={`messaging-popup ${isOpen ? 'show' : 'hide'}`}>
                        <div className='popup'>
                            <div className='popup-header'>
                                <div className='popup-header-left'>
                                    <div className='popup-title'>{t('home:home.mobile.account')}</div>
                                </div>
                                <div className='popup-header-right'>
                                    <SVGElements name='close-icon' onClick={() => this.props.closeBankModal()} />
                                    {/* <MdClose onClick={() => this.props.closeBankModal()} /> */}
                                </div>
                            </div>
                            <div className='popup-body'>
                                {/* ///////////////////////////////// SELECT BANK //////////////////////////////// */}
                                <div className='popup-common-field popup-selectbank'>
                                    <p>
                                        {t('bank:bank.bank')} <span className='text-danger asterisk'>*</span>
                                    </p>
                                    <div className='popup-field'>
                                        <Select
                                            noDataRenderer={this.customNoDataRenderer}
                                            placeholder={t('bank:bank.newBank.selectABank')}
                                            options={unOwnedBankList}
                                            labelField='bankName'
                                            valueField='bankId'
                                            key='bankId'
                                            searchable={false}
                                            onChange={this.handleBankChange}
                                            values={this.state.selectedUnOwned}
                                        />
                                    </div>
                                </div>
                                {/* ///////////////////////////////// BANK NAME //////////////////////////////// */}
                                <div className={`popup-common-field popup-bankname ${this.state.showBankName ? '' : 'd-none'}`}>
                                    <p>{t('bank:bank.newBank.bankName')}</p>
                                    <div className='popup-field'>
                                        <input
                                            name='bankName'
                                            className={` ${this.state.bankNameErrMsg.length > 0 ? 'is-invalid' : ''}`}
                                            id='bankName'
                                            placeholder={t('bank:bank.newBank.bankName')}
                                            value={this.state.bankName}
                                            onChange={this.handleBankNameChange}
                                        />
                                        {this.state.bankNameErrMsg.map((errMsg, index) => {
                                            return (
                                                this.state.showBankName && (
                                                    <div key={index} className='invalid-feedback text-danger'>
                                                        <Trans i18nKey={errMsg}></Trans>
                                                    </div>
                                                )
                                            );
                                        })}
                                    </div>
                                </div>
                                {/* ///////////////////////////////// BANK BRANCH //////////////////////////////// */}
                                {showBankBranch && (
                                    <div className='popup-common-field popup-bankbranch'>
                                        <p>{t('bank:bank.branch')}</p>
                                        <div className='popup-field'>
                                            <input
                                                name='bankbranch'
                                                id='bankbranch'
                                                placeholder='Exp : Swan Town'
                                                value={this.state.bankBranch}
                                                onChange={this.handleBankBranchChange}
                                            />
                                        </div>
                                    </div>
                                )}

                                {/* ///////////////////////////////// ACCOUNT HOLDER NAME //////////////////////////////// */}
                                <div className='popup-common-field popup-bankaccname'>
                                    <p>
                                        {' '}
                                        {t('bank:bank.newBank.account-name')} <span className='text-danger asterisk'>*</span>
                                    </p>
                                    <div className='popup-field'>
                                        <input value={accountName} disabled />
                                    </div>
                                </div>

                                {/* ///////////////////////////////// ACCOUNT NUMBER //////////////////////////////// */}
                                <div className='popup-common-field popup-bankaccno'>
                                    <p>
                                        {' '}
                                        {t('bank:bank.newBank.account-number')} <span className='text-danger asterisk'>*</span>
                                    </p>
                                    <div className='popup-field'>
                                        <input
                                            name='accountnumber'
                                            id='accountnumber'
                                            placeholder='Exp : 1234567'
                                            value={this.state.accountNumber}
                                            onChange={this.handleAccountNumberChange}
                                        />
                                    </div>
                                </div>
                                {this.state.statusMsg.map((errMsg, index) => {
                                    return (
                                        <div key={index} className='invalid-feedback text-danger'>
                                            <Trans i18nKey={errMsg}>{errMsg}</Trans>
                                        </div>
                                    );
                                })}

                                {/* ///////////////////////////////// BANK STATEMENT //////////////////////////////// */}
                                {reqBankStatementByCurrencies && (
                                    <div className='popup-common-field popup-bank-statement'>
                                        <p>
                                            {t('transaction:transaction.withdrawal.bankStatement', 'Bank Statement')} <span className='text-danger asterisk'>*</span>
                                        </p>

                                        <div className='popup-field'>
                                            <Button
                                                className='standard-button standard-st-upload-button'
                                                buttonText={t('transaction:transaction.upload')}
                                                onClickFunction={gu_handleUploadReceiptButtonOnClick.bind(this)}
                                                handleReceiptFunction={this.handleReceiptImageChange.bind(this)}
                                                isReceiptInput={true}
                                            />
                                            <div className='reminder-note-container'>
                                                <i className='icon-tip'></i>
                                                <div className='reminder-note'>{t('transaction:transaction:withdrawal.bankStatementRequirement')}</div>
                                            </div>
                                            {receiptImg.length > 0 &&
                                                (receiptImgFile && receiptImgFile.type === 'application/pdf' ? (
                                                    <div className='receipt-image-box'>
                                                        <label>{t('transaction:transaction.deposit.pdf-file-name', 'PDF File Name: ')}</label>
                                                        <span className='pdf-name'>{receiptImgFile.name}</span>
                                                        <img src='/public/images/icon/close-red.png' className='pdf-clear' onClick={() => this.handleClearReceipt()} alt='' />
                                                    </div>
                                                ) : (
                                                    <div className='receipt-image-box'>
                                                        <img src={receiptImg} alt='receipt.png'></img>
                                                    </div>
                                                ))}
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className='popup-footer'>
                                <button className='btn-normal btn-submit-popup' onClick={() => this.handleSubmit()} disabled={!this.isSubmitButtonDisabled()}>
                                    <span>{t('global:entity.action.save')}</span>
                                </button>
                            </div>
                        </div>
                    </div>
                )}
            </Translation>
        );
    }
}

export default connect(mapStateToProps)(withTranslation(['transaction', 'settings', 'bank'])(withRouter(BankDetailModal)));
