import React, { Component } from 'react';
import Select from 'react-dropdown-select';
import { Trans, Translation, withTranslation } from 'react-i18next';
import SmallLoading from '../../components/Loading/SmallLoading';
import { AiOutlineSwap } from 'react-icons/ai';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { mapStateToProps } from '../../redux/selector/selector';
import TransferTab from './10_Transfer_Tab';
import notification from 'root/utils/notification';
class Transfer extends Component {
    isMount = true;
    defaultPromo = {
        id: 0,
        bonusName: <Trans i18nKey={'settings:settings.dropdown.pleaseselect'}></Trans>,
        code: '',
        bonusType: '',
    };
    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.state = {
            activeTab: 'wallet',
            // activeTab: 'wallet',
            iconLoading: false,
            providerList: [],
            totalvalidturnoveramount: 0,
            fromProvider: [],
            toProvider: [],
            fromProviderBalance: '',
            toProviderBalance: '',
            selectedPromotionCode: [],
            promoCodeList: [],
            transferAmount: 0,
            minimumAmount: 0.01,
            mainWallet: null,
            restoreOnLoad: false,
            refreshMainWallet: false,
            isLoad: false,
            showLoading: false,
            active: true,
            isLoading: false,
            promoSelected: false,
            transferTab: 'Provider Balance',
        };
    }

    componentDidMount() {
        this.isMount = true;
        this.refreshAllProviderAndBalance();
        this.loadProviders(true);
        if (this.props.screen.viewType === 'web' && this.props.location.pathname === '/transfer') {
            this.props.history.push('/myaccount/transfer');
        } else if (this.props.screen.viewType === 'mobile' && this.props.location.pathname === '/myaccount/transfer') {
            this.props.history.push('/fund');
            this.props.history.push('/transfer');
        }
    }

    componentWillUnmount() {
        this.isMount = false;
    }

    refreshAllProviderAndBalance = (toEmptyProvider) => {
        if (toEmptyProvider !== false) {
            this.setState({ providerList: [] });
        }
        this.loadProviders(true);
        this.loadPromotions();
    };

    componentDidUpdate(prevProps) {
        if (this.props.toUpdate) {
            this.setState({ reloadOnUpdate: false }, () => {
                this.refreshAllProviderAndBalance(false);
                this.props.toUpdateProviderInfo(false);
            });
        }
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab,
            });
        }

        if (tab === 'transfer') {
            this.loadPromotions(null);
            if (this.isMount) {
                this.setState({
                    selectedPromotionCode: [this.defaultPromo],
                    transferAmount: this.state.fromProvider[0].balance,
                });
            }
        }
    }

    state = {
        value: { label: this.props.val, value: this.props.val },
    };

    swapProvider = () => {
        let from = this.state.fromProvider;
        let to = this.state.toProvider;

        this.setState({ fromProvider: to, toProvider: from }, this.handleFromProviderChange(to), this.handleToProviderChange(from));
    };

    handleAmountChange = (event) => {
        let value = event.target.value;
        if (value) {
            if (value.toString().match(/^(\d*)\.{0,1}(\d){0,2}$/)) {
                this.setState({ transferAmount: value });
            }
        } else {
            this.setState({ transferAmount: value });
        }
    };

    handleFromProviderChange = (value) => {
        let provider = value[0];
        if (provider) {
            // if provider null then get main wallet
            this.getMinimumAmountForTransfer(provider.provider, this.state.toProvider[0].provider, null);
            this.setState({ fromProvider: value, transferAmount: provider.balance, fromProviderBalance: provider.balance });
        }
    };

    handleToProviderChange = (value) => {
        let provider = value[0];
        if (provider) {
            this.getMinimumAmountForTransfer(this.state.fromProvider[0].provider, provider.provider, null);
            this.setState({ toProvider: value, toProviderBalance: provider.balance }, () => {
                if (!(provider.provider === null || provider.provider === '')) {
                    this.loadPromotions(provider.provider);
                }
            });
        }
    };

    handlePromotionCodeChange = (value) => {
        this.setState({ selectedPromotionCode: value, promoSelected: true }, () => {
            if (value.length > 0 && value[0].id !== 0) {
                this.getMinimumAmountForTransfer(this.state.fromProvider[0].provider, this.state.toProvider[0].provider, value[0]);
            }
        });
    };

    enterLoading = () => {
        this.setState({ loading: !this.state.loading });
    };

    enterIconLoading = () => {
        this.setState({ iconLoading: !this.state.iconLoading });
    };

    loadProviders = (isLoadAsyncProviderWallet) => {
        this.setState({ iconLoading: true });
        window.SPL_Provider.getAllProviderWalletList(null, false, this.props)
            .then((data) => {
                if (this.isMount) {
                    let providerList = data.providerList;
                    let fromProvider;
                    let toProvider;
                    const { currency } = this.props.user.account || { currency: '' };
                    const { t } = this.props;
                    for (let i = 0; i < providerList.length; i++) {
                        let balance = providerList[i].balance;
                        if (balance) {
                            balance = window.SPL_Other.formatAmount(providerList[i].balance);
                        } else {
                            balance = 0;
                        }
                        // providerList[i].provider = `${providerList[i].provider === null ? null : providerList[i].provider}`;
                        providerList[i].displayName = `${providerList[i].provider === null ? t('settings:settings.mywallet') : providerList[i].provider}`;

                        providerList[i].display = `${providerList[i].name ? providerList[i].name : t('settings:settings.mywallet')} (${
                            window.SPL_Other.formatAmount(balance) || window.SPL_Other.formatAmount('0')
                        } ${currency}) 
                                         ${t(providerList[i].serverMaintenanceStr)}`;

                        if (providerList[i].provider === null) {
                            fromProvider = [providerList[i]];
                            toProvider = [providerList[i]];
                        }
                    }
                    this.setState({
                        providerList: data.providerList,
                        totalvalidturnoveramount: data.totalvalidturnoveramount,
                        transferAmount: window.SPL_Other.formatAmount(fromProvider[0].balance) || window.SPL_Other.formatAmount('0'),
                        fromProvider: fromProvider,
                        fromProviderBalance: fromProvider[0].balance,
                        toProvider: toProvider,
                        toProviderBalance: toProvider[0].balance,
                        iconLoading: false,
                    });
                }
            })
            .then(() => {
                if (isLoadAsyncProviderWallet) {
                    this.loadAsyncProviderWallet();
                }
            });
    };

    loadAsyncProviderWallet = () => {
        if (this.isMount) {
            this.setState({ isLoad: true }, () => {
                window.SPL_Provider.loadAsyncProviderWallet(null, this.props).then((providerWallet) => {
                    let providerList = this.state.providerList;
                    for (let i = 0; i < providerList.length; i++) {
                        let results = providerWallet.providerList.filter(function (e) {
                            return e.provider === providerList[i].provider;
                        })[0];

                        if (results) {
                            providerList[i].balance = results.balance ? results.balance : 0;
                            providerList[i].serverIsUnderMaintenance = results.serverIsUnderMaintenance;
                            providerList[i].isdisplay = true;
                            providerList[i].serverMaintenanceStr = results.serverIsUnderMaintenance ? 'error:error.underMaintainance' : '';
                        } else {
                            providerList[i].serverMaintenanceStr = '';
                        }
                        if (this.isMount) {
                            this.setState({ providerList: providerList });
                        }
                    }
                });
            });
        }
    };

    loadPromotions = (provider) => {
        const { countryLanguageKey } = this.props.language;
        const { portal, screen } = this.props;
        
        if (provider) {
            let platform = null;
  
            if (portal && portal.settings && portal.settings.getPromoCodeByPlatform) {
                if (window.SPL_LiteApp.isZT828LiteApp()) {
                  platform = 'A';
                } else if (screen.viewType === 'web') {
                  platform = 'W';
                } else {
                  platform = 'M';
                }
            }

            window.SPL_Provider.loadPromotions(provider, null, countryLanguageKey, platform).then((promoCodeList) => {
                if (this.isMount) {
                    promoCodeList.unshift(this.defaultPromo);
                    let pl = this.state.promoCodeList;
                    pl = [];
                    for (let i = 0; i < promoCodeList.length; i++) {
                        if (promoCodeList[i].id !== 0) {
                            pl.push(promoCodeList[i]);
                        }
                    }
                    this.setState(
                        {
                            promoCodeList: pl,
                        },
                        () => {
                            this.setState({ selectedPromotionCode: [this.defaultPromo] });
                        }
                    );
                }
            });
        } else {
            let promoCodeList = [];
            promoCodeList.unshift(this.defaultPromo);
            if (this.isMount) {
                let pl = this.state.promoCodeList;
                pl = [];
                for (let i = 0; i < promoCodeList.length; i++) {
                    if (promoCodeList[i].id !== 0) {
                        pl.push(promoCodeList[i]);
                    }
                }
                this.setState(
                    {
                        promoCodeList: pl,
                    },
                    () => {
                        this.setState({ selectedPromotionCode: [this.defaultPromo] });
                    }
                );
            }
        }
    };

    requestTransfer = (provider) => {
        this.setState(
            { iconLoading: true },
            () => {
                const { transferAmount, selectedPromotionCode, toProvider, fromProvider, promoSelected } = this.state;
                const { t } = this.props;
                let promotionId = null;
                if (promoSelected) {
                    promotionId = selectedPromotionCode[0].id;
                } else {
                    promotionId = 0;
                }

                // if provider is not null, transfer is from balance tab, and only applicable for mainwallet to provider
                let toProviderValue = provider ? provider.provider : toProvider[0].provider;
                let fromProviderValue = provider ? null : fromProvider[0].provider;
                let remark = fromProviderValue === null ? 'Member Deposit' : 'Member Withdraw';

                this.setState({ showLoading: true }, () => {
                    let mainWalletBalance = this.state.providerList.filter((e) => e.provider === null)[0].balance;
                    //  mainWalletBalance, transferAmount, fromProvider, toProvider, promotion, blockProvidersFlag
                    window.SPL_Transaction.validateTransferForm(mainWalletBalance, transferAmount, fromProvider[0], toProvider[0], selectedPromotionCode[0], false).then(
                        (validateRes) => {
                            if (validateRes.status === 'F') {
                                notification.showNotification('error', t(validateRes.msg, { amount: validateRes.amount || '' }));
                                this.setState({ showLoading: false });
                            } else {
                                window.SPL_Transaction.requestTransfer(transferAmount, promotionId, remark, toProviderValue, fromProviderValue).then((data) => {
                                    this.setState({ showLoading: false });
                                    if (data.status === 'F') {
                                        if (data.msg && data.msg.data && data.msg.data.detail) {
                                            notification.showNotification('error', t(data.msg.data.detail));
                                        } else {
                                            notification.showNotification('error', t(data.msg));
                                        }
                                    } else {
                                        if (this.isMount) {
                                            this.setState({
                                                transferAmount: '',
                                                selectedPromotionCode: [this.state.promoCodeList[0]],
                                                refreshMainWallet: true,
                                            });
                                            setTimeout(() => {
                                                // for desktop need to use parent get main wallet to update amount for parent component also
                                                if (this.props.getMainWallet) {
                                                    this.props.getMainWallet().then((res) => {
                                                        this.refreshAllProviderAndBalance(false);
                                                    });
                                                } else {
                                                    this.getMainWallet().then((res) => {
                                                        this.refreshAllProviderAndBalance(false);
                                                    });
                                                }
                                            }, 500);
                                        }
                                    }
                                });
                            }
                        }
                    );
                });
            },
            () => {
                this.setState({ iconLoading: false });
            }
        );
    };

    requestTransferAllInToProvider = (provider) => {
        const { t } = this.props;
        let mainWallet = this.state.providerList.filter((e) => e.provider === null)[0];
        let transferAmount = mainWallet.balance;
        if (transferAmount <= '0') {
            notification.showNotification('error', t('transaction:transaction.transfer.insuffficientMainWallet'));
            return;
        }
        let remark = 'Member Deposit';

        this.setState({ showLoading: true, iconLoading: true, restoreOnLoad: true }, () => {
            window.SPL_Transaction.requestTransfer(transferAmount, 0, remark, provider, null).then((data) => {
                this.setState({ showLoading: false, iconLoading: false });
                if (data.status === 'F') {
                    if (data.msg && data.msg.data && data.msg.data.detail) {
                        notification.showNotification('error', t(data.msg.data.detail));
                    } else {
                        notification.showNotification('error', t(data.msg));
                    }
                } else {
                    this.setState({
                        transferAmount: 0,
                        selectedPromotionCode: [this.state.promoCodeList && this.state.promoCodeList.length > 0 ? this.state.promoCodeList[0] : ''],
                        refreshMainWallet: true,
                        providerList: [],
                    });
                    this.loadPromotions();
                    // if can gett from props then use props
                    if (this.props.getMainWallet) {
                        this.props.getMainWallet();
                    } else {
                        this.getMainWallet();
                    }
                }
            });
        });
    };
    getMainWallet = () => {
        this.setState({ iconLoading: true, restoreOnLoad: true });
        let component = this;
        return new Promise(function (resolve, reject) {
            window.SPL_Member.getMainWallet()
                .then((mainWallet) => {
                    if (component.isMount) {
                        component.setState({
                            mainWallet: mainWallet.data,
                            iconLoading: false,
                            restoreOnLoad: false,
                        });
                        component.loadProviders(true);
                        resolve(mainWallet.data);
                    }
                })
                .catch((err) => {
                    if (component.isMount) {
                        component.setState({ iconLoading: false, restoreOnLoad: false });
                    }
                });
        });
    };

    restoreWallet = () => {
        this.setState({ restoreOnLoad: true });
        window.SPL_Transaction.transferAllBackMainWallet()
            .then(() => {
                setTimeout(() => {
                    // if can gett from props then use props
                    if (this.props.getMainWallet) {
                        this.props.getMainWallet();
                    } else {
                        this.getMainWallet();
                    }
                    this.setState({ restoreOnLoad: false });
                }, 5000);
            })
            .catch(() => {
                setTimeout(() => {
                    this.setState({ restoreOnLoad: false });
                    // if can gett from props then use props
                    if (this.props.getMainWallet) {
                        this.props.getMainWallet();
                    } else {
                        this.getMainWallet();
                    }
                }, 5000);
            });
    };

    toggleVisibility = () => this.setState((prevState) => ({ isActive: !this.state.isActive }));

    getMinimumAmountForTransfer = (fromProvider, toProvider, promo) => {
        let minimumAmount = window.SPL_Transaction.getMinimumAmountForTransfer(fromProvider, toProvider, promo);
        this.setState({ minimumAmount: minimumAmount });
    };

    afterCompleteRefreshMainWallet = () => {
        this.setState({ refreshMainWallet: false });
    };

    setTransferAmountFromMainWallet = (toProvider) => {
        let mainWallet = this.state.providerList.filter((e) => e.provider === null)[0];
        this.setState({ transferAmount: mainWallet.balance, fromProvider: [mainWallet], toProvider: [toProvider] });
    };
    changeColor() {
        this.setState({ active: !this.state.active });
    }

    swapProvider = () => {
        let from = this.state.fromProvider;
        let to = this.state.toProvider;

        this.setState({ fromProvider: to, toProvider: from }, () => {
            this.handleFromProviderChange(to);
            this.handleToProviderChange(from);
        });
    };

    customNoDataRenderer = ({ props, state, methods }) => (
        <Translation>{(t) => <div className='select-noData'> {t('reports:reports.noDataAvailable', 'No Data Available')}</div>}</Translation>
    );

    render() {
        const { restoreOnLoad } = this.state;
        const { iconLoading } = this.state;
        return (
            <Translation>
                {(t) =>
                    this.props.user.account && (
                        <div className='app-transfer'>
                            <div className='d-none d-md-block transfer-bg'>
                                <div className='row d-flex justify-content-center'>
                                    <div className='account-col d-flex justify-content-center p-l-0 p-r-0 p-t-0'>
                                        {/* <TabContent className='w-100'>
											<Nav tabs className='subTransfer-bg d-flex justify-content-center'>
												<Col className='d-flex justify-content-center align-items-center p-0'>
													<NavItem
														className='d-flex justify-content-center align-items-center h-100 w-100'
														onClick={() => {
															this.toggle('wallet');
														}}>
														<NavLink
															className={classnames({
																active: this.state.activeTab === 'wallet',
																navlinkTransferForm: true,
															})}>
															<div className='transfer-tab w-100'>
																<div className='w-100 d-flex justify-content-center'>
																	<div className={`${!this.state.restoreOnLoad ? 'd-flex subTransfer-amount align-items-center' : 'd-none '}`}>
																		{this.props.user && this.props.user.account && this.props.user.account.currency && (
																			<h5 className=' title'>{this.props.user.account.currency}</h5>
																		)}
																		<h3 className='m-l-5 m-r-5 title'>
																			{(this.props.mainWallet && window.SPL_Other.formatAmount(this.props.mainWallet.balance)) || window.SPL_Other.formatAmount('0')}
																		</h3>
																	</div>
																	<div className={`${this.state.restoreOnLoad ? 'd-flex' : 'd-none'}`}>
																		<SmallLoading></SmallLoading>
																	</div>
																</div>

																{t('settings:settings.wallet')}
															</div>
														</NavLink>
													</NavItem>
												</Col>

												<Col className='d-flex justify-content-center align-items-center p-0'>
													<NavItem
														className='d-flex justify-content-center align-items-center h-100 w-100'
														onClick={() => {
															this.toggle('turnover');
														}}>
														<NavLink
															className={classnames({
																active: this.state.activeTab === 'turnover',
																navlinkTransferForm: true,
															})}>
															<div className='transfer-tab w-100'>
																<div className='w-100 d-flex justify-content-center'>
																	<div className='d-flex subTransfer-amount align-items-center'>
																		{this.props.user && this.props.user.account && this.props.user.account.currency && (
																			<h5 className='title'>{this.props.user.account.currency}</h5>
																		)}
																		<h3 className='m-l-5 m-r-5 title'>{window.SPL_Other.formatAmount(this.state.totalvalidturnoveramount)}</h3>
																	</div>
																</div>

																{t('transaction:transaction.transfer.weeklyTurnoverTotal')}
															</div>
														</NavLink>
													</NavItem>
												</Col>
											</Nav>

											{this.state.providerList.length > 0 && !this.state.showLoading && this.state.activeTab === 'wallet' && (
												<TabPane tabId='wallet' className='transfer-content'>
													<Row className='d-flex transfer-item'>
														{' '}
														{this.state.providerList.map((provider, index) => {
															return (
																provider.name && (
																	<Col key={index} id={provider.provider} xs='6' sm='6' lg='3' md='3' className='transfer-item-box text-center'>
																		<div>
																			{' '}
																			<h5 className='subTransfer-amount font-weight-bold'>{provider.balance ? provider.balance.toFixed(2) : (0).toFixed(2)}</h5>
																			<h6 className='providertransfer-text'>{provider.name}</h6>
																		</div>
																	</Col>
																)
															);
														})}
													</Row>
												</TabPane>
											)}
											{this.state.providerList.length > 0 && !this.state.showLoading && this.state.activeTab === 'turnover' && (
												<TabPane tabId='turnover' className=' transfer-content'>
													<Row className='d-flex transfer-item'>
														{!this.state.showLoading &&
															this.state.providerList.map((provider, index) => {
																return (
																	provider.name && (
																		<Col key={index} id={provider.provider} xs='6' sm='6' lg='3' md='3' className=' transfer-item-box text-center'>
																			<div>
																				{provider.turnover === 0 ? (
																					<h5 className='subTransfer-amount font-weight-bold'>-</h5>
																				) : (
																					<h5 className='subTransfer-amount font-weight-bold'>
																						{window.SPL_Other.formatAmount(provider.turnover) || window.SPL_Other.formatAmount('0')}
																					</h5>
																				)}
																				<h6 className='providertransfer-text'>{provider.name}</h6>
																			</div>
																		</Col>
																	)
																);
															})}
													</Row>
												</TabPane>
											)}
											{(this.state.showLoading || this.state.providerList.length <= 0) && <Loading height='vh-50'></Loading>}
										</TabContent> */}
                                    </div>
                                </div>
                                {this.state.providerList.length > 0 && !this.state.showLoading && (
                                    <div className='transfer-form-content d-flex justify-content-center'>
                                        <Col xs={12} md={12}>
                                            <div className='d-flex transfer-from-to'>
                                                <div className='form-group transfer-left-form'>
                                                    <div className='d-flex align-items-center'>
                                                        <div className='transfer-form-label'>
                                                            <label htmlFor='TransferFrom' className='form-label'>
                                                                {t('transaction:transaction.transfer.from')}
                                                                {/* <span className='text-danger m-r-5'>*</span> */}
                                                            </label>
                                                        </div>
                                                        <div className='transfer-form-input'>
                                                            <Select
                                                                className='form-control-inner'
                                                                placeholder='Transfer From'
                                                                options={this.state.providerList}
                                                                values={this.state.fromProvider}
                                                                labelField='displayName'
                                                                valueField='provider'
                                                                key='provider'
                                                                onChange={(value) => this.handleFromProviderChange(value)}
                                                                searchable={false}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className='d-flex align-items-center input-spacing'>
                                                        <div className='transfer-form-label'></div>
                                                        <div className='transfer-form-input'>
                                                            <div className='form-control-inner disable-input'>
                                                                <input
                                                                    name='fromAmount'
                                                                    className='border-0 w-100 '
                                                                    id='fromAmount'
                                                                    placeholder={t('global:global.form.accountamount-placeholder')}
                                                                    type='number'
                                                                    min='0'
                                                                    value={window.SPL_Other.formatAmount(this.state.fromProviderBalance)}
                                                                    disabled
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className=' form-group transfer-right-form'>
                                                    <div className='d-flex align-items-center'>
                                                        <div className='transfer-form-label transfer-to'>
                                                            <div className='d-flex justify-content-center'>
                                                                <AiOutlineSwap className=' swap-icon-desktop' onClick={() => this.swapProvider()}></AiOutlineSwap>
                                                            </div>
                                                            <label htmlFor='TransferTo' className='form-label'>
                                                                {t('transaction:transaction.transfer.to')}
                                                                {/* <span className='text-danger m-r-5'>*</span> */}
                                                            </label>
                                                        </div>
                                                        <div className='transfer-form-input'>
                                                            <Select
                                                                className='form-control-inner'
                                                                placeholder={t('settings:settings.dropdown.pleaseselect')}
                                                                options={this.state.providerList}
                                                                labelField='displayName'
                                                                valueField='provider'
                                                                values={this.state.toProvider}
                                                                onChange={(value) => this.handleToProviderChange(value)}
                                                                searchable={false}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className='d-flex align-items-center input-spacing'>
                                                        <div className='transfer-form-label'></div>
                                                        <div className='transfer-form-input'>
                                                            <div className='form-control-inner disable-input'>
                                                                <input
                                                                    name='toAmount'
                                                                    className='border-0 w-100 '
                                                                    id='toAmount'
                                                                    placeholder={t('global:global.form.accountamount-placeholder')}
                                                                    type='number'
                                                                    min='0'
                                                                    value={window.SPL_Other.formatAmount(this.state.toProviderBalance)}
                                                                    disabled
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='transfer-left-form special-code'>
                                                <div className='d-flex align-items-center'>
                                                    <div className='transfer-form-label'>
                                                        <label htmlFor='specialCode' className='form-label'>
                                                            {t('transaction:transaction.transfer.specialCode')}
                                                            {/* <span className='text-danger m-r-5'>*</span> */}
                                                        </label>
                                                    </div>
                                                    <div className='transfer-form-input'>
                                                        <div className='form-control-inner'>
                                                            <input
                                                                name='specialCode'
                                                                className={`border-0 w-100
															 ${this.state.amountError ? ' is-invalid' : ''}`}
                                                                id='specialCode'
                                                                placeholder={t('transaction:transaction.transfer.specialCode')}
                                                                type='text'
                                                                //value={this.state.transferAmount}
                                                                //onChange={this.handleAmountChange}
                                                                //onBlur={this.validateAmount}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='transfer-left-form promo-code'>
                                                <div className='d-flex align-items-center'>
                                                    <div className='transfer-form-label'>
                                                        <label htmlFor='promocode' className='form-label'>
                                                            {t('transaction:transaction.transfer.promocode', 'Promo Code')}
                                                        </label>
                                                    </div>
                                                    <div className='transfer-form-input'>
                                                        <Select
                                                            noDataRenderer={this.customNoDataRenderer}
                                                            className='form-control-inner'
                                                            placeholder={t('settings:settings.dropdown.pleaseselect')}
                                                            options={this.state.promoCodeList}
                                                            labelField='bonusName'
                                                            valueField='code'
                                                            values={this.state.defaultPromo}
                                                            onChange={(value) => this.handlePromotionCodeChange(value)}
                                                            searchable={false}
                                                        />
                                                    </div>
                                                    {/* <div className='promo-details'>
                            <a href=''>Promo Details</a>
                          </div> */}
                                                </div>
                                                <div className='d-flex align-items-center input-spacing'>
                                                    <div className='transfer-form-label'></div>
                                                    <div className='transfer-form-input'>
                                                        <div className='form-control-inner disable-input'>
                                                            <input
                                                                name='promoAmount'
                                                                className='border-0 w-100 '
                                                                id='promoAmount'
                                                                placeholder={t('global:global.form.accountamount-placeholder')}
                                                                type='number'
                                                                min='0'
                                                                //value={window.SPL_Other.formatAmount(this.state.toProviderBalance)}
                                                                disabled
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='transfer-left-form transfer-amount'>
                                                <div className='d-flex align-items-center'>
                                                    <div className='transfer-form-label'>
                                                        <label htmlFor='transferAmount' className='form-label'>
                                                            {t('popupTransfer:popupTransfer.transferAmount')}
                                                            {/* <span className='text-danger m-r-5'>*</span> */}
                                                        </label>
                                                    </div>
                                                    <div className='transfer-form-input '>
                                                        <div className='form-control-inner outline-input'>
                                                            <input
                                                                name='transferAmount'
                                                                className={`border-0 w-100
															 ${this.state.amountError ? ' is-invalid' : ''}`}
                                                                id='transferAmount'
                                                                placeholder=''
                                                                type='text'
                                                                //value={this.state.transferAmount}
                                                                //onChange={this.handleAmountChange}
                                                                //onBlur={this.validateAmount}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className='all-in'>
                                                        <button className='button-allin'>
                                                            <span>ALL IN</span>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='transfer-left-form transfer-button-group'>
                                                <div className='d-flex align-items-center'>
                                                    <div className='transfer-form-label'></div>
                                                    <div className='transfer-form-input d-flex align-items-center '>
                                                        <button
                                                            type='submit'
                                                            disabled={this.state.disabledDepositButton}
                                                            onClick={() => this.requestTransfer()}
                                                            className='primary-btn button-submit border-0 button-account reset-btn'
                                                        >
                                                            <span>{t('register:register.form.reset')}</span>
                                                        </button>
                                                        <button
                                                            type='submit'
                                                            disabled={this.state.disabledDepositButton}
                                                            onClick={() => this.requestTransfer()}
                                                            className='primary-btn button-submit border-0 button-account'
                                                        >
                                                            <span>{t('home:home.mobile.transfer')}</span>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>

                                            <TransferTab providerList={this.state.providerList} />
                                        </Col>
                                    </div>
                                )}
                            </div>

                            {/*  MobileView */}

                            <div className='content-mobile-account d-block d-md-none content'>
                                <div className='content-inner'>
                                    {this.state.iconLoading && (
                                        <div className='w-100 h-100 d-flex justify-content-center align-items-center'>
                                            <SmallLoading></SmallLoading>
                                        </div>
                                    )}
                                    {this.state.providerList.length > 0 && !this.state.showLoading && (
                                        <div className='mobile-account-width d-flex justify-content-center'>
                                            <Col xs={12} md={5}>
                                                <div>
                                                    <Row className='form-group'>
                                                        <Col xs={12} md={12} lg={12} className='form-spacing form-group'>
                                                            <div className=''>
                                                                <Col xs={12} md={4}>
                                                                    <label htmlFor='TransferFrom' className='form-label mTransfer-text'>
                                                                        {t('transaction:transaction.transfer.from')}
                                                                        <span className='text-danger m-r-5'>*</span>
                                                                    </label>
                                                                </Col>
                                                                <Col xs={12} md={8}>
                                                                    <Select
                                                                        className='form-control-inner'
                                                                        placeholder='Transfer From'
                                                                        options={this.state.providerList}
                                                                        values={this.state.fromProvider}
                                                                        labelField='display'
                                                                        valueField='provider'
                                                                        key='provider'
                                                                        onChange={(value) => this.handleFromProviderChange(value)}
                                                                        searchable={false}
                                                                    />
                                                                </Col>
                                                            </div>
                                                        </Col>

                                                        {/* <Col xs={12} md={12} lg={12} className='d-flex justify-content-center'>
															<AiOutlineSwap className='icon-sm p-l-5 p-r-5' onClick={() => this.swapProvider()}></AiOutlineSwap>
														</Col> */}

                                                        <Col xs={12} md={12} lg={12} className='form-spacing form-group'>
                                                            <div className=''>
                                                                <Col xs={12} md={4}>
                                                                    <label htmlFor='TransferTo' className='form-label mTransfer-text'>
                                                                        {t('transaction:transaction.transfer.to')}
                                                                        <span className='text-danger m-r-5'>*</span>
                                                                    </label>
                                                                </Col>
                                                                <Col xs={12} md={7}>
                                                                    <Select
                                                                        className='form-control-inner'
                                                                        placeholder={t('settings:settings.dropdown.pleaseselect')}
                                                                        options={this.state.providerList}
                                                                        labelField='display'
                                                                        valueField='provider'
                                                                        values={this.state.toProvider}
                                                                        onChange={(value) => this.handleToProviderChange(value)}
                                                                        searchable={false}
                                                                    />
                                                                </Col>
                                                            </div>
                                                        </Col>

                                                        <Col xs={12} md={12} lg={12} className='form-spacing form-group'>
                                                            <div>
                                                                <Col xs={12} md={4}>
                                                                    <label htmlFor='Amount' className='form-label mTransfer-text'>
                                                                        {t('transaction:transaction.transfer.amount')}
                                                                        <span className='text-danger m-r-5'>*</span>
                                                                    </label>
                                                                </Col>
                                                                <Col xs={12} md={7}>
                                                                    <div className='form-control-inner'>
                                                                        <input
                                                                            name='affilate'
                                                                            className={`border-0
																			 ${this.state.amountError ? ' is-invalid' : ''}`}
                                                                            id='name'
                                                                            placeholder={t('global:global.form.accountamount-placeholder')}
                                                                            type='number'
                                                                            min='0'
                                                                            value={this.state.transferAmount}
                                                                            onChange={this.handleAmountChange}
                                                                            onBlur={this.validateAmount}
                                                                        />
                                                                    </div>
                                                                </Col>
                                                            </div>
                                                        </Col>

                                                        <Col xs={12} md={12} lg={12} className='form-spacing form-group'>
                                                            <div>
                                                                <Col xs={12} md={4}>
                                                                    <label htmlFor='promocode' className='form-label mTransfer-text'>
                                                                        {t('transaction:transaction.transfer.promocode', 'Promo Code')}
                                                                    </label>
                                                                </Col>
                                                                <Col xs={12} md={7}>
                                                                    <Select
                                                                        className='form-control-inner'
                                                                        placeholder={t('settings:settings.dropdown.pleaseselect')}
                                                                        options={this.state.promoCodeList}
                                                                        labelField='bonusName'
                                                                        valueField='code'
                                                                        noDataRenderer={this.customNoDataRenderer}
                                                                        values={this.state.defaultPromo}
                                                                        onChange={(value) => this.handlePromotionCodeChange(value)}
                                                                        searchable={false}
                                                                    />
                                                                    {/* <input
																		name='specialcode'
																		className={`form-control-inner ${this.state.amountError ? 'is-invalid' : ''}`}
																		placeholder='Special Code'
																	/> */}
                                                                </Col>
                                                            </div>
                                                        </Col>
                                                        <Col xs={12} md={12} lg={12} className='d-flex justify-content-end button-group form-spacing'>
                                                            <div className='w-100 d-flex'>
                                                                <Col xs={12} md={12} className='justify-content-start'>
                                                                    <button
                                                                        type='submit'
                                                                        disabled={this.state.disabledDepositButton}
                                                                        onClick={() => this.requestTransfer()}
                                                                        className='primary-btn button-submit border-0'
                                                                    >
                                                                        <span>{t('home:home.mobile.transfer')}</span>
                                                                    </button>
                                                                </Col>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Col>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    )
                }
            </Translation>
        );
    }
}

export default connect(mapStateToProps)(withTranslation(['transaction', 'settings', 'reports', 'register', 'popupTransfer'])(withRouter(Transfer)));
