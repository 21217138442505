import React, { Component } from 'react';
import { Translation, withTranslation } from 'react-i18next';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import 'slick-carousel/slick/slick.css';
import SmallLoading from '../../components/Loading/SmallLoading';
import { mapStateToProps } from '../../redux/selector/selector';
import { myAccountMobile } from '../01Home/012_Home_Content_Item_Data';
import { imgCoinsTH9 } from '../16MyAccount/161_MyAccount_Items_Data';
import SVGMyAcc from '../../components/Icon/SVGMyAcc';
import SVGMyFund from '../../components/Icon/SVGMyFund';

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return <div className={className} style={{ ...style, display: 'block' }} onClick={onClick} />;
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return <div className={className} style={{ ...style, display: 'block' }} onClick={onClick} />;
}

const defaultSettings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  nextArrow: <SampleNextArrow />,
  prevArrow: <SamplePrevArrow />,
  isLoading: false,
  providerList: [],
};

class MyAccountMobile extends Component {
  isMount = true;
  constructor(props) {
    super(props);
    this.state = {
      settings: defaultSettings,
      mainWallet: null,
      restoreOnLoad: false,
      account: null,
    };
  }

  componentDidMount() {
    this.isMount = true;
    this.getMainWallet();
    this.identity();
  }

  componentWillUnmount() {
    this.isMount = false;
  }

  identity = () => {
    const { account } = this.props.user;
    if (this.isMount) {
      if (this.props.user.userName !== '') {
        this.setState({ account: this.props.user.userName });
      } else {
        this.setState({ account: this.props.user.account.login.toLowerCase() });
      }
    }
  };

  getMainWallet = () => {
    this.setState({ iconLoading: true, restoreOnLoad: true });
    let component = this;
    return new Promise(function (resolve, reject) {
      window.SPL_Member.getMainWallet()
        .then((mainWallet) => {
          if (component.isMount) {
            component.setState({
              mainWallet: mainWallet.data,
              iconLoading: false,
              restoreOnLoad: false,
            });

            resolve(mainWallet.data);
          }
        })
        .catch((err) => {
          if (component.isMount) {
            component.setState({ iconLoading: false, restoreOnLoad: false });
          }
        });
    });
  };

  restoreWallet = () => {
    this.setState({ restoreOnLoad: true });
    window.SPL_Transaction.transferAllBackMainWallet()
      .then(() => {
        this.getMainWallet();
        this.setState({ restoreOnLoad: false });
      })
      .catch(() => {
        this.setState({ restoreOnLoad: false });
        this.getMainWallet();
      });
  };

  render() {
    const { restoreOnLoad } = this.state;
    return (
      <Translation>
        {(t) => (
          <div className='account-content d-block d-md-none content'>
            <div className='app-myaccount-mobile content-inner'>
              <div className='myaccount-mobile-content'>
                <div>
                  <div className='wallet'>
                    <Row className=' d-flex align-items-center'>
                      <Col xs='12' sm='12' className='d-flex justify-content-center mobile-memberDetail'>
                        <Col xs='6' sm='6' className='mobile-wallet-icon h-100 d-flex justify-content-end align-items-center'>
                          <LazyLoadImage src={imgCoinsTH9} className='icon-lg' />
                        </Col>
                        <Col xs='6' sm='6' className='d-flex justify-content-start h-100'>
                          <Col xs='6' sm='6' className='h-100 d-flex align-items-center'>
                            <div>
                              <div className='d-flex w-100 h5 align-items-center mobile-memberDetail-item'>
                                {this.state.account}
                                <svg className='mobile-memberDetail-icon icon-xs m-l-5' aria-hidden='true'>
                                  <SVGMyFund name='verified' />
                                </svg>
                              </div>

                              <div className='d-flex w-100 mobile-memberDetail-level d-flex align-items-center'>
                                {this.props.user.account.groupName}
                              </div>
                            </div>
                          </Col>
                        </Col>
                      </Col>
                    </Row>
                    <Row className='d-flex align-items-center'>
                      <Col xs='12' sm='12' className='d-flex justify-content-center mobile-memberDetail'>
                        <Col xs='4' sm='4' className='d-flex justify-content-center align-items-center'>
                          <div className='text-center'>
                            <h5 className='myAcc-mainWallet'>{t('settings:settings.mainwallet')}</h5>
                            <div className={`${!this.state.restoreOnLoad ? 'd-block' : 'hide'}`}>
                              <div className='h3 myAcc-text'>
                                {(this.state.mainWallet && window.SPL_Other.formatAmount(this.state.mainWallet.balance)) ||
                                  window.SPL_Other.formatAmount('0')}
                              </div>
                              <div className='h5 myAcc-currency'>{this.props.user.account.currency}</div>
                            </div>
                            <div className={`${this.state.restoreOnLoad ? 'd-flex justify-content-center' : 'd-none'}`}>
                              <SmallLoading></SmallLoading>
                            </div>
                          </div>
                        </Col>
                      </Col>

                      {/* <button className='bg-transparent border-0 text-gray d-flex justify-content-center align-items-center ' onClick={this.getMainWallet}>
												<AiOutlineSync className={`icon-sm icon-gray m-l-5 m-r-5  ${!restoreOnLoad ? 'd-block' : 'd-none'}`} />
												<Spinner animation='border' className={`icon-sm icon-gray ${restoreOnLoad ? 'd-block' : 'd-none'}`} />
											</button> */}
                    </Row>
                  </div>
                  <div>
                    {/* <Row className=' d-flex align-items-center m-b-10 m-l-0 m-r-0 '>
											<Col xs='6' sm='6' className='d-flex p-l-0 p-r-0'>
												
											</Col>
											<Col xs='6' sm='6' className='d-flex justify-content-end p-l-0 p-r-0'>
												<button
													className='btn-icon btn-outline-primary rounded d-flex justify-content-center align-items-center btn-xl'
													disabled={this.state.restoreOnLoad}
													onClick={this.restoreWallet}>
													<div className='d-flex align-items-center m-r-10'>
														<svg className='icon-dark icon-sm'>
															<SVGHome name='restore' />
														</svg>
													</div>
													<h5 className=''>{t('settings:settings.transferwallet')}</h5>
												</button>
											</Col>
										</Row> */}
                  </div>
                  {/* <hr className='m-t-10 m-b-10'></hr> */}
                  <Row className='d-flex align-items-center myAcc-bg'>
                    {myAccountMobile.map((action) => {
                      return (
                        <Col
                          xs='6'
                          key={action.id}
                          className='text-center fund-item-box'
                          onClick={() => {
                            this.props.history.push(action.route);
                          }}>
                          <div className='fund-item-icon'>
                            <svg className='submyAcc-icon icon-md' aria-hidden='true'>
                              <SVGMyAcc name={action.svgname} />
                            </svg>
                          </div>
                          <div className='fund-item-text'>
                            <div className='d-block text-center text-capitalize h6'>{t(action.desp)}</div>
                          </div>
                        </Col>
                      );
                    })}
                  </Row>
                </div>
              </div>
            </div>
          </div>
        )}
      </Translation>
    );
  }
}

export default connect(mapStateToProps)(withTranslation(['settings', 'transaction'])(withRouter(MyAccountMobile)));
