import React from 'react';
import { Translation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { mapStateToProps } from 'root/redux/selector/selector';
import { withRouter } from 'react-router-dom';
import { TweenLite } from 'gsap';
import SVGElements from 'root/components/SVGElements';
import { Fragment } from 'react';

class ToolBar extends React.Component {
    isMount = true;
    constructor(props) {
        super(props);
        this.state = {
            isSticky: false,
            anis: {},
            get_raffle_draw: false,
            showScroll: false,
            hideRaffleDraw: false,
        };
    }

    componentDidMount() {
        this.isMount = true;
        window.addEventListener('scroll', () => {
            let scrollTop = document.body.scrollTop || document.documentElement.scrollTop;
            const top = window.innerWidth > 1025 ? 20 : 50;
            this.setState(() => ({
                isSticky: scrollTop > top ? true : false,
                showScroll: scrollTop > top ? true : false,
            }));
        });
        this.getDisplayMenu();
        this.getPortalSettings();
    }

    componentWillUnmount() {
        this.isMount = false;
    }

    handleEnter(tag) {
        const oani = this.state.anis[tag];
        if (oani) {
            return oani.repeat(-1).restart();
        }

        const tween = TweenLite.fromTo(
            '.slide-menu .icon-' + tag,
            0.3,
            {
                rotate: 0,
            },
            {
                rotate: 20,
                yoyo: true,
                repeat: -1,
            }
        );

        this.setState((state) => {
            state.anis[tag] = tween;
        });
    }

    handleLeave(tag) {
        const oani = this.state.anis[tag];
        oani && oani.repeat(1);
    }

    getDisplayMenu = () => {
        const { language } = this.props;
        window.SPL_Content.getDisplayMenu(language.countryLanguageKey, 'web').then((data) => {
            for (let i = 0; i < data.length; i++) {
                if (data[i].name === 'RAFFLE') {
                    this.setState({ get_raffle_draw: true });
                }
            }
        });
    };

    getPortalSettings = () => {
        const { language } = this.props;
        window.SPL_Content.getPortalSettings().then((settings) => {
            if (settings && settings.replaceRaffleDrawFor) {
                const hideRaffleDraw = settings.replaceRaffleDrawFor.find((item) => item === language.countryLanguageKey);
                if (hideRaffleDraw) {
                    this.setState({ hideRaffleDraw: true });
                }
            }
        });
    };

    renderFirstToolbarItem = (t) => {
        const { countryLanguageKey } = this.props.language;
        const vnContent = countryLanguageKey.indexOf('VN') !== -1 ? `-${countryLanguageKey}` : '';

        let firstToolbarItem = null;

        if (this.state.hideRaffleDraw) {
            return firstToolbarItem;
        } else if (this.state.get_raffle_draw && !vnContent) {
            return (
                <li
                    onClick={() => this.props.history.push('/raffledraw')}
                    onPointerEnter={this.handleEnter.bind(this, 'evo')}
                    onPointerLeave={this.handleLeave.bind(this, 'evo')}
                >
                    <SVGElements className='icon-evo' name='icon-evo' />
                    {/* <i className="icon-raffle"></i> */}
                    <span>{t('global:global.raffle', 'Raffle Draw')}</span>
                </li>
            );
        } else if (this.state.get_raffle_draw && vnContent) {
            return (
                <li
                    onClick={() => this.props.history.push('/raffledraw')}
                    onPointerEnter={this.handleEnter.bind(this, 'raffle')}
                    onPointerLeave={this.handleLeave.bind(this, 'raffle')}
                >
                    <SVGElements className='icon-raffle' name='maxim-raffle-icon' />
                    {/* <i className="icon-raffle"></i> */}
                    <span>{t('global:global.raffle', 'Raffle Draw')}</span>
                </li>
            );
        }
    };

    render() {
        return (
            <Translation>
                {(t) => (
                    <Fragment>
                        <ul
                            className={`slide-menu ${this.state.isSticky ? 'fix-top' : ''}
              ${
                  ['/favourite', '/promotion', '/raffledraw-history', '/contact', '/aboutus', '/terms', '/cmd368', '/m8'].some((pathName) =>
                      this.props.history.location.pathname.includes(pathName)
                  )
                      ? 'slide-menu-top'
                      : ''
              }`}
                        >
                            {this.renderFirstToolbarItem(t)}
                            <li
                                onClick={() => this.props.history.push('/download')}
                                onPointerEnter={this.handleEnter.bind(this, 'download')}
                                onPointerLeave={this.handleLeave.bind(this, 'download')}
                            >
                                <SVGElements className='icon-download' name='download-icon' />
                                {/* <i className="icon-download"></i> */}
                                <span>{t('global:global.banner.download', 'DOWNLOAD')}</span>
                            </li>
                            <li
                                onClick={() => this.props.history.push('/contact')}
                                onPointerEnter={this.handleEnter.bind(this, 'contact')}
                                onPointerLeave={this.handleLeave.bind(this, 'contact')}
                            >
                                <SVGElements className='icon-contact' name='contact-icon' />
                                {/* <i className="icon-contact"></i> */}
                                <span>{t('contactus:contactus.title', 'Contact')}</span>
                            </li>
                        </ul>

                        {this.state.showScroll && (
                            <div
                                className='backTop-box'
                                onClick={() => {
                                    window.scrollTo({
                                        top: 0,
                                        behavior: 'smooth',
                                    });
                                }}
                            >
                                <SVGElements name='back-top-icon' />
                            </div>
                        )}
                    </Fragment>
                )}
            </Translation>
        );
    }
}

export default connect(mapStateToProps)(withTranslation()(withRouter(ToolBar)));
