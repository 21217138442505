import moment from 'moment';
import React, { Component, Fragment } from 'react';
import { Translation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import Select from 'react-dropdown-select';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { mapStateToProps } from '../../../redux/selector/selector';
import { withRouter } from 'react-router-dom';
import {
    EmailIcon,
    EmailShareButton,
    FacebookIcon,
    FacebookShareButton,
    LineIcon,
    LineShareButton,
    TelegramIcon,
    TelegramShareButton,
    WhatsappIcon,
    WhatsappShareButton,
} from 'react-share';
import Pagination from 'react-js-pagination';
import SVGHistory from 'root/components/Icon/SVGHistory';
import Loading from 'root/components/Loading/Loading';
import SVGElements from 'root/components/SVGElements';
import core from '../../../utils/core';
import SmallLoading from 'root/components/Loading/SmallLoading';
class Referral extends Component {
    referralCategories = [
        { id: 0, name: this.props.t('settings:settings.myReferral'), referralType: 'myReferral' },
        { id: 1, name: this.props.t('settings:settings.myBonus'), referralType: 'myBonus' },
    ];
    isMount = true;
    constructor(props) {
        super(props);
        let currentDate = new Date();
        this.state = {
            selectedFilterType: [{ id: 0, name: this.props.t('settings:settings.myReferral'), referralType: 'myReferral' }],
            referralType: 'myReferral',
            referralCode: '',
            referralLink: '',
            referralOption: {},
            language: '',
            referralDetail: {},
            filter: { startDate: new Date(new Date().setMonth(currentDate.getMonth() - 1)), endDate: currentDate },
            predicate: 'id',
            reverse: false,
            referralHistories: [],
            totalItem: 0,
            totalPageSize: '',
            getInviteInfo: false,
            value: { start: null, end: null },
            currentPage: 1,
            todosPerPage: 10,
            referralTab: '',
            getTncReferral: false,
            disableMemberBecomeReferral: false,
            showLoading: true,
        };
    }

    componentDidMount() {
        this.isMount = true;
        this.getReferralTypeEnabledAndMemberSummaryGroupAutomation();
        this.initReferralSetting();
        this.checkCountryLanguage();
    }

    componentWillUnmount() {
        this.isMount = false;
    }

    async getReferralTypeEnabledAndMemberSummaryGroupAutomation() {
        const data = await window.SPL_Member.getReferralTypeEnabledAndMemberSummaryGroupAutomation();

        this.setState({
            disableMemberBecomeReferral: data?.disableMemberBecomeReferral,
            showLoading: false,
        });
    }

    onTabClick = (index) => {
        let referralType = '';

        if (index.length > 0) {
            for (let i = 0; i < index.length; i++) {
                referralType = index[i].referralType;
                this.selectedFilterType = [this.referralCategories[index[i].id]];

                this.setState({
                    referralType: referralType,
                    histories: [],
                    selectedFilterType: [this.referralCategories[index[i].id]],
                    activeTab: index[i].id,
                });
            }
        }
    };

    onDateChanged = (date, type) => {
        if (type === 'start') {
            this.setState({ filter: { ...this.state.filter, startDate: date } });
        } else {
            this.setState({ filter: { ...this.state.filter, endDate: date } });
        }
    };

    initReferralSetting = () => {
        const { key } = this.props.language;
        const { currency } = this.props.user.account;

        if (key) {
            window.SPL_Referral.initReferralSetting(currency, key, window.globMerchantName).then((res) => {
                if (this.isMount) {
                    let referralCode = '';
                    let referralLink = '';
                    let referralMsg = '';
                    let referralSetting = res.referralSetting;
                    let referralDetail = res.referralDetail;
                    let referralOption = {};

                    // handling for json file
                    const { lastModifiedDate } = referralDetail;
                    if (lastModifiedDate) {
                        const lang = key.toLowerCase();
                        referralDetail.year = moment(lastModifiedDate).format('YYYY');
                        referralDetail.month = moment(lastModifiedDate).format(lang === 'zh-cn' ? 'M' : 'MMMM');
                        referralDetail.day = moment(lastModifiedDate).format(lang === 'zh-cn' ? 'D' : 'Do');
                    }

                    if (referralSetting.hasOwnProperty('referralCode')) {
                        referralCode = referralSetting.referralCode;
                    }
                    if (referralSetting.hasOwnProperty('referralLink')) {
                        referralLink = referralSetting.referralLink;
                    }
                    if (referralSetting.hasOwnProperty('referralMsg')) {
                        referralMsg = referralSetting.referralMsg;
                    }

                    if (referralSetting.hasOwnProperty('referralOption')) {
                        referralOption = referralSetting.referralOption;
                    }

                    this.setState({
                        referralCode: referralCode,
                        referralLink: referralLink,
                        referralMsg: referralMsg,
                        referralOption: referralOption,
                        referralDetail: referralDetail,
                    });
                }
            });
        }
    };

    checkCountryLanguage = () => {
        if (this.props.language.countryLanguageKey === 'TH_TH') {
            this.setState({ language: 'th' });
        } else if (this.props.language.countryLanguageKey === 'ZH_MY') {
            this.setState({ language: 'zhCN' });
        } else {
            this.setState({ language: '' });
        }
    };

    searchAllReferrals = () => {
        this.setState({ loading: true });
        const { filter, predicate, reverse, currentPage, todosPerPage } = this.state;
        const { startDate, endDate } = filter;
        let start = moment(startDate).format('YYYY-MM-DD').toString();
        let end = moment(endDate).format('YYYY-MM-DD').toString();
        let params = {
            page: currentPage,
            itemsPerPage: todosPerPage,
            predicate: predicate,
            reverse: reverse,
        };
        window.SPL_Referral.searchAllReferrals(params, start, end).then((res) => {
            if (this.isMount) {
                this.setState({ referralHistories: res.myReferrals, totalItems: res.totalItems, totalPageSize: res.totalPageSize, loading: false });
            }
        });
    };

    copy(text) {
        const { t } = this.props;
        core.copy(t, text);
    }

    getInviteInfo = (message, referralLink) => {
        this.setState(
            { getInviteInfo: !this.state.getInviteInfo },

            () => {
                if (this.state.getInviteInfo) {
                    if (!window.isAgent && !window.isAffiliate) {
                        if (window.SPL_LiteApp.isZT828LiteApp()) {
                            window.SPL_LiteApp.onShareButtonTapped(message, referralLink);
                        }
                    }
                }
            }
        );
    };

    getTncReferral = () => {
        this.setState({ getTncReferral: !this.state.getTncReferral });
    };

    handlePageChange = (pageNumber) => {
        this.setState({ currentPage: pageNumber }, () => {
            this.searchAllReferrals();
        });
    };

    render() {
        const { referralType, referralOption, referralDetail, referralMsg, referralLink, referralCode, filter, language, showLoading, disableMemberBecomeReferral } =
            this.state;
        const { key } = this.props.language;
        let lang = '';

        if (key) {
            lang = key.toLowerCase();
        }

        return (
            <Translation>
                {(t) => (
                    <div className='wap-referral-page'>
                        {this.state.loading && <Loading />}

                        <div className='page-content'>
                            <div className='referral-item-box'>
                                <div className='title-item'>
                                    <span>
                                        {t('settings:settings.profiletab.referral', 'Referral')} {t('transaction:transaction.deposit.onlinetransfer.option', 'Option')}
                                    </span>
                                </div>

                                <div className='referral-filter'>
                                    {this.referralCategories.length > 0 && (
                                        <Select
                                            key='name'
                                            labelField='name'
                                            valueField='name'
                                            searchable={false}
                                            placeholder={t('global:global.form.pleaseSelect2')}
                                            options={this.referralCategories}
                                            values={this.referralCategories}
                                            onChange={(value) => this.onTabClick(value)}
                                        />
                                    )}
                                </div>
                            </div>

                            <div className='referral-item-box'>
                                <div className='referral-mobile-content'>
                                    <ul className='referral-container'>
                                        {(() => {
                                            switch (referralType) {
                                                case 'myReferral':
                                                    return (
                                                        <Fragment>
                                                            {showLoading ? (
                                                                <SmallLoading></SmallLoading>
                                                            ) : (
                                                                <>
                                                                    {!disableMemberBecomeReferral && (
                                                                        <>
                                                                            <div className='invite-box'>
                                                                                <p className='invite-text'>
                                                                                    {t('settings:settings.shareReferral', 'Share this offer to your friends')}.{' '}
                                                                                    <span>
                                                                                        {t(
                                                                                            'settings:settings.bothGetBonus',
                                                                                            'Both of you get Bonus when your friend sign up using your code below'
                                                                                        )}
                                                                                        .
                                                                                    </span>
                                                                                </p>
                                                                                <div className='invite-info'>
                                                                                    <span>{referralCode}</span>
                                                                                    <div
                                                                                        className='btn-normal invite-btn'
                                                                                        onClick={() => this.getInviteInfo(referralMsg, referralLink)}
                                                                                    >
                                                                                        {t('settings:settings.share', 'Share')}
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            {this.state.getInviteInfo && (
                                                                                <div className='messaging-popup'>
                                                                                    <div className='popup'>
                                                                                        <div className='popup-header'>
                                                                                            <div className='popup-header-left'>
                                                                                                <div className='popup-title'>
                                                                                                    {t('settings:settings.share', 'Share')}{' '}
                                                                                                    {t('transaction:transaction.history.to')}
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className='popup-header-right'>
                                                                                                <SVGElements name='close-icon' onClick={() => this.getInviteInfo()} />
                                                                                                {/* <MdClose onClick={() => this.getInviteInfo()} /> */}
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className='popup-body'>
                                                                                            <div className='icon-box' onClick={() => this.copy(referralMsg)}>
                                                                                                <SVGElements className='icon-copy' name='copy-icon' />
                                                                                                {/* <i className="icon-copy"></i> */}
                                                                                            </div>
                                                                                            <FacebookShareButton url={referralLink}>
                                                                                                <FacebookIcon />
                                                                                            </FacebookShareButton>
                                                                                            <EmailShareButton url={referralMsg}>
                                                                                                <EmailIcon></EmailIcon>
                                                                                            </EmailShareButton>
                                                                                            <WhatsappShareButton url={referralMsg}>
                                                                                                <WhatsappIcon></WhatsappIcon>
                                                                                            </WhatsappShareButton>
                                                                                            <TelegramShareButton url={referralMsg}>
                                                                                                <TelegramIcon></TelegramIcon>
                                                                                            </TelegramShareButton>
                                                                                            <LineShareButton url={referralMsg}>
                                                                                                <LineIcon></LineIcon>
                                                                                            </LineShareButton>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='popup-overlay' onClick={() => this.getInviteInfo()}></div>
                                                                                </div>
                                                                            )}

                                                                            <li className='referral-content'>
                                                                                <div>
                                                                                    <p>{t('settings:settings.shareReferral', 'Share this offer to your friends')} !</p>
                                                                                </div>
                                                                                <div className='share-content'>
                                                                                    <label className='share-option'>
                                                                                        <input type='radio' name='option' checked readOnly />
                                                                                        {/* <span className='checkmark'></span>
                                                                        {t('transaction:transaction.deposit.onlinetransfer.option', 'Option')} 1 -{' '}
                                                                        {referralOption[lang] && referralOption[lang].amount && (
                                                                            <span className='option-amount'>{referralOption[lang].amount}</span>
                                                                        )} */}
                                                                                    </label>

                                                                                    {referralOption[lang] && !referralOption[lang].isHideShareBox && (
                                                                                        <div className='share-box'>
                                                                                            <p className='share-amount'>{referralOption[lang].amount}</p>
                                                                                            <div
                                                                                                className='share-detail'
                                                                                                dangerouslySetInnerHTML={{ __html: referralOption[lang].details }}
                                                                                            ></div>
                                                                                        </div>
                                                                                    )}

                                                                                    <div className='btn-normal invite-btn' onClick={() => this.getInviteInfo()}>
                                                                                        {t('settings:settings.inviteFriend', 'Invite Friend')}
                                                                                    </div>
                                                                                </div>
                                                                            </li>
                                                                        </>
                                                                    )}
                                                                </>
                                                            )}

                                                            <li className='referral-status'>
                                                                <p>{t('settings:settings.urReferralStatus', 'Referral Status')}</p>
                                                                <table className='status-box'>
                                                                    <tbody>
                                                                        <tr className='invitation-box'>
                                                                            <td>{t('settings:settings.inviteAccepted', 'Invitation Accepted')} :</td>
                                                                            <td>{referralDetail.totalReferree}</td>
                                                                        </tr>
                                                                        <tr className='mission-box'>
                                                                            <td>{t('settings:settings.missionComplete', 'Mission Completed')} :</td>
                                                                            <td>{referralDetail.totalConverted}</td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>

                                                                <span className='tnc-apply' onClick={() => this.getTncReferral()}>
                                                                    * {t('slot:slot.tnc-apply', 'Terms & Conditions Applied')}
                                                                </span>
                                                            </li>
                                                        </Fragment>
                                                    );
                                                    break;

                                                case 'myBonus':
                                                    return (
                                                        <Fragment>
                                                            <li className='bonus-formpage'>
                                                                <div className='form-bonus'>
                                                                    <div className='form-item'>
                                                                        <div className='top'>
                                                                            <div className='top-title'>
                                                                                <span className='bonus-label'>
                                                                                    {t('transaction:transaction.history.filter.start_date_nocol')}
                                                                                </span>
                                                                            </div>
                                                                            <DatePicker
                                                                                locale={language}
                                                                                name='startDte'
                                                                                // isClearable
                                                                                autoComplete='off'
                                                                                label='w-100'
                                                                                type='text'
                                                                                placeholderText='Start Date'
                                                                                selectsStart
                                                                                selected={filter.startDate}
                                                                                onChange={(date) => this.onDateChanged(date, 'start')}
                                                                                startDate={filter.start}
                                                                                endDate={filter.end}
                                                                                maxDate={filter.end}
                                                                                popperPlacement='bottom'
                                                                                popperModifiers={{
                                                                                    flip: {
                                                                                        behavior: ['bottom'], // don't allow it to flip to be above
                                                                                    },
                                                                                    preventOverflow: {
                                                                                        enabled: false, // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
                                                                                    },
                                                                                    hide: {
                                                                                        enabled: false, // turn off since needs preventOverflow to be enabled
                                                                                    },
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    </div>

                                                                    <div className='form-item'>
                                                                        <div className='top'>
                                                                            <div className='to-label'>
                                                                                <span className='bonus-label'>{t('transaction:transaction.history.to')}</span>
                                                                            </div>
                                                                            <DatePicker
                                                                                locale={language}
                                                                                name='endDate'
                                                                                // isClearable
                                                                                autoComplete='off'
                                                                                label='w-100'
                                                                                type='text'
                                                                                placeholderText='End Date'
                                                                                selectsEnd
                                                                                selected={filter.endDate}
                                                                                onChange={(date) => this.onDateChanged(date, 'end')}
                                                                                startDate={filter.start}
                                                                                endDate={filter.end}
                                                                                minDate={filter.start}
                                                                                popperPlacement='bottom'
                                                                                popperModifiers={{
                                                                                    flip: {
                                                                                        behavior: ['bottom'], // don't allow it to flip to be above
                                                                                    },
                                                                                    preventOverflow: {
                                                                                        enabled: false, // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
                                                                                    },
                                                                                    hide: {
                                                                                        enabled: false, // turn off since needs preventOverflow to be enabled
                                                                                    },
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    </div>

                                                                    <div className='form-item'>
                                                                        <button type='submit' onClick={() => this.searchAllReferrals()} className='btn-normal btn-search'>
                                                                            <span>{t('transaction:transaction.deposit.cashdeposit.submitbtn').toLowerCase()}</span>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </li>

                                                            <li className='bonus-container'>
                                                                <table>
                                                                    <tbody>
                                                                        <tr>
                                                                            <th>{t('settings:settings.memberLogin')}</th>
                                                                            <th>{t('settings:settings.registerTime')}</th>
                                                                            <th>{t('settings:settings.currency')}</th>
                                                                            <th>{t('settings:settings.totalDeposit')}</th>
                                                                            <th>{t('settings:settings.bonusAmount')}</th>
                                                                            <th>{t('settings:settings.status')}</th>
                                                                        </tr>
                                                                        {this.state.referralHistories.length > 0 ? (
                                                                            this.state.referralHistories.map((todo, index) => {
                                                                                return (
                                                                                    <tr key={index}>
                                                                                        <td>{t(todo.refereeLogin)}</td>
                                                                                        <td>{moment(todo.registerTime).format('YYYY-MM-DD HH:mm').toString()}</td>
                                                                                        <td>{t(todo.refereeCurrency)}</td>
                                                                                        <td className='deposit-amount'>
                                                                                            {window.SPL_Other.formatAmount(todo.currentDepositAmount)} /{' '}
                                                                                            {window.SPL_Other.formatAmount(todo.targetDepositAmount)}
                                                                                        </td>
                                                                                        <td>{window.SPL_Other.formatAmount(todo.referralBonusAmount)}</td>
                                                                                        <td>{t(todo.status)}</td>
                                                                                    </tr>
                                                                                );
                                                                            })
                                                                        ) : (
                                                                            <tr>
                                                                                <td colSpan='6' className='noData'>
                                                                                    {t('transaction:transaction.emptyHistory')}
                                                                                </td>
                                                                            </tr>
                                                                        )}
                                                                    </tbody>
                                                                </table>
                                                            </li>

                                                            {this.state.referralHistories.length > 0 && (
                                                                <Pagination
                                                                    firstPageText={<SVGHistory name='prevFirst-arrow' className='' alt='prevFirst-arrow' />}
                                                                    lastPageText={<SVGHistory name='nextLast-arrow' className='' alt='nextLast-arrow' />}
                                                                    prevPageText={<SVGHistory name='prev-arrow' className='' alt='prev-arrow' />}
                                                                    nextPageText={<SVGHistory name='next-arrow' className='' alt='next-arrow' />}
                                                                    activePage={this.state.currentPage}
                                                                    itemsCountPerPage={this.state.todosPerPage}
                                                                    totalItemsCount={parseInt(this.state.totalItems)}
                                                                    pageRangeDisplayed={5}
                                                                    onChange={this.handlePageChange.bind(this)}
                                                                />
                                                            )}
                                                        </Fragment>
                                                    );
                                                    break;

                                                default:
                                                    break;
                                            }
                                        })()}
                                    </ul>
                                </div>
                            </div>
                        </div>

                        {referralOption && referralOption[lang] && (
                            <Fragment>
                                {this.state.getTncReferral && (
                                    <li className='messaging-popup mobile-notice'>
                                        <div className='popup notice-box'>
                                            <div className='popup-header'>
                                                <div className='popup-header-left'>
                                                    <div className='popup-title'>{t('slot:slot.tnc-apply', 'Terms & Conditions Applied')}</div>
                                                </div>
                                                <div className='popup-header-right'>
                                                    <SVGElements name='close-icon' onClick={() => this.getTncReferral()} />
                                                    {/* <MdClose onClick={() => this.getTncReferral()} /> */}
                                                </div>
                                            </div>
                                            <div className='popup-body'>
                                                <div className='title'>
                                                    <i className='icon-notice-important'></i>
                                                    <span>{referralOption[lang].termNConditionTitle}</span>
                                                </div>
                                                <ul>
                                                    <li>
                                                        <span>1</span>
                                                        <p>{referralOption[lang].termNCondition1}</p>
                                                    </li>
                                                    <li>
                                                        <span>2</span>
                                                        <p>
                                                            {t(referralOption[lang].termNCondition2, {
                                                                targetDepositAmount: referralDetail.targetDepositAmount,
                                                                expiredDays: referralDetail.expiredDays,
                                                                invitationBonusAmount: referralDetail.invitationBonusAmount,
                                                                referralBonusAmount: referralDetail.referralBonusAmount,
                                                            })}
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <span>3</span>
                                                        <p>
                                                            {' '}
                                                            {t(referralOption[lang].termNCondition3, {
                                                                colon: ':',
                                                                targetDepositAmount: referralDetail.targetDepositAmount,
                                                                expiredDays: referralDetail.expiredDays,
                                                            })}
                                                        </p>
                                                    </li>
                                                    {referralOption[lang].termNCondition4 ? (
                                                        <li>
                                                            <span>4</span>
                                                            <p>
                                                                {t(referralOption[lang].termNCondition4, {
                                                                    targetDepositAmount: referralDetail.targetDepositAmount,
                                                                    expiredDays: referralDetail.expiredDays,
                                                                })}
                                                            </p>
                                                        </li>
                                                    ) : null}
                                                    <li>
                                                        <span className='empty-span'> </span>
                                                        <p dangerouslySetInnerHTML={{ __html: referralOption[lang].learnMore }}></p>
                                                    </li>
                                                    <li>
                                                        <span className='empty-span'> </span>
                                                        <p>
                                                            {t(referralOption[lang].effectiveDate, {
                                                                year: referralDetail.year,
                                                                month: referralDetail.month,
                                                                day: referralDetail.day,
                                                            })}
                                                        </p>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className='popup-overlay notice-overlay' onClick={() => this.getTncReferral()}></div>
                                    </li>
                                )}
                            </Fragment>
                        )}
                    </div>
                )}
            </Translation>
        );
    }
}

export default connect(mapStateToProps, null)(withTranslation('settings')(withRouter(Referral)));
