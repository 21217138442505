import React from "react";
import { Translation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { mapStateToProps } from 'root/redux/selector/selector';
import { withRouter } from 'react-router-dom';
import Slider from "root/components/Slider";

class HomeSlider extends React.Component {
  constructor(props) {
    super();
    this.state = {
      homePageBanner: [],
    }
  }

  componentDidMount() {
    this.getHomePageBanner();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.language.countryLanguageKey !== this.props.language.countryLanguageKey) {
      this.getHomePageBanner();
    }

    if (prevProps.screen.isMobile !== this.props.screen.isMobile) {
      this.getHomePageBanner();
    }
  }

  getHomePageBanner() {
    const { language, screen } = this.props;
    window.SPL_Content.getHomePageBanner(language.countryLanguageKey, screen.viewType).then((result) => {
      this.setState({ homePageBanner: result });
    });
  }

  bannerOnClick = (url) => {
    let check = url.charAt(0);
    let checkHttp = url.substring(0, 4);
    if (check !== '/' && checkHttp !== 'http') {
      this.props.history.push('/' + url);
    } else if (check === '/' && checkHttp !== 'http') {
      this.props.history.push(url);
    } else if (check !== '/' && checkHttp === 'http') {
      window.open(url, '');
    }
  };

  render() {
    const homeBanner = (
      this.state.homePageBanner?.map(banner => (
        <div key={banner.id} className="banner-item" onClick={() => banner.href && this.bannerOnClick(banner.href)}>
          <picture>
            <source type='image/webp' srcSet={banner.webPUrl} />
            <img src={banner.url} alt='banner' onLoad={() => this.setState({ imgCompleted: true })}  width="100%" height="100%" />
          </picture>


          {this.state.imgCompleted &&
            <div className="content-container">
              <div className="slide-text-box home-slide">
                <p className="text-banner-green">
                  {banner && banner.header && banner.header.headerOne &&
                    <span className='green-content'>{banner.header.headerOne}</span>
                  }
                  {banner && banner.header && banner.header.headerTwo &&
                    <span className='green-content'>{banner.header.headerTwo}</span>
                  }
                </p>

                <p className="text-banner-white">
                  {banner && banner.header && banner.header.headerThree &&
                    <span className="white-content">{banner.header.headerThree}</span>
                  }
                  {banner && banner.header && banner.header.headerFour &&
                    <span className="white-content">{banner.header.headerFour}</span>
                  }
                </p>

                {banner && banner.desc &&
                  <p className="text-banner-content">{banner.desc}</p>
                }
              </div>
            </div>
          }
        </div>
      ))
    );

    return (
      <Translation>
        {(t) => (
          <div className="banner-box">
            <div className="banner">
              <Slider
                items={homeBanner}
                className="fade1"
                timeout={1000}
              />
            </div>
          </div>
        )}
      </Translation>
    );
  }
}

export default connect(mapStateToProps)(withTranslation()(withRouter(HomeSlider)));