import React, { Component } from 'react';
import { Trans, Translation, withTranslation } from 'react-i18next';
import { Row, Col } from 'reactstrap';
import SVGPassword from '../../components/Icon/SVGPassword';
import { connect } from 'react-redux';
import { mapStateToProps } from '../../redux/selector/selector';
import { withRouter } from 'react-router-dom';

class ChangePassword extends Component {
    isMount = true;
    constructor(props) {
        super(props);
        this.state = {
            currentpassword: '',
            newpassword: '',
            confirmpassword: '',
            samePassword: false,
            hiddenCurrent: true,
            hiddenNewPassword: true,
            hiddenConfirmPassword: true,
            newPasswordErrMsg: [],
            confirmpasswordError: [],
            message: '',
        };
    }

    toggleShowCurrent = () => {
        this.setState({ hiddenCurrent: !this.state.hiddenCurrent });
    };

    toggleShowNewPassword = () => {
        this.setState({ hiddenNewPassword: !this.state.hiddenNewPassword });
    };

    handleCurrentPasswordChange = (e) => {
        const currentpassword = e.target.value;

        this.setState({
            currentpassword: currentpassword,
        });
    };

    handleNewPasswordChange = (e) => {
        const newpassword = e.target.value;
        if (!this.state.newpassword && newpassword.length > 1) return null;

        this.setState({ newpassword: newpassword }, () => {
            this.validateNewPassword();
        });
    };

    handleConfirmNewPasswordChange = (e) => {
        const confirmpassword = e.target.value;
        if (!this.state.confirmpassword && confirmpassword.length > 1) return null;

        this.setState({ confirmpassword: confirmpassword }, () => {
            this.validateConfirmPassword();
        });
    };

    componentDidMount() {
        this.isMount = true;
        if (this.props.newpassword) {
            this.setState({ newpassword: this.props.newpassword });
        }

        if (this.props.screen.viewType === 'web' && this.props.location.pathname === '/changepassword') {
            this.props.history.push('/myaccount/changepassword');
        } else if (this.props.screen.viewType === 'mobile' && this.props.location.pathname === '/myaccount/changepassword') {
            this.props.history.push('/myaccount/myprofile');
            this.props.history.push('/changepassword');
        }
    }

    componentWillUnmount() {
        this.isMount = false;
    }

    validateNewPassword = () => {
        window.SPL_Register.validatePassword(this.state.newpassword, this.state.confirmpassword).then((errMsg) => {
            if (this.isMount) {
                this.setState({ newPasswordErrMsg: errMsg }, () => {
                    if (this.state.confirmpassword) this.validateConfirmPassword();
                });
            }
        });
    };

    validateConfirmPassword = () => {
        window.SPL_Register.validateConfirmPassword(this.state.newpassword, this.state.confirmpassword).then((errMsg) => {
            if (this.isMount) {
                this.setState({ confirmpasswordError: errMsg });
            }
        });
    };

    handleSubmit = () => {
        if (this.state.newPasswordErrMsg.length > 0 || this.state.confirmpasswordError.length > 0) {
            return;
        }
        if (this.state.currentpassword.length <= 0 || this.state.confirmpassword.length <= 0 || this.state.newpassword.length <= 0) {
            return;
        }
        window.SPL_Member.changePassword(this.state.newpassword, this.state.currentpassword).then((data) => {
            if (this.isMount) {
                if (data.msg === 'success') {
                    this.setState({
                        message: 'password:password.messages.success',
                        status: 'S',
                        currentpassword: '',
                        newpassword: '',
                        confirmpassword: '',
                    });
                } else if (data.msg === 'incorrect') {
                    this.setState({
                        message: 'global:global.messages.error.invalidcurrentpass',
                        status: 'F',
                    });
                } else {
                    this.setState({
                        message: 'password:password.messages.error',
                        status: 'F',
                    });
                }
            }
        });
    };

    render() {
        return (
            <Translation>
                {(t) => (
                    <div className='app-changePassword'>
                        <div className='row d-none d-md-flex justify-content-center'>
                            <div className='account-col'>
                                <div className='form-content d-flex '>
                                    <Col xs={12} md={8} lg={8}>
                                        <div>
                                            <Row className='form-group myacc-left-spacing'>
                                                <Col xs={12} md={12} lg={12} className='form-spacing form-group'>
                                                    <div className='d-flex'>
                                                        <Col xs={12} md={4}>
                                                            <label htmlFor='currentpassword' className='form-label'>
                                                                {/* current password */}
                                                                {t('global:global.form.currentpassword')}
                                                                <span className='text-danger m-r-5'>*</span>
                                                            </label>
                                                        </Col>
                                                        <Col xs={12} md={7}>
                                                            <div className='form-control-inner d-flex'>
                                                                <input
                                                                    className='border-0 w-100'
                                                                    value={this.state.currentpassword}
                                                                    onChange={this.handleCurrentPasswordChange}
                                                                    type={this.state.hiddenCurrent ? 'password' : 'text'}
                                                                />
                                                                <button
                                                                    className='d-flex justify-content-end align-items-center bg-transparent'
                                                                    onClick={this.toggleShowCurrent}
                                                                >
                                                                    <svg className={`${this.state.hiddenCurrent ? 'icon-primary icon-xs' : 'icon-light icon-xs'}`}>
                                                                        <SVGPassword name='hide' />
                                                                    </svg>
                                                                </button>
                                                            </div>
                                                        </Col>
                                                    </div>
                                                </Col>
                                                <Col xs={12} md={12} lg={12} className='form-spacing form-group'>
                                                    <div className='d-flex'>
                                                        <Col xs={12} md={4}>
                                                            <label htmlFor='newpassword' className='form-label'>
                                                                {/* new password */}
                                                                {t('global.form.newpassword')}
                                                                <span className='text-danger m-r-5'>*</span>
                                                            </label>
                                                        </Col>
                                                        <Col xs={12} md={7}>
                                                            <div className='form-control-inner d-flex'>
                                                                <input
                                                                    className='border-0 w-100'
                                                                    value={this.state.newpassword}
                                                                    onChange={this.handleNewPasswordChange}
                                                                    onBlur={this.validateNewPassword}
                                                                    type={this.state.hiddenNewPassword ? 'password' : 'text'}
                                                                />
                                                                <button
                                                                    className='d-flex justify-content-end align-items-center bg-transparent'
                                                                    onClick={this.toggleShowNewPassword}
                                                                >
                                                                    <svg className={`${this.state.hiddenCurrent ? 'icon-primary icon-xs' : 'icon-light icon-xs'}`}>
                                                                        <SVGPassword name='hide' />
                                                                    </svg>
                                                                </button>
                                                            </div>
                                                            <Col xs={12} sm={12} md={12} lg={12}>
                                                                {this.state.newPasswordErrMsg.map((errMsg, index) => {
                                                                    return (
                                                                        <div key={index} className='invalid-feedback text-danger'>
                                                                            <Trans i18nKey={errMsg}></Trans>
                                                                        </div>
                                                                    );
                                                                })}
                                                            </Col>
                                                        </Col>
                                                    </div>
                                                </Col>
                                                <Col xs={12} md={12} lg={12} className='form-spacing form-group'>
                                                    <div className='d-flex'>
                                                        <Col xs={12} md={4}>
                                                            <label htmlFor='confirmpassword' className='form-label'>
                                                                {/* connfirm password */}
                                                                {t('global.form.confirmpassword')} <span className='text-danger m-r-5 '>*</span>
                                                            </label>
                                                        </Col>
                                                        <Col xs={12} md={7}>
                                                            <div className='form-control-inner'>
                                                                <input
                                                                    className='border-0 w-100'
                                                                    value={this.state.confirmpassword}
                                                                    onChange={this.handleConfirmNewPasswordChange}
                                                                    onBlur={this.validateConfirmPassword}
                                                                    type='password'
                                                                />
                                                            </div>
                                                            <Col xs={12} sm={12} md={12} lg={12}>
                                                                {this.state.status === 'F' && (
                                                                    <div className='invalid-feedback text-danger'>
                                                                        <Trans i18nKey={this.state.message}></Trans>
                                                                    </div>
                                                                )}
                                                                {this.state.status === 'S' && (
                                                                    <div className='invalid-feedback text-success '>
                                                                        <Trans i18nKey={this.state.message}></Trans>
                                                                    </div>
                                                                )}
                                                            </Col>
                                                        </Col>
                                                    </div>
                                                </Col>
                                                <Col xs={12} md={12} lg={12} className='d-flex justify-content-end button-group form-spacing '>
                                                    <div className='w-100 d-flex'>
                                                        <Col xs={5} md={4} className='p-l-0 p-r-0'></Col>
                                                        <Col xs={7} md={7} className='justify-content-start'>
                                                            <button
                                                                type='submit'
                                                                disabled={this.state.disabledDepositButton}
                                                                onClick={this.handleSubmit}
                                                                className=' primary-btn button-submit border-0 button-account'
                                                            >
                                                                <span>{t('global:entity.action.save')}</span>
                                                            </button>
                                                        </Col>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Col>
                                </div>
                            </div>
                        </div>
                        <div className='content-mobile d-block d-md-none p-l-0 p-r-0 content'>
                            <div className='row d-flex justify-content-center'>
                                <div className='mobile-content-width'>
                                    <div className='d-flex justify-content-center'>
                                        <Col xs={12} md={12}>
                                            <div>
                                                <Row className='form-group'>
                                                    <Col xs={12} md={12} lg={12} className='form-spacing form-group'>
                                                        <div>
                                                            <Col xs={12} md={12}>
                                                                <label htmlFor='currentpassword' className='chdPassw-text form-label'>
                                                                    {/* current password */}
                                                                    {t('global:global.form.currentpassword')}
                                                                    <span className='text-danger m-r-5'>*</span>
                                                                </label>
                                                            </Col>
                                                            <Col xs={12} md={12}>
                                                                <div className='form-control-inner d-flex'>
                                                                    <input
                                                                        className='border-0 w-100'
                                                                        value={this.state.currentpassword}
                                                                        onChange={this.handleCurrentPasswordChange}
                                                                        type={this.state.hiddenCurrent ? 'password' : 'text'}
                                                                    />
                                                                    <button
                                                                        className=' d-flex justify-content-end align-items-center bg-transparent'
                                                                        onClick={this.toggleShowCurrent}
                                                                    >
                                                                        <svg className={`${this.state.hiddenCurrent ? 'icon-primary icon-xs' : 'icon-light icon-xs'}`}>
                                                                            <SVGPassword name='hide' />
                                                                        </svg>
                                                                    </button>
                                                                </div>
                                                            </Col>
                                                        </div>
                                                    </Col>
                                                    <Col xs={12} md={12} lg={12} className='form-spacing form-group'>
                                                        <div>
                                                            <Col xs={12} md={4}>
                                                                <label htmlFor='newpassword' className='form-label chdPassw-text'>
                                                                    {/* new password */}
                                                                    {t('global.form.newpassword')}
                                                                    <span className='text-danger m-r-5 '>*</span>
                                                                </label>
                                                            </Col>
                                                            <Col xs={12} md={7}>
                                                                <div className='form-control-inner d-flex'>
                                                                    <input
                                                                        className='border-0 w-100'
                                                                        value={this.state.newpassword}
                                                                        onChange={this.handleNewPasswordChange}
                                                                        onBlur={this.validateNewPassword}
                                                                        type={this.state.hiddenNewPassword ? 'password' : 'text'}
                                                                    />
                                                                    <button
                                                                        className=' d-flex justify-content-end align-items-center bg-transparent'
                                                                        onClick={this.toggleShowNewPassword}
                                                                    >
                                                                        <svg className={`${this.state.hiddenCurrent ? 'icon-primary icon-xs' : 'icon-light icon-xs'}`}>
                                                                            <SVGPassword name='hide' />
                                                                        </svg>
                                                                    </button>
                                                                </div>
                                                                <Col xs={12} sm={12} md={12} lg={12}>
                                                                    {this.state.newPasswordErrMsg.map((errMsg, index) => {
                                                                        return (
                                                                            <div key={index} className='invalid-feedback text-danger'>
                                                                                <Trans i18nKey={errMsg}></Trans>
                                                                            </div>
                                                                        );
                                                                    })}
                                                                </Col>
                                                            </Col>
                                                        </div>
                                                    </Col>
                                                    <Col xs={12} md={12} lg={12} className='form-spacing form-group'>
                                                        <div>
                                                            <Col xs={12} md={4}>
                                                                <label htmlFor='confirmpassword' className='form-label chdPassw-text'>
                                                                    {/* connfirm password */}
                                                                    {t('global.form.confirmpassword')} <span className='text-danger m-r-5'>*</span>
                                                                </label>
                                                            </Col>
                                                            <Col xs={12} md={7}>
                                                                <div className='form-control-inner'>
                                                                    <input
                                                                        className='border-0 w-100'
                                                                        value={this.state.confirmpassword}
                                                                        onChange={this.handleConfirmNewPasswordChange}
                                                                        onBlur={this.validateConfirmPassword}
                                                                        type='password'
                                                                    />
                                                                </div>
                                                                <Col xs={12} sm={12} md={12} lg={12}>
                                                                    {this.state.status === 'F' && (
                                                                        <div className='invalid-feedback text-danger'>
                                                                            <Trans i18nKey={this.state.message}></Trans>
                                                                        </div>
                                                                    )}
                                                                    {this.state.status === 'S' && (
                                                                        <div className='invalid-feedback text-success '>
                                                                            <Trans i18nKey={this.state.message}></Trans>
                                                                        </div>
                                                                    )}
                                                                </Col>
                                                            </Col>
                                                        </div>
                                                    </Col>
                                                    <Col xs={12} md={12} lg={12} className='d-flex justify-content-end button-group form-spacing'>
                                                        <div className='w-100 d-flex'>
                                                            <Col xs={12} md={12} className='justify-content-start'>
                                                                <button
                                                                    type='submit'
                                                                    disabled={this.state.disabledDepositButton}
                                                                    onClick={this.handleSubmit}
                                                                    className='primary-btn button-submit border-0'
                                                                >
                                                                    <span>{t('global:entity.action.save')}</span>
                                                                </button>
                                                            </Col>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Col>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </Translation>
        );
    }
}

export default connect(mapStateToProps, null)(withTranslation(['password'])(withRouter(ChangePassword)));
