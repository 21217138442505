import React from 'react';
import { useState } from 'react';

// import SVGReactIcons from 'root/components/Icon/SVGReactIcons';
import Input from '../../atoms/input/input';
import styles from './password-input.module.scss';

const PasswordInput = ({ value, type, ...props }) => {
    const [passwordReveal, setPasswordReveal] = useState(false);

    const onPasswordEyeClick = () => {
        setPasswordReveal(!passwordReveal);
    };

    const _inputProps = {
        ...props,
        type: passwordReveal ? 'text' : 'password',
    };

    return (
        <>
            <Input value={value} type={passwordReveal ? 'text' : 'password'} {..._inputProps} />
            <div className={`${styles.passwordEyes} ${styles?.[props.layout] || ''} ${props.hasLabel ? styles.hasLabel : ''}`} onClick={onPasswordEyeClick}>
                <PasswordEyes reveal={passwordReveal} />
            </div>
        </>
    );
};

export default PasswordInput;

const PasswordEyes = ({ reveal }) => {
    return <img className={`standard-password-eye`} src={`/public/html/images/icon/${reveal ? 'password-show' : 'password-hide'}.png`} alt='password-eye.png' />;
};
