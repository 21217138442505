import React, { Component } from 'react';
import { Translation, withTranslation } from 'react-i18next';
import { AiOutlineMenu } from 'react-icons/ai';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { mapStateToProps } from '../../../redux/selector/selector';
import classnames from 'classnames';
import LoginPanel from '../LoginPanel';
import HeaderMenu from '../../../layout/MainNavigation/AppHeader/HeaderMenu';

class ConnectedLogin extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
      activeTab: '',
      activeToolBar: false
    };
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClick, false);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick, false);
  }

  handleClick = (e) => {
    if (this.node.contains(e.target)) {
      return
    }

    this.handleClickOutside();
  }

  handleClickOutside() {
    this.setState({activeToolBar: false});
  }

  setOpenClose = () => {
    if (this.state.isOpen === true) {
      this.setState({isOpen: false});
    } else {
      this.setState({isOpen: true});
    }
  }

  openRegisterTab = () => {
    this.setState({activeTab: 'registerTab'});
  }

  openLoginTab = () => {
    this.setState({activeTab: 'loginTab'});
  }

  toggle = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({ activeTab: tab, }, () => {
        this.setState({
            amount: '',
            errMsgLimitAmount: [],
            amountErrMsg: [],
        });
      });
    }
  }

  render() {
    return (
      <Translation>
        {(t) => (
          <div className='d-flex align-items-center dtLogin-container position-relative'>
            <span onClick={() => {this.setOpenClose(); this.openRegisterTab();}}>{t('global:global.menu.account.register')}</span>
            <span onClick={() => {this.setOpenClose(); this.openLoginTab();}}>{t('global:global.menu.account.login')}</span>
            <AiOutlineMenu name='mtoolBar-icon' className='dttoolBar-icon'
              onClick = {() => this.setState({activeToolBar: !this.state.activeToolBar})} />
            <div ref={node => this.node = node}>
              <div className={`toolBar-headerMenu ${this.state.activeToolBar ? 'activeToolBar' : 'closeToolBar'}`}>
                <HeaderMenu></HeaderMenu>
              </div>
            </div>

            <div className={classnames({ bgBlur: this.state.isOpen === true, })}></div>

            <div className={classnames({ loginPanel: true, loginPanel_show: this.state.isOpen === true, })}>
              <LoginPanel setOpenClose={this.setOpenClose} openRegisterTab={this.openRegisterTab} activeTab = {this.state.activeTab} toggle={this.toggle} />
            </div>
          </div>
        )}
      </Translation>
    );
  }
}

export default withRouter(connect(mapStateToProps, null)(withTranslation()(ConnectedLogin)));
