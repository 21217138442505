import { MODULE_TYPE as MT } from '@constants';
import { authSettingsConstant } from '../constant';

const initialState = {
    status: false,
    // raw data from JSON
    rawData: {
        [MT.CRYPTO_ADDRESS]: null,
        [MT.WITHDRAWAL_CRYPTO]: null,
        [MT.BIND_AUTHENTICATOR]: null,
        [MT.UPDATE_MEMBER_INFO]: null,
    },
    processedData: {
        [MT.CRYPTO_ADDRESS]: null,
        [MT.WITHDRAWAL_CRYPTO]: null,
        [MT.BIND_AUTHENTICATOR]: null,
        [MT.UPDATE_MEMBER_INFO]: null,
    },
    moduleData: {},
};

export function authSettingsReducer(state = initialState, action) {
    switch (action.type) {
        case authSettingsConstant.FETCH_AUTH_SETTINGS:
            const { authType, rawData, processedData } = action.payload;
            return {
                ...state,
                status: true,
                rawData: {
                    ...state.rawData,
                    [authType]: rawData,
                    status: true, // add first, in case if required wait API done only process
                },
                processedData: {
                    ...state.processedData,
                    [authType]: processedData,
                    status: true, // add first, in case if required wait API done only process
                },
            };

        case authSettingsConstant.SET_TURNSTILE_ENABLE:
            return {
                ...state,
                processedData: {
                    ...state.processedData,
                    [action?.payload?.module]: {
                        ...state?.processedData?.[action?.payload?.module],
                        turnstileEnabled: action?.payload?.moduleEnableTurnstile,
                    },
                },
            };

        case authSettingsConstant.SET_GOOGLE_MEMBER_REGISTERED:
            return {
                ...state,
                processedData: {
                    ...state.processedData,
                    [MT.BIND_AUTHENTICATOR]: {
                        ...state?.processedData?.[MT.BIND_AUTHENTICATOR],
                        gaRegistered: action?.payload?.gaRegistered,
                        transitionFromUnbindToBindGA: true, // special keyword used in affiliate-hook.js
                    },
                },
            };

        case authSettingsConstant.SET_MODULE_GOOGLE_REGISTERED:
            return {
                ...state,
                processedData: {
                    ...state.processedData,
                    [action?.payload?.module]: {
                        ...state?.processedData?.[action?.payload?.module],
                        gaRegistered: true,
                    },
                },
            };

        case authSettingsConstant.SET_SIX_PIN_MEMBER_REGISTERED:
            return {
                ...state,
                processedData: {
                    ...state.processedData,
                    [MT.SIX_PIN]: {
                        ...state?.processedData?.[MT.SIX_PIN],
                        transitionFromUnbindToBindSixPin: true, // special keyword used in affiliate-hook.js
                    },
                },
            };

        case authSettingsConstant.SET_MODULE_SIX_PIN_REGISTERED:
            return {
                ...state,
                processedData: {
                    ...state.processedData,
                    [action?.payload?.module]: {
                        ...state?.processedData?.[action?.payload?.module],
                        memberSixPinInfo: {
                            ...state?.processedData?.[action?.payload?.module]?.memberSixPinInfo,
                            sixPinRegistered: true,
                        },
                    },
                },
            };

        case authSettingsConstant.SET_SIX_PIN_LOCKED:
            return {
                ...state,
                processedData: {
                    ...state.processedData,
                    [MT.SIX_PIN]: {
                        ...state?.processedData?.[MT.SIX_PIN],
                        transitionToLock: true, // special keyword used in affiliate-hook.js
                    },
                },
            };

        case authSettingsConstant.SET_MODULE_SIX_PIN_LOCKED:
            return {
                ...state,
                processedData: {
                    ...state.processedData,
                    [action?.payload?.module]: {
                        ...state?.processedData?.[action?.payload?.module],
                        memberSixPinInfo: {
                            ...state?.processedData?.[action?.payload?.module]?.memberSixPinInfo,
                            sixPinLocked: true,
                        },
                    },
                },
            };

        default:
            return state;
    }
}
