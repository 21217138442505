import React from 'react';
import { Row } from 'react-bootstrap';
import 'react-datepicker/dist/react-datepicker.css';
import { useTranslation } from 'react-i18next';
import Pagination from 'react-js-pagination';
import SVGHistory from 'root/components/Icon/SVGHistory';

const FreeSpinHistory = ({ histories, showDetails, sortHistories, currentPage, totalItems, todosPerPage, handlePageChange, freeSpinColumnConfigs }) => {
    const { t } = useTranslation();
    return (
        <div className='history-header'>
            <ul className='history-title-div history-freeSpinHistory'>
                {freeSpinColumnConfigs.map(({ key, sortable }) => (
                    <li key={key} className={`history-${key}`} onClick={() => (sortable ? sortHistories('date') : null)}>
                        {t(`transaction:transaction.freeSpinHistory.${key}`)}
                    </li>
                ))}
            </ul>

            {histories.length ? (
                <div>
                    {histories.map((hist) => (
                        <ul key={hist.id} className='history-details history-freeSpinHistory' onClick={() => showDetails('freeSpinHistory', hist)}>
                            {freeSpinColumnConfigs.map(({ key, render }) => (
                                <li key={key} className={`history-${key}`}>
                                    {render(hist, t)}
                                </li>
                            ))}
                        </ul>
                    ))}

                    <Pagination
                        firstPageText={<SVGHistory name='prevFirst-arrow' className='' alt='prevFirst-arrow' />}
                        lastPageText={<SVGHistory name='nextLast-arrow' className='' alt='nextLast-arrow' />}
                        prevPageText={<SVGHistory name='prev-arrow' className='' alt='prev-arrow' />}
                        nextPageText={<SVGHistory name='next-arrow' className='' alt='next-arrow' />}
                        activePage={currentPage}
                        itemsCountPerPage={todosPerPage}
                        totalItemsCount={parseInt(totalItems)}
                        pageRangeDisplayed={5}
                        onChange={handlePageChange(this)}
                    />
                </div>
            ) : (
                <Row className='no-Data'>{t('transaction:transaction.emptyHistory')}</Row>
            )}
        </div>
    );
};

export default FreeSpinHistory;
