import React, { Fragment, createRef } from 'react';
import { connect } from 'react-redux';
import { browserName, osName, osVersion, mobileModel, browserVersion } from 'react-device-detect';

import { mapStateToProps } from 'root/redux/selector/selector';
import { withRouter } from 'react-router-dom';
import { Trans, Translation, withTranslation } from 'react-i18next';
import { userAction } from 'root/redux/action';
import PropTypes from 'prop-types';
import Countdown from 'react-countdown-now';
import Select from 'react-select';
import vm from './Register.controller';
import SliderRecaptchaPopup from 'root/components/sliderRecaptcha-popup/sliderRecaptcha-popup.selector';
import commonUtil from '../../utils/common-util';
import TurnstileWidget from 'root/components/TurnstileWidget/Turnstile';
import { loadAuthSettings } from '../../utils/turnstile-util';
import { MODULE_TYPE } from '@constants';

class Register extends React.Component {
    elementRef = createRef();
    isMount = true;
    constructor(props) {
        super(props);
        vm.init(this);
    }

    initRegisterSettings = () => {
        const { portal, language } = this.props;
        loadAuthSettings(true, 'REGISTRATION', this, language.currencyLang, 'v2');
        if (portal && portal.settings) {
            if (portal.settings.hasOwnProperty('registerHideAff')) {
                let hideAff = portal.settings.registerHideAff;
                this.setState({ hideAff });
            }

            if (portal.settings.hasOwnProperty('currencies')) {
                let currencies = portal.settings.currencies.split(',').map((c) => ({ value: c, label: vm.getCurrencyDescription(c) }));
                this.setState({ currencies }, () => {
                    this.getCountryInfo().then(() => this.autoCompleteForm());
                });
            }

            this.checkRegisterOTPEnabled();
        }
    };

    checkRegisterOTPEnabled = () => {
        window.SPL_Register.checkRegisterOTPEnabled().then((res) => {
            if (this.isMount) {
                this.setState({ registerPhoneVerification: res.registerPhoneVerification, registerOTPCurrencyControl: res.registerOTPCurrencyControl });
            }
        });
    };

    autoCompleteForm = () => {
        const { portal, language } = this.props;

        const currency = language?.currencyLang;

        if (!currency) {
            return;
        }

        this.handleCurrencyChange({ value: currency, label: vm.getCurrencyDescription(currency) });

        // this.handleTelcodeChange();
    };

    getCountryInfo = async () => {
        const { language } = this.props;
        let list = [];

        window.SPL_Content.getCountryInfo().then((telCodeList) => {
            if (this.isMount) {
                for (let i = 0; i < telCodeList.length; i++) {
                    list = telCodeList[i];

                    Object.assign(list, { imgTel: '/public/html/images/icon/imgTel-' + list.currency + '.jpg' });

                    if (list.country === language.country) {
                        this.setState({ telCodeData: list });
                    }
                }

                let selectedObj = this.getSelectedObj(telCodeList, language.country);

                this.setState(
                    {
                        telCodeList: telCodeList,
                        telcode: selectedObj.telCode,
                        countryCode: selectedObj.countryCode,
                    },
                    () => {
                        this.checkOtpEnabled();
                    }
                );
            }
        });
    };

    getSelectedObj(telCodeList, country) {
        let returnObj = {};
        for (let i = 0; i < telCodeList.length; i++) {
            const telCodeObj = telCodeList[i];
            if (telCodeObj.country === country) {
                returnObj = {
                    telCode: `+${telCodeObj.telCode}`,
                    countryCode: telCodeObj.country,
                };
            }
        }
        return returnObj;
    }

    handlePhoneVerification = () => {
        const { phone, countdownKey, telcode, currency } = this.state;
        let thisObj = this;
        return new Promise(function (resolve, reject) {
            //this.setState({ submittedOTP: true, countdownTimer: Date.now() + 10000, countdownKey: countdownKey + 1 });
            let tempTelCode = telcode.toString();
            if (tempTelCode.startsWith('+')) {
                tempTelCode = tempTelCode.substring(1, tempTelCode.length);
            }
            let amendPhone = phone.toString();
            if (amendPhone.startsWith('0')) {
                amendPhone = amendPhone.substring(1, amendPhone.length);
            }

            window.SPL_Register.validatePhone(phone, window.merchantCode, tempTelCode).then((errMsg) => {
                if (errMsg.length > 0) {
                    thisObj.setState({ phoneError: errMsg });
                    resolve(thisObj.isError(errMsg));
                } else {
                    window.SPL_Register.handlePhoneVerification(amendPhone, tempTelCode, window.merchantCode, currency[0].value).then((errMsg) => {
                        if (errMsg.length > 0) {
                            thisObj.setState({ otpSubmitError: errMsg, otpError: errMsg, submittedOTP: false });
                        } else {
                            thisObj.setState({
                                otpSubmitError: errMsg,
                                otpError: errMsg,
                                submittedOTP: true,
                                countdownTimer: Date.now() + 60000,
                                countdownKey: countdownKey + 1,
                            });
                            if (countdownKey === null) {
                                thisObj.setState({ otpSubmitError: errMsg, countdownTimer: Date.now() + 60000, countdownKey: 1, step2Countdown: false });
                            } else {
                                thisObj.setState({ otpSubmitError: errMsg, countdownTimer: Date.now() + 60000, countdownKey: countdownKey + 1, step2Countdown: true });
                            }
                        }
                        resolve(thisObj.isError(errMsg));
                    });
                }
            });
        });
    };

    handleOTPChange = (event) => {
        let otpNumber = event.target.value;
        this.setState({ otp: otpNumber }, () => {
            this.validateOtp();
        });
    };

    validateOtp = () => {
        const { otp, telcode, currency, phone, countryCode, otpEnabled } = this.state;
        let thisObj = this;
        return new Promise(function (resolve, reject) {
            if (otpEnabled) {
                let tempTelCode = telcode.toString();
                if (tempTelCode.startsWith('+')) {
                    tempTelCode = tempTelCode.substring(1, tempTelCode.length);
                }
                let amendPhone = phone.toString();
                if (amendPhone.startsWith('0')) {
                    amendPhone = amendPhone.substring(1, amendPhone.length);
                }
                window.SPL_Register.validateOtp(amendPhone, otp, tempTelCode, window.merchantCode, currency[0].value).then((errMsg) => {
                    let err = [];
                    let verified = errMsg.verified;
                    if (otp === null || otp === '') {
                        err = errMsg.errMsg;
                    } else {
                        if (verified === false) {
                            err = ['global:global.verification.codeIncorrect'];
                        }
                    }
                    thisObj.setState({ otpSubmitError: err, otpValidate: verified });
                    resolve(thisObj.isError(errMsg));
                });
            } else {
                resolve(false);
            }
        });
    };

    checkOtpEnabled = () => {
        const { registerPhoneVerification, registerOTPCurrencyControl, currency } = this.state;
        let otpEnabled = false;
        if (registerPhoneVerification && currency && currency.length > 0) {
            if (registerOTPCurrencyControl && registerOTPCurrencyControl.hasOwnProperty(currency[0].value)) {
                otpEnabled = registerOTPCurrencyControl[currency[0].value];
            }
        }
        this.setState({ otpEnabled: otpEnabled });
    };

    componentDidMount() {
        this.initCompSettings();
        this.initRegisterSettings();
        this.getMemberBenefit();
        setTimeout(() => {
            this.getRefCode();
        }, 1000);

        if (!window.isAgent && !window.isAffiliate) {
            if (window.SPL_LiteApp.isZT828LiteApp()) {
                window.SPL_LiteApp.onRegisterPageDisableRefresh(true);
            }
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.portal !== this.props.portal) {
            this.initRegisterSettings();
        }

        // if(document.querySelectorAll("input:-internal-autofill-selected")) {
        //   document.querySelectorAll("input:-internal-autofill-selected").forEach(function(item) {
        //     item.value = null;
        //   })
        // }
    }

    componentWillUnmount() {
        this.isMount = false;
        this.setState = () => {
            return;
        };

        if (!window.isAgent && !window.isAffiliate) {
            if (window.SPL_LiteApp.isZT828LiteApp()) {
                window.SPL_LiteApp.onRegisterPageDisableRefresh(false);
            }
        }
    }

    initCompSettings() {
        return new Promise((resolve) => {
            // Step 1: Standard way to get from portal settings
            let tempSettings = commonUtil.getSettingFromPortalSettings({
                vm: this,
                settingName: 'registerConfiguration',
                notViewType: true,
                notFeatures: true,
            });

            // Step 2: Massage again to get the specific data you need (Skip if not needed)
            let compSettings = { customAffiliateErrorMsg: tempSettings.customAffiliateErrorMsg, isAllowTurnstileForLiteApp: tempSettings.isAllowTurnstileForLiteApp };

            this.setState({ compSettings }, () => {
                resolve();
            });
        });
    }

    getMemberBenefit = () => {
        const { language } = this.props;
        window.SPL_Content.readMemberBenefit(language.countryLanguageKey, (res) => {
            if (this.isMount) {
                this.setState({ memberBenefit: res.data });
            }
        });
    };

    handleUserNameChange = (event) => {
        this.setState({ username: event.target.value }, () => {
            //this.validateUserName();
            //this.checkUsernameErrMsg();
        });
    };

    validateUserName = () => {
        const { username } = this.state;
        let thisObj = this;
        return new Promise(function (resolve, reject) {
            window.SPL_Register.validateUsername(username, window.merchantCode).then((errMsg) => {
                thisObj.setState({ usernameError: errMsg, usernameErrMsg: errMsg.length });
                resolve(thisObj.isError(errMsg));
            });
        });
    };

    //   checkUsernameErrMsg = () => {
    //     if (this.state.username !== null || this.state.username !== '') {
    //       window.SPL_Register.validateUsername(this.state.username, window.merchantCode).then((errMsg) => {
    //         this.setState({ usernameErrMsg: errMsg.length });
    //       });
    //     }
    //   };

    handlePasswordChange = (event) => {
        this.setState({ password: event.target.value }, () => {
            this.validatePassword();
            // this.checkPasswordErrMsg();
        });
    };

    validatePassword = () => {
        const { password, confirmpassword } = this.state;
        let thisObj = this;
        return new Promise(function (resolve, reject) {
            window.SPL_Register.validatePassword(password, confirmpassword).then((errMsg) => {
                const specialCharError = Array.isArray(errMsg) ? errMsg.find((errObj) => errObj?.specialCharsFormat) : null;
                if (specialCharError) {
                    thisObj.setState({
                        showSpecialCharsErrMsg: !specialCharError?.status,
                    });
                }
                thisObj.setState({ passwordError: errMsg });
                resolve(thisObj.isError(errMsg));
            });
        });
    };

    checkPasswordErrMsg = () => {
        if (this.state.password !== null || this.state.password === '' || this.state.confirmpassword !== null || this.state.confirmpassword !== '') {
            window.SPL_Register.validatePassword(this.state.password, this.state.confirmpassword).then((errMsg) => {
                this.setState({ passwordErrMsg: (errMsg || []).length });
            });
        }
    };

    handleFullnameChange = (event) => {
        this.setState({ fullname: event.target.value }, () => {
            //   this.validateFullname();
            //   this.checkFullnameErrMsg();
        });
    };

    validateFullname = () => {
        const { fullname } = this.state;
        let thisObj = this;
        return new Promise(function (resolve, reject) {
            window.SPL_Register.validateFullName(fullname, window.merchantCode).then((errMsg) => {
                thisObj.setState({ fullnameError: errMsg });
                resolve(thisObj.isError(errMsg));
            });
        });
    };

    checkFullnameErrMsg = () => {
        if (this.state.fullname !== '' || this.state.fullname !== null) {
            window.SPL_Register.validateFullName(this.state.fullname, window.merchantCode).then((errMsg) => {
                this.setState({ fullnameErrMsg: errMsg.length });
            });
        }
    };

    handleEmailChange = (event) => {
        this.setState({ email: event.target.value }, () => {
            //this.validateEmail();
            //this.checkEmailErrMsg();
        });
    };

    validateEmail = () => {
        const { email } = this.state;
        let thisObj = this;
        return new Promise(function (resolve, reject) {
            window.SPL_Register.validateEmail(email, window.merchantCode).then((errMsg) => {
                thisObj.setState({ emailError: errMsg, emailErrMsg: errMsg.length });
                resolve(thisObj.isError(errMsg));
            });
        });
    };

    //   checkEmailErrMsg = () => {
    //     if (this.state.email !== '' || this.state.email !== null) {
    //       window.SPL_Register.validateEmail(this.state.email, window.merchantCode).then((errMsg) => {
    //         this.setState({ emailErrMsg: errMsg.length });
    //       });
    //     }
    //   };

    checkListingByCurrency = (currency) => {
        const existing = this.state?.telCodeList?.find((telCodeObject) => telCodeObject.currency === currency);
        if (!existing) {
            return false;
        }

        return existing;
    };

    checkListingByCountry = (country) => {
        const existing = this.state?.telCodeList?.find((telCodeObject) => telCodeObject.country === country);

        if (!existing) {
            return false;
        }

        return existing;
    };

    handleCurrencyChange = (event) => {
        const { portal, language } = this.props;
        const newCurrency = { ...event };
        this.setState({ currency: newCurrency }, () => {
            let enableUnbindCurrencyToPrefix = false;
            enableUnbindCurrencyToPrefix = !!portal?.settings?.unbindCurrencyToPrefix;

            if (!enableUnbindCurrencyToPrefix) {
                return;
            }

            const newCurrencyObject = this.checkListingByCurrency(newCurrency.value);
            const existingTelCodeObject = this.checkListingByCountry(this.state.telCodeData.country);

            if (!newCurrencyObject) {
                return;
            }

            const currencyToUnbind = portal?.settings?.unbindCurrencyToPrefix;

            if (!currencyToUnbind || currencyToUnbind.length === 0) {
                return;
            }

            const newCurrencyToUnbind = !!currencyToUnbind.find((currency) => currency === newCurrencyObject.currency);

            const existingTelCodeToUnbind = !!currencyToUnbind.find((currency) => currency === existingTelCodeObject.currency);

            if (newCurrencyToUnbind && existingTelCodeToUnbind) {
                return;
            }

            if (newCurrencyObject.country === this.state.countryCode) {
                return;
            }

            this.handleTelcodeChange(newCurrencyObject);
        });
    };

    handleTelcodeChange = (event) => {
        const { portal, language } = this.props;

        let countryCode = event.country;
        let selectedObj = this.getSelectedObj(this.state.telCodeList, countryCode);

        const filterdTelCodeObject = this.state?.telCodeList?.find((telCodeObject) => telCodeObject.currency === event.currency);

        this.setState({ telCodeData: filterdTelCodeObject, telcode: selectedObj.telCode, countryCode: selectedObj.countryCode }, () => {
            this.checkOtpEnabled();
            let enableUnbindCurrencyToPrefix = false;
            enableUnbindCurrencyToPrefix = !!portal?.settings?.unbindCurrencyToPrefix;

            if (!enableUnbindCurrencyToPrefix) {
                return;
            }

            const newTelCodeObject = this.checkListingByCurrency(event.currency);
            const existingCurrencyObject = this.checkListingByCurrency(this.state.currency.value);

            // const filterdTelCodeObject = this.state?.telCodeList?.find((telCodeObject) => telCodeObject.currency === event.currency);

            if (!newTelCodeObject) {
                return;
            }

            if (newTelCodeObject.currency === this.state.currency) {
                return;
            }

            const currencyToUnbind = portal?.settings?.unbindCurrencyToPrefix;

            if (!currencyToUnbind || currencyToUnbind.length === 0) {
                return;
            }

            const newTelCodeToUnbind = !!currencyToUnbind.find((currency) => currency === newTelCodeObject.currency);

            const existingCurrencyToUnbind = !!currencyToUnbind.find((currency) => currency === existingCurrencyObject.currency);

            if (newTelCodeToUnbind && existingCurrencyToUnbind) {
                return;
            }

            this.handleCurrencyChange({ label: event.currency, value: event.currency });
        });
    };

    handlePhoneChange = (event) => {
        let phoneNumber = event.target.value;

        this.setState({ phone: phoneNumber }, () => {
            //this.validatePhone();
        });
    };

    validatePhone = () => {
        const { phone, telcode } = this.state;
        let thisObj = this;
        return new Promise(function (resolve, reject) {
            let amendPhone = phone.toString();
            if (amendPhone.startsWith('0')) {
                amendPhone = amendPhone.substring(1, amendPhone.length);
            }
            let tempTelCode = telcode.toString();
            if (tempTelCode.startsWith('+')) {
                tempTelCode = tempTelCode.substring(1, tempTelCode.length);
            }

            window.SPL_Register.validatePhone(phone, window.merchantCode, tempTelCode).then((errMsg) => {
                thisObj.setState({ phoneError: errMsg });
                resolve(thisObj.isError(errMsg));
            });
        });
    };

    handleAffiliateChange = (event) => {
        this.setState({ affiliateId: event.target.value }, () => {
            //this.validateAffiliate();
            //this.checkAffiliateErrMsg();
        });
    };

    validateAffiliate = () => {
        const { affiliateId, compSettings } = this.state;
        let thisObj = this;
        return new Promise(function (resolve, reject) {
            window.SPL_Register.validateAffiliate(affiliateId, window.merchantCode, compSettings?.customAffiliateErrorMsg).then((errMsg) => {
                thisObj.setState({ affiliateError: errMsg, affiliateErrMsg: errMsg.length });
                resolve(thisObj.isError(errMsg));
            });
        });
    };

    //   checkAffiliateErrMsg = () => {
    //     if (this.state.affiliateId !== '' || this.state.affiliateId !== null) {
    //       window.SPL_Register.validateAffiliate(this.state.affiliateId, window.merchantCode).then((errMsg) => {
    //         this.setState({ affiliateErrMsg: errMsg.length });
    //       });
    //     }
    //   };

    isError = (errMsg) => {
        return (errMsg || [])?.filter((err) => !err.status).length > 0;
    };

    handleSubmit = () => {
        const { username, password, fullname, email, phone, affiliateId, telcode, currency, otpEnabled, registerApiLoading, captchaToken } = this.state;
        const { language, screen } = this.props;

        // to avoid button click function triggered
        if (registerApiLoading || !vm.onPasswordValidationChecking()) {
            return;
        }

        this.setState({ registerApiLoading: true });
        Promise.all([
            this.validateUserName(),
            this.validatePassword(),
            this.validateEmail(),
            this.validatePhone(),
            this.validateFullname(),
            this.validateOtp(),
            this.validateAffiliate(),
        ]).then((data) => {
            // true mean having error
            if (data.includes(true)) {
                this.setState({ registerApiLoading: false });
                return;
            } else {
                let languageKey = language.key;
                let amendPhone = phone.toString();
                if (amendPhone.startsWith('0')) {
                    amendPhone = amendPhone.substring(1, amendPhone.length);
                }
                let registerObj = {
                    login: username,
                    password: password,
                    email: email,
                    name: fullname,
                    langKey: languageKey,
                    language: languageKey.toUpperCase(),
                    phone: telcode + amendPhone,
                    merchantCode: window.merchantCode,
                    currency: currency.value,
                    affiliateName: affiliateId === '' ? null : affiliateId,
                    phoneVerifyKey: otpEnabled ? this.state.otp : undefined,
                    captchaToken: captchaToken,
                };

                window.SPL_Member.validateIpDevice(username).then((data) => {
                    if (data.error !== '') {
                        this.props.history.push('/redirect-error?' + data.error);
                    } else if (data.error === '') {
                        const fpRegisterParam = {
                            deviceType: screen.viewType,
                            deviceOs: `${browserName}_${browserVersion}_${osName}_${osVersion}_${mobileModel}`,
                        };
                        const finalRegObject = {
                            ...registerObj,
                            ...fpRegisterParam,
                        };

                        window.SPL_Register.registerAccount(finalRegObject).then((data) => {
                            if (data.status === 'S') {
                                if (!window.isAgent && !window.isAffiliate) {
                                    if (window.SPL_LiteApp.isZT828LiteApp()) {
                                        window.SPL_LiteApp.onRegisterSuccess(username, password);
                                    }
                                }

                                this.props.accountFunction.setCompleteTag();
                                this.loginAndNavigate();
                            } else {
                                if (data.defaultErrMsg && data.defaultErrMsg === 'captcha timeout') {
                                    let _captchaToken = this.state.captchaToken;
                                    _captchaToken = null;
                                    this.setState({ captchaToken: _captchaToken });
                                }
                                const { errMsg } = data;
                                if (errMsg === 'captcha triggered') {
                                    this.setState({ showSliderCaptcha: true });
                                } else {
                                    this.setState({ regErrMsg: data.errMsg, passwordRulesSettingJson: data?.passwordRulesSettingJson || null });
                                }
                            }
                            this.setState({ registerApiLoading: false });
                        });
                    }
                });
            }
        });
    };

    loginAndNavigate = () => {
        let loginObj = {
            domain: '',
            isAffiliate: false,
            isVipLogin: false,
            language: this.props.language.key,
            merchantCode: window.merchantCode,
            login: this.state.username,
            password: this.state.password,
            rememberMe: false,
        };

        window.SPL_Member.validateIpDevice(this.state.username).then((data) => {
            if (data.error !== '') {
                this.props.history.push('/redirect-error?' + data.error);
            } else if (data.error === '') {
                this.props.dispatch(userAction.login(loginObj)).then(() => {
                    this.props.dispatch(userAction.isAuthenticated()).then(() => {
                        if (commonUtil.getIsZT828LiteApp()) {
                            const { login, password, rememberMe } = loginObj;
                            const { user } = this.props;
                            const currencyData = user && user.account && user.account.currency;
                            window.SPL_LiteApp.onLoginSuccess(login, password, rememberMe, false, currencyData);
                        }

                        // will reload, hence no need this func.
                        // core.getLoggedInLanguage(this.props);

                        commonUtil.redirectToDeposit(this.props.history);
                    });
                });
            }
        });
    };

    termsOnClick = (url) => {
        window.open(url, '', 'width=1025,height=700,toolbar=no,location=no,directories=no,status=yes,menubar=no,scrollbars=no');
    };

    getRefCode = () => {
        window.SPL_Content.validateAffParam().then((res) => {
            if (res.validateAffParam) {
                let refCode = '';

                if (res.validateAffParam.refCode) {
                    refCode = res.validateAffParam.refCode;
                } else if (res.validateAffParam.code) {
                    refCode = res.validateAffParam.code;
                }

                if (refCode) {
                    this.setState({ affiliateId: refCode, disabledAffiliate: true });
                }
            }
        });
    };

    render() {
        const { tag, screen, language, authSettingsReducer } = this.props;
        const { showSliderCaptcha, passwordRulesSettingJson } = this.state;

        const turnstileDisabled = authSettingsReducer?.processedData?.[MODULE_TYPE.REGISTRATION]?.turnstileEnabled && !this.state.captchaToken;

        const renderer = ({ hours, minutes, seconds, completed }) => {
            if (completed) {
                // Render a complete state
                return <Completionist />;
            } else {
                // Render a countdown
                return (
                    <Translation>
                        {(t) => (
                            <span>
                                {t('global:global.verification.resend')}({seconds}s)
                            </span>
                        )}
                    </Translation>
                );
            }
        };

        const Completionist = () => {
            return (
                <a className='getOtp-button' onClick={() => this.handlePhoneVerification()}>
                    Resend
                </a>
            );
        };

        const currencyOption = (props) => {
            return (
                <>
                    <div>{props.label}</div>
                </>
            );
        };

        const telCodeOption = (props) => {
            return (
                <Fragment>
                    <img src={props.imgTel} alt='flag' />
                    <span>+{props.telCode}</span>
                </Fragment>
            );
        };

        let SliderRecaptchaPopupProps = {
            showSliderCaptcha: this.state.showSliderCaptcha,
            triggerV3token: vm.triggerV3token,
        };

        const isMobileLiteApp = commonUtil.getIsZT828LiteApp() && screen.isMobile;
        const isLiteApp = commonUtil.getIsZT828LiteApp();

        return (
            <Translation>
                {(t) =>
                    tag === 'register' && (
                        <>
                            {commonUtil.getIsZT828LiteApp() && (
                                <div className='form-header'>
                                    <h3>{t('register:register.bannerTitle')}</h3>
                                    <p>{t('register:register.bannerDesc')}</p>
                                </div>
                            )}
                            <div>
                                {!commonUtil.getIsZT828LiteApp() && (
                                    <div className='form-header'>
                                        <h3>{t('register:register.bannerTitle')}</h3>
                                        <p>{t('register:register.bannerDesc')}</p>
                                    </div>
                                )}
                                <div className={`create-acount ${isMobileLiteApp ? 'login-panel-liteapp' : isLiteApp ? 'create-account-liteapp' : ''}`}>
                                    {showSliderCaptcha && <SliderRecaptchaPopup {...SliderRecaptchaPopupProps} />}
                                    <ul>
                                        <li>
                                            <label>
                                                {t('global:global.form.username-placeholder')} <span className='text-red'>*</span>
                                            </label>
                                            <div className='input-box' autoComplete='off'>
                                                <input
                                                    type='text'
                                                    className={`${this.state.usernameError.length > 0 ? 'is-invalid' : ''}`}
                                                    name='username'
                                                    id='username'
                                                    // autoComplete='off'
                                                    // autoComplete='new-password'
                                                    autoComplete='nope'
                                                    placeholder={t('global:global.form.username-placeholder')}
                                                    value={this.state.username}
                                                    onChange={this.handleUserNameChange}
                                                    onBlur={this.validateUserName}
                                                    onFocus={this.validateUserName}
                                                />
                                            </div>
                                            {this.state.usernameError.map((errMsg, index) => {
                                                return (
                                                    <div key={index} className='text-red'>
                                                        <Trans i18nKey={errMsg}></Trans>
                                                    </div>
                                                );
                                            })}
                                        </li>
                                        <li>
                                            <label>
                                                {t('global:global.form.newpassword-placeholder')} <span className='text-red'>*</span>
                                            </label>
                                            <div className='input-box' autoComplete='off'>
                                                <input
                                                    type={this.state.passwordReveal['password'] ? 'text' : 'password'}
                                                    className={`${this.state.usernameError.length > 0 ? 'is-invalid' : ''}`}
                                                    name='password'
                                                    id='password'
                                                    autoComplete='nope'
                                                    placeholder={t('global:global.form.newpassword-placeholder')}
                                                    value={this.state.password}
                                                    onChange={this.handlePasswordChange}
                                                    onBlur={this.validatePassword}
                                                    onFocus={this.validatePassword}
                                                />
                                            </div>

                                            <img
                                                className={`standard-password-eye`}
                                                src={`/public/html/images/icon/${this.state.passwordReveal['password'] ? 'password-show' : 'password-hide'}.png`}
                                                alt='password-eye.png'
                                                onClick={() => vm.onToogleHideShowPasswordOnClickButton('password')}
                                            />

                                            {(this.state.passwordError || []).map((errMsg, index) => {
                                                if (!errMsg || (!this.state.showSpecialCharsErrMsg && errMsg.specialCharsFormat)) return null;

                                                return (
                                                    <div key={index} className={`register-form-error-message ${errMsg.status ? 'text-success' : 'text-danger'}`}>
                                                        {errMsg.status !== undefined && (
                                                            <img
                                                                className={`register-form-error-message-status-img`}
                                                                src={`/public/html/default_whitelabel/shared-image/icons/${errMsg.status ? 'tick' : 'close'}.png`}
                                                                alt='error-status-icon.png'
                                                            />
                                                        )}
                                                        <span>
                                                            {t(errMsg.error || errMsg, {
                                                                min: errMsg.minLength || 6,
                                                                max: errMsg.maxLength || 12,
                                                                specialCharsFormat: errMsg.specialCharsFormat,
                                                            })}
                                                        </span>
                                                    </div>
                                                );
                                            })}
                                        </li>
                                        <li>
                                            <label>
                                                {t('global:global.form.fullname')} <span className='text-red'>*</span>
                                            </label>
                                            <div className='input-box'>
                                                <input
                                                    type='text'
                                                    className={`${this.state.fullnameError.length > 0 ? 'is-invalid' : ''}`}
                                                    name='fullname'
                                                    id='fullname'
                                                    autoComplete='off'
                                                    placeholder={t('global:global.form.fullname')}
                                                    value={this.state.fullname}
                                                    onChange={this.handleFullnameChange}
                                                    onBlur={this.validateFullname}
                                                    onFocus={this.validateFullname}
                                                />
                                            </div>
                                            {this.state.fullnameError.map((errMsg, index) => {
                                                return (
                                                    <div key={index} className='text-red'>
                                                        <Trans i18nKey={errMsg}></Trans>
                                                    </div>
                                                );
                                            })}
                                        </li>
                                        <li>
                                            <label>
                                                {t('global:global.form.email-placeholder')} <span className='text-red'>*</span>
                                            </label>
                                            <div className='input-box'>
                                                <input
                                                    type='text'
                                                    className={`${this.state.emailError.length > 0 ? 'is-invalid' : ''}`}
                                                    name='email'
                                                    id='email'
                                                    autoComplete='off'
                                                    placeholder={t('global:global.form.email-placeholder')}
                                                    value={this.state.email}
                                                    onChange={this.handleEmailChange}
                                                    onBlur={this.validateEmail}
                                                    onFocus={this.validateEmail}
                                                />
                                            </div>
                                            {this.state.emailError.map((errMsg, index) => {
                                                return (
                                                    <div key={index} className='text-red'>
                                                        {this.props.t(errMsg)}
                                                    </div>
                                                );
                                            })}
                                        </li>
                                        <li>
                                            <label>
                                                {t('global:global.form.currency')} <span className='text-red'>*</span>
                                            </label>
                                            <Select
                                                ref={this.currencyDropdown}
                                                formatOptionLabel={currencyOption}
                                                getOptionValue={(option) => `${option.value}`}
                                                options={this.state.currencies}
                                                defaultValue={this.state.currency.label}
                                                onChange={this.handleCurrencyChange}
                                                isSearchable={false}
                                                value={this.state.currency}
                                            />
                                            {this.state.currencyError.map((errMsg, index) => {
                                                return (
                                                    <div key={index} className='text-red'>
                                                        {this.props.t(errMsg)}
                                                    </div>
                                                );
                                            })}
                                        </li>

                                        {this.state.telCodeList && (
                                            <li>
                                                <label>
                                                    {t('global:global.menu.mobile', 'Mobile')} <span className='text-red'>*</span>
                                                </label>
                                                <div className='input-box'>
                                                    {/* <select name="telcode" id="telcode" onChange={this.handleTelcodeChange} value={this.state.countryCode}>
                          {(this.state.telCodeList || []).map(telcode => (
                            <option key={telcode.country} value={telcode.country}>{`+${telcode.telCode}`}</option>
                          ))}
                        </select> */}
                                                    <Select
                                                        ref={this.telCodeDropdown}
                                                        classNamePrefix='telcode-select'
                                                        // getOptionLabel={option =>
                                                        //   `+${option.telCode}`
                                                        // }
                                                        formatOptionLabel={telCodeOption} // for custom select option
                                                        getOptionValue={(option) => `${option.country}`}
                                                        options={this.state.telCodeList}
                                                        defaultValue={this.state.telCodeData}
                                                        onChange={this.handleTelcodeChange}
                                                        isSearchable={false}
                                                        value={this.state.telCodeData}
                                                    />
                                                    <input
                                                        type='tel'
                                                        className={`${this.state.phoneError.length > 0 ? 'is-invalid' : ''}`}
                                                        name='phone'
                                                        id='phone'
                                                        autoComplete='off'
                                                        placeholder={t('global:global.form.phone')}
                                                        value={this.state.phone}
                                                        onChange={this.handlePhoneChange}
                                                        onBlur={this.validatePhone}
                                                        onFocus={this.validatePhone}
                                                    />
                                                </div>

                                                {this.state.phoneError.map((errMsg, index) => {
                                                    return (
                                                        <div key={index} className='text-red'>
                                                            <Trans i18nKey={errMsg}></Trans>
                                                        </div>
                                                    );
                                                })}
                                            </li>
                                        )}

                                        {this.state.otpEnabled && (
                                            <li>
                                                <div className='input-box'>
                                                    <input
                                                        className={`${this.state.otpSubmitError.length > 0 ? 'is-invalid' : ''}`}
                                                        id='otp'
                                                        name='otp'
                                                        type='number'
                                                        autoComplete='off'
                                                        onKeyDown={(evt) => ['e', '+', '-', '.'].includes(evt.key) && evt.preventDefault()}
                                                        debounceTimeout={1000}
                                                        placeholder={t('global:global.verification.enterOTPCode')}
                                                        value={this.state.otp}
                                                        onChange={this.handleOTPChange}
                                                        onBlur={this.validateOtp}
                                                    />
                                                </div>
                                                {this.state.submittedOTP === true && this.state.step2Countdown === false ? (
                                                    <div key={this.state.countdownKey} className='getOtp-button'>
                                                        <Countdown date={this.state.countdownTimer} renderer={renderer} />
                                                    </div>
                                                ) : this.state.submittedOTP === true && this.state.step2Countdown === true ? (
                                                    <div key={this.state.countdownKey} className='getOtp-button'>
                                                        <Countdown date={this.state.countdownTimer} renderer={renderer} />
                                                    </div>
                                                ) : (
                                                    <span className='getOtp-button' onClick={this.handlePhoneVerification}>
                                                        {t('register:register.form.getCode')}
                                                    </span>
                                                )}

                                                {this.state.otpSubmitError.map((errMsg, index) => {
                                                    return (
                                                        <div key={index} className='text-red'>
                                                            <Trans i18nKey={errMsg}></Trans>
                                                        </div>
                                                    );
                                                })}
                                            </li>
                                        )}
                                        <li>
                                            <label>{t('global:global.form.aff_refID', 'Affiliate / Referral ID')}</label>
                                            <div className='input-box'>
                                                <input
                                                    type='text'
                                                    className={`${this.state.affiliateError.length > 0 ? 'is-invalid' : ''}`}
                                                    name='affiliate'
                                                    id='affiliate'
                                                    autoComplete='off'
                                                    placeholder={t('global:global.form.affiliateID-placeholderv2')}
                                                    value={this.state.affiliateId}
                                                    onChange={this.handleAffiliateChange}
                                                    onBlur={this.validateAffiliate}
                                                    disabled={this.state.disabledAffiliate}
                                                />
                                            </div>
                                            {this.state.affiliateError.map((errMsg, index) => {
                                                return (
                                                    <div key={index} className='text-red'>
                                                        <Trans i18nKey={errMsg}></Trans>
                                                    </div>
                                                );
                                            })}
                                        </li>
                                    </ul>
                                    <p className='text'>
                                        <Trans i18nKey={'register:register.096-tnc'} />
                                        <span onClick={() => this.termsOnClick(this.state.termsLink)}>{t('register:register.condition.tnc')}</span>
                                        {language?.countryLanguageKey === 'MS_MY' ? t('register:register.condition.tnc1') : ''}.
                                    </p>

                                    {/* Cloudfare Capcha Turnstile */}
                                    <div ref={this.elementRef} className='turnstile-widget-register'>
                                        <TurnstileWidget
                                            moduleType={'REGISTRATION'}
                                            theme={'light'}
                                            onVerifyCallback={(token) => {
                                                this.setState({
                                                    captchaToken: token,
                                                    isTurnstilelFailed: false,
                                                });
                                            }}
                                            onErrorCallback={(token) => {
                                                this.setState({
                                                    captchaToken: token,
                                                    isTurnstilelFailed: true,
                                                    regErrMsg: t('register:register.messages.error.turnstileError'),
                                                });
                                            }}
                                        />
                                    </div>

                                    <button
                                        className='btn-normal'
                                        onClick={() => this.handleSubmit()}
                                        disabled={
                                            !this.state.username ||
                                            !this.state.password ||
                                            !this.state.fullname ||
                                            !this.state.email ||
                                            !this.state.currency ||
                                            !this.state.phone ||
                                            this.state.usernameErrMsg > 0 ||
                                            this.state.fullnameErrMsg > 0 ||
                                            this.state.emailErrMsg > 0 ||
                                            this.state.currencyErrMsg ||
                                            this.state.phoneErrMsg > 0 ||
                                            this.state.affiliateErrMsg > 0 ||
                                            this.state.registerApiLoading ||
                                            this.isError(this.state.passwordError) ||
                                            this.state.isTurnstilelFailed ||
                                            turnstileDisabled
                                        }
                                    >
                                        {t('register:register.form.register')}
                                    </button>
                                    {/* some error msg might be array */}
                                    <div className='err-summary-container'>
                                        {Array.isArray(this.state.regErrMsg) &&
                                            this.state.regErrMsg.map((errMsg, index) => {
                                                return (
                                                    <div key={index} className='text-red'>
                                                        <div
                                                            dangerouslySetInnerHTML={{
                                                                __html: t(errMsg, {
                                                                    min: passwordRulesSettingJson?.minLength || 6,
                                                                    max: passwordRulesSettingJson?.maxLength || 12,
                                                                    specialCharsFormat: passwordRulesSettingJson?.specialChars?.format?.replace(/\\/g, ''),
                                                                }),
                                                            }}
                                                        />
                                                    </div>
                                                );
                                            })}

                                        {!Array.isArray(this.state.regErrMsg) && this.state.regErrMsg && (
                                            <div className='text-red'>
                                                <div
                                                    dangerouslySetInnerHTML={{
                                                        __html: t(this.state.regErrMsg, {
                                                            min: passwordRulesSettingJson?.minLength || 6,
                                                            max: passwordRulesSettingJson?.maxLength || 12,
                                                            specialCharsFormat: passwordRulesSettingJson?.specialChars?.format?.replace(/\\/g, ''),
                                                        }),
                                                    }}
                                                />
                                            </div>
                                        )}
                                    </div>
                                    {/* <div className='member-benefits' dangerouslySetInnerHTML={{ __html: this.state.memberBenefit }}></div> */}
                                </div>
                            </div>
                        </>
                    )
                }
            </Translation>
        );
    }
}

Register.propTypes = {
    accountFunction: PropTypes.object.isRequired,
};

export default connect(mapStateToProps)(withTranslation(['popupTransfer', 'password'])(withRouter(Register)));
