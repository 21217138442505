export function du_getMonthSelectOption(vm) {
    const { t } = vm.props;
    var months = [];

    months.push({ value: 1, text: t('global:months.jan') });
    months.push({ value: 2, text: t('global:months.feb') });
    months.push({ value: 3, text: t('global:months.mar') });
    months.push({ value: 4, text: t('global:months.apr') });
    months.push({ value: 5, text: t('global:months.may') });
    months.push({ value: 6, text: t('global:months.jun') });
    months.push({ value: 7, text: t('global:months.jul') });
    months.push({ value: 8, text: t('global:months.aug') });
    months.push({ value: 9, text: t('global:months.sep') });
    months.push({ value: 10, text: t('global:months.oct') });
    months.push({ value: 11, text: t('global:months.nov') });
    months.push({ value: 12, text: t('global:months.dec') });

    return months;
}

export function du_getTotalDaysByMonthAndYear(month, year) {
    let totalDays = 0;
    let isLeapYear = du_isLeapYear(year);

    month = Number(month);
    if (month === 2) {
        if (isLeapYear) {
            totalDays = 29;
        } else {
            totalDays = 28;
        }
    } else if (month === 4 || month === 6 || month === 9 || month === 11) {
        // 30-day-months
        totalDays = 30;
    } else {
        totalDays = 31;
    }

    return totalDays;
}

export function du_massageDayByMonthOrYear(day, month, year, days) {
    let _day = day;
    if (month && year) {
        let totalDays = du_getTotalDaysByMonthAndYear(month, year);
        if (day > totalDays) {
            _day = days.find((e) => e.value === totalDays).value;
        }
    }
    return _day;
}

export function du_isLeapYear(year) {
    return year % 100 === 0 ? year % 400 === 0 : year % 4 === 0;
}

export function du_getDaysInCurrentMonth() {
    const date = new Date();

    return new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
}

export function du_convertTotalDaysToArr(arrays) {
    let arrayObj = [];
    for (let i = 0; i < arrays; i++) {
        let obj = {};
        obj.label = i + 1;
        obj.value = i + 1;
        arrayObj.push(obj);
    }
    return arrayObj;
}
