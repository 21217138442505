import React, { Fragment } from 'react';
import Select from 'react-dropdown-select';
import { Trans, Translation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { mapStateToProps } from 'root/redux/selector/selector';
import Loading from 'root/components/Loading/Loading';
import SmsVerifyModal from 'root/components/SmsVerifyModal';
import SVGElements from 'root/components/SVGElements';
import { walletAction } from 'root/redux/action/wallet.action';
import notification from 'root/utils/notification';
import commonUtil from 'root/utils/common-util';
class Transfer2 extends React.Component {
    isMount = true;
    defaultPromo = {
        id: 0,
        bonusName: <Trans i18nKey={'settings:settings.dropdown.pleaseselect'}></Trans>,
        code: '',
        bonusType: '',
    };
    constructor(props) {
        super(props);
        this.state = {
            active: 0,
            providerList: [],
            toProviderList: [],
            totalvalidturnoveramount: 0,
            fromProvider: [],
            toProvider: [],
            fromProviderBalance: '',
            toProviderBalance: '',
            transferAmount: '',
            selectedPromotionCode: [],
            promoCodeList: [],
            sportbook: [
                { key: 'cmd368', value: 'CMD' },
                { key: 'maxbet', value: 'IBC' },
                { key: 'betrader', value: 'BR' },
                { key: 'bti', value: 'BTI' },
                { key: 'm8', value: 'M8' },
                { key: 'im', value: 'IME' },
                { key: 'tfg', value: 'TF' },
            ],
            casinoSlotFishing: [
                { key: 'dreamgaming', value: 'DG' },
                { key: 'ae_sexy', value: 'AES' },
                { key: 'ae_sexy', value: 'AES2' },
                // { key: 'gameplay', value: 'GP' },
                { key: 'ag_gaming', value: 'AG2' },
                { key: 'mg', value: 'MGP' },
                { key: 'ebet', value: 'EBET' },
                { key: 'pt_logo', value: 'PT' },
                { key: 'pt_logo', value: 'PT2' },
                { key: 'pt_logo', value: 'PT3' },
                { key: 'pt_logo', value: 'PTL' },
                { key: 'ybl', value: 'YBL' },
                { key: 'sa', value: 'SA' },
                { key: 'allbet', value: 'AB' },
                { key: 'spade', value: 'SG' },
                { key: 'ultimate', value: 'UL' },
                { key: 'PP', value: 'PP' },
                { key: 'ttg', value: 'TTG' },
                { key: 'pngo', value: 'PG' },
                { key: 'mega888', value: 'MEGA' },
                { key: 'gg_gaming', value: 'GG' },
                { key: '918kiss', value: 'SCR2' },
                { key: 'ns', value: 'NS' },
                { key: 'evo', value: 'EVO' },
                { key: 'wm', value: 'WM' },
                { key: 'hb', value: 'HB' },
                { key: 'PPL', value: 'PPL' },
                { key: 'gd', value: 'GD' },
                { key: 'jkr', value: 'JKR' },
                { key: 'hongchow', value: 'HC' },
                { key: 'jili', value: 'JL' },
                { key: 'bg', value: 'BG' },
                { key: 'nt', value: 'NT' },
                { key: 'rt', value: 'RT' },
                { key: 'psy8', value: 'PSY8' },
                { key: 'funkygames', value: 'FG' },
                // { key: 'minited', value: 'MT' },
                { key: 'pgsoft', value: 'PGS' },
                { key: 'yesgetrich', value: 'YGR' },
                { key: 'cq9', value: 'CQ9' },
                { key: 'fachai', value: 'FC' },
                { key: 'binl2', value: 'BINL2' },
                { key: 'bins2', value: 'BINS2' },
                { key: 'jdb', value: 'JDB' },
                { key: 'allbet2', value: 'AB2' },
                { key: 'rg', value: 'RG' },
                { key: 'ps', value: 'PS' },
                { key: 'ygg', value: 'YGG' },
                { key: 'spx', value: 'SPX' },
                { key: 'ap', value: 'AP' },
                { key: 'drg', value: 'DRG' },
                { key: 'w', value: 'W' },
                { key: 'we', value: 'WE' },
                { key: 'ds', value: 'DS' },
                { key: 'ep', value: 'EP' },
                { key: 'exs', value: 'EXS' },
                { key: 'vp', value: 'VP' },
                { key: 'vc', value: 'VC' },
                { key: 'fs', value: 'FS' },
                { key: 'sw', value: 'SW' },
                { key: 'r88', value: 'R88' },
                { key: 'weslt', value: 'WESLT' },
                { key: 'jdb2', value: 'JDB2' },
            ],
            others: [
                { key: 'idn_poker', value: 'IDN' },
                { key: 'qq', value: 'QQK' },
                { key: 'winwinlottery', value: 'WWL' },
                { key: 'v8poker', value: 'V8' },
                { key: 'kingmaker', value: 'KM' }, // TODO: remove this after SUP-70770 go LIVE
                { key: 'kingmaker', value: 'KM2' },
                { key: 'gameplay', value: 'GP' },
                { key: 'minited', value: 'MT' },
                { key: 'granddragon', value: 'GDL' },
                { key: 'funkygames', value: 'FG' },
                { key: 'shicai', value: 'GW' },
                { key: 'digmaan', value: 'DIG' },
                { key: 'tcg', value: 'TCG' },
                { key: 'tcga', value: 'TCGA' },
                { key: 'ws', value: 'WS' },
                { key: 'spb', value: 'SPB' },
                { key: 't1g', value: 'T1G' },
                { key: 'mtv', value: 'MTV' },
            ],
            mainWallet: '',
            promoSelected: false,
            bonusRate: '',
            maxBonus: '',
            specialCode: '',
            specialCodeId: '',
            disablePromoSelect: '',
            isValidPromo: '',
            calculateRate: 0.0,
            specialPromo: null,
            status: '',
            message: '',
            itemCategory: false,
            transferDisable: false,
            smsVerify: false,
            getPromoDetail: true,
            getPromoCodeDetail: false,
        };
    }

    componentDidMount() {
        this.isMount = true;
        this.loadProviders(true);
        this.loadMainWallet();
        commonUtil.loadCustomTranslation(['Rebate', 'Transfer'], this, function () {});
    }

    componentDidUpdate(prevProps) {
        if (prevProps.wallet.loadWallet !== this.props.wallet.loadWallet) {
            if (this.props.wallet.loadWallet) {
                this.loadMainWallet();
                this.refreshAllProviderAndBalance(false);

                setTimeout(() => {
                    this.props.dispatch(walletAction.updateLoadWallet(false));
                }, 500);
            }
        }
    }

    onReload(target) {
        this.setState({ reload: true });
        this.loadProviders();

        setTimeout(() => {
            this.setState({ reload: false });
        }, 1000);
    }

    refreshAllProviderAndBalance = (toEmptyProvider) => {
        if (toEmptyProvider !== false) {
            this.setState({ providerList: [] });
        }
        this.loadProviders(true);
        this.loadPromotions();
    };

    loadProviders = (isLoadAsyncProviderWallet) => {
        this.setState({ iconLoading: true });
        window.SPL_Provider.getAllProviderWalletList(this.props.language, false, this.props)
            .then((data) => {
                if (this.isMount) {
                    let providerList = data.providerList;
                    let fromProvider;
                    let toProvider;
                    const { currency } = this.props.user.account || { currency: '' };
                    const { t } = this.props;

                    for (let i = 0; i < providerList.length; i++) {
                        let balance = providerList[i].balance;
                        if (balance) {
                            balance = window.SPL_Other.formatAmount(providerList[i].balance);
                        } else {
                            balance = 0;
                        }
                        // providerList[i].provider = `${providerList[i].provider === null ? null : providerList[i].provider}`;
                        providerList[i].displayName = `${
                            providerList[i].provider === null
                                ? t('settings:settings.mywallet')
                                : this.state.customTranslationObj && this.state.customTranslationObj[providerList[i].provider]
                                ? t(this.state.customTranslationObj[providerList[i].provider])
                                : providerList[i].provider
                        }`;
                        providerList[i].name = `${
                            providerList[i].provider === null
                                ? t('settings:settings.mywallet')
                                : this.state.customTranslationObj && this.state.customTranslationObj[providerList[i].provider]
                                ? t(this.state.customTranslationObj[providerList[i].provider])
                                : providerList[i].name
                        }`;
                        providerList[i].display = `${providerList[i].name ? providerList[i].name : t('settings:settings.mywallet')} (${
                            window.SPL_Other.formatAmount(balance) || window.SPL_Other.formatAmount('0')
                        } ${currency}) ${t(providerList[i].serverMaintenanceStr)}`;

                        if (providerList[i].provider === null) {
                            fromProvider = [providerList[i]];
                            toProvider = [providerList[i]];
                        }
                    }

                    let sortedProviderList = sortProvider(providerList);
                    this.setState(
                        {
                            providerList: sortedProviderList,
                            toProviderList: sortedProviderList,
                            totalvalidturnoveramount: data.totalvalidturnoveramount,
                            transferAmount: window.SPL_Other.formatAmount(fromProvider[0].balance) || window.SPL_Other.formatAmount('0'),
                            fromProvider: fromProvider,
                            toProvider: toProvider,
                            iconLoading: false,
                            fromProviderBalance: window.SPL_Other.formatAmount(fromProvider[0].balance) || window.SPL_Other.formatAmount('0'),
                            toProviderBalance: window.SPL_Other.formatAmount(toProvider[0].balance) || window.SPL_Other.formatAmount('0'),
                            status: '',
                            message: '',
                            specialPromo: null,
                        },
                        () => {
                            this.updateToProviderList();
                            this.getItem();
                        }
                    );
                }
            })
            .then(() => {
                if (isLoadAsyncProviderWallet) {
                    this.loadAsyncProviderWallet();
                }
            });
    };

    updateToProviderList = () => {
        // update to provider list
        const { specialPromo, providerList, toProvider } = this.state;
        let toProviderList = providerList;
        let toProviderSelected = toProvider;

        if (specialPromo && specialPromo.providers) {
            let specialPromoProviderList = specialPromo.providers.map((pvd) => pvd.provider);
            toProviderList = providerList.filter((p) => specialPromoProviderList.includes(p.provider) || p.provider === null);
            // if selected provider is in the list then remain selected provider else get first provider from special promo list
            toProviderSelected = toProvider.filter((p) => specialPromoProviderList.includes(p.provider) || p.provider === null);
            if (toProviderSelected.length <= 0) {
                toProviderSelected = [toProviderList[0]];
            }
        }

        this.setState({ toProviderList: toProviderList, toProvider: toProviderSelected });
    };

    loadAsyncProviderWallet = () => {
        if (this.isMount) {
            this.setState({ isLoad: true }, () => {
                window.SPL_Provider.loadAsyncProviderWallet(null, this.props).then((providerWallet) => {
                    let providerList = this.state.providerList;

                    for (let i = 0; i < providerList.length; i++) {
                        let results = providerWallet.providerList.filter(function (e) {
                            return e.provider === providerList[i].provider;
                        })[0];

                        if (results) {
                            providerList[i].balance = results.balance ? results.balance : 0;
                            providerList[i].serverIsUnderMaintenance = results.serverIsUnderMaintenance;
                            providerList[i].isdisplay = true;
                            providerList[i].serverMaintenanceStr = results.serverIsUnderMaintenance ? 'error:error.underMaintainance' : '';
                        } else {
                            providerList[i].serverMaintenanceStr = '';
                        }

                        if (this.isMount) {
                            this.setState({ providerList: providerList }, () => {
                                this.updateToProviderList();
                            });
                        }
                    }
                });
            });
        }
    };

    loadMainWallet = () => {
        window.SPL_Member.getMainWallet().then((mainWallet) => {
            this.setState({ mainWallet: mainWallet.data.balance });
        });
    };

    loadPromotions = (provider) => {
        const { countryLanguageKey } = this.props.language;
        const { portal, screen } = this.props;

        if (provider) {
            let platform = null;

            if (portal && portal.settings && portal.settings.getPromoCodeByPlatform) {
                if (window.SPL_LiteApp.isZT828LiteApp()) {
                    platform = 'A';
                } else if (screen.viewType === 'web') {
                    platform = 'W';
                } else {
                    platform = 'M';
                }
            }

            window.SPL_Provider.loadPromotions(provider, null, countryLanguageKey, platform).then((promoCodeList) => {
                if (this.isMount) {
                    promoCodeList.unshift(this.defaultPromo);

                    let pl = this.state.promoCodeList;
                    pl = [];

                    for (let i = 0; i < promoCodeList.length; i++) {
                        if (promoCodeList[i].id !== 0) {
                            pl.push(promoCodeList[i]);
                        }
                    }

                    pl.unshift({ bonusName: this.props.t('settings:settings.dropdown.pleaseselect') });

                    this.setState({ promoCodeList: pl }, () => {
                        this.setState({ selectedPromotionCode: [this.defaultPromo] });
                    });
                }
            });
        } else {
            let promoCodeList = [];
            promoCodeList.unshift(this.defaultPromo);
            if (this.isMount) {
                let pl = this.state.promoCodeList;
                pl = [];
                for (let i = 0; i < promoCodeList.length; i++) {
                    if (promoCodeList[i].id !== 0) {
                        pl.push(promoCodeList[i]);
                    }
                }

                pl.unshift({ bonusName: this.props.t('settings:settings.dropdown.pleaseselect') });

                this.setState({ promoCodeList: pl }, () => {
                    this.setState({ selectedPromotionCode: [this.defaultPromo] });
                });
            }
        }
    };

    handleFromProviderChange = (value) => {
        let provider = value[0];

        if (provider) {
            this.getMinimumAmountForTransfer(provider.provider, this.state.toProvider[0].provider, null);
            this.setState({
                fromProvider: value,
                transferAmount: provider.balance === 0 ? '' : window.SPL_Other.formatAmount(provider.balance),
                fromProviderBalance: provider.balance,
            });
        }
    };

    handleToProviderChange = (value) => {
        let provider = value[0];

        if (provider) {
            this.getMinimumAmountForTransfer(this.state.fromProvider[0].provider, provider.provider, null);
            this.setState({ toProvider: value, toProviderBalance: provider.balance }, () => {
                if (!(provider.provider === null || provider.provider === '')) {
                    if (this.state.specialCode) {
                        this.validateSpecialCode();
                    } else {
                        this.loadPromotions(provider.provider);
                    }
                } else {
                    this.loadPromotions(provider.provider);
                }
            });
        }
    };

    handlePromotionCodeChange = (value) => {
        let rate = '';
        let maxBonus = '';

        if (value && value[0]) {
            rate = value[0].rate;
            maxBonus = value[0].maxBonus;

            if (value[0].code) {
                this.setState({ selectedPromotionCode: value, promoSelected: true, bonusRate: rate, maxBonus: maxBonus, specialCode: null, getPromoDetail: false }, () => {
                    this.calPromoDetails(value[0]);
                    this.calculateRate();
                    if (value.length > 0 && value[0].id !== 0) {
                        this.getMinimumAmountForTransfer(this.state.fromProvider[0].provider, this.state.toProvider[0].provider, value[0]);
                    } else {
                        this.setState({ promoSelected: false, calculateRate: 0.0 });
                    }
                });
            } else {
                this.setState({
                    selectedPromotionCode: value,
                    promoSelected: false,
                    bonusRate: rate,
                    maxBonus: maxBonus,
                    specialCode: null,
                    calculateRate: 0.0,
                    getPromoDetail: true,
                });
            }
        } else {
            this.setState({
                selectedPromotionCode: [],
                promoSelected: false,
                bonusRate: rate,
                maxBonus: maxBonus,
                specialCode: null,
                calculateRate: 0.0,
                getPromoDetail: true,
            });
        }
    };

    calPromoDetails = (value) => {
        if (value) {
            let bonus_title = value.bonusName,
                bonus_rate = value.rate * 100,
                turnover_multiplier = value.targetMultiplier,
                min_deposit = value.minDeposit,
                max_deposit = value.maxDeposit,
                max_bonus = value.maxBonus;

            this.setState({
                bonus_title: bonus_title,
                bonus_rate: bonus_rate,
                turnover_multiplier: turnover_multiplier,
                min_deposit: min_deposit,
                max_deposit: max_deposit,
                max_bonus: max_bonus,
            });
        }
    };

    getPromoCodeDetail = () => {
        this.setState({ getPromoCodeDetail: !this.state.getPromoCodeDetail });
    };

    handleSpecialCodeChange = (value) => {
        if (value) {
            this.setState({ disablePromoSelect: 'true' });
            this.setState({ specialCode: value }, () => {
                if (this.state.providerList.length > 0) {
                    this.validateSpecialCode();
                }
            });
        } else {
            this.setState(
                { disablePromoSelect: '', isValidPromo: '', specialCodeId: '', specialCode: '', specialPromo: null, calculateRate: 0.0, maxBonus: 0, transferDisable: false },
                () => {
                    this.loadProviders(true);
                }
            );
        }
    };

    validateSpecialCode = () => {
        const { providerList, specialCode, fromProvider, toProvider, transferAmount } = this.state;
        const { user } = this.props;
        let from = fromProvider[0].provider;
        if (from === 'My Wallet') {
            from = null;
        }
        let to = toProvider[0].provider;
        if (to === 'My Wallet') {
            to = null;
        }

        if (specialCode && specialCode !== '' && specialCode.length > 0) {
            window.SPL_Transaction.verifyPromotionCode(providerList, user.account.login, specialCode, from, to, transferAmount).then((data) => {
                if (data.specialPromo !== null) {
                    const { isValidPromo, isValidSpecialPromoProvider, isValidSpecialPromoAmount, specialPromo } = data;
                    this.setState(
                        {
                            isValidPromo: isValidPromo,
                            isValidSpecialPromoProvider: isValidSpecialPromoProvider,
                            isValidSpecialPromoAmount: isValidSpecialPromoAmount,
                            bonusRate: specialPromo.rate,
                            specialCodeId: specialPromo.id,
                            specialPromo: specialPromo,
                            maxBonus: specialPromo.maxBonus,
                            transferDisable: false,
                        },
                        () => {
                            this.calculateRate();
                            this.updateToProviderList();
                        }
                    );
                } else {
                    this.setState({ isValidPromo: data.isValidPromo, specialPromo: null, transferDisable: true }, () => {
                        this.updateToProviderList();
                    });
                }
            });
        }
    };

    handleAmountChange = (value, wallet) => {
        if (value) {
            if (value.toString().match(/^(\d*)\.{0,1}(\d){0,2}$/)) {
                this.setState({ transferAmount: wallet ? parseInt(value) : value }, () => {
                    const { specialPromo } = this.state;
                    this.calculateRate();
                    if (specialPromo) {
                        this.checkAmountWithSpecialCode();
                    }
                });
            }
        } else {
            this.setState({ transferAmount: wallet ? parseInt(value) : value }, () => {
                this.calculateRate();
            });
        }
    };

    checkAmountWithSpecialCode = () => {
        const { specialPromo, transferAmount } = this.state;
        let isValidSpecialPromoAmount = window.SPL_Transaction.checkAmountWithSpecialCode(specialPromo, transferAmount, specialPromo.minDeposit);
        this.setState({ isValidSpecialPromoAmount: isValidSpecialPromoAmount });
    };

    calculateRate = () => {
        const { bonusRate, transferAmount, maxBonus } = this.state;

        let total = 0;
        if (bonusRate === 0) {
            total = maxBonus;
        } else {
            total = bonusRate * transferAmount;
        }

        if (total > maxBonus) {
            total = maxBonus;
        }

        this.setState({ calculateRate: total.toFixed(2) });
    };

    getMinimumAmountForTransfer = (fromProvider, toProvider, promo) => {
        let minimumAmount = window.SPL_Transaction.getMinimumAmountForTransfer(fromProvider, toProvider, promo);
        this.setState({ minimumAmount: minimumAmount });
    };

    checkPhoneVerification = (provider, all_in_provider) => {
        const { selectedPromotionCode } = this.state;
        if (selectedPromotionCode && selectedPromotionCode.length > 0 && selectedPromotionCode[0].isPhoneVerification) {
            window.SPL_Member.getPhoneRequiredVerification().then((isReqVerification) => {
                if (isReqVerification) {
                    this.getSmsVerifyModal();
                    this.setState({ iconLoading: false });
                } else {
                    this.requestTransfer(provider, all_in_provider);
                }
            });
        } else {
            this.requestTransfer(provider, all_in_provider);
        }
    };

    requestTransfer = (provider, all_in_provider) => {
        this.setState(
            { iconLoading: true },
            () => {
                const {
                    transferAmount,
                    selectedPromotionCode,
                    toProvider,
                    fromProvider,
                    promoSelected,
                    specialCodeId,
                    specialPromo,
                    isValidPromo,
                    isValidSpecialPromoAmount,
                    isValidSpecialPromoProvider,
                    specialCode,
                } = this.state;
                if (specialPromo) {
                    specialPromo.isValidPromo = isValidPromo;
                    specialPromo.isValidSpecialPromoAmount = isValidSpecialPromoAmount;
                    specialPromo.isValidSpecialPromoProvider = isValidSpecialPromoProvider;
                }
                if (specialCode !== '' && isValidPromo === false) {
                    return;
                }
                const { t } = this.props;
                let promotionId = null;
                if (promoSelected) {
                    promotionId = selectedPromotionCode[0].id;
                } else if (specialCodeId !== '') {
                    promotionId = specialCodeId;
                } else {
                    promotionId = 0;
                }

                // if provider is not null, transfer is from balance tab, and only applicable for mainwallet to provider
                let toProviderValue = all_in_provider ? all_in_provider.provider : provider ? provider.provider : toProvider[0].provider;
                let fromProviderValue = provider === 'main' ? 'My Wallet' : fromProvider[0].provider;
                let remark = fromProviderValue === 'My Wallet' ? 'Member Deposit' : 'Member Withdraw';

                if (toProviderValue === 'My Wallet') {
                    toProviderValue = null;
                }

                if (fromProviderValue === 'My Wallet') {
                    fromProviderValue = null;
                }

                let from = fromProvider;
                let to = toProvider;

                if (fromProvider[0].provider === 'My Wallet') {
                    from[0].provider = null;
                }

                if (toProvider[0].provider === 'My Wallet') {
                    to[0].provider = null;
                }

                this.setState({ iconLoading: true }, () => {
                    let mainWalletBalance = this.state.providerList.filter((e) => e.provider === null)[0].balance;

                    window.SPL_Transaction.validateTransferFormV2(
                        mainWalletBalance,
                        transferAmount,
                        from[0],
                        to[0],
                        selectedPromotionCode.length > 0 ? selectedPromotionCode[0] : null,
                        false,
                        specialPromo
                    ).then((validateRes) => {
                        if (validateRes.status === 'F') {
                            notification.showNotification('error', t(validateRes.msg, { amount: validateRes.amount || validateRes.amount === 0 ? validateRes.amount : '' }));
                            this.setState({ iconLoading: false });
                        } else if (validateRes.status === 'V') {
                            this.getSmsVerifyModal();
                            this.setState({ iconLoading: false });
                        } else {
                            window.SPL_Transaction.requestTransfer(transferAmount, promotionId, remark, toProviderValue, fromProviderValue).then((data) => {
                                this.setState({ iconLoading: false });
                                if (data.status === 'F') {
                                    if (data.msg && data.msg.data && data.msg.data.detail) {
                                        notification.showNotification('error', t(data.msg.data.detail));
                                    } else {
                                        notification.showNotification('error', t(data.msg));
                                    }
                                    this.refreshAllProviderAndBalance(false);
                                } else {
                                    if (this.isMount) {
                                        if (this.state.promoCodeList.length > 0) {
                                            this.setState({
                                                transferAmount: '',
                                                selectedPromotionCode: [this.state.promoCodeList[0]],
                                                refreshMainWallet: true,
                                                status: data.status,
                                                message: 'transaction:transaction.transfer.success',
                                            });
                                        } else {
                                            this.setState({
                                                transferAmount: '',
                                                refreshMainWallet: true,
                                                status: data.status,
                                                message: 'transaction:transaction.transfer.success',
                                            });
                                        }
                                        setTimeout(() => {
                                            // for desktop need to use parent get main wallet to update amount for parent component also
                                            // this.loadMainWallet();
                                            this.refreshAllProviderAndBalance(false);
                                            this.props.dispatch(walletAction.updateLoadWallet(true));
                                        }, 1000);
                                    }
                                }
                            });
                        }
                    });
                });
            },
            () => {
                this.setState({ iconLoading: false });
            }
        );
    };

    swapTransfer = () => {
        let from = this.state.fromProvider;
        let to = this.state.toProvider;

        let fromB = this.state.fromProviderBalance;
        let toB = this.state.toProviderBalance;

        this.setState({ fromProvider: to, toProvider: from, fromProviderBalance: toB, toProviderBalance: fromB, specialCode: '', calculateRate: 0.0, maxBonus: 0 }, () => {
            this.handleFromProviderChange(to);
            this.handleToProviderChange(from);
            this.loadPromotions();
        });
    };

    customNoDataRenderer = ({ props, state, methods }) => (
        <Translation>{(t) => <div className='select-noData'> {t('reports:reports.noDataAvailable', 'No Data Available')}</div>}</Translation>
    );

    getItem() {
        let provider = this.state.providerList;
        let other = this.state.others;

        for (let i = 0; i < provider.length; i++) {
            for (let k = 0; k < other.length; k++) {
                if (provider[i].provider === other[k].value) {
                    this.setState({ itemCategory: true });
                }
            }
        }
    }

    getSmsVerifyModal = () => {
        this.setState({ smsVerify: !this.state.smsVerify });
    };

    renderProviderItemByCategoryList(categoryList) {
        const { t } = this.props;
        const { providerList, customTranslationObj } = this.state;
        let foundProviderList = [];
        for (let i = 0; i < categoryList.length; i++) {
            let item = categoryList[i];
            let title = providerList.filter((a) => a.provider === item.value);
            if (title && title.length > 0) {
                let foundProvider = {
                    title: title[0],
                    item: item,
                };
                foundProviderList.push(foundProvider);
            }
        }

        if (foundProviderList.length === 0) {
            return null;
        } else {
            return foundProviderList.map((providerObj, index) => {
                const { title, item } = providerObj;
                return (
                    <Fragment key={index}>
                        <div className='provider-wallet-container'>
                            <div className='item'>
                                <div className='icon-box'>
                                    <i className={`icon-transfer-${item.key} icon-custom-transfer-${title.name?.replaceAll(' ', '')}`}></i>
                                </div>
                                <div>
                                    <span> {customTranslationObj && customTranslationObj[title.provider] ? t(customTranslationObj[title.provider]) : title.name}</span>
                                    <span>{title.balance ? window.SPL_Other.formatAmount(title.balance) : window.SPL_Other.formatAmount(0)}</span>
                                </div>
                            </div>

                            <div className='turnover-box'>
                                <span>{t('transaction:transaction.transfer.turnover', 'Turn over')}</span>
                                <span className='turnover-amount'>{title.turnover ? window.SPL_Other.formatAmount(title.turnover) : window.SPL_Other.formatAmount(0)}</span>
                            </div>

                            <span
                                className='btn-all-in'
                                onClick={() => {
                                    this.handlePromotionCodeChange();
                                    this.handleSpecialCodeChange('');
                                    this.handleToProviderChange([title]);
                                    this.handleAmountChange(this.state.fromProvider[0].balance, 'My Wallet');
                                    this.requestTransfer('main', title);
                                }}
                            >
                                {t('transaction:transaction.transfer.allIn', 'All In')}
                            </span>
                        </div>
                        {index % 4 !== 3 && <div className='provider-wallet-column-separator'></div>}
                    </Fragment>
                );
            });
        }
    }

    render() {
        const { smsVerify } = this.state;
        return (
            <Translation>
                {(t) => (
                    <div className='transfer'>
                        {this.state.iconLoading && <Loading />}

                        {this.state.smsVerify && <SmsVerifyModal isOpen={smsVerify} getSmsVerifyModal={this.getSmsVerifyModal} requestTransfer={this.requestTransfer} />}
                        {/* <SmsVerifyModal getSmsVerifyModal={this.getSmsVerifyModal} requestTransfer={this.requestTransfer} /> */}

                        {this.state.getPromoCodeDetail && (
                            <div className='messaging-popup promoCodeDetail-popup'>
                                <div className='popup'>
                                    <div className='popup-body'>
                                        <div className='body-header'>
                                            <span>{this.state.bonus_title}</span>
                                            <SVGElements name='close-icon' onClick={() => this.getPromoCodeDetail()} />
                                        </div>

                                        <table>
                                            <tr>
                                                <td>Bonus %</td>
                                                <td>{this.state.bonus_rate} %</td>
                                            </tr>
                                            <tr>
                                                <td>Turnover Multiplier</td>
                                                <td>{this.state.turnover_multiplier}x</td>
                                            </tr>
                                            <tr>
                                                <td>Min Deposit</td>
                                                <td>
                                                    {(this, this.props.user.account.currency)} {this.state.min_deposit}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Max Deposit</td>
                                                <td>
                                                    {(this, this.props.user.account.currency)} {this.state.max_deposit}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Max Bonus</td>
                                                <td>
                                                    {(this, this.props.user.account.currency)} {this.state.max_bonus}
                                                </td>
                                            </tr>
                                        </table>

                                        <span className='tnc-apply'>* {t('slot:slot.tnc-apply', 'Terms & Conditions Applied')}</span>
                                    </div>
                                </div>
                                <div className='popup-overlay' onClick={() => this.getPromoCodeDetail()}></div>
                            </div>
                        )}

                        <h1>{t('transaction:transaction.transfer.transfer', 'Transfer')}</h1>
                        <ul>
                            <li className='transfer-item1'>
                                <div className='form-transfer'>
                                    <div className='form-item tf'>
                                        <div className='top'>
                                            <div className='top-title'>
                                                <span>{t('transaction:transaction.transfer.transferFrom', 'Transfer from')}</span>
                                            </div>
                                            <Select
                                                className='form-control-inner'
                                                placeholder='Transfer from'
                                                options={this.state.providerList}
                                                values={this.state.fromProvider}
                                                labelField='name'
                                                valueField='provider'
                                                key='provider'
                                                onChange={(value) => this.handleFromProviderChange(value)}
                                                searchable={false}
                                            />
                                        </div>
                                        <div className='bottom'>
                                            <input type='text' disabled value={window.SPL_Other.formatAmount(this.state.fromProviderBalance)} />
                                        </div>
                                    </div>
                                    <div className='form-item to'>
                                        <div className='top'>
                                            <button className='top-title' onClick={() => this.swapTransfer()} disabled={this.state.specialCode}>
                                                <i className='icon-transfer-btn pointer'></i>
                                                <span>{t('transaction:transaction.transfer.transferTo', 'Transfer to')}</span>
                                            </button>

                                            <Select
                                                className='form-control-inner'
                                                placeholder='Transfer to'
                                                options={this.state.toProviderList}
                                                labelField='name'
                                                valueField='provider'
                                                values={this.state.toProvider}
                                                onChange={(value) => this.handleToProviderChange(value)}
                                                searchable={false}
                                            />
                                        </div>
                                        <div className='bottom'>
                                            <input type='text' disabled value={window.SPL_Other.formatAmount(this.state.toProviderBalance)} />
                                        </div>
                                    </div>
                                </div>
                                <div className='form-item sc'>
                                    <div className='top'>
                                        <div className='top-title'>
                                            <span>{t('transaction:transaction.transfer.specialCode', 'Special Code')}</span>
                                        </div>

                                        <input
                                            name='specialCode'
                                            id='specialCode'
                                            type='text'
                                            value={this.state.specialCode ? this.state.specialCode : ''}
                                            onChange={(e) => {
                                                this.handleSpecialCodeChange(e.target.value);
                                            }}
                                            disabled={this.state.promoSelected}
                                        />

                                        {this.state.specialCode && this.state.isValidPromo === true ? (
                                            <span className='specialCodeValid'>{t('transaction:transaction.transfer.promocode-valid', 'Promo Code Valid')}</span>
                                        ) : this.state.specialCode && this.state.isValidPromo === false ? (
                                            <span className='specialCodeInvalid'>{t('transaction:transaction.transfer.promocode-invalid', 'Promo Code Invalid')}</span>
                                        ) : (
                                            <span></span>
                                        )}
                                    </div>
                                </div>
                                <div className='form-item pc'>
                                    <div className='top'>
                                        <div className='top-title'>
                                            <span>{t('transaction:transaction.transfer.promocode', 'Promo Code')}</span>
                                        </div>

                                        <Select
                                            noDataRenderer={this.customNoDataRenderer}
                                            className='form-control-inner'
                                            placeholder={t('settings:settings.dropdown.pleaseselect')}
                                            options={this.state.promoCodeList}
                                            labelField='bonusName'
                                            valueField='code'
                                            values={this.state.selectedPromotionCode}
                                            onChange={(value) => this.handlePromotionCodeChange(value)}
                                            searchable={false}
                                            disabled={this.state.disablePromoSelect}
                                        />
                                        <button onClick={() => this.getPromoCodeDetail()} disabled={this.state.getPromoDetail}>
                                            {t('transaction:transaction.transfer.promoDetailTxt', 'Promo Detail')}
                                        </button>
                                    </div>
                                    <div className='bottom'>
                                        {this.state.calculateRate > 0 ? (
                                            <input type='text' disabled value={this.state.calculateRate} />
                                        ) : (
                                            <input type='text' disabled value={0} />
                                        )}
                                    </div>
                                </div>
                                <div className='form-item ta'>
                                    <div className='top'>
                                        <div className='top-title'>
                                            <span>
                                                {t('transaction:transaction.transfer.transfer', 'Transfer')} {t('transaction:transaction.transfer.amount', 'Amount')}
                                            </span>
                                        </div>

                                        <input
                                            name='transferAmount'
                                            id='transferAmount'
                                            type='text'
                                            min='0'
                                            placeholder='0.00'
                                            value={this.state.transferAmount}
                                            onChange={(e) => {
                                                this.handleAmountChange(e.target.value);
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className='btn-box'>
                                    <div
                                        className='btn-normal btn-reset'
                                        onClick={() => {
                                            this.loadMainWallet();
                                            this.loadPromotions(null);
                                            this.handleSpecialCodeChange(null);
                                        }}
                                    >
                                        {t('register:register.form.reset', 'Reset')}
                                    </div>
                                    <button className='btn-normal btn-transfer' disabled={this.state.transferDisable} onClick={() => this.checkPhoneVerification()}>
                                        {t('transaction:transaction.transfer.transfer', 'Transfer')}
                                    </button>
                                </div>
                                <div className='status-box'>
                                    <div>
                                        {this.state.status === 'S' && (
                                            <div className='invalid-feedback text-success'>
                                                <Trans i18nKey={this.state.message}></Trans>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </li>
                            <li className='transfer-item2'>
                                <div className='transfer-tabs'>
                                    <div className='turnover-item'>
                                        <span>{t('transaction:transaction.transfer.weeklyTurnoverTotal', 'Weekly Turnover Total')}</span>
                                        <span className='item-amount'>{window.SPL_Other.formatAmount(this.state.totalvalidturnoveramount)}</span>
                                    </div>

                                    <div className='tabs-content'>
                                        <div className='provider-balance'>
                                            <ul className='provider-list'>
                                                <li>
                                                    <span>{t('global:global.menu.sportsbook2') + ' / ' + t('global:global.menu.esport2')}</span>
                                                    <div className='item'>{this.renderProviderItemByCategoryList(this.state.sportbook)}</div>
                                                </li>

                                                <hr className='provider-category-separator' />

                                                <li>
                                                    <span>
                                                        {t('global:global.menu.casino2') + ' / ' + t('global:global.menu.slot2') + ' / ' + t('global:global.menu.fishing2')}
                                                    </span>
                                                    <div className='item'>{this.renderProviderItemByCategoryList(this.state.casinoSlotFishing)}</div>
                                                </li>

                                                <hr className='provider-category-separator' />

                                                <li>
                                                    {this.state.itemCategory && <span>{t('global:global.others')}</span>}
                                                    <div className='item'>{this.renderProviderItemByCategoryList(this.state.others)}</div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                )}
            </Translation>
        );
    }
}

export default connect(mapStateToProps)(withTranslation(['transaction', 'settings', 'reports', 'register', 'popupTransfer', 'providerName'])(withRouter(Transfer2)));

const sortProvider = (providerList) => {
    return providerList.sort((a, b) => {
        if (a.displayName === 'My Wallet') {
            return -1;
        }

        if (b.displayName === 'My Wallet') {
            return 1;
        }

        if (a.displayName < b.displayName) {
            return -1;
        }

        if (a.displayName > b.displayName) {
            return 1;
        }
        return 0;
    });
};
