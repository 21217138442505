// library
import React from 'react';
import { connect } from 'react-redux';
import { mapStateToProps } from 'root/redux/selector/selector';
import { withRouter } from 'react-router-dom';

// selector / component
import SVG from 'react-inlinesvg';

class floatingNotificationContent extends React.Component {
    constructor() {
        super();
        this.state = {
            notificationContent: [],
        };
    }

    componentDidMount() {
        this.getContent();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.notificationContent !== this.props.notificationContent) {
            this.getContent();
        }
    }

    getContent() {
        this.setState({ notificationContent: this.props.notificationContent });
    }

    renderctaButtonName(data) {
        const { customTranslationObj } = this.props;
        let type = data?.ctaButtonType;
        let name = customTranslationObj?.knowMore ? customTranslationObj.knowMore : 'Know More';

        switch (type) {
            case 'CLAIM_NOW':
                name = customTranslationObj?.claimNow ? customTranslationObj.claimNow : 'Claim Now';
                break;
            case 'PLAY_NOW':
                name = customTranslationObj?.playNow ? customTranslationObj.playNow : 'Play Now';
                break;
            case 'CLICK_HERE':
                name = customTranslationObj?.clickHere ? customTranslationObj.clickHere : 'Click Here';
                break;
            case 'KNOW_MORE':
                name = customTranslationObj?.knowMore ? customTranslationObj.knowMore : 'Know More';
                break;
            case 'JOIN_NOW':
                name = customTranslationObj?.joinNow ? customTranslationObj.joinNow : 'Join Now';
                break;
            case 'APPLY_NOW':
                name = customTranslationObj?.applyNow ? customTranslationObj.applyNow : 'Apply Now';
                break;
            case 'LEARN_MORE':
                name = customTranslationObj?.learnMore ? customTranslationObj.learnMore : 'Learn More';
                break;
            case 'OTHERS':
                name = data?.ctaButtonName;
                break;
            default:
        }

        return name;
    }

    onCloseNotificationClick(data, floatingNotificationSettingId, displayFrequencyType) {
        if (this.props.notificationType === 'SLIDING') {
            this.props.setNotificationClose();
        } else {
            this.props.closeNotification(data, floatingNotificationSettingId, displayFrequencyType);
        }
    }

    render() {
        const { notificationContent } = this.state;
        const { screen, floatingNotificationSettingId, displayFrequencyType, notificationClick } = this.props;

        function getFloatingNotificationContentClassName(data) {
            let className = '';
            if (screen.viewType === 'web') {
                className = className.concat(className, 'floating-banner-container');
            } else {
                className = className.concat(className, 'floating-banner-container');
            }

            if (data.horizontalPosition === 'BOTTOM') {
                let bottomClassName = 'floating-banner-container-bottom';
                className = className.concat(' ', bottomClassName);
            } else if (data.horizontalPosition === 'RIGHT') {
                className = className.concat(' ', 'floating-banner-container-right');
            } else {
                className = className.concat(' ', 'floating-banner-container-left');
            }

            if (!data.imagePath) {
                className = className.concat(' ', 'without-img');
            }
            return className;
        }

        return (
            <div>
                {notificationContent &&
                    notificationContent.map((data, index) => {
                        const { redirectUrl, ctaButtonType } = data;
                        return (
                            <div key={index} className={getFloatingNotificationContentClassName(data)} style={{ top: data?.verticalPosition + '%' }}>
                                <div className='floating-banner-img'>
                                    <img
                                        alt=''
                                        src={data?.imagePath}
                                        width="100%"
                                        height="100%"
                                        onClick={() => redirectUrl && !ctaButtonType && notificationClick(data, floatingNotificationSettingId, displayFrequencyType)}
                                    />
                                    <button
                                        className='floating-banner-close-btn'
                                        onClick={() => this.onCloseNotificationClick(data, floatingNotificationSettingId, displayFrequencyType)}
                                    >
                                        <SVG className='' src={'/public/html/images/icon-close.svg'} />
                                    </button>
                                </div>

                                {(data?.content || (redirectUrl && ctaButtonType)) && (
                                    <div className='floating-banner-content'>
                                        {data?.content && (
                                            <div
                                                className={`floating-banner-text ${!data?.imagePath && 'withoutImg'}`}
                                                dangerouslySetInnerHTML={{
                                                    __html: data?.content,
                                                }}
                                            ></div>
                                        )}
                                        {redirectUrl && ctaButtonType && (
                                            <div className='floating-banner-button'>
                                                <button onClick={() => notificationClick(data, floatingNotificationSettingId, displayFrequencyType)}>
                                                    {this.renderctaButtonName(data)}
                                                </button>
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                        );
                    })}
            </div>
        );
    }
}

export default connect(mapStateToProps)(withRouter(floatingNotificationContent));
