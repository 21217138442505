import { du_getMonthSelectOption, du_getTotalDaysByMonthAndYear, du_getDaysInCurrentMonth, du_convertTotalDaysToArr, du_massageDayByMonthOrYear } from 'root/utils/date-util';

const controller = {
    vm: null,

    init(vm) {
        controller.vm = vm;
        controller.vm.state = {
            days: [],
            months: [],
            years: [],
            day: null,
            month: null,
            year: null,
            selectedDate: null,
        };
    },

    initDateTime() {
        const { registerAge, day, year } = controller.vm.props;
        let month = controller.vm.props.month && parseInt(controller.vm.props.month); // remove leading zero
        let currentDate = new Date();
        let days = [];
        let totalDays = 0;
        if (month && year) {
            totalDays = du_getTotalDaysByMonthAndYear(month, year);
        } else {
            totalDays = du_getDaysInCurrentMonth();
        }
        days = du_convertTotalDaysToArr(totalDays);
        let _day = day || currentDate.getDate();
        let months = du_getMonthSelectOption(controller.vm);
        let finalMonth = currentDate.getMonth() + 1;
        let _month =
            month ||
            months.find((e) => {
                return e.value === finalMonth;
            }).value;
        let years = [];
        for (let i = 1900; i <= currentDate.getFullYear() - registerAge; i++) {
            let obj = {};
            obj.label = i;
            obj.value = i;
            years.push(obj);
        }
        let _year = year || currentDate.getFullYear() - registerAge;

        controller.vm.setState({
            days: days,
            months: months,
            years: years,
            day: _day,
            month: _month,
            year: _year,
        });
    },

    onDayChanged(day) {
        controller.vm.setState(
            {
                day: day,
            },
            () => {
                const { onDateChanged } = controller.vm.props;
                if (onDateChanged) {
                    onDateChanged('day', 'dob', day);
                }
            }
        );
    },

    onMonthChanged(month) {
        const vm = controller.vm;
        let { day, days } = vm.state;
        let newTotalDays = du_getTotalDaysByMonthAndYear(month, vm.state.year);
        let newDay = du_massageDayByMonthOrYear(day, month, vm.state.year, days);
        days = du_convertTotalDaysToArr(newTotalDays);

        if (day !== newDay) {
            controller.onDayChanged(newDay);
        }

        vm.setState(
            {
                days,
                month,
            },
            () => {
                const { onDateChanged } = vm.props;
                if (onDateChanged) {
                    onDateChanged('month', 'dob', month);
                }
            }
        );
    },

    onYearChanged(year) {
        controller.vm.setState(
            {
                year: year,
            },
            () => {
                const { onDateChanged } = controller.vm.props;
                if (onDateChanged) {
                    onDateChanged('year', 'dob', year);
                }
                // handle leapYear case
                controller.onMonthChanged(controller.vm.state.month);
            }
        );
    },
};

export default controller;
