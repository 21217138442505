import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { LazyLoadImage } from 'react-lazy-load-image-component';
// core / logic
import { useValidatePasswordFormatValidity, useValidatePasswordSpecialCharsValidity, useValidateNumericPassword } from 'root/hook/password.hook';
import { psw_validatePasswordLengthValidity } from 'root/utils/password.util';

// components
import styles from './password-guideline.module.scss';

/**
 *
 * @param {Array} errors - errors
 * @param {Object} statusImage - configs for success/fail image names.
 * @returns
 */
const PasswordGuideLine = ({ password, customStatusImage = {}, ...props }) => {
    const { t } = useTranslation('password');
    const passwordRedux = useSelector((state) => state.password);
    const passwordConfig = passwordRedux?.processedData?.passwordConfig || {};

    const { minLength, maxLength, specialChars, uppercase } = passwordConfig;

    // NOTE: expect 'text-success', 'text-danger' (beta used only)
    const { isShowSpecialCharsGuide, additionalMsgWrapperClassName } = props;

    const statusConfig = {
        success: 'check-circle',
        fail: 'warning-circle',
        ...customStatusImage,
    };

    const alphabetGuidelineMessage = uppercase
        ? t('password:password.passguide.uppercaseAlphabet', 'Uppercase alphabet (A-Z)')
        : t('password:password.passguide.alphabet', 'Alphabet (a-z)');

    const numberGuidelineMessage = t('password:password.passguide.number', 'Number (0-9)');

    const specialCharsGuideMessage =
        specialChars?.allow || specialChars?.mandatory
            ? t('password:password.passguide.allowSpecialCharacter', 'Allowed special characters {{specialCharsFormat}}', { specialCharsFormat: specialChars?.format })
            : t('password:password.passguide.notAllowSpecialCharacter', 'Special character is not allowed');

    const isFormatValid = useValidatePasswordFormatValidity(password);
    const isSpecialCharCheckValid = useValidatePasswordSpecialCharsValidity(password);
    const isNumberValid = useValidateNumericPassword(password);
    return (
        <>
            <div className={`${styles.passwordRulesMsgWrapper} ${additionalMsgWrapperClassName || ''}`}>
                <PasswordStatusIcon type={psw_validatePasswordLengthValidity(password, minLength, maxLength) ? statusConfig?.success : statusConfig?.fail} />
                <PasswordGuideMessage message={t('password:password.passguide.minMaxLength', { min: minLength, max: maxLength }, '{{min}} to {{max}} characters')} />
            </div>

            <div className={`${styles.passwordRulesMsgWrapper} ${additionalMsgWrapperClassName || ''}`}>
                <PasswordStatusIcon type={isFormatValid ? statusConfig?.success : statusConfig?.fail} />
                <PasswordGuideMessage message={alphabetGuidelineMessage} />
            </div>

            <div className={`${styles.passwordRulesMsgWrapper} ${additionalMsgWrapperClassName || ''}`}>
                <PasswordStatusIcon type={isNumberValid ? statusConfig?.success : statusConfig?.fail} />
                <PasswordGuideMessage message={numberGuidelineMessage} />
            </div>

            {isShowSpecialCharsGuide && !isSpecialCharCheckValid && (
                <div className={`${styles.passwordRulesMsgWrapper} ${additionalMsgWrapperClassName || ''}`}>
                    <PasswordStatusIcon type={isSpecialCharCheckValid ? statusConfig?.success : statusConfig?.fail} />
                    <PasswordGuideMessage message={specialCharsGuideMessage} />
                </div>
            )}
        </>
    );
};
export default PasswordGuideLine;

export const PasswordStatusIcon = ({ type }) => {
    const _src = `/public/html/default_whitelabel/shared-image/icons/${type}.svg`;
    return <LazyLoadImage alt='' className={`${styles.statusIcon} password-rules-msg-status-img`} src={_src} />;
};

export const PasswordGuideMessage = ({ message }) => {
    return <p className={`${styles.passwordGuideMessage} fs-14 fw-400`}>{message}</p>;
};

// version 2 of passwordGuideLine, as six pin also need this format as well. Hence, need more flexible structure.
// temp copy quick version for handle six pin
export const ValidationCheckers = ({ validationCheckers, ...props }) => {
    const { additionalMsgWrapperClassName, customStatusImage } = props;

    const statusConfig = {
        success: 'check-circle',
        fail: 'warning-circle',
        ...customStatusImage,
    };

    return (
        <>
            {Object.entries(validationCheckers || []).map(([item, checker], index) => {
                const { status, message } = checker;
                return (
                    <div key={`${item}_${index}`} className={`${styles.passwordRulesMsgWrapper} ${additionalMsgWrapperClassName || ''}`}>
                        <PasswordStatusIcon type={status ? statusConfig.success : statusConfig.fail} />
                        <PasswordGuideMessage message={message} />
                    </div>
                );
            })}
        </>
    );
};
