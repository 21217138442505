import React from 'react';
import { connect } from 'react-redux';
import { mapStateToProps } from 'root/redux/selector/selector';
import { withRouter } from 'react-router-dom';
import { Translation, withTranslation } from 'react-i18next';
import SVGElements from 'root/components/SVGElements';

class Navbar extends React.Component {
    isMount = true;

    constructor(prop) {
        super(prop);
        this.state = {
            liveChatUrl: '',
        };
        this.mobileNavbarRedirect = this.mobileNavbarRedirect.bind(this);
    }

    componentDidMount() {
        this.isMount = true;
        if (this.props.screen.isMobile) {
            this.intervalId = setInterval(this.contactGet, 2000);
        }
    }

    componentWillUnmount() {
        this.isMount = false;
        clearInterval(this.intervalId);
    }

    contactGet = () => {
        const { language, screen } = this.props;
        window.SPL_Content.getContact(language.countryLanguageKey, screen.viewType).then((data) => {
            if (this.isMount) {
                if (data) {
                    for (let i = 0; i < data.length; i++) {
                        const contact = data[i];
                        if (contact.name === 'LiveChat') {
                            this.setState({ liveChatUrl: contact.desc });
                        }
                    }
                }
            }
        });
    };

    mobileNavbarRedirect = (key, path) => {
        if (key === 'live_chat') {
            this.contactOnClick(this.state.liveChatUrl);
        } else {
            this.props.history.push(path);
        }
    };

    contactOnClick = (url) => {
        window.open(url, '', 'width=500,height=700,toolbar=no,location=no,directories=no,status=yes,menubar=no,scrollbars=no');
    };

    render() {
        const navList = [
            {
                key: 'home',
                value: 'global:global.menu.home',
                path: '/home',
                param: '/home',
                name: 'home',
            },
            {
                key: 'funds',
                value: 'global:global.menu.account.funds',
                path: '/account/deposit',
                param: '/deposit',
                name: 'fund',
            },
            {
                key: 'rebate',
                value: 'global:global.sidebar.funds.rebate',
                path: '/account/rebate',
                param: '/rebate',
                name: 'rebate',
            },
            {
                key: 'my_account',
                value: 'settings:settings.header',
                path: '/myprofile/profile',
                param: '/myprofile',
                name: 'profile',
            },
            {
                key: 'live_chat',
                value: 'global:footer.livechat',
                name: 'live-chat',
            },
        ];
        return (
            this.props.screen.isMobile &&
            this.props.user.isLogin && (
                <div className='wap-navbar'>
                    <ul>
                        {navList.map(({ key, value, path, param, name }, i) => (
                            <li key={key} onClick={() => this.mobileNavbarRedirect(key, path)} className={this.props.location.pathname.includes(param) ? 'on' : ''}>
                                <span className='icon-box'>
                                    <SVGElements
                                        className={'icon-wap-' + key}
                                        name={this.props.location.pathname.includes(param) ? 'm-' + name + '-icon' + '-on' : 'm-' + name + '-icon'}
                                    />
                                    {/* <i className={`${["icon-wap-" + key, (this.props.location.pathname).includes(param) && "on",].join(" ")}`}></i> */}
                                </span>
                                <span>{this.props.t(value)}</span>
                            </li>
                        ))}
                    </ul>
                </div>
            )
        );
    }
}

export default connect(mapStateToProps)(withTranslation()(withRouter(Navbar)));
