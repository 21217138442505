import moment from 'moment';
import { floatingAction } from 'root/redux/action';

const controller = {
    vm: null,

    init(vm) {
        this.vm = vm;
        this.vm.state = {
            beforeLoginNotification: [],
            afterLoginNotification: [],
            afterLoginAPIrunCheck: false,
            floatingNotificationList: [],
            actual: [],
            notificationType: null,
            currentNotificationIndex: 0,
            testing: 'a',
            closedFloatingNotification: [],
            count: 0,
        };
    },

    initFloatingNotificationData(isDidmount) {
        const { user } = controller.vm.props;

        if (user.isLogin) {
            controller.getAfterLoginFloatingNotification(isDidmount);
        } else {
            controller.getBeforeLoginFloatingNotification(isDidmount);
        }
    },

    getAfterLoginFloatingNotification(isDidmount) {
        const { language, screen, user } = controller.vm.props;
        const { afterLoginNotification } = controller.vm.props.floatingNotification;
        const { afterLoginAPIrunCheck } = controller.vm.state;

        let contentType = screen.viewType;

        if (user.isLogin && user.account && !afterLoginAPIrunCheck) {
            window.SPL_Member.getAfterLoginFloatingNotificationData({
                language: language.countryLanguageKey,
                contentType: contentType.toUpperCase(),
                merchantCode: window.merchantCode,
                memberId: user.account.id,
            }).then((data) => {
                let afterLoginData = [];
                if (data && data.data) {
                    let afterLogin = data.data;
                    if (afterLogin.length > 0) {
                        afterLoginData = afterLogin;
                        controller.vm.props.dispatch(floatingAction.getAfterLoginNotification(afterLoginData));
                        controller.vm.setState({ afterLoginNotification: afterLoginData, afterLoginAPIrunCheck: true }, () => {
                            controller.getNotificationByTimer();
                            controller.addBackNotifications();
                        });
                    }
                }
            });
        } else {
            controller.vm.setState({ afterLoginNotification }, () => {
                controller.addBackNotifications();
                controller.getNotificationByTimer(isDidmount);
            });
        }
    },

    getBeforeLoginFloatingNotification(isDidmount) {
        const { user } = controller.vm.props;
        const { beforeLoginNotification } = controller.vm.props.floatingNotification;

        if (!user.isLogin && beforeLoginNotification && beforeLoginNotification.length < 1) {
            window.SPL_Content.getBeforeLoginFloatingNotificationData().then((data) => {
                if (data && data.length > 0) {
                    controller.vm.props.dispatch(floatingAction.getBeforeLoginNotification(data));
                    controller.vm.setState({ beforeLoginNotification: data }, () => {
                        controller.addBackNotifications();
                        controller.getNotificationByTimer(isDidmount);
                    });
                }
            });
        } else {
            controller.vm.setState({ beforeLoginNotification }, () => {
                controller.addBackNotifications();
                controller.getNotificationByTimer(isDidmount);
            });
        }
    },

    getNotificationByTimer(isDidmount) {
        const { beforeLoginNotification, afterLoginNotification } = controller.vm.state;
        const { location, user } = controller.vm.props;

        let getTheNotification = beforeLoginNotification;
        if (user.isLogin) {
            getTheNotification = afterLoginNotification;
        }

        let notificationType = null;
        let now = moment(Date.now());
        let start = '';
        let end = '';
        let currentRoute = location.pathname;
        let displayFrequencyType = '';
        let displayFrequency = '';

        for (let a = 0; a < getTheNotification.length; a++) {
            notificationType = getTheNotification[a].displayNotification;
            start = moment(getTheNotification[a].startDateTime);
            end = moment(getTheNotification[a].endDateTime);
            displayFrequencyType = getTheNotification[a].displayFrequencyType;
            displayFrequency = getTheNotification[a].displayFrequency;

            let dateDiff = end.diff(now, 'days');
            if (dateDiff > 23) {
                end = moment(now).add(23, 'd');
            }

            let dateDiff2 = start.diff(now, 'days');
            if (dateDiff2 > 23) {
                start = moment(now).add(23, 'd');
            }

            if (displayFrequencyType && displayFrequencyType !== 'ONCE' && displayFrequencyType !== 'ALWAYS' && displayFrequency) {
                if (displayFrequencyType === 'DAY') {
                    displayFrequencyType = 'd';
                } else if (displayFrequencyType === 'HOUR') {
                    displayFrequencyType = 'h';
                } else if (displayFrequencyType === 'WEEK') {
                    displayFrequencyType = 'w';
                }

                let renewalDate = moment(getTheNotification[a].startDateTime).add(displayFrequency, displayFrequencyType);
                while (now === renewalDate || now > renewalDate) {
                    renewalDate = renewalDate.add(displayFrequency, displayFrequencyType);
                }
                controller.getClosedNotificationData(getTheNotification[a].id, renewalDate, displayFrequency, displayFrequencyType);
            }

            let displayPage = getTheNotification[a].displayPage;
            if (currentRoute.includes(displayPage)) {
                let nowToStart = now - start;
                let nowToEnd = now - end;

                if (nowToStart > 0 && nowToEnd < 0) {
                    controller.addOneNotification(getTheNotification[a]);

                    let endToNow = end - now;

                    setTimeout(() => {
                        this.removeOneNotification(getTheNotification[a]);
                    }, endToNow);
                }

                if (nowToStart < 0 && nowToEnd < 0) {
                    let startToNow = start - now;
                    let endToNow = end - now;

                    setTimeout(() => {
                        controller.addOneNotification(getTheNotification[a]);
                    }, startToNow);

                    setTimeout(() => {
                        controller.removeOneNotification(getTheNotification[a]);
                    }, endToNow);
                }

                controller.vm.setState({ notificationType });
            } else {
                controller.removeOneNotification(getTheNotification[a]);
            }
        }

        let closedFloatingNotification = JSON.parse(window.SPL_Cookie.getCookie('closedFloatingNotification'));
        if (closedFloatingNotification !== null || closedFloatingNotification !== '') {
            controller.vm.setState({ closedFloatingNotification });
            if (isDidmount) {
                controller.vm.props.dispatch(floatingAction.getCloseNotification(closedFloatingNotification));
            }
        }
    },

    addBackNotifications() {
        const { beforeLoginNotification, afterLoginNotification, count } = controller.vm.state;
        const { user } = controller.vm.props;

        let getTheNotification = beforeLoginNotification;
        if (user.isLogin) {
            getTheNotification = afterLoginNotification;
        }

        let closedFloatingNotificationContent = JSON.parse(localStorage.getItem('closedFloatingNotificationContent'));
        let closedMain = JSON.parse(localStorage.getItem('closedFloatingNotification'));

        if (closedFloatingNotificationContent && count === 0) {
            let splitClosedFloatingNotification = closedFloatingNotificationContent.split(':');
            let splitMain = closedMain ? closedMain.split(',') : [];
            if (splitClosedFloatingNotification && splitClosedFloatingNotification.length > 0) {
                for (let a = 0; a < splitClosedFloatingNotification.length; a++) {
                    for (let i = 0; i < getTheNotification.length; i++) {
                        if (splitClosedFloatingNotification[a]) {
                            if (splitClosedFloatingNotification[a].includes('_ALWAYS')) {
                                splitClosedFloatingNotification.splice(a, 1);

                                let content = splitClosedFloatingNotification.length > 0 ? splitClosedFloatingNotification.join(':') : null;
                                localStorage.setItem('closedFloatingNotificationContent', JSON.stringify(content));
                            }

                            if (splitClosedFloatingNotification[a]) {
                                let contentSplit = splitClosedFloatingNotification[a].split('_');
                                let checkNotifID = getTheNotification.findIndex((j) => j.id === parseInt(contentSplit[1]));
                                if (checkNotifID < 0) {
                                    splitClosedFloatingNotification.splice(a, 1);
                                    let content = splitClosedFloatingNotification.length > 0 ? splitClosedFloatingNotification.join(':') : null;
                                    localStorage.setItem('closedFloatingNotificationContent', JSON.stringify(content));
                                }
                            }
                        }
                    }

                    if (splitMain && splitMain.length > 0) {
                        for (let j = 0; j < splitMain.length; j++) {
                            let contentSplit = splitMain[j].split('_');
                            let now = moment(Date.now());
                            let mainid = contentSplit[1];
                            let date = contentSplit[3];
                            let time = contentSplit[4];
                            let renewalDate = moment(date + ' ' + time);
                            if (now === renewalDate || (now > renewalDate && splitClosedFloatingNotification[a])) {
                                if (splitClosedFloatingNotification[a].includes('main_' + mainid)) {
                                    splitClosedFloatingNotification.splice(a, 1);

                                    let content = splitClosedFloatingNotification.length > 0 ? splitClosedFloatingNotification.join(':') : null;
                                    localStorage.setItem('closedFloatingNotificationContent', JSON.stringify(content));
                                }
                            }
                        }
                    }
                }
            }
        }

        controller.vm.setState({ count: 999 });
    },

    notificationTypeCheck() {
        const { notificationType, oneByOneNotification } = controller.vm.state;
        let getIndex = controller.vm.state.currentNotificationIndex;

        if (notificationType === 'ONE_BY_ONE' && oneByOneNotification && oneByOneNotification.length > 0) {
            let tempNotification = [];
            if (oneByOneNotification[getIndex]) {
                tempNotification.push(oneByOneNotification[getIndex]);
            }

            controller.vm.setState({ floatingNotificationList: tempNotification });
        }
    },

    closeNotification(notification, floatingNotificationSettingId, interactionType) {
        const { user } = controller.vm.props;

        let closedFloatingNotificationContent = JSON.parse(localStorage.getItem('closedFloatingNotificationContent'));
        if (closedFloatingNotificationContent) {
            let splitClosedFloatingNotification = closedFloatingNotificationContent.split(':');

            for (let a = 0; a < splitClosedFloatingNotification.length; a++) {
                if (!splitClosedFloatingNotification[a].includes('main_' + floatingNotificationSettingId + '_id_' + notification.id)) {
                    let newNotif = null;

                    if (interactionType === 'ALWAYS') {
                        newNotif = 'main_' + floatingNotificationSettingId + '_id_' + notification.id + '_ALWAYS';
                    } else {
                        newNotif = 'main_' + floatingNotificationSettingId + '_id_' + notification.id;
                    }

                    let arrayWithFilterObjects = splitClosedFloatingNotification.filter((o) => o === newNotif);
                    if (arrayWithFilterObjects.length < 1) {
                        splitClosedFloatingNotification.push(newNotif);
                    }

                    let content = splitClosedFloatingNotification.length > 0 ? splitClosedFloatingNotification.join(':') : null;

                    localStorage.setItem('closedFloatingNotificationContent', JSON.stringify(content));
                    controller.vm.props.dispatch(floatingAction.getCloseNotification(content));
                }
            }
        } else {
            let content = null;
            if (interactionType === 'ALWAYS') {
                content = 'main_' + floatingNotificationSettingId + '_id_' + notification.id + '_ALWAYS';
            } else {
                content = 'main_' + floatingNotificationSettingId + '_id_' + notification.id;
            }
            localStorage.setItem('closedFloatingNotificationContent', JSON.stringify(content));
            controller.vm.props.dispatch(floatingAction.getCloseNotification(content));
        }

        if (interactionType !== 'claimed') {
            controller.floatingNotificationInteraction(user, notification, 'NON_INTEREST');
        }
    },

    removeOneNotification(obj) {
        const { floatingNotificationList } = controller.vm.state;
        let objWithIdIndex = floatingNotificationList.findIndex((a) => a.id === obj.id);

        let tempFloatArr = floatingNotificationList;
        if (objWithIdIndex > -1) {
            tempFloatArr.splice(objWithIdIndex, 1);
            controller.vm.setState({ floatingNotificationList: tempFloatArr });
        }
    },

    addOneNotification(obj) {
        const { floatingNotificationList } = controller.vm.state;
        let objWithIdIndex = floatingNotificationList.findIndex((a) => a.id === obj.id);

        let tempFloatArr = floatingNotificationList;
        if (objWithIdIndex < 0) {
            tempFloatArr.push(obj);
            controller.vm.setState({ floatingNotificationList: tempFloatArr });
        }
    },

    filterNotificationContent(content, type) {
        const { language, screen } = controller.vm.props;
        let closedFloatingNotification = JSON.parse(localStorage.getItem('closedFloatingNotificationContent'));
        let contentType = screen.viewType;
        let filteredContent = [];

        let shortenContentArr = [];
        if (content && content.length > 0) {
            for (let i = 0; i < content.length; i++) {
                if (content[i].type === contentType.toUpperCase() && content[i].language === language.countryLanguageKey && (content[i].imagePath || content[i].content)) {
                    shortenContentArr.push(content[i]);
                }
            }
        }

        if (closedFloatingNotification) {
            let splitClosedFloatingNotification = closedFloatingNotification.split(':');
            if (splitClosedFloatingNotification.length > 0) {
                // for (let a = 0; a < splitClosedFloatingNotification.length; a++) {
                //     for (let j = 0; j < shortenContentArr.length; j++) {
                //         let contentSplit = splitClosedFloatingNotification[a].split('_');

                //         if (shortenContentArr[j].id === parseInt(contentSplit[3])) {
                //             shortenContentArr.splice(j, 1);
                //             filteredContent = shortenContentArr;
                //         } else {
                //             filteredContent.push(shortenContentArr[j]);
                //         }
                //     }
                // }

                filteredContent.push(...shortenContentArr.filter((obj) => !splitClosedFloatingNotification.some((str) => str.includes('_id_' + obj.id))));
            }
        } else {
            filteredContent = shortenContentArr;
        }

        if (type === 'ONE_BY_ONE') {
            let onebyone_content = [];
            if (filteredContent && filteredContent.length > 0) {
                onebyone_content.push(filteredContent[0]);
            }
            return onebyone_content;
        } else {
            return filteredContent;
        }
    },

    notificationClick(data, floatingNotificationSettingId) {
        const { language, user } = controller.vm.props;
        let pathLang = language.countryLanguageKey && language.countryLanguageKey.toLowerCase().replace('_', '-');

        if (data && data.redirectUrl && data.urlOpenIn) {
            if (data.urlOpenIn === 'CURRENT_PAGE') {
                let redirectLink = data.redirectUrl;
                let domainPath = window.location.protocol + '//' + window.location.hostname + '/' + pathLang;
                if (redirectLink.includes(domainPath)) {
                    redirectLink = redirectLink.replace(domainPath, '');
                }

                if (data.isAllowConversion && data.isAllowConversion === true) {
                    controller.vm.props.history.push(redirectLink);
                    controller.vm.props.dispatch(floatingAction.storeFloatingNotificationId(data.floatingNotificationId));
                } else {
                    controller.vm.props.history.push(redirectLink);
                }
            } else {
                if (data.isAllowConversion && data.isAllowConversion === true) {
                    window.open(data.redirectUrl + '?floatingNotificationId=' + data.floatingNotificationId, '_blank');
                } else {
                    window.open(data.redirectUrl, '_blank');
                }
            }
        }

        controller.floatingNotificationInteraction(user, data, 'INTEREST');
        controller.closeNotification(data, floatingNotificationSettingId, 'claimed');
    },

    floatingNotificationInteraction(user, data, type) {
        if (user.isLogin && user.account) {
            let params = {
                memberId: user.account.id,
                floatingNotificationId: data.floatingNotificationId,
                applicationType: type,
                merchantCode: window.merchantCode,
            };

            window.SPL_Member.floatingNotificationInteraction(params);
        }
    },

    getClosedNotificationData(id, renewalDate, displayFrequency, displayFrequencyType) {
        let date = renewalDate.format('YYYY-MM-DD').toString();
        let time = renewalDate.format('HH:mm:ss').toString();
        let closedFloatingNotification = JSON.parse(localStorage.getItem('closedFloatingNotification'));

        if (closedFloatingNotification) {
            let splitClosedFloatingNotification = closedFloatingNotification.split(',');
            for (let a = 0; a < splitClosedFloatingNotification.length; a++) {
                let contentSplit = splitClosedFloatingNotification[a].split('_');
                if (parseInt(contentSplit[1]) === id) {
                    let now = moment(Date.now());
                    let currentRenewal = moment(contentSplit[3] + ' ' + contentSplit[4]);
                    if (now === currentRenewal || now > currentRenewal) {
                        splitClosedFloatingNotification.splice(a, 1);
                        let newRenewal = currentRenewal.add(displayFrequency, displayFrequencyType);
                        let newdate = newRenewal.format('YYYY-MM-DD').toString();
                        let newtime = newRenewal.format('HH:mm:ss').toString();
                        splitClosedFloatingNotification.push('id_' + id + '_renewalDate_' + newdate + '_' + newtime);
                        let content = splitClosedFloatingNotification.length > 0 ? splitClosedFloatingNotification.join(',') : null;
                        localStorage.setItem('closedFloatingNotification', JSON.stringify(content));
                    }
                } else {
                    let newNotif = 'id_' + id + '_renewalDate_' + date + '_' + time;
                    let arrayWithFilterObjects = splitClosedFloatingNotification.filter((o) => o === newNotif);
                    if (arrayWithFilterObjects.length < 1) {
                        splitClosedFloatingNotification.push(newNotif);
                    }
                    let content = splitClosedFloatingNotification.length > 0 ? splitClosedFloatingNotification.join(',') : null;
                    localStorage.setItem('closedFloatingNotification', JSON.stringify(content));
                }
            }
        } else {
            let content = 'id_' + id + '_renewalDate_' + date + '_' + time;
            localStorage.setItem('closedFloatingNotification', JSON.stringify(content));
        }
    },
};

export default controller;
