import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { mapStateToProps } from '../../redux/selector/selector';
class Clock extends React.Component {
    constructor(props) {
        super(props);

        this.state = { date: new Date(), timeZone: 'GMT +8' };
    }

    componentDidMount() {
        this.timer = setInterval(() => this.tick(), 1000);

        const timeZone = window.SPL_Other.getTimeZoneBycountryLanguageKey(this.props.language.countryLanguageKey);
        this.setState({ timeZone });
    }

    componentWillUnmount() {
        clearInterval(this.timer);
    }

    tick() {
        this.setState({ date: new Date() });
    }

    getDateStr() {
        const date = window.SPL_Other.getTimeNowByCountryLanguageKey(this.props.language.countryLanguageKey);
        return moment(date.fullDateTime).format('DD/MM/YYYY hh:mm:ss');
    }

    render() {
        const { timeZone } = this.state;
        return <span className='date-time'>{this.getDateStr() + ' ' + timeZone}</span>;
    }
}

export default connect(mapStateToProps)(Clock);
