import moment from 'moment';
import React, { Component } from 'react';
import { Translation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Nav, NavItem, NavLink } from 'reactstrap';
import { Col, Row } from 'react-bootstrap';
import 'react-datepicker/dist/react-datepicker.css';
import { mapStateToProps } from '../../redux/selector/selector';
import { withRouter } from 'react-router-dom';
import HistoryModal from './152_HistoryModal';
import HistoryDetailsModal from './153_HistoryDetailsModal';
import { FiArrowDownRight } from 'react-icons/fi';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
/* const TabPane = Tabs.TabPane; */

// This component shows the items user checked out from the cart.
class History extends Component {
  historyCategories = [
    { id: 0, name: this.props.t('settings:settings.history.transfer'), transType: 'transfer' },
    { id: 1, name: this.props.t('settings:settings.history.withdrawalDeposit'), transType: 'depositWithdraw' },
    { id: 2, name: this.props.t('settings:settings.history.promotionApply'), transType: 'promotion' },
    { id: 3, name: this.props.t('settings:settings.history.rebateCashback'), transType: 'rebate' },
    { id: 4, name: this.props.t('settings:settings.history.rewards'), transType: 'rewards' },
  ];
  historyTime = [
    { id: 0, name: this.props.t('settings:settings.history.today'), transType: 'today' },
    { id: 1, name: this.props.t('settings:settings.history.in3days'), transType: 'threedays' },
    { id: 2, name: this.props.t('settings:settings.history.inaweek'), transType: 'weekly' },
    { id: 3, name: this.props.t('settings:settings.history.inamonth'), transType: 'monthly' },
  ]
  isMount = true;

  constructor(props) {
    super(props);
    let currentDate = new Date();

    this.toggle = this.toggle.bind(this);
    this.state = {
      filter: {
        startDate: new Date(new Date().setMonth(currentDate.getMonth() - 1)),
        endDate: currentDate,
        activeTab: 0,
        transType: 'transfer',
        transactionType: [{ value: '', desc: this.props.t('transaction:transaction.history.filter.all') }],
        status: [{ value: '', desc: this.props.t('transaction:transaction.history.filter.all') }],
      },
      histories: [],
      transTypeList: [
        { value: '', desc: this.props.t('transaction:transaction.history.filter.all') },
        { value: 'D', desc: this.props.t('transaction:transaction.trans_type.deposit') },
        { value: 'W', desc: this.props.t('transaction:transaction.trans_type.withdrawal') },
      ],
      statusList: [
        { value: '', desc: this.props.t('transaction:transaction.history.filter.all') },
        { value: 'P', desc: this.props.t('transaction:transaction.status.pending') },
        { value: 'A', desc: this.props.t('transaction:transaction.status.accepted') },
        { value: 'R', desc: this.props.t('transaction:transaction.status.rejected') },
        { value: 'I', desc: this.props.t('transaction:transaction.status.inProgress') },
      ],
      activeTab: 0,
      selectedFilterType: [{ id: 0, name: 'Transfer', transType: 'transfer' }],
      currentPage: 1,
      currentPage1: 1,
      currentPage2: 1,
      currentPage3: 1,
      currentPage4: 1,
      todosPerPage: 5,
      todosPerPage1: 5,
      todosPerPage2: 5,
      todosPerPage3: 5,
      todosPerPage4: 5,
      language: '',
    };

    this.controls = {};
    for (var tab of props.config || []) {
      for (var ctrl of tab.controls || []) {
        this.controls[tab.title + '/' + ctrl.name] = React.createRef();
      }
    }
  }
  state = {
    visible: false,
  };

  handleShow = () => {
    this.setState({ visible: true });
  };

  handleHide = () => {
    this.setState({ visible: false });
  };

  componentDidMount() {
    this.isMount = true;
    this.loadHistories();
    this.checkCountryLanguage();

    if (this.props.screen.viewType === 'web' && this.props.location.pathname === '/history') {
      this.props.history.push('/myaccount/report');
    }

  }

  componentWillUnmount() {
    this.isMount = false;
  }

  checkCountryLanguage = () => {
    if (this.props.language.countryLanguageKey === "TH_TH") {
      this.setState({ language: 'th' });
    } else if (this.props.language.countryLanguageKey === "ZH_MY") {
      this.setState({ language: 'zhCN' });
    } else {
      this.setState({ language: '' });
    }
  }

  loadHistories = () => {
    const { startDate, endDate, transactionType, status } = this.state.filter;
    let params = {
      start: new Date(new Date(startDate).setHours(0, 0, 0)),
      end: new Date(new Date(endDate).setHours(23, 59, 59)),
      type: transactionType.length > 0 ? transactionType[0].value : null,
      status: status.length > 0 ? status[0].value : null,
    };
    window.SPL_Transaction.loadHistories(this.state.filter.transType, params).then((histories) => {
      if (this.isMount) {
        this.setState({ histories: histories.histories });
      }
    });
  };

  onTabClick(index) {
    let transType = this.historyCategories[index].transType;
    this.selectedFilterType = [this.historyCategories[index]];
    this.setState(
      {
        filter: { ...this.state.filter, transType: transType },
        histories: [],
        selectedFilterType: [this.historyCategories[index]],
        activeTab: index,
      },
      () => {
        this.loadHistories();
      }
    );
    this.setState({
      currentPage: 1,
      currentPage1: 1,
      currentPage2: 1,
      currentPage3: 1,
      currentPage4: 1,
    });
  }
  // handleTabClick = event => {
  // 	this.setState({ activeTab: event.target.id });
  // };

  onSelectCategory = (value) => {
    this.setState({ selectedFilterType: value });
  };

  applyOnClick = () => {
    this.setState({ filter: { ...this.state.filter, transType: this.state.selectedFilterType[0].transType } }, () => {
      this.loadHistories();
    });
  };

  onDateChanged = (date, type) => {
    if (type === 'start') {
      this.setState({ filter: { ...this.state.filter, startDate: date } });
    } else {
      this.setState({ filter: { ...this.state.filter, endDate: date } });
    }
  };

  onTransTypeChange = (value) => {
    this.setState({ filter: { ...this.state.filter, transactionType: value } });
  };

  onStatusChange = (value) => {
    this.setState({ filter: { ...this.state.filter, status: value } });
  };

  getHistoryTemplate(type, historyObj) {
    const { t } = this.props;
    let disPlayItem = [];
    if (type === 'transfer') {
      disPlayItem = [
        {
          label: t('history:history.id'),
          detail: t(historyObj.id),
        },
        {
          label: t('history:history.to'),
          detail: t(historyObj.to),
        },
        {
          label: t('history:history.from'),
          detail: t(historyObj.from),
        },
        {
          label: t('history:history.promo_code'),
          detail: t(historyObj.promoCode),
        },
        {
          label: t('history:history.amount'),
          detail: window.SPL_Other.formatAmount(historyObj.amoun),
        },
        {
          label: t('history:history.datatime'),
          detail: moment(historyObj.createdDate).format('YYYY-MM-DD HH:mm').toString(),
        },
        {
          label: t('history:history.status'),
          detail: t(historyObj.result),
        },
      ];

      return disPlayItem;
    } else if (type === 'depositWithdraw') {
      disPlayItem = [
        {
          label: t('history:history.method'),
          detail: t(historyObj.method),
        },
        {
          label: t('history:history.id'),
          detail: t(historyObj.id),
        },
        {
          label: t('history:history.amount'),
          detail: window.SPL_Other.formatAmount(historyObj.amount),
        },
        {
          label: t('transaction:transaction.deposit.deposithistory.status'),
          detail: t(historyObj.status),
        },
        {
          label: t('history:history.datatime'),
          detail: moment(historyObj.createdDate).format('YYYY-MM-DD HH:mm').toString(),
        },
      ];

      return disPlayItem;
    } else if (type === 'promotion') {
      disPlayItem = [
        {
          label: t('history:history.id'),
          detail: t(historyObj.id),
        },
        {
          label: t('history:history.promo_code'),
          detail: t(historyObj.promoCode),
        },
        {
          label: t('history:history.amount'),
          detail: window.SPL_Other.formatAmount(Math.abs(historyObj.targetAmount)),
        },
        {
          label: t('history:history.target_achieved'),
          detail: window.SPL_Other.formatAmount(historyObj.targetAchieved),
        },
        {
          label: t('transaction:transaction.deposit.deposithistory.status'),
          detail: t(historyObj.status),
        },
        {
          label: t('history:history.datatime'),
          detail: moment(historyObj.createdDate).format('YYYY-MM-DD HH:mm').toString(),
        },
      ];

      return disPlayItem;
    } else if (type === 'rebate') {
      disPlayItem = [
        {
          label: t('history:history.id'),
          detail: t(historyObj.id),
        },
        {
          label: t('transaction:transaction.history.provider'),
          detail: t(historyObj.provider),
        },
        {
          label: t('history:history.amount'),
          detail: window.SPL_Other.formatAmount(historyObj.amount),
        },
        {
          label: t('transaction:transaction.history.totalValidTurnOver'),
          detail: window.SPL_Other.formatAmount(historyObj.totalValidTurnOver),
        },
        {
          label: t('transaction:transaction.history.totalPromoTurnOver'),
          detail: window.SPL_Other.formatAmount(historyObj.totalPromoTurnOver),
        },
        {
          label: t('history:history.totalEligibleTurnOver'),
          detail: window.SPL_Other.formatAmount(historyObj.totalEligibleTurnOver),
        },
        {
          label: t('transaction:transaction.history.transactionDate'),
          detail: moment(historyObj.createdDate).format('YYYY-MM-DD HH:mm').toString(),
        },
      ];

      return disPlayItem;
    } else if (type === 'rewards') {
      disPlayItem = [
        {
          label: t('history:history.id'),
          detail: t(historyObj.id),
        },
        {
          label: t('transaction:transaction.history.rewardName'),
          detail: t(historyObj.name),
        },
        {
          label: t('transaction:transaction.history.rewardType'),
          detail: historyObj.rewardType,
        },
        {
          label: t('history:history.amount'),
          detail: historyObj.rewardType === 'FREE_SPIN' ? historyObj.noOfFreeSpin : window.SPL_Other.formatAmount(historyObj.amount),
          currency: historyObj.currency,
          rewardType: historyObj.rewardType,
        },
        {
          label: t('transaction:transaction.deposit.deposithistory.status'),
          detail: t(historyObj.status),
        },
        {
          label: t('transaction:transaction.history.transactionDate'),
          detail: moment(historyObj.createdDate).format('YYYY-MM-DD HH:mm').toString(),
        },
      ];

      return disPlayItem;
    }
  }

  showDetails = (type, history) => {
    this.setState({ type: type, historySelected: history, isOpenHistoryDetailsModal: true });
  };

  closeDetailsModal = () => {
    this.setState({ isOpenHistoryDetailsModal: false });
  };

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  handleClick = (event) => {
    this.setState({
      currentPage: Number(event.target.id),
      currentPage1: Number(event.target.id),
      currentPage2: Number(event.target.id),
      currentPage3: Number(event.target.id),
      currentPage4: Number(event.target.id),
    });
  };

  backwards = () => {
    let check = this.state.currentPage;
    if (check > 1 && check !== 1) {
      this.setState({
        currentPage: check - 1,
        currentPage1: check - 1,
        currentPage2: check - 1,
        currentPage3: check - 1,
        currentPage4: check - 1,
      });
    }
  };

  forwards = (e) => {
    let check = this.state.currentPage;
    if (e > check && e !== check) {
      this.setState({
        currentPage: check + 1,
        currentPage1: check + 1,
        currentPage2: check + 1,
        currentPage3: check + 1,
        currentPage4: check + 1,
      });
    }
  };

  render() {
    return (
      <Translation>
        {(t) => (
          <div className='app-history content'>
            <div className='d-none d-md-block history-bg'>
              <div >


                <div>
                  <div className='history-upper'>
                    <div className='d-flex'>
                      <div className='history-left-form'>
                        <div className='history-form-label'>
                          <label htmlFor='TransferFrom' className='form-label'>
                            {t('transaction:transaction.history.filter.trans_type_no_colon')}
                            <span className='asterisk'>*</span>
                          </label>
                        </div>

                      </div>
                      <div className='history-right-form'>
                        <Nav tabs className='history-tab'>
                          {this.historyCategories.map((historytab, index) => {
                            return (
                              <NavItem key={index} className='history-tab-click '>
                                <NavLink
                                  className={`${
                                    historytab.transType === this.state.selectedFilterType[0].transType
                                      ? 'history-header navlinkForm active'
                                      : 'navlinkForm'
                                    } `}
                                  onClick={() => {
                                    this.onTabClick(index);
                                  }}>
                                  {t(historytab.name)}
                                </NavLink>
                              </NavItem>
                            );
                          })}
                        </Nav>
                      </div>
                    </div>

                    <div className='d-flex'>
                      <div className='history-left-form'>
                        <div className='history-form-label'>
                          <label htmlFor='TransferFrom' className='form-label'>
                            {t('transaction:transaction.history.filter.trans_type_no_colon')}
                            <span className='asterisk'>*</span>
                          </label>
                        </div>

                      </div>
                      <div className='history-right-form'>
                        <Nav tabs className='history-tab justify-content-start'>
                          {this.historyTime.map((historytab, index) => {
                            return (
                              <NavItem key={index} className='history-tab-click '>
                                <NavLink
                                  className={`${
                                    historytab.transType === this.state.selectedFilterType[0].transType
                                      ? 'history-header navlinkForm active'
                                      : 'navlinkForm'
                                    } `}
                                  onClick={() => {
                                    this.onTabClick(index);
                                  }}>
                                  {t(historytab.name)}
                                </NavLink>
                              </NavItem>
                            );
                          })}
                        </Nav>
                      </div>


                    </div>
                    <HistoryModal
                      filter={this.state.filter}
                      selectedFilterType={this.state.selectedFilterType}
                      buttonClass={'btn-link position-absolute'}
                      applyOnClick={this.applyOnClick}
                      categories={this.historyCategories}
                      onSelectCategory={this.onSelectCategory}
                      onDateChanged={this.onDateChanged}
                      transType={this.state.filter.transType}
                      transTypeList={this.state.transTypeList}
                      statusList={this.state.statusList}
                      transactionType={this.state.filter.transactionType}
                      status={this.state.filter.status}
                      onTransTypeChange={this.onTransTypeChange}
                      onStatusChange={this.onStatusChange}
                      language={this.state.language}></HistoryModal>

                  </div>



                  <Row className=''>
                    {(() => {
                      switch (this.state.filter.transType) {
                        case 'transfer':
                          let { currentPage, todosPerPage } = this.state;
                          let indexOfLastTodo = currentPage * todosPerPage;
                          let indexOfFirstTodo = indexOfLastTodo - todosPerPage;
                          let currentTodos = this.state.histories.slice(indexOfFirstTodo, indexOfLastTodo);
                          let renderTodos = currentTodos.map((hist, index) => {
                            return (
                              <Row
                                key={index}
                                className='w-100 d-flex history-detail-row'
                                onClick={() => {
                                  this.showDetails('transfer', hist);
                                }}>
                                <Col className='d-flex justify-content-center align-items-center'>
                                  <FiArrowDownRight className='icon-xs arrow-collapse'></FiArrowDownRight>
                                </Col>
                                <Col xs='5' sm='5' className='p-l-0'>
                                  <div className='table-title'>{t(hist.to)}</div>
                                  <div className='table-date'>{moment(hist.createdDate).format('YYYY-MM-DD HH:mm').toString()}</div>
                                </Col>
                                <Col xs='4' sm='4' className='p-l-0 p-r-0'>
                                  <div className='no-wrap h5'>{`${hist.currency} ${window.SPL_Other.formatAmount(hist.amount)}`}</div>
                                </Col>
                                <Col xs='2' sm='2' className='h5  p-l-0 p-r-0 d-flex justify-content-end '>
                                  <div className={hist.resultClass}>{t(hist.result)}</div>
                                </Col>
                              </Row>
                            );
                          });
                          let pageNumbers = [];
                          for (let i = 1; i <= Math.ceil(this.state.histories.length / todosPerPage); i++) {
                            pageNumbers.push(i);
                          }

                          let renderPageNumbers = pageNumbers.map((number) => {
                            return (
                              <li key={number} id={number} onClick={this.handleClick} className='pagination-number'>
                                {number}
                              </li>
                            );
                          });
                          if (this.state.histories.length > 0) {
                            return (
                              <Row className='w-100 history-detail'>
                                <Col xs='12' className=' history-detail-item'>
                                  {renderTodos}
                                </Col>
                                <Col xs='12' className='p-0'>
                                  <ul id='page-numbers ' className='page-numbers d-flex justify-content-center'>
                                    <li onClick={() => this.backwards()} className='p-10 page-arrow-left'>
                                      <IoIosArrowBack className='icon-xs account-icon' />
                                    </li>
                                    {renderPageNumbers}
                                    <li onClick={() => this.forwards(pageNumbers.length)} className='p-10 page-arrow-right'>
                                      <IoIosArrowForward className='icon-xs account-icon' />
                                    </li>
                                  </ul>
                                </Col>
                              </Row>
                            );
                          } else {
                            return <Row className='no-Data'>{t('transaction:transaction.emptyHistory')}</Row>;
                          }

                        case 'depositWithdraw':
                          let { currentPage1, todosPerPage1 } = this.state;
                          let indexOfLastTodo1 = currentPage1 * todosPerPage1;
                          let indexOfFirstTodo1 = indexOfLastTodo1 - todosPerPage1;
                          let currentTodos1 = this.state.histories.slice(indexOfFirstTodo1, indexOfLastTodo1);

                          let renderTodos1 = currentTodos1.map((hist, index) => {
                            return (
                              <Row
                                className='d-flex  history-detail-row'
                                key={(hist, index)}
                                onClick={() => {
                                  this.showDetails('depositWithdraw', hist);
                                }}>
                                <Col className='d-flex justify-content-center align-items-center'>
                                  <FiArrowDownRight className='icon-xs arrow-collapse'></FiArrowDownRight>
                                </Col>

                                <Col xs='5' sm='5' className='p-l-0'>
                                  <div className='table-title'>{t(hist.transType)}</div>
                                  <div className='table-date'>{moment(hist.date).format('YYYY-MM-DD HH:mm').toString()}</div>
                                </Col>
                                <Col xs='4' sm='4' className='p-l-0 p-r-0'>
                                  <div className='no-wrap h5'>{`${hist.currency} ${window.SPL_Other.formatAmount(hist.amount)}`}</div>
                                </Col>
                                <Col xs='2' sm='2' className='h5  p-l-0 p-r-0 d-flex justify-content-end '>
                                  <div className={hist.resultClass}>{t(hist.status)}</div>
                                </Col>
                              </Row>
                            );
                          });
                          let pageNumbers1 = [];
                          for (let i = 1; i <= Math.ceil(this.state.histories.length / todosPerPage1); i++) {
                            pageNumbers1.push(i);
                          }

                          let renderPageNumbers1 = pageNumbers1.map((number) => {
                            return (
                              <li key={number} id={number} onClick={this.handleClick} className='pagination-number'>
                                {number}
                              </li>
                            );
                          });
                          if (this.state.histories.length > 0) {
                            return (
                              <Row className='w-100 history-detail'>
                                <Col xs='12' className='history-detail-item'>
                                  {renderTodos1}
                                </Col>
                                <Col xs='12' className='p-0'>
                                  <ul id='page-numbers ' className='page-numbers d-flex justify-content-center'>
                                    <li onClick={() => this.backwards()} className='p-10 page-arrow-left'>
                                      <IoIosArrowBack className='icon-xs account-icon' />
                                    </li>
                                    {renderPageNumbers1}
                                    <li onClick={() => this.forwards(pageNumbers1.length)} className='p-10 page-arrow-right'>
                                      <IoIosArrowForward className='icon-xs account-icon' />
                                    </li>
                                  </ul>
                                </Col>
                              </Row>
                            );
                          } else {
                            return <Row className='no-Data'>{t('transaction:transaction.emptyHistory')}</Row>;
                          }

                        case 'promotion':
                          let { currentPage2, todosPerPage2 } = this.state;
                          let indexOfLastTodo2 = currentPage2 * todosPerPage2;
                          let indexOfFirstTodo2 = indexOfLastTodo2 - todosPerPage2;
                          let currentTodos2 = this.state.histories.slice(indexOfFirstTodo2, indexOfLastTodo2);
                          let renderTodos2 = currentTodos2.map((hist, index) => {
                            return (
                              <Row
                                key={index}
                                className='w-100 d-flex history-detail-row'
                                onClick={() => {
                                  this.showDetails('promotion', hist);
                                }}>
                                <Col className='d-flex p-l-0 p-r-0 icon-light'>
                                  <FiArrowDownRight className='icon-xs arrow-collapse'></FiArrowDownRight>
                                </Col>

                                <Col xs='5' sm='5' className='p-l-0'>
                                  <div className='table-title'>{t(hist.promoName)}</div>
                                  <div className='table-date'>{moment(hist.createdDate).format('YYYY-MM-DD HH:mm').toString()}</div>
                                </Col>

                                <Col xs='4' sm='4' className='p-l-0 p-r-0'></Col>
                                <Col xs='2' sm='2' className='h5 p-l-0 p-r-0 d-flex justify-content-end'></Col>
                              </Row>
                            );
                          });
                          let pageNumbers2 = [];
                          for (let i = 1; i <= Math.ceil(this.state.histories.length / todosPerPage2); i++) {
                            pageNumbers2.push(i);
                          }

                          let renderPageNumbers2 = pageNumbers2.map((number) => {
                            return (
                              <li key={number} id={number} onClick={this.handleClick} className='pagination-number'>
                                {number}
                              </li>
                            );
                          });
                          if (this.state.histories.length > 0) {
                            return (
                              <Row className='w-100'>
                                <Col xs='12' className='p-0 history-detail-item'>
                                  {renderTodos2}
                                </Col>
                                <Col xs='12' className='p-0'>
                                  <ul id='page-numbers' className='d-flex justify-content-center'>
                                    <li onClick={() => this.backwards()} className='p-10 page-arrow-left'>
                                      <IoIosArrowBack className='icon-xs account-icon' />
                                    </li>
                                    {renderPageNumbers2}
                                    <li onClick={() => this.forwards(pageNumbers2.length)} className='p-10 page-arrow-right'>
                                      <IoIosArrowForward className='icon-xs account-icon' />
                                    </li>
                                  </ul>
                                </Col>
                              </Row>
                            );
                          } else {
                            return (
                              <Row className='w-100'>
                                <Col xs='12' className='no-Data'>
                                  {t('transaction:transaction.emptyHistory')}
                                </Col>
                              </Row>
                            );
                          }
                        case 'rebate':
                          let { currentPage3, todosPerPage3 } = this.state;
                          let indexOfLastTodo3 = currentPage3 * todosPerPage3;
                          let indexOfFirstTodo3 = indexOfLastTodo3 - todosPerPage3;
                          let currentTodos3 = this.state.histories.slice(indexOfFirstTodo3, indexOfLastTodo3);
                          let renderTodos3 = currentTodos3.map((hist, index) => {
                            return (
                              <Row
                                key={index}
                                className='w-100 d-flex history-detail-row'
                                onClick={() => {
                                  this.showDetails('rebate', hist);
                                }}>
                                <Col className='d-flex justify-content-center align-items-center'>
                                  <FiArrowDownRight className='icon-xs arrow-collapse'></FiArrowDownRight>
                                </Col>

                                <Col xs='5' sm='5' className='p-l-0'>
                                  <div className='table-title'>{t(hist.provider)}</div>
                                  <div className='table-date'>{moment(hist.createdDate).format('YYYY-MM-DD HH:mm').toString()}</div>
                                </Col>
                                <Col xs='4' sm='4' className='p-l-0 p-r-0'>
                                  <div className='no-wrap h5'>{`${hist.currency} ${window.SPL_Other.formatAmount(hist.amount)}`}</div>
                                </Col>
                                <Col xs='2' sm='2' className='h5 p-l-0 p-r-0 d-flex justify-content-end'></Col>
                              </Row>
                            );
                          });
                          let pageNumbers3 = [];
                          for (let i = 1; i <= Math.ceil(this.state.histories.length / todosPerPage3); i++) {
                            pageNumbers3.push(i);
                          }

                          let renderPageNumbers3 = pageNumbers3.map((number) => {
                            return (
                              <li key={number} id={number} onClick={this.handleClick} className='pagination-number'>
                                {number}
                              </li>
                            );
                          });
                          if (this.state.histories.length > 0) {
                            return (
                              <Row className='w-100 history-detail'>
                                <Col xs='12' className='history-detail-item'>
                                  {renderTodos3}
                                </Col>
                                <Col xs='12' className='p-0'>
                                  <ul id='page-numbers' className='page-numbers d-flex justify-content-center'>
                                    <li onClick={() => this.backwards()} className='p-10 page-arrow-left'>
                                      <IoIosArrowBack className='icon-xs account-icon' />
                                    </li>
                                    {renderPageNumbers3}
                                    <li onClick={() => this.forwards(pageNumbers3.length)} className='p-10 page-arrow-right'>
                                      <IoIosArrowForward className='icon-xs account-icon' />
                                    </li>
                                  </ul>
                                </Col>
                              </Row>
                            );
                          } else {
                            return (
                              <Row className='w-100'>
                                <Col xs='12' className='no-Data'>
                                  {t('transaction:transaction.emptyHistory')}
                                </Col>
                              </Row>
                            );
                          }

                        case 'rewards':
                          let { currentPage4, todosPerPage4 } = this.state;
                          let indexOfLastTodo4 = currentPage4 * todosPerPage4;
                          let indexOfFirstTodo4 = indexOfLastTodo4 - todosPerPage4;
                          let currentTodos4 = this.state.histories.slice(indexOfFirstTodo4, indexOfLastTodo4);
                          let renderTodos4 = currentTodos4.map((hist, index) => {
                            return (
                              <Row
                                key={index}
                                className='d-flex history-detail-row'
                                onClick={() => {
                                  this.showDetails('rewards', hist);
                                }}>
                                <Col className='d-flex justify-content-center align-items-center'>
                                  <FiArrowDownRight className='icon-xs arrow-collapse'></FiArrowDownRight>
                                </Col>

                                <Col xs='5' sm='5' className='p-l-0'>
                                  <div className='table-title'>{t(hist.rewardType)}</div>
                                  <div className='table-date'>{moment(hist.createdDate).format('YYYY-MM-DD HH:mm').toString()}</div>
                                </Col>
                                <Col xs='4' sm='4' className='p-l-0 p-r-0'></Col>
                                <Col xs='2' sm='2' className='h5  p-l-0 p-r-0 d-flex justify-content-end'>
                                  <div className={hist.resultClass}>{t(hist.status)}</div>
                                </Col>
                              </Row>
                            );
                          });
                          let pageNumbers4 = [];
                          for (let i = 1; i <= Math.ceil(this.state.histories.length / todosPerPage4); i++) {
                            pageNumbers4.push(i);
                          }

                          let renderPageNumbers4 = pageNumbers4.map((number) => {
                            return (
                              <li key={number} id={number} onClick={this.handleClick} className='pagination-number'>
                                {number}
                              </li>
                            );
                          });
                          if (this.state.histories.length > 0) {
                            return (
                              <Row className='w-100 history-detail '>
                                <Col xs='12' className='history-detail-item'>
                                  {renderTodos4}
                                </Col>
                                <Col xs='12' className='p-0'>
                                  <ul id='page-numbers' className='page-numbers d-flex justify-content-center'>
                                    <li onClick={() => this.backwards()} className='p-10 page-arrow-left'>
                                      <IoIosArrowBack className='icon-xs account-icon' />
                                    </li>
                                    {renderPageNumbers4}
                                    <li onClick={() => this.forwards(pageNumbers4.length)} className='p-10 page-arrow-right'>
                                      <IoIosArrowForward className='icon-xs account-icon' />
                                    </li>
                                  </ul>
                                </Col>
                              </Row>
                            );
                          } else {
                            return (
                              <Row className='w-100'>
                                <Col xs='12' className='no-Data'>
                                  {t('transaction:transaction.emptyHistory')}
                                </Col>
                              </Row>
                            );
                          }
                        default:
                          return <Row className='no-Data'>{t('transaction:transaction.emptyHistory')}</Row>;
                          break;
                      }
                    })()}
                  </Row>
                </div>
              </div>


            </div>

            <div className='content-mobile-account d-block d-md-none'>
              <div>
                {' '}
                <HistoryDetailsModal
                  t={t}
                  isOpen={this.state.isOpenHistoryDetailsModal}
                  closeModal={this.closeDetailsModal}
                  historyItems={this.getHistoryTemplate(this.state.type, this.state.historySelected)}></HistoryDetailsModal>
                <HistoryModal
                  filter={this.state.filter}
                  selectedFilterType={this.state.selectedFilterType}
                  buttonClass={'btn-link position-absolute'}
                  applyOnClick={this.applyOnClick}
                  categories={this.historyCategories}
                  onSelectCategory={this.onSelectCategory}
                  onDateChanged={this.onDateChanged}
                  transType={this.state.filter.transType}
                  transTypeList={this.state.transTypeList}
                  statusList={this.state.statusList}
                  transactionType={this.state.filter.transactionType}
                  status={this.state.filter.status}
                  onTransTypeChange={this.onTransTypeChange}
                  onStatusChange={this.onStatusChange}
                  language={this.state.language}></HistoryModal>
              </div>

              <div>
                <div>
                  <Nav tabs className='history-table-header'>
                    {this.historyCategories.map((historytab, index) => {
                      return (
                        <NavItem key={index}>
                          <NavLink
                            className={`${
                              historytab.transType === this.state.selectedFilterType[0].transType
                                ? 'history-header navlinkForm active'
                                : 'navlinkForm'
                              } `}
                            onClick={() => {
                              this.onTabClick(index);
                            }}>
                            {t(historytab.name)}
                          </NavLink>
                        </NavItem>
                      );
                    })}
                  </Nav>

                  <Row className='history-detail'>
                    {(() => {
                      switch (this.state.filter.transType) {
                        case 'transfer':
                          let { currentPage, todosPerPage } = this.state;
                          let indexOfLastTodo = currentPage * todosPerPage;
                          let indexOfFirstTodo = indexOfLastTodo - todosPerPage;
                          let currentTodos = this.state.histories.slice(indexOfFirstTodo, indexOfLastTodo);
                          let renderTodos = currentTodos.map((hist, index) => {
                            return (
                              <Row
                                key={index}
                                className='w-100 d-flex history-detail-row '
                                onClick={() => {
                                  this.showDetails('transfer', hist);
                                }}>
                                <Col className='d-flex justify-content-center align-items-center p-l-0 p-r-0'>
                                  <FiArrowDownRight className='icon-sm arrow-collapse'></FiArrowDownRight>
                                </Col>
                                <Col xs='5' sm='5' className='p-l-0 p-r-0 history-table'>
                                  <div className='table-title'>{t(hist.to)}</div>
                                  <div className='table-date'>{moment(hist.createdDate).format('YYYY-MM-DD HH:mm').toString()}</div>
                                </Col>
                                <Col xs='4' sm='4' className='p-l-0 p-r-0 d-flex align-items-center'>
                                  <div className='no-wrap h5'>{`${hist.currency} ${window.SPL_Other.formatAmount(hist.amount)}`}</div>
                                </Col>
                                <Col xs='2' sm='2' className='h5  p-l-0 p-r-0 d-flex align-items-center '>
                                  <div className={hist.resultClass}>{t(hist.result)}</div>
                                </Col>
                              </Row>
                            );
                          });
                          let pageNumbers = [];
                          for (let i = 1; i <= Math.ceil(this.state.histories.length / todosPerPage); i++) {
                            pageNumbers.push(i);
                          }

                          let renderPageNumbers = pageNumbers.map((number) => {
                            return (
                              <li key={number} id={number} onClick={this.handleClick} className='pagination-number'>
                                {number}
                              </li>
                            );
                          });
                          if (this.state.histories.length > 0) {
                            return (
                              <Row className='w-100'>
                                <Col xs='12' className='p-0 history-detail-item'>
                                  {renderTodos}
                                </Col>
                                <Col xs='12' className='p-0'>
                                  <ul id='page-numbers' className='d-flex justify-content-center'>
                                    <li onClick={() => this.backwards()} className='p-10 page-arrow-left'>
                                      <IoIosArrowBack className='icon-xs account-icon' />
                                    </li>
                                    {renderPageNumbers}
                                    <li onClick={() => this.forwards(pageNumbers.length)} className='p-10 page-arrow-right'>
                                      <IoIosArrowForward className='icon-xs account-icon' />
                                    </li>
                                  </ul>
                                </Col>
                              </Row>
                            );
                          } else {
                            return <Row className='no-Data'>{t('transaction:transaction.emptyHistory')}</Row>;
                          }

                        case 'depositWithdraw':
                          let { currentPage1, todosPerPage1 } = this.state;
                          let indexOfLastTodo1 = currentPage1 * todosPerPage1;
                          let indexOfFirstTodo1 = indexOfLastTodo1 - todosPerPage1;
                          let currentTodos1 = this.state.histories.slice(indexOfFirstTodo1, indexOfLastTodo1);

                          let renderTodos1 = currentTodos1.map((hist, index) => {
                            return (
                              <Row
                                className='w-100 d-flex history-detail-row'
                                key={(hist, index)}
                                onClick={() => {
                                  this.showDetails('depositWithdraw', hist);
                                }}>
                                <Col className='d-flex p-l-0 p-r-0'>
                                  <FiArrowDownRight className='icon-sm arrow-collapse'></FiArrowDownRight>
                                </Col>

                                <Col xs='5' sm='5' className='p-l-0'>
                                  <div className='table-title'>{t(hist.transType)}</div>
                                  <div className='table-date'>{moment(hist.date).format('YYYY-MM-DD HH:mm').toString()}</div>
                                </Col>
                                <Col xs='4' sm='4' className='p-l-0 p-r-0'>
                                  <div className='no-wrap h5'>{`${hist.currency} ${window.SPL_Other.formatAmount(hist.amount)}`}</div>
                                </Col>
                                <Col xs='2' sm='2' className='h5 p-l-0 p-r-0 d-flex justify-content-end'>
                                  <div className={hist.resultClass}>{t(hist.status)}</div>
                                </Col>
                              </Row>
                            );
                          });
                          let pageNumbers1 = [];
                          for (let i = 1; i <= Math.ceil(this.state.histories.length / todosPerPage1); i++) {
                            pageNumbers1.push(i);
                          }

                          let renderPageNumbers1 = pageNumbers1.map((number) => {
                            return (
                              <li key={number} id={number} onClick={this.handleClick} className='pagination-number'>
                                {number}
                              </li>
                            );
                          });
                          if (this.state.histories.length > 0) {
                            return (
                              <Row className='w-100'>
                                <Col xs='12' className='p-0 history-detail-item'>
                                  {renderTodos1}
                                </Col>
                                <Col xs='12' className='p-0'>
                                  <ul id='page-numbers' className='d-flex justify-content-center'>
                                    <li onClick={() => this.backwards()} className='p-10 page-arrow-left'>
                                      <IoIosArrowBack className='icon-xs account-icon' />
                                    </li>
                                    {renderPageNumbers1}
                                    <li onClick={() => this.forwards(pageNumbers1.length)} className='p-10 page-arrow-right'>
                                      <IoIosArrowForward className='icon-xs account-icon' />
                                    </li>
                                  </ul>
                                </Col>
                              </Row>
                            );
                          } else {
                            return <Row className='no-Data'>{t('transaction:transaction.emptyHistory')}</Row>;
                          }

                        case 'promotion':
                          let { currentPage2, todosPerPage2 } = this.state;
                          let indexOfLastTodo2 = currentPage2 * todosPerPage2;
                          let indexOfFirstTodo2 = indexOfLastTodo2 - todosPerPage2;
                          let currentTodos2 = this.state.histories.slice(indexOfFirstTodo2, indexOfLastTodo2);
                          let renderTodos2 = currentTodos2.map((hist, index) => {
                            return (
                              <Row
                                key={index}
                                className='w-100 d-flex history-detail-row'
                                onClick={() => {
                                  this.showDetails('promotion', hist);
                                }}>
                                <Col className='d-flex p-l-0 p-r-0 icon-light'>
                                  <FiArrowDownRight className='icon-xs arrow-collapse'></FiArrowDownRight>
                                </Col>

                                <Col xs='5' sm='5' className='p-l-0'>
                                  <div className='table-title'>{t(hist.promoName)}</div>
                                  <div className='table-date'>{moment(hist.createdDate).format('YYYY-MM-DD HH:mm').toString()}</div>
                                </Col>

                                <Col xs='4' sm='4' className='p-l-0 p-r-0'></Col>
                                <Col xs='2' sm='2' className='h5 p-l-0 p-r-0 d-flex justify-content-end'></Col>
                              </Row>
                            );
                          });
                          let pageNumbers2 = [];
                          for (let i = 1; i <= Math.ceil(this.state.histories.length / todosPerPage2); i++) {
                            pageNumbers2.push(i);
                          }

                          let renderPageNumbers2 = pageNumbers2.map((number) => {
                            return (
                              <li key={number} id={number} onClick={this.handleClick} className='pagination-number'>
                                {number}
                              </li>
                            );
                          });
                          if (this.state.histories.length > 0) {
                            return (
                              <Row className='w-100'>
                                <Col xs='12' className='p-0 history-detail-item'>
                                  {renderTodos2}
                                </Col>
                                <Col xs='12' className='p-0'>
                                  <ul id='page-numbers ' className='d-flex justify-content-center'>
                                    <li onClick={() => this.backwards()} className='p-10 page-arrow-left'>
                                      <IoIosArrowBack className='icon-xs account-icon' />
                                    </li>
                                    {renderPageNumbers2}
                                    <li onClick={() => this.forwards(pageNumbers2.length)} className='p-10 page-arrow-right'>
                                      <IoIosArrowForward className='icon-xs account-icon' />
                                    </li>
                                  </ul>
                                </Col>
                              </Row>
                            );
                          } else {
                            return <Row className='no-Data'>{t('transaction:transaction.emptyHistory')}</Row>;
                          }
                        case 'rebate':
                          let { currentPage3, todosPerPage3 } = this.state;
                          let indexOfLastTodo3 = currentPage3 * todosPerPage3;
                          let indexOfFirstTodo3 = indexOfLastTodo3 - todosPerPage3;
                          let currentTodos3 = this.state.histories.slice(indexOfFirstTodo3, indexOfLastTodo3);
                          let renderTodos3 = currentTodos3.map((hist, index) => {
                            return (
                              <Row
                                key={index}
                                className='w-100 d-flex history-detail-row'
                                onClick={() => {
                                  this.showDetails('rebate', hist);
                                }}>
                                <Col className='d-flex p-l-0 p-r-0 icon-light'>
                                  <FiArrowDownRight className='icon-xs arrow-collapse'></FiArrowDownRight>
                                </Col>

                                <Col xs='5' sm='5' className='p-l-0'>
                                  <div className='table-title'>{t(hist.provider)}</div>
                                  <div className='table-date'>{moment(hist.createdDate).format('YYYY-MM-DD HH:mm').toString()}</div>
                                </Col>
                                <Col xs='4' sm='4' className='p-l-0 p-r-0'>
                                  <div className='no-wrap h5'>{`${hist.currency} ${window.SPL_Other.formatAmount(hist.amount)}`}</div>
                                </Col>
                                <Col xs='2' sm='2' className='h5 p-l-0 p-r-0 d-flex justify-content-end'></Col>
                              </Row>
                            );
                          });
                          let pageNumbers3 = [];
                          for (let i = 1; i <= Math.ceil(this.state.histories.length / todosPerPage3); i++) {
                            pageNumbers3.push(i);
                          }

                          let renderPageNumbers3 = pageNumbers3.map((number) => {
                            return (
                              <li key={number} id={number} onClick={this.handleClick} className='pagination-number'>
                                {number}
                              </li>
                            );
                          });
                          if (this.state.histories.length > 0) {
                            return (
                              <Row className='w-100'>
                                <Col xs='12' className='p-0 history-detail-item'>
                                  {renderTodos3}
                                </Col>
                                <Col xs='12' className='p-0'>
                                  <ul id='page-numbers ' className='d-flex justify-content-center'>
                                    <li onClick={() => this.backwards()} className='p-10 page-arrow-left'>
                                      <IoIosArrowBack className='icon-xs account-icon' />
                                    </li>
                                    {renderPageNumbers3}
                                    <li onClick={() => this.forwards(pageNumbers3.length)} className='p-10 page-arrow-right'>
                                      <IoIosArrowForward className='icon-xs account-icon' />
                                    </li>
                                  </ul>
                                </Col>
                              </Row>
                            );
                          } else {
                            return <Row className='no-Data'>{t('transaction:transaction.emptyHistory')}</Row>;
                          }

                        case 'rewards':
                          let { currentPage4, todosPerPage4 } = this.state;
                          let indexOfLastTodo4 = currentPage4 * todosPerPage4;
                          let indexOfFirstTodo4 = indexOfLastTodo4 - todosPerPage4;
                          let currentTodos4 = this.state.histories.slice(indexOfFirstTodo4, indexOfLastTodo4);
                          let renderTodos4 = currentTodos4.map((hist, index) => {
                            return (
                              <Row
                                key={index}
                                className='w-100 d-flex history-detail-row'
                                onClick={() => {
                                  this.showDetails('rewards', hist);
                                }}>
                                <Col className='d-flex p-l-0 p-r-0 icon-light'>
                                  <FiArrowDownRight className='icon-xs arrow-collapse'></FiArrowDownRight>
                                </Col>

                                <Col xs='5' sm='5' className='p-l-0'>
                                  <div className='table-title'>{t(hist.rewardType)}</div>
                                  <div className='table-date'>{moment(hist.createdDate).format('YYYY-MM-DD HH:mm').toString()}</div>
                                </Col>
                                <Col xs='4' sm='4' className='p-l-0 p-r-0'></Col>
                                <Col xs='2' sm='2' className='h5 p-l-0 p-r-0 d-flex justify-content-end'>
                                  <div className={hist.resultClass}>{t(hist.status)}</div>
                                </Col>
                              </Row>
                            );
                          });
                          let pageNumbers4 = [];
                          for (let i = 1; i <= Math.ceil(this.state.histories.length / todosPerPage4); i++) {
                            pageNumbers4.push(i);
                          }

                          let renderPageNumbers4 = pageNumbers4.map((number) => {
                            return (
                              <li key={number} id={number} onClick={this.handleClick} className='pagination-number'>
                                {number}
                              </li>
                            );
                          });
                          if (this.state.histories.length > 0) {
                            return (
                              <Row className='w-100'>
                                <Col xs='12' className='p-0 history-detail-item'>
                                  {renderTodos4}
                                </Col>
                                <Col xs='12' className='p-0'>
                                  <ul id='page-numbers' className='d-flex justify-content-center'>
                                    <li onClick={() => this.backwards()} className='p-10 page-arrow-left'>
                                      <IoIosArrowBack className='icon-xs account-icon' />
                                    </li>
                                    {renderPageNumbers4}
                                    <li onClick={() => this.forwards(pageNumbers4.length)} className='p-10 page-arrow-right'>
                                      <IoIosArrowForward className='icon-xs account-icon' />
                                    </li>
                                  </ul>
                                </Col>
                              </Row>
                            );
                          } else {
                            return <Row className='no-Data'>{t('transaction:transaction.emptyHistory')}</Row>;
                          }
                        default:
                          return <Row className='no-Data'>{t('transaction:transaction.emptyHistory')}</Row>;
                          break;
                      }
                    })()}
                  </Row>
                </div>
              </div>
            </div>
          </div>
        )}
      </Translation>
    );
  }
}
export default connect(mapStateToProps, null)(withTranslation(['history', 'settings', 'transaction', 'providerName'])(withRouter(History)));
