import React from 'react';
import { connect } from 'react-redux';
import { mapStateToProps } from 'root/redux/selector/selector';
import { withRouter } from 'react-router-dom';
import { Trans, Translation, withTranslation } from 'react-i18next';
import SVGCommon from 'root/components/Icon/SVGCommon';
import commonUtil from '../../utils/common-util';

class Complete extends React.Component {
    isMount = true;
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { tag, history } = this.props;

        return (
            <Translation>
                {(t) =>
                    tag === 'complete' && (
                        <div className='completed'>
                            <div className='completed-main'>
                                <SVGCommon className='icon-logo' name='maxim-logo'></SVGCommon>
                                <p className='text-big'>{t('register:register.congratulation', 'CONGRATULATION!')}</p>
                                <p className='text-small'> {t('register:register.createSuccess', 'Successfully register as a member!')}</p>
                            </div>

                            <span
                                className='btn-normal'
                                onClick={() => {
                                    commonUtil.redirectToDeposit(history, 0);
                                }}
                            >
                                {t('popupTransfer:popupTransfer.btndeposit', 'Deposit Now')}
                            </span>

                            <p className='text'>
                                <Trans i18nKey={'register:register.096-deposit_msg'} />
                            </p>
                        </div>
                    )
                }
            </Translation>
        );
    }
}

export default connect(mapStateToProps)(withTranslation('popupTransfer')(withRouter(Complete)));
