import moment from 'moment';
import React, { Component, Fragment } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Translation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { mapStateToProps } from 'root/redux/selector/selector';
import {
    EmailIcon,
    EmailShareButton,
    FacebookIcon,
    FacebookShareButton,
    LineIcon,
    LineShareButton,
    TelegramIcon,
    TelegramShareButton,
    WhatsappIcon,
    WhatsappShareButton,
} from 'react-share';
import Pagination from 'react-js-pagination';
import SVGHistory from 'root/components/Icon/SVGHistory';
import Loading from 'root/components/Loading/Loading';
import SVGElements from 'root/components/SVGElements';
import core from '../../../utils/core';
import SmallLoading from 'root/components/Loading/SmallLoading';
class Referral extends Component {
    referralCategories = [
        {
            id: 0,
            name: this.props.t('settings:settings.myReferral'),
            referralType: 'myReferral',
        },
        {
            id: 1,
            name: this.props.t('settings:settings.myBonus'),
            referralType: 'myBonus',
        },
    ];
    isMount = true;
    constructor(props) {
        super(props);
        let currentDate = new Date();
        this.state = {
            selectedFilterType: [
                {
                    id: 0,
                    name: this.props.t('settings:settings.myReferral'),
                    referralType: 'myReferral',
                },
            ],
            referralType: 'myReferral',
            referralCode: '',
            referralLink: '',
            referralOption: {},
            language: '',
            referralDetail: {},
            filter: {
                startDate: new Date(new Date().setMonth(currentDate.getMonth() - 1)),
                endDate: currentDate,
            },
            predicate: 'id',
            reverse: false,
            referralHistories: [],
            totalItem: 0,
            totalPageSize: '',
            getInviteInfo: false,
            value: { start: null, end: null },
            currentPage: 1,
            todosPerPage: 10,
            loading: false,
            disableMemberBecomeReferral: false,
            showLoading: true,
        };
    }

    componentDidMount() {
        this.isMount = true;
        this.getReferralTypeEnabledAndMemberSummaryGroupAutomation();
        this.momentJSLocalization();
        this.initReferralSetting();
        this.checkCountryLanguage();
    }

    componentWillUnmount() {
        this.isMount = false;
    }

    async getReferralTypeEnabledAndMemberSummaryGroupAutomation() {
        const data = await window.SPL_Member.getReferralTypeEnabledAndMemberSummaryGroupAutomation();

        this.setState({
            disableMemberBecomeReferral: data?.disableMemberBecomeReferral,
            showLoading: false,
        });
    }

    momentJSLocalization() {
        const { key } = this.props.language;
        const lang = key ? key.toLowerCase() : 'en';
        moment.locale(lang);
    }

    onTabClick(index) {
        let referralType = this.referralCategories[index].referralType;
        this.selectedFilterType = [this.referralCategories[index]];
        this.setState({
            referralType: referralType,
            histories: [],
            selectedFilterType: [this.referralCategories[index]],
            activeTab: index,
        });
    }

    onDateChanged = (date, type) => {
        if (type === 'start') {
            this.setState({
                filter: { ...this.state.filter, startDate: date },
            });
        } else {
            this.setState({ filter: { ...this.state.filter, endDate: date } });
        }
    };

    initReferralSetting = () => {
        const { key, currencyLang } = this.props.language;
        const { currency } = this.props.user.account;

        if (key) {
            window.SPL_Referral.initReferralSetting(currency, key, window.globMerchantName).then((res) => {
                if (this.isMount && res) {
                    let referralCode = '';
                    let referralLink = '';
                    let referralMsg = '';
                    let referralOption = {};
                    let referralDetail = res.referralDetail;

                    // handling for json file
                    const { lastModifiedDate } = referralDetail;
                    if (lastModifiedDate) {
                        const lang = key.toLowerCase();
                        referralDetail.year = moment(lastModifiedDate).format('YYYY');
                        referralDetail.month = moment(lastModifiedDate).format(lang === 'zh-cn' ? 'M' : 'MMMM');
                        referralDetail.day = moment(lastModifiedDate).format(lang === 'zh-cn' ? 'D' : 'Do');
                    }

                    if (res.referralSetting) {
                        let referralSetting = res.referralSetting;

                        if (referralSetting.hasOwnProperty('referralCode')) {
                            referralCode = referralSetting.referralCode;
                        }
                        if (referralSetting.hasOwnProperty('referralLink')) {
                            referralLink = referralSetting.referralLink;
                        }
                        if (referralSetting.hasOwnProperty('referralMsg')) {
                            referralMsg = referralSetting.referralMsg;
                        }

                        if (referralSetting.hasOwnProperty('referralOption')) {
                            referralOption = referralSetting.referralOption;
                        }
                    }

                    this.setState({
                        referralCode: referralCode,
                        referralLink: referralLink,
                        referralMsg: referralMsg,
                        referralOption: referralOption,
                        referralDetail: referralDetail,
                    });
                }
            });
        }
    };

    checkCountryLanguage = () => {
        if (this.props.language.countryLanguageKey === 'TH_TH') {
            this.setState({ language: 'th' });
        } else if (this.props.language.countryLanguageKey === 'ZH_MY') {
            this.setState({ language: 'zhCN' });
        } else {
            this.setState({ language: '' });
        }
    };

    searchAllReferrals = () => {
        this.setState({ loading: true });
        const { filter, predicate, reverse, currentPage, todosPerPage } = this.state;
        const { startDate, endDate } = filter;
        let start = moment(startDate).format('YYYY-MM-DD').toString();
        let end = moment(endDate).format('YYYY-MM-DD').toString();
        let params = {
            page: currentPage,
            itemsPerPage: todosPerPage,
            predicate: predicate,
            reverse: reverse,
        };
        window.SPL_Referral.searchAllReferrals(params, start, end).then((res) => {
            if (this.isMount) {
                this.setState({
                    referralHistories: res.myReferrals,
                    totalItems: res.totalItems,
                    totalPageSize: res.totalPageSize,
                    loading: false,
                });
            }
        });
    };

    copy(text) {
        const { t } = this.props;
        core.copy(t, text);
    }

    getInviteInfo = () => {
        this.setState({ getInviteInfo: !this.state.getInviteInfo });
    };

    handlePageChange = (pageNumber) => {
        this.setState({ currentPage: pageNumber }, () => {
            this.searchAllReferrals();
        });
    };

    render() {
        const { referralType, referralOption, referralDetail, referralMsg, referralLink, referralCode, filter, language, showLoading, disableMemberBecomeReferral } =
            this.state;
        const { key } = this.props.language;

        let lang = '';
        if (key) {
            lang = key.toLowerCase();
        }

        return (
            <Translation>
                {(t) => (
                    <div className='referral'>
                        {this.state.loading && <Loading />}

                        <h1>{t('settings:settings.profiletab.referral', 'Referral')}</h1>

                        <div className='inbox'>
                            {this.referralCategories.map((referralTab, index) => (
                                <span key={index} onClick={() => this.onTabClick(index)} className={`${referralTab.referralType === referralType && 'tab-active'}`}>
                                    {t(referralTab.name)}
                                </span>
                            ))}
                        </div>
                        <div className='row-separator-4'></div>

                        {(() => {
                            switch (referralType) {
                                case 'myReferral':
                                    return (
                                        <Fragment>
                                            <div className='referral-content-container'>
                                                <div className='left-container'>
                                                    {showLoading ? (
                                                        <SmallLoading></SmallLoading>
                                                    ) : (
                                                        <>
                                                            {!disableMemberBecomeReferral && (
                                                                <>
                                                                    <div className='user-info'>
                                                                        <table className='referral-link-table'>
                                                                            <tr>
                                                                                <td className='title-column'>{t('settings:settings.referralCode', 'Referral Code')}</td>
                                                                                <td className='colon-column'>:</td>
                                                                                <td className='content-column'>
                                                                                    <p>{referralCode}</p>
                                                                                    <div className='icon-box' onClick={() => this.copy(this.state.referralCode)}>
                                                                                        <SVGElements className='icon-copy' name='copy-icon' />
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td className='title-column'>{t('settings:settings.referralLink', 'Referral Link')}</td>
                                                                                <td className='colon-column'>:</td>
                                                                                <td className='content-column'>
                                                                                    <p>{referralLink}</p>
                                                                                    <div className='icon-box' onClick={() => this.copy(this.state.referralLink)}>
                                                                                        <SVGElements className='icon-copy' name='copy-icon' />
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                        </table>
                                                                    </div>

                                                                    <div className='row-separator-40'></div>

                                                                    <div className='referral-option-container'>
                                                                        <p className='referral-container-title'>
                                                                            {t('settings:settings.shareReferral', 'Share this offer to your friends')} !
                                                                        </p>

                                                                        <div className='row-separator-25'></div>

                                                                        <div className='referral-radio-button-container'>
                                                                            <label className='share-option'>
                                                                                <input type='radio' name='option' checked readOnly />
                                                                            </label>

                                                                            {referralOption[lang] && !referralOption[lang].isHideShareBox && (
                                                                                <div>
                                                                                    <div className='row-separator-10'></div>{' '}
                                                                                    <div className='share-box'>
                                                                                        <p className='share-amount'>{referralOption[lang].amount}</p>
                                                                                        <div
                                                                                            className='share-detail'
                                                                                            dangerouslySetInnerHTML={{
                                                                                                __html: referralOption[lang].details,
                                                                                            }}
                                                                                        ></div>
                                                                                    </div>
                                                                                    <div className='row-separator-30'></div>
                                                                                </div>
                                                                            )}

                                                                            {this.state.getInviteInfo && (
                                                                                <div className='messaging-popup'>
                                                                                    <div className='popup'>
                                                                                        <div className='popup-header'>
                                                                                            <div className='popup-header-left'>
                                                                                                <div className='popup-title'>
                                                                                                    {t('settings:settings.inviteFriend', 'Invite Friend')}
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className='popup-header-right'>
                                                                                                <SVGElements name='close-icon' onClick={() => this.getInviteInfo()} />
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className='popup-body'>
                                                                                            <div className='icon-box' onClick={() => this.copy(referralMsg)}>
                                                                                                <SVGElements className='icon-copy' name='copy-icon' />
                                                                                            </div>
                                                                                            <FacebookShareButton url={referralLink}>
                                                                                                <FacebookIcon />
                                                                                            </FacebookShareButton>
                                                                                            <EmailShareButton url={referralMsg}>
                                                                                                <EmailIcon></EmailIcon>
                                                                                            </EmailShareButton>
                                                                                            <WhatsappShareButton url={referralMsg}>
                                                                                                <WhatsappIcon></WhatsappIcon>
                                                                                            </WhatsappShareButton>
                                                                                            <TelegramShareButton url={referralMsg}>
                                                                                                <TelegramIcon></TelegramIcon>
                                                                                            </TelegramShareButton>
                                                                                            <LineShareButton url={referralLink} title={referralMsg}>
                                                                                                <LineIcon></LineIcon>
                                                                                            </LineShareButton>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='popup-overlay' onClick={() => this.getInviteInfo()}></div>
                                                                                </div>
                                                                            )}

                                                                            <div className='btn-normal invite-btn' onClick={() => this.getInviteInfo()}>
                                                                                {t('settings:settings.inviteFriend', 'Invite Friend')}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            )}
                                                        </>
                                                    )}
                                                </div>

                                                <div className='right-container'>
                                                    {referralOption && referralOption[lang] && (
                                                        <div className='notice-box'>
                                                            <div className='title'>
                                                                <i className='icon-notice-important'></i>
                                                                <span>{referralOption[lang].termNConditionTitle}</span>
                                                            </div>
                                                            <ul>
                                                                <li>
                                                                    <span>1</span>
                                                                    <p>{referralOption[lang].termNCondition1}</p>
                                                                </li>
                                                                <li>
                                                                    <span>2</span>
                                                                    <p>
                                                                        {t(referralOption[lang].termNCondition2, {
                                                                            targetDepositAmount: referralDetail.targetDepositAmount,
                                                                            expiredDays: referralDetail.expiredDays,
                                                                            invitationBonusAmount: referralDetail.invitationBonusAmount,
                                                                            referralBonusAmount: referralDetail.referralBonusAmount,
                                                                        })}
                                                                    </p>
                                                                </li>
                                                                <li>
                                                                    <span>3</span>
                                                                    <p>
                                                                        {t(referralOption[lang].termNCondition3, {
                                                                            colon: ':',
                                                                            targetDepositAmount: referralDetail.targetDepositAmount,
                                                                            expiredDays: referralDetail.expiredDays,
                                                                        })}
                                                                    </p>
                                                                </li>
                                                                {referralOption[lang].termNCondition4 ? (
                                                                    <li>
                                                                        <span>4</span>
                                                                        <p>
                                                                            {t(referralOption[lang].termNCondition4, {
                                                                                targetDepositAmount: referralDetail.targetDepositAmount,
                                                                                expiredDays: referralDetail.expiredDays,
                                                                            })}
                                                                        </p>
                                                                    </li>
                                                                ) : null}
                                                                <li>
                                                                    <span className='empty-span'> </span>
                                                                    <p dangerouslySetInnerHTML={{ __html: referralOption[lang].learnMore }}></p>
                                                                </li>
                                                                <li>
                                                                    <span className='empty-span'> </span>
                                                                    <p>
                                                                        {t(referralOption[lang].effectiveDate, {
                                                                            year: referralDetail.year,
                                                                            month: referralDetail.month,
                                                                            day: referralDetail.day,
                                                                        })}
                                                                    </p>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>

                                            <div className='row-separator-40 row-separator-line'></div>
                                            <div className='row-separator-30'></div>

                                            <div className='referral-content-container'>
                                                <div className='left-container'>
                                                    <div className='referral-status-container'>
                                                        <p>{t('settings:settings.urReferralStatus', 'Referral Status')}</p>
                                                        <div className='row-separator-15'></div>
                                                        <div className='status-box'>
                                                            <table className='status-detail-table'>
                                                                <tr>
                                                                    <td className='title-column'>{t('settings:settings.inviteAccepted', 'Invitation Accepted')}</td>
                                                                    <td className='colon-column'>:</td>
                                                                    <td className='content-column'>{referralDetail.totalReferree}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td className='title-column'>{t('settings:settings.missionComplete', 'Mission Completed')}</td>
                                                                    <td className='colon-column'>:</td>
                                                                    <td className='content-column'>{referralDetail.totalConverted}</td>
                                                                </tr>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Fragment>
                                    );
                                    break;

                                case 'myBonus':
                                    return (
                                        <Fragment>
                                            <ul className='referral-container'>
                                                <li className='bonus-formpage'>
                                                    <div className='form-bonus'>
                                                        <div className='form-item'>
                                                            <div className='top'>
                                                                <div className='top-title'>
                                                                    <span className='bonus-label'>{t('transaction:transaction.history.filter.start_date_nocol')}</span>
                                                                </div>
                                                                <DatePicker
                                                                    locale={language}
                                                                    name='startDte'
                                                                    // isClearable
                                                                    autoComplete='off'
                                                                    label='w-100'
                                                                    type='text'
                                                                    placeholderText='Start Date'
                                                                    selectsStart
                                                                    selected={filter.startDate}
                                                                    onChange={(date) => this.onDateChanged(date, 'start')}
                                                                    startDate={filter.start}
                                                                    endDate={filter.end}
                                                                    maxDate={filter.end}
                                                                    popperPlacement='bottom'
                                                                    popperModifiers={{
                                                                        flip: {
                                                                            behavior: ['bottom'], // don't allow it to flip to be above
                                                                        },
                                                                        preventOverflow: {
                                                                            enabled: false, // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
                                                                        },
                                                                        hide: {
                                                                            enabled: false, // turn off since needs preventOverflow to be enabled
                                                                        },
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className='form-item'>
                                                            <div className='top'>
                                                                <div className='to-label'>
                                                                    <span className='bonus-label'>{t('transaction:transaction.history.to')}</span>
                                                                </div>
                                                                <DatePicker
                                                                    locale={language}
                                                                    name='endDate'
                                                                    // isClearable
                                                                    autoComplete='off'
                                                                    label='w-100'
                                                                    type='text'
                                                                    placeholderText='End Date'
                                                                    selectsEnd
                                                                    selected={filter.endDate}
                                                                    onChange={(date) => this.onDateChanged(date, 'end')}
                                                                    startDate={filter.start}
                                                                    endDate={filter.end}
                                                                    minDate={filter.start}
                                                                    popperPlacement='bottom'
                                                                    popperModifiers={{
                                                                        flip: {
                                                                            behavior: ['bottom'], // don't allow it to flip to be above
                                                                        },
                                                                        preventOverflow: {
                                                                            enabled: false, // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
                                                                        },
                                                                        hide: {
                                                                            enabled: false, // turn off since needs preventOverflow to be enabled
                                                                        },
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className='form-item'>
                                                            <button type='submit' onClick={() => this.searchAllReferrals()} className='btn-normal btn-search'>
                                                                <span>{t('transaction:transaction.deposit.cashdeposit.submitbtn').toLowerCase()}</span>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </li>

                                                <li className='bonus-container'>
                                                    <table>
                                                        <tbody>
                                                            <tr>
                                                                <th>{t('settings:settings.memberLogin')}</th>
                                                                <th>{t('settings:settings.registerTime')}</th>
                                                                <th>{t('settings:settings.currency')}</th>
                                                                <th>{t('settings:settings.totalDeposit')}</th>
                                                                <th>{t('settings:settings.bonusAmount')}</th>
                                                                <th>{t('settings:settings.status')}</th>
                                                            </tr>

                                                            {this.state.referralHistories.length > 0 ? (
                                                                // renderTodos
                                                                this.state.referralHistories.map((todo, index) => {
                                                                    return (
                                                                        <tr key={index}>
                                                                            <td>{t(todo.refereeLogin)}</td>
                                                                            <td>{moment(todo.registerTime).format('YYYY-MM-DD HH:mm').toString()}</td>
                                                                            <td>{t(todo.refereeCurrency)}</td>
                                                                            <td className='deposit-amount'>
                                                                                {window.SPL_Other.formatAmount(todo.currentDepositAmount)} /{' '}
                                                                                {window.SPL_Other.formatAmount(todo.targetDepositAmount)}
                                                                            </td>
                                                                            <td>{window.SPL_Other.formatAmount(todo.referralBonusAmount)}</td>
                                                                            <td>{t(todo.status)}</td>
                                                                        </tr>
                                                                    );
                                                                })
                                                            ) : (
                                                                <tr>
                                                                    <td colSpan='6' className='noData'>
                                                                        {t('transaction:transaction.emptyHistory')}
                                                                    </td>
                                                                </tr>
                                                            )}
                                                        </tbody>
                                                    </table>
                                                </li>

                                                {this.state.referralHistories.length > 0 && (
                                                    <Pagination
                                                        firstPageText={<SVGHistory name='prevFirst-arrow' className='' alt='prevFirst-arrow' />}
                                                        lastPageText={<SVGHistory name='nextLast-arrow' className='' alt='nextLast-arrow' />}
                                                        prevPageText={<SVGHistory name='prev-arrow' className='' alt='prev-arrow' />}
                                                        nextPageText={<SVGHistory name='next-arrow' className='' alt='next-arrow' />}
                                                        activePage={this.state.currentPage}
                                                        itemsCountPerPage={this.state.todosPerPage}
                                                        totalItemsCount={parseInt(this.state.totalItems)}
                                                        pageRangeDisplayed={5}
                                                        onChange={this.handlePageChange.bind(this)}
                                                    />
                                                )}
                                            </ul>
                                        </Fragment>
                                    );
                                    break;

                                default:
                                    break;
                            }
                        })()}
                    </div>
                )}
            </Translation>
        );
    }
}

export default connect(mapStateToProps)(withTranslation(['transaction', 'referral'])(withRouter(Referral)));
