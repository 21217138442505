import { gu_filterCommonKey } from 'root/utils/general-util';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const convertImgPath = (imgSrc) => {
    if (imgSrc) {
        const merchantCode = window.merchantCode;
        const index = imgSrc.indexOf(merchantCode) + window.merchantCode.length + 1;
        return `/public/html/${imgSrc.substring(index)}`;
    }
};

const useFreespin = () => {
    const viewType = useSelector((state) => state?.screen?.viewType || 'web');
    const features = useSelector((state) => state?.portal?.settings?.features);
    const memberId = useSelector((state) => state?.user?.account?.memberId);
    const language = useSelector((state) => state?.language);

    const [openModal, setOpenModal] = useState(false);
    const [isEnabled, setIsEnabled] = useState(false);
    const [data, setData] = useState({
        floatIcon: null,
        popupImage: null,
        claimExpired: null,
        popupClaimDataList: null,
        textMessageColor: null,
        textProviderColor: null,
        checkMemberInboxMessage: false,
        hideCTA: false,
        ctaMenu: 'SL',
        ctaLink: '',
        provider: null,
    });
    const settings = gu_filterCommonKey(features, 'floatingFreeSpinSettings') || {};

    useEffect(() => {
        Object.keys(localStorage).forEach((x) => x.includes('freespinPopup.') && !x.includes(`freespinPopup.${memberId}`) && localStorage.removeItem(x));
    }, [memberId]);

    useEffect(() => {
        if (!memberId || localStorage.getItem(`freespinPopup.${memberId}`) === 'disabled') {
            return;
        }

        window.SPL_Member.getFreespinPopup({ memberId, merchantCode: window.merchantCode }).then(async (data) => {
            if (data) {
                if (!data?.popupClaimDataList?.length) {
                    return;
                }

                const { floatIcon, popupImage, hideCTA, menuCode = 'SL', ctaLink } = settings || null;

                const providers = await window.SPL_Content.getDisplaySubCategory(language.countryLanguageKey, viewType);
                const providerMenuMatched = providers?.find((item) => item?.link === menuCode);
                const providerMatched = providerMenuMatched?.submenu?.find((item) => item?.provider === data?.popupClaimDataList?.[0]?.provider);

                const popupClaimDataListSortByEndtime = data.popupClaimDataList.sort((a, b) => {
                    return new Date(a.endTime).getTime() - new Date(b.endTime).getTime();
                });

                const earlyTime = format(new Date(popupClaimDataListSortByEndtime?.[0]?.endTime), 'yyyy-MM-dd HH:mm');
                const lastClaimDataItem = popupClaimDataListSortByEndtime?.[popupClaimDataListSortByEndtime.length - 1];
                const newData = {
                    floatIcon: convertImgPath(viewType === 'web' ? lastClaimDataItem?.floatIcon : lastClaimDataItem?.mFloatIcon) || floatIcon,
                    popupImage: convertImgPath(viewType === 'web' ? lastClaimDataItem?.popup : lastClaimDataItem?.mPopup) || popupImage,
                    claimExpired: earlyTime,
                    popupClaimDataList: data.popupClaimDataList,
                    textMessageColor: lastClaimDataItem?.messageFont || undefined,
                    textProviderColor: lastClaimDataItem?.providerFont || undefined,
                    checkMemberInboxMessage: data.checkMemberInboxMessage,
                    hideCTA: Array.isArray(hideCTA) ? hideCTA.includes(language?.currencyLang) : hideCTA,
                    ctaLink: ctaLink || '',
                    ctaMenu: menuCode,
                    provider: providerMatched,
                };
                setIsEnabled(true);
                setData(newData);
            }
        });
    }, [memberId, window.merchantCode]);

    const closeIcon = () => {
        setIsEnabled(false);
        localStorage.setItem(`freespinPopup.${memberId}`, 'disabled');
    };

    const toggleModal = () => setOpenModal(!openModal);

    return {
        toggleModal,
        showIcon: isEnabled && settings,
        data,
        openModal,
        closeIcon,
    };
};

export default useFreespin;
