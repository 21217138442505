import React from 'react';
import { withTranslation } from 'react-i18next';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { mapStateToProps } from '../../../redux/selector/selector';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import HeaderMenu_01 from './HeaderMenu_01/HeaderMenu_01';

class HeaderMenu extends React.Component {
  isMount = true;
  constructor(props) {
    super(props);

    this.state = {
      hover: false,
      showAboutMenu: false,
      itemIdOnHover: 0,
      submenuIdOnHover: 0,
      submenuHotProvider: false,
      displaySubmenu: [],
      showBorder: 'no',
      hoverName: '',
      turnOpacity: '',
    };
    this.onMouseEnterHandler = this.onMouseEnterHandler.bind(this);
    this.onMouseLeaveHandler = this.onMouseLeaveHandler.bind(this);
  }

  componentDidMount() {
    this.getDisplayMenu();
    this.getDisplaySubCategory();
  }

  componentWillUnmount() {
    this.isMount = false;
  }

  preloadImage(images) {
    for (let i = 0; i < images.length; i++) {
      const image = images[i];
      let img = new Image();
      img.src = image.img;
      let imgOn = new Image();
      imgOn.src = image.imghover;
    }
  }

  handleHover = (item) => {
    if (
      item.name === 'Mega888' ||
      item.name === 'Home' ||
      item.name === 'Mobile' ||
      item.name === 'LiveTV' ||
      item.name === 'LiveScore' ||
      item.name === 'Poker' ||
      item.name === 'Promotions' ||
      item.name === 'AffiliateNav' ||
      item.name === 'VIP' ||
      item.name === '918Kiss'
    ) {
      this.setState({ showBorder: 'no' });
    } else {
      this.setState({ showBorder: item.name });
    }

    // select submenu obj categrary based on hover
    let displaySubmenuObj = this.state.submenus.filter((s) => s.link === item.submenu)[0];
    let displaySubmenu = [];

    if (displaySubmenuObj) {
      displaySubmenu = displaySubmenuObj.submenu;
    }
    this.setState({ showSubMenu: true, itemIdOnHover: item.submenu, displaySubmenu: displaySubmenu });
  };

  handleLeave = (mouse) => {
    this.setState({ showSubMenu: false, itemIdOnHover: null, showBorder: 'no' });
  };

  onMouseEnterHandler(id) {
    this.setState({
      submenuIdOnHover: id,
    });
  }

  onMouseLeaveHandler() {
    this.setState({
      isHover: false,
      submenuIdOnHover: null,
    });
  }

  getDisplayMenu = () => {
    const { language } = this.props;
    // viewType hardcoded to web due to headermenu only display in web
    window.SPL_Content.getDisplayMenu(language.countryLanguageKey, 'web').then((data) => {
      if (this.isMount) {
        this.setState({ menus: data });
        // this.preloadImage(data);
      }
    });
  };

  getAnnoucement = () => {
    const { countryLanguageKey } = this.props.language;
    const { isLogin } = this.props.user;

    window.SPL_Content.getAnnoucementList(isLogin, countryLanguageKey).then((data) => {
      if (this.isMount) {
        this.setState({ annoucementList: data.announcementsDisplay });
      }
    });
  };

  getDisplaySubCategory = () => {
    const { language } = this.props;
    // viewType hardcoded to web due to headermenu only display in web
    window.SPL_Content.getDisplaySubCategory(language.countryLanguageKey, 'web').then((data) => {
      if (this.isMount) {
        this.setState({ submenus: data });
        for (let i = 0; i < data.length; i++) {
          this.preloadImage(data[i].submenu);
        }
      }
    });
  };

  navigateToPage = (gameObj) => {
    if (!gameObj.route) {
      const { category, provider } = gameObj;
      if (category === 'C' || category === 'P') {
        this.props.history.push(`/casino/${gameObj.provider.toLowerCase()}`);
      } else if (category === 'SL') {
        if (provider === 'SCR' || provider === 'SCR2') {
          this.props.history.push(`/918kiss`);
        } else if (provider === 'MEGA') {
          this.props.history.push(`/mega888`);
        } else {
          this.props.history.push(`/slot/${gameObj.provider.toLowerCase()}`);
        }
      } else if (category === 'F') {
        this.props.history.push(`/fishing/${gameObj.provider.toLowerCase()}`);
      } else if (['S'].includes(category)) {
        this.launchGame(gameObj);
      }
    }
  };

  launchGame = (gameObj) => {
    const { language, isLogin, screen } = this.props;
    window.SPL_Provider.getIsEnabledWithouthAuth(gameObj, screen.viewType).then((isEnabled) => {
      if (this.isMount) {
        if (!isLogin && !isEnabled) {
          this.setState({ loginModalVisible: true });
        } else {
          window.SPL_Provider.generateGameUrl(gameObj, language, screen.viewType).then((url) => {
            window.open(url, '_blank');
          });
        }
      }
    });
  };

  switchOpacity = (name, opacity) => {
    this.setState({ hoverName: name, turnOpacity: opacity });
  };

  getHeaderLayout = () => {
    let layout = window.layoutGroup;
    if (["L04"].includes(layout)) {
      return "HeaderMenu_04" 
    }
     else {
      // default or no special requirement go to else
      return "HeaderMenu_01"
    }

  }

  render() {
    return (
      <div className='w-100 h-100'>
        {this.getHeaderLayout() === 'HeaderMenu_01' && <HeaderMenu_01/>}
      </div>
    );
  }
}
export default connect(mapStateToProps)(withTranslation()(withRouter(HeaderMenu)));
