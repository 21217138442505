import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Translation, withTranslation } from 'react-i18next';
import { mapStateToProps } from 'root/redux/selector/selector';

import vm from './date-time-picker.controller';
import SelectDropdown from '../select-dropdown/select-dropdown';
import Option from '../select-dropdown/sub-component/option';

class DateTimePicker extends React.Component {
    props = null;

    constructor(props) {
        super();
        this.props = props;
        vm.init(this);
    }

    componentDidMount() {
        vm.initDateTime();
    }

    componentWillUnmount() {
        this.setState = () => {
            return;
        };
    }

    renderDateType(type) {
        // global props
        const { t } = this.props;
        // passed props

        const { days, months, years } = this.state;
        // priority take props,  if not will slow 1 pace, state is for no date props passed in
        let day = this.props.day ? this.props.day : this.state.day;
        let month = this.props.month ? this.props.month : this.state.month;
        let year = this.props.year ? this.props.year : this.state.year;
        day = day <= 10 ? parseInt(day) : day;
        month = month < 10 ? parseInt(month) : month;

        switch (type) {
            case 'day':
                return (
                    <SelectDropdown name={`dropdown-day`} selectedLabel={day}>
                        {(days || []).map((a, i) => {
                            const { value } = a;
                            return (
                                <Option
                                    key={i}
                                    className={`${value === day ? 'active' : ''} `}
                                    optionLabel={t(value)}
                                    onClickFunction={() => {
                                        vm.onDayChanged(value);
                                    }}
                                />
                            );
                        })}
                    </SelectDropdown>
                );
                break;
            case 'month':
                return (
                    <SelectDropdown name={`dropdown-month`} selectedLabel={month}>
                        {(months || []).map((a, i) => {
                            const { value } = a;
                            return (
                                <Option
                                    key={i}
                                    className={`${value === month ? 'active' : ''} `}
                                    optionLabel={t(value)}
                                    onClickFunction={() => {
                                        vm.onMonthChanged(value);
                                    }}
                                />
                            );
                        })}
                    </SelectDropdown>
                );
                break;
            case 'year':
                return (
                    <SelectDropdown name={`dropdown-year`} selectedLabel={year}>
                        {(years || []).map((a, i) => {
                            const { label, value } = a;
                            return (
                                <Option
                                    key={i}
                                    className={`${value === Number(year) ? 'active' : ''} `}
                                    optionLabel={t(label)}
                                    onClickFunction={() => {
                                        vm.onYearChanged(value);
                                    }}
                                />
                            );
                        })}
                    </SelectDropdown>
                );
                break;

            default:
                break;
        }
    }

    render() {
        const { ordering } = this.props;
        let dobOrdering = ordering ? ordering : ['day', 'month', 'year'];

        return (
            <Translation>
                {(t) => (
                    <>
                        {(dobOrdering || []).map((type, i) => (
                            <Fragment key={i}>{this.renderDateType(type)}</Fragment>
                        ))}
                    </>
                )}
            </Translation>
        );
    }
}

export default connect(mapStateToProps)(withTranslation()(withRouter(DateTimePicker)));
