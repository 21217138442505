import React, { Component } from 'react';
import { Trans, Translation, withTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';
import Select from 'react-dropdown-select';
import { AiOutlineCopy } from 'react-icons/ai';
import { connect } from 'react-redux';
import { mapStateToProps } from '../../redux/selector/selector';
import { withRouter } from 'react-router-dom';

class ChangeGamePassword extends Component {
	isMount = true;
	constructor(props) {
		super(props);
		this.state = {
			newpassword: '',
			confirmpassword: '',
			samePassword: false,
			hiddenCurrent: true,
			hiddenNewPassword: true,
			hiddenConfirmPassword: true,
			newPasswordErrMsg: [],
			providerList: [],
			message: '',
			name: '',
			passguide: ''
		};
	}

	handleNewPasswordChange = e => {
		const newpassword = e.target.value;

		this.setState({ newpassword: newpassword }, () => {
			this.validatePassword();
		});
	};

	handleConfirmNewPasswordChange = e => {
		const confirmpassword = e.target.value;

		this.setState({ confirmpassword: confirmpassword }, () => {
			this.validatePassword();
		});
	};

	componentDidMount() {
		this.isMount = true;
		if (this.props.newpassword) {
			this.setState({ newpassword: this.props.newpassword });
		}
		this.getProviders();

		if (this.props.screen.viewType === 'web' && this.props.location.pathname === '/changegamepassword') {
			this.props.history.push('/myaccount/changegamepassword');
		} else if (this.props.screen.viewType === 'mobile' && this.props.location.pathname === '/myaccount/changegamepassword') {
			this.props.history.push('/myaccount/myprofile');
			this.props.history.push('/changegamepassword');
		}
	}

	componentWillUnmount() {
		this.isMount = false;
	}

	handleSubmit = () => {
		if (this.state.newPasswordErrMsg.length > 0) {
			return;
		}
		if (this.state.confirmpassword.length <= 0 || this.state.newpassword.length <= 0) {
			return;
		}
		window.SPL_Provider.changeProviderPassword(this.state.selectedProvider[0].provider, this.state.newpassword).then(data => {
			if (this.isMount) {
				if (data.status === 'S') {
					this.setState({
						message: 'password:password.messages.success',
						status: 'S',
						currentpassword: '',
						newpassword: '',
						confirmpassword: ''
					});
				} else if (data.status === 'F') {
					this.setState({
						message: 'password:password.messages.error',
						status: 'F'
					});
				}
			}
		});
	};

	getProviders = () => {
		window.SPL_Provider.getChangePasswordProviderList().then(data=>{
			this.setState({ providerList: data });
		});
			
	};

	validatePassword = () => {
		
		window.SPL_Provider.validateGamePassword(this.state.newpassword, this.state.confirmpassword, this.state.pattern, this.state.passguide, 6, '').then(
			errMsg => {
				if (this.isMount) {
					this.setState({ newPasswordErrMsg: errMsg });
				}
			}
		);
	};

	copy(text) {
		window.SPL_Other.copyToClipboard(text);
	}

	handleProviderChange = value => {
		let provider = value[0];
		if (provider) {
			// if provider null then get main wallet
			this.setState({ selectedProvider: value }, () => {
				this.getPassGuide();
				this.getGameLoginCredential();
			});
		}
	};

	getPassGuide = () => {
		window.SPL_Provider.passwordGuide(this.state.selectedProvider[0].provider).then(data => {
			if (this.isMount) {
				let guide = data.passguide;
				let pattern = data.pattern;
				this.setState({ passguide: this.props.t(guide), pattern: pattern });
			}
		});
	};

	getGameLoginCredential = () => {
		let provider = this.state.selectedProvider[0].provider;
		if (provider !== '') {
			let params = {
				provider: this.state.selectedProvider[0].provider
			};
			window.SPL_Provider.getGameLoginCredential(params).then(credential => {
				if (this.isMount) {
					this.setState({ name: credential.playId.toLowerCase() });
				}
			});
		}
	};

	render() {
		return (
			<Translation>
				{(t) => (
					<div className='app-changegamepassword '>
						<div className='row d-none d-md-flex justify-content-center'>
							<div className='account-col'>
								<div className='form-content d-flex justify-content-center'>
									<Col xs={12} md={6} lg={5}>
										<div>
											<Row className='form-group'>
												<Col xs={12} md={12} lg={12} className='form-spacing form-group'>
													<div className='d-flex'>
														<Col xs={12} md={4}>
															<label htmlFor='TransferFrom' className='form-label'>
																{t('password:password.form.provider')}
																<span className='text-danger m-r-5'>*</span>
															</label>
														</Col>
														<Col xs={12} md={7}>
															<Select
																placeholder='Provider'
																className='form-control-inner'
																options={this.state.providerList}
																values={this.state.providerList}
																labelField='name'
																valueField='provider'
																key='provider'
																searchable={false}
																onChange={(value) => this.handleProviderChange(value)}
															/>
														</Col>
													</div>
												</Col>

												<Col xs={12} md={12} lg={12} className='form-spacing form-group'>
													<div className='d-flex'>
														<Col xs={12} md={4}>
															<label htmlFor='newpassword' className='form-label'>
																{/* Name*/}
																{t('password:password.form.provider-playid')}
																<span className='text-danger m-r-5'>*</span>
															</label>
														</Col>
														<Col xs={12} md={7}>
															<div className='form-control-inner d-flex'>
																<input disabled className='form-control-transparent w-80 border-0' value={this.state.name} />
																<a
																	className='w-20 d-flex justify-content-end'
																	onClick={() => {
																		this.copy(this.state.name);
																	}}>
																	{/* Joanne change to copy icon */}
																	<div className='d-flex justify-content-center align-items-center'>
																		<AiOutlineCopy className='copy-icon icon-xs'></AiOutlineCopy>
																	</div>
																</a>
															</div>
														</Col>
													</div>
												</Col>

												<Col xs={12} md={12} lg={12} className='form-spacing form-group'>
													<div className='d-flex'>
														<Col xs={12} md={4}>
															<label htmlFor='newpassword' className='form-label'>
																{/* new password */}
																{t('global.form.newpassword')}
																<span className='text-danger m-r-5'>*</span>
															</label>
														</Col>
														<Col xs={12} md={7}>
															<div className='form-control-inner d-flex'>
																<input
																	className='form-control-transparent w-80 border-0'
																	value={this.state.newpassword}
																	onChange={this.handleNewPasswordChange}
																	onBlur={this.validateNewPassword}
																	type={this.state.hiddenNewPassword ? 'password' : 'text'}
																/>
															</div>
															<Col xs={12} sm={12} md={12} lg={12}>
																{this.state.newPasswordErrMsg.map((errMsg, index) => {
																	return (
																		<div key={index} className='invalid-feedback text-danger'>
																			<Trans i18nKey={errMsg}></Trans>
																		</div>
																	);
																})}
															</Col>
														</Col>
													</div>
												</Col>

												<Col xs={12} md={12} lg={12} className='form-spacing form-group'>
													<div className='d-flex'>
														<Col xs={12} md={4}>
															<label htmlFor='confirmpassword' className='form-label'>
																{/* connfirm password */}
																{t('global.form.confirmpassword')} <span className='text-danger m-r-5'>*</span>
															</label>
														</Col>
														<Col xs={12} md={7}>
															<div className='form-control-inner'>
																<input
																	className='border-0'
																	value={this.state.confirmpassword}
																	onChange={this.handleConfirmNewPasswordChange}
																	onBlur={this.validateNewPassword}
																	type='password'
																/>
															</div>

															<Col xs={12} sm={12} md={12} lg={12}>
																{' '}
																<div className='invalid-feedback text-warning'>{this.state.passguide}</div>
																{this.state.status === 'F' && (
																	<div className='invalid-feedback text-danger'>
																		<Trans i18nKey={this.state.message}></Trans>
																	</div>
																)}
																{this.state.status === 'S' && (
																	<div className='invalid-feedback text-success'>
																		<Trans i18nKey={this.state.message}></Trans>
																	</div>
																)}
															</Col>
														</Col>
													</div>
												</Col>

												<Col xs={12} md={12} lg={12} className='d-flex justify-content-end button-group form-spacing'>
													<div className='w-100 d-flex'>
														<Col xs={5} md={4} className='p-l-0 p-r-0'></Col>
														<Col xs={7} md={7} className='justify-content-start'>
															<button type='submit' onClick={this.handleSubmit} className='primary-btn button-submit border-0 button-account'>
																<span>{t('global:entity.action.save')}</span>
															</button>
														</Col>
													</div>
												</Col>
											</Row>
										</div>
									</Col>
								</div>
							</div>
						</div>
						<div className='content-mobile d-block d-md-none p-l-0 p-r-0 content'>
							<div className='row d-flex justify-content-center'>
								<div >
									<div className='d-flex justify-content-center'>
										<Col xs={12} md={12}>
											<div>
												<Row className='form-group'>
													<Col xs={12} md={12} lg={12} className='form-spacing form-group'>
														<div className=''>
															<Col xs={12} md={4}>
																<label htmlFor='TransferFrom' className='chdPassw-text form-label'>
																	{t('password:password.form.provider')}
																	<span className='text-danger m-r-5'>*</span>
																</label>
															</Col>
															<Col xs={12} md={7}>
																<Select
																	placeholder='Provider'
																	className='form-control-inner'
																	options={this.state.providerList}
																	values={this.state.providerList}
																	labelField='name'
																	valueField='provider'
																	key='provider'
																	searchable={false}
																	onChange={(value) => this.handleProviderChange(value)}
																/>
															</Col>
														</div>
													</Col>

													<Col xs={12} md={12} lg={12} className='form-spacing form-group'>
														<div className=''>
															<Col xs={12} md={4}>
																<label htmlFor='newpassword' className='chdPassw-text form-label'>
																	{/* Name*/}
																	{t('password:password.form.provider-playid')}
																	<span className='text-danger m-r-5'>*</span>
																</label>
															</Col>
															<Col xs={12} md={7}>
																<div className='form-control-inner d-flex'>
																	<input disabled className='form-control-transparent w-80 border-0' value={this.state.name} />
																	<a
																		className='w-20 d-flex justify-content-end'
																		onClick={() => {
																			this.copy(this.state.name);
																		}}>
																		{/* Joanne change to copy icon */}
																		<div className='d-flex justify-content-center align-items-center'>
																			<AiOutlineCopy className='copy-icon icon-xs'></AiOutlineCopy>
																		</div>
																	</a>
																</div>
															</Col>
														</div>
													</Col>

													<Col xs={12} md={12} lg={12} className='form-spacing form-group'>
														<div className=''>
															<Col xs={12} md={4}>
																<label htmlFor='newpassword' className='chdPassw-text form-label'>
																	{/* new password */}
																	{t('global.form.newpassword')}
																	<span className='text-danger m-r-5 '>*</span>
																</label>
															</Col>
															<Col xs={12} md={7}>
																<div className='form-control-inner d-flex'>
																	<input
																		className='form-control-transparent w-80 border-0'
																		value={this.state.newpassword}
																		onChange={this.handleNewPasswordChange}
																		onBlur={this.validateNewPassword}
																		type={this.state.hiddenNewPassword ? 'password' : 'text'}
																	/>
																</div>
																<Col xs={12} sm={12} md={12} lg={12}>
																	{this.state.newPasswordErrMsg.map((errMsg, index) => {
																		return (
																			<div key={index} className='invalid-feedback text-danger'>
																				<Trans i18nKey={errMsg}></Trans>
																			</div>
																		);
																	})}
																</Col>
															</Col>
														</div>
													</Col>

													<Col xs={12} md={12} lg={12} className='form-spacing form-group'>
														<div className=''>
															<Col xs={12} md={4}>
																<label htmlFor='confirmpassword' className='chdPassw-text form-label'>
																	{/* connfirm password */}
																	{t('global.form.confirmpassword')} <span className='text-danger m-r-5'>*</span>
																</label>
															</Col>
															<Col xs={12} md={7}>
																<div className='form-control-inner'>
																	<input
																		className='border-0'
																		value={this.state.confirmpassword}
																		onChange={this.handleConfirmNewPasswordChange}
																		onBlur={this.validateNewPassword}
																		type='password'
																	/>
																</div>

																<Col xs={12} sm={12} md={12} lg={12}>
																	{' '}
																	<div className='invalid-feedback text-warning'>{this.state.passguide}</div>
																	{this.state.status === 'F' && (
																		<div className='invalid-feedback text-danger'>
																			<Trans i18nKey={this.state.message}></Trans>
																		</div>
																	)}
																	{this.state.status === 'S' && (
																		<div className='invalid-feedback text-success '>
																			<Trans i18nKey={this.state.message}></Trans>
																		</div>
																	)}
																</Col>
															</Col>
														</div>
													</Col>
													<Col xs={12} md={12} lg={12} className='d-flex justify-content-end button-group form-spacing'>
														<div className='w-100 d-flex'>
															<Col xs={12} md={12} className='justify-content-start'>
																<button type='submit' onClick={this.handleSubmit} className='primary-btn button-submit border-0'>
																	<span>{t('global:entity.action.save')}</span>
																</button>
															</Col>
														</div>
													</Col>
												</Row>
											</div>
										</Col>
									</div>
								</div>
							</div>
						</div>
					</div>
				)}
			</Translation>
		);
	}
}

export default connect(mapStateToProps)(withTranslation(['password'])(withRouter(ChangeGamePassword)));
