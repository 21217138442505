import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { mapStateToProps } from 'root/redux/selector/selector';
import { useTranslation } from 'react-i18next';

// component / file
import ImageHelper from 'root/pages/Share/image-helper';

// util / controller

// Custom Hook to detect outside clicks
const useOutsideClick = (ref, callback) => {
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (ref.current && !ref.current.contains(event.target)) {
                callback();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref, callback]);
};

const SelectDropdown = (props) => {
    const { customStyle, selectedLabel, enableSVGArrow, promptSelect, placeholderText, spanClass, togglePromptSelect, disabled } = props;
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);
    const { t } = useTranslation(['transaction', 'home', 'settings', 'reports', 'promotion']);

    useOutsideClick(dropdownRef, () => setIsOpen(false));

    const handleDropdownClick = (e) => {
        // commonUtil.dropdownUpdate(e, 'certainElement', props, name);
        if (disabled) {
            return;
        }
        setIsOpen(!isOpen);
    };

    return (
        <div
            id='dropdownBtn'
            className={`standard-select ${disabled ? 'disabled' : ''}`}
            onClick={handleDropdownClick}
            style={customStyle && customStyle}
            ref={dropdownRef}
            disabled={disabled}
        >
            <span className={`${spanClass ? spanClass : ''}`}>{promptSelect ? placeholderText : selectedLabel}</span>

            {enableSVGArrow ? (
                <ImageHelper displaySVG='drop-down-arrow' className={`drop-down-arrow ${isOpen ? 'active' : ''}`} isSVG={true} svgType='SVGElements' />
            ) : (
                <span className={`standard-select-arrow ${isOpen ? 'active' : ''}`} />
            )}

            {isOpen && (
                <div className='standard-select-dropdown sort-type-dropdown'>
                    {promptSelect !== null && promptSelect !== undefined && (
                        <div className={`standard-select-options placeholder ${promptSelect ? 'active' : ''}`} onClick={togglePromptSelect && togglePromptSelect}>
                            {placeholderText ? placeholderText : t('global:global.form.pleaseSelect4')}
                        </div>
                    )}
                    {props.children}
                </div>
            )}
        </div>
    );
};

export default connect(mapStateToProps, null)(withRouter(SelectDropdown));
