import Modal from 'react-modal';
import React from 'react';
import classNames from 'classnames';

export default function Popup({ show, onClose, children, className }) {
    return (
        <Modal
            overlayClassName='standard-popup-overlay'
            className={classNames('standard-popup-modal-container', className)}
            isOpen={show}
            shouldCloseOnOverlayClick={true}
            shouldCloseOnEsc={true}
            onRequestClose={onClose}
        >
            <div className='standard-popup-modal-body'>{children}</div>
        </Modal>
    );
}
