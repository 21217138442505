import React, { Component } from 'react';
import { Translation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Redirect, withRouter } from 'react-router-dom';
import { userAction } from '../../redux/action';
import { mapStateToProps } from '../../redux/selector/selector';
import LoginDesktop_01 from './LoginDesktop_01/021_LoginDesktop_01';

class ConnectedLogin extends Component {
  isMount = true;
  constructor() {
    super();
    this.state = {
      userName: '',
      pass: '',
      redirectToReferrer: false,
    };
  }

  onSuccess = () => {
    // if (this.props.user.isLogin === 1 || this.props.user.isLogin) {
    // 	console.log('success');
    // 	this.setState(() => ({
    // 		redirectToReferrer: true
    // 	}));
    // }
  };

  handleLogin = (e) => {
    e.preventDefault();
    let loginObj = {
      domain: '',
      isAffiliate: false,
      isVipLogin: false,
      language: this.props.language.key,
      merchantCode: window.merchantCode,
      login: this.state.userName,
      password: this.state.pass,
      rememberMe: false,
    };

    this.props.dispatch(userAction.login(loginObj)).then(() => {
      this.props.dispatch(userAction.isAuthenticated()).then(() => {
        this.props.history.push('/myaccount/deposit');
      });
      window.SPL_FingerPrint.checkingFp();
    });
  };

  keyHandleLogin = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      let loginObj = {
        domain: '',
        isAffiliate: false,
        isVipLogin: false,
        language: this.props.language.key,
        merchantCode: window.merchantCode,
        login: this.state.userName,
        password: this.state.pass,
        rememberMe: false,
      };

      this.props.dispatch(userAction.login(loginObj)).then(() => {
        this.props.dispatch(userAction.isAuthenticated()).then(() => {
          this.props.history.push('/myaccount/deposit');
        });
        window.SPL_FingerPrint.checkingFp();
      });
    }
  };

  componentDidMount() {
    this.isMount = true;
    this.onSuccess();
    const { userName, password } = this.props.user;
    this.setState({ userName: userName, pass: password });
  }

  componentWillUnmount() {
    this.isMount = false;
  }

  componentDidUpdate() {
    this.onSuccess();
  }
  getLoginLayout = () => {
    let layout = window.layoutGroup;
    if (["L03"].includes(layout)) {
      return "LoginDesktop_03"
    } else if (["L04"].includes(layout)){
      return "LoginDesktop_04"
    } else if (["L06"].includes(layout)){
      return "LoginDesktop_02"
    } 
    else{
      // default or no special requirement go to else
      return "LoginDesktop_01"
    }

  }
  render() {
    const { from } = this.props.location.state || {
      from: { pathname: '/' },
    };
    // If user was authenticated, redirect her to where she came from.
    if (this.state.redirectToReferrer === true) {
      return <Redirect to={from} />;
    }
    const { userName, pass, redirectToReferrer} = this.state;
    let requireFuntion = {
      userName: userName,
      keyHandleLogin: this.keyHandleLogin,
      pass: pass,
      redirectToReferrer: redirectToReferrer,
      handleLogin: this.handleLogin,
      onSuccess: this.onSuccess,
      setState: this.setState
    }
    return (
      <Translation>
        {(t) => (
          <div className='d-flex align-items-center'>
            {this.getLoginLayout() === 'LoginDesktop_01' && <LoginDesktop_01 requireFuntion={requireFuntion} />}
          </div>
        )}
      </Translation>
    );
  }
}

const LoginDesktop = withRouter(connect(mapStateToProps, null)(withTranslation('mobile')(ConnectedLogin)));

export default LoginDesktop;
