import React, { Fragment } from 'react';
import { userAction } from 'root/redux/action';
import { connect } from 'react-redux';
import { mapStateToProps } from 'root/redux/selector/selector';
import { withRouter } from 'react-router-dom';
import { Translation, withTranslation } from 'react-i18next';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { CSSTransition } from 'react-transition-group';
import { LodashUtils } from 'root/utils/lodash-util';
import Login from '../login/Login';
import Register from '../login/Register';
import Clock from './Clock';
import Language from './Language';
import MenuDrop from './MenuDrop';
import LoginPanel from '../login/LoginPanel';
import Menu from 'root/components/Menu';
import Loading from 'root/components/Loading/Loading';
import SVGElements from 'root/components/SVGElements';
import { walletAction } from 'root/redux/action/wallet.action';
import ImageHelper from 'root/pages/Share/image-helper';

class Header extends React.Component {
    isMount = true;
    constructor(props) {
        super(props);
        this.state = {
            isLogin: false,
            fundIn: false,
            menuIn: false,
            tag: '',
            compo: '',
            count: 0,
            mainWallet: '',
            currency: '',
            account: null,
            brandLogoObj: null,
            loading: false,
            inboxCount: 0,
            notificationCount: 0,
            menus: [],
        };
    }

    componentDidMount() {
        this.isMount = true;
        this.initEvent();
        this.loadMainWallet();
        this.loadCurrency();
        this.identity();
        this.getBrandLogo();
        this.countMsg();
        this.msgId = setInterval(() => {
            this.countMsg();
        }, 180000);
        this.getDisplayMenu();
    }

    componentWillUnmount() {
        this.isMount = false;
        clearInterval(this.msgId);
    }

    componentDidUpdate(prevProps) {
        if (this.props.location.pathname !== prevProps.location.pathname) {
            localStorage.setItem('loginPanel', false);
        }

        if (prevProps.wallet.loadWallet !== this.props.wallet.loadWallet) {
            if (this.props.wallet.loadWallet) {
                this.loadMainWallet();

                setTimeout(() => {
                    this.props.dispatch(walletAction.updateLoadWallet(false));
                }, 500);
            }
        }
    }

    getBrandLogo = () => {
        window.SPL_Content.getBrandLogo().then((res) => {
            this.setState({ brandLogoObj: res });
        });
    };

    initEvent() {
        document.querySelector('#root').addEventListener('click', (e) => {
            if (this.state.fundIn) {
                let stopEventEl = [document.querySelector('.fund-box'), document.querySelector('.fund-spread')];
                for (let index = 0; index < stopEventEl.length; index++) {
                    let child = stopEventEl[index];
                    child = child && child.$el ? child.$el : child;
                    if (child && child.contains(e.target)) return;
                }

                this.setState({ fundIn: false });
            }
        });
    }

    showLoginPanel() {
        const { count } = this.state;

        window.SPL_Member.validateIpDevice('').then((data) => {
            if (data.error !== '') {
                this.props.history.push('/redirect-error?' + data.error);
            } else if (data.error === '') {
                this.props.history.push('/register');
                this.setState({
                    tag: 'register',
                    show: true,
                    compo: Register,
                    count: count + 1,
                });
                localStorage.setItem('loginPanel', true);
            }
        });
    }

    controlMenu = () => {
        this.setState({ menuIn: !this.state.menuIn }, () => {
            if (!window.isAgent && !window.isAffiliate) {
                if (window.SPL_LiteApp.isZT828LiteApp()) {
                    window.SPL_LiteApp.onSideMenuToggle(this.state.menuIn);
                }
            }
        });
    };

    onShow() {
        this.setState((state) => ({
            isLogin: state.isLogin,
            fundIn: !state.fundIn,
        }));
    }

    toggleLogin() {
        const { count } = this.state;
        this.props.history.push('/login');
        this.setState({
            tag: 'login',
            show: true,
            compo: Login,
            count: count + 1,
        });
        localStorage.setItem('loginPanel', true);
    }

    toggleLogout = () => {
        // NOTE: FIX IF NETWORK SLOW API DIDN'T CALL
        this.props.dispatch(userAction.logout()).then(() => {
            window.location.href = '/home';
        });
    };

    onReload() {
        this.setState({ reload: true });

        this.loadMainWallet();
        this.props.dispatch(walletAction.updateLoadWallet(true));

        setTimeout(() => {
            this.setState({ reload: false });
        }, 1000);
    }

    loadMainWallet = () => {
        if (this.props.user.isLogin) {
            window.SPL_Member.getMainWallet().then((mainWallet) => {
                this.setState({
                    mainWallet: mainWallet.data.balance,
                    loading: false,
                });
            });
        } else {
            this.setState({ mainWallet: 0 });
        }
    };

    restoreWallet = () => {
        this.setState({ restoreOnLoad: true, loading: true });
        window.SPL_Transaction.transferAllBackMainWallet()
            .then(() => {
                setTimeout(() => {
                    this.props.dispatch(walletAction.updateLoadWallet(true));
                }, 5000);
            })
            .catch(() => {
                setTimeout(() => {
                    this.props.dispatch(walletAction.updateLoadWallet(true));
                }, 5000);
            });
    };

    navigate(path) {
        this.props.history.push(path);
    }

    loadCurrency() {
        const { currency } = this.props.user.account || { currency: '' };
        this.setState({ currency: currency });
    }

    identity = () => {
        if (this.isMount && this.props.user.isLogin) {
            if (this.props.user.userName !== '') {
                this.setState({ account: this.props.user.userName });
            } else {
                this.setState({
                    account: this.props.user.account.login.toLowerCase(),
                });
            }
        }
    };

    redirectMessaging = (type) => {
        const { screen } = this.props;
        let isNotification = false;

        if (type === 'N') {
            isNotification = true;
        }

        if (screen.viewType === 'web') {
            this.props.history.push({
                pathname: '/myaccount/messaging',
                state: { isNotification: isNotification, msgType: type },
            });
        } else {
            this.props.history.push('/myprofile/messaging');
        }
    };

    getFundItem = (dtLink, mLink) => {
        if (!this.props.screen.isMobile) {
            this.props.history.push(dtLink);
        } else {
            this.props.history.push(mLink);
        }
    };

    countMsg = () => {
        if (this.props.user.isLogin) {
            window.SPL_Member.getUnreadMessageCount().then((data) => {
                this.setState({
                    inboxCount: data.unreadInboxCount,
                    notificationCount: data.unreadNotificationsCount,
                    totalUnreadCount: data.totalUnreadCount,
                });
            });
        }
    };

    getDisplayMenu = () => {
        const { language } = this.props;

        window.SPL_Content.getDisplayMenu(language.countryLanguageKey, 'web').then((data) => {
            this.setState({ menus: data }, () => {
                this.addMenu();
            });
        });
    };

    addMenu = () => {
        const { screen, user, language } = this.props;
        const countryLanguageKey = LodashUtils.get(language, 'countryLanguageKey', '');
        const currencyLang = LodashUtils.get(language, 'currencyLang', '');
        let menu = this.state.menus;

        for (let i = 0; i < menu.length; i++) {
            if (menu[i].sref === 'home') {
                menu[i].route = '/home';
            }
        }

        let setting = this.props.portal.settings;
        if (setting && setting.sponsorshipUrl && setting.sponsorshipUrl[countryLanguageKey]) {
            menu.push({
                id: 24,
                name: 'Sponsorship',
                sref: 'sponsorship',
                class: 'mb-only',
                content: 'global:global.menu.sponsorship',
                imageMenu: '/public/html/images/drawer_img/sponsorship.png',
                imageMenu_ov: '/public/html/images/drawer_img/sponsorship-ov.png',
                route: '/sponsorship',
            });
        }

        if (setting && setting.menuSportImage && setting.menuSportImage[screen.viewType]) {
            for (let i = 0; i < menu.length; i++) {
                if (menu[i].name === 'Sportsbook') {
                    menu[i].imageMenuSvg = setting.menuSportImage[screen.viewType];
                }
            }
        }

        const ignoreMenuHOC = (name) => (menu) => LodashUtils.toLower(menu.name) !== LodashUtils.toLower(name);

        if (setting && setting.hideRaffleDraw && setting.hideRaffleDraw) {
            menu = menu.filter(ignoreMenuHOC('RAFFLE'));
        }

        const hideCockFight = !LodashUtils.isEqual(currencyLang, 'MYR') || !LodashUtils.get(user, 'isLogin');
        if (hideCockFight) {
            menu = menu.filter(ignoreMenuHOC('Cockfight'));
        }

        this.setState({ menus: menu });
    };

    goToPage = (route) => {
        const { history, language, portal } = this.props;
        if (route === '/slots') {
            history.push({
                pathname: '/slots',
                state: { slotBrand: 'nextspin' },
            });
            return;
        } else if (route === '/games') {
            history.push('/games/KM');
            return;
        } else if (route === '/sponsorship') {
            let key = language.countryLanguageKey;
            let setting = portal.settings;
            if (setting && setting.sponsorshipUrl && setting.sponsorshipUrl[key]) {
                window.open(setting.sponsorshipUrl[key], '_blank');
                return;
            }
        }

        history.push(route);
    };

    render() {
        const { brandLogoObj } = this.state;
        const { user, screen } = this.props;
        const { isMobile } = screen;

        return (
            <Translation>
                {(t) => (
                    <div ref={(node) => (this.node = node)}>
                        {this.state.loading && <Loading />}

                        <div className='bg-header-top'>
                            <div className='content-container'>
                                <div className='header-top'>
                                    <div className='header-timeLang-box'>
                                        <Clock />
                                        <Language />
                                    </div>

                                    {user.isLogin && isMobile && (
                                        <div className='user-info'>
                                            <span className='header-title deposit-btn' onClick={() => this.getFundItem('/myaccount/deposit', '/account/deposit')}>
                                                {t('global:global.sidebar.funds.deposit', 'DEPOSIT')}
                                            </span>

                                            <div className='message'>
                                                <SVGElements className='icon-message pointer' name='message-icon' onClick={() => this.redirectMessaging('I')} />

                                                {this.state.totalUnreadCount > 0 && (
                                                    <div className='msg-count-box'>
                                                        <div className='tag'>{this.state.totalUnreadCount}</div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    )}

                                    <div className={['header-nav-tools', user.isLogin ? 'logined' : null].join(' ')}>
                                        {!isMobile && (
                                            <Fragment>
                                                {user.isLogin ? (
                                                    <div className='wallet-box'>
                                                        <div className='wallet'>
                                                            <div className='reload-box'>
                                                                <span className='text-yellow'>{t('global:global.form.mainWallet', 'Main Wallet')}</span>
                                                                <p>
                                                                    <span className='number'>
                                                                        {this.state.currency} {window.SPL_Other.formatAmount(this.state.mainWallet)}
                                                                    </span>
                                                                    <SVGElements
                                                                        className={`icon-reload ${this.state.reload && 'start'}`}
                                                                        name='reload-icon'
                                                                        onClick={this.onReload.bind(this)}
                                                                    />
                                                                </p>
                                                            </div>

                                                            <div className='restore-box' onClick={() => this.restoreWallet()}>
                                                                <SVGElements className='icon-restore' name='restore-icon' />
                                                                <span className='text-yellow'>{t('settings:settings.transferwalletv2', 'Restore')}</span>
                                                            </div>

                                                            <div className='tool-box'>
                                                                <div className='user-info'>
                                                                    <div className='message'>
                                                                        <SVGElements
                                                                            className='icon-message pointer'
                                                                            name='message-icon'
                                                                            onClick={() => this.redirectMessaging('I')}
                                                                        />

                                                                        {this.state.totalUnreadCount > 0 && (
                                                                            <div className='msg-count-box'>
                                                                                <div className='tag'>{this.state.totalUnreadCount}</div>
                                                                            </div>
                                                                        )}

                                                                        <div className='headerMsg-box'>
                                                                            <span onClick={() => this.redirectMessaging()}>
                                                                                {t('settings:settings.profiletab.messages', 'Messages')}
                                                                                {this.state.totalUnreadCount > 0 ? (
                                                                                    <Fragment> ({this.state.totalUnreadCount})</Fragment>
                                                                                ) : this.state.totalUnreadCount > 99 ? (
                                                                                    <Fragment> (99+)</Fragment>
                                                                                ) : (
                                                                                    <Fragment> (0)</Fragment>
                                                                                )}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <span className='split'>/</span>
                                                                <span className='fund-box' onClick={() => this.onShow()}>
                                                                    <SVGElements className='icon-fund' name='fund-icon' />
                                                                    <span className='text-yellow'>{t('global:global.menu.account.funds', 'Fund')}</span>

                                                                    <CSSTransition classNames='rotate180' in={this.state.fundIn} timeout={300}>
                                                                        <SVGElements className='icon-arrow-down' name='arrow-down-icon' />
                                                                    </CSSTransition>

                                                                    <CSSTransition classNames='spread' in={this.state.fundIn} timeout={300} unmountOnExit>
                                                                        <ul className='fund-spread'>
                                                                            <li onClick={() => this.getFundItem('/myaccount/deposit', '/account/deposit')}>
                                                                                <span className='icon-box'>
                                                                                    <SVGElements className='icon-deposit' name='deposit-icon' />
                                                                                </span>
                                                                                <span>{t('global:global.sidebar.funds.deposit', 'DEPOSIT')}</span>
                                                                            </li>
                                                                            <li onClick={() => this.getFundItem('/myaccount/transfer', '/account/transfer')}>
                                                                                <span className='icon-box'>
                                                                                    <SVGElements className='icon-transfer' name='transfer-icon' />
                                                                                </span>
                                                                                <span>{t('global:global.sidebar.funds.transfer', 'TRANSFER')}</span>
                                                                            </li>
                                                                            <li onClick={() => this.getFundItem('/myaccount/withdraw', '/account/withdraw')}>
                                                                                <span className='icon-box'>
                                                                                    <SVGElements className='icon-withdraw' name='withdraw-icon' />
                                                                                </span>
                                                                                <span>{t('global:global.sidebar.funds.withdraw', 'WITHDRAW')}</span>
                                                                            </li>
                                                                            <li onClick={() => this.getFundItem('/myaccount/report', '/account/report')}>
                                                                                <span className='icon-box'>
                                                                                    <SVGElements className='icon-report' name='history-icon' />
                                                                                </span>
                                                                                <span>{t('global:global.sidebar.funds.history', 'HISTORY')}</span>
                                                                            </li>
                                                                        </ul>
                                                                    </CSSTransition>
                                                                </span>
                                                            </div>
                                                        </div>

                                                        <span className='header-title deposit-btn' onClick={() => this.getFundItem('/myaccount/deposit', '/account/deposit')}>
                                                            {t('global:global.sidebar.funds.deposit', 'DEPOSIT')}
                                                        </span>
                                                        <span className='header-logout' onClick={() => this.toggleLogout()}>
                                                            {t('global:global.menu.account.logout', 'LOGOUT')}
                                                        </span>
                                                    </div>
                                                ) : (
                                                    <Fragment>
                                                        <span className='header-title register-btn' onClick={() => this.showLoginPanel()}>
                                                            {t('global:global.menu.account.register')}
                                                        </span>
                                                        <span className='header-title login-btn' onClick={() => this.toggleLogin()}>
                                                            {t('global:global.menu.account.login')}
                                                        </span>
                                                    </Fragment>
                                                )}

                                                <SVGElements className='icon-game-menu' name='menu-icon' onClick={() => this.controlMenu()} />
                                            </Fragment>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='bg-header-bottom'>
                            <div className='content-container'>
                                <div id='header-bottom-container' className='header-bottom'>
                                    <div className='header-logo-box'>
                                        {brandLogoObj && (
                                            <LazyLoadImage
                                                className='icon-logo'
                                                src={isMobile ? brandLogoObj.brandLogoMobile : brandLogoObj.brandLogo}
                                                width="100%"
                                                height="100%"
                                                onClick={() =>
                                                    this.props.history.push({
                                                        pathname: '/home',
                                                        state: {
                                                            disableHomeAnnouncement: true,
                                                        },
                                                    })
                                                }
                                            />
                                        )}
                                    </div>

                                    <img className='header-separator-triangle-box' src='/public/html/images/header-bottom-separator.png'></img>

                                    {!isMobile ? (
                                        <div className='header-menu-box'>
                                            {this.state.menus.map((info) => (
                                                <span
                                                    key={info.name}
                                                    className={`menu-name ${this.props.history.location.pathname.includes(info.route) ? 'current' : ''}`}
                                                    onClick={() => this.goToPage(info.route)}
                                                >
                                                    {info.imageMenuSvg ? <ImageHelper displayImg={info.imageMenuSvg} className='fifa-icon' /> : t(info.content)}
                                                </span>
                                            ))}
                                        </div>
                                    ) : (
                                        <div className={['header-nav-tools', user.isLogin ? 'logined' : null].join(' ')}>
                                            {user.isLogin ? (
                                                <div className='wallet-box'>
                                                    <div className='wallet'>
                                                        <div className='reload-box'>
                                                            <span className='text-yellow'>{t('global:global.form.mainWallet', 'Main Wallet')}</span>
                                                            <p>
                                                                <span className='number'>
                                                                    {this.state.currency} {window.SPL_Other.formatAmount(this.state.mainWallet)}
                                                                </span>
                                                                <SVGElements
                                                                    className={`icon-reload ${this.state.reload && 'start'}`}
                                                                    name='reload-icon'
                                                                    onClick={this.onReload.bind(this)}
                                                                />
                                                            </p>
                                                        </div>

                                                        <div className='tool-box'>
                                                            <div className='restore-box' onClick={() => this.restoreWallet()}>
                                                                <SVGElements className='icon-restore' name='restore-icon' />
                                                                <span className='text-yellow'>{t('settings:settings.transferwalletv2', 'Restore')}</span>
                                                            </div>

                                                            <span className='split'>/</span>
                                                            <span className='fund-box' onClick={() => this.onShow()}>
                                                                <SVGElements className='icon-fund' name='fund-icon' />
                                                                <span className='text-yellow'>{t('global:global.menu.account.funds', 'Fund')}</span>

                                                                <CSSTransition classNames='rotate180' in={this.state.fundIn} timeout={300}>
                                                                    <SVGElements className='icon-arrow-down' name='arrow-down-icon' />
                                                                </CSSTransition>

                                                                <CSSTransition classNames='spread' in={this.state.fundIn} timeout={300} unmountOnExit>
                                                                    <ul className='fund-spread'>
                                                                        <li onClick={() => this.getFundItem('/myaccount/deposit', '/account/deposit')}>
                                                                            <span className='icon-box'>
                                                                                <SVGElements className='icon-deposit' name='deposit-icon' />
                                                                            </span>
                                                                            <span>{t('global:global.sidebar.funds.deposit', 'DEPOSIT')}</span>
                                                                        </li>
                                                                        <li onClick={() => this.getFundItem('/myaccount/transfer', '/account/transfer')}>
                                                                            <span className='icon-box'>
                                                                                <SVGElements className='icon-transfer' name='transfer-icon' />
                                                                            </span>
                                                                            <span>{t('global:global.sidebar.funds.transfer', 'TRANSFER')}</span>
                                                                        </li>
                                                                        <li onClick={() => this.getFundItem('/myaccount/withdraw', '/account/withdraw')}>
                                                                            <span className='icon-box'>
                                                                                <SVGElements className='icon-withdraw' name='withdraw-icon' />
                                                                            </span>
                                                                            <span>{t('global:global.sidebar.funds.withdraw', 'WITHDRAW')}</span>
                                                                        </li>
                                                                        <li onClick={() => this.getFundItem('/myaccount/report', '/account/report')}>
                                                                            <span className='icon-box'>
                                                                                <SVGElements className='icon-report' name='history-icon' />
                                                                            </span>
                                                                            <span>{t('global:global.sidebar.funds.history', 'HISTORY')}</span>
                                                                        </li>
                                                                    </ul>
                                                                </CSSTransition>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : (
                                                <Fragment>
                                                    <span className='header-title register-btn' onClick={() => this.showLoginPanel()}>
                                                        {t('global:global.menu.account.register')}
                                                    </span>
                                                    <span className='header-title login-btn' onClick={() => this.toggleLogin()}>
                                                        {t('global:global.menu.account.login')}
                                                    </span>
                                                </Fragment>
                                            )}
                                            <SVGElements className='icon-game-menu' name='menu-icon' onClick={() => this.controlMenu()} />
                                        </div>
                                    )}
                                </div>

                                {/* mobile dummy container which only to push a space to show the member wallet control */}
                                {user.isLogin && <div className='header-bottom-mobile' style={{ display: 'none' }}></div>}
                            </div>
                        </div>

                        {isMobile &&
                            ['/language', '/favourite', '/promotion', '/raffledraw-history', '/contact', '/aboutus', '/terms', '/cmd368', '/m8'].some((pathName) =>
                                this.props.history.location.pathname.includes(pathName)
                            ) && <Menu />}

                        <LoginPanel tag={this.state.tag} show={this.state.show} compo={this.state.compo} count={this.state.count} brandLogoObj={brandLogoObj} />
                        <MenuDrop menuIn={this.state.menuIn} toggleShow={this.controlMenu} />
                    </div>
                )}
            </Translation>
        );
    }
}

export default connect(mapStateToProps)(withTranslation(['message', 'global'])(withRouter(Header)));
