export const fixedItems = [
  {
    label: 'Allbet',
    svgname: 'bonus',
    amount: '0.00'
  },

  {
    label: 'Spadegaming',
    svgname: 'bonus',
    amount: '999.00'
  }
];

export const turnoverAmount = ['5,999,999.00'];
export const turnoverItem = [
  {
    title: 'Playtech',
    amount: '0.00'
  },
  {
    title: 'GamePlay',
    amount: '0.00'
  },
  {
    title: "Play'n Go",
    amount: '0.00'
  },
  {
    title: 'GD Gaming',
    amount: '0.00'
  },
  {
    title: 'Dream Gaming',
    amount: '0.00'
  }
];
export const userAccount = ['KELVIN TAN CHOON HAO'];
export const mbb = ['/public/html/images/bank_img/mbb.png'];
export const cimb = ['/public/html/images/bank_img/cimb.png'];
export const pbe = ['/public/html/images/bank_img/pbe.png'];
export const rhb = ['/public/html/images/bank_img/rhb.png'];
export const hlb = ['/public/html/images/bank_img/hlb.png'];
export const amb = ['/public/html/images/bank_img/cimb.png'];
export const uob = ['/public/html/images/bank_img/uob.png'];
export const rakyat = ['/public/html/images/bank_img/cimb.png'];
export const ocbc = ['/public/html/images/bank_img/ocbc.png'];
export const hsbc = ['/public/html/images/bank_img/hsbc.png'];
export const islam = ['/public/html/images/bank_img/islam.png'];
export const affin = ['/public/html/images/bank_img/affin.png'];
export const alliance = ['/public/html/images/bank_img/alliance.png'];
export const bsn = ['/public/html/images/bank_img/bsn.png'];
export const citi = ['/public/html/images/bank_img/citi.png'];
export const scb = ['/public/html/images/bank_img/scb.png'];
export const bankOn = ['/public/html/images/bank_img/bank_on.png'];