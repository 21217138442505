import React from 'react';
import ReactFlagsSelect from 'root/components/Form/Dropdown/ReactFlagSelect';

class FlagSelect extends React.Component {
	telCodeProps = {};
	constructor(props) {
		super(props);
		this.state = {
			telCodeProps: {}
		};
	}

	componentDidMount() {
		this.getTelCodePropsForFlags(this.props.telCodeList);
	}

	componentDidUpdate(prevProps) {
		if (prevProps.telCodeList !== this.props.telCodeList) {
			this.getTelCodePropsForFlags(this.props.telCodeList);
		}
	}

	getTelCodePropsForFlags(telCodeObjList) {
		let countryList = [];
		let customLabels = {};

		for (let i = 0; i < telCodeObjList.length; i++) {
			let telCodeObj = telCodeObjList[i];
			countryList.push(telCodeObj.country);
			customLabels[telCodeObj.country] = `+${telCodeObj.telCode}`;
		}

		let telCodeProps = {
			countryList: countryList,
			customLabels: customLabels
		};

		this.setState({telCodeProps: telCodeProps});
	}

	render() {
		return (
			<div className={`flag-select-section popup-telcode ${this.props.disabled ? 'disabled' : ''}`}>
				<ReactFlagsSelect
					countries={this.state.telCodeProps.countryList}
					customLabels={this.state.telCodeProps.customLabels}
					countryCode={this.props.countryCode}
					onSelect={this.props.onSelect}
          disabled={this.props.disabled}
				/>
			</div>
		);
	}
}

export default FlagSelect;
