import classnames from 'classnames';
import moment from 'moment';
import 'rc-datetime-picker/dist/picker.css';
import React, { Fragment } from 'react';
import { Translation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import SmallLoading from '../../../components/Loading/SmallLoading';
import { mapStateToProps } from '../../../redux/selector/selector';
import QuickPay from '../../08Deposit/084_QuickPay';
import OnlineTransfer from '../../08Deposit/085_OnlineTransfer';
import BankApps from '../../08Deposit/086_BankApps';
import Crypto from '../../08Deposit/087_Crypto';
import QRPay from '../../08Deposit/088_QRPay';
import EWalletApps from '../../08Deposit/088_QRPayV2';
import QRPayV2 from '../../08Deposit/088_QRPayV2';
import P2P from '../../08Deposit/089_P2P';
import BankQr from '../../08Deposit/bank-qr/bank-qr';
import { bankAppIcon, fpy, cryptoIcon, depositTabs } from '../../16MyAccount/161_MyAccount_Items_Data';
import depositMaterial from '../../08Deposit/deposit-method';
import SVGElements from 'root/components/SVGElements';
import commonUtil from 'root/utils/common-util';
import Select from 'react-dropdown-select';
import { trans_massageHideAddBank } from 'root/utils/transaction-util';
import ImageHelper from 'root/pages/Share/image-helper';
import { trans_handlePGDepositCountDown } from 'root/utils/transaction-util';
import notification from 'root/utils/notification';
import { gu_getNestedValue } from 'root/utils/general-util';
import AuthSettings from '@components/molecules/auth-settings/auth-settings';
import { MODULE_TYPE as MT } from '@constants';

const IMG_ALT_STRING = 'Bank Transfer';

class Deposit extends React.Component {
    isMount = true;
    constructor(props) {
        super(props);
        let currentDate = new Date();
        let currentDateTime = moment(Date.now()).format('YYYY-MM-DD hh:mm a');
        this.toggle = this.toggle.bind(this);
        this.state = {
            activeTab: 'QP',
            activeTabActualCode: 'QP',
            iconLoading: false,
            depositDetails: null,
            selectedMethod: [],
            selectedBank: [],
            amount: '',
            amountErrMsg: [],
            disabledDepositButton: true,
            limitAmount: '',
            currency: '',
            referenceCode: '',
            depositOptions: [],
            bankList: [],
            bankNameErrMsg: [],
            selectedDepositChannel: [],
            receiptImg: [],
            depositDate: moment(currentDateTime, 'YYYY-MM-DD hh:mm a'),
            depositDateString: moment(currentDateTime, 'YYYY-MM-DD hh:mm a').format('YYYY-MM-DDTHH:mm'),
            selectedDepositBank: null,
            radioValue: 0,
            status: '',
            message: '',
            moment: moment(),
            histories: [],
            start: new Date(new Date().setMonth(currentDate.getMonth() - 1)),
            end: currentDate,
            currentPage1: 1,
            todosPerPage1: 5,
            showLoading: false,
            newMethodList: [],
            bankAppsEnabled: false,
            getTncReferral: false,
            isFirstTimeInit: true,
            cryptoEnabled: false,

            //deposit new structure
            depositApiJson: null,
            filteredDepositApiJson: null,
            displayTab: [],
            displayTabData: [],
            defaultTab: null,
            customTranslationObj: {},

            compSettings: {},
            paymentGatewaySetting: null,

            paymentOption: [],
            paymentTranslation: [],
            selectedPaymentOption: 0,

            // bank offline
            showBankStatus: false,
            bankStatusModal: false,
            bankMaintenanceDisplay: [],
            depositSubmitCountdown: null,
            depositAmountOptions: [],

            memberDecimalSettings: {},
        };
    }

    initPaymentGatewaySetting() {
        window.SPL_JsonSettings.getPaymentGatewaySetting((paymentGatewaySetting) => {
            this.setState({ paymentGatewaySetting });
        });
    }

    toggle(tab) {
        const { depositApiJson, displayTab } = this.state;
        let depositAllSettings = this.state.depositAllSettings;

        let _filteredDepositApiJson = null;
        let code = null;
        this.setState({ isSubLoading: true }, () => {
            if (depositApiJson) {
                code = displayTab.find((e) => e.actualCode === tab).code;
                if (code) {
                    _filteredDepositApiJson = { channel: gu_getNestedValue(depositApiJson, `${tab}.bank`) || depositApiJson[tab], optionCode: code };
                }
            }
            if (depositAllSettings) {
                depositAllSettings.hideAddBankAccount = trans_massageHideAddBank(this, 'deposit', code);
            }

            if (this.state.activeTabActualCode !== tab) {
                this.setState({ activeTab: code, activeTabActualCode: tab, filteredDepositApiJson: _filteredDepositApiJson, depositAllSettings, isSubLoading: false }, () => {
                    this.setState({
                        amount: '',
                        errMsgLimitAmount: [],
                        amountErrMsg: [],
                    });
                });

                // if (this.props.screen.viewType === 'web') {
                //   if (tab === 'quickPay') {
                //     this.props.history.push('/myaccount/deposit');
                //   } else if (tab === 'onlinetransfer') {
                //     this.props.history.push('/myaccount/deposit');
                //   }
                // }
            } else {
                this.setState({ isSubLoading: false });
            }
        });
    }

    // setDepositOptions() {
    //   const { t } = this.props;
    //   let depositOptions = [
    //     { label: t('settings:settings.dropdown.onlineBanking'), value: 'O' },
    //     { label: t('settings:settings.dropdown.cashDepositMachine'), value: 'D' },
    //     { label: t('settings:settings.dropdown.atmTransfer'), value: 'A' },
    //   ];
    //   this.setState({ depositOptions: depositOptions });
    // }

    handleMethodChange = (value) => {
        value = [value];
        this.setState({ selectedMethod: value, isFirstTimeInit: false }, () => {
            this.checkError();
        });
    };

    handleBankChange = (value) => {
        this.setState({ selectedBank: value }, () => {
            this.checkError();
        });
    };

    handleDepositChannelChange = (value) => {
        this.setState({ selectedDepositChannel: value, isFirstTimeInit: false }, () => {
            const { selectedDepositChannel, receiptImg } = this.state;
            if (
                selectedDepositChannel.length > 0 &&
                ((receiptImg.length <= 0 && selectedDepositChannel[0].value === 'O') ||
                    (receiptImg.length <= 0 && selectedDepositChannel[0].value === 'D') ||
                    (receiptImg.length <= 0 && selectedDepositChannel[0].value === 'A'))
            ) {
                this.setState({
                    status: 'F',
                    message: 'transaction:transaction.reload.receiptIsRequired',
                });
            }

            this.checkError();
        });
    };

    handleReceiptImageChange = (event) => {
        if (event && event.target && event.target.files.length > 0) {
            this.setState(
                {
                    receiptImg: URL.createObjectURL(event.target.files[0]),
                    receiptImgFile: event.target.files[0],
                },
                () => {
                    if (this.state.receiptImg.length > 0) {
                        this.setState({ status: '', message: '' });
                    }

                    this.checkError();
                },
            );
        }
    };

    handlereferenceCodeChange = (event) => {
        this.setState({ referenceCode: event.target.value }, () => {
            this.checkError();
        });
    };

    handleDepositDateChanged = (date, dateString) => {
        this.setState(
            {
                depositDate: date,
                depositDateString: date.format('YYYY-MM-DDTHH:ss'),
            },
            () => {
                this.checkError();
            },
        );
    };

    onDepositBankAcc = (index) => {
        this.setState(
            {
                selectedDepositBank: this.state.merchantBankList[index],
                radioValue: index,
            },
            () => {
                this.checkError();
            },
        );
    };

    componentDidMount() {
        this.initPaymentGatewaySetting();
        this.loadMerchantBank();
        this.loadDepositOptions();
        this.getMemberDepositWithdrawDecimalSettings();

        const loadPaymentOption = () => {
            return (callback) => {
                this.loadPaymentOptionCategory().then(() => {
                    callback();
                });
            };
        };

        const initCompSettings = () => {
            return (callback) => {
                this.initCompSettings().then(() => {
                    callback();
                });
            };
        };

        const finalCallback = () => {
            this.loadDepositDetails();
            this.loadCryptoDeposit();
        };

        window.SPL_Other.spawnCallback(loadPaymentOption, initCompSettings)(finalCallback);
        // this.setDepositOptions();
        // this.loadBankAccounts();
        // this.loadHistories();
        // this.loadBankAccountsForDeposit();

        if (this.props.screen.viewType === 'web' && this.props.location.pathname === '/deposit') {
            this.props.history.push('/myaccount/deposit');
        } else if (this.props.screen.viewType === 'web' && this.props.location.pathname === '/onlinetransfer') {
            this.props.history.push('/myaccount/deposit');
        } else if (this.props.screen.viewType === 'web' && this.props.location.pathname === '/quickpay') {
            this.props.history.push('/myaccount/deposit');
        } else if (this.props.screen.viewType === 'mobile' && this.props.location.pathname === '/myaccount/deposit') {
            // to able to back to home when switch from desktop deposit page to mobile deposit page
            this.props.history.push('/home');
            this.props.history.push('/fund');
            this.props.history.push('/deposit');
        }
        commonUtil.loadCustomTranslation('Deposit', this);
    }

    componentWillUnmount() {
        this.isMount = false;
    }

    componentDidUpdate(prevProps) {
        if (prevProps != this.props) {
            // this.loadBankAccounts();
            this.getPathForActiveTab();
        }
    }

    loadDepositOptions() {
        window.SPL_Transaction.loadDepositOptions().then((data) => {
            this.setState({ depositAmountOptions: data });
        });
    }

    initCompSettings = () => {
        const { filteredDepositApiJson } = this.state;
        const paymentCode = filteredDepositApiJson && filteredDepositApiJson.optionCode;

        return new Promise((resolve) => {
            // Step 1: Standard way to get from portal settings
            let depositSettings = commonUtil.getSettingFromPortalSettings({
                vm: this,
                settingName: 'depositSettings',
                notViewType: true,
            });

            // Step 2: Massage again to get the specific data you need (Skip if not needed)

            let compSettings = {
                depositPagesSettings: depositSettings,
                customCountdown: depositSettings?.customCountdown,
            };

            this.setState({ compSettings }, () => {
                window.SPL_Content.getBankNameJson(this.props);
                trans_handlePGDepositCountDown(this, false, compSettings.customCountdown, paymentCode, Date.now().valueOf(), { disabledDepositButton: true });
                resolve();
            });
        });
    };

    loadMerchantBank() {
        window.SPL_Transaction.loadMerchantBank(this.props).then((merchantBankList) => {
            this.setState({ merchantBankList: merchantBankList }, () => {
                this.getBankStatus();
            });
        });
    }

    loadDepositOptions() {
        window.SPL_Transaction.loadDepositOptions().then((data) => {
            this.setState({ depositAmountOptions: data });
        });
    }

    getPathForActiveTab = () => {
        if (this.props.screen && this.props.screen.viewType && this.props.screen.viewType === 'web') {
            if (window.location.pathname.includes('onlinetransfer')) {
                this.toggle('B');
            } else if (window.location.pathname.includes('quickpay')) {
                this.toggle('QP');
            }
        }
    };

    loadDepositDetails = () => {
        this.setState({ isLoading: true });
        const { account } = this.props.user;
        let currency = '';
        let activeTab = 'QP';

        if (account) {
            currency = account.currency;
        }

        const { depositPagesSettings } = this.state.compSettings;
        if (depositPagesSettings && depositPagesSettings.enableJsonApi) {
            this.loadNewDepositLandingStructure();
        } else {
            if (Object.keys(depositMaterial.depositDetails).length > 0) {
                if (!depositMaterial.getDepositDetails().defaultMethod) {
                    activeTab = 'B';
                }

                this.setState(
                    {
                        depositDetails: depositMaterial.getDepositDetails(),
                        selectedMethod: [depositMaterial.getDepositDetails().defaultMethod],
                        isLoading: false,
                        activeTab: activeTab,
                    },
                    () => {
                        this.validateDepositAmount();

                        let method = this.state.depositDetails.methodList;
                        let list = this.state.newMethodList;
                        let list2 = [];

                        if (method.length > 0) {
                            for (let i = 0; i < method.length; i++) {
                                list.push(method[i]);
                            }

                            list.push({
                                displayName: this.props.t('global:global.menu.account.bankTransfer'),
                            });
                            this.setState({ newMethodList: list }, () => {
                                this.loadBankAppsEnabled();
                            });
                        } else {
                            list.push({
                                displayName: this.props.t('global:global.menu.account.bankTransfer'),
                            });
                            list2.push({
                                displayName: this.props.t('global:global.menu.account.bankTransfer'),
                            });

                            this.setState({ newMethodList: list, selectedMethod: list2 }, () => {
                                this.loadBankAppsEnabled();
                            });
                        }
                    },
                );
            } else {
                window.SPL_Transaction.loadDepositDetails(currency, this.state.activeTab, false).then((depositDetails) => {
                    if (this.isMount) {
                        if (!depositDetails.defaultMethod) {
                            activeTab = 'B';
                        }

                        if (depositDetails.qrPayList && depositDetails.qrPayList.length > 0) {
                            let qrpay = {
                                name: 'transaction:transaction.deposit.cashdeposit.qrpay',
                                methodKey: 'qrpay',
                                isQRPay: true,
                            };
                            depositDetails.methodList.push(qrpay);
                        }

                        this.setState(
                            {
                                depositDetails: depositDetails,
                                selectedMethod: [depositDetails.defaultMethod],
                                isLoading: false,
                                activeTab: activeTab,
                            },
                            () => {
                                this.validateDepositAmount();
                                depositMaterial.setDepositDetails(depositDetails);

                                let method = this.state.depositDetails.methodList;
                                let list = this.state.newMethodList;
                                let list2 = [];

                                if (method.length > 0) {
                                    for (let i = 0; i < method.length; i++) {
                                        list.push(method[i]);
                                    }

                                    list.push({
                                        displayName: this.props.t('global:global.menu.account.bankTransfer'),
                                    });
                                    this.setState({ newMethodList: list }, () => {
                                        this.loadBankAppsEnabled();
                                    });
                                } else {
                                    list.push({
                                        displayName: this.props.t('global:global.menu.account.bankTransfer'),
                                    });
                                    list2.push({
                                        displayName: this.props.t('global:global.menu.account.bankTransfer'),
                                    });

                                    this.setState(
                                        {
                                            newMethodList: list,
                                            selectedMethod: list2,
                                        },
                                        () => {
                                            this.loadBankAppsEnabled();
                                        },
                                    );
                                }
                            },
                        );
                    }
                });
            }
        }
    };

    getMemberDepositWithdrawDecimalSettings = () => {
        window.SPL_Member.getMemberDepositWithdrawDecimalSettings().then((data) => {
            if (data) {
                this.setState({ memberDecimalSettings: data?.deposit || {} });
            }
        });
    };

    loadNewDepositLandingStructure = () => {
        const { user } = this.props;
        let getMerchantBanksPromise = new Promise((resolve) => {
            window.SPL_Member.getMerchantBanks().then((depositData) => resolve(depositData));
        });
        let getDefaultTabPromise = this.loadDefaultTab();
        let loadDepositRelatedSettingsPromise = new Promise((resolve) => {
            window.SPL_Transaction.loadDepositRelatedSettings(user.account.currency).then((depositSettings) => resolve(depositSettings));
        });

        Promise.all([getMerchantBanksPromise, getDefaultTabPromise, loadDepositRelatedSettingsPromise]).then((result) => {
            const { depositPagesSettings } = this.state.compSettings;
            const { selectedPaymentOption, paymentOption } = this.state;
            let depositApiJson = result[0];
            let defaultTab = result[1];
            let depositAllSettings = result[2];
            let filterOption = [];

            let _displayTab = this.state.displayTab;
            let orderedTabFromApi = depositApiJson ? Object.keys(depositApiJson) : [];
            let newOrderDisplayTab = [];
            for (let i = 0; i < orderedTabFromApi.length; i++) {
                let code = orderedTabFromApi[i];
                if (gu_getNestedValue(depositApiJson, `${code}.type`)) {
                    code = depositApiJson[code]['type'];
                }

                let foundTab = window.SPL_Other.deepCopy(depositTabs.filter((tab) => tab.code === code)[0]);
                if (foundTab) {
                    foundTab.translation = window.SPL_Translation.getTranslatedTransactionMsg(foundTab.code, 'depositTabs');
                    foundTab.actualCode = orderedTabFromApi[i];
                    newOrderDisplayTab.push(foundTab);
                }
            }
            _displayTab = newOrderDisplayTab;

            if (_displayTab && _displayTab.length > 0) {
                _displayTab[0].selected = true;
            }
            // arrange sequence if found setting
            _displayTab = this.customTabArrangement(_displayTab, depositPagesSettings);

            //catogorise deposit tab
            if (paymentOption && paymentOption.length > 0) {
                //to filter empty payment option
                filterOption = this.filteredPaymentDepositTab(paymentOption, _displayTab, selectedPaymentOption);
                _displayTab = filterOption;
            }

            let defaultTabObj = _displayTab.filter((tab) => {
                return tab.key === defaultTab;
            });

            let _activeTab = _displayTab && _displayTab[0] && _displayTab[0].code;
            if (defaultTabObj && defaultTabObj.length > 0) {
                _activeTab = (_displayTab || []).some((tab) => tab && tab.actualCode === defaultTabObj[0].actualCode) && defaultTabObj[0].code;
            }
            let filterDefaultTab = _displayTab && (_displayTab || []).find((tab) => tab.actualCode === _activeTab);
            let defaultTabCode = filterDefaultTab && filterDefaultTab.actualCode;
            let filteredDepositApiJson = {
                channel: gu_getNestedValue(depositApiJson, `${defaultTabCode}.bank`) || (depositApiJson && depositApiJson[defaultTabCode]),
                optionCode: filterDefaultTab && filterDefaultTab.code,
            };
            window.SPL_Content.getBankName(_displayTab, 'option');

            depositAllSettings.hideAddBankAccount = trans_massageHideAddBank(this, 'deposit', defaultTabCode);

            this.setState({
                depositApiJson: depositApiJson,
                filteredDepositApiJson: filteredDepositApiJson,
                depositAllSettings: depositAllSettings,
                activeTab: _activeTab,
                activeTabActualCode: defaultTabCode,
                displayTab: _displayTab,
                displayTabData: newOrderDisplayTab,
                isLoading: false,
                defaultTab: defaultTab,
            });
        });
    };

    customTabArrangement = (displayTab, depositSettings) => {
        if (depositSettings.depositTabOrdering && depositSettings.depositTabOrdering.length > 0) {
            let orderedMenuList = [];
            let nonOrderList = displayTab.filter(function (el) {
                return depositSettings.depositTabOrdering.indexOf(el.id) < 0;
            });

            for (let i = 0; i < depositSettings.depositTabOrdering.length; i++) {
                let menu = displayTab.filter(function (e) {
                    return e.id == depositSettings.depositTabOrdering[i];
                })[0];
                if (menu !== undefined && menu !== null) {
                    orderedMenuList.push(menu);
                }
            }

            if (depositSettings.swapDepositOrderTab) {
                displayTab = nonOrderList.concat(orderedMenuList);
            } else {
                displayTab = orderedMenuList.concat(nonOrderList);
            }
        }
        return displayTab;
    };

    loadDefaultTab = () => {
        const { user } = this.props;
        let currency = user.account.currency;
        return new Promise((resolve) => {
            window.SPL_JsonSettings.getDepositJson(function (settings) {
                if (settings && settings['defaultDepositMethod'] && settings['defaultDepositMethod'][currency] && settings['defaultDepositMethod'][currency] !== '') {
                    resolve(settings['defaultDepositMethod'][currency].toLowerCase());
                } else {
                    resolve();
                }
            });
        });
    };

    // loadMerchantBank = () => {
    //   window.SPL_Transaction.loadMerchantBank().then((merchantBankList) => {
    //     if (this.isMount) {
    //       this.setState({ merchantBankList: merchantBankList, selectedDepositBank: merchantBankList[this.state.radioValue] });
    //     }
    //   });
    // };

    loadBankAppsEnabled = () => {
        const { currency } = this.props.user.account;

        window.SPL_Transaction.loadBankAppsEnabled(currency).then((bankAppsRes) => {
            let bankAppsEnabled = bankAppsRes.bankAppsEnabled;
            let newMethodList = this.state.newMethodList;

            if (bankAppsEnabled) {
                newMethodList.push({ displayName: 'Bank Apps' });
            }

            this.setState({
                bankAppsEnabled: bankAppsEnabled,
                newMethodList: newMethodList,
            });
        });
    };

    loadCryptoDeposit = () => {
        if (this.props.portal.settings.enableCryptoTab) {
            let params = {
                purpose: 'D',
                option: 'CT',
            };

            window.SPL_Transaction.getMerchantBankGroupWithOption(params).then((data) => {
                if (data && data.channelOptions && data.channelOptions.length > 0) {
                    this.setState({ cryptoEnabled: true });
                }
            });
        }
    };

    loadBankAccounts = (callback) => {
        window.SPL_Transaction.loadAllOwnedAndUnOwnedBank().then((data) => {
            let memberBankList = data.memberBankList;

            for (let i = 0; i < memberBankList.length; i++) {
                const memberBank = memberBankList[i];
                memberBank.displayName = `${memberBank.bankName} ${memberBank.accountNumber} ${memberBank.accountHolder}`;
            }

            if (this.isMount) {
                this.setState(
                    {
                        bankList: data.bankList,
                        memberBankList: memberBankList,
                        unOwnedBankList: data.unOwnedBankList,
                    },
                    () => {
                        if (callback) {
                            callback();
                        }
                    },
                );
            }
        });
    };

    handleAmountChange = (value) => {
        if (value) {
            if (value.toString().match(/^(\d*)\.{0,1}(\d){0,2}$/)) {
                this.setState({ amount: value }, () => {
                    this.validateDepositAmount();
                });
            }
        } else {
            this.setState({ amount: '' }, () => {
                this.validateDepositAmount();
            });
        }
    };

    checkError = () => {
        const { activeTab, amountErrMsg, selectedMethod, selectedBank } = this.state;

        if (activeTab === 'QP') {
            if (amountErrMsg.length > 0 || selectedMethod.length <= 0 || selectedBank.length <= 0) {
                this.setState({ disabledDepositButton: true });
            } else {
                this.setState({ disabledDepositButton: false });
            }
        } else {
            const { amount, depositDateString, selectedDepositChannel, selectedDepositBank, receiptImg, selectedBank, referenceCode } = this.state;

            if (
                !amount ||
                selectedDepositChannel.length <= 0 ||
                (selectedBank.length <= 0 && selectedDepositChannel[0].value !== 'D') ||
                !selectedDepositBank ||
                (receiptImg.length <= 0 && selectedDepositChannel[0].value === 'O') ||
                (receiptImg.length <= 0 && selectedDepositChannel[0].value === 'D') ||
                (receiptImg.length <= 0 && selectedDepositChannel[0].value === 'A') ||
                !depositDateString
            ) {
                this.setState({ disabledDepositButton: true });
            } else {
                this.setState({ disabledDepositButton: false });
            }
        }
    };

    handleSubmit(type) {
        const { depositSubmission, t } = this.props;
        const { filteredDepositApiJson, depositSubmitCountdown } = this.state;
        const paymentCode = filteredDepositApiJson && filteredDepositApiJson.optionCode;
        const submissionData = depositSubmission && depositSubmission.submissionData;
        const paymentObj = submissionData && submissionData[paymentCode];

        if (paymentObj && paymentObj.submissionRetryTime > Date.now().valueOf()) {
            notification.showNotification('error', t('transaction:transaction.deposit.resubmitError', { retryTime: depositSubmitCountdown }));
        } else {
            if (type === 'quickpay') {
                this.quickPaySubmit(paymentCode);
            } else {
                this.localPaySubmit();
            }
        }
    }

    validateDepositAmount = () => {
        window.SPL_Transaction.validateDepositAmount(this.state.amount, this.state.depositDetails.depositLimit).then((errMsg) => {
            if (this.isMount) {
                let errMsgLimitAmount = this.getLimitAmountForErrMsg(errMsg[0]);

                this.setState(
                    {
                        amountErrMsg: errMsg,
                        limitAmount: errMsgLimitAmount.limitAmount,
                        currency: errMsgLimitAmount.currency,
                    },
                    () => {
                        this.checkError();
                    },
                );
            }
        });
    };

    getLimitAmountForErrMsg(errMsg) {
        let limitAmount = '';
        let currency = this.props.user.account.currency;

        if (errMsg) {
            if (errMsg.includes('invalidMinAmount')) {
                if (this.state.activeTab === 'QP') {
                    limitAmount = window.SPL_Other.formatAmount(this.state.depositDetails.depositLimit.minLimitQuick);
                } else {
                    limitAmount = window.SPL_Other.formatAmount(this.state.depositDetails.depositLimit.minLimit);
                }
            } else if (errMsg.includes('invalidMaxAmount')) {
                if (this.state.activeTab === 'QP') {
                    limitAmount = window.SPL_Other.formatAmount(this.state.depositDetails.depositLimit.maxLimitQuick);
                } else {
                    limitAmount = window.SPL_Other.formatAmount(this.state.depositDetails.depositLimit.maxLimit);
                }
            }
        }

        return {
            limitAmount: limitAmount,
            currency: currency,
        };
    }

    quickPaySubmit = (paymentCode) => {
        if (this.state.amountErrMsg.length > 0 || this.state.selectedMethod.length <= 0 || this.state.selectedBank.length <= 0) {
            return;
        }

        let method = this.state.selectedMethod[0];
        let selectedBankCode = this.state.selectedBank[0] ? this.state.selectedBank[0].code : null;
        let amount = this.state.amount;
        let preferredWallet = this.state.depositDetails.preferredWalletDetails.preferredWallet;
        let hidePWallet = this.state.depositDetails.preferredWalletDetails.hidePWallet;
        let callBackUrl = '/myaccount/deposit';
        let stateUrl = `/${this.props.langPath}/quickpayredirect`;
        const { customCountdown } = this.state.compSettings;
        const countdown = customCountdown || typeof customCountdown === 'number' ? customCountdown : 30;
        // window.open(stateUrl);

        window.SPL_Transaction.requestDepositOnlineTransfer(
            method,
            selectedBankCode,
            amount,
            preferredWallet,
            hidePWallet,
            stateUrl,
            this.props.language.key,
            callBackUrl,
        ).then((data) => {
            if (data.errorCode) {
                notification.showNotification('error', data.message);
            } else {
                trans_handlePGDepositCountDown(this, true, countdown, paymentCode, Date.now().valueOf(), { disabledDepositButton: false });
            }
        });
    };

    localPaySubmit = () => {
        const { preferredWalletDetails } = this.state.depositDetails;
        const { amount, depositDateString, selectedDepositChannel, selectedDepositBank, receiptImgFile, receiptImg, referenceCode, selectedBank } = this.state;

        if (
            !amount ||
            selectedDepositChannel.length <= 0 ||
            (selectedBank.length <= 0 && selectedDepositChannel[0].value !== 'D') ||
            !selectedDepositBank ||
            (receiptImg.length <= 0 && selectedDepositChannel[0].value === 'O') ||
            (receiptImg.length <= 0 && selectedDepositChannel[0].value === 'D') ||
            (receiptImg.length <= 0 && selectedDepositChannel[0].value === 'A') ||
            !depositDateString
        ) {
            return;
        }

        let preferredWallet = preferredWalletDetails.preferredWallet;
        let hidePWallet = preferredWalletDetails.hidePWallet;
        let depositAmount = amount;
        let depositChannel = selectedDepositChannel[0].value;
        let depositDateTime = depositDateString;
        let merchantBankId = selectedDepositBank.id;
        let memberBankId = (selectedDepositChannel[0].value !== 'D' && selectedBank[0].id) || null;
        let picFile = receiptImgFile;

        // preferredWallet, hidePWallet, merchantBankId, depositAmount, depositChannel, depositDateTime, memberBankId, picFile, referenceCode
        window.SPL_Transaction.localPaySubmit(
            preferredWallet,
            hidePWallet,
            merchantBankId,
            depositAmount,
            depositChannel,
            depositDateTime,
            memberBankId,
            picFile,
            referenceCode,
        ).then((data) => {
            if (this.isMount) {
                if (data.status === 'S') {
                    this.setState({ disabledDepositButton: false });

                    let depositAmount = '';
                    let depositChannel = [];
                    let receiptImgFile = null;
                    let referenceCode = '';
                    let selectedBank = [];
                    this.setState(
                        {
                            status: 'S',
                            message: 'transaction:transaction.deposit.request-success',
                            amount: depositAmount,
                            selectedDepositChannel: depositChannel,
                            receiptImgFile: receiptImgFile,
                            referenceCode: referenceCode,
                            selectedBank: selectedBank,
                            receiptImg: [],
                        },
                        () => {
                            let status = this.state.status;
                            if (status === 'S') {
                                setTimeout(() => {
                                    this.setState({ message: '' });
                                }, 5000);
                            }
                        },
                    );
                } else {
                    this.setState({ disabledDepositButton: false });

                    if (data.code === 'receipt_fail') {
                        this.setState({
                            status: 'F',
                            message: 'transaction:transaction.withdrawal.request-uploadReceiptError',
                        });
                    } else if (data.msg.data.status === 409) {
                        this.setState({
                            status: 'F',
                            message: 'transaction:transaction.withdrawal.request-pending',
                        });
                    } else {
                        this.setState({
                            status: 'F',
                            message: 'transaction:transaction.withdrawal.request-error',
                        });
                    }
                }
            }
        });
    };

    copy(text) {
        window.SPL_Other.copyToClipboard(text);
    }

    openBankDetailModal = () => {
        this.setState({ isOpenBankModal: true });
    };

    selectAddedBank = (bankId) => {
        this.loadBankAccounts(() => {
            let memberBankList = this.state.memberBankList;
            let autoSelectedAddedBank = memberBankList.filter((e) => e.bankId === bankId);

            this.setState({ selectedBank: autoSelectedAddedBank });
        });
    };

    closeBankModal = () => {
        this.setState({ isOpenBankModal: false });
    };

    handleChange = (moment) => {
        this.setState({ moment });
    };

    onDateChanged = (date, type) => {
        if (type === 'start') {
            this.setState({ start: date });
        } else {
            this.setState({ end: date });
        }
    };

    showDetails = (type, history) => {
        this.setState({
            type: type,
            historySelected: history,
            isOpenHistoryDetailsModal: true,
        });
    };

    filteredPaymentDepositTab(option, displayTab, index) {
        if (!option[index] || !displayTab) {
            return displayTab; // Return displayTab if option[index] or displayTab is undefined
        }

        const paymentOption = option[index];
        if (!paymentOption.paymentOption) {
            return displayTab; // Return displayTab if paymentOption.paymentOption is undefined
        }

        const filterAllOptionCode = option
            .filter((d) => d && d.paymentOption)
            .map((d) => Object.keys(d.paymentOption))
            .flat();

        const filteredOption = displayTab.filter((item2) => filterAllOptionCode.includes(item2.actualCode));

        const filterSelectedOption = filteredOption.filter((filteredItem) => Object.keys(paymentOption.paymentOption).includes(filteredItem.actualCode));

        return filterSelectedOption.length ? filterSelectedOption : displayTab;
    }

    toggleLoading = (toggle) => {
        this.setState({ showLoading: toggle });
    };

    closeBankModal = () => {
        this.setState({ isOpenBankModal: false });
    };

    handleMethodSelect = (value) => {
        let name = value[0].displayName;

        if (name.toUpperCase() === 'EEZIEPAY' || name.toUpperCase() === 'HELP2PAY' || name.toUpperCase() === 'FASTPAY' || name.toUpperCase() === 'FASTPAY2') {
            this.toggle('quickPay');
            this.setState({ selectedMethod: value, isFirstTimeInit: false }, () => {
                this.checkError();
            });
        } else if (name === 'Bank Apps') {
            this.toggle('bankApps');
        } else {
            this.toggle('onlinetransfer');
            this.loadBankAccounts();
        }
    };

    getTncReferral = () => {
        this.setState({ getTncReferral: !this.state.getTncReferral });
    };

    renderMethod(methodName) {
        const { selectedMethod, activeTab } = this.state;
        const method = selectedMethod[0];

        let activeClassDeterminer;
        if (method.isQRPay) {
            activeClassDeterminer = method.methodKey === methodName && !['B', 'BA', 'CT'].includes(activeTab);
        } else {
            activeClassDeterminer = method.name === methodName && !['B', 'BA', 'CT'].includes(activeTab);
        }
        const activeClass = activeClassDeterminer ? 'active' : '';

        switch (methodName.toUpperCase()) {
            case 'FASTPAY':
            case 'FASTPAY2':
                return <img src={fpy} alt={IMG_ALT_STRING} className={`fpy ${activeClass}`} />;
            case 'EEZIEPAY':
                return <SVGElements name='ezp-logo' alt={IMG_ALT_STRING} className={`ezpay ${activeClass}`} />;
            case 'HELP2PAY':
                return <SVGElements name='h2p-logo' alt={IMG_ALT_STRING} className={`h2pay ${activeClass}`} />;
            case 'IP4U':
                return <SVGElements name='ip4u-logo' alt={IMG_ALT_STRING} className={`ip4u ${activeClass}`} />;
            case 'YESPAY':
                return <SVGElements name='yespay' alt={IMG_ALT_STRING} className={`yespay ${activeClass}`} />;
            case 'QRPAY':
                return <SVGElements name='qrpay' alt={IMG_ALT_STRING} className={`qrpay ${activeClass}`} />;
            default:
                return null;
        }
    }

    loadPaymentOptionCategory = () => {
        return new Promise((resolve) => {
            window.SPL_Content.loadPaymentOptionCategory(this.props).then((paymentCategory) => {
                this.setState({ paymentOption: paymentCategory }, () => {
                    resolve();
                });
            });
        });
    };

    paymentOptionSelection(option) {
        const { paymentOption, defaultTab, depositApiJson } = this.state;
        let { displayTabData, depositAllSettings } = this.state;
        let selectedPaymentOption = 0;

        if (option && paymentOption) {
            for (let i = 0; i < paymentOption.length; i++) {
                for (let key in option) {
                    if (option[key].type === paymentOption[i].type) {
                        selectedPaymentOption = i;
                    }
                }
            }
        }
        displayTabData = this.filteredPaymentDepositTab(paymentOption, displayTabData, selectedPaymentOption);
        let _activeTab = displayTabData.some((tab) => tab.key === defaultTab) ? displayTabData.filter((tab) => tab.key === defaultTab)[0] : displayTabData[0];
        let defaultTabCode = _activeTab && _activeTab.actualCode;
        let filteredDepositApiJson = {
            channel: gu_getNestedValue(depositApiJson, `${defaultTabCode}.bank`) || depositApiJson[defaultTabCode],
            optionCode: _activeTab && _activeTab.code,
        };

        window.SPL_Content.getBankName(displayTabData, 'option');

        if (depositAllSettings) {
            depositAllSettings.hideAddBankAccount = trans_massageHideAddBank(this, 'deposit', _activeTab.code);
        }

        this.setState({
            displayTab: displayTabData,
            filteredDepositApiJson: filteredDepositApiJson,
            activeTab: _activeTab.code,
            activeTabActualCode: defaultTabCode,
            selectedPaymentOption: selectedPaymentOption,
        });
    }

    // bank offline
    getBankStatus() {
        const { merchantBankList } = this.state;
        let currency = this.props.user.account.currency;

        window.SPL_Transaction.processBankMaintenanceDisplay(merchantBankList, currency).then(async (data) => {
            let info = data;
            let foundFlag = false;
            let showBankStatus = false;

            for (let i = 0; i < info.length; i++) {
                if (info[i].displayMaintenances.length > 0) {
                    for (let j = 0; j < info[i].displayMaintenances.length; j++) {
                        if (info[i].displayMaintenances[j].prefix === 'DAILY') {
                            info[i].displayMaintenances[j].prefix = 'transaction:transaction.deposit.bankMaintenance.daily';
                        } else if (info[i].displayMaintenances[j].prefix === 'WEEKDAY') {
                            info[i].displayMaintenances[j].prefix = 'transaction:transaction.deposit.bankMaintenance.weekday';
                        } else if (info[i].displayMaintenances[j].prefix === 'WEEKEND') {
                            info[i].displayMaintenances[j].prefix = 'transaction:transaction.deposit.bankMaintenance.weekend';
                        } else if (info[i].displayMaintenances[j].prefix === 'SAT') {
                            info[i].displayMaintenances[j].prefix = 'transaction:transaction.deposit.bankMaintenance.sat';
                        } else if (info[i].displayMaintenances[j].prefix === 'SUN') {
                            info[i].displayMaintenances[j].prefix = 'transaction:transaction.deposit.bankMaintenance.sun';
                        } else if (info[i].displayMaintenances[j].prefix === 'EMERGENCY') {
                            info[i].displayMaintenances[j].prefix = 'transaction:transaction.deposit.bankMaintenance.emergency';
                        }
                    }
                    showBankStatus = true;
                }

                if (!foundFlag && info[i].displayMaintenances.length > 0) {
                    this.setState({ hasMaintenanceWithPrefix: true });
                }
            }

            this.setState({ showBankStatus: showBankStatus, bankMaintenanceDisplay: info });
        });
    }

    toggleBankStatus() {
        let bankStatusModal = this.state.bankStatusModal;

        if (bankStatusModal) {
            this.setState({
                bankStatusModal: false,
            });
        } else {
            this.setState({
                bankStatusModal: true,
            });
        }
    }

    renderBankMaintenanceContent() {
        const { user, t } = this.props;
        const { bankMaintenanceDisplay } = this.state;

        return (bankMaintenanceDisplay || []).map(
            (bank, i) =>
                bank.displayMaintenances &&
                bank.displayMaintenances.length > 0 && (
                    <div className='bank-container' key={i}>
                        <div className='img-container'>
                            <ImageHelper displayImg={`/public/images/bankImg/${user.account.currency}/${bank.bankCode}.png`} alt={`${bank.bankCode}.png`} />
                        </div>
                        <div className='bank-detail'>
                            <p className='title'>{` ${bank.name} `}</p>
                            {bank.displayMaintenances.map((status, j) => (
                                <div className='content' key={j}>
                                    <p>{t(status.prefix)}</p>
                                    <p>{status.interval}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                ),
        );
    }

    render() {
        const {
            depositDetails,
            bankAppsEnabled,
            cryptoEnabled,
            selectedMethod,
            displayTab,
            activeTab,
            activeTabActualCode,
            customTranslationObj,
            depositAllSettings,
            filteredDepositApiJson,
            paymentGatewaySetting,
            paymentOption,
        } = this.state;
        let depositValue = {
            depositDetails: depositDetails,
            selectedMethod: selectedMethod,
        };

        let depositDataProps = {
            filteredDepositApiJson: this.state.filteredDepositApiJson,
            depositAllSettings: depositAllSettings,
            paymentGatewaySetting,
            hideAddBankAccount: depositAllSettings && depositAllSettings.hideAddBankAccount,
            merchantBankList: this.state.merchantBankList,
            depositAmountOptions: this.state.depositAmountOptions,
            memberDecimalSettings: this.state.memberDecimalSettings,
            parentvm: this,
        };

        return (
            <Translation>
                {(t) => (
                    <div className='wap-deposit-page'>
                        <AuthSettings module={MT?.BANK_DETAIL_BANK} isPublic={false} />
                        <AuthSettings module={MT?.BANK_DETAIL_EWALLET} isPublic={false} />

                        <div className='page-content'>
                            <div className='deposit-item-box'>
                                <div className='title-item fast-deposit-tab'>
                                    <span>{t('settings:settings.tabs.heading.depositOptions', 'Deposit Options')}</span>

                                    {/* bank offline */}
                                    {displayTab.find((e) => this.props.portal.settings.features.showBankOfflineList.includes(e.code) && activeTab === e.code) && (
                                        <div className='bank-offline-container'>
                                            <div className={`bankoff-btn ${this.state.bankStatusModal ? 'active' : ''}`} onClick={() => this.toggleBankStatus()}>
                                                <div className='img-container'>
                                                    <img src='/public/html/images/account/096-bank.png' alt='bank-offline-img' />
                                                    {this.state.showBankStatus && <div className='red-dot'>!</div>}
                                                </div>
                                                <span>{t('transaction:transaction.deposit.bankOfflineStatus')}</span>
                                            </div>

                                            {this.state.bankStatusModal && this.state.hasMaintenanceWithPrefix && (
                                                <div className='floating-bank-offline-container'>{this.renderBankMaintenanceContent()}</div>
                                            )}
                                        </div>
                                    )}
                                </div>
                                {paymentOption && paymentOption.length > 0 && (
                                    <Select
                                        valueField='translation'
                                        key='translation'
                                        labelField='translation'
                                        placeholder={t('settings:settings.dropdown.pleaseselect')}
                                        options={paymentOption}
                                        values={paymentOption}
                                        onChange={(value) => this.paymentOptionSelection(value)}
                                        searchable={false}
                                    />
                                )}
                            </div>
                            <div className='deposit-item-box'>
                                <div className='title-item'>
                                    <span>{t('settings:settings.tabs.heading.depositOptions', 'Deposit Options')}</span>
                                </div>

                                <Nav tabs className='deposit-mobile-tab'>
                                    {depositDetails &&
                                        depositDetails.methodList &&
                                        depositDetails.methodList.map((method, index) => {
                                            return (
                                                <NavItem
                                                    key={`method-${index}`}
                                                    className='deposit-mobile-item'
                                                    onClick={() => {
                                                        if (method.isQRPay) {
                                                            let getActualCode = displayTab.filter((tab) => tab.key === method.methodKey)[0].actualCode;
                                                            this.toggle(getActualCode);
                                                        } else {
                                                            this.toggle('QP');
                                                        }
                                                        this.handleMethodChange(method);
                                                    }}
                                                >
                                                    <NavLink
                                                        className={classnames({
                                                            active: this.state.selectedMethod[0].name === method.name && !['B', 'BA', 'CT'].includes(this.state.activeTab),
                                                            navlinkForm: true,
                                                            depositLink: true,
                                                        })}
                                                    >
                                                        <div className='deposit-banktransfer'>{this.renderMethod(method.isQRPay ? method.methodKey : method.name)}</div>
                                                        <div className='deposit-options-text'>{t(method.name)}</div>
                                                    </NavLink>
                                                </NavItem>
                                            );
                                        })}

                                    {depositAllSettings && depositAllSettings.enableJsonApi ? (
                                        <>
                                            {displayTab.map((tab, i) => (
                                                <NavItem
                                                    key={i}
                                                    className={`${tab.key === 'qrpay' ? 'deposit-mobile-item' : 'deposit-tab-click mobile-deposit-transfer'}`}
                                                    onClick={() => {
                                                        this.toggle(tab.actualCode);
                                                        this.loadBankAccounts();
                                                    }}
                                                >
                                                    <NavLink
                                                        className={classnames({
                                                            active: activeTab === tab.code && activeTabActualCode === tab.actualCode,
                                                            navlinkForm: true,
                                                            depositLink: true,
                                                        })}
                                                    >
                                                        <div className='deposit-banktransfer'>
                                                            <img
                                                                src={tab.icon}
                                                                alt={tab.key}
                                                                className={`${tab.key} ${activeTab === tab.code && activeTabActualCode === tab.actualCode ? 'active' : ''}`}
                                                            />
                                                        </div>
                                                        <div className='deposit-options-text'>
                                                            {customTranslationObj && customTranslationObj[tab.code] ? customTranslationObj[tab.code] : tab.translation}
                                                        </div>
                                                    </NavLink>
                                                </NavItem>
                                            ))}
                                        </>
                                    ) : (
                                        <>
                                            <NavItem
                                                className='deposit-tab-click mobile-deposit-transfer'
                                                onClick={() => {
                                                    this.toggle('B');
                                                    this.loadBankAccounts();
                                                }}
                                            >
                                                <NavLink
                                                    className={classnames({
                                                        active: this.state.activeTab === 'B',
                                                        navlinkForm: true,
                                                        depositLink: true,
                                                    })}
                                                >
                                                    <div className='deposit-banktransfer'>
                                                        <SVGElements name='bank-logo' alt='Bank Transfer' className={`bank ${this.state.activeTab === 'B' ? 'active' : ''}`} />
                                                    </div>
                                                    <div className='deposit-options-text'>{t('global:global.menu.account.bankTransfer', 'Bank Transfer')}</div>
                                                </NavLink>
                                            </NavItem>

                                            {/* bank apps */}
                                            {bankAppsEnabled && (
                                                <NavItem
                                                    className='deposit-tab-click mobile-deposit-transfer'
                                                    onClick={() => {
                                                        this.toggle('BA');
                                                        this.loadBankAccounts();
                                                    }}
                                                >
                                                    <NavLink
                                                        className={classnames({
                                                            active: this.state.activeTab === 'BA',
                                                            navlinkForm: true,
                                                            depositLink: true,
                                                        })}
                                                    >
                                                        <div className='deposit-banktransfer'>
                                                            <img src={bankAppIcon} alt='Bank Apps' className={`bankapp ${this.state.activeTab === 'BA' ? 'active' : ''}`} />
                                                        </div>
                                                        <div className='deposit-options-text'>Paynow</div>
                                                    </NavLink>
                                                </NavItem>
                                            )}

                                            {/* crypto */}
                                            {cryptoEnabled && (
                                                <NavItem
                                                    className='deposit-tab-click mobile-deposit-transfer'
                                                    onClick={() => {
                                                        this.toggle('CT');
                                                        this.loadBankAccounts();
                                                    }}
                                                >
                                                    <NavLink
                                                        className={classnames({
                                                            active: this.state.activeTab === 'CT',
                                                            navlinkForm: true,
                                                            depositLink: true,
                                                        })}
                                                    >
                                                        <div className='deposit-banktransfer'>
                                                            <img src={cryptoIcon} alt='Bank Apps' className={`crypto ${this.state.activeTab === 'CT' ? 'active' : ''}`} />
                                                        </div>
                                                        <div className='deposit-options-text'>Crypto</div>
                                                    </NavLink>
                                                </NavItem>
                                            )}
                                        </>
                                    )}
                                </Nav>
                            </div>

                            <div className='deposit-item-box'>
                                <TabContent activeTab={this.state.activeTab} className='deposit-tab-content'>
                                    {this.state.isLoading && <SmallLoading></SmallLoading>}

                                    {this.state.isSubLoading ? (
                                        <SmallLoading />
                                    ) : (
                                        <Fragment>
                                            {this.state.activeTab === 'QP' && (depositDetails || filteredDepositApiJson) && (
                                                <TabPane tabId='quickPay' className='d-flex form-content'>
                                                    {((depositDetails && depositDetails.defaultMethod) || filteredDepositApiJson) && (
                                                        <QuickPay {...depositValue} isFirstTimeInit={this.state.isFirstTimeInit} {...depositDataProps} />
                                                    )}
                                                    {depositDetails && !depositDetails.defaultMethod && <div className='coming-soon'>Coming Soon...</div>}
                                                </TabPane>
                                            )}

                                            {/* Online Trans */}
                                            {this.state.activeTab === 'B' && (depositDetails || filteredDepositApiJson) && (
                                                <TabPane tabId='onlinetransfer' className='d-flex form-content'>
                                                    <OnlineTransfer {...depositDataProps} />
                                                </TabPane>
                                            )}

                                            {/* Bank Apps */}
                                            {this.state.activeTab === 'BA' && (depositDetails || filteredDepositApiJson) && (
                                                <TabPane tabId='bankApps' className='d-flex form-content' {...depositDataProps}>
                                                    <BankApps {...depositDataProps} />
                                                </TabPane>
                                            )}

                                            {/* Crypto */}
                                            {this.state.activeTab === 'CT' && (depositDetails || filteredDepositApiJson) && (
                                                <TabPane tabId='crypto' className='d-flex form-content' {...depositDataProps}>
                                                    <Crypto type='deposit' {...depositDataProps} />
                                                </TabPane>
                                            )}

                                            {/* QRPay */}
                                            {this.state.activeTab === 'QR' && (depositDetails || filteredDepositApiJson) && (
                                                <TabPane tabId='qrpay' className='d-flex form-content'>
                                                    {depositAllSettings && depositAllSettings.enableJsonApi ? (
                                                        <QRPayV2 {...depositDataProps} />
                                                    ) : (
                                                        <>
                                                            <QRPay
                                                                {...depositValue}
                                                                isFirstTimeInit={this.state.isFirstTimeInit}
                                                                qrPayList={depositDetails && depositDetails.qrPayList}
                                                            />
                                                            {depositDetails && !depositDetails.defaultMethod && <div className='coming-soon'>Coming Soon...</div>}
                                                        </>
                                                    )}
                                                </TabPane>
                                            )}

                                            {/* P2P */}
                                            {this.state.activeTab === 'P2P' && (depositDetails || filteredDepositApiJson) && (
                                                <TabPane tabId='p2p' className='d-flex form-content'>
                                                    <P2P type='deposit' {...depositDataProps} />
                                                </TabPane>
                                            )}

                                            {this.state.activeTab === 'BQR' && (depositDetails || filteredDepositApiJson) && (
                                                <TabPane tabId='bqr' className='d-flex form content'>
                                                    <BankQr type='deposit' {...depositDataProps} />
                                                </TabPane>
                                            )}

                                            {/* E-Wallet */}
                                            {this.state.activeTab === 'EA' && (depositDetails || filteredDepositApiJson) && (
                                                <TabPane tabId='ewalletapps' className='d-flex form-content'>
                                                    <EWalletApps {...depositDataProps} />
                                                </TabPane>
                                            )}
                                        </Fragment>
                                    )}
                                </TabContent>
                            </div>
                        </div>
                    </div>
                )}
            </Translation>
        );
    }
}

export default connect(mapStateToProps)(withTranslation(['transaction', 'settings', 'bank'])(withRouter(Deposit)));
