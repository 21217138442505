import React from 'react';
import { Translation, withTranslation } from 'react-i18next';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import { Col, ModalBody, Row } from 'reactstrap';
import { mapStateToProps } from 'root/redux/selector/selector';
import { withRouter } from 'react-router-dom';
import { MdClose } from 'react-icons/md';
import SVGElements from 'root/components/SVGElements';
import commonUtil from 'root/utils/common-util';
import moment from 'moment';

class HistoryPopup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        commonUtil.loadCustomTranslation('Rebate', this, function () {});
    }

    render() {
        const { isOpen, closeModal, item, rebatePeriodStart, rebatePeriodEnd } = this.props;
        const { customTranslationObj } = this.state;
        return (
            <Translation>
                {(t) => (
                    <div className='messaging-popup'>
                        <div className='popup'>
                            <div className='popup-header'>
                                <div className='popup-header-left'>
                                    <div className='popup-title'>
                                        {moment(rebatePeriodStart).format('YYYY-MM-DD').toString() + '-' + moment(rebatePeriodEnd).format('YYYY-MM-DD').toString()}
                                    </div>
                                </div>
                                <div className='popup-header-right'>
                                    <SVGElements name='close-icon' onClick={() => closeModal()} />
                                    {/* <MdClose onClick={() => this.setState({ showSelectedDelete: false })} /> */}
                                </div>
                            </div>
                            <div className='popup-history-body'>
                                {item && item.length > 0 && (
                                    <div className='popup-provider-table'>
                                        {item.map((hist, i) => (
                                            <>
                                                <div className='rebate-provider-title'>
                                                    {customTranslationObj && customTranslationObj[hist.product] ? t(customTranslationObj[hist.product]) : hist.product}
                                                </div>

                                                {/* loop */}
                                                {hist.productHistories && hist.productHistories.length > 0 && (
                                                    <>
                                                        {hist.productHistories.map((list, i) => (
                                                            <div className='rebate-provider-table'>
                                                                <ul>
                                                                    <li>
                                                                        <span className='title'>{t('rebate:rebate.memberLevel', 'Membership Level')}</span>
                                                                        <span>{list.memberGroupCode}</span>
                                                                    </li>
                                                                    <li>
                                                                        <span className='title'>{t('rebate:rebate.provider', 'Provider')}</span>
                                                                        <span>
                                                                            {customTranslationObj && customTranslationObj[list.provider]
                                                                                ? t(customTranslationObj[list.provider])
                                                                                : list.provider}
                                                                        </span>
                                                                    </li>
                                                                    <li>
                                                                        <span className='title'>{t('rebate:rebate.rebate', 'Rebate (%)')}</span>
                                                                        <span>{list.rate}%</span>
                                                                    </li>
                                                                    <li>
                                                                        <span className='title'>{t('rebate:rebate.validBet', 'Valid Bet')}</span>
                                                                        <span>{list.totalEligibleTurnOver}</span>
                                                                    </li>
                                                                    <li>
                                                                        <span className='title'>{t('rebate:rebate.rebateEarned', 'Rebate Earn')}</span>
                                                                        <span>{list.releasedAmount.toFixed(2)}</span>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        ))}
                                                    </>
                                                )}
                                            </>
                                        ))}
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className='popup-overlay' onClick={() => this.setState({ showSelectedDelete: false })}></div>
                    </div>
                )}
            </Translation>
        );
    }
}

export default connect(mapStateToProps, null)(withTranslation('rebate')(withRouter(HistoryPopup)));
