import isEmpty from 'lodash/isEmpty';

let interval;

const isEmptyValue = (v) => {
    if (typeof v === 'object') {
        return isEmpty(v);
    }

    if (typeof v === 'number') {
        return v !== 0 && !v;
    }

    return !v;
};

export const scrollToDepositStep2 = (enableScrollToDepositStep2, oldValue, newValue, attempts = 15, deplay = 200) => {
    interval && clearInterval(interval);

    if (enableScrollToDepositStep2 && isEmptyValue(oldValue) && !isEmptyValue(newValue)) {
        interval = setInterval(() => {
            attempts = attempts - 1;
            const amountInput =
                document.getElementById('deposit-bottom') || document.getElementById('amount') || document.getElementById('name') || document.getElementById('cryptoQR');
            if (amountInput) {
                amountInput.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
                clearInterval(interval);
            }

            if (attempts === 0) {
                clearInterval(interval);
            }
        }, deplay);
    }
};
