export const sidebarData = [
    {
        id: 0,
        name: 'Home',
        submenu: false,
        dropdownOptions: [],
        route: '/home',
    },
    {
        id: 1,
        name: 'Sports',
        submenu: true,
        imgurl: '/public/html/images/drawer_img/drawer_sport_menu.png',
        imgurlOn: '/public/html/images/drawer_img/drawer_sport_menu_hover.png',
        route: '/sports',
        dropdownOptions: [
            {
                id: 1.11,
                img: '/public/html/images/drawer_img/m8-on.png',
                imghover: '/public/html/images/drawer_img/m8-on.png',
                alturlhover: 'M8-Sports',
                text: ' M8-Sports',
                route: '/sports/m8',
            },
            {
                id: 1.12,
                img: '/public/html/images/drawer_img/cmd.png',
                imghover: '/public/html/images/drawer_img/cmd-on.png',
                alturlhover: 'c-Sports',
                text: ' c-Sports',
                route: '/sports/cmd368',
            },
            {
                id: 1.13,
                img: '/public/html/images/drawer_img/ibc.png',
                imghover: '/public/html/images/drawer_img/ibc-on.png',
                alturlhover: 'Saba-Sports',
                text: ' Saba-Sports',
                route: '',
            },
            {
                id: 1.14,
                img: '/public/html/images/drawer_img/betradar.png',
                imghover: '/public/html/images/drawer_img/betradar-on.png',
                alturlhover: 'Virtual Sports',
                text: ' Virtual Sports',
                route: '/sports/virtualsports-landing',
            },
        ],
    },

    {
        id: '888',
        name: '/public/html/images/drawer_img/menu_mega888.png',
        submenu: true,
        imgurl: '/public/html/images/drawer_img/menu_mega888.png',
        imgurlOn: '/public/html/images/drawer_img/menu_mega888.png',
        dropdownOptions: [],
        route: '/mega888',
    },
    {
        id: 2,
        name: 'Live Casino',
        submenu: true,
        imgurl: '/public/html/images/drawer_img/drawer_casino_menu.png',
        imgurlOn: '/public/html/images/drawer_img/drawer_casino_menu_hover.png',
        dropdownOptions: [
            {
                id: 2.11,
                img: '/public/html/images/drawer_img/gd.png',
                imghover: '/public/html/images/drawer_img/gd-on.png',
                alturlhover: 'GD Gaming',
                text: ' GD Gaming',
                route: '/casino/gd',
            },
            {
                id: 2.12,
                img: '/public/html/images/drawer_img/dg.png',
                imghover: '/public/html/images/drawer_img/dg-on.png',
                alturlhover: 'Dream Gaming',
                text: ' Dream Gaming',
                route: '/casino/dg',
            },
            {
                id: 2.13,
                img: '/public/html/images/drawer_img/allbet.png',
                imghover: '/public/html/images/drawer_img/allbet-on.png',
                alturlhover: 'AllBet',
                text: ' AllBet',
                route: '/casino/ab',
            },
            {
                id: 2.14,
                img: '/public/html/images/drawer_img/evo.png',
                imghover: '/public/html/images/drawer_img/evo-on.png',
                alturlhover: 'Evolution Gaming',
                text: ' Evolution Gaming',
                route: '/casino/evo',
            },
            {
                id: 2.15,
                img: '/public/html/images/drawer_img/casino-gp.png',
                imghover: '/public/html/images/drawer_img/casino-gp-on.png',
                alturlhover: 'Game Play',
                text: ' Game Play',
                route: '/casino/gp',
            },
            {
                id: 2.16,
                img: '/public/html/images/drawer_img/casino-pt.png',
                imghover: '/public/html/images/drawer_img/casino-pt-on.png',
                alturlhover: 'Playtech',
                text: ' Playtech',
                route: '/casino/pt',
            },

            {
                id: 2.17,
                img: '/public/html/images/drawer_img/casino-sa.png',
                imghover: '/public/html/images/drawer_img/casino-sa-on.png',
                alturlhover: 'SA Gaming',
                text: ' SA Gaming',
                route: '/casino/sa',
            },
            {
                id: 2.18,
                img: '/public/html/images/drawer_img/ug.png',
                imghover: '/public/html/images/drawer_img/ug-on.png',
                alturlhover: 'Sexy Baccarat',
                text: ' Sexy Baccarat',
                route: '/casino/sb',
            },
            {
                id: 2.19,
                img: '/public/html/images/drawer_img/casino-ag.png',
                imghover: '/public/html/images/drawer_img/casino-ag-on.png',
                alturlhover: 'Asia Gaming',
                text: ' Asia Gaming',
                route: '/casino/ag',
            },
        ],
    },
    {
        id: 3,
        name: 'Slots',
        submenu: true,
        imgurl: '/public/html/images/drawer_img/drawer_slot_menu.png',
        imgurlOn: '/public/html/images/drawer_img/drawer_slot_menu_hover.png',
        dropdownOptions: [
            {
                id: 3.11,
                img: '/public/html/images/drawer_img/sg.png',
                imghover: '/public/html/images/drawer_img/sg-on.png',
                alturlhover: 'Spade Gaming',
                text: ' Spade Gaming',
                route: '/slot/sg',
            },
            {
                id: 3.12,
                img: '/public/html/images/drawer_img/slot-pt.png',
                imghover: '/public/html/images/drawer_img/slot-pt-on.png',
                alturlhover: 'Playtech',
                text: ' Playtech',
                route: '/slot/pt',
            },
            {
                id: 3.13,
                img: '/public/html/images/drawer_img/mg.png',
                imghover: '/public/html/images/drawer_img/mg-on.png',
                alturlhover: 'Microgaming',
                text: ' Microgaming',
                route: '/slot/mgp',
            },
            {
                id: 3.14,
                img: '/public/html/images/drawer_img/ttg.png',
                imghover: '/public/html/images/drawer_img/ttg-on.png',
                alturlhover: 'TopTrend Gaming',
                text: ' TopTrend Gaming',
                route: '/slot/ttg',
            },
            {
                id: 3.15,
                img: '/public/html/images/drawer_img/ultimate.png',
                imghover: '/public/html/images/drawer_img/ultimate-on.png',
                alturlhover: 'Ultimate',
                text: ' Ultimate',
                route: '/slot/ul',
            },
            {
                id: 3.16,
                img: '/public/html/images/drawer_img/gp.png',
                imghover: '/public/html/images/drawer_img/gp-on.png',
                alturlhover: 'Game Play',
                text: ' Game Play',
                route: '/slot/gp',
            },
            {
                id: 3.17,
                img: '/public/html/images/drawer_img/slot-sa.png',
                imghover: '/public/html/images/drawer_img/slot-sa-on.png',
                alturlhover: 'SA Gaming',
                text: ' SA Gaming',
                route: '/slot/sa',
            },
            {
                id: 3.18,
                img: '/public/html/images/drawer_img/pp.png',
                imghover: '/public/html/images/drawer_img/pp-on.png',
                alturlhover: 'Pragmatic Play',
                text: ' Pragmatic Play',
                route: '/slot/pp',
            },

            {
                id: 3.19,
                img: '/public/html/images/drawer_img/scr.png',
                imghover: '/public/html/images/drawer_img/scr-on.png',
                alturlhover: '918 Kiss',
                text: ' 918 Kiss',
                route: '/slot/918Kiss',
            },
            {
                id: 3.2,
                img: '/public/html/images/drawer_img/ag.png',
                imghover: '/public/html/images/drawer_img/ag-on.png',
                alturlhover: 'Asia Gaming',
                text: ' Asia Gaming',
                route: '/slot/ag2',
            },
            {
                id: 3.3,
                img: '/public/html/images/drawer_img/ag.png',
                imghover: '/public/html/images/drawer_img/ag-on.png',
                alturlhover: 'Mega 888',
                text: 'Mega 888',
                route: '/mega',
            },
        ],
    },
    {
        id: 4,
        name: 'Poker',
        submenu: true,
        imgurl: '/public/html/images/drawer_img/drawer_poker_menu.png',
        imgurlOn: '/public/html/images/drawer_img/drawer_poker_menu_hover.png',
        dropdownOptions: [],
        route: '/poker',
    },
    {
        id: 5,
        name: 'Fishing',
        submenu: true,
        imgurl: '/public/html/images/drawer_img/drawer_fishing_menu.png',
        imgurlOn: '/public/html/images/drawer_img/drawer_fishing_menu_hover.png',
        dropdownOptions: [
            {
                id: 5.11,
                img: '/public/html/images/drawer_img/sgfish.png',
                imghover: '/public/html/images/drawer_img/sgfish-on.png',
                alturlhover: 'Fishing God',
                text: ' Fishing God',
                route: '/fishing/sg-fishing',
            },
            {
                id: 5.12,
                img: '/public/html/images/drawer_img/gg.png',
                imghover: '/public/html/images/drawer_img/gg-on.png',
                alturlhover: 'GG Fishing',
                text: ' GG Fishing',
                route: '/fishing/gggaming',
            },
            {
                id: 5.13,
                img: '/public/html/images/drawer_img/sa.png',
                imghover: '/public/html/images/drawer_img/sa-on.png',
                alturlhover: 'SA Fishing',
                text: ' SA Fishing',
                route: '/fishing/sa-fishing',
            },
            {
                id: 5.14,
                img: '/public/html/images/drawer_img/pt.png',
                imghover: '/public/html/images/drawer_img/pt-on.png',
                alturlhover: 'PT Fishing',
                text: ' PT Fishing',
                route: '/fishing/cashfish',
            },
        ],
    },
    {
        id: 6,
        name: 'Lottery',
        submenu: true,
        imgurl: '/public/html/images/drawer_img/drawer_lottery_menu.png',
        imgurlOn: '/public/html/images/drawer_img/drawer_lottery_menu_hover.png',
        dropdownOptions: [
            {
                id: 6.11,
                img: '/public/html/images/drawer_img/qqkeno.png',
                imghover: '/public/html/images/drawer_img/qqkeno-on.png',
                alturlhover: 'QQKeno',
                text: ' QQKeno',
                route: '/lottery/qqkeno',
            },
        ],
    },
    {
        id: 7,
        name: 'Promos',
        submenu: true,
        imgurl: '/public/html/images/drawer_img/drawer_lottery_menu.png',
        imgurlOn: '/public/html/images/drawer_img/drawer_lottery_menu_hover.png',
        dropdownOptions: [],
        route: '/promotion',
    },
    {
        id: 8,
        img: '/public/html/images/drawer_img/menu_icon_live.gif',
        name: 'TV',
        submenu: true,
        imgurl: '/public/html/images/drawer_img/drawer_lottery_menu.png',
        imgurlOn: '/public/html/images/drawer_img/drawer_lottery_menu_hover.png',
        dropdownOptions: [],
        route: '/livetv',
    },
    {
        id: 9,
        img: '/public/html/images/drawer_img/menu_icon_live.gif',
        name: 'SCORE',
        submenu: true,
        imgurl: '/public/html/images/drawer_img/drawer_lottery_menu.png',
        imgurlOn: '/public/html/images/drawer_img/drawer_lottery_menu_hover.png',
        dropdownOptions: [],
        route: '/livescore',
    },
];

export const userData = [
    {
        id: 7,
        name: 'Home',
        svgname: 'drhome',
        icon: '/public/html/images/drawer_img/sdmenu-home.png',
        path: '/public/html/images/drawer_img/sdmenu-home.png',
        route: '/',
    },
    {
        id: 8,
        name: 'Promotion',
        svgname: 'drpromo',
        icon: '/public/html/images/drawer_img/sdmenu-promo.png',
        path: '/public/html/images/drawer_img/sdmenu-promo.png',
        route: '/contact',
    },
    {
        id: 9,
        name: 'Contact Us',
        svgname: 'drcontact',
        icon: '/public/html/images/drawer_img/sdmenu-contact.png',
        path: '/public/html/images/drawer_img/sdmenu-contact.png',
        route: '/contact',
    },
    {
        id: 10,
        name: 'Language',
        svgname: 'drlanguage',
        icon: '/public/html/images/drawer_img/sdmenu-language.png',
        path: '/public/html/images/drawer_img/sdmenu-language.png',
        route: '/language',
    },
    {
        id: 11,
        name: 'Desktop',
        svgname: 'drdesktop',
        icon: '/public/html/images/drawer_img/sdmenu-desktop.png',
        path: '/public/html/images/drawer_img/sdmenu-desktop.png',
        route: '/',
    },
    {
        id: 12,
        name: 'Download',
        svgname: 'drdownload',
        icon: '/public/html/images/drawer_img/sdmenu-download.png',
        path: '/public/html/images/drawer_img/sdmenu-download.png',
        route: '/download',
    },
    {
        id: 13,
        name: 'Logout',
        svgname: 'drlogout',
        icon: '/public/html/images/drawer_img/sdmenu-logout.png',
        path: '/public/html/images/drawer_img/sdmenu-logout.png',
        route: '/',
    },
];
export const contentFooter = [
    {
        id: 'footer.item.home',
        text: 'Home',
        svgname: 'home',
        type: 'route',
        route: '/home',
        translate: 'global:global.menu.home',
        login: false,
    },
    {
        id: 'footer.item.funds',
        text: 'Fund',
        svgname: 'funds',
        type: 'route',
        route: '/fund',
        translate: 'home:home.mobile.funds',
        login: true,
    },
    {
        id: 'footer.item.myaccount',
        text: 'My Account',
        svgname: 'account',
        type: 'route',
        route: '/myaccount/myprofile',
        translate: 'home:home.mobile.account',
        login: true,
    },
    {
        id: 'footer.item.promo',
        text: 'Promotion',
        svgname: 'promotion',
        type: 'route',
        route: '/promotion',
        translate: 'home:home.mobile.promotion',
        login: false,
    },
    {
        id: 'footer.item.livechat',
        text: 'Live Chat',
        svgname: 'livechat',
        type: 'button',
        route: '/',
        translate: 'global:footer.livechat',
        login: false,
    },
];

export const footerSection = [
    {
        id: 1,
        name: 'Providers',
        text: 'global:footer.provider',
        providers: [
            {
                id: 1.1,
                name: 'SpadeGaming',
                img: '/public/html/images/footer/ftr_brand_spade_of.png',
                imgHover: '/public/html/images/footer/ftr_brand_spade_ov.png',
            },
            {
                id: 1.2,
                name: 'PlayTech',
                img: '/public/html/images/footer/ftr_brand_pt_of.png',
                imgHover: '/public/html/images/footer/ftr_brand_pt_ov.png',
            },
            {
                id: 1.3,
                name: 'Microgaming',
                img: '/public/html/images/footer/ftr_brand_mg_of.png',
                imgHover: '/public/html/images/footer/ftr_brand_mg_ov.png',
            },
            {
                id: 1.4,
                name: 'TopTrend',
                img: '/public/html/images/footer/ftr_brand_ttg_of.png',
                imgHover: '/public/html/images/footer/ftr_brand_ttg_ov.png',
            },
            {
                id: 1.5,
                name: 'GamePlay',
                img: '/public/html/images/footer/ftr_brand_gp_of.png',
                imgHover: '/public/html/images/footer/ftr_brand_gp_ov.png',
            },
            {
                id: 1.6,
                name: 'AG',
                img: '/public/html/images/footer/ftr_brand_ag_of.png',
                imgHover: '/public/html/images/footer/ftr_brand_ag_ov.png',
            },
            {
                id: 1.7,
                name: 'AllBet',
                img: '/public/html/images/footer/ftr_brand_allbet_of.png',
                imgHover: '/public/html/images/footer/ftr_brand_allbet_ov.png',
            },
            {
                id: 1.8,
                name: 'GD',
                img: '/public/html/images/footer/ftr_brand_gd_of.png',
                imgHover: '/public/html/images/footer/ftr_brand_gd_ov.png',
            },
            {
                id: 1.9,
                name: 'DG',
                img: '/public/html/images/footer/ftr_brand_dream_of.png',
                imgHover: '/public/html/images/footer/ftr_brand_dream_ov.png',
            },
            {
                id: 2.0,
                name: 'AE Sexy',
                img: '/public/html/images/footer/ftr_brand_aesexy_of.png',
                imgHover: '/public/html/images/footer/ftr_brand_aesexy_ov.png',
            },
            {
                id: 2.1,
                name: 'GG',
                img: '/public/html/images/footer/ftr_brand_ggg_of.png',
                imgHover: '/public/html/images/footer/ftr_brand_ggg_ov.png',
            },
            {
                id: 2.2,
                name: 'CMD368',
                img: '/public/html/images/footer/ftr_brand_cmd_of.png',
                imgHover: '/public/html/images/footer/ftr_brand_cmd_ov.png',
            },
            {
                id: 2.3,
                name: 'Betradar',
                img: '/public/html/images/footer/ftr_brand_br_of.png',
                imgHover: '/public/html/images/footer/ftr_brand_br_ov.png',
            },
            {
                id: 2.4,
                name: 'MaxBet',
                img: '/public/html/images/footer/maxbet.png',
                imgHover: '/public/html/images/footer/maxbet_hover.png',
            },
            {
                id: 2.5,
                name: 'SBOBet',
                img: '/public/html/images/footer/sbobet.png',
                imgHover: '/public/html/images/footer/sbobet_hover.png',
            },
            {
                id: 2.6,
                name: 'M8Bet',
                img: '/public/html/images/footer/ftr_brand_m8bet_of.png',
                imgHover: '/public/html/images/footer/ftr_brand_m8bet_ov.png',
            },
            {
                id: 2.7,
                name: 'Ultimate',
                img: '/public/html/images/footer/ultimate.png',
                imgHover: '/public/html/images/footer/ultimate_hover.png',
            },
            {
                id: 2.8,
                name: 'PnG',
                img: '/public/html/images/footer/ftr_brand_playngo_of.png',
                imgHover: '/public/html/images/footer/ftr_brand_playngo_ov.png',
            },

            {
                id: 3.0,
                name: 'IDNPoker',
                img: '/public/html/images/footer/ftr_brand_idn_of.png',
                imgHover: '/public/html/images/footer/ftr_brand_idn_ov.png',
            },
            {
                id: 3.1,
                name: 'SA Gaming',
                img: '/public/html/images/footer/ftr_brand_sa_of.png',
                imgHover: '/public/html/images/footer/ftr_brand_sa_ov.png',
            },
        ],
    },
    {
        id: 2,
        name: 'Licenses',
        text: 'global:footer.license',
        providers: [
            {
                id: 2.1,
                name: 'Pagcor',
                img: '/public/html/images/new/footer/ftr_reco_pagcor_of.png',
                imgHover: '/public/html/images/footer/ftr_reco_pagcor_ov.png',
            },
            {
                id: 2.2,
                name: 'BMM',
                img: '/public/html/images/new/footer/ftr_reco_bmm_of.png',
                imgHover: '/public/html/images/footer/ftr_reco_bmm_ov.png',
            },
            {
                id: 2.3,
                name: 'reco',
                img: '/public/html/images/new/footer/ftr_reco_veri_of.png',
                imgHover: '/public/html/images/footer/ftr_reco_veri_ov.png',
            },
            {
                id: 2.4,
                name: 'itech',
                img: '/public/html/images/new/footer/ftr_reco_itech_of.png',
                imgHover: '/public/html/images/footer/ftr_reco_itech_ov.png',
            },
            {
                id: 2.5,
                name: 'Cert',
                img: '/public/html/images/new/footer/ftr_reco_cert.png',
                imgHover: '/public/html/images/footer/ftr_reco_cert_ov.png',
            },
            {
                id: 2.6,
                name: 'tt',
                img: '/public/html/images/new/footer/ftr_reco_tt_of.png',
                imgHover: '/public/html/images/footer/ftr_reco_tt_ov.png',
            },
        ],
    },
    {
        id: 3,
        name: 'Payment Method',
        text: 'global:footer.payment-method',
        providers: [
            {
                id: 3.1,
                name: 'EeziePay',
                img: '/public/html/images/footer/ftr_pay_eezie_of.png',
                imgHover: '/public/html/images/footer/ftr_pay_eezie_ov.png',
            },
            {
                id: 3.2,
                name: 'PayTrust',
                img: '/public/html/images/footer/paytrust88.png',
                imgHover: '/public/html/images/footer/paytrust88_hover.png',
            },
            {
                id: 3.3,
                name: 'Help2Pay',
                img: '/public/html/images/footer/help2pay.png',
                imgHover: '/public/html/images/footer/help2pay_hover.png',
            },
        ],
    },
    {
        id: 4,
        name: 'Follow Us',
        text: 'global:footer.follow-us',
        providers: [
            {
                id: 4.1,
                name: 'Fb',
                img: '/public/html/images/new/footer/ftr_social_fb_of.png',
                imgHover: '/public/html/images/footer/ftr_social_fb_ov.png',
            },
            {
                id: 4.2,
                name: 'Yt',
                img: '/public/html/images/new/footer/ftr_social_yt_of.png',
                imgHover: '/public/html/images/footer/ftr_social_yt_ov.png',
            },
        ],
    },
    {
        id: 5,
        name: 'Suggested Browser',
        text: 'global:footer.suggest-browser',
        providers: [
            {
                id: 2.1,
                name: 'Chrome',
                img: '/public/html/images/new/footer/ftr_browser_chrome_of.png',
                imgHover: '/public/html/images/footer/ftr_browser_chrome_ov.png',
            },
            {
                id: 2.2,
                name: 'IE',
                img: '/public/html/images/new/footer/ftr_browser_ie_of.png',
                imgHover: '/public/html/images/footer/ftr_browser_ie_ov.png',
            },
            {
                id: 2.3,
                name: 'FireFox',
                img: '/public/html/images/new/footer/ftr_browser_ff_of.png',
                imgHover: '/public/html/images/footer/ftr_browser_ff_ov.png',
            },
        ],
    },
];

export const footerSection_s99 = [
    {
        id: 1,
        name: 'Providers',
        text: 'global:footer.provider',
        providers: [
            {
                id: 1.1,
                name: 'SpadeGaming',
                img: '/public/html/images/footer/ftr_brand_spade_of.png',
                imgHover: '/public/html/images/footer/ftr_brand_spade_ov.png',
            },
            {
                id: 1.2,
                name: 'PlayTech',
                img: '/public/html/images/footer/ftr_brand_pt_of.png',
                imgHover: '/public/html/images/footer/ftr_brand_pt_ov.png',
            },
            {
                id: 1.3,
                name: 'Microgaming',
                img: '/public/html/images/footer/ftr_brand_mg_of.png',
                imgHover: '/public/html/images/footer/ftr_brand_mg_ov.png',
            },
            {
                id: 1.4,
                name: 'TopTrend',
                img: '/public/html/images/footer/ftr_brand_ttg_of.png',
                imgHover: '/public/html/images/footer/ftr_brand_ttg_ov.png',
            },
            {
                id: 1.5,
                name: 'GamePlay',
                img: '/public/html/images/footer/ftr_brand_gp_of.png',
                imgHover: '/public/html/images/footer/ftr_brand_gp_ov.png',
            },
            {
                id: 1.6,
                name: 'AG',
                img: '/public/html/images/footer/ftr_brand_ag_of.png',
                imgHover: '/public/html/images/footer/ftr_brand_ag_ov.png',
            },
            {
                id: 1.7,
                name: 'AllBet',
                img: '/public/html/images/footer/ftr_brand_allbet_of.png',
                imgHover: '/public/html/images/footer/ftr_brand_allbet_ov.png',
            },
            {
                id: 1.8,
                name: 'GD',
                img: '/public/html/images/footer/ftr_brand_gd_of.png',
                imgHover: '/public/html/images/footer/ftr_brand_gd_ov.png',
            },
            {
                id: 1.9,
                name: 'DG',
                img: '/public/html/images/footer/ftr_brand_dream_of.png',
                imgHover: '/public/html/images/footer/ftr_brand_dream_ov.png',
            },
            {
                id: 2.0,
                name: 'AE Sexy',
                img: '/public/html/images/footer/ftr_brand_aesexy_of.png',
                imgHover: '/public/html/images/footer/ftr_brand_aesexy_ov.png',
            },
            {
                id: 2.1,
                name: 'GG',
                img: '/public/html/images/footer/ftr_brand_ggg_of.png',
                imgHover: '/public/html/images/footer/ftr_brand_ggg_ov.png',
            },
            {
                id: 2.2,
                name: 'CMD368',
                img: '/public/html/images/footer/ftr_brand_cmd_of.png',
                imgHover: '/public/html/images/footer/ftr_brand_cmd_ov.png',
            },
            {
                id: 2.3,
                name: 'Betradar',
                img: '/public/html/images/footer/ftr_brand_br_of.png',
                imgHover: '/public/html/images/footer/ftr_brand_br_ov.png',
            },
            // {
            //   id: 2.4,
            //   name: 'MaxBet',
            //   img: '/public/html/images/footer/maxbet.png',
            //   imgHover: '/public/html/images/footer/maxbet_hover.png',
            // },
            // {
            //   id: 2.5,
            //   name: 'SBOBet',
            //   img: '/public/html/images/footer/sbobet.png',
            //   imgHover: '/public/html/images/footer/sbobet_hover.png',
            // },
            {
                id: 2.6,
                name: 'M8Bet',
                img: '/public/html/images/footer/ftr_brand_m8bet_of.png',
                imgHover: '/public/html/images/footer/ftr_brand_m8bet_ov.png',
            },
            // {
            //   id: 2.7,
            //   name: 'Ultimate',
            //   img: '/public/html/images/footer/ultimate.png',
            //   imgHover: '/public/html/images/footer/ultimate_hover.png',
            // },
            // {
            //   id: 2.8,
            //   name: 'PnG',
            //   img: '/public/html/images/footer/ftr_brand_playngo_of.png',
            //   imgHover: '/public/html/images/footer/ftr_brand_playngo_ov.png',
            // },

            {
                id: 3.0,
                name: 'IDNPoker',
                img: '/public/html/images/footer/ftr_brand_idn_of.png',
                imgHover: '/public/html/images/footer/ftr_brand_idn_ov.png',
            },
            {
                id: 3.1,
                name: 'SA Gaming',
                img: '/public/html/images/footer/ftr_brand_sa_of.png',
                imgHover: '/public/html/images/footer/ftr_brand_sa_ov.png',
            },
        ],
    },
    {
        id: 2,
        name: 'Licenses',
        text: 'global:footer.license',
        providers: [
            {
                id: 2.11,
                name: 'Pagcor',
                img: '/public/html/images/footer/ftr_reco_pagcor_of.png',
                imgHover: '/public/html/images/footer/ftr_reco_pagcor_ov.png',
            },
            {
                id: 2.12,
                name: 'BMM',
                img: '/public/html/images/footer/ftr_reco_bmm_of.png',
                imgHover: '/public/html/images/footer/ftr_reco_bmm_ov.png',
            },
            {
                id: 2.13,
                name: 'reco',
                img: '/public/html/images/footer/ftr_reco_veri_of.png',
                imgHover: '/public/html/images/footer/ftr_reco_veri_ov.png',
            },
            {
                id: 2.14,
                name: 'itech',
                img: '/public/html/images/footer/ftr_reco_itech_of.png',
                imgHover: '/public/html/images/footer/ftr_reco_itech_ov.png',
            },
            {
                id: 2.15,
                name: 'Bmm',
                img: '/public/html/images/footer/ftr_reco_bmm_of.png',
                imgHover: '/public/html/images/footer/ftr_reco_bmm_ov.png',
            },
            {
                id: 2.16,
                name: 'tt',
                img: '/public/html/images/footer/ftr_reco_tt_of.png',
                imgHover: '/public/html/images/footer/ftr_reco_tt_ov.png',
            },
        ],
    },
    {
        id: 3,
        name: 'Payment Method',
        text: 'global:footer.payment-method',
        providers: [
            {
                id: 3.11,
                name: 'EeziePay',
                img: '/public/html/images/footer/ftr_pay_eezie_of.png',
                imgHover: '/public/html/images/footer/ftr_pay_eezie_ov.png',
            },
            {
                id: 3.12,
                name: 'PayTrust',
                img: '/public/html/images/footer/ftr_pay_pt88_of.png',
                imgHover: '/public/html/images/footer/ftr_pay_pt88_ov.png',
            },
            {
                id: 3.13,
                name: 'Help2Pay',
                img: '/public/html/images/footer/ftr_pay_h2p_of.png',
                imgHover: '/public/html/images/footer/ftr_pay_h2p_ov.png',
            },
        ],
    },
    {
        id: 4,
        name: 'Follow Us',
        text: 'global:footer.follow-us',
        providers: [
            {
                id: 4.11,
                name: 'Fb',
                img: '/public/html/images/new/footer/ftr_social_fb_of.png',
                imgHover: '/public/html/images/footer/ftr_social_fb_ov.png',
            },
            {
                id: 4.12,
                name: 'Yt',
                img: '/public/html/images/new/footer/ftr_social_yt_of.png',
                imgHover: '/public/html/images/footer/ftr_social_yt_ov.png',
            },
        ],
    },
    {
        id: 5,
        name: 'Suggested Browser',
        text: 'global:footer.suggest-browser',
        providers: [
            {
                id: 5.11,
                name: 'Chrome',
                img: '/public/html/images/footer/ftr_browser_chrome_of.png',
                imgHover: '/public/html/images/footer/ftr_browser_chrome_ov.png',
            },
            {
                id: 5.12,
                name: 'IE',
                img: '/public/html/images/footer/ftr_browser_ie_of.png',
                imgHover: '/public/html/images/footer/ftr_browser_ie_ov.png',
            },
            {
                id: 5.13,
                name: 'FireFox',
                img: '/public/html/images/footer/ftr_browser_ff_of.png',
                imgHover: '/public/html/images/footer/ftr_browser_ff_ov.png',
            },
        ],
    },
];
export const footerSection_bdb = [
    {
        id: 1,
        name: 'Follow Us',
        text: 'global:footer.follow-us',
        providers: [
            {
                id: 1.1,
                name: 'Facebook',
                img: '/public/html/images/footer/ftr_social_fb_of.png',
                imgHover: '/public/html/images/footer/ftr_social_fb_ov.png',
            },
            {
                id: 1.2,
                name: 'Twitter',
                img: '/public/html/images/footer/ftr_social_twt_of.png',
                imgHover: '/public/html/images/footer/ftr_social_twt_ov.png',
            },
            {
                id: 1.3,
                name: 'Youtube',
                img: '/public/html/images/footer/ftr_social_yt_of.png',
                imgHover: '/public/html/images/footer/ftr_social_yt_ov.png',
            },
            {
                id: 1.4,
                name: 'Instagram',
                img: '/public/html/images/footer/ftr_social_ig_of.png',
                imgHover: '/public/html/images/footer/ftr_social_ig_ov.png',
            },
        ],
    },
    {
        id: 2,
        name: 'Download',
        text: 'global:footer.suggest-browser',
        providers: [
            {
                id: 2.1,
                name: 'Google',
                img: '/public/html/images/footer/ftr_googleplay.png',
                route: '/download',
            },
            {
                id: 2.2,
                name: 'iOS',
                img: '/public/html/images/footer/ftr_appstore.png',
                route: '/download',
            },
        ],
    },
    {
        id: 3,
        name: 'Online Casino Games Supplied By',
        text: 'global:footer.Online-casino-games-supplied-by',
        providers: [
            {
                id: 3.1,
                name: 'SpadeGaming',
                img: '/public/html/images/footer/ftr_brand_spade_of.png',
                imgHover: '/public/html/images/footer/ftr_brand_spade_ov.png',
            },
            {
                id: 3.2,
                name: 'PlayTech',
                img: '/public/html/images/footer/ftr_brand_pt_of.png',
                imgHover: '/public/html/images/footer/ftr_brand_pt_ov.png',
            },
            {
                id: 3.3,
                name: 'Microgaming',
                img: '/public/html/images/footer/ftr_brand_mg_of.png',
                imgHover: '/public/html/images/footer/ftr_brand_mg_ov.png',
            },
            {
                id: 3.4,
                name: 'TopTrend',
                img: '/public/html/images/footer/ftr_brand_ttg_of.png',
                imgHover: '/public/html/images/footer/ftr_brand_ttg_ov.png',
            },
            {
                id: 3.5,
                name: 'GamePlay',
                img: '/public/html/images/footer/ftr_brand_gp_of.png',
                imgHover: '/public/html/images/footer/ftr_brand_gp_ov.png',
            },
            {
                id: 3.6,
                name: 'AG',
                img: '/public/html/images/footer/ftr_brand_ag_of.png',
                imgHover: '/public/html/images/footer/ftr_brand_ag_ov.png',
            },
            {
                id: 3.7,
                name: 'AllBet',
                img: '/public/html/images/footer/ftr_brand_allbet_of.png',
                imgHover: '/public/html/images/footer/ftr_brand_allbet_ov.png',
            },
            {
                id: 3.8,
                name: 'GD',
                img: '/public/html/images/footer/ftr_brand_gd_of.png',
                imgHover: '/public/html/images/footer/ftr_brand_gd_ov.png',
            },
            {
                id: 3.9,
                name: 'DG',
                img: '/public/html/images/footer/ftr_brand_dream_of.png',
                imgHover: '/public/html/images/footer/ftr_brand_dream_ov.png',
            },
            {
                id: 4.0,
                name: 'AE Sexy',
                img: '/public/html/images/footer/ftr_brand_aesexy_of.png',
                imgHover: '/public/html/images/footer/ftr_brand_aesexy_ov.png',
            },
            {
                id: 4.1,
                name: 'GG',
                img: '/public/html/images/footer/ftr_brand_ggg_of.png',
                imgHover: '/public/html/images/footer/ftr_brand_ggg_ov.png',
            },
            {
                id: 4.2,
                name: 'CMD368',
                img: '/public/html/images/footer/ftr_brand_cmd_of.png',
                imgHover: '/public/html/images/footer/ftr_brand_cmd_ov.png',
            },
            {
                id: 4.3,
                name: 'Betradar',
                img: '/public/html/images/footer/ftr_brand_br_of.png',
                imgHover: '/public/html/images/footer/ftr_brand_br_ov.png',
            },
            {
                id: 4.4,
                name: 'MaxBet',
                img: '/public/html/images/footer/maxbet.png',
                imgHover: '/public/html/images/footer/maxbet_hover.png',
            },
            {
                id: 4.5,
                name: 'SBOBet',
                img: '/public/html/images/footer/sbobet.png',
                imgHover: '/public/html/images/footer/sbobet_hover.png',
            },
            {
                id: 4.6,
                name: 'M8Bet',
                img: '/public/html/images/footer/ftr_brand_m8bet_of.png',
                imgHover: '/public/html/images/footer/ftr_brand_m8bet_ov.png',
            },
            {
                id: 4.7,
                name: 'Ultimate',
                img: '/public/html/images/footer/ultimate.png',
                imgHover: '/public/html/images/footer/ultimate_hover.png',
            },
            {
                id: 4.8,
                name: 'PnG',
                img: '/public/html/images/footer/ftr_brand_playngo_of.png',
                imgHover: '/public/html/images/footer/ftr_brand_playngo_ov.png',
            },
            {
                id: 5.0,
                name: 'IDNPoker',
                img: '/public/html/images/footer/ftr_brand_idn_of.png',
                imgHover: '/public/html/images/footer/ftr_brand_idn_ov.png',
            },
            {
                id: 5.1,
                name: 'SA Gaming',
                img: '/public/html/images/footer/ftr_brand_sa_of.png',
                imgHover: '/public/html/images/footer/ftr_brand_sa_ov.png',
            },
            {
                id: 5.3,
                name: 'QQKeno',
                img: '/public/html/images/footer/ftr_brand_qqkeno_of.png',
                imgHover: '/public/html/images/footer/ftr_brand_qqkeno_ov.png',
            },
            {
                id: 5.5,
                name: 'PragmaticPlay',
                img: '/public/html/images/footer/pragmaticplay.png',
                imgHover: '/public/html/images/footer/pragmaticplay_hover.png',
            },
        ],
    },
    {
        id: 6,
        name: 'Payment Method',
        text: 'global:footer.payment-method',
        providers: [
            {
                id: 6.1,
                name: 'EeziePay',
                img: '/public/html/images/footer/ftr_pay_eezie_of.png',
                imgHover: '/public/html/images/footer/ftr_pay_eezie_ov.png',
            },
            {
                id: 6.2,
                name: 'PayTrust',
                img: '/public/html/images/footer/paytrust88.png',
                imgHover: '/public/html/images/footer/paytrust88_hover.png',
            },
            {
                id: 6.3,
                name: 'Help2Pay',
                img: '/public/html/images/footer/help2pay.png',
                imgHover: '/public/html/images/footer/help2pay_hover.png',
            },
        ],
    },
    {
        id: 7,
        name: 'Licenses',
        text: 'global:footer.license',
        providers: [
            {
                id: 7.1,
                name: 'Pagcor',
                img: '/public/html/images/new/footer/ftr_reco_pagcor_of.png',
                imgHover: '/public/html/images/footer/ftr_reco_pagcor_ov.png',
            },
            {
                id: 7.2,
                name: 'Cert',
                img: '/public/html/images/new/footer/ftr_reco_cert_of.png',
                imgHover: '/public/html/images/footer/ftr_reco_cert_ov.png',
            },
            {
                id: 7.3,
                name: 'itech',
                img: '/public/html/images/new/footer/ftr_reco_itech_of.png',
                imgHover: '/public/html/images/footer/ftr_reco_itech_ov.png',
            },
            {
                id: 7.4,
                name: 'veri',
                img: '/public/html/images/new/footer/ftr_reco_veri_of.png',
                imgHover: '/public/html/images/footer/ftr_reco_veri_ov.png',
            },
            {
                id: 3.5,
                name: 'Bmm',
                img: '/public/html/images/new/footer/ftr_reco_bmm_of.png',
                imgHover: '/public/html/images/footer/ftr_reco_bmm_ov.png',
            },
        ],
    },
];
export const sideHotSlider = [
    {
        id: '1',
        name: 'Recent Play',
        submenu: [
            {
                id: 1.11,
                name: 'GG Gaming',
                img: '/public/html/images/drawer_img/gg.png',
                imghover: '/public/html/images/drawer_img/gg.png',
                alturlhover: 'GG Fishing',
                text: 'GG Gaming',
                route: '/fishing/gggaming',
            },
        ],
    },

    {
        id: '2',
        name: 'New Games',
        submenu: [
            {
                id: 2.11,
                img: '/public/html/images/drawer_img/sg.png',
                imghover: '/public/html/images/drawer_img/sg.png',
                alturlhover: 'SG-Slots',
                text: 'SG Slots',
                route: '/slots/spadeGaming',
            },
            {
                id: 2.12,
                img: '/public/html/images/drawer_img/cmd.png',
                imghover: '/public/html/images/drawer_img/cmd.png',
                alturlhover: 'C-Sports',
                text: 'C-Sports',
                route: '/sports/cmd368',
            },
            {
                id: 2.13,
                img: '/public/html/images/drawer_img/ultimate.png',
                imghover: '/public/html/images/drawer_img/ultimate.png',
                alturlhover: 'Ultimate',
                text: 'ULTIMATE',
                route: '/ultimate-slots',
            },
        ],
    },

    {
        id: '3',
        name: 'Hot Games',
        submenu: [
            {
                id: 3.11,
                img: '/public/html/images/drawer_img/casino-pt.png',
                imghover: '/public/html/images/drawer_img/casino-pt.png',
                alturlhover: '12Win Rolex',
                text: '12Win Rolex',
                route: '/slots/playTech',
            },
            {
                id: 3.12,
                img: '/public/html/images/drawer_img/ttg.png',
                imghover: '/public/html/images/drawer_img/ttg.png',
                alturlhover: 'TTG Slots',
                text: 'TTG Slots',
                route: '/slots/toptrendgaming',
            },
            {
                id: 3.13,
                img: '/public/html/images/drawer_img/allbet.png',
                imghover: '/public/html/images/drawer_img/allbet.png',
                alturlhover: 'Allbet',
                text: 'ALLBET',
                route: '/casino/allbet',
            },
            {
                id: 3.14,
                img: '/public/html/images/drawer_img/gd.png',
                imghover: '/public/html/images/drawer_img/gd.png',
                alturlhover: 'GD',
                text: 'GD GAMING',
                route: '/casino/gold-deluxe',
            },
        ],
    },
];
export const loadingGIF = ['/public/html/images/common_img//TH9_Logo_Loading.gif'];
export const brandLogo = ['/public/html/images/home_img/merchant_logo.svg'];
export const brandLogoMobile = ['/public/html/images/common_img/hdr-logo.svg'];
export const monitorIcon = ['/public/html/images/home_img/icon_pc.svg'];
export const mobileIcon = ['/public/html/images/account/menu_mobile.png'];
export const iconLiveChat = ['/public/html/images/home_img/livechat.png'];
export const pagcorLicense = ['/public/html/images/footer/ftr_reco_pagcor_of.png'];
export const currencyCode = ['IDR'];
export const currencyAmount = ['9,999,999.00'];
export const iconRightName = ['Restore'];
export const imgAnnouncement = ['/public/html/images/home_img/announce_left.jpg'];
export const flagMY = ['/public/html/images/language_img/language_msia.png'];
export const flagSG = ['/public/html/images/language_img/language_sing.png'];
export const flagTH = ['/public/html/images/language_img/language_thai.png'];
export const iconArrow = ['/public/html/images/language_img/arrow.png'];
export const icon18only = ['/public/html/images/footer/18_only.png'];
export const iconfbhover = ['/public/html/images/footer/icon_fb_hover.png'];
export const iconythover = ['/public/html/images/footer/icon_yt_hover.png'];
export const iconchromehover = ['/public/html/images/footer/icon_chrome_hover.png'];
export const iconffhover = ['/public/html/images/footer/icon_ff_hover.png'];
export const curacaoMobile = ['/public/html/images/footer/license-curacao.png'];
export const fbMobile = ['/public/html/images/footer/ftr_social_fb_ov.png'];
export const igMobile = ['/public/html/images/footer/ftr_social_ig_ov.png'];
export const ytMobile = ['/public/html/images/footer/ftr_social_yt_ov.png'];
export const lineMobile = ['/public/html/images/footer/ftr_social_line_ov.png'];
export const twitterMobile = ['/public/html/images/footer/ftr_social_twt_ov.png'];
export const lineIcon = ['public/html/images/account/icon_line.png'];
export const iconContact1 = ['/public/html/images/home_img/wc.svg'];
export const iconContact2 = ['/public/html/images/home_img/mobile.svg'];
export const iconContact3 = ['/public/html/images/home_img/whatsapp.svg'];
export const iconContact4 = ['/public/html/images/home_img/livechat.svg'];
export const announcementIcon = ['/public/html/images/common_img/icon_notice.png'];
export const announcementIconMobile = ['/public/html/images/common_img/icon_notice.png'];
export const imgContact = ['/public/html/images/home_img/contact_dd_icon.png'];
export const imgDownload = ['/public/html/images/home_img/download_dd_icon.png'];
export const iconAndriod = ['/public/html/images/home_img/android.svg'];
export const iconApple = ['/public/html/images/home_img/apple.svg'];
export const iconPC = ['/public/html/images/home_img/pc.svg'];
export const contactBanner = ['/public/html/images/home_img/contact_dd_speed.png'];
export const contactBanner_thai = ['/public/html/images/home_img/contact_dd_247_thai.png'];
export const hotBanner = ['/public/html/images/home_img/hg_dd_bn_fog.png'];
export const hotgamesGif = ['/public/html/images/home_img/hg_dd_icon_hot2.gif'];
export const hotgamesIcon = ['/public/html/images/home_img/hg_dd_icon_hot.gif'];
export const hotgamesNew = ['/public/html/images/home_img/hg_dd_icon_new.gif'];
export const hotgameNewBorder = ['/public/html/images/home_img/hg_dd_band_new.png'];
export const hotgameHotBorder = ['/public/html/images/home_img/hg_dd_band_hot.png'];
export const hotgameRecBorder = ['/public/html/images/home_img/hg_dd_band_reco.png'];
export const quickBanner = ['/public/html/images/home_img/contactus_myr.png'];
export const quickBanner_thai = ['/public/html/images/home_img/contactus_thai.png'];
export const language_dropdown = ['/public/html/images/language_img/arrow.svg'];
export const global_language_icon = ['/public/html/images/language_img/global-language-icon.png'];

// v99
export const v99brandLogo = ['/public/html/images/home_img/logo-loginNregister.png'];

export const noticeIcon = ['/public/html/images/drawer_img/096-notice.png'];
