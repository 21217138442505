import React from 'react';
import styles from './label.module.scss';

const Label = ({ labelText, isMandatory = false }) => {
    return (
        <div className={styles.labelWrapper}>
            <span>{labelText}</span>
            {isMandatory && <span className={styles.asterisk}> *</span>}
        </div>
    );
};

export default Label;
