import React from "react";
import { connect } from 'react-redux';
import { mapStateToProps } from '../../redux/selector/selector';
import { withRouter } from 'react-router-dom';
import { Translation, withTranslation } from 'react-i18next';
import SVGElements from 'root/components/SVGElements';

class Toolbar extends React.Component {
  constructor(prop) {
    super(prop);

  }

  controlMenu() {
    this.props.onToogleMenu && this.props.onToogleMenu()
  }

  goBack = () => {
    this.props.history.goBack();
  }

  render() {
    return (
      <div className="wap-toolbar">
        <div className="top-tools">
          <div className="tools-left">
            <i className="icon-wap-back" onClick={this.goBack}></i>
            <span>{this.props.name}</span>
          </div>
          <div className="tools-right">
            <SVGElements className="icon-wap-menu" name='menu-icon' onClick={() => this.controlMenu()} />
            {/* <i className="icon-wap-menu" onClick={() => this.controlMenu()}></i> */}
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps)(withTranslation()(withRouter(Toolbar)));
