import React from 'react';
import { Trans, Translation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { mapStateToProps } from 'root/redux/selector/selector';
import TurnstileWidget from 'root/components/TurnstileWidget/Turnstile';
import { withRouter } from 'react-router-dom';
import { loadAuthSettings } from '../../utils/turnstile-util';
import { gu_getSettingFromPortalSettings } from '../../utils/general-util';
import { MODULE_TYPE } from '@constants';

class Forgot extends React.Component {
    isMount = true;
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            emailError: [],
            requestFail: false,
            requestSuccess: false,
            isTurnstilelFailed: false,
            captchaToken: null,
        };
    }

    componentDidMount() {
        const { language } = this.props;

        this.isMount = true;
        loadAuthSettings(true, 'RESET_PASSWORD', this, language.currencyLang, 'v2');
        this.initCompSettings();
    }

    componentWillUnmount() {
        this.isMount = false;
    }

    initCompSettings() {
        return new Promise((resolve) => {
            // Step 1: Standard way to get from portal settings
            let tempSettings = gu_getSettingFromPortalSettings({
                vm: this,
                settingName: 'resetPasswordConfiguration',
                notViewType: true,
                notFeatures: true,
            });

            // Step 2: Massage again to get the specific data you need (Skip if not needed)
            let compSettings = { isAllowTurnstileForLiteApp: tempSettings.isAllowTurnstileForLiteApp };

            this.setState({ compSettings }, () => {
                resolve();
            });
        });
    }

    reqResetPasswordEmail = (email, captchaToken, currency) => {
        window.SPL_Member.reqResetPasswordEmail(email, captchaToken, null, null, currency).then((res) => {
            if (this.isMount) {
                if (res === 'success') {
                    this.setState({ requestSuccess: true, requestFail: false });
                } else {
                    this.setState({ requestSuccess: false, requestFail: true });
                }
            }
        });
    };

    handleEmailChange = (event) => {
        this.setState({ email: event.target.value, requestFail: false }, () => {
            this.validateEmailFormat();
        });
    };

    validateEmailFormat = () => {
        const { email } = this.state;
        let component = this;
        return new Promise(function (resolve, reject) {
            window.SPL_Register.validateEmailFormat(email).then((errMsg) => {
                if (component.isMount) {
                    component.setState({ emailError: errMsg });
                    resolve(component.isError(errMsg));
                }
            });
        });
    };

    isError = (errMsg) => {
        return errMsg.length > 0;
    };

    handleSubmit = (event) => {
        event.preventDefault();
        const { email, captchaToken } = this.state;
        const { currencyLang } = this.props.language;
        Promise.all([this.validateEmailFormat()]).then((data) => {
            // true mean having error
            if (data.includes(true)) {
                return;
            } else {
                this.reqResetPasswordEmail(email, captchaToken, currencyLang);
            }
        });
    };

    render() {
        const { tag, authSettingsReducer } = this.props;
        const { compSettings } = this.state;
        const isAllowTurnstileForLiteApp = compSettings?.isAllowTurnstileForLiteApp;
        const isRenderTurnstile = (isAllowTurnstileForLiteApp && window.SPL_LiteApp.isZT828LiteApp()) || !window.SPL_LiteApp.isZT828LiteApp();

        const turnstileDisabled = authSettingsReducer?.processedData?.[MODULE_TYPE.RESET_PASSWORD]?.turnstileEnabled && !this.state.captchaToken;

        return (
            <Translation>
                {(t) =>
                    tag === 'forgot' && (
                        <div className='login resetPass'>
                            <ul>
                                <li className='resetPass-title'>
                                    <label>{t('home:home.mobile.reset-password', 'Reset Password')}</label>
                                    <label>{t('reset:reset.request.messages.info')}</label>
                                    <label>{t('reset:reset.request.messages.info-2')}</label>
                                </li>

                                {!this.state.requestSuccess && (
                                    <li>
                                        <label>
                                            {t('global:global.form.email', 'Email')} <span className='text-red'>*</span>
                                        </label>
                                        <div className='input-box'>
                                            <input
                                                name='email'
                                                className=''
                                                id='email'
                                                type='email'
                                                placeholder={t('global:global.form.email-placeholder')}
                                                value={this.state.email}
                                                onChange={this.handleEmailChange}
                                                onBlur={this.validateEmailFormat}
                                            />
                                        </div>
                                        {this.state.emailError.map((errMsg, index) => {
                                            return (
                                                <div key={index} className='text-red'>
                                                    <Trans className='text-red' i18nKey={errMsg}></Trans>
                                                </div>
                                            );
                                        })}
                                    </li>
                                )}

                                {this.state.requestFail && (
                                    <li className='text-red'>
                                        <Trans i18nKey='reset:reset.request.messages.notfound'></Trans>
                                    </li>
                                )}

                                {this.state.requestSuccess && <li className='text-green'>{t('reset:reset.request.messages.success')}</li>}

                                {this.state.isTurnstilelFailed && (
                                    <li className='text-red'>
                                        <Trans i18nKey='register:register.messages.error.turnstileError'></Trans>
                                    </li>
                                )}
                            </ul>

                            {/* Cloudfare Capcha Turnstile */}
                            {isRenderTurnstile && (
                                <div ref={this.elementRef} className='turnstile-widget-forgot'>
                                    <TurnstileWidget
                                        moduleType={'RESET_PASSWORD'}
                                        theme={'light'}
                                        onVerifyCallback={(token) => {
                                            this.setState({
                                                captchaToken: token,
                                                isTurnstilelFailed: false,
                                            });
                                        }}
                                        onErrorCallback={(token) => {
                                            this.setState({
                                                captchaToken: token,
                                                isTurnstilelFailed: true,
                                            });
                                        }}
                                    />
                                </div>
                            )}
                            <button
                                className={`btn-normal reset-password-button ${this.state.requestSuccess && 'btn-disabled'}`}
                                onClick={this.handleSubmit}
                                disabled={this.state.isTurnstilelFailed || turnstileDisabled}
                            >
                                {t('reset:reset.request.form.button', 'Reset Password')}
                            </button>
                        </div>
                    )
                }
            </Translation>
        );
    }
}

export default connect(mapStateToProps)(withTranslation('reset')(withRouter(Forgot)));
