import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Select from 'react-dropdown-select';
import { Translation } from 'react-i18next';
import { FaRegCalendar } from 'react-icons/fa';
import { Col, ModalBody, ModalFooter, Row, ModalHeader } from 'reactstrap';
import th from 'date-fns/locale/th';
import zhCN from 'date-fns/locale/zh-CN';
registerLocale('th', th);
registerLocale('zhCN', zhCN);
/* const { RangePicker } = DatePicker; */

class HistoryModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            telCodeList: [],
            value: { start: null, end: null },
            getSelectedFilter: '',
            test: null,
        };
    }
    componentDidMount() {}

    getStartDate = (newValue, range) => {
        const start = moment(newValue);
        const end = moment(range && range.end);

        if (end) {
            if (start.isAfter(end)) {
                return end.toDate();
            }
        }

        return start.toDate();
    };

    getEndDate = (newValue, range) => {
        const start = moment(range && range.start);
        const end = moment(newValue);

        if (end.isAfter(start)) {
            return end.toDate();
        }

        return range && range.end;
    };

    render() {
        const { isOpen } = this.state;
        const { value } = this.state;
        let openClass = isOpen ? `open` : ``;
        return (
            <Translation>
                {(t) => (
                    <div className='history-formrow history-formpage'>
                        <ul>
                            <li className='history-item'>
                                <div className='form-history'>
                                    <div className='form-item mobile-history-transtype'>
                                        <div className='top'>
                                            <div className='top-title'>
                                                <span className='history-label'>{t('transaction:transaction.history.filter.trans_type_no_colon')}</span>
                                                <span className='asterisk'> *</span>
                                            </div>
                                            <Select
                                                className='form-control-inner'
                                                placeholder={t('settings:settings.dropdown.pleaseselect')}
                                                options={this.props.categories}
                                                values={this.props.selectedFilterType}
                                                labelField='name'
                                                valueField='transType'
                                                key='id'
                                                searchable={false}
                                                onChange={(value) => this.props.onSelectCategory(value)}
                                            />
                                        </div>
                                    </div>

                                    <div className='form-item'>
                                        <div className='top'>
                                            <div className='top-title'>
                                                <span className='history-label'>{t('transaction:transaction.history.filter.start_date_nocol')}</span>
                                                {/* <span className='asterisk'>*</span> */}
                                            </div>
                                            <DatePicker
                                                locale={this.props.language}
                                                name='startDte'
                                                // isClearable
                                                autoComplete='off'
                                                label='w-100'
                                                type='text'
                                                placeholderText='Start Date'
                                                selectsStart
                                                selected={this.props.filter.startDate}
                                                onChange={(date) => {
                                                    this.props.onDateChanged(date, 'start');
                                                }}
                                                startDate={value.start}
                                                endDate={value.end}
                                                maxDate={value.end}
                                                popperPlacement='bottom'
                                                popperModifiers={{
                                                    flip: {
                                                        behavior: ['bottom'], // don't allow it to flip to be above
                                                    },
                                                    preventOverflow: {
                                                        enabled: false, // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
                                                    },
                                                    hide: {
                                                        enabled: false, // turn off since needs preventOverflow to be enabled
                                                    },
                                                }}
                                            />
                                        </div>
                                    </div>

                                    <div className='form-item'>
                                        <div className='top'>
                                            <div className='to-label'>
                                                <span className='history-label'>{t('transaction:transaction.history.to')}</span>
                                                {/* <span className='asterisk'>*</span> */}
                                            </div>
                                            <DatePicker
                                                locale={this.props.language}
                                                name='endDate'
                                                // isClearable
                                                autoComplete='off'
                                                label='w-100'
                                                type='text'
                                                placeholderText='End Date'
                                                selectsEnd
                                                selected={this.props.filter.endDate}
                                                onChange={(date) => {
                                                    this.props.onDateChanged(date, 'end');
                                                }}
                                                startDate={value.start}
                                                endDate={value.end}
                                                minDate={value.start}
                                                popperPlacement='bottom'
                                                popperModifiers={{
                                                    flip: {
                                                        behavior: ['bottom'], // don't allow it to flip to be above
                                                    },
                                                    preventOverflow: {
                                                        enabled: false, // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
                                                    },
                                                    hide: {
                                                        enabled: false, // turn off since needs preventOverflow to be enabled
                                                    },
                                                }}
                                            />
                                        </div>
                                    </div>

                                    <div className='form-item'>
                                        <button
                                            type='submit'
                                            onClick={() => {
                                                if (this.props.selectedFilterType.length > 0) {
                                                    this.setState({ isOpen: false });
                                                    this.props.applyOnClick();
                                                }
                                            }}
                                            className='btn-normal btn-search'
                                        >
                                            <span>{t('transaction:transaction.deposit.cashdeposit.submitbtn').toLowerCase()}</span>
                                        </button>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                )}
            </Translation>
        );
    }
}

export default HistoryModal;
HistoryModal.propTypes = {
    buttonText: PropTypes.string,
};
