import React from 'react';
import { withTranslation } from 'react-i18next';
import { MdHome } from 'react-icons/md';
import { Link } from 'react-router-dom';
// import { CSSTransitionGroup } from 'react-transition-group'; // ES6
import { Row } from 'reactstrap';
import SVGMaster from '../../../../components/Icon/SVGMaster';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { mapStateToProps } from '../../../../redux/selector/selector';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import {
  hotIcon,
} from '../HeaderData';

class HeaderMenu extends React.Component {
  isMount = true;
  constructor(props) {
    super(props);

    this.state = {
      hover: false,
      showAboutMenu: false,
      itemIdOnHover: 0,
      submenuIdOnHover: 0,
      submenuHotProvider: false,
      displaySubmenu: [],
      showBorder: 'no',
      hoverName: '',
      turnOpacity: '',
    };
    this.onMouseEnterHandler = this.onMouseEnterHandler.bind(this);
    this.onMouseLeaveHandler = this.onMouseLeaveHandler.bind(this);
  }

  componentDidMount() {
    this.getDisplayMenu();
    this.getDisplaySubCategory();
  }

  componentWillUnmount() {
    this.isMount = false;
  }

  preloadImage(images) {
    for (let i = 0; i < images.length; i++) {
      const image = images[i];
      let img = new Image();
      img.src = image.img;
      let imgOn = new Image();
      imgOn.src = image.imghover;
    }
  }

  handleHover = (item) => {
    if (item.closeHeaderHover !== true) {
      if (
        item.name === 'Mega888' ||
        item.name === 'Home' ||
        item.name === 'Mobile' ||
        item.name === 'LiveTV' ||
        item.name === 'LiveScore' ||
        item.name === 'Poker' ||
        item.name === 'Promotions' ||
        item.name === 'AffiliateNav' ||
        item.name === 'VIP' ||
        item.name === '918Kiss'
      ) {
        this.setState({ showBorder: 'no' });
      } else {
        this.setState({ showBorder: item.name });
      }

      // select submenu obj categrary based on hover
      let displaySubmenuObj = this.state.submenus.filter((s) => s.link === item.submenu)[0];
      let displaySubmenu = [];

      if (displaySubmenuObj) {
        displaySubmenu = displaySubmenuObj.submenu;
      }
      this.setState({ showSubMenu: true, itemIdOnHover: item.submenu, displaySubmenu: displaySubmenu });
    }
  };

  handleLeave = (mouse) => {
    this.setState({ showSubMenu: false, itemIdOnHover: null, showBorder: 'no' });
  };

  onMouseEnterHandler(id) {
    this.setState({
      submenuIdOnHover: id,
    });
  }

  onMouseLeaveHandler() {
    this.setState({
      isHover: false,
      submenuIdOnHover: null,
    });
  }

  getDisplayMenu = () => {
    const { language } = this.props;
    // viewType hardcoded to web due to headermenu only display in web
    window.SPL_Content.getDisplayMenu(language.countryLanguageKey, 'web').then((data) => {
      if (this.isMount) {
        this.setState({ menus: data });
        // this.preloadImage(data);
      }
    });
  };

  getAnnoucement = () => {
    const { countryLanguageKey } = this.props.language;
    const { isLogin } = this.props.user;

    window.SPL_Content.getAnnoucementList(isLogin, countryLanguageKey).then((data) => {
      if (this.isMount) {
        this.setState({ annoucementList: data.announcementsDisplay });
      }
    });
  };

  getDisplaySubCategory = () => {
    const { language } = this.props;
    // viewType hardcoded to web due to headermenu only display in web
    window.SPL_Content.getDisplaySubCategory(language.countryLanguageKey, 'web').then((data) => {
      if (this.isMount) {
        this.setState({ submenus: data });
        for (let i = 0; i < data.length; i++) {
          this.preloadImage(data[i].submenu);
        }
      }
    });
  };

  navigateToPage = (gameObj) => {
    if (!gameObj.route) {
      const { category, provider } = gameObj;
      if (category === 'C' || category === 'P') {
        this.props.history.push(`/casino/${gameObj.provider.toLowerCase()}`);
      } else if (category === 'SL') {
        if (provider === 'SCR' || provider === 'SCR2') {
          this.props.history.push(`/918kiss`);
        } else if (provider === 'MEGA') {
          this.props.history.push(`/mega888`);
        } else {
          this.props.history.push(`/slot/${gameObj.provider.toLowerCase()}`);
        }
      } else if (category === 'F') {
        this.props.history.push(`/fishing/${gameObj.provider.toLowerCase()}`);
      } else if (['S'].includes(category)) {
        this.launchGame(gameObj);
      }
    }
  };

  launchGame = (gameObj) => {
    const { language, isLogin, screen } = this.props;
    window.SPL_Provider.getIsEnabledWithouthAuth(gameObj, screen.viewType).then((isEnabled) => {
      if (this.isMount) {
        if (!isLogin && !isEnabled) {
          this.setState({ loginModalVisible: true });
        } else {
          window.SPL_Provider.generateGameUrl(gameObj, language, screen.viewType).then((url) => {
            window.open(url, '_blank');
          });
        }
      }
    });
  };

  switchOpacity = (name, opacity) => {
    this.setState({ hoverName: name, turnOpacity: opacity });
  };

  toggleHover(e) {
    this.setState({ isHovered: e });
  }

  render() {
    const {settings} = this.props.portal;
    return (
      // <></>
      // <div className="footer-section">
      //   <Footer />
      // </div>

      <div className='sub-toolbar_navigation h-100'>
        {this.props.history.location.pathname !== '/faq' ? (
          <div className='d-flex justify-content-start w-100 toolbar_navigation-items'>
          {(this.state.menus || []).map((item) => (
            <div key={item.id} className='h-100'>
              {
                <li onMouseLeave={() => this.handleLeave()} defaultChecked={this.state.itemIdOnHover}>
                  <div onMouseEnter={() => this.handleHover(item)} className='navbar-error-fix'>
                    {['Mega888'].includes(item.name) ? (
                      <Link to={item.route} className='headerLink'>
                        <div className='menu-icon'>
                          <LazyLoadImage src={item.imageMenu} className='img-md' />
                        </div>
                        {item.showHeaderIcon === true ? <div>{this.props.t(item.content)}</div> : ""}
                      </Link>
                    ) : ['918Kiss'].includes(item.name) ? (
                      <Link to={item.route} className='headerLink'>
                        <div className='menu-icon'>
                          <LazyLoadImage src={item.imageMenu} className='img-md' />
                        </div>
                        {item.showHeaderIcon === true ? <div>{this.props.t(item.content)}</div> : ""}
                      </Link>
                    ) : item.name === 'Mobile' ? (
                      <Link to={item.route} className='headerSVG-align'>
                        <SVGMaster name='mobile' className='icon-sm icon-header headerLink'></SVGMaster>
                        {item.showHeaderIcon === true ? <div>{this.props.t(item.content)}</div> : ""}
                      </Link>
                    ) : item.name === 'Home' ? (
                      <Link to={item.route} className='headerSVG-align'>
                        {window.merchantCode === '096' ?
                          <div className='menu-icon'>
                            <LazyLoadImage className='menu-icon-png' src={item.imageMenu} />
                          </div>
                        : <MdHome className='icon-sm headerLink'></MdHome>
                        }
                        {item.showHeaderIcon === true ? <div>{this.props.t(item.content)}</div> : ""}
                      </Link>
                    ) : ['Poker', 'Promotions', 'AffiliateNav', 'VIP'].includes(item.name) && item.route ? (
                      <Link to={item.route} className='headerLink position-relative justify-content-center'
                        onMouseEnter = {() => {this.toggleHover(item.id);}}
                        onMouseLeave = {() => {this.toggleHover('');}}>
                        {item.showHeaderIcon === true ? 
                         this.state.isHovered === item.id ? 
                          // close icon hover effect according portal-settings.json (headerIconHover: false)
                          <div className='menu-icon'>
                            <LazyLoadImage className='menu-icon-png' 
                              src={settings.headerIconHover === false ? item.imageMenu : item.imageMenu_ov} />
                          </div> :
                          <div className='menu-icon'>
                            <LazyLoadImage className='menu-icon-png' src={item.imageMenu} /> 
                          </div>: ''}
                        <div>{this.props.t(item.content)}</div>
                      </Link>
                    ) : ['LiveScore'].includes(item.name) && item.route && item.isLive ? (
                      <Link to={item.route} className='headerLink position-relative justify-content-center'
                        onMouseEnter = {() => {this.toggleHover(item.id);}}
                        onMouseLeave = {() => {this.toggleHover('');}}>
                        {item.showHeaderIcon === true ?
                          this.state.isHovered === item.id ?
                          <div className='menu-icon'>
                            <LazyLoadImage className='menu-icon-png' 
                              src={settings.headerIconHover === false ? item.imageMenu : item.imageMenu_ov} />
                          </div> :
                          <div className='menu-icon'>
                            <LazyLoadImage className='menu-icon-png' src={item.imageMenu} />
                          </div>
                          : <LazyLoadImage className='live-icon' src='/public/html/images/drawer_img/menu_icon_live.gif' />
                        }
                        <div className='live-icon-text'>{this.props.t(item.content)}</div>
                      </Link>
                    ) : ['LiveTV'].includes(item.name) && item.route ? (
                      <Link to={item.route} className='headerLink position-relative justify-content-center'
                        onMouseEnter = {() => {this.toggleHover(item.id);}}
                        onMouseLeave = {() => {this.toggleHover('');}}>
                        {item.showHeaderIcon === true && item.isLive ? 
                          this.state.isHovered === item.id ?
                            <div className='menu-icon'>
                              <LazyLoadImage className='menu-icon-png' 
                                src={settings.headerIconHover === false ? item.imageMenu : item.imageMenu_ov} />
                            </div> :
                            <div className='menu-icon'>
                              <LazyLoadImage className='menu-icon-png' src={item.imageMenu} />
                            </div>
                          : <LazyLoadImage className='live-icon' src='/public/html/images/drawer_img/menu_icon_live.gif' />
                        }
                        <div className='live-icon-text'>{this.props.t(item.content)}</div>
                      </Link>
                    ) : item.getIndexPage === true ? (
                      <Link to={item.route} className='headerLink position-relative justify-content-center'
                        onMouseEnter = {() => {this.toggleHover(item.id);}}
                        onMouseLeave = {() => {this.toggleHover('');}}>
                          {item.showHeaderIcon === true ?
                            this.state.isHovered === item.id ?
                            <div className='menu-icon'>
                              <LazyLoadImage className='menu-icon-png' 
                                src={settings.headerIconHover === false ? item.imageMenu : item.imageMenu_ov} />
                            </div> :
                            <div className='menu-icon'>
                              <LazyLoadImage className='menu-icon-png' src={item.imageMenu} />
                            </div> : '' }
                          <div>{this.props.t(item.content)}</div>
                        </Link>
                      ) : (
                        <div className='headerLink position-relative justify-content-center'
                          onMouseEnter={() => { this.toggleHover(item.id); }}
                          onMouseLeave={() => { this.toggleHover(''); }}>
                          {item.showHeaderIcon === true ?
                            this.state.isHovered === item.id ?
                            <div className='menu-icon'>
                              <LazyLoadImage className='menu-icon-png' 
                                src={settings.headerIconHover === false ? item.imageMenu : item.imageMenu_ov}/>
                            </div> :
                            <div className='menu-icon'>
                              <LazyLoadImage className='menu-icon-png' src={item.imageMenu} />
                            </div> : ''}
                          <div>{this.props.t(item.content)}</div>
                        </div>
                      )}
                    </div>

                    <div id={item.name} className={`submenu-container d-flex ${this.state.showBorder === item.name ? 'borderNav' : ''}`}>
                      <div className='container'>
                        <div
                          className={`row d-flex align-items-center  ${
                            item.name === 'Lottery' ? 'justify-content-center' : 'justify-content-start'
                            } submenu-container-item `}>
                          {(this.state.displaySubmenu || []).map((submenu) => (
                            <Link
                              key={submenu.id}
                              to={submenu.route === '' ? '#' : submenu.route}
                              onMouseEnter={() => this.switchOpacity(submenu.name, 'opacity')}
                              onMouseLeave={() => this.switchOpacity('', '')}
                              onClick={() => {
                                this.navigateToPage(submenu);
                              }}
                              className={`col-sm-6 col-md-2 col-lg-2 text-center p-l-0 p-r-0 route-hover 
                                ${
                                this.state.hoverName === submenu.name && this.state.turnOpacity !== ''
                                  ? ''
                                  : this.state.hoverName === '' && this.state.turnOpacity === ''
                                    ? ''
                                    : 'opacity-5'
                                }
                                ${
                                submenu.route === '/qqkeno' || submenu.route === '/qqkeno-thai'
                                  ? 'col-lg-2 col-md-2 col-sm-6 align-items-center justify-content-center text-center'
                                  : ''
                                }
                                  ${this.state.showSubMenu && this.state.itemIdOnHover === item.submenu ? 'd-block' : 'd-none'}`}>
                              <div
                                className='submenu-hover'
                                onMouseOver={() => {
                                  this.onMouseEnterHandler(submenu.id);
                                }}
                                onMouseLeave={this.onMouseLeaveHandler}>
                                <div className='icon'>
                                  {/* close provider hover effect according portal-settings.json (providerImgHover: false) */}
                                  <img className={`submenu-item-img ${this.state.submenuIdOnHover === submenu.id ? 'd-inline' : 'd-none'}`}
                                    src={settings.providerImgHover === false ? submenu.img : submenu.imghover}></img>
                                  <img className={`submenu-item-img ${this.state.submenuIdOnHover !== submenu.id ? 'd-inline' : 'd-none'}`}
                                    src={submenu.img}></img>
                                  <img className={`submenu-item-icon ${this.state.submenuHotProvider !== submenu.isHotProvider ? 'd-inline' : 'd-none'}`}
                                    src={hotIcon}></img>
                                  <div className='submenu-item-box m-10'>
                                    <div className=' submenu-item-text'>{this.props.t(submenu.content)}</div>
                                  </div>
                                </div>
                              </div>
                            </Link>
                          ))}
                        </div>
                      </div>
                    </div>
                    <div className='nav-bottom-border'> </div>
                  </li>
                }
              </div>
            ))}
          </div>
        ) : (
          <Row className='d-flex justify-content-center w-100 toolbar_navigation-items infoCentre'>
            {this.props.t('faq:faq.infoCentre')}
          </Row>
        )}
      </div>
    );
  }
}
export default connect(mapStateToProps)(withTranslation('faq')(withRouter(HeaderMenu)));