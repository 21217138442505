// library
import React from 'react';
import { Translation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { mapStateToProps } from 'root/redux/selector/selector';
import PopupWrapper from '../../../../../Share/popup/popup-wrapper/popup-wrapper';
// import DateTimePicker from 'root/webapp/shared/date-time-picker/date-time-picker';
import PopupHeader from '../../../../../Share/popup/popup-header/popup-header';
import DateTimePicker from '../../../../../Share/date-time-picker.js/date-time-picker';

class EditDOBPopUp extends React.Component {
    props = null;

    constructor(props) {
        super();
        this.props = props;
    }

    componentDidMount() {}

    componentWillUnmount() {
        this.setState = () => {
            return;
        };
    }

    render() {
        const { isOpen, submitEditDOB, registerAge, day, month, year, fieldName, onDateChanged, screen, isSubmitting, togglePopup } = this.props;

        let dateTimePickerProps = {
            registerAge,
            fieldName,
            onDateChanged,
            day,
            month,
            year,
            displayDigitMonth: true,
            displayDigitDay: true,
            ordering: ['day', 'month', 'year'],
        };

        return (
            <Translation>
                {(t) => (
                    <PopupWrapper
                        isOpen={isOpen}
                        overlayClassName={`${screen.isMobile ? 'm-' : ''}dob-verify-popup-overlay`}
                        className={`${screen.isMobile ? 'm-' : ''}dob-verify-popup-container`}
                        closeFunc={() => {
                            togglePopup && togglePopup('editDob');
                        }}
                    >
                        <PopupHeader
                            title={t('settings:settings.memberVerificationForm.editDob', 'Edit Date of Birth')}
                            closeFunc={() => {
                                togglePopup && togglePopup('editDob');
                            }}
                        />

                        <div className='standard-popup-modal-body dob-verify-popup-body'>
                            <div className='popup-body-content'>
                                <div className='standard-form-field dob-field'>
                                    <label>{t('settings:settings.memberVerificationForm.enterDOB', 'Enter Date of Birth')}</label>
                                    <div className='select-date-seperate'>
                                        <DateTimePicker {...dateTimePickerProps} />
                                    </div>
                                </div>
                                <div className='standard-error-validation'>
                                    {t('settings:settings.memberVerificationForm.editDobNote', 'You are only allowed to edit your Date of Birth once.')}
                                </div>
                            </div>
                        </div>

                        <div className='standard-popup-modal-footer dob-verify-popup-footer'>
                            <div className='standard-button-container'>
                                <button
                                    className='standard-button standard-modal-submit-button'
                                    disabled={isSubmitting}
                                    onClick={() => {
                                        submitEditDOB();
                                        togglePopup && togglePopup('editDob');
                                    }}
                                >
                                    <span>{t('transaction:transaction.deposit.localPay.submit', 'Submit')}</span>
                                </button>
                                <button
                                    className='standard-button standard-modal-cancel-button'
                                    onClick={() => {
                                        togglePopup && togglePopup('editDob');
                                    }}
                                >
                                    <span>{t('global:entity.action.cancel', 'Cancel')}</span>
                                </button>
                            </div>
                        </div>
                    </PopupWrapper>
                )}
            </Translation>
        );
    }
}

export default connect(mapStateToProps)(withTranslation(['settings', 'transaction'])(EditDOBPopUp));
