import classnames from 'classnames';
import React, { Component } from 'react';
import Select from 'react-dropdown-select';
import { Trans, Translation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Col, Nav, NavItem, NavLink, Row, TabContent, TabPane, Modal, ModalHeader, ModalFooter } from 'reactstrap';
import Loading from '../../components/Loading/Loading';
import { mapStateToProps } from '../../redux/selector/selector';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { mbb, cimb, pbe, rhb, hlb, amb, uob, rakyat, ocbc, hsbc, islam, affin, alliance, bsn, citi, scb, bankOn } from './111_Withdraw_Items_Data';
import { RiErrorWarningLine } from "react-icons/ri";
class Withdraw extends Component {
  isMount = true;
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 'withdraw',
      mainWallet: {},
      bankList: [],
      bankBranch: '',
      bankName: '',
      accountName: '',
      accountNumber: '',
      bankId: '',
      memberBankId: '',
      amount: '',
      disabledBankBranch: false,
      disabledAccountName: false,
      disabledAccountNumber: false,
      disabledOtherBankBranch: false,
      iconLoading: false,
      limit: {},
      bankNameErrMsg: [],
      accountNumberErrMsg: [],
      amountErrMsg: [],
      bankBranchErrMsg: [],
      disabledButton: false,
      showOtherBankName: false,
      message: '',
      status: '',
      bankActive: '',
      bankHover: '',
    };
  }

  methodoption = [
    { label: 'EEZIEPAY', value: 'EEZIEPAY' },
    { label: 'HELP2PAY', value: 'HELP2PAY' },
  ];
  bankoption = [
    { label: 'MAYBANK', value: 'MAYBANK' },
    { label: 'CIMB BANK', value: 'CIMB BANK' },
  ];

  handleBankChange = (value) => {
    value = [value];
    let bankBranch = '';
    let disabledBankBranch = false;
    let disabledAccountName = false;
    let disabledAccountNumber = false;
    let disabledOtherBankBranch = false;
    let accountName = '';
    let bankName = '';
    let accountNumber = '';
    let memberBankId = '';
    let bankId = 0;
    if (value && value.length > 0) {
      this.setState({ showOtherBankName: false });
      if (value[0].bankId !== '0') {
        this.setState({ bankActive: value[0].bankId });
        for (let i = 0; i < this.state.bankList.length; i++) {
          const bank = this.state.bankList[i];
          if (bank.bankId === value[0].bankId) {
            bankBranch = bank.bankBranch;
            accountName = updateAccountNumber(bank, this.props);
            bankName = bank.bankName;
            accountNumber = bank.accountNumber || '';
            memberBankId = bank.memberBankId;
            bankId = bank.bankId;
            if (bank.haveBank) {
              disabledBankBranch = true;
              disabledAccountName = true;
              disabledAccountNumber = true;
              disabledOtherBankBranch = true;
            }
            break;
          }
        }
      } else {
        bankName = '';
        bankId = 0;
        accountName = this.props.user && this.props.user.account && this.props.user.account.name;
        let memberBank = this.state.memberBankList.filter((bank) => {
          return bank.bankId === null;
        })[0];
        if (memberBank) {
          bankId = memberBank.bankId;
          accountName = memberBank.accountHolder;
          accountNumber = memberBank.accountNumber;
          bankName = memberBank.bankName || '';
          bankBranch = memberBank.bankBranch;
          disabledBankBranch = true;
          disabledAccountName = true;
          disabledAccountNumber = true;
          disabledOtherBankBranch = true;
        }
        this.setState({ showOtherBankName: true });
      }
    }

    if (this.isMount) {
      this.setState(
        {
          bankId: bankId,
          memberBankId: memberBankId,
          bankBranch: bankBranch,
          accountName: accountName,
          accountNumber: accountNumber,
          bankName: bankName,
          disabledBankBranch: disabledBankBranch,
          disabledAccountName: disabledAccountName,
          disabledAccountNumber: disabledAccountNumber,
          disabledOtherBankBranch: disabledOtherBankBranch,
        },
        () => {
          this.validateAccountNumber();
        }
      );
    }

    function updateAccountNumber(bank, props) {
      if (bank.status === 'R') {
        return bank.accountName;
      } else {
        return props.user.account.name;
      }
    }
  };

  handleBankNameChange = (event) => {
    this.setState({ bankName: event.target.value }, () => {
      this.validateBankName();
    });
  };

  handleBankBranchChange = (event) => {
    this.setState({ bankBranch: event.target.value }, () => {
      this.validateBankBranch();
    });
  };

  handleAccountNumberChange = (event) => {
    if (this.isNumber(event)) {
      this.setState({ accountNumber: event.target.value }, () => {
        this.validateAccountNumber();
      });
    }
  };

  handleAmountChange = (event) => {
    this.setState({ amount: event.target.value }, () => {
      this.validateAmount();
    });
  };

  isNumber = (event) => {
    return /^\d*$/.test(event.target.value);
  };

  handleSubmit = () => {
    this.setState({ showLoading: false, disabledButton: true }, () => {
      Promise.all([this.validateAmount(), this.validateAccountNumber()]).then(() => {
        let error = this.checkError();
        if (error === false) {
          this.getMainWallet().then((mainWallet) => {
            if (this.state.amount > mainWallet.balance) {
              this.setState({
                showLoading: false,
                disabledButton: false,
                status: 'F',
                message: this.props.t('transaction:transaction.transfer.insuffficientMainWallet'),
                visible: true,
              });
              return;
            }
            if (this.checkError()) {
              this.setState({ showLoading: false, disabledButton: false });
              return;
            }

            const { accountName, accountNumber, bankId, bankName, amount } = this.state;
            let memberBankIdInit = 0;
            if (bankId === 'o' || bankId === 0 || bankId === null) {
              let memberBankFinal = this.state.memberBankList.filter((m) => m.bankId === null)[0];
              if (memberBankFinal != null) {
                memberBankIdInit = memberBankFinal.memberBankId;
              }
            } else {
              let memberBankFinal = this.state.memberBankList.filter((m) => m.bankId === bankId)[0];
              if (memberBankFinal != null) {
                memberBankIdInit = memberBankFinal.memberBankId;
              }
            }

            let withdrawObj = {
              accountName: accountName,
              accountNumber: accountNumber,
              memberBankId: memberBankIdInit,
              memberBankBankId: bankId,
              amount: amount,
              bankName: bankName,
              merchantCode: window.merchantCode,
              transactionOption: 'B',
            };

            window.SPL_Transaction.requestWithdraw(withdrawObj).then((data) => {
              if (data.status === 'Fail') {
                this.setState({ status: 'F', message: this.props.t(data.errMsg), visible: true, });
              } else {
                this.setState(
                  {
                    status: 'S',
                    message: this.props.t('transaction:transaction.withdrawal.request-success'),
                    amount: '',
                    accountNumber: '',
                    bankBranch: '',
                    bankName: '',
                    bankId: '',
                    visible: true,
                  },
                  () => {
                    setTimeout(() => {
                      this.setState({ message: '', visible: false });
                    }, 5000);
                  }
                );
                this.getMainWallet();
              }
              this.setState({ showLoading: false, disabledButton: false });
            });
          });
        } else {
          this.setState({ showLoading: false, disabledButton: false });
        }
      });
    });
  };

  componentDidMount() {
    this.isIdentity();
    this.getMainWallet();
    this.loadBankAccounts();
    this.getRemainingAvailableWithdrawDetails();
    this.isMount = true;

    if (this.props.screen.viewType === 'web' && this.props.location.pathname === '/withdraw') {
      this.props.history.push('/myaccount/withdraw');
    } else if (this.props.screen.viewType === 'mobile' && this.props.location.pathname === '/myaccount/withdraw') {
      this.props.history.push('/fund');
      this.props.history.push('/withdraw');
    }
  }

  componentWillUnmount() {
    this.isMount = false;
  }

  getMainWallet() {
    this.setState({ iconLoading: true });
    let component = this;
    return new Promise(function (resolve, reject) {
      window.SPL_Member.getMainWallet()
        .then((mainWallet) => {
          if (component.isMount) {
            component.setState({ mainWallet: mainWallet.data, iconLoading: false });
            resolve(mainWallet.data);
          }
        })
        .catch((err) => {
          if (component.isMount) {
            component.setState({ iconLoading: false });
          }
        });
    });
  }

  loadBankAccounts() {
    const { t } = this.props;
    window.SPL_Transaction.loadAllOwnedAndUnOwnedBank().then((data) => {
      let otherBanks = { bankId: '0', bankName: t('transaction:transaction.withdrawal.otherBanks'), bankBranch: null, memberBankId: '0' };
      data.bankList.push(otherBanks);
      if (this.isMount) {
        this.setState({ bankList: data.bankList, memberBankList: data.memberBankList });
      }
    });
  }

  getRemainingAvailableWithdrawDetails() {
    window.SPL_Transaction.getRemainingAvailableWithdrawDetails().then((limit) => {
      for (const key in limit) {
        if (typeof limit[key] === 'number') {
          if(this.props.user && this.props.user.account && this.props.user.account.currency !== 'VND') {
            limit[key] = window.SPL_Other.formatAmount(limit[key]);
          }
        } else {
          limit[key] = this.props.t(limit[key]);
        }
      }
      if (this.isMount) {
        this.setState({ limit: limit });
      }
    });
  }

  validateBankName = () => {
    window.SPL_Transaction.validateBankName(this.state.bankName).then((errMsg) => {
      if (this.isMount) {
        this.setState({ bankNameErrMsg: errMsg });

        this.checkError();
      }
    });
  };

  validateBankBranch = () => {
    window.SPL_Transaction.validateBankBranch(this.state.bankBranch).then((errMsg) => {
      if (this.isMount) {
        for (let i = 0; i < errMsg.length; i++) {
          if (errMsg[i] === 'BANK_BRANCH_IS_REQUIRED') {
            errMsg[i] = `inputHelpBlock:inputHelpBlock.transfer.bankBranchRequired`;
          }
        }
        this.setState({ bankBranchErrMsg: errMsg });

        this.checkError();
      }
    });
  };

  validateAccountNumber = () => {
    window.SPL_Transaction.validateAccountNumber(this.state.accountNumber).then((errMsg) => {
      if (this.isMount) {
        this.setState({ accountNumberErrMsg: errMsg });
        this.checkError();
      }
    });
  };

  validateAmount = () => {
    window.SPL_Transaction.validateAmount(this.state.amount, this.state.limit).then((errMsg) => {
      if (this.isMount) {
        this.setState({ amountErrMsg: errMsg });
        this.checkError();
      }
    });
  };

  isIdentity = () => {
    this.setState({ accountName: this.props.user && this.props.user.account && this.props.user.account.name });
  };

  closeModal = () => {
    this.setState({ visible: false });
  };

  checkError() {
    if (this.state.showOtherBankName) {
      if (
        this.state.amountErrMsg.length > 0 ||
        this.state.accountNumberErrMsg.length > 0 ||
        this.state.accountNumber === ''
      ) {
        this.setState({ disabledButton: true });
        return true;
      } else {
        this.setState({ disabledButton: false });
        return false;
      }
    } else {
      if (this.state.amountErrMsg.length > 0 || this.state.accountNumberErrMsg.length > 0 || this.state.accountNumber === '') {
        this.setState({ disabledButton: true });
        return true;
      } else {
        this.setState({ disabledButton: false });
        return false;
      }
    }
  }

  hoverBank = (id) => {
    this.setState({ bankHover: id });
  }

  render() {
    // const { history } = this.props;
    const { account } = this.props.user;
    const { limit } = this.state;
    return (
      this.isMount && (
        <Translation>
          {(t) => (
            <div className='app-withdraw d-flex justify-content-center'>
              <Modal
                className='d-flex justibtnfy-content-center align-items-center flex-column login-modal-dialog'
                bodyopenclassname='modal-open'
                isOpen={this.state.visible} >
                <ModalHeader className='w-100'>
                  <Col xs='10' sm='10' className='d-flex'>
                    <div className='h5'>
                      <div>{this.state.message}</div>
                    </div>
                  </Col>
                </ModalHeader>
                <ModalFooter className='w-100 h-auto p-10 bg-white d-flex justify-content-center'>
                  <button
                    className='primary-btn'
                    onClick={() => this.closeModal()}
                  >
                    <span>
                      {t(
                        'global:global.verification.close'
                      )}
                    </span>
                  </button>
                </ModalFooter>
              </Modal>
              {this.state.showLoading && <Loading></Loading>}

              <div className='d-none d-md-block'>
                <div className='row d-flex justify-content-center'>
                  <div className='account-col withdraw-bg'>
                    <Row><div className='form-spacing form-group withdraw-left-content'>
                      <div className='d-flex'>
                        <Col xs={12} md={2} className='d-flex'>
                          <label htmlFor='Bank' className='form-label bank-label'>
                            {t('transaction:transaction.withdrawal.bank')} <span className='text-danger m-r-5'>*</span>
                          </label>
                        </Col>
                        <Col xs={12} md={10}>
                          <Row>
                            {this.state.bankList.map((bank, index) => {
                              return (
                                bank.bankId !== '0' ? (
                                  <Col xs='2' className='bank-div' key={index} onClick={() => this.handleBankChange(bank)} onMouseEnter={() => this.hoverBank(bank.bankId)} onMouseLeave={() => this.hoverBank('')}>
                                    <Row>
                                      <Col xs='12' className={`position-relative bank-img ${this.state.bankActive === bank.bankId || this.state.bankHover === bank.bankId ? 'bankActive' : ''}`}>
                                        {bank.bankId === 60001 ? (
                                          <LazyLoadImage src={mbb} />
                                        ) : bank.bankId === 60002 ? (
                                          <LazyLoadImage src={cimb} />
                                        ) : bank.bankId === 60003 ? (
                                          <LazyLoadImage src={pbe} />
                                        ) : bank.bankId === 60004 ? (
                                          <LazyLoadImage src={rhb} />
                                        ) : bank.bankId === 60005 ? (
                                          <LazyLoadImage src={hlb} />
                                        ) : bank.bankId === 60006 ? (
                                          <LazyLoadImage src={amb} />
                                        ) : bank.bankId === 60007 ? (
                                          <LazyLoadImage src={uob} />
                                        ) : bank.bankId === 60008 ? (
                                          <LazyLoadImage src={rakyat} />
                                        ) : bank.bankId === 60009 ? (
                                          <LazyLoadImage src={ocbc} />
                                        ) : bank.bankId === 60010 ? (
                                          <LazyLoadImage src={hsbc} />
                                        ) : bank.bankId === 60011 ? (
                                          <LazyLoadImage src={islam} />
                                        ) : bank.bankId === 60012 ? (
                                          <LazyLoadImage src={affin} />
                                        ) : bank.bankId === 60013 ? (
                                          <LazyLoadImage src={alliance} />
                                        ) : bank.bankId === 60017 ? (
                                          <LazyLoadImage src={bsn} />
                                        ) : bank.bankId === 60018 ? (
                                          <LazyLoadImage src={citi} />
                                        ) : bank.bankId === 60019 ? (
                                          <LazyLoadImage src={scb} />
                                        ) : (
                                                                          void 0
                                                                        )}
                                        <div className={`bankOn-div ${this.state.bankActive === bank.bankId || this.state.bankHover === bank.bankId ? '' : 'd-none'}`}>
                                          <LazyLoadImage src={bankOn} />
                                        </div>
                                      </Col>
                                      <Col xs='12' className='bank-name'>
                                        {t(bank.bankName)}
                                      </Col>
                                    </Row>

                                  </Col>
                                ) : (
                                    <Col xs='12' className='bank-div other-bank-col' key={index} onClick={() => this.handleBankChange(bank)}>
                                      <u className='other-banks'>{t(bank.bankName)}</u>
                                    </Col>
                                  )
                              );
                            })}

                          </Row>
                          {/* <Select
                                className='form-control-inner'
                                placeholder={t('bank:bank.newBank.selectABank')}
                                options={this.state.bankList}
                                labelField={t('bankName')}
                                valueField='bankId'
                                key='bankId'
                                searchable={false}
                                value={this.state.bankId}
                                onChange={(value) => {
                                  this.handleBankChange(value);
                                }}
                              /> */}
                          <Col xs={12} md={12} lg={12} className='form-spacing'>
                            {this.state.bankNameErrMsg.map((errMsg, index) => {
                              return (
                                <div key={index} className='invalid-feedback text-danger'>
                                  <Trans i18nKey={errMsg}></Trans>
                                </div>
                              );
                            })}
                          </Col>
                        </Col>
                      </div>
                    </div>
                      <div className='withdraw-right-content'>

                        <Col xs='12' className='important-notice-content'>
                          <span className='important-notice-title'>IMPORTANT NOTICE</span>
                          <ul className='important-notice-bullet'>
                            <li>
                              <span>1</span>
                              <p>Kindly check with our <span className='livechat-24'>24/7 LIVECHAT </span>
                              if your transaction is pending for more
                              than 10 minutes.</p>

                            </li>
                            <li>
                              <span>2</span>
                              <p>For further inquiries, kindly contact our
                              <span className='livechat-24'> 24/7 LIVECHAT. </span>Thank you.</p>

                            </li>
                          </ul>
                        </Col>

                      </div></Row>
                    <div className='account-divider'></div>
                    {/* <Col
                          xs={12}
                          md={12}
                          lg={12}
                          className={`form-spacing form-group w-100 ${this.state.showOtherBankName ? 'd-block' : 'd-none'}`}>
                          <div className='d-flex'>
                            <Col xs={12} md={4}>
                              <label htmlFor='Bank' className='form-label'>
                                {t('bank:bank.newBank.bankName')} <span className='text-danger m-r-5'>*</span>
                              </label>
                            </Col>
                            <Col xs={12} md={7}>
                              <div>
                                <input
                                  name='bankName'
                                  className={`form-control-inner ${this.state.bankNameErrMsg.length > 0 ? 'is-invalid' : ''}`}
                                  id='bankName'
                                  placeholder={t('bank:bank.newBank.bankName')}
                                  value={this.state.bankName}
                                  onChange={this.handleBankNameChange}
                                  onBlur={this.validateBankName}
                                  disabled={this.state.disabledBankName}
                                />
                              </div>

                              <Col xs={12} md={12} lg={12} className='form-spacing'>
                                {this.state.bankNameErrMsg.map((errMsg, index) => {
                                  return (
                                    this.state.showOtherBankName && (
                                      <div key={index} className='invalid-feedback text-danger'>
                                        <Trans i18nKey={errMsg}></Trans>
                                      </div>
                                    )
                                  );
                                })}
                              </Col>
                            </Col>
                          </div>
                        </Col> */}
                    {/* <Col xs={12} md={12} lg={12} className='form-spacing form-group w-100'>
                          <div className='d-flex'>
                            <Col xs={12} md={4}>
                              <label htmlFor='BankBranch' className='form-label '>
                                {t('bank:bank.newBank.bankBranch')} <span className='text-danger m-r-5'>*</span>
                              </label>
                            </Col>
                            <Col xs={12} md={7}>
                              <input
                                name='bankbranch'
                                className={` form-control-inner ${this.state.bankBranchErrMsg.length > 0 ? 'form-contol is-invalid' : ''}`}
                                id='bankbranch'
                                placeholder={t('bank:bank.newBank.bankBranch')}
                                value={this.state.bankBranch}
                                onChange={this.handleBankBranchChange}
                                onBlur={this.validateBankBranch}
                                disabled={this.state.disabledBankBranch}
                              />
                              <Col xs={12} md={12} lg={12} className='form-spacing'>
                                {this.state.bankBranchErrMsg.map((errMsg, index) => {
                                  return (
                                    <div key={index} className='invalid-feedback text-danger'>
                                      <Trans i18nKey={errMsg}></Trans>
                                    </div>
                                  );
                                })}
                              </Col>
                            </Col>
                          </div>
                        </Col> */}
                    <Row>
                      <Col xs={12} md={8} lg={8} className='form-spacing form-group w-100 withdraw-form-spacing'>
                        <div className='d-flex'>
                          <Col xs={12} md={3} className='d-flex align-items-center'>
                            <label htmlFor='AccountName' className='form-label'>
                              {t('bank:bank.newBank.accountName')}
                              <span className='text-danger m-r-5'>*</span>
                            </label>
                          </Col>
                          <Col xs={12} md={7}>
                            <div className='form-control-inner disable-input d-flex align-items-center'>{this.state.accountName}</div>
                          </Col>
                        </div>
                      </Col>
                      <Col xs={12} md={8} lg={8} className='form-spacing form-group w-100 withdraw-form-spacing'>
                        <div className='d-flex'>
                          <Col xs={12} md={3} className='d-flex align-items-center'>
                            <label htmlFor='AccountNumber' className='form-label'>
                              {t('transaction:transaction.withdrawal.accountNumber')}
                              <span className='text-danger m-r-5'>*</span>
                            </label>
                          </Col>
                          <Col xs={12} md={7}>
                            <input
                              name='accountnumber'
                              className={`form-control-inner ${this.state.accountNumberErrMsg.length > 0 ? 'is-invalid' : ''}`}
                              id='accountnumber'
                              placeholder='Exp : 1234567'
                              value={this.state.accountNumber}
                              onChange={this.handleAccountNumberChange}
                              onBlur={this.validateAccountNumber}
                              disabled={this.state.disabledAccountNumber}
                            />

                            <Col xs={12} md={12} lg={12} className='form-spacing'>
                              {this.state.accountNumberErrMsg.map((errMsg, index) => {
                                return (
                                  <div key={index} className='invalid-feedback text-danger'>
                                    <Trans i18nKey={errMsg}></Trans>
                                  </div>
                                );
                              })}
                            </Col>
                          </Col>
                        </div>
                      </Col>
                      <Col xs={12} md={8} lg={8} className='form-spacing form-group w-100'>
                        <div className='d-flex'>
                          <Col xs={12} md={3} className='d-flex align-items-center'>
                            <label htmlFor='Amount' className='form-label'>
                              {t('transaction:transaction.withdrawal.amount')} <span className='text-danger m-r-5'>*</span>
                            </label>
                          </Col>
                          <Col xs={12} md={7}>
                            <input
                              name='amount'
                              className={`form-control-inner ${
                                this.state.amountErrMsg.length > 0 ? 'form-control-inner is-invalid-custom' : ''
                                }`}
                              id='amountInput'
                              placeholder={t('global:global.form.online-transfer-amount-placeholder', {
                                min: this.state.limit.minLimit,
                                max: this.state.limit.maxLimit,
                              })}
                              type='number'
                              min='0'
                              value={this.state.amount}
                              onChange={this.handleAmountChange}
                              onBlur={this.validateAmount}
                            />

                            <Col xs={12} md={12} lg={12} className='form-spacing'>
                              {this.state.amountErrMsg.map((errMsg, index) => {
                                if (errMsg.includes('min_maxAmount')) {
                                  return (
                                    <div key={index} className='invalid-feedback text-danger'>
                                      <Trans i18nKey={errMsg}></Trans> {account.currency} {this.state.limit.minLimit} - {account.currency}{' '}
                                      {this.state.limit.maxLimit}
                                    </div>
                                  );
                                } else {
                                  return (
                                    <div key={index} className='invalid-feedback text-danger'>
                                      <Trans i18nKey={errMsg}></Trans>
                                    </div>
                                  );
                                }
                              })}
                            </Col>
                          </Col>
                        </div>
                      </Col>

                      <Col xs='12' md='8' lg='8' className='withdraw-form-spacing'>
                        <Col xs={12} md={{ size: 7, offset: 3 }} lg={{ size: 7, offset: 3 }} >
                          <div className='daily-limit'> <RiErrorWarningLine className='icon-xs' />  Daily Limit : 30,000.00 / Daily Count Balance : Unlimited</div>

                        </Col>
                      </Col>
                      <Col xs={{ size: 3 }} md={{ size: 7 }} className='justify-content-start'>
                        {this.state.status !== '' && this.state.status === 'F' ? (
                          <div className='invalid-feedback text-danger m-l-5'>{this.state.message}</div>
                        ) : this.state.status !== '' && this.state.status === 'S' ? (
                          <div className='invalid-feedback text-success  m-l-5'>{this.state.message}</div>
                        ) : (
                              void 0
                            )}
                      </Col>

                      <Col xs={12} md={8} lg={8} className='d-flex justify-content-end button-group form-spacing'>
                        <div className='w-100 d-flex'>
                          <Col xs={3} md={3} className='p-l-0 p-r-0'></Col>
                          <Col xs={9} md={9} className='justify-content-start'>
                            <button
                              type='submit'
                              className='primary-btn button-submit border-0 button-account'
                              onClick={this.handleSubmit}
                              disabled={this.state.disabledButton}>
                              <span>{t('transaction:transaction.withdrawal.withdraw')}</span>
                            </button>
                          </Col>
                        </div>
                      </Col>
                    </Row>



                  </div>
                </div>
              </div>
              <div className='content-mobile-account d-block d-md-none content'>
                <div className='row d-flex justify-content-center content-inner'>
                  <div className=''>
                    <TabContent activeTab={this.state.activeTab} className='deposit-tab-content'>
                      {this.state.activeTab === 'withdraw' && (
                        <TabPane tabId='withdraw' className='d-flex mobile-account-width'>
                          <div className='w-100 d-flex'>
                            <Col xs={12} md={5}>
                              <div>
                                <Col xs={12} md={12} lg={12} className='form-spacing form-group w-100'>
                                  <div className=''>
                                    <Col xs={12} md={4}>
                                      <label htmlFor='Bank' className='form-label mWithdraw-text'>
                                        {t('transaction:transaction.withdrawal.bank')} <span className='text-danger m-r-5'>*</span>
                                      </label>
                                    </Col>
                                    <Col xs={12} md={7}>
                                      <Row>
                                        {this.state.bankList.map((bank, index) => {
                                          return (
                                            bank.bankId !== '0' ? (
                                              <Col xs='3' className='bank-div' key={index} onClick={() => this.handleBankChange(bank)} onMouseEnter={() => this.hoverBank(bank.bankId)} onMouseLeave={() => this.hoverBank('')}>
                                                <Row>
                                                  <Col xs='12' className={`position-relative bank-img ${this.state.bankActive === bank.bankId || this.state.bankHover === bank.bankId ? 'bankActive' : ''}`}>
                                                    {bank.bankId === 60001 ? (
                                                      <LazyLoadImage src={mbb} />
                                                    ) : bank.bankId === 60002 ? (
                                                      <LazyLoadImage src={cimb} />
                                                    ) : bank.bankId === 60003 ? (
                                                      <LazyLoadImage src={pbe} />
                                                    ) : bank.bankId === 60004 ? (
                                                      <LazyLoadImage src={rhb} />
                                                    ) : bank.bankId === 60005 ? (
                                                      <LazyLoadImage src={hlb} />
                                                    ) : bank.bankId === 60006 ? (
                                                      <LazyLoadImage src={amb} />
                                                    ) : bank.bankId === 60007 ? (
                                                      <LazyLoadImage src={uob} />
                                                    ) : bank.bankId === 60008 ? (
                                                      <LazyLoadImage src={rakyat} />
                                                    ) : bank.bankId === 60009 ? (
                                                      <LazyLoadImage src={ocbc} />
                                                    ) : bank.bankId === 60010 ? (
                                                      <LazyLoadImage src={hsbc} />
                                                    ) : bank.bankId === 60011 ? (
                                                      <LazyLoadImage src={islam} />
                                                    ) : bank.bankId === 60012 ? (
                                                      <LazyLoadImage src={affin} />
                                                    ) : bank.bankId === 60013 ? (
                                                      <LazyLoadImage src={alliance} />
                                                    ) : bank.bankId === 60017 ? (
                                                      <LazyLoadImage src={bsn} />
                                                    ) : bank.bankId === 60018 ? (
                                                      <LazyLoadImage src={citi} />
                                                    ) : bank.bankId === 60019 ? (
                                                      <LazyLoadImage src={scb} />
                                                    ) : (
                                                                                      void 0
                                                                                    )}
                                                    <div className={`bankOn-div ${this.state.bankActive === bank.bankId || this.state.bankHover === bank.bankId ? '' : 'd-none'}`}>
                                                      <LazyLoadImage src={bankOn} />
                                                    </div>
                                                  </Col>
                                                  <Col xs='12' className='bank-name mWithdraw-text'>
                                                    {t(bank.bankName)}
                                                  </Col>
                                                </Row>

                                              </Col>
                                            ) : (
                                                <Col xs='12' className='bank-div pointer mWithdraw-text' key={index} onClick={() => this.handleBankChange(bank)}>
                                                  <u>{t(bank.bankName)}</u>
                                                </Col>
                                              )
                                          );
                                        })}
                                      </Row>
                                      {/* <Select
                                        className='form-control-inner'
                                        placeholder={t('bank:bank.newBank.selectABank')}
                                        options={this.state.bankList}
                                        labelField='bankName'
                                        valueField='bankId'
                                        key='bankId'
                                        searchable={false}
                                        value={this.state.bankId}
                                        onChange={(value) => {
                                          this.handleBankChange(value);
                                        }}
                                      /> */}
                                      <Col xs={12} md={12} lg={12} className='form-spacing'>
                                        {this.state.bankNameErrMsg.map((errMsg, index) => {
                                          return (
                                            <div key={index} className='invalid-feedback text-danger'>
                                              <Trans i18nKey={errMsg}></Trans>
                                            </div>
                                          );
                                        })}
                                      </Col>
                                    </Col>
                                  </div>
                                </Col>
                                {/* <Col
                                  xs={12}
                                  md={12}
                                  lg={12}
                                  className={`form-spacing form-group w-100 ${this.state.showOtherBankName ? 'd-block' : 'd-none'}`}>
                                  <div className=''>
                                    <Col xs={12} md={4}>
                                      <label htmlFor='Bank' className='form-label mWithdraw-text'>
                                        {t('bank:bank.newBank.bankName')} <span className='text-danger m-r-5'>*</span>
                                      </label>
                                    </Col>
                                    <Col xs={12} md={7}>
                                      <div>
                                        <input
                                          name='bankName'
                                          className={`form-control-inner ${this.state.bankNameErrMsg.length > 0 ? 'is-invalid' : ''}`}
                                          id='bankName'
                                          placeholder={t('bank:bank.newBank.bankName')}
                                          value={this.state.bankName}
                                          onChange={this.handleBankNameChange}
                                          onBlur={this.validateBankName}
                                          disabled={this.state.disabledBankName}
                                        />
                                      </div>

                                      <Col xs={12} md={12} lg={12} className='form-spacing'>
                                        {this.state.bankNameErrMsg.map((errMsg, index) => {
                                          return (
                                            this.state.showOtherBankName && (
                                              <div key={index} className='invalid-feedback text-danger'>
                                                <Trans i18nKey={errMsg}></Trans>
                                              </div>
                                            )
                                          );
                                        })}
                                      </Col>
                                    </Col>
                                  </div>
                                </Col> */}
                                {/* <Col xs={12} md={12} lg={12} className='form-spacing form-group w-100'>
                                  <div className=''>
                                    <Col xs={12} md={4}>
                                      <label htmlFor='BankBranch' className='form-label mWithdraw-text'>
                                        {t('bank:bank.newBank.bankBranch')} <span className='text-danger m-r-5'>*</span>
                                      </label>
                                    </Col>
                                    <Col xs={12} md={7}>
                                      <input
                                        name='bankbranch'
                                        className={` form-control-inner ${this.state.bankBranchErrMsg.length > 0 ? 'form-contol is-invalid' : ''}`}
                                        id='bankbranch'
                                        placeholder={t('bank:bank.newBank.bankBranch')}
                                        value={this.state.bankBranch}
                                        onChange={this.handleBankBranchChange}
                                        onBlur={this.validateBankBranch}
                                        disabled={this.state.disabledBankBranch}
                                      />
                                      <Col xs={12} md={12} lg={12} className='form-spacing '>
                                        {this.state.bankBranchErrMsg.map((errMsg, index) => {
                                          return (
                                            <div key={index} className='invalid-feedback text-danger'>
                                              <Trans i18nKey={errMsg}></Trans>
                                            </div>
                                          );
                                        })}
                                      </Col>
                                    </Col>
                                  </div>
                                </Col> */}
                                <Col xs={12} md={12} lg={12} className='form-spacing form-group w-100'>
                                  <div className=''>
                                    <Col xs={12} md={4}>
                                      <label htmlFor=' AccountName' className='form-label mWithdraw-text'>
                                        {t('bank:bank.newBank.accountName')}
                                        <span className='text-danger m-r-5'>*</span>
                                      </label>
                                    </Col>
                                    <Col xs={12} md={7}>
                                      <div className='form-control-inner disable-input d-flex align-items-center'>{this.state.accountName}</div>
                                    </Col>
                                  </div>
                                </Col>
                                <Col xs={12} md={12} lg={12} className='form-spacing form-group w-100'>
                                  <div className=''>
                                    <Col xs={12} md={4}>
                                      <label htmlFor='AccountNumber' className='form-label mWithdraw-text'>
                                        {t('transaction:transaction.withdrawal.accountNumber')}
                                        <span className='text-danger m-r-5'>*</span>
                                      </label>
                                    </Col>
                                    <Col xs={12} md={7}>
                                      <input
                                        name='accountnumber'
                                        className={`form-control-inner ${this.state.accountNumberErrMsg.length > 0 ? 'is-invalid' : ''}`}
                                        id='accountnumber'
                                        placeholder='Exp : 1234567'
                                        value={this.state.accountNumber}
                                        onChange={this.handleAccountNumberChange}
                                        onBlur={this.validateAccountNumber}
                                        disabled={this.state.disabledAccountNumber}
                                      />

                                      <Col xs={12} md={12} lg={12} className='form-spacing'>
                                        {this.state.accountNumberErrMsg.map((errMsg, index) => {
                                          return (
                                            <div key={index} className='invalid-feedback text-danger'>
                                              <Trans i18nKey={errMsg}></Trans>
                                            </div>
                                          );
                                        })}
                                      </Col>
                                    </Col>
                                  </div>
                                </Col>
                                <Col xs={12} md={12} lg={12} className='form-spacing form-group w-100'>
                                  <div className=''>
                                    <Col xs={12} md={4}>
                                      <label htmlFor='Amount' className='form-label mWithdraw-text'>
                                        {t('transaction:transaction.withdrawal.amount')} <span className='text-danger m-r-5'>*</span>
                                      </label>
                                    </Col>
                                    <Col xs={12} md={7}>
                                      <input
                                        name='amount'
                                        className={`form-control-inner ${
                                          this.state.amountErrMsg.length > 0 ? 'form-control-inner is-invalid-custom' : ''
                                          }`}
                                        id='name'
                                        placeholder={t('global:global.form.online-transfer-amount-placeholder', {
                                          min: this.state.limit.minLimit,
                                          max: this.state.limit.maxLimit,
                                        })}
                                        type='number'
                                        min='0'
                                        value={this.state.amount}
                                        onChange={this.handleAmountChange}
                                        onBlur={this.validateAmount}
                                      />
                                      <Col xs={12} md={12} lg={12} className='daily-limit'>
                                        <RiErrorWarningLine className='icon-xs' /> Daily Limit : 30,000.00 / Daily Count Balance : Unlimited
                                      </Col>
                                      <Col xs={12} md={12} lg={12} className='form-spacing'>
                                        {this.state.amountErrMsg.map((errMsg, index) => {
                                          if (errMsg.includes('min_maxAmount')) {
                                            return (
                                              <div key={index} className='invalid-feedback text-danger'>
                                                <Trans i18nKey={errMsg}></Trans> {account.currency} {this.state.limit.minLimit} - {account.currency}{' '}
                                                {this.state.limit.maxLimit}
                                              </div>
                                            );
                                          } else {
                                            return (
                                              <div key={index} className='invalid-feedback text-danger'>
                                                <Trans i18nKey={errMsg}></Trans>
                                              </div>
                                            );
                                          }
                                        })}
                                      </Col>
                                    </Col>
                                  </div>
                                </Col>
                                <div className='form-spacing'>
                                  {' '}
                                  {this.state.status !== '' && this.state.status === 'F' ? (
                                    <div className='invalid-feedback text-danger m-l-10'>{this.state.message}</div>
                                  ) : this.state.status !== '' && this.state.status === 'S' ? (
                                    <div className='invalid-feedback text-success m-l-10'>{this.state.message}</div>
                                  ) : (
                                        void 0
                                      )}
                                </div>

                                <Col xs={12} md={12} lg={12} className='d-flex justify-content-end button-group form-spacing'>
                                  <div className='w-100 d-flex'>
                                    <Col xs={12} md={12} className='justify-content-start'>
                                      <button
                                        type='submit'
                                        className='primary-btn button-submit border-0'
                                        onClick={this.handleSubmit}
                                        disabled={this.state.disabledButton}>
                                        <span>{t('transaction:transaction.withdrawal.withdraw')}</span>
                                      </button>
                                    </Col>
                                  </div>
                                </Col>
                              </div>
                            </Col>
                          </div>
                        </TabPane>
                      )}
                    </TabContent>
                  </div>
                </div>
              </div>
            </div>
          )}
        </Translation>
      )
    );
  }
}

export default connect(mapStateToProps, null)(withTranslation(['transaction', 'bank', 'settings', 'inputHelpBlock'])(withRouter(Withdraw)));
