import React, { Component } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Button, CardText, CardTitle, Form, Row, Col } from 'reactstrap';
import { v99brandLogo } from '../../../layout/MainNavigation/Common_Items_Data';
import { userAction } from '../../../redux/action';
import MultiStepProgressBar from './MultiStepProgressBar';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { mapStateToProps } from '../../../redux/selector/selector';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Loading from '../../../components/Loading/Loading';
import { Trans, Translation, withTranslation } from 'react-i18next';

class MasterForm extends Component {
    isMount = true;
    constructor(props) {
        super(props);

        // Set the intiial input values
        this.state = {
            currentStep: 1,
            username: '',
            password: '',
            confirmpassword: '',
            fullname: '',
            email: '',
            affiliateId: '',
            phone: '',
            usernameError: [],
            passwordError: [],
            confirmpasswordError: [],
            fullnameError: [],
            emailError: [],
            affiliateError: [],
            affiliateErrMsg: '',
            phoneError: [],
            telCode: '',
            telCodeList: [],
            ageTerms: false,
            disabledButton: false,
            disabledAffiliate: false,
            usernameErrMsg: '',
            passwordErrMsg: '',
            confirmPasswordErrMsg: '',
            fullnameErrMsg: '',
            emailErrMsg: '',
            phoneErrMsg: '',
            brandLogoObj: null,
            regErrMsg: [],
            memberBenefit: '',
        };

        // // Bind the submission to handleChange()
        this.handleChange = this.handleChange.bind(this);
        this.handleUserNameChange = this.handleUserNameChange.bind(this);
        this.handlePasswordChange = this.handlePasswordChange.bind(this);
        this.handleconfirmPasswordChange = this.handleconfirmPasswordChange.bind(this);
        this.handleFullnameChange = this.handleFullnameChange.bind(this);
        this.handleCurrencyChange = this.handleCurrencyChange.bind(this);
        this.handleEmailChange = this.handleEmailChange.bind(this);
        this.handlePhoneChange = this.handlePhoneChange.bind(this);
        this.handleAffiliateChange = this.handleAffiliateChange.bind(this);

        // Bind new functions for next and previous
        this._next = this._next.bind(this);
    }

    initRegisterSettings = () => {
        const { portal } = this.props;
        if (portal && portal.settings) {
            if (portal.settings.hasOwnProperty('registerHideAff')) {
                let hideAff = portal.settings.registerHideAff;
                this.setState({ hideAff });
            }

            if (portal.settings.hasOwnProperty('currencies')) {
                let currencies = portal.settings.currencies.split(',').map((c) => ({ value: c, label: c }));
                this.setState({ currencies }, () => {
                    this.getCountryInfo();
                });
            }
        }
    };

    handleCurrencyChange = (event) => {
        let currency = this.state.currencies.filter((c) => c.value === event[0].value);
        this.setState({ currency: currency });
    };

    handleChange(event) {
        const { name, value } = event.target;
        this.setState({});
    }

    componentDidMount() {
        this.isMount = true;
        this.getCountryInfo();
        this.initRegisterSettings();
        this.getBrandLogo();
        this.getAffiliateId();
        this.getMemberBenefit();
    }

    getMemberBenefit = () => {
        const { language } = this.props;
        window.SPL_Content.readMemberBenefit(language.countryLanguageKey, (res) => {
            if (this.isMount) {
                this.setState({ memberBenefit: res.data });
            }
        });
    };

    getBrandLogo = () => {
        window.SPL_Content.getBrandLogo().then((res) => {
            this.setState({ brandLogoObj: res });
        });
    };

    getCountryInfo() {
        window.SPL_Content.getCountryInfo().then((telCodeList) => {
            if (this.isMount) {
                const { language } = this.props;
                let selectedObj = this.getSelectedObj(telCodeList, language.country);
                this.setState({
                    telCodeList: telCodeList,
                    telCode: selectedObj.telCode,
                    countryCode: selectedObj.countryCode,
                    currency: selectedObj.currency,
                });
            }
        });
    }

    getSelectedObj(telCodeList, country) {
        let returnObj = {};
        for (let i = 0; i < telCodeList.length; i++) {
            const telCodeObj = telCodeList[i];
            if (telCodeObj.country === country) {
                returnObj = {
                    telCode: `+${telCodeObj.telCode}`,
                    countryCode: telCodeObj.country,
                    currency: telCodeObj.currency,
                };
            }
        }
        return returnObj;
    }

    getAffiliateId = () => {
        let queryParams = window.SPL_Other.getQueryStringParams(this.props.location.search);
        if (queryParams.hasOwnProperty('affid')) {
            this.setState({ affiliateId: queryParams.affid, disabledAffiliate: true });
        }
    };

    componentWillUnmount() {
        this.isMount = false;
    }

    handleUserNameChange = (event) => {
        this.setState({ username: event.target.value }, () => {
            this.validateUserName();
            this.checkUsernameErrMsg();
        });
    };

    handlePasswordChange = (event) => {
        this.setState({ password: event.target.value }, () => {
            this.validatePassword();
            this.checkPasswordErrMsg();
        });
    };

    handleconfirmPasswordChange = (event) => {
        this.setState({ confirmpassword: event.target.value }, () => {
            this.validateConfirmPassword();
            this.checkConfirmPasswordErrMsg();
        });
    };
    handleFullnameChange = (event) => {
        this.setState({ fullname: event.target.value }, () => {
            this.validateFullname();
            this.checkFullnameErrMsg();
        });
    };

    handleEmailChange = (event) => {
        this.setState({ email: event.target.value }, () => {
            this.validateEmail();
            this.checkEmailErrMsg();
        });
    };

    handlePhoneChange = (event) => {
        let phoneNumber = event.target.value;

        this.setState({ phone: phoneNumber }, () => {
            this.validatePhone();
            this.checkPhoneErrMsg();
        });
    };

    handleAffiliateChange = (event) => {
        this.setState({ affiliateId: event.target.value }, () => {
            this.validateAffiliate();
            this.checkAffiliateErrMsg();
        });
    };

    isNumber = (event) => {
        return /^\d*$/.test(event.target.value);
    };

    isError = (errMsg) => {
        return errMsg.length > 0;
    };

    validateUserName = () => {
        const { username } = this.state;
        let thisObj = this;
        return new Promise(function (resolve, reject) {
            window.SPL_Register.validateUsername(username, window.merchantCode).then((errMsg) => {
                thisObj.setState({ usernameError: errMsg });
                resolve(thisObj.isError(errMsg));
            });
        });
    };

    checkUsernameErrMsg = () => {
        if (this.state.username !== null || this.state.username !== '') {
            window.SPL_Register.validateUsername(this.state.username, window.merchantCode).then((errMsg) => {
                this.setState({ usernameErrMsg: errMsg.length });
            });
        }
    };

    validatePassword = () => {
        const { password, confirmpassword } = this.state;
        let thisObj = this;
        return new Promise(function (resolve, reject) {
            window.SPL_Register.validatePassword(password, confirmpassword).then((errMsg) => {
                thisObj.setState({ passwordError: errMsg });
                resolve(thisObj.isError(errMsg));
            });
        });
    };

    checkPasswordErrMsg = () => {
        if (this.state.password !== null || this.state.password !== '' || this.state.confirmpassword !== null || this.state.confirmpassword !== '') {
            window.SPL_Register.validatePassword(this.state.password, this.state.confirmpassword).then((errMsg) => {
                this.setState({ passwordErrMsg: errMsg.length });
            });
        }
    };

    validateConfirmPassword = () => {
        const { password, confirmpassword } = this.state;
        let thisObj = this;
        return new Promise(function (resolve, reject) {
            window.SPL_Register.validateConfirmPassword(password, confirmpassword).then((errMsg) => {
                thisObj.setState({ confirmpasswordError: errMsg });
                resolve(thisObj.isError(errMsg));
            });
        });
    };

    checkConfirmPasswordErrMsg = () => {
        if (this.state.password !== null || this.state.password !== '' || this.state.confirmpassword !== null || this.state.confirmpassword !== '') {
            window.SPL_Register.validateConfirmPassword(this.state.password, this.state.confirmpassword).then((errMsg) => {
                this.setState({ confirmPasswordErrMsg: errMsg.length });
            });
        }
    };

    validateFullname = () => {
        const { fullname } = this.state;
        let thisObj = this;
        return new Promise(function (resolve, reject) {
            window.SPL_Register.validateFullName(fullname, window.merchantCode).then((errMsg) => {
                thisObj.setState({ fullnameError: errMsg });
                resolve(thisObj.isError(errMsg));
            });
        });
    };

    checkFullnameErrMsg = () => {
        if (this.state.fullname !== '' || this.state.fullname !== null) {
            window.SPL_Register.validateFullName(this.state.fullname, window.merchantCode).then((errMsg) => {
                this.setState({ fullnameErrMsg: errMsg.length });
            });
        }
    };

    validateEmail = () => {
        const { email } = this.state;
        let thisObj = this;
        return new Promise(function (resolve, reject) {
            window.SPL_Register.validateEmail(email, window.merchantCode).then((errMsg) => {
                thisObj.setState({ emailError: errMsg });
                resolve(thisObj.isError(errMsg));
            });
        });
    };

    checkEmailErrMsg = () => {
        if (this.state.email !== '' || this.state.email !== null) {
            window.SPL_Register.validateEmail(this.state.email, window.merchantCode).then((errMsg) => {
                this.setState({ emailErrMsg: errMsg.length });
            });
        }
    };

    validateAffiliate = () => {
        const { affiliateId } = this.state;
        let thisObj = this;
        return new Promise(function (resolve, reject) {
            window.SPL_Register.validateAffiliate(affiliateId, window.merchantCode).then((errMsg) => {
                thisObj.setState({ affiliateError: errMsg });
                resolve(thisObj.isError(errMsg));
            });
        });
    };

    checkAffiliateErrMsg = () => {
        if (this.state.affiliateId !== '' || this.state.affiliateId !== null) {
            window.SPL_Register.validateAffiliate(this.state.affiliateId, window.merchantCode).then((errMsg) => {
                this.setState({ affiliateErrMsg: errMsg.length });
            });
        }
    };

    validatePhone = () => {
        const { phone, telCode } = this.state;
        let thisObj = this;
        return new Promise(function (resolve, reject) {
            let amendPhone = phone.toString();
            if (amendPhone.startsWith('0')) {
                amendPhone = amendPhone.substring(1, amendPhone.length);
            }
            let tempTelCode = telCode.toString();
            if (tempTelCode.startsWith('+')) {
                tempTelCode = tempTelCode.substring(1, tempTelCode.length);
            }
            let fullNumber = telCode + amendPhone;
            window.SPL_Register.validatePhone(phone, window.merchantCode, tempTelCode).then((errMsg) => {
                thisObj.setState({ phoneError: errMsg });
                resolve(thisObj.isError(errMsg));
            });
        });
    };

    checkPhoneErrMsg = () => {
        const { phone, telCode } = this.state;
        let tempTelCode = telCode.toString();
        if (tempTelCode.startsWith('+')) {
            tempTelCode = tempTelCode.substring(1, tempTelCode.length);
        }
        if (phone !== '' || phone !== null) {
            window.SPL_Register.validatePhone(phone, window.merchantCode, tempTelCode).then((errMsg) => {
                this.setState({ phoneErrMsg: errMsg.length });
            });
        }
    };

    selectCode = (countryCode) => {
        let selectedObj = this.getSelectedObj(this.state.telCodeList, countryCode);
        this.setState({ telCode: selectedObj.telCode, countryCode: selectedObj.countryCode });
    };

    handleSubmit = (event) => {
        event.preventDefault();
        const { username, password, fullname, email, phone, affiliateId, currency, telCode } = this.state;
        const { language } = this.props;

        this.setState({ disabledButton: true }, () => {
            Promise.all([
                this.validateUserName(),
                // this.validatePassword(),
                this.validateConfirmPassword(),
                this.validateEmail(),
                this.validatePhone(),
                this.validateFullname(),
                this.validateAffiliate(),
            ]).then((data) => {
                // true mean having error
                if (data.includes(true)) {
                    this.setState({ disabledButton: false });
                    return;
                } else {
                    let languageKey = language.key;
                    let registerObj = {
                        login: username,
                        password: password,
                        email: email,
                        name: fullname,
                        langKey: languageKey,
                        language: languageKey.toUpperCase(),
                        phone: telCode + phone,
                        merchantCode: window.merchantCode,
                        currency: currency[0].value,
                        affiliateName: affiliateId === '' ? null : affiliateId,
                    };
                    window.SPL_Register.registerAccount(registerObj).then((data) => {
                        if (data.status === 'S') {
                            this.setState({ disabledButton: false });
                            setTimeout(() => {
                                this.loginAndNavigate();
                            }, 4000);
                            this._next();
                        } else {
                            this.setState({ disabledButton: false, regErrMsg: data.errMsg });
                        }
                    });
                }
            });
        });
    };

    loginAndNavigate = () => {
        let loginObj = {
            domain: '',
            isAffiliate: false,
            isVipLogin: false,
            language: this.props.language.key,
            merchantCode: window.merchantCode,
            login: this.state.username,
            password: this.state.password,
            rememberMe: false,
        };

        this.props.dispatch(userAction.login(loginObj)).then(() => {
            this.props.dispatch(userAction.isAuthenticated());
        });
    };

    // Test current step with ternary
    // _next and _previous functions will be called on button click
    _next() {
        let currentStep = this.state.currentStep;

        // If the current step is 1 or 2, then add one on "next" button click
        currentStep = currentStep >= 2 ? 3 : currentStep + 1;
        this.setState({
            currentStep: currentStep,
        });
    }

    get submitButton() {
        let currentStep = this.state.currentStep;

        // If the current step is the last step, then render the "submit" button
        if (currentStep === 1) {
            return (
                <Translation>
                    {(t) => (
                        <Col className='justify-content-start w-100'>
                            <button
                                className='primary-btn w-100 button-submit border-0'
                                onClick={this.handleSubmit}
                                disabled={
                                    !this.state.fullname ||
                                    !this.state.email ||
                                    !this.state.phone ||
                                    this.state.currency == null ||
                                    this.state.ageTerms === false ||
                                    this.state.usernameErrMsg > 0 ||
                                    this.state.emailErrMsg > 0 ||
                                    this.state.phoneErrMsg > 0
                                }
                            >
                                <span>{t('register:register.form.register')}</span>
                            </button>
                        </Col>
                    )}
                </Translation>
            );
        }
        // ...else render nothing
        return null;
    }

    render() {
        const { brandLogoObj } = this.state;
        return (
            <>
                <Translation>
                    {(t) => (
                        <Form onSubmit={this.handleSubmit}>
                            <div className='w-100 d-flex justify-content-center'>
                                <Col xs='12'>
                                    <Step1
                                        currentStep={this.state.currentStep}
                                        handleUserNameChange={this.handleUserNameChange}
                                        handlePasswordChange={this.handlePasswordChange}
                                        handleconfirmPasswordChange={this.handleconfirmPasswordChange}
                                        handleAffiliateChange={this.handleAffiliateChange}
                                        affiliateId={this.state.affiliateId}
                                        disabledAffiliate={this.state.disabledAffiliate}
                                        username={this.state.username}
                                        password={this.state.password}
                                        confirmpassword={this.state.confirmpassword}
                                        email={this.state.email}
                                        currencies={this.state.currencies}
                                        currency={this.state.currency}
                                        handleFullnameChange={this.handleFullnameChange}
                                        handleCurrencyChange={this.handleCurrencyChange}
                                        handleEmailChange={this.handleEmailChange}
                                        handlePhoneChange={this.handlePhoneChange}
                                        phone={this.state.phone}
                                        selectCode={this.selectCode}
                                        telCodeList={this.state.telCodeList}
                                        countryCode={this.state.countryCode}
                                    />

                                    <Col xs='4' className='button-group form-spacing register-btn'>
                                        <div className='w-100 d-flex'>
                                            <Col xs='12' className='w-100 d-flex'>
                                                {this.submitButton}
                                            </Col>
                                        </div>
                                    </Col>

                                    <Col xs={12} md={12} lg={12} className='d-none d-md-flex justify-content-end button-group form-spacing'>
                                        <div className='w-100 d-flex'>
                                            <Col xs={4} md={4} className='p-l-0 p-r-0'></Col>
                                            <Col xs={8} md={8} className='w-100 d-flex'>
                                                {this.state.regErrMsg.map((errMsg, index) => {
                                                    return (
                                                        <div key={index} className='invalid-feedback text-danger'>
                                                            <Trans i18nKey={errMsg}>{errMsg}</Trans>
                                                        </div>
                                                    );
                                                })}
                                            </Col>
                                        </div>
                                    </Col>

                                    <div className='memberBenefit-container'>
                                        <div dangerouslySetInnerHTML={{ __html: this.state.memberBenefit }}></div>
                                    </div>
                                </Col>
                            </div>
                        </Form>
                    )}
                </Translation>
            </>
        );
    }
}

export default connect(mapStateToProps, null)(withTranslation(['register'])(withRouter(MasterForm)));
