import moment from 'moment';
import React, { Component, Fragment } from 'react';
import { userAction } from 'root/redux/action';
import { Translation, withTranslation } from 'react-i18next';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import MessagingDetailModal from '../../12Messaging/122_MessagingDetailModal';
import { connect } from 'react-redux';
import { mapStateToProps } from 'root/redux/selector/selector';
import { withRouter } from 'react-router-dom';
import { deleteIcon, flagIcon } from '../161_MyAccount_Items_Data';
import { AiFillDelete } from 'react-icons/ai';
import { MdClose } from 'react-icons/md';
import SVGMessage from 'root/components/Icon/SVGMessage';
import Pagination from 'react-js-pagination';
import SVGHistory from 'root/components/Icon/SVGHistory';
import Loading from 'root/components/Loading/Loading';
import SVGElements from 'root/components/SVGElements';

class Messaging extends Component {
    isMount = true;
    timeOut = null;

    constructor(props) {
        super(props);
        let startEndParams = {
            start: null,
            end: new Date(new Date(new Date()).setHours(23, 59, 59)),
        };
        this.state = {
            visible: false,
            messageList: [],
            listOfCheckedId: [],
            allChecked: false,
            show: false,
            checkAll: false,
            currentPage: 0,
            todosPerPage: 5,
            isLoading: false,
            checkBool: false,
            countCheck: 0,
            getId: [],
            unreadMsgCount: 0,
            showDelete: false,
            isNotification: false,
            showSelectedDelete: false,
            inboxCount: 0,
            notificationCount: 0,
            paymentCount: 0,
            promotionCount: 0,
            currentMsgType: 'P',
            startEndParams,
        };
    }

    componentDidMount() {
        this.isMount = true;
        this.getMessages(this.props.isNotification, this.state.currentMsgType);
        this.setState({ isNotification: this.props.isNotification });
        this.countMsg();

        if (this.props.screen.viewType === 'web' && this.props.location.pathname === '/messaging') {
            this.props.history.push('/myaccount/messaging');
        } else if (this.props.screen.viewType === 'mobile' && this.props.location.pathname === '/myaccount/messaging') {
            this.props.history.push('/myaccount/myprofile');
            this.props.history.push('/messaging');
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.user.unreadMsg !== this.props.user.unreadMsg) {
            this.countMsg();
        }
    }

    componentWillUnmount() {
        this.isMount = false;
        if (this.timeOut) {
            clearTimeout(this.timeOut);
        }
    }

    handleClick = (event) => {
        this.setState({ currentPage: Number(event.target.id) }, () => {
            this.clearCheck();
        });
    };

    getMessages(isNotification, currentMsgType) {
        const { currentPage, todosPerPage } = this.state;

        this.setState({ isNotification: isNotification });

        window.SPL_Member.getMessages(isNotification, currentPage, todosPerPage, this.state.startEndParams, '', currentMsgType).then((msgObj) => {
            if (this.isMount) {
                let messageArr = msgObj.messages;
                let totalPageSize = msgObj.totalPageSize;

                if (messageArr.length === 0) {
                    if (currentPage - 1 < 0) {
                        this.setState({ currentPage: 0 });
                    } else {
                        this.setState({ currentPage: currentPage - 1 });
                    }

                    this.getMessages(this.state.isNotification, this.state.currentMsgType);
                }

                for (let i = 0; i < messageArr.length; i++) {
                    messageArr[i].isChecked = false;

                    if (messageArr[i].status.includes('F')) {
                        messageArr[i].isFlagChecked = true;
                    } else {
                        messageArr[i].isFlagChecked = false;
                    }
                }

                this.setState({ messageList: messageArr, totalItems: totalPageSize, isLoading: false });
            }
        });
    }

    /* CAN DELETE ALOT MESSAGE AT A TIME */
    deleteMessageByBatch = () => {
        this.setState({ isLoading: true }, () => {
            let listOfCheckedId = [];
            let messageList = this.state.messageList;
            let setTimeOut = 1000;

            for (let i = 0; i < messageList.length; i++) {
                const msg = messageList[i];

                if (msg.isChecked) {
                    listOfCheckedId.push(msg.id);
                }
            }

            if (listOfCheckedId.length > 0) {
                setTimeOut = 5000;
            }

            this.setState({ listOfCheckedId: listOfCheckedId }, () => {
                window.SPL_Member.deleteMessage(listOfCheckedId).then((data) => {
                    this.timeOut = setTimeout(() => {
                        if (this.isMount) {
                            this.countMsg();
                            this.getMessages(this.state.isNotification, this.state.currentMsgType);
                            this.onCheckAllChange(false);
                        }
                    }, setTimeOut);

                    this.setState({ listOfCheckedId: [], showSelectedDelete: false });
                });
            });
        });
    };

    /* CAN ONLY DELETE ONE MESSAGE AT A TIME */
    deleteMessage = (id) => {
        this.setState({ isLoading: true }, () => {
            let listOfCheckedId = [];

            listOfCheckedId.push(id);
            this.setState({ listOfCheckedId: listOfCheckedId }, () => {
                window.SPL_Member.deleteMessage(listOfCheckedId).then((data) => {
                    this.timeOut = setTimeout(() => {
                        if (this.isMount) {
                            this.countMsg();
                            this.getMessages(this.state.isNotification, this.state.currentMsgType);
                        }
                    }, 1000);

                    this.setState({ listOfCheckedId: [] });
                });
            });
        });
    };

    onChange = (index, id) => {
        let array = this.state.messageList;

        for (let a = 0; a < array.length; a++) {
            if (id === array[a].id) {
                if (array[a].isChecked === false) {
                    array[a].isChecked = true;
                } else {
                    array[a].isChecked = false;
                }
            }
        }

        this.setState({ messageList: array, checkAll: false }, () => {
            let msgList = this.state.messageList;

            for (let i = 0; i < msgList.length; i++) {
                const contact = msgList[i];

                if (contact.isChecked === true) {
                    this.setState({ countCheck: 1 });
                    break;
                } else {
                    this.setState({ countCheck: 0 });
                }
            }
        });
    };

    onFlagChange = (index) => {
        let array = this.state.messageList;
        let msg = array[index];

        msg.isFlagChecked = !msg.isFlagChecked;

        let messageObj = {
            id: msg.id,
            isFlag: msg.isFlagChecked,
            status: msg.status,
        };

        array[index] = msg;

        window.SPL_Member.readAndFlagMessage(messageObj, () => {
            this.setState({ messageList: array });
        });

        this.setState({ messageList: array });
    };

    /* CAN MARK AS READ ALOT MESSAGE AT A TIME */
    onClickMessageByBatch = () => {
        this.setState({ isLoading: true }, () => {
            let messageList = this.state.messageList;
            let msg = null;

            for (let i = 0; i < messageList.length; i++) {
                if (messageList[i].isChecked) {
                    msg = messageList[i];

                    let messageObj = {
                        id: msg.id,
                        isFlag: msg.isFlagChecked,
                        status: 'R',
                    };

                    window.SPL_Member.readAndFlagMessage(messageObj, () => {
                        if (this.timeOut) {
                            clearTimeout(this.timeOut);
                        }

                        this.timeOut = setTimeout(() => {
                            if (this.isMount) {
                                this.countMsg();
                                this.getMessages(this.state.isNotification, this.state.currentMsgType);
                                this.onCheckAllChange(false);
                            }
                        }, 1000);
                    });
                } else {
                    if (i === messageList.length - 1) {
                        this.setState({ isLoading: false });
                    }
                }
            }
        });
    };

    formatCurrentLanguage = (lng) => {
        return lng ? lng.toLowerCase().replace('_', '-') : 'en-my';
    };

    formatMessage(message) {
        if (!message) {
            return message;
        }
        const currentLang = this.formatCurrentLanguage(this?.props?.language?.countryLanguageKey);
        const providerLink = `/${currentLang}${this.props.portal?.settings?.features?.floatingFreeSpinSettings?.ctaLink ?? `/slots/$1`}`;

        let formattedMessage = message?.replaceAll(/{{(.*?)\s*(?:<<|&lt;&lt;)\s*(.*?)\s*(?:>>|&gt;&gt;)}}/g, (_, textTranslation) => {
            return `<a style="color:#3498db" href="${providerLink}">${textTranslation}</a>`;
        });

        // Replace date format like 2024-11-23 16:59:59.000 to YYYY-MM-DD HH:MM
        return formattedMessage?.replaceAll(/\b(\d{4})-(\d{2})-(\d{2}) (\d{2}):(\d{2}):\d{2}\.\d{3}\b/g, (_, year, month, day, hour, minute) => {
            return `${year}-${month}-${day} ${hour}:${minute}`;
        });
    }

    onClickMessage(index, id) {
        this.onCheckAllChange(false);

        let counter = 1;
        let page = this.state.currentPage;

        if (page > counter) {
            let i = page - counter;
            let j = 0;

            for (j; j > i; j++) {
                index = index + 10;
            }
        }

        let array = this.state.messageList;
        let msg = null;

        for (let a = 0; a < array.length; a++) {
            if (id === array[a].id) {
                msg = array[a];
            }
        }

        let messageObj = {
            id: msg.id,
            isFlag: msg.isFlagChecked,
            status: 'R',
        };

        if (msg.messageType === 'I') {
            msg.message = this.formatMessage( msg.message);
        }

        this.setState({ selectedMessage: msg, openMessageModal: true });

        window.SPL_Member.readAndFlagMessage(messageObj, () => {
            if (this.timeOut) {
                clearTimeout(this.timeOut);
            }

            this.timeOut = setTimeout(() => {
                if (this.isMount) {
                    this.countMsg();
                    this.getMessages(this.state.isNotification, this.state.currentMsgType);
                }
            }, 1000);
        });
    }

    countMsg = () => {
        window.SPL_Member.getUnreadMessageCount().then((data) => {
            if (this.isMount) {
                this.setState(
                    {
                        unreadMsgCount: data.unreadCount,
                        inboxCount: data.unreadInboxCount,
                        notificationCount: data.unreadNotificationsCount,
                        paymentCount: data.unreadPaymentCount,
                        promotionCount: data.unreadPromotionCount,
                    },
                    () => {
                        let count = this.state.unreadMsgCount;

                        this.props.dispatch(userAction.getUnreadMsg(count));
                    }
                );
            }
        });
    };

    isChecked(index, id) {
        let array = this.state.messageList;
        let check = null;

        for (let a = 0; a < array.length; a++) {
            if (id === array[a].id) {
                check = array[a].isChecked;
            }
        }

        return this.state.checkAll || check;
    }

    isFlagChecked(index, id) {
        let array = this.state.messageList;
        let check = null;

        for (let a = 0; a < array.length; a++) {
            if (id === array[a].id) {
                check = array[a].isFlagChecked;
            }
        }

        return check;
    }

    onCheckAllChange = (e) => {
        let checked = false;
        let messageList = this.state.messageList;

        if (e) {
            checked = e.target.checked;
        }

        for (let i = 0; i < messageList.length; i++) {
            messageList[i].isChecked = checked;
        }

        let id = this.state.getId;

        this.setState({ messageList: messageList, getId: id, checkAll: checked, checkBool: !this.state.checkBool }, () => {
            let check = this.state.checkBool;

            if (check) {
                this.setState({ countCheck: 1 });
            } else {
                this.setState({ countCheck: 0 });
            }
        });
    };

    uncheck() {
        let messageList = this.state.messageList;

        for (let i = 0; i < messageList.length; i++) {
            messageList[i].isChecked = this.state.checkAll;
        }

        this.setState({ messageList: messageList });
    }

    clearCheck = () => {
        let messageList = this.state.messageList;

        for (let i = 0; i < messageList.length; i++) {
            messageList[i].isChecked = false;
        }

        this.setState({ messageList: messageList, checkAll: false, checkBool: false, countCheck: 0, getId: [] });
    };

    showModal = () => {
        this.setState({ show: true });
    };

    hideModal = () => {
        this.setState({ show: false });
    };

    closeModal = () => {
        this.setState({ selectedMessage: null, openMessageModal: false });
    };

    showDeleteBtn = (id) => {
        this.onCheckAllChange(false);
        this.setState({ showDelete: id });
    };

    onTabClick = (isNotification, currentMsgType) => {
        this.setState({ currentPage: 0, currentMsgType }, () => {
            this.props.dispatch(userAction.getUnreadMsg());
            this.getMessages(isNotification, currentMsgType);
        });
    };

    handlePageChange = (pageNumber) => {
        this.setState({ currentPage: pageNumber - 1 }, () => {
            this.getMessages(this.state.isNotification, this.state.currentMsgType);
        });
    };

    render() {
        const renderTodos = this.state.messageList.map((msg, index) => {
            return (
                <li key={msg.id} id={index}>
                    <div className='msg-row'>
                        <div className='msg-item msgChkbox'>
                            <div className='top'>
                                <div className={`top-title `}>
                                    <input type='checkbox' value={index} checked={this.isChecked(index, msg.id)} onChange={() => this.onChange(index, msg.id)} />
                                    <span className='checkmark'></span>
                                </div>
                            </div>
                        </div>

                        <div className='msg-item msgDate'>
                            <div className='top'>
                                <div className={`top-title `}>
                                    <span>
                                        {' '}
                                        {moment(msg.sentTime || msg.createdDate)
                                            .format('DD-MM-YYYY HH:mm')
                                            .toString()}
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div
                            className='msg-item msgSubject'
                            onClick={() => {
                                this.onClickMessage(index, msg.id);
                            }}
                        >
                            <div className='top'>
                                <div className={`top-title ${['R', 'FR'].includes(msg.status) && 'msg-read'}`}>
                                    <span>{msg.title}</span>
                                </div>
                            </div>
                        </div>

                        <div className='msg-item msgRemark'>
                            <div className='top'>
                                <div className='top-title'>
                                    <label className='custom-checkbox'>
                                        <input
                                            type='checkbox'
                                            value={index}
                                            checked={this.isChecked(index, msg.id)}
                                            onChange={() => this.onChange(index, msg.id)}
                                            className='w-auto'
                                        />
                                        <span className='checkmark'></span>
                                    </label>
                                    <div className={`flag-delete ${this.state.showDelete === msg.id ? 'hide' : 'show'}`}>
                                        <SVGElements
                                            name='mark-as-read-icon'
                                            className={`${this.isFlagChecked(index, msg.id) ? 'msg-flagged' : 'msg-unflag'}`}
                                            onClick={() => this.onFlagChange(index, msg.id)}
                                        />
                                        {/* <img src={flagIcon} className={`${this.isFlagChecked(index, msg.id) ? 'msg-flagged' : 'msg-unflag'}`} onClick={() => this.onFlagChange(index, msg.id)} /> */}
                                        <SVGElements className='msg-delete' name='delete-icon' onClick={() => this.showDeleteBtn(msg.id)} />
                                        {/* <img src={deleteIcon} className='msg-delete' onClick={() => this.showDeleteBtn(msg.id)} /> */}
                                    </div>
                                    <div className={`msg-cancel-delete ${this.state.showDelete === msg.id ? 'show' : 'hide'}`}>
                                        <button className='btn-cancel' onClick={() => this.showDeleteBtn('')}>
                                            {this.props.t('global:entity.action.cancel', 'Cancel')}
                                        </button>
                                        <button
                                            className='btn-delete'
                                            onClick={() => {
                                                this.deleteMessage(msg.id);
                                            }}
                                        >
                                            {this.props.t('global:entity.action.delete', 'Delete')}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* <div className='msg-item msgDelete'>
              <div className='top'>
                <div className='top-title'>
                  <div className={`flag-delete ${this.state.showDelete === msg.id ? 'hide' : 'show'}`}>
                    <img src={deleteIcon} className='msg-delete' onClick={() => this.showDeleteBtn(msg.id)} />
                  </div>
                  <div className={`msg-cancel-delete ${this.state.showDelete === msg.id ? 'show' : 'hide'}`}>
                    <button className='btn-cancel' onClick={() => this.showDeleteBtn('')}>Cancel</button>
                    <button className='btn-delete' onClick={() => {this.deleteMessage(msg.id);}}>Delete</button>
                  </div>
                </div>
              </div>
            </div> */}
                    </div>
                </li>
            );
        });

        return (
            <Translation>
                {(t) => (
                    <div className='messaging'>
                        {this.state.isLoading && <Loading />}

                        {this.state.showSelectedDelete && (
                            <div className='messaging-popup'>
                                <div className='popup'>
                                    <div className='popup-header'>
                                        <div className='popup-header-left'>
                                            <div className='popup-title'>{t('message:message.confirm-subject', 'Delete Selected Message?')}</div>
                                        </div>
                                        <div className='popup-header-right'>
                                            <SVGElements name='close-icon' onClick={() => this.setState({ showSelectedDelete: false })} />
                                            {/* <MdClose onClick={() => this.setState({ showSelectedDelete: false })} /> */}
                                        </div>
                                    </div>
                                    <div className='popup-body'>
                                        <span className='btn-normal btn-body btn-ok' onClick={this.deleteMessageByBatch}>
                                            {t('login:login.ok', 'ok')}
                                        </span>
                                    </div>
                                </div>
                                <div className='popup-overlay' onClick={() => this.setState({ showSelectedDelete: false })}></div>
                            </div>
                        )}

                        <MessagingDetailModal
                            messaging={this.state.selectedMessage}
                            isOpen={this.state.openMessageModal}
                            closeModal={this.closeModal}
                            buttonText='DETAILS'
                            buttonClass={'btn-secondary btn-lg'}
                            onFlagChange={this.onFlagChange}
                        />

                        <h1>{t('settings:settings.tabs.heading.messaging', 'Messaging')}</h1>

                        <div className='inbox'>
                            <div className='msg-tab'>
                                <span onClick={() => this.onTabClick(false, 'P')} className={`${this.state.currentMsgType === 'P' && 'tab-active'}`}>
                                    {t('global:infoCentre.promotions', 'Promotions')}
                                    {this.state.promotionCount > 0 ? (
                                        <Fragment> ({this.state.promotionCount})</Fragment>
                                    ) : this.state.promotionCount > 99 ? (
                                        <Fragment> (99+)</Fragment>
                                    ) : (
                                        <Fragment> (0)</Fragment>
                                    )}
                                </span>
                                <span onClick={() => this.onTabClick(false, 'PT')} className={`${this.state.currentMsgType === 'PT' && 'tab-active'}`}>
                                    {t('global:infoCentre.payment', 'Payment')}
                                    {this.state.paymentCount > 0 ? (
                                        <Fragment> ({this.state.paymentCount})</Fragment>
                                    ) : this.state.paymentCount > 99 ? (
                                        <Fragment> (99+)</Fragment>
                                    ) : (
                                        <Fragment> (0)</Fragment>
                                    )}
                                </span>
                                <span onClick={() => this.onTabClick(false, 'N')} className={`${this.state.currentMsgType === 'N' && 'tab-active'}`}>
                                    {t('settings:settings.profiletab.system', 'System')}
                                    {this.state.inboxCount > 0 ? (
                                        <Fragment> ({this.state.inboxCount})</Fragment>
                                    ) : this.state.inboxCount > 99 ? (
                                        <Fragment> (99+)</Fragment>
                                    ) : (
                                        <Fragment> (0)</Fragment>
                                    )}
                                </span>
                                <span onClick={() => this.onTabClick(true, 'I')} className={`${this.state.currentMsgType === 'I' && 'tab-active'}`}>
                                    {t('transaction:transaction.notification', 'Notification')}
                                    {this.state.notificationCount > 0 ? (
                                        <Fragment> ({this.state.notificationCount})</Fragment>
                                    ) : this.state.notificationCount > 99 ? (
                                        <Fragment> (99+)</Fragment>
                                    ) : (
                                        <Fragment> (0)</Fragment>
                                    )}
                                </span>
                            </div>
                        </div>

                        <div className='msgAction'>
                            <div className='msgChkbox'>
                                <input type='checkbox' onChange={this.onCheckAllChange} checked={this.state.checkAll} />
                                {t('message:message.selectAll')}
                            </div>

                            <div className='msgDelete_Read'>
                                <p className='readAll-msg' onClick={() => this.onClickMessageByBatch()}>
                                    <SVGElements name='mark-as-read-icon' />
                                    {/* <SVGMessage name='openedMsg' />  */}
                                    {t('message:message.markAsRead', 'Mark As Read')}
                                </p>
                                <p className='deleteAll-msg' onClick={() => this.setState({ showSelectedDelete: true })}>
                                    <SVGElements name='delete-icon' /> {t('global:entity.action.delete', 'Delete')}{' '}
                                </p>
                            </div>
                        </div>

                        <ul>
                            <li>
                                <div className='msg-header'>
                                    <div className='msg-item msgChkbox'>
                                        <div className='top'>
                                            <div className='top-title'>{/* For Space Purpose */}</div>
                                        </div>
                                    </div>

                                    <div className='msg-item msgDate'>
                                        <div className='top'>
                                            <div className='top-title'>
                                                <span>{t('transaction:transaction.history.date')}</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='msg-item msgSubject'>
                                        <div className='top'>
                                            <div className='top-title'>
                                                <span>{t('message:message.subject', 'Subject')}</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='msg-item msgRemark'>
                                        <div className='top'>
                                            <div className='top-title'>
                                                <span>{t('transaction:transaction.history.remark')}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>

                        {this.state.messageList.length > 0 ? (
                            <ul>
                                {renderTodos}

                                <Pagination
                                    firstPageText={<SVGHistory name='prevFirst-arrow' className='' alt='prevFirst-arrow' />}
                                    lastPageText={<SVGHistory name='nextLast-arrow' className='' alt='nextLast-arrow' />}
                                    prevPageText={<SVGHistory name='prev-arrow' className='' alt='prev-arrow' />}
                                    nextPageText={<SVGHistory name='next-arrow' className='' alt='next-arrow' />}
                                    activePage={this.state.currentPage + 1}
                                    itemsCountPerPage={this.state.todosPerPage}
                                    totalItemsCount={parseInt(this.state.totalItems)}
                                    pageRangeDisplayed={5}
                                    onChange={this.handlePageChange.bind(this)}
                                />
                            </ul>
                        ) : (
                            <ul>
                                <li>
                                    <div className='no-msg-text'>{t('message:message.noMessage', 'No Message')}</div>
                                </li>
                            </ul>
                        )}
                    </div>
                )}
            </Translation>
        );
    }
}

export default connect(mapStateToProps, null)(withTranslation(['message', 'global'])(withRouter(Messaging)));
