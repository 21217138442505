export const copyrightImgPC = ['/public/html/images/register_footer_img/register_foot_pagcor.png'];
export const copyrightImgGC = ['/public/html/images/register_footer_img/register_foot_gc.png'];

export const registerEmail = ['/public/html/images/icon/register-mail.png'];
export const registerContact = ['/public/html/images/icon/register-contact.png'];
export const registerCurrency = ['/public/html/images/icon/register-currency.png'];
export const registerUser = ['/public/html/images/icon/register-user.png'];
export const registerDOB = ['/public/html/images/icon/register-dob.png'];
export const registerPassword = ['/public/html/images/icon/register-password.png'];
export const registerAFF = ['/public/html/images/icon/register-affiliate.png'];

export const userIcon = ['/public/html/images/drawer_img/user-icon.png'];
export const lock = ['/public/html/images/drawer_img/lock.png'];