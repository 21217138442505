import React from 'react';
import Turnstile, { useTurnstile } from 'react-turnstile';
import isFunction from 'lodash/isFunction';
import { useSelector } from 'react-redux';

const TurnstileWidget = (props) => {
    const { onVerifyCallback, onErrorCallback, onLoadCallback, theme = 'auto', size = 'normal', moduleType = '', ...restProps } = props;
    const turnstile = useTurnstile();
    const { siteKey } = useSelector((state) => state.portal);
    const language = useSelector((state) => state.language);
    const { processedData } = useSelector((state) => state.authSettingsReducer);
    const langKey = language.key || 'en';

    const isCFTurnstileToggle = processedData?.[moduleType]?.turnstileEnabled;

    if (!isCFTurnstileToggle || (isCFTurnstileToggle && (siteKey === null || siteKey === ''))) return null;

    return (
        <div className='turnstile-wrapper'>
            <Turnstile
                {...restProps}
                size={size}
                theme={theme}
                language={langKey}
                sitekey={siteKey}
                onError={(error) => {
                    isFunction(onErrorCallback) && onErrorCallback(error, turnstile);
                }}
                onLoad={(widgetId) => {
                    isFunction(onLoadCallback) && onLoadCallback(widgetId, turnstile);
                }}
                onVerify={(token) => {
                    isFunction(onVerifyCallback) && onVerifyCallback(token, turnstile);
                }}
            />
        </div>
    );
};

export default TurnstileWidget;
