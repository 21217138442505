import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { mapStateToProps } from '../../../redux/selector/selector';
import { withRouter } from 'react-router-dom';
import { Translation, withTranslation } from 'react-i18next';
import { TweenLite } from 'gsap';
import { MdClose } from 'react-icons/md';
import SVGElements from 'root/components/SVGElements';

class MemberHeader extends React.Component {
    isMount = true;
    constructor(props) {
        super(props);
        this.state = {
            on: false,
            hasFakeSeamLess: true,
            disabledSeamless: false,
            disableSeamlessButton: false,
            seamLessModal: false,
        };
    }

    componentDidMount() {
        this.getUserSeamlessStatus();
    }

    componentWillUnmount() {
        this.isMount = false;
    }

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            // this.getUserSeamlessStatus();
        }
    }

    onReload() {
        TweenLite.fromTo(
            '.main-wallet-header .icon-reload',
            1,
            {
                rotate: 0,
            },
            {
                rotate: 360,
            }
        );
    }

    getUserSeamlessStatus() {
        const { isLogin } = this.props.user;
        if (isLogin === true) {
            window.SPL_Member.loadMemberSeamlessStatus().then((res) => {
                if (this.isMount) {
                    this.setState({
                        hasFakeSeamLess: res.hasFakeSeamless,
                        disabledSeamless: res.isFakeSeamlessDisabled,
                    });
                }
            });
        }
    }

    handleSeamlessChange = () => {
        this.setState({ disableSeamlessButton: true });
        window.SPL_Member.updateMemberSeamlessStatus(this.state.disabledSeamless, this.state.hasFakeSeamLess).then((res) => {
            if (res.hasFakeSeamless) {
                this.setState({ disabledSeamless: res.isFakeSeamlessDisabled, message: res.message, disableSeamlessButton: false }, () => {
                    this.getSeamlessModal();
                });
            }
        });
    };

    getSeamlessModal = () => {
        this.setState({ seamLessModal: !this.state.seamLessModal });
    };

    // updateUserSeamless(seamless) {
    //   console.log(seamless);
    //   window.SPL_Member.updateMemberSeamlessStatus(seamless).then(
    //     (res) => {
    //       console.log(res);
    //     }
    //   );
    // }

    render() {
        return (
            <Translation>
                {(t) => (
                    <Fragment>
                        {this.state.seamLessModal && (
                            <div className='messaging-popup'>
                                <div className='popup'>
                                    <div className='popup-header'>
                                        <div className='popup-header-left'>
                                            <div className='popup-title'>{t('global:global.menu.account.autoTransfer', 'Main Wallet Auto Transfer')}</div>
                                        </div>
                                        <div className='popup-header-right'>
                                            <SVGElements name='close-icon' onClick={() => this.getSeamlessModal()} />
                                            {/* <MdClose onClick={() => this.getSeamlessModal()} /> */}
                                        </div>
                                    </div>

                                    <div className='popup-body'>
                                        {t('global:global.messages.autoTransfer.content1', 'You have')}{' '}
                                        {!this.state.disabledSeamless
                                            ? t('global:global.messages.autoTransfer.enable', 'enable')
                                            : t('global:global.messages.autoTransfer.disable', 'disable')}{' '}
                                        {t(
                                            'global:global.messages.autoTransfer.content2',
                                            'Auto Transfer. Please allow up to 5 minutes for the system to update your preferences. Thank you.'
                                        )}
                                    </div>

                                    <div className='popup-footer'>
                                        <span className='btn-normal btn-body' onClick={() => this.getSeamlessModal()}>
                                            {t('login:login.ok', 'ok')}
                                        </span>
                                    </div>
                                </div>
                                <div className='popup-overlay' onClick={() => this.getSeamlessModal()}></div>
                            </div>
                        )}

                        <div className='member-header'>
                            <div className='auto-transfer'>
                                <span>{t('global:global.menu.account.mainWalletAutoTransfer', 'Main Wallet Auto Transfer')}</span>
                                <div
                                    className={`icon-${!this.state.disabledSeamless ? 'on' : 'off'}`}
                                    onClick={() => this.handleSeamlessChange()}
                                    disabled={this.state.disableSeamlessButton}
                                ></div>
                            </div>
                            <div className='main-wallet-header'></div>
                        </div>
                    </Fragment>
                )}
            </Translation>
        );
    }
}

export default connect(mapStateToProps)(withTranslation()(withRouter(MemberHeader)));
