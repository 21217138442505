import React, { Fragment, useEffect, useState } from 'react';
import { Translation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { mapStateToProps } from 'root/redux/selector/selector';
import { withRouter } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { MdClose } from "react-icons/md";
import { REWARD_TYPE } from 'root/utils/constants';

const Reward = ({ user, screen, history }) => {
  const [getReward, setGetReward] = useState(false);
  const [rewardInfo, setRewardInfo] = useState(false);
  const [reward, setReward] = useState([]);
  
  useEffect(() => {
    let isMount = true;
    getRewardDetail();

    function getRewardDetail() {
      if (user.account !== null) {
        const { id, currency } = user.account;

        window.SPL_Reward.getRewards(id, currency).then(res => {
          if (res?.rewards && res.rewards.rewardType !== REWARD_TYPE.FREE_SPIN_SETTING && isMount) {
              setReward(res.rewards);
              setGetReward(true);
          }
        }); 
      } else {
        setGetReward(false);
      }
    }

    return () => { isMount = false; };
  }, [user.isLogin, user.account]);

  function closeReward() {
    setGetReward(false);
  };

  function getRewardInfo(info) {
    setRewardInfo(!rewardInfo);
    setGetReward(false);

    if (info.rewardType !== REWARD_TYPE.FREE_SPIN) {
      window.SPL_Reward.claimRedPacket(info);
    } else {
      window.SPL_Reward.claimRewardFreeSpin(info);
    }
  };

  function getHistoryPage() {
    setRewardInfo(false);

    if (screen.isMobile) {
      history.push('/account/report');
    } else {
      history.push('/myaccount/report');
    }
  };

  return (
    <Translation>
      {(t) => (
        <Fragment>
          {rewardInfo &&
            <div className='reward-popup'>
              <div className='popup'>
                <LazyLoadImage className='reward-popupImg' src={reward.popupImage}  onClick={getHistoryPage} />
                <MdClose className='reward-close' onClick={() => getRewardInfo(reward)} />

                <div className='popup-detail'  onClick={getHistoryPage}>
                  <p className='detail-title' style={{ color: reward.titleColor && reward.titleColor.split('/').pop() }}>
                    {reward.name}
                  </p>
                  <p className='detail-amount' style={{ color: reward.amountColor && reward.amountColor.split('/').pop() }}>
                    {user.account.currency} {reward.amount.toFixed(2)}
                  </p>
                  <p className='detail-desc'  style={{ color: reward.descColor && reward.descColor.split('/').pop() }}>
                    {reward.description}
                  </p>
                </div>
              </div>
              <div className='popup-overlay' onClick={() => getRewardInfo(reward)}></div>
            </div>
          }

          {getReward &&
            <div className='reward-box'>
              <img className='reward-img' src={reward.rewardImgSrc} onClick={() => getRewardInfo(reward)} />
              <MdClose className='reward-close' onClick={closeReward} />
            </div>
          }
        </Fragment>
      )}
    </Translation>
  )
}

export default connect(mapStateToProps)(withTranslation()(withRouter(Reward)));