import React from "react";

export default class Chat extends React.Component{
    render(){
        return (
            <div className="live-chat">
                <span className="icon-box">
                    <i className="icon-chat"></i>
                </span>
                <span className="text">LIVE CHAT</span>
            </div>
        );
    }
}