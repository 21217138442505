import React from 'react';

const getViewBox = name => {
  switch (name) {
    case 'calendar':
      return '0 0 40 37.5';
    case 'arrow':
      return '0 0 40 37.5';
    case 'prevFirst-arrow':
      return '0 0 1024 1024';
    case 'nextLast-arrow':
      return '0 0 1024 1024';
    case 'prev-arrow':
      return '0 0 512 512';
    case 'next-arrow':
      return '0 0 512 512';

    default:
      return '0 0 40 40';
  }
};

const getPath = (name, props) => {
  switch (name) {
    case 'calendar':
      return (
        <path
          {...props}
          d='M5.86 23.44h4.69a1.18 1.18 0 0 0 1.17-1.17v-4.69a1.17 1.17 0 0 0-1.17-1.17H5.86a1.17 1.17 0 0 0-1.17 1.17v4.69a1.17 1.17 0 0 0 1.17 1.17zM7 18.75h2.38v2.34H7zm10.63 4.69h4.68a1.18 1.18 0 0 0 1.18-1.17v-4.69a1.17 1.17 0 0 0-1.18-1.17h-4.65a1.17 1.17 0 0 0-1.18 1.17v4.69a1.18 1.18 0 0 0 1.18 1.17zm1.17-4.69h2.34v2.34h-2.31zm10.62 4.69h4.69a1.17 1.17 0 0 0 1.17-1.17v-4.69a1.17 1.17 0 0 0-1.17-1.17h-4.66a1.17 1.17 0 0 0-1.17 1.17v4.69a1.18 1.18 0 0 0 1.17 1.17zm1.18-4.69H33v2.34h-2.37zM5.86 32.81h4.69a1.17 1.17 0 0 0 1.17-1.17V27a1.18 1.18 0 0 0-1.17-1.17H5.86A1.17 1.17 0 0 0 4.69 27v4.69a1.17 1.17 0 0 0 1.17 1.17zM7 28.13h2.38v2.34H7zm10.63 4.68h4.68a1.17 1.17 0 0 0 1.18-1.17V27a1.18 1.18 0 0 0-1.18-1.17h-4.65A1.18 1.18 0 0 0 16.48 27v4.69a1.17 1.17 0 0 0 1.18 1.17zm1.17-4.68h2.34v2.34h-2.31zm10.62 4.68h4.69a1.17 1.17 0 0 0 1.17-1.17V27a1.17 1.17 0 0 0-1.17-1.17h-4.66A1.18 1.18 0 0 0 28.28 27v4.69a1.17 1.17 0 0 0 1.17 1.17zm1.18-4.68H33v2.34h-2.37zm6.67-24.61h-2.82a3.52 3.52 0 1 0-7 0h-3.9a3.52 3.52 0 0 0-7 0h-3.9a3.52 3.52 0 1 0-7 0H2.73A2.73 2.73 0 0 0 0 6.25v28.52a2.74 2.74 0 0 0 2.73 2.73h34.54A2.74 2.74 0 0 0 40 34.77V6.25a2.73 2.73 0 0 0-2.73-2.73zm-7.5 0a1.17 1.17 0 1 1 2.34 0v2.34a1.17 1.17 0 1 1-2.34 0zm-10.94 0a1.17 1.17 0 1 1 2.34 0v2.34a1.17 1.17 0 1 1-2.34 0zm-10.94 0a1.17 1.17 0 1 1 2.34 0v2.34a1.17 1.17 0 1 1-2.34 0zM2.34 6.25a.39.39 0 0 1 .39-.39h2.82a3.52 3.52 0 1 0 7 0h3.9a3.52 3.52 0 1 0 7 0h3.9a3.52 3.52 0 1 0 7 0h2.82a.39.39 0 0 1 .39.39v5.47H2.34zm35.32 28.52a.4.4 0 0 1-.39.39H2.73a.4.4 0 0 1-.39-.39V14.06h35.32z'
        />
      );
    case 'arrow':
      return (
        <path
          {...props}
          d='M40 37.47V14.54a2.54 2.54 0 0 0-5.07 0v16.81L4.32.74A2.53 2.53 0 0 0 .74 4.32l30.61 30.61H14.54a2.54 2.54 0 0 0 0 5.07h22.93A2.53 2.53 0 0 0 40 37.47z'
        />
      );
    case 'prevFirst-arrow':
      return (
        <path
          {...props}
          d='M272.9 512l265.4-339.1c4.1-5.2.4-12.9-6.3-12.9h-77.3c-4.9 0-9.6 2.3-12.6 6.1L186.8 492.3a31.99 31.99 0 0 0 0 39.5l255.3 326.1c3 3.9 7.7 6.1 12.6 6.1H532c6.7 0 10.4-7.7 6.3-12.9L272.9 512zm304 0l265.4-339.1c4.1-5.2.4-12.9-6.3-12.9h-77.3c-4.9 0-9.6 2.3-12.6 6.1L490.8 492.3a31.99 31.99 0 0 0 0 39.5l255.3 326.1c3 3.9 7.7 6.1 12.6 6.1H836c6.7 0 10.4-7.7 6.3-12.9L576.9 512z'
        />
      );
    case 'nextLast-arrow':
      return (
        <path
          {...props}
          d='M533.2 492.3L277.9 166.1c-3-3.9-7.7-6.1-12.6-6.1H188c-6.7 0-10.4 7.7-6.3 12.9L447.1 512 181.7 851.1A7.98 7.98 0 0 0 188 864h77.3c4.9 0 9.6-2.3 12.6-6.1l255.3-326.1c9.1-11.7 9.1-27.9 0-39.5zm304 0L581.9 166.1c-3-3.9-7.7-6.1-12.6-6.1H492c-6.7 0-10.4 7.7-6.3 12.9L751.1 512 485.7 851.1A7.98 7.98 0 0 0 492 864h77.3c4.9 0 9.6-2.3 12.6-6.1l255.3-326.1c9.1-11.7 9.1-27.9 0-39.5z'
        />
      );
    case 'prev-arrow':
      return (
        <path
          {...props}
          d='M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z'
        />
      );
    case 'next-arrow':
      return (
        <path
          {...props}
          d='M294.1 256L167 129c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.3 34 0L345 239c9.1 9.1 9.3 23.7.7 33.1L201.1 417c-4.7 4.7-10.9 7-17 7s-12.3-2.3-17-7c-9.4-9.4-9.4-24.6 0-33.9l127-127.1z'
        />
      );

    default:
      return <path />;
  }
};

const SVGHistory = ({ name = '', style = {}, fill = '', viewBox = '', width = '100%', className = '', height = '100%' }) => (
  <svg
    width={width}
    style={style}
    height={height}
    className={className}
    xmlns='http://www.w3.org/2000/svg'
    viewBox={viewBox || getViewBox(name)}
    xmlnsXlink='http://www.w3.org/1999/xlink'>
    {getPath(name, { fill })}
  </svg>
);

export default SVGHistory;
