const controller = {
    vm: null,

    init(vm) {
        this.vm = vm;
        this.vm.state = {
            detailModal: false,
            msgTitle: '',
            msgContent: '',
            compo: '',
            username: '',
            usernameError: [],
            usernameErrMsg: '',
            fullname: '',
            fullnameError: [],
            fullnameErrMsg: '',
            password: '',
            passwordError: [],
            passwordErrMsg: '',
            email: '',
            emailError: [],
            emailErrMsg: '',
            currency: {},
            currencyError: [],
            currencyErrMsg: '',
            telCodeData: {},
            telcode: '+60',
            phone: '',
            phoneError: [],
            phoneErrMsg: '',
            captchaToken: null,
            affiliateId: '',
            affiliateError: [],
            affiliateErrMsg: '',
            disabledAffiliate: false,
            regErrMsg: [],
            modal: false,
            memberBenefit: '',
            termContent: '',
            submittedOTP: false,
            otp: '',
            otpError: [],
            otpValidate: false,
            otpSubmitError: [],
            countdownTimer: '',
            countdownKey: null,
            registerPhoneVerification: false,
            registerOTPCurrencyControl: null,
            otpEnabled: false,
            registerApiLoading: false,
            termsLink: '/' + this.vm.props.language.countryLanguageKey.toLowerCase().replace('_', '-') + '/terms',
            showSliderCaptcha: false,

            passwordReveal: {
                newPassword: false,
            },
            showSpecialCharsErrMsg: false,
        };
    },

    getCurrencyDescription(currencyCode) {
        const standardize = {
            MYR: 'Malaysian Ringgit (MYR)',
            SGD: 'Singapore Dollar (SGD)',
        };
        return standardize[currencyCode] || currencyCode;
    },

    triggerV3token() {
        controller.vm.setState({ showSliderCaptcha: false });
        let _captchaToken = controller.vm.state.captchaToken;
        window.SPL_Content.callGrecaptcha('register', function (token) {
            if (token) {
                _captchaToken = token;
                controller.vm.setState(
                    {
                        captchaToken: _captchaToken,
                        isFormValid: true,
                    },
                    () => {
                        controller.vm.handleSubmit();
                    }
                );
            }
        });
    },

    onToogleHideShowPasswordOnClickButton(field) {
        let passwordReveal = controller.vm.state.passwordReveal;
        passwordReveal[field] = !passwordReveal[field];
        controller.vm.setState({ passwordReveal });
    },

    onPasswordValidationChecking() {
        const { showSpecialCharsErrMsg, passwordError } = controller.vm.state;

        let status = true;
        let checkErr = (passwordError || []).filter((params) => !params.status)?.length;
        if (checkErr > 0) {
            status = false;
        }

        if (checkErr > 0 && !showSpecialCharsErrMsg) {
            controller.vm.setState(
                {
                    showSpecialCharsErrMsg: true,
                },
                () => {
                    return status;
                }
            );
        } else {
            return status;
        }
    },
};

export default controller;
