import React, { useEffect, useState } from 'react';
import { Translation, withTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { NavLink, withRouter } from 'react-router-dom';
import SVGMaster from '../../../../components/Icon/SVGMaster';
import LoginModal from '../../../../pages/02Login/LoginModal';

const Sidebar = (props) => {
  const [itemOnClickId, setItemOnClickId] = useState(-1);
  const [menu, setMenu] = useState([]);
  const [gameMenu, setGameMenu] = useState([]);
  const [loginModalVisible, setLoginModalVisible] = useState(false);
  // const [submenuIsOpen, setSubmenuIsOpen] = useState(false);
  const languageState = useSelector((state) => state.language);
  const userState = useSelector((state) => state.user);
  //const screenState = useSelector(state => state.screen);
  const { isLogin } = userState;
  const { countryLanguageKey } = languageState;
  const [sidebarHeight, setSidebarHeight] = useState(0);

  // dynamicly change sidebar lvl 1 height when second lvl height keep change
  useEffect(() => {
    let interval;
    let count = 0;
    interval = setInterval(() => {
      if (count < 20) {
        let firstHeight = '';
        let secondHeight = '';

        if (document.getElementsByTagName('body')) {
          firstHeight = document.getElementsByTagName('body')[0].clientHeight;
          if (document.getElementById('sidebarSecondLevelHidden')) {
            secondHeight = document.getElementById('sidebarSecondLevelHidden').clientHeight;

            let height = Math.max(secondHeight, firstHeight);

            if (height === firstHeight) {
              setSidebarHeight(height);
            } else {
              setSidebarHeight(height + 20);
            }
          }
        }

        count++;
      } else {
        clearInterval(interval);
      }
    }, 100);
  });

  // check if sidemenushow is close, else set itemonclick to default
  useEffect(() => {
    let isMount = true;
    if (!props.sideBarSubMenuShow) {
      if (isMount) {
        setItemOnClickId(-1);
      }
    }

    return () => {
      isMount = false;
    };
  }, [props.sideBarSubMenuShow]);

  useEffect(() => {
    let isMount = true;
    window.SPL_Content.getSideMenu(isLogin, countryLanguageKey, 'mobile').then((data) => {
      if (isMount) {
        setGameMenu(data.firstMenu);
        setMenu(data.secondMenu);
      }
    });

    return () => {
      isMount = false;
    };
  }, [isLogin, countryLanguageKey]);

  const navigate = (item) => {
    props.sideBar(item);
    if (item) {
      if (item.name.toLowerCase() === 'desktop') {
        window.SPL_Other.setViewPort('web');
      } else {
        props.history.push(item.route);
      }
    }
  };

  // function launchGame(gameObj) {
  //   const { isLogin } = userState;
  //   const language = languageState;
  //   window.SPL_Provider.getIsEnabledWithouthAuth(gameObj, 'mobile').then(isEnabled => {
  //     if (!isLogin && !isEnabled) {
  //       setLoginModalVisible(true);
  //     } else {
  //       window.SPL_Provider.generateGameUrl(gameObj, language, 'mobile').then(url => {
  //         window.open(url, '_blank');
  //       });
  //     }
  //   });
  // }

  // function navigateToPage(gameObj) {
  //   const { category, provider } = gameObj;
  //   if (category === 'C' || category === 'P') {
  //     props.history.push(`/casino/${gameObj.provider.toLowerCase()}`);
  //   } else if (category === 'SL') {
  //     if (provider === 'SCR' || provider === 'SCR2') {
  //       props.history.push(`/918kiss`);
  //     } else {
  //       props.history.push(`/slot/${gameObj.provider.toLowerCase()}`);
  //     }
  //   } else if (['S', 'F'].includes(category)) {
  //     launchGame(gameObj);
  //   }
  //   // close sidebar
  //   props.sideBar();
  // }

  function handleLoginModalOk() {
    setLoginModalVisible(false);
  }

  return (
    <Translation>
      {(t) => (
        <div className={`sidebar sidebar-transition ${props.showSidebar ? 'sidebarShow' : 'sidebarHide'}`}>
          <LoginModal handleOk={handleLoginModalOk} visible={loginModalVisible}></LoginModal>
          <div id='sidebarFirstLevel' className='sidebarFirstLevel' style={{ height: sidebarHeight }}>
            <div className='d-block sidebar-container'>
              {menu.map((item) => (
                <div key={item.id}>
                  <div
                    className='text-center sidebar-item'
                    onClick={() => {
                      navigate(item);
                    }}>
                    <div className='d-block text-center w-100'>
                      <NavLink to={item.route} onClick={(e) => e.preventDefault()}>
                        {/* <img src={item.icon} className='icon-sidebar' /> */}
                        <svg className='icon-sidebar icon-sm' aria-hidden='true'>
                          <SVGMaster name={item.svgname} className='icon-sm' />
                        </svg>
                        {/* <div className='sidebar-item-text small'>{t(item.translation).toLocaleLowerCase()}</div> */}
                        <div className='sidebar-item-text'>{t(item.translation, item.name)}</div>
                      </NavLink>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            {/* <div className='d-block bg-light-more'>
							{gameMenu.map(item => (
								<div key={item.id}>
									<div
										className='text-center sidebar-item d-flex'
										onClick={() => {
											sideBarClick(item);
										}}>
										<div className='d-block text-center w-100'>
											<LazyLoadImage src={getImage(item)} className='img-md' alt={item.name} />

											<div className='text-dark sidebar-item-text text-capitalize m-t-5 small'>{item.name}</div>
										</div>
									</div>
								</div>
							))}
						</div> */}
          </div>
          {/* <div
						id='sidebarSecondLevel'
						className={`sidebarSecondLevel sidebar-transition  ${props.sideBarSubMenuShow ? 'sidebarSecondShow' : 'sidebarSecondHide'}`}
						style={{ height: sidebarHeight }}>
						<div className='d-block'>
							{props.sideBarSubMenuShow &&
								gameMenu.length > 0 &&
								itemOnClickId >= 0 &&
								gameMenu[itemOnClickId].submenu.map(item => {
									if (!(item.provider === 'MEGA' || item.provider === 'PP')) {
										return (
											<div key={item.id}>
												<div
													className='text-center sidebar-item-secondLevel'
													onClick={() => {
														navigateToPage(item);
													}}>
													<div className='d-flex justify-content-end'>
														<LazyLoadImage src={item.sideSubMenuImg} alt={item.name} className='sidebarSecondLevel-Img ' />
													</div>
												</div>
											</div>
										);
									}
								})}
						</div>
					</div> */}

          {/* <div
            id='sidebarSecondLevelHidden'
            className={`sidebarSecondLevel sidebar-transition  ${props.sideBarSubMenuShow ? 'sidebarSecondShow' : 'sidebarSecondHide'}`}
            style={{ visibility: 'none' }}
          >
            <div className='d-block'>
           
              {props.sideBarSubMenuShow &&
                gameMenu.length > 0 &&
                itemOnClickId >= 0 &&
                gameMenu[itemOnClickId].submenu.map(item => {
                  if (!(item.provider === 'MEGA' || item.provider === 'PP')) {
                    return (
                      <div key={item.id}>
                        <div
                          className='text-center sidebar-item-secondLevel'
                          onClick={() => {
                            navigateToPage(item);
                          }}
                        >
                          <div className='d-flex justify-content-end'>
                            <LazyLoadImage src={item.sideSubMenuImg} alt={item.name} className='sidebarSecondLevel-Img ' />
                          </div>
                        
                        </div>
                      </div>
                    );
                  }
                })}
            </div>
          </div> */}

          <div
            className={`sidebarThirdLevel`}
            onClick={() => {
              props.sideBar();
            }}
            style={{ display: `${props.showSidebar ? 'flex' : 'none'}`, height: sidebarHeight }}></div>
        </div>
      )}
    </Translation>
  );
};

export default withRouter(withTranslation([])(Sidebar));
