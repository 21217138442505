import React, { Component } from 'react';
import { connect } from 'react-redux';
import { mapStateToProps } from '../../redux/selector/selector';

class UnreadMsg extends Component {
  isMount = true;
  constructor(props) {
    super(props);
    this.state = {
      unreadMsg: 0,
    };
  }

  componentDidMount() {
    this.isMount = true;
  }
  
  render() {
    const { user } = this.props;
    return (
      <div>
        {user.unreadMsg > 0 &&
          <div className='messaging-notification'>
            <div className='circle'></div>
            <div className='circle-count'>{user.unreadMsg}</div>
          </div>
        }
      </div>
    )
  }
}

export default connect(mapStateToProps)(UnreadMsg);