import { Component, default as React } from 'react';
import { Translation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { mapStateToProps } from '../../redux/selector/selector';
import { Col, Row } from 'react-bootstrap';
import { TweenLite } from "gsap";
class MemberHeader extends Component {
  isMount = true;
  constructor(props, context, images) {
    super(props);
    this.state = {
      menus: [],
      submenus: [],
      loginModalVisible: false,
      itemIdOnclick: '',
      messageList: [],
      listOfCheckedId: [],
      allChecked: false,
      show: false,
      checkAll: false,
      mainWallet: null,
      restoreOnLoad: false,
      likeBtn: true,
      test: [],
      providerList: [],
      toUpdate: false,
      isLoading: false,
      withDrawAnnoucementList: [],
      withdrawModal: false,
      dontShowWithdrawal: false,
      depositAnnoucementList: [],
      depositModal: false,
      dontShowDeposit: false,
      breadcumText: '',
      breadCumSecond: '',
      hasFakeSeamLess: false,
      disabledSeamless: false,
      memberHeader: 'settings:settings.tabs.heading.deposit',
      on: false,
    };

    // this.handleSeamlessChange = this.handleSeamlessChange.bind(this);
    // this.updateUserSeamless = this.updateUserSeamless.bind(this);
  }

  componentDidMount() {
    this.isMount = true;
    // this.getUserSeamlessStatus();
  }

  changeTitle = () => {
    this.setState({ itemIdOnclick: this.props.itemIdOnclick }, () => {
      let path = this.props.pathname;
      let title = this.state.itemIdOnclick;
      if (title === 'transfer') {
        this.setState({ memberHeader: 'settings:settings.tabs.heading.transfer' });
      } else if (title === 'deposit') {
        this.setState({ memberHeader: 'settings:settings.tabs.heading.deposit' });
      } else if (title === 'withdraw') {
        this.setState({ memberHeader: 'settings:settings.tabs.heading.withdraw' });
      } else if (title === 'history') {
        this.setState({ memberHeader: 'settings:settings.tabs.heading.history' });
      } else if (title === 'myprofile' && path === '/myaccount/myprofile') {
        this.setState({ memberHeader: 'settings:settings.tabs.heading.myProfile' });
      } else if (title === 'changepassword' && path === '/myaccount/changepassword') {
        this.setState({ memberHeader: 'settings:settings.tabs.heading.password' });
      } else if (title === 'changegamepassword' && path === '/myaccount/changegamepassword') {
        this.setState({ memberHeader: 'settings:settings.tabs.heading.provider_password' });
      } else if (title === 'messaging' && path === '/myaccount/messaging') {
        this.setState({ memberHeader: 'settings:settings.tabs.heading.messaging' });
      } else if (title === 'bankdetails' && path === '/myaccount/bankdetails') {
        this.setState({ memberHeader: 'settings:settings.tabs.heading.banking' });
      }
    });
  }

  isChecked = (index) => {
    return this.state.checkAll || this.state.messageList[index].isChecked;
  };

  componentWillUnmount() {
    this.isMount = false;
  }

  componentDidUpdate(prevProps) {

    if (prevProps !== this.props) {
      // this.getUserSeamlessStatus();
      this.changeTitle();
    }
  }

  // getUserSeamlessStatus() {
  //   const { isLogin } = this.props.user;
  //   if (isLogin === true) {
  //     window.SPL_Member.loadMemberSeamlessStatus().then((res) => {
  //       if (this.isMount) {
  //         this.setState({
  //           hasFakeSeamLess: res.hasFakeSeamless,
  //           disabledSeamless: res.isFakeSeamlessDisabled,
  //         });
  //       }
  //     });
  //   }
  // }
  // handleSeamlessChange = (e) => {
  //   let checkSeamLess = e.target.checked;

  //   if (checkSeamLess === true) {
  //     this.setState({
  //       disabledSeamless: !this.state.disabledSeamless,
  //     });
  //   } else {
  //     this.setState({
  //       disabledSeamless: !this.state.disabledSeamless,
  //     });
  //   }
  // };

  // updateUserSeamless() {
  //   const { disabledSeamless } = this.state;
  //   window.SPL_Member.updateMemberSeamlessStatus(disabledSeamless).then(
  //     (res) => {
  //       if (res.hasFakeSeamLess) {
  //         this.setState({
  //           disabledSeamless: !disabledSeamless,
  //           message: res.message,
  //         });
  //       }
  //     }
  //   );
  // }
  onReload() {
    TweenLite.fromTo(".main-wallet-header .icon-reload", 1, {
      rotate: 0
    }, {
      rotate: 360
    })
  }

  onCheck() {
    this.setState((state) => ({
      on: !state.on
    }));
  }

  render = () => {
    const { restoreOnLoad } = this.state;
    // const { history } = this.props;
    // const { iconLoading } = this.state;
    // let btn_class = this.state.likeBtn ? this.props.color : this.props.color2;
    return (
      <Translation>
        {(t) =>

          // <div className='member-header d-flex'>
          //   <Col xs='6' className='myacc-page-title'>
          //     {t(this.state.memberHeader)}
          //   </Col>
          //   <Col xs='6' className='d-flex justify-content-end align-items-center'>
          //     <div>
          //     Main Wallet Auto Transfe
          //     </div>

          //     <div className="auto-transfer">
          //       <span className='auto-transfer-text'>
          //         {t('', 'Wallet Auto Transfer')}
          //       </span>
          //       <label className='auto-transfer-icon' onClick={this.updateUserSeamless}>
          //         <input type='checkbox' id='chkseamless' checked={!this.state.disabledSeamless} onChange={(event) => this.handleSeamlessChange(event)} />
          //         <span className='check'></span>
          //         <span className='check-btn'></span>
          //       </label>
          //     </div>
          //   </Col>
          // </div>

          <div className="member-header">
            <div className="auto-transfer">
              <span>{t('global:global.menu.account.autoTransfer', 'Main Wallet Auto Transfer')}</span>
              <i className={`icon-${this.state.on ? "on" : "off"}`} onClick={this.onCheck.bind(this)}></i>
            </div>
            <div className="main-wallet-header">



            </div>
          </div>

        }
      </Translation>
    );
  };
}
export default connect(
  mapStateToProps,
  null
)(withTranslation(['error'])(MemberHeader));
