import React from 'react';
import { DebounceInput } from 'react-debounce-input';
import { Col, Row, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { userAction } from '../../../redux/action';
import Select from 'react-dropdown-select';
import { mapStateToProps } from '../../../redux/selector/selector';
import { Trans, Translation, withTranslation } from 'react-i18next';
import FlagsSelect from '../052_Flag_Select';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';

class Step1 extends React.Component {
    isMount = true;
    constructor(props) {
        super(props);
        this.state = {
            username: '',
            password: '',
            confirmpassword: '',
            fullname: '',
            email: '',
            affiliateId: '',
            phone: '',
            usernameError: [],
            passwordError: [],
            confirmpasswordError: [],
            fullnameError: [],
            emailError: [],
            affiliateError: [],
            phoneError: [],
            telCode: '',
            telCodeList: [],
            ageTerms: false,
            disabledButton: false,
            disabledAffiliate: false,
            hideAff: false,
            termContent: '',
            modal: false,
            hiddenCurrent: true,
            hiddenConfirm: true,
            termContent: '',
        };
        this.toggle = this.toggle.bind(this);
    }

    componentDidMount() {
        this.getCountryInfo();
        this.initRegisterSettings();
        this.getTerms();
    }

    initRegisterSettings = () => {
        const { portal } = this.props;
        if (portal && portal.settings) {
            if (portal.settings.hasOwnProperty('registerHideAff')) {
                let hideAff = portal.settings.registerHideAff;
                this.setState({ hideAff });
            }

            if (portal.settings.hasOwnProperty('currencies')) {
                let currencies = portal.settings.currencies.split(',').map((c) => ({ value: c, label: c }));
                this.setState({ currencies }, () => {
                    this.getCountryInfo();
                });
            }
        }
    };

    getCountryInfo() {
        window.SPL_Content.getCountryInfo().then((telCodeList) => {
            if (this.isMount) {
                const { language } = this.props;
                let selectedObj = this.getSelectedObj(telCodeList, language.country);
                this.setState({
                    telCodeList: telCodeList,
                    telCode: selectedObj.telCode,
                    countryCode: selectedObj.countryCode,
                    currency: selectedObj.currency,
                });
            }
        });
    }

    getSelectedObj(telCodeList, country) {
        let returnObj = {};
        for (let i = 0; i < telCodeList.length; i++) {
            const telCodeObj = telCodeList[i];
            if (telCodeObj.country === country) {
                returnObj = {
                    telCode: `+${telCodeObj.telCode}`,
                    countryCode: telCodeObj.country,
                    currency: telCodeObj.currency,
                };
            }
        }
        return returnObj;
    }

    componentWillUnmount() {
        this.isMount = false;
    }

    handleUserNameChange = (event) => {
        this.setState({ username: event.target.value }, () => {
            this.validateUserName();
        });
    };

    handlePasswordChange = (event) => {
        this.setState({ password: event.target.value }, () => {
            this.validatePassword();
        });
    };

    handleconfirmPasswordChange = (event) => {
        this.setState({ confirmpassword: event.target.value }, () => {
            this.validateConfirmPassword();
        });
    };
    handleFullnameChange = (event) => {
        this.setState({ fullname: event.target.value }, () => {
            this.validateFullname();
        });
    };

    handleEmailChange = (event) => {
        this.setState({ email: event.target.value }, () => {
            this.validateEmail();
        });
    };

    handlePhoneChange = (event) => {
        if (this.isNumber(event)) {
            let phoneNumber = event.target.value;

            this.setState({ phone: phoneNumber }, () => {});
        }
    };

    handleAffiliateChange = (event) => {
        this.setState({ affiliateId: event.target.value }, () => {
            this.validateAffiliate();
        });
    };

    isNumber = (event) => {
        return /^\d*$/.test(event.target.value);
    };

    isError = (errMsg) => {
        return errMsg.length > 0;
    };

    toggle() {
        this.setState({ modal: !this.state.modal });
    }

    toggleShowCurrent = () => {
        this.setState({ hiddenCurrent: !this.state.hiddenCurrent });
    };

    toggleShowConfirm = () => {
        this.setState({ hiddenConfirm: !this.state.hiddenConfirm });
    };

    validateUserName = () => {
        const { username } = this.state;
        let thisObj = this;
        return new Promise(function (resolve, reject) {
            window.SPL_Register.validateUsername(username, window.merchantCode).then((errMsg) => {
                thisObj.setState({ usernameError: errMsg });
                resolve(thisObj.isError(errMsg));
            });
        });
    };

    validateFullname = () => {
        const { fullname } = this.state;
        let thisObj = this;
        return new Promise(function (resolve, reject) {
            window.SPL_Register.validateFullName(fullname, window.merchantCode).then((errMsg) => {
                thisObj.setState({ fullnameError: errMsg });
                resolve(thisObj.isError(errMsg));
            });
        });
    };

    validateEmail = () => {
        const { email } = this.state;
        let thisObj = this;
        return new Promise(function (resolve, reject) {
            window.SPL_Register.validateEmail(email, window.merchantCode).then((errMsg) => {
                thisObj.setState({ emailError: errMsg });
                resolve(thisObj.isError(errMsg));
            });
        });
    };

    validateAffiliate = () => {
        const { affiliateId } = this.state;
        let thisObj = this;
        return new Promise(function (resolve, reject) {
            window.SPL_Register.validateAffiliate(affiliateId, window.merchantCode).then((errMsg) => {
                thisObj.setState({ affiliateError: errMsg });
                resolve(thisObj.isError(errMsg));
            });
        });
    };

    validatePassword = () => {
        const { password, confirmpassword } = this.state;
        let thisObj = this;
        return new Promise(function (resolve, reject) {
            window.SPL_Register.validatePassword(password, confirmpassword).then((errMsg) => {
                thisObj.setState({ passwordError: errMsg });
                resolve(thisObj.isError(errMsg));
            });
        });
    };

    validateConfirmPassword = () => {
        const { password, confirmpassword } = this.state;
        let thisObj = this;
        return new Promise(function (resolve, reject) {
            window.SPL_Register.validateConfirmPassword(password, confirmpassword).then((errMsg) => {
                thisObj.setState({ confirmpasswordError: errMsg });
                resolve(thisObj.isError(errMsg));
            });
        });
    };

    handleSubmit = (event) => {
        event.preventDefault();
        const { username, password, fullname, email, phone, affiliateId, currency } = this.state;
        const { language } = this.props;

        this.setState({ disabledButton: true }, () => {
            Promise.all([
                this.validateUserName(),
                this.validatePassword(),
                this.validateConfirmPassword(),
                this.validateEmail(),
                this.validatePhone(),
                this.validateFullname(),
                this.validateAffiliate(),
            ]).then((data) => {
                // true mean having error
                if (data.includes(true)) {
                    this.setState({ disabledButton: false });
                    return;
                } else {
                    let languageKey = language.key;
                    let registerObj = {
                        login: username,
                        password: password,
                        email: email,
                        name: fullname,
                        langKey: languageKey,
                        language: languageKey.toUpperCase(),
                        phone: phone,
                        merchantCode: window.merchantCode,
                        currency: currency,
                        affiliateName: affiliateId === '' ? null : affiliateId,
                    };
                    window.SPL_Register.registerAccount(registerObj).then((data) => {
                        if (data.status === 'S') {
                            this.setState({ disabledButton: false });

                            this.loginAndNavigate();
                            // this.props.history.push('/home');
                        }
                    });
                }
            });
        });
    };

    loginAndNavigate = () => {
        let loginObj = {
            domain: '',
            isAffiliate: false,
            isVipLogin: false,
            language: this.props.language.key,
            merchantCode: window.merchantCode,
            login: this.state.username,
            password: this.state.password,
            rememberMe: false,
        };

        this.props.dispatch(userAction.login(loginObj)).then(() => {
            this.props.dispatch(userAction.isAuthenticated());
            this.props.history.push('/registersuccess');
        });
    };

    getTerms() {
        const { language } = this.props;
        window.SPL_Content.getTermPage(language.countryLanguageKey).then((res) => {
            if (this.isMount) {
                this.setState({
                    termContent: res.content,
                });
            }
        });
    }

    render() {
        if (this.props.currentStep !== 1) {
            return null;
        }

        return (
            <Translation>
                {(t) => (
                    <div>
                        <Modal className='tncModal modal-lg' isOpen={this.state.modal} toggle={this.toggle}>
                            <ModalHeader className='w-100 d-flex' toggle={this.toggle}>
                                <Col xs='12' sm='12' className='d-flex justify-content-start align-items-center'>
                                    <div className='text-nowrap'>{t('terms:terms.termsandconditions')}</div>
                                </Col>
                            </ModalHeader>
                            <ModalBody className='text-left w-100'>
                                <div className='p-10' dangerouslySetInnerHTML={{ __html: this.state.termContent }} />
                            </ModalBody>
                        </Modal>

                        <div className='registerPanel-content'>
                            <Col xs='12' className='registerTab-input'>
                                <label htmlFor='username'>
                                    {t('global:global.form.username-placeholder')}
                                    <span className='text-danger m-l-5'>*</span>
                                </label>
                                <DebounceInput
                                    name='username'
                                    className={`form-control-inner ${this.state.usernameError.length > 0 ? 'is-invalid' : ''}`}
                                    id='username'
                                    debounceTimeout={1000}
                                    value={this.props.username}
                                    onChange={this.props.handleUserNameChange}
                                    onBlur={(this.validateUserName, this.handleUserNameChange)}
                                    required
                                />
                                {this.state.usernameError.map((errMsg, index) => {
                                    return (
                                        <div key={index} className='invalid-feedback text-danger'>
                                            <Trans i18nKey={errMsg}></Trans>
                                        </div>
                                    );
                                })}
                            </Col>

                            <Col xs='12' className='registerTab-input'>
                                <label htmlFor='password'>
                                    {t('global:global.form.newpassword-placeholder')}
                                    <span className='text-danger m-l-5'>*</span>
                                </label>
                                <input
                                    name='password'
                                    className={`form-control-inner ${this.state.passwordError.length > 0 ? 'is-invalid' : ''}`}
                                    id='password'
                                    type='password'
                                    value={this.props.password}
                                    onChange={this.props.handlePasswordChange}
                                    onBlur={(this.validatePassword, this.handlePasswordChange)}
                                    required
                                />

                                {this.state.passwordError.map((errMsg, index) => {
                                    return (
                                        <div key={index} className='invalid-feedback text-danger'>
                                            <Trans i18nKey={errMsg}></Trans>
                                        </div>
                                    );
                                })}
                            </Col>

                            <Col xs='12' className='registerTab-input'>
                                <label htmlFor='confirmpassword'>
                                    {t('global:global.form.confirmpassword')}
                                    <span className='text-danger m-l-5'>*</span>
                                </label>
                                <input
                                    name='confirmpassword'
                                    className={`form-control-inner ${this.state.confirmpasswordError.length > 0 ? 'is-invalid' : ''}`}
                                    id='confirmpassword'
                                    type='password'
                                    value={this.props.confirmpassword}
                                    onChange={this.props.handleconfirmPasswordChange}
                                    onBlur={(this.validateConfirmPassword, this.handleconfirmPasswordChange)}
                                    required
                                />

                                {this.state.confirmpasswordError.map((errMsg, index) => {
                                    return (
                                        <div key={index} className='invalid-feedback text-danger'>
                                            <Trans i18nKey={errMsg}></Trans>
                                        </div>
                                    );
                                })}
                            </Col>

                            <Col xs='12' className='registerTab-input'>
                                <label htmlFor='fullname'>
                                    {t('global:global.form.fullname')}
                                    <span className='text-danger m-l-5'>*</span>
                                </label>
                                <DebounceInput
                                    name='fullname'
                                    className={`form-control-inner ${this.state.fullnameError.length > 0 ? 'is-invalid' : ''}`}
                                    id='fullname'
                                    debounceTimeout={1000}
                                    value={this.state.fullname}
                                    onChange={this.props.handleFullnameChange}
                                    onBlur={(this.validateFullname, this.handleFullnameChange)}
                                />

                                {this.state.fullnameError.map((errMsg, index) => {
                                    return (
                                        <div key={index} className='invalid-feedback text-danger'>
                                            <Trans i18nKey={errMsg}></Trans>
                                        </div>
                                    );
                                })}
                            </Col>

                            {/* Email */}
                            <Col xs='12' className='registerTab-input'>
                                <label htmlFor='email'>
                                    {t('global:global.form.email-placeholder')}
                                    <span className='text-danger m-l-5'>*</span>
                                </label>
                                <DebounceInput
                                    name='email'
                                    className={`form-control-inner ${this.state.emailError.length > 0 ? 'is-invalid' : ''}`}
                                    debounceTimeout={1000}
                                    id='email'
                                    value={this.props.email}
                                    onChange={this.props.handleEmailChange}
                                    onBlur={(this.validateEmail, this.handleEmailChange)}
                                />

                                {this.state.emailError.map((errMsg, index) => {
                                    return (
                                        <div key={index} className='invalid-feedback text-danger'>
                                            <Trans i18nKey={errMsg}></Trans>
                                        </div>
                                    );
                                })}
                            </Col>

                            {/* Currency */}
                            <Col xs='12' className='registerTab-input'>
                                <label htmlFor='currency'>
                                    {t('global:global.form.currency')}
                                    <span className='text-danger m-l-5'>*</span>
                                </label>
                                <Select
                                    noDataRenderer={this.customNoDataRenderer}
                                    className='dropdown-registerTab'
                                    options={this.props.currencies}
                                    value={this.props.currency}
                                    placeholder={t('global:global.form.Select')}
                                    key='value'
                                    labelField='label'
                                    valueField='value'
                                    onChange={(value) => this.props.handleCurrencyChange(value)}
                                    searchable={false}
                                />
                            </Col>

                            {/* phone */}
                            <Col xs='12' className='registerTab-input'>
                                <label htmlFor='phone'>
                                    {t('global:global.form.phone')} <span className='text-danger m-l-5'>*</span>
                                </label>
                                <Row className='d-flex align-items-center m-l-0 m-r-0'>
                                    <Col xs='4' sm='4' className={`p-l-0 p-r-0 ${this.state.phoneError.length > 0 ? 'm-t--20' : ''}`}>
                                        <FlagsSelect
                                            telCodeList={this.props.telCodeList}
                                            onSelect={this.props.selectCode}
                                            countryCode={this.props.countryCode}
                                            className='form-control-inner'
                                        />
                                    </Col>
                                    <Col xs='8' sm='8' className='p-l-0 p-r-0 d-flex justify-content-end'>
                                        <Row className='m-l-10 w-100'>
                                            <DebounceInput
                                                name='phone'
                                                className={`col-12 p-l-5 p-r-5 dropdown-registerTab register-field ${this.state.phoneError.length > 0 ? 'is-invalid' : ''}`}
                                                id='phone'
                                                debounceTimeout={1000}
                                                value={this.props.phone}
                                                onChange={this.props.handlePhoneChange}
                                                onBlur={(this.validatePhone, this.handlePhoneChange)}
                                            />
                                            {this.state.phoneError.map((errMsg, index) => {
                                                return (
                                                    <div key={index} className='col-12 invalid-feedback text-danger'>
                                                        <Trans i18nKey={errMsg}></Trans>
                                                    </div>
                                                );
                                            })}
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>

                            {!this.state.hideAff && (
                                <Col xs='12' className='registerTab-input'>
                                    <label htmlFor='affiliate'>{t('global:global.form.affiliateID-placeholder')}</label>
                                    <input
                                        name='affiliate'
                                        className={`form-control-inner ${this.state.affiliateError.length > 0 ? 'is-invalid' : ''}`}
                                        id='affiliate'
                                        placeholder={t('global:global.form.affiliateID-placeholderv2')}
                                        value={this.props.affiliateId}
                                        onChange={this.props.handleAffiliateChange}
                                        onBlur={(this.validateAffiliate, this.handleAffiliateChange)}
                                        disabled={this.props.disabledAffiliate}
                                    />

                                    {this.state.affiliateError.map((errMsg, index) => {
                                        return (
                                            <div key={index} className='invalid-feedback text-danger'>
                                                <Trans i18nKey={errMsg}></Trans>
                                            </div>
                                        );
                                    })}
                                </Col>
                            )}

                            <Col xs='12' className='registerTab-input'>
                                <Col xs={12}>
                                    <div className='lh-1.2 tnc-text'>
                                        {t('register:register.condition.096_line', `By clicking the `)}
                                        <span>{t('register:register.condition.line2', `REGISTER `)}</span>
                                        {t('register:register.condition.096_line2', `button, I hereby `)}
                                        <br />
                                        {t('register:register.condition.096_line3', `acknowledge that I am above 18 years old and `)}
                                        <br />
                                        <div className='last-line'>
                                            {t('register:register.condition.096_line4', `have read and accepted your `)}
                                            <Link className='text-underline' onClick={this.toggle}>
                                                {t('register:register.condition.tnc', 'Terms & Conditions')}
                                            </Link>
                                            .
                                        </div>
                                    </div>
                                </Col>
                            </Col>
                        </div>
                    </div>
                )}
            </Translation>
        );
    }
}
export default withRouter(connect(mapStateToProps, null)(withTranslation(['password'])(Step1)));
