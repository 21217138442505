const depositMaterial = {
    bankCache: {},
    merchantBank: {},
    bankAppData: {},
    depositDetails: [],
    currentCurrency: '',
    currentBankCurrency: '',

    setDepositMethod(selectedMethod, data) {
        depositMaterial.bankCache[selectedMethod] = data;
    },

    getDepositMethod(selectedMethod) {
        return depositMaterial.bankCache[selectedMethod];
    },

    setMerchantBank(data) {
        depositMaterial.merchantBank = data;
    },

    getMerchantBank() {
        return depositMaterial.merchantBank;
    },

    setDepositDetails(data) {
        depositMaterial.depositDetails = data;
    },

    getDepositDetails() {
        return depositMaterial.depositDetails;
    },

    setBankAppData(data) {
        depositMaterial.bankAppData = data;
    },

    getBankAppData() {
        return depositMaterial.bankAppData;
    },

    setCurrentCurrency(currency) {
        depositMaterial.currentCurrency = currency;
    },

    getCurrentCurrency() {
        return depositMaterial.currentCurrency;
    },

    setCurrentBankCurrency(currency) {
        depositMaterial.currentBankCurrency = currency;
    },

    getCurrentBankCurrency() {
        return depositMaterial.currentBankCurrency;
    },
};

export default depositMaterial;

export const sumUp = (currentAmount, value, decimal = 2) => {
    const intCA = currentAmount ? parseFloat(currentAmount) : 0;
    const intValue = value ? parseFloat(value) : 0;
    let result = (intCA + intValue).toFixed(decimal);

    result = parseFloat(result).toString();

    return result;
};
