import React from 'react';
import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { gu_logout } from 'root/utils/general-util';
import { passwordAction } from 'root/redux/action/password.action';
import { useValidatePassword, usePreCheckSpecialCharFormatValidity } from 'root/hook/password.hook';
import { psw_validateConfirmPassword, psw_validateCurrentPassword } from 'root/utils/password.util';

import PopupWrapper from '../../../../pages/Share/popup/popup-wrapper/popup-wrapper';
import PopupHeader from '../../../../pages/Share/popup/popup-header/popup-header';
import styles from './force-change-password.module.scss';
import notification from 'root/utils/notification';
import FormField from 'root/components/maple/molecules/form-field/form-field';

const TransactionTitle = () => {
    const { t } = useTranslation(['password', 'settings']);
    const dispatch = useDispatch();
    const passwordReducer = useSelector((state) => state.password);
    const languageRedux = useSelector((state) => state.language);
    const portalRedux = useSelector((state) => state.portal);
    const passwordConfig = passwordReducer?.processedData?.passwordConfig || {};

    const [isSkipCheckSpecialChars, setIsSkipCheckSpecialChars] = useState(true);

    const [changedField, setChangedField] = useState(null);

    const [isButtonClicked, setIsButtonClicked] = useState(false);
    const [errors, setError] = useState({});

    const [fields, setField] = useState({
        currentPassword: '',
        password: '',
        confirmPassword: '',
    });

    useEffect(() => {
        dispatch(passwordAction.fetchGetPasswordConfigApi(portalRedux?.settings?.isSkipCheckPasswordConfig));
    }, []);

    useEffect(() => {
        if (passwordReducer?.rawData?.status) {
            dispatch(passwordAction.fetchProcessedPasswordConfig());
        }
    }, [passwordReducer?.rawData?.status]);

    const onFieldChange = (event) => {
        const { name, value } = event.target;
        setField({
            ...fields,
            [name]: value,
        });
        setChangedField(name);
        setIsButtonClicked(false);
    };

    useEffect(() => {
        switch (changedField) {
            case 'currentPassword':
                onValidateCurrentPassword();
                break;
            case 'password':
                onValidatePassword();
                break;
            case 'confirmPassword':
                onValidateConfirmPassword();
                break;
            default:
                break;
        }
    }, [changedField, fields[changedField]]);

    const passwordError = useValidatePassword(fields['password'], isSkipCheckSpecialChars);

    const verifyCurrentAndNewPassword = (updatedError) => {
        const checkErrorKey = psw_validateCurrentPassword(fields['currentPassword'], fields['password']);
        const isSamePwd = fields['currentPassword'] === fields['password'];

        updatedError.password = isSamePwd ? 'password_same' : passwordError;

        updatedError.currentPassword = isSamePwd ? '' : checkErrorKey;

        return updatedError;
    };

    function onValidateCurrentPassword() {
        let updatedError = {};
        updatedError = verifyCurrentAndNewPassword(updatedError);
        setError({ ...errors, ...updatedError });
    }

    function onValidatePassword() {
        let updatedError = {};
        updatedError = verifyCurrentAndNewPassword(updatedError);
        if (fields['confirmPassword']) {
            updatedError.confirmPassword = psw_validateConfirmPassword(fields['password'], fields['confirmPassword']);
        }

        setError({ ...errors, ...updatedError });
    }

    function onValidateConfirmPassword() {
        const errorKey = psw_validateConfirmPassword(fields['password'], fields['confirmPassword']);
        setError({ ...errors, confirmPassword: errorKey });
    }

    const isPreCheckPasswordContainError = !usePreCheckSpecialCharFormatValidity(fields['password'], isSkipCheckSpecialChars);
    const submitForceChangePasswordForm = async () => {
        setIsButtonClicked(true);
        if (isPreCheckPasswordContainError) {
            setIsSkipCheckSpecialChars(false);
            return;
        }

        let changePasswordApiParam = {
            newPassword: fields['password'],
            password: fields['currentPassword'],
        };
        const passwordConfigApiData = passwordReducer.rawData?.passwordConfigApiData;
        if (passwordConfigApiData && Object.keys(passwordConfigApiData).length > 0) {
            changePasswordApiParam = {
                ...changePasswordApiParam,
                passwordConfig: passwordConfigApiData,
            };
        }

        try {
            const isUpdateSuccess = await window.SPL_Member.changePasswordV2(changePasswordApiParam);
            if (isUpdateSuccess) {
                notification.showNotification(
                    'success',
                    <div
                        dangerouslySetInnerHTML={{
                            __html: t('password:password.messages.success', { interpolation: { escapeValue: false } }),
                        }}
                    />
                );
                gu_logout(dispatch, languageRedux?.countryLanguage);
            }
        } catch (err) {
            // TODO: can ask whether we should have more clear error message based on errorKey/message from BE, currently we only show general error message
            notification.showNotification(
                'error',
                <div
                    dangerouslySetInnerHTML={{
                        __html: t('global:global.messages.error.invalidcurrentpass', { interpolation: { escapeValue: false } }),
                    }}
                />
            );
        }
    };
    useEffect(() => {
        // submit trigger isSkipCheckSpecialChars become false and validate field again.
        if (!isSkipCheckSpecialChars) {
            onValidatePassword();
        }
    }, [isSkipCheckSpecialChars]);

    useEffect(() => {
        isSubmitBtnDisable();
    }, [isButtonClicked]);

    const isSubmitBtnDisable = () => {
        const isMandatoryFieldsInvalid = fields && Object.keys(fields).some((field) => !fields[field]);
        const isErrorExist = Object.keys(errors).some((field) => errors[field]);

        return isMandatoryFieldsInvalid || isErrorExist || isButtonClicked ? true : false;
    };

    return (
        <PopupWrapper isOpen={true} overlayClassName={`popup-overlay`} className={`${styles.forceChangePasswordPopup} popup-container`}>
            <PopupHeader title={t('settings:settings.profiletab.changepass', 'Change Password')} />

            <div className={`${styles.forceChangePasswordContainer}`}>
                <div className={`${styles.forceChangePasswordFormItemContainer}`}>
                    <FormField
                        label={t('global:global.form.currentpassword', 'Current Password')}
                        type={'password'}
                        name={'currentPassword'}
                        value={fields['currentPassword']}
                        errorMessage={
                            errors['currentPassword'] &&
                            t(`password:password.messages.${errors['currentPassword']}`, { min: passwordConfig?.minLength, max: passwordConfig?.maxLength })
                        }
                        placeholder={t('global:global.form.currentpassword', 'Current password')}
                        onChange={onFieldChange}
                        onBlur={onValidateCurrentPassword}
                        layout={'vertical'}
                    />

                    <FormField
                        label={t('global:global.form.newpassword', 'Password')}
                        type={'password'}
                        name={'password'}
                        value={fields['password']}
                        errorMessage={errors['password'] && t(`password:password.messages.${errors['password']}`)}
                        placeholder={t('global:global.form.newpassword', 'Password')}
                        onChange={onFieldChange}
                        onBlur={onValidatePassword}
                        isShowSpecialCharsGuide={isSkipCheckSpecialChars ? false : true}
                        isValidationChecker={true}
                        layout={'vertical'}
                        showErrorMsgPriority={fields['password'] === fields['currentPassword']}
                    />

                    <FormField
                        label={t('global:global.form.confirmpassword', 'Confirm Password')}
                        type={'password'}
                        name={'confirmPassword'}
                        value={fields['confirmPassword']}
                        errorMessage={errors['confirmPassword'] && t(`password:password.messages.${errors['confirmPassword']}`)}
                        placeholder={t('global:global.form.confirmpassword', 'Confirm Password')}
                        onChange={onFieldChange}
                        onBlur={onValidateCurrentPassword}
                        layout={'vertical'}
                    />
                </div>

                <div className={`${styles.buttonContainer}`}>
                    <button className={`${styles.saveButton}`} type='submit' disabled={isSubmitBtnDisable()} onClick={submitForceChangePasswordForm}>
                        {t('global:entity.action.save', 'Save')}
                    </button>
                </div>
            </div>
        </PopupWrapper>
    );
};

export default TransactionTitle;
