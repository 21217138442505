import React, { Component } from 'react';
import { Trans, Translation, withTranslation } from 'react-i18next';
import SVGPassword from '../../../components/Icon/SVGPassword';
import { connect } from 'react-redux';
import { mapStateToProps } from '../../../redux/selector/selector';
import { withRouter } from 'react-router-dom';
import Select from 'react-dropdown-select';
import SVGElements from 'root/components/SVGElements';
import notification from 'root/utils/notification';

class ChangeGamePassword extends Component {
    isMount = true;
    constructor(props) {
        super(props);
        this.state = {
            newpassword: '',
            confirmpassword: '',
            samePassword: false,
            hiddenCurrent: true,
            hiddenNewPassword: true,
            hiddenConfirmPassword: true,
            newPasswordErrMsg: [],
            providerList: [],
            message: '',
            name: '',
            passguide: '',
            disabledButton: true,
            minLength: 0,
        };
    }

    handleNewPasswordChange = (e) => {
        const newpassword = e.target.value;

        this.setState({ newpassword: newpassword, message: '' }, () => {
            this.validatePassword();
            this.valdiate();
        });
    };

    handleConfirmNewPasswordChange = (e) => {
        const confirmpassword = e.target.value;

        this.setState({ confirmpassword: confirmpassword, message: '' }, () => {
            this.validatePassword();
            this.valdiate();
        });
    };

    componentDidMount() {
        this.isMount = true;
        if (this.props.newpassword) {
            this.setState({ newpassword: this.props.newpassword });
        }
        this.getProviders();

        if (this.props.screen.viewType === 'web' && this.props.location.pathname === '/changegamepassword') {
            this.props.history.push('/myaccount/changegamepassword');
        } else if (this.props.screen.viewType === 'mobile' && this.props.location.pathname === '/myaccount/changegamepassword') {
            this.props.history.push('/myaccount/myprofile');
            this.props.history.push('/changegamepassword');
        }
    }

    componentWillUnmount() {
        this.isMount = false;
    }

    handleSubmit = () => {
        if (this.state.newPasswordErrMsg.length > 0) {
            return;
        }
        if (this.state.confirmpassword.length <= 0 || this.state.newpassword.length <= 0) {
            return;
        }
        window.SPL_Provider.changeProviderPassword(this.state.selectedProvider[0].provider, this.state.newpassword).then((data) => {
            if (this.isMount) {
                if (data.status === 'S') {
                    this.setState({
                        message: 'password:password.messages.success',
                        status: 'S',
                        currentpassword: '',
                        newpassword: '',
                        confirmpassword: '',
                    });
                } else if (data.status === 'F') {
                    this.setState({
                        message: 'password:password.messages.error',
                        status: 'F',
                    });
                }
            }
        });
    };

    getProviders = () => {
        window.SPL_Provider.getChangePasswordProviderList().then((data) => {
            let selectedProvider = '';
            if (data.length > 0) {
                selectedProvider = data[0];
            }
            this.setState({ providerList: data, selectedProvider: [selectedProvider] });
        });
    };

    validatePassword = () => {
        window.SPL_Provider.validateGamePassword(this.state.newpassword, this.state.confirmpassword, this.state.pattern, this.state.passguide, this.state.minLength, '').then(
            (errMsg) => {
                if (this.isMount) {
                    this.setState({ newPasswordErrMsg: errMsg });
                    this.valdiate();
                }
            }
        );
    };

    copy = (text) => {
        const { t } = this.props;
        let copied = 'transaction:transaction.withdrawal.copied';
        window.SPL_Other.copyToClipboard(text);
        try {
            let successful = document.execCommand('copy');
            if (successful) {
                notification.showNotification('success', t(copied));
            }
        } catch (err) {
            console.log('Oops, unable to copy');
        }
    };

    handleProviderChange = (value) => {
        let provider = value[0];
        if (provider) {
            // if provider null then get main wallet
            this.setState({ selectedProvider: value, message: '' }, () => {
                this.getPassGuide();
                this.getGameLoginCredential();
            });
        }
    };

    getPassGuide = () => {
        window.SPL_Provider.passwordGuide(this.state.selectedProvider[0].provider).then((data) => {
            if (this.isMount) {
                let guide = data.passguide;
                let pattern = data.pattern;
                let minLength = data.minlength;
                this.setState({ passguide: this.props.t(guide), pattern: pattern, minLength: minLength });
            }
        });
    };

    getGameLoginCredential = () => {
        let provider = this.state.selectedProvider[0].provider;
        if (provider !== '') {
            let params = {
                provider: this.state.selectedProvider[0].provider,
            };
            window.SPL_Provider.getGameLoginCredential(params).then((credential) => {
                if (this.isMount) {
                    this.setState({ name: (credential && credential.playId && credential.playId.toLowerCase()) || '' });
                }
            });
        }
    };

    valdiate = () => {
        const { newPasswordErrMsg, confirmpassword, newpassword } = this.state;

        if (confirmpassword === '' || newpassword === '' || newPasswordErrMsg.length > 0) {
            this.setState({ disabledButton: true });
        } else {
            this.setState({ disabledButton: false });
        }
    };

    render() {
        return (
            <Translation>
                {(t) => (
                    <div className='wap-changepass-page'>
                        <div className='page-content'>
                            <div className='changepass-item-box'>
                                <div className='title-item'>
                                    <span>
                                        {' '}
                                        {t('password:password.form.provider')} <span className='asterisk'>*</span>
                                    </span>
                                </div>
                                <div className='pass-details'>
                                    <Select
                                        placeholder='Provider'
                                        options={this.state.providerList}
                                        values={this.state.selectedProvider}
                                        labelField='name'
                                        valueField='provider'
                                        key='provider'
                                        searchable={false}
                                        onChange={(value) => this.handleProviderChange(value)}
                                    />
                                </div>
                            </div>

                            <div className='changepass-item-box'>
                                <div className='title-item'>
                                    <span>
                                        {' '}
                                        {t('password:password.form.provider-playid')} <span className='asterisk'>*</span>
                                    </span>
                                </div>
                                <div className='input-box pass-details changegame-name'>
                                    <input disabled value={this.state.name} className='disabled-input' />
                                    <div className='icon-box' onClick={() => this.copy(this.state.name)}>
                                        <SVGElements className='icon-copy' name='copy-icon' />
                                        {/* <i className="icon-copy"></i> */}
                                    </div>
                                </div>
                            </div>

                            <div className='changepass-item-box'>
                                <div className='title-item'>
                                    <span>
                                        {' '}
                                        {t('global.form.newpassword')} <span className='asterisk'>*</span>
                                    </span>
                                </div>
                                <div className='pass-details'>
                                    <input
                                        value={this.state.newpassword}
                                        onChange={this.handleNewPasswordChange}
                                        onBlur={this.validateNewPassword}
                                        type={this.state.hiddenNewPassword ? 'password' : 'text'}
                                    />
                                </div>
                            </div>

                            <div className='changepass-item-box'>
                                <div className='title-item'>
                                    <span>
                                        {' '}
                                        {t('global.form.confirmpassword')} <span className='asterisk'>*</span>
                                    </span>
                                </div>
                                <div className='pass-details'>
                                    <input
                                        value={this.state.confirmpassword}
                                        onChange={this.handleConfirmNewPasswordChange}
                                        onBlur={this.validateNewPassword}
                                        type='password'
                                    />
                                    {this.state.newPasswordErrMsg.map((errMsg, index) => {
                                        return (
                                            <div key={index} className='invalid-feedback text-danger'>
                                                {t(errMsg, { min: this.state.minLength })}
                                                {/* <Trans i18nKey={errMsg}></Trans> */}
                                            </div>
                                        );
                                    })}
                                    {this.state.status === 'F' && (
                                        <div className='invalid-feedback text-danger'>
                                            <Trans i18nKey={this.state.message}></Trans>
                                        </div>
                                    )}
                                    {this.state.status === 'S' && (
                                        <div className='invalid-feedback text-success'>
                                            <Trans i18nKey={this.state.message}></Trans>
                                        </div>
                                    )}
                                </div>
                            </div>

                            {/* havent done */}
                            <div className='changepass-item-box'>
                                <div className='passguide'>
                                    <span> {this.state.passguide} </span>
                                </div>
                            </div>

                            <div className='changepass-item-box'>
                                <div className='changepass-button'>
                                    <button type='submit' onClick={this.handleSubmit} className='btn-normal btn-mobile-changepass' disabled={this.state.disabledButton}>
                                        <span>{t('global:entity.action.save')}</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </Translation>
        );
    }
}

export default connect(mapStateToProps, null)(withTranslation(['password'])(withRouter(ChangeGamePassword)));
