import React from 'react';
import { Translation, withTranslation } from 'react-i18next';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { mapStateToProps } from '../../../redux/selector/selector';
import GetLoginUsername_01 from './GetLoginUsername_01/GetLoginUsername_01';

class UserName extends React.Component {
  getLoginUsernameLayout = () => {
    let layout = window.layoutGroup;
    if (["L03"].includes(layout)) {
      return "GetLoginUsername_03" 
    }
     else {
      // default or no special requirement go to else
      return "GetLoginUsername_01"
    }

  }
  
  render() {
    return (
      <Translation>
        {(t) => (
          <div className='header-username d-flex'>
            {this.getLoginUsernameLayout() === 'GetLoginUsername_01' && <GetLoginUsername_01 />}
          </div>
        )}
      </Translation>
    );
  }
}

export default connect(mapStateToProps)(withTranslation(['casino', 'member'])(withRouter(UserName)));
