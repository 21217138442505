import React from 'react';
import { withRouter } from 'react-router-dom';
import { Col, Row, ModalBody, ModalFooter, Modal, ModalHeader } from 'reactstrap';
import { Translation } from 'react-i18next';
import { connect } from 'react-redux';
import { mapStateToProps } from '../../../redux/selector/selector';
import { userAction } from '../../../redux/action/user.action';
import SVGCommon from '../../../components/Icon/SVGCommon';
import {
  noticeIcon
} from '../Common_Items_Data';

class AnnouncementSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      annoucementList: [],
      animation: true,
      isOpen: false,
    };
    this.toggle = this.toggle.bind(this);
  }

  componentDidMount() {
    this.isMount = true;
    this.getAnnoucement();
  }

  componentWillUnmount() {
    this.isMount = false;
  }

  toggle() {
    this.setState({ isOpen: !this.state.isOpen });
  }
  dontShow = (e) => {
    let check = e.target.checked;
    if (check === 'true') {
      this.setState({ dontShow: !this.state.dontShow });
    } else {
      this.setState({ dontShow: !this.state.dontShow });
    }
  };

  checkDontShow = () => {
    this.setState({ isOpen: false });
    let check = this.state.dontShow;
    if (check === true) {
      this.props.dispatch(userAction.hideHomepageAnnouncement());
    }
  };

  getAnnoucement() {
    const { countryLanguageKey } = this.props.language;
    const { isLogin } = this.props.user;

    window.SPL_Content.getAnnoucementList(isLogin, countryLanguageKey).then((data) => {
      if (this.isMount) {
        this.setState({ annoucementList: data.announcementsDisplay });
      }
    });
  }

  render() {
    return (
      <Translation>
        {(t) => (
          <div className='container overflow-hidden'>
            <Modal className='modal-md' isOpen={this.state.isOpen} toggle={this.toggle}>
              <ModalHeader className='w-100 d-flex'>
                <Col xs='10' sm='10' className='d-block align-items-center w-100'>
                  <div className='text-nowrap w-100'>{t('global:global.announcement2', 'Annoucement')}</div>
                </Col>

                <Col xs='2' sm='2' className='d-flex justify-content-end align-items-center'>
                  <button className='close' onClick={() => this.setState({ isOpen: false })}>
                    <span className='inner-cancel'>x</span>
                  </button>
                </Col>
              </ModalHeader>
              <ModalBody className='text-left w-100'>
                <div>
                  {this.state.annoucementList.map((annoucement, index) => {
                    return (
                      <div key={index}>
                        <div
                          className='contact-desc'
                          dangerouslySetInnerHTML={{
                            __html:
                              annoucement
                          }}
                        ></div>
                      </div>
                    );
                  })}
                </div>
              </ModalBody>
              <ModalFooter className='w-100 d-flex justify-content-between align-items-center'>
                <Row className='w-100'>
                  <Col xs='12' className='d-flex justify-content-end align-items-center'>
                    <button type='submit' onClick={() => this.checkDontShow()} className='primary-btn button-35px'>
                      <span>{t('global:global.verification.close', 'Close')}</span>
                    </button>
                  </Col>
                </Row>
              </ModalFooter>
            </Modal>

            <div className='container position-relative d-webkit-box'>
              <div className='annoucement-icon'><img src={noticeIcon} /></div>
              <div className='annoucement'>
                {this.state.annoucementList.map((annoucement, index) => {
                  return (
                    <div
                      key={index}
                      className={`annoucement-text pointer ${this.props.location.pathname.includes('myaccount') ? '' : ''}`}
                      onClick={this.toggle}>
                      {annoucement}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        )}
      </Translation>
    );
  }
}
AnnouncementSection.defaultProps = {
  animation: true,
};

export default connect(mapStateToProps)(withRouter(AnnouncementSection));
