export const historytabs = [
    {
        id: 'history.transfer',
        title: 'TRANSFER',
        controls: [
            {
                type: 'content1',
                title: 'Main Wallet',
                datetime: '2018-11-14 10:50:50',
                amount: '999.00',
                status: 'Success',
            },
            {
                type: 'content1',
                title: 'Allbet',
                datetime: '2018-11-13 10:50:50',
                amount: '999.00',
                status: 'Failed',
            },
            {
                type: 'content1',
                title: 'Spadegaming',
                datetime: '2018-11-12 10:50:50',
                amount: '999.00',
                status: 'Success',
            },
        ],
    },
    {
        title: 'WITHDRAWAL/ DEPOSIT',
        controls: [
            {
                type: 'content1',
                title: 'Main Wallet',
                datetime: '2018-11-14 10:50:50',
                amount: '999.00',
                status: 'Success',
            },
            {
                type: 'content1',
                title: 'Allbet',
                datetime: '2018-11-13 10:50:50',
                amount: '999.00',
                status: 'Failed',
            },
            {
                type: 'content1',
                title: 'Spadegaming',
                datetime: '2018-11-12 10:50:50',
                amount: '999.00',
                status: 'Success',
            },
        ],
    },
    {
        title: 'PROMOTION',
        controls: [
            {
                type: 'content1',
                title: 'Main Wallet',
                datetime: '2018-11-14 10:50:50',
                amount: '999.00',
                status: 'Success',
            },
            {
                type: 'content1',
                title: 'Allbet',
                datetime: '2018-11-13 10:50:50',
                amount: '999.00',
                status: 'Failed',
            },
            {
                type: 'content1',
                title: 'Spadegaming',
                datetime: '2018-11-12 10:50:50',
                amount: '999.00',
                status: 'Success',
            },
        ],
    },
];
export const accountMenu = [
    {
        id: '1',
        submenu: 'deposit',
        name: 'deposit',
        text: 'deposit',
        icon: 'deposit',
        path: '/public/html/images/account/deposit.svg',
        pathOn: '/public/html/images/account/deposit-active.svg',
        img: '/public/html/images/account/096-deposit.png',
        route: '/myaccount/deposit',
    },
    {
        id: '2',
        submenu: 'transfer',
        name: 'transfer',
        text: 'transfer',
        icon: 'transferall',
        path: '/public/html/images/account/transfer.svg',
        pathOn: '/public/html/images/account/transfer-active.svg',
        img: '/public/html/images/account/096-transfer.png',
        route: '/myaccount/transfer',
    },

    {
        id: '3',
        submenu: 'withdraw',
        name: 'withdraw',
        text: 'withdraw',
        icon: 'withdraw',
        path: '/public/html/images/account/withdraw.svg',
        pathOn: '/public/html/images/account/withdraw-active.svg',
        img: '/public/html/images/account/096-withdraw.png',
        route: '/myaccount/withdraw',
    },
    {
        id: '4',
        submenu: 'history',
        name: 'history',
        text: 'history',
        icon: 'history',
        path: '/public/html/images/account/history.svg',
        pathOn: '/public/html/images/account/history-active.svg',
        img: '/public/html/images/account/096-history.png',
        route: '/myaccount/history',
    },
];
export const userProfile = [
    {
        id: '1',
        submenu: 'messaging',
        name: 'messaging',
        text: 'messaging',
        icon: 'messaging',
        path: '/public/html/images/account/messaging.svg',
        pathOn: '/public/html/images/account/messaging-active.svg',
        img: '/public/html/images/account/096-message.png',
        route: '/myaccount/messaging',
    },
    {
        id: '2',
        submenu: 'myprofile',
        name: 'myprofile',
        text: 'my profile',
        icon: 'icnprofile',
        path: '',
        pathOn: '',
        img: '/public/html/images/account/096-username.png',
        route: '/myaccount/myprofile',
    },
    {
        id: '3',
        submenu: 'changepassword',
        name: 'changepassword',
        text: 'change password',
        icon: 'changepw',
        path: '',
        pathOn: '',
        img: '/public/html/images/account/096-password.png',
        route: '/myaccount/changepassword',
    },
    {
        id: '4',
        submenu: 'changegamepassword',
        name: 'change game password',
        text: 'change game password',
        icon: 'changepw',
        path: '',
        pathOn: '',
        img: '/public/html/images/account/096-password.png',
        route: '/myaccount/changegamepassword',
    },

    {
        id: '5',
        submenu: 'bankdetails',
        name: 'bankdetails',
        text: 'bank details',
        icon: 'bankdetail',
        path: '',
        pathOn: '',
        img: '/public/html/images/account/096-bank.png',
        route: '/myaccount/bankdetails',
    },
];
export const bankFooter = [
    {
        id: '1',
        name: 'MayBank',
        imgPath: '/public/html/images/bank_img/icon_bank_mbb.png',
    },
    {
        id: '2',
        name: 'Public Bank',
        imgPath: '/public/html/images/bank_img/icon_bank_pbe.png',
    },
    {
        id: '3',
        name: 'CIMB Bank',
        imgPath: '/public/html/images/bank_img/icon_bank_cimb.png',
    },
    {
        id: '4',
        name: 'RHB Bank',
        imgPath: '/public/html/images/bank_img/icon_bank_rgb.png',
    },
    {
        id: '5',
        name: 'HongLeong Bank',
        imgPath: '/public/html/images/bank_img/icon_bank_hlb.png',
    },
];

export const bankList96 = [
    {
        id: '1',
        name: 'Maybank Berhad',
        text: 'Maybank',
        imgOn: '/public/html/images/bank_img/096-mbb.png',
        imgOf: '/public/html/images/bank_img/096-mbb-gray.png',
    },
    {
        id: '2',
        name: 'Public Bank Berhad',
        text: 'Public Bank',
        imgOn: '/public/html/images/bank_img/096-pbe.png',
        imgOf: '/public/html/images/bank_img/096-pbe-gray.png',
    },
    {
        id: '3',
        name: 'CIMB Bank Berhad',
        text: 'CIMB',
        imgOn: '/public/html/images/bank_img/096-cimb.png',
        imgOf: '/public/html/images/bank_img/096-cimb-gray.png',
    },
    {
        id: '4',
        name: 'RHB Banking Group',
        text: 'RHB',
        imgOn: '/public/html/images/bank_img/096-rhb.png',
        imgOf: '/public/html/images/bank_img/096-rhb-gray.png',
    },
    {
        id: '5',
        name: 'Hong Leong Bank Berhad',
        text: 'Hong Leong',
        imgOn: '/public/html/images/bank_img/096-hlb.png',
        imgOf: '/public/html/images/bank_img/096-hlb-gray.png',
    },
];

export const onlineTransferBankList96 = [
    {
        id: '1',
        name: 'Maybank Berhad',
        text: 'Maybank',
        imgOn: '/public/html/images/bank_img/096-mbb.png',
        imgOf: '/public/html/images/bank_img/096-mbb-gray.png',
    },
    {
        id: '2',
        name: 'Public Bank Berhad',
        text: 'Public Bank',
        imgOn: '/public/html/images/bank_img/096-pbe.png',
        imgOf: '/public/html/images/bank_img/096-pbe-gray.png',
    },
    {
        id: '3',
        name: 'CIMB Bank Berhad',
        text: 'CIMB',
        imgOn: '/public/html/images/bank_img/096-cimb.png',
        imgOf: '/public/html/images/bank_img/096-cimb-gray.png',
    },
    {
        id: '4',
        name: 'AmBank Group',
        text: 'AMB',
        imgOn: '/public/html/images/bank_img/096-amb.png',
        imgOf: '/public/html/images/bank_img/096-amb-gray.png',
    },
    {
        id: '5',
        name: 'Hong Leong Bank Berhad',
        text: 'Hong Leong',
        imgOn: '/public/html/images/bank_img/096-hlb.png',
        imgOf: '/public/html/images/bank_img/096-hlb-gray.png',
    },
    {
        id: '6',
        name: 'United Overseas Bank (Malaysia)',
        text: 'United Overseas Bank',
        imgOn: '/public/html/images/bank_img/096-uob.png',
        imgOf: '/public/html/images/bank_img/096-uob-gray.png',
    },
    {
        id: '7',
        name: 'Bank Rakyat',
        text: 'Bank Rakyat',
        imgOn: '/public/html/images/bank_img/096-br.png',
        imgOf: '/public/html/images/bank_img/096-br-gray.png',
    },
    {
        id: '8',
        name: 'OCBC Bank',
        text: 'OCBC Bank',
        imgOn: '/public/html/images/bank_img/096-ocbc.png',
        imgOf: '/public/html/images/bank_img/096-ocbc-gray.png',
    },
    {
        id: '9',
        name: 'HSBC Bank',
        text: 'HSBC Bank',
        imgOn: '/public/html/images/bank_img/096-hsbc.png',
        imgOf: '/public/html/images/bank_img/096-hsbc-gray.png',
    },
    {
        id: '10',
        name: 'Bank Islam Malaysia',
        text: 'Bank Islam Malaysia',
        imgOn: '/public/html/images/bank_img/096-islam.png',
        imgOf: '/public/html/images/bank_img/096-islam-gray.png',
    },
    {
        id: '11',
        name: 'Affin Bank',
        text: 'Affin Bank',
        imgOn: '/public/html/images/bank_img/096-affin.png',
        imgOf: '/public/html/images/bank_img/096-affin-gray.png',
    },
    {
        id: '12',
        name: 'Alliance Bank',
        text: 'Alliance Bank',
        imgOn: '/public/html/images/bank_img/096-alliance.png',
        imgOf: '/public/html/images/bank_img/096-alliance-gray.png',
    },
    {
        id: '13',
        name: 'Bank Simpanan Nasional',
        text: 'Bank Simpanan Nasional',
        imgOn: '/public/html/images/bank_img/096-bsn.png',
        imgOf: '/public/html/images/bank_img/096-bsn-gray.png',
    },
    {
        id: '14',
        name: 'Citi Bank',
        text: 'Citi Bank',
        imgOn: '/public/html/images/bank_img/096-citi.png',
        imgOf: '/public/html/images/bank_img/096-citi-gray.png',
    },
    {
        id: '15',
        name: 'Standard Chartered Bank',
        text: 'Standard Chartered Bank',
        imgOn: '/public/html/images/bank_img/096-scb.png',
        imgOf: '/public/html/images/bank_img/096-scb-gray.png',
    },
    {
        id: '16',
        name: 'RHB Banking Group',
        text: 'RHB Bank',
        imgOn: '/public/html/images/bank_img/096-rhb.png',
        imgOf: '/public/html/images/bank_img/096-rhb-gray.png',
    },
];

export const providerIcon = [
    {
        id: '1',
        provider: 'AG2',
        imgPath: '/public/html/images/icon/ag-icon.png',
        redirect: '/casino/asia-gaming-2',
    },
    {
        id: '2',
        provider: 'MEGA',
        imgPath: '/public/html/images/icon/mega888-icon.png',
        redirect: '/mega888',
    },
    {
        id: '3',
        provider: 'MGP',
        imgPath: '/public/html/images/icon/mirco-icon.png',
        redirect: '/slots/microGamingPlus',
    },
    {
        id: '4',
        provider: 'SG',
        imgPath: '/public/html/images/icon/sg-icon.png',
        redirect: '/slots/spadeGaming',
    },
    {
        id: '5',
        provider: 'SA',
        imgPath: '/public/html/images/icon/sagaming-icon.png',
        redirect: '/casino/sa-gaming',
    },
    {
        id: '6',
        provider: 'SCR2',
        imgPath: '/public/html/images/icon/918kiss-icon.png',
        redirect: '/918kiss',
    },
    {
        id: '7',
        provider: 'PT2',
        imgPath: '/public/html/images/icon/playtech-icon.png',
        redirect: '/slots/playTech',
    },
    {
        id: '8',
        provider: 'EBET',
        imgPath: '/public/html/images/icon/ebet-icon.png',
        redirect: '/casino/ebet',
    },
    {
        id: '9',
        provider: 'TTG',
        imgPath: '/public/html/images/icon/ttg-icon.png',
        redirect: '/slots/toptrendgaming',
    },
    {
        id: '10',
        provider: 'UL',
        imgPath: '/public/html/images/icon/ultimate-icon.png',
        redirect: '/ultimate-slots',
    },
    {
        id: '11',
        provider: 'GP',
        imgPath: '/public/html/images/icon/gameplay-icon.png',
        redirect: '/slots/gameplay',
    },
    {
        id: '12',
        provider: 'AB',
        imgPath: '/public/html/images/icon/allbet-icon.png',
        redirect: '/casino/allbet',
    },
    {
        id: '13',
        provider: 'GD',
        imgPath: '/public/html/images/icon/gd-icon.png',
        redirect: '/casino/gold-deluxe',
    },
    {
        id: '14',
        provider: 'QQK',
        imgPath: '/public/html/images/icon/qqkeno-icon.png',
        redirect: '/qqkeno',
    },
    {
        id: '15',
        provider: 'DG',
        imgPath: '/public/html/images/icon/dream-gaming-icon.png',
        redirect: '/casino/dream-gaming',
    },
    {
        id: '16',
        provider: 'AES',
        imgPath: '/public/html/images/icon/aesexy-icon.png',
        redirect: '/casino/sexy-baccarat',
    },
    {
        id: '16',
        provider: 'AES2',
        imgPath: '/public/html/images/icon/aesexy-icon.png',
        redirect: '/casino/sexy-baccarat',
    },
    {
        id: '17',
        provider: 'CMD',
        imgPath: '/public/html/images/icon/cmd-icon.png',
        redirect: '/cmd368',
    },
    {
        id: '18',
        provider: 'IBC',
        imgPath: '/public/html/images/icon/maxbet-icon.png',
        redirect: '/',
    },
    {
        id: '19',
        provider: 'BR',
        imgPath: '/public/html/images/icon/betradar-icon.png',
        redirect: '/virtualsports-landing',
    },
    {
        id: '20',
        provider: 'IDN',
        imgPath: '/public/html/images/icon/idnpoker-icon.png',
        redirect: '/poker',
    },
    {
        id: '21',
        provider: 'GG',
        imgPath: '/public/html/images/icon/gg-gaming-icon.png',
        redirect: '/',
    },
    {
        id: '22',
        provider: 'SBO',
        imgPath: '/public/html/images/icon/sbobet-icon.png',
        redirect: '/sbo',
    },
    {
        id: '23',
        provider: 'EVO',
        imgPath: '/public/html/images/icon/evolution-gaming-icon.png',
        redirect: '/casino/evolution-gaming',
    },
    {
        id: '24',
        provider: 'PG',
        imgPath: '/public/html/images/icon/play-n-go-icon.png',
        redirect: '/slots/playNGo',
    },
    {
        id: '25',
        provider: 'PP',
        imgPath: '/public/html/images/icon/pragmatic-icon.png',
        redirect: '/slots/pragmaticPlay',
    },
    {
        id: '26',
        provider: 'UFA',
        imgPath: '/public/html/images/icon/ufa-icon.png',
        redirect: '/ufa',
    },
    {
        id: '27',
        provider: 'NS',
        imgPath: '/public/html/images/icon/nextspin-black-icon.png',
        redirect: '/',
    },
    {
        id: '28',
        provider: 'IME',
        imgPath: '/public/html/images/icon/im-icon.png',
        redirect: '/',
    },
];

export const userstatusImg = ['/public/html/images/account/sapphire.png'];
export const imgRestore = ['/public/html/images/account/restore.svg'];
export const coinsImg = ['/public/html/images/account/coins.svg'];
export const imgCoinsTH9 = ['/public/html/images/account/bk-coin-gold.png'];

//Bank Img
export const bankAppIcon = ['/public/html/images/account/096-bankapp.svg'];
export const bankIcon = ['/public/html/images/account/096-bank.png'];
export const mayBank = ['/public/html/images/bank_img/096-mbb.png'];
export const mayBankOf = ['/public/html/images/bank_img/096-mbb-gray.png'];
export const cimbBank = ['/public/html/images/bank_img/096-cimb.png'];
export const cimbBankOf = ['/public/html/images/bank_img/096-cimb-gray.png'];
export const h2pay = ['/public/html/images/bank_img/help2pay2.png'];
export const ezzpay = ['/public/html/images/bank_img/eeziepay.png'];
export const fpy = ['/public/html/images/bank_img/fastpay.png'];
export const ip4u = ['/public/html/images/bank_img/IP4U.svg'];
export const truepay = ['/public/html/images/bank_img/truepay.svg'];
export const dgpay = ['/public/html/images/bank_img/dgpay.svg'];
export const itpy = ['/public/html/images/bank_img/itpy.png'];
export const surepay = ['/public/html/images/bank_img/surepay.svg'];
export const upy = ['/public/html/images/bank_img/upay.svg'];
export const all = ['/public/html/images/bank_img/AllPay.svg'];
export const cryptoIcon = ['/public/html/images/account/crypto.png'];
export const yespay = ['/public/html/images/account/yespay.svg'];
export const qrpay = ['/public/html/images/account/qrpay.svg'];
export const deleteIcon = ['/public/html/images/account/delete-icon.png'];
export const flagIcon = ['/public/html/images/account/flag-icon.png'];
export const heng = ['/public/html/images/bank_img/heng.svg'];

export const depositTabs = [
    {
        key: 'quickPay',
        code: 'QP',
        selected: false,
        icon: '/public/html/images/account/deposit-quickpay.svg',
        pathname: '/myaccount/deposit/quick-pay',
    },
    {
        key: 'onlinetransfer',
        code: 'B',
        selected: false,
        icon: bankIcon,
        pathname: '/myaccount/deposit',
    },
    {
        key: 'qrpay',
        code: 'QR',
        selected: false,
        icon: qrpay,
        pathname: '/myaccount/deposit/qrcode-pay',
    },
    {
        key: 'reload',
        code: 'R',
        selected: false,
        icon: '/public/html/images/account/deposit-reload.svg',
        pathname: '/myaccount/deposit/reload',
    },
    {
        key: 'expressDeposit',
        code: 'E',
        selected: false,
        icon: '/public/html/images/account/deposit-express.svg',
        pathname: '/myaccount/deposit/express-deposit',
    },
    {
        key: 'bankApps',
        code: 'BA',
        selected: false,
        icon: bankAppIcon,
        pathname: '/myaccount/deposit/bankApps',
    },
    {
        key: 'Line',
        code: 'LINE',
        selected: false,
        icon: '/public/html/images/account/deposit-line.svg',
        pathname: '/myaccount/deposit/line-deposit',
    },
    {
        key: 'crypto',
        code: 'CT',
        selected: false,
        icon: cryptoIcon,
        pathname: '/myaccount/deposit/crypto',
    },
    {
        key: 'p2p',
        code: 'P2P',
        selected: false,
        icon: '/public/html/images/account/deposit-p2p.svg',
        pathname: '/myaccount/deposit/p2p-transfer',
    },
    {
        key: 'bqr',
        code: 'BQR',
        selected: false,
        icon: '/public/html/images/account/deposit-bqr.svg',
        pathname: '/myaccount/deposit/bank-qr',
    },
    {
        key: 'ewalletapps',
        code: 'EA',
        selected: false,
        icon: '/public/html/images/account/deposit-ewalletapps.svg',
        pathname: '',
    },
];

export const userVerificationData = {
    birthday: {
        key: 'birthday',
        i18n: 'settings:settings.memberVerificationForm.dob',
        svg: 'icon-verify-birthday',
        splKey: 'birthdayVerified',
    },
    email: {
        key: 'email',
        i18n: 'settings:settings.memberVerificationForm.email',
        svg: 'icon-verify-email',
        splKey: 'emailVerified',
        togglePopup: true,
    },
    phone: {
        key: 'phone',
        i18n: 'settings:settings.memberVerificationForm.mobile',
        svg: 'icon-verify-phone',
        splKey: 'phoneVerified',
        togglePopup: true,
    },
    withdrawal: {
        key: 'withdrawal',
        i18n: 'settings:settings.memberVerificationForm.bankingDetails',
        svg: 'icon-verify-withdrawal',
        splKey: 'withdrawalVerified',
    },
};
