import React from 'react';
import { Trans, Translation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { mapStateToProps } from 'root/redux/selector/selector';
import { Redirect, withRouter } from 'react-router-dom';
import { userAction } from 'root/redux/action';
import PropTypes from 'prop-types';
import SVGElements from 'root/components/SVGElements';
import TurnstileWidget from 'root/components/TurnstileWidget/Turnstile';
import { loadAuthSettings } from '../../utils/turnstile-util';
import commonUtil from '../../utils/common-util';
import { gu_getSettingFromPortalSettings } from '../../utils/general-util';
import { portalAction } from '../../redux/action/portal.action';

class Login extends React.Component {
    isMount = true;
    constructor(props) {
        super(props);
        this.state = {
            userName: '',
            pass: '',
            redirectToReferrer: false,
            liveChatUrl: '',
            bioMode: '',
            isLiteAppLoginData: false,
            isLoading: false,
            isTurnstilelFailed: false,
            captchaToken: null,
            alwaysShowTurnstile: false,
        };
    }

    componentDidMount() {
        const { language } = this.props;

        this.isMount = true;
        loadAuthSettings(true, 'LOGIN', this, language.currencyLang, 'v2');
        this.initCompSettings();
        const { userName, password } = this.props.user;
        this.setState({ userName: userName, pass: password });
        this.contactGet();
        this.intervalId = setInterval(this.contactGet, 2000);

        this.initCheckLiteAppLoginInfo();
    }

    componentWillUnmount() {
        this.isMount = false;
        clearInterval(this.intervalId);
    }

    componentDidUpdate(prevProps) {
        if (this.props.location.pathname !== prevProps.location.pathname) {
            this.props.dispatch(userAction.clearResetPassword());
        }
    }

    initCompSettings() {
        return new Promise((resolve) => {
            // Step 1: Standard way to get from portal settings
            let tempSettings = gu_getSettingFromPortalSettings({
                vm: this,
                settingName: 'loginConfiguration',
                notViewType: true,
                notFeatures: true,
            });

            // Step 2: Massage again to get the specific data you need (Skip if not needed)
            let compSettings = { isAllowTurnstileForLiteApp: tempSettings.isAllowTurnstileForLiteApp };

            this.setState({ compSettings }, () => {
                resolve();
            });
        });
    }

    initCheckLiteAppLoginInfo() {
        if (!window.isAgent && !window.isAffiliate) {
            if (window.SPL_LiteApp.isZT828LiteApp()) {
                const isLiteAppLoginData = window.SPL_LiteApp.isLoginDataExist() ? true : false;

                let bioMode = '';
                if (window.SPL_LiteApp.isTouchBioAuthUnlocked()) {
                    bioMode = 'TouchID';
                } else if (window.SPL_LiteApp.isFaceBioAuthUnlocked()) {
                    bioMode = 'FaceID';
                }
                this.setState({
                    isLiteAppLoginData,
                    bioMode,
                });
            }
        }
    }

    handleLogin = (e) => {
        e.preventDefault();

        if (this.state.isLoading || this.state.isTurnstilelFailed) return;

        const { language, portal } = this.props;
        let loginObj = {
            domain: '',
            isAffiliate: false,
            isVipLogin: false,
            language: language.key,
            merchantCode: window.merchantCode,
            login: this.state.userName,
            password: this.state.pass,
            rememberMe: false,
            showPassword: false,
            ...(this.state.captchaToken && { captchaToken: this.state.captchaToken }),
        };

        const isAllowTurnstileForLiteApp = portal?.settings?.isAllowTurnstileForLiteApp;

        localStorage.setItem('loginPanel', true);

        const validateUser = () => {
            window.SPL_Member.validateIpDevice(this.state.userName)
                .then((data) => {
                    if (data.error !== '') {
                        this.props.history.push('/redirect-error?' + data.error);
                    } else if (data.error === '') {
                        this.props
                            .dispatch(userAction.login(loginObj, isAllowTurnstileForLiteApp))
                            .then(() => {
                                this.props.dispatch(userAction.isAuthenticated()).then(() => {
                                    const { user } = this.props;
                                    // after user login, get the countryLanguageKey by user country,currency
                                    window.SPL_Content.getCountryLanguageKeyByCountryOrCurrency(user.account.country, user.account.currency).then((countryLanguageKey) => {
                                        if (!window.isAgent && !window.isAffiliate) {
                                            if (window.SPL_LiteApp.isZT828LiteApp()) {
                                                const { login, password, rememberMe } = loginObj;
                                                const { user } = this.props;
                                                const currencyData = user && user.account && user.account.currency;
                                                const bioEnabled = window.SPL_LiteApp.isTouchBioAuthUnlocked() || window.SPL_LiteApp.isFaceBioAuthUnlocked() ? '1' : '0';
                                                window.SPL_LiteApp.onLoginSuccess(login, password, rememberMe, bioEnabled, currencyData);
                                            }
                                        }

                                        if (this.props.screen.isMobile) {
                                            if (this.props.location.state && this.props.location.state.currentDomainPath) {
                                                this.props.history.push({ pathname: this.props.location.state.currentDomainPath, state: { currentDomainPath: false } });
                                            } else {
                                                window.location.href = `/${countryLanguageKey}/home`;
                                                // window.location.reload(true);
                                            }
                                        } else {
                                            window.location.href = `/${countryLanguageKey}/myaccount/deposit`;
                                        }

                                        localStorage.setItem('loginPanel', '');
                                        window.SPL_Member.getUnreadMessageCount().then((data) => {
                                            this.props.dispatch(userAction.getUnreadMsg(data.unreadCount));
                                        });
                                    });
                                });
                                window.SPL_FingerPrint.checkingFp();
                            })
                            .catch(() => {
                                this.setState({ isLoading: false });
                                const { processedData } = this.props.authSettingsReducer;

                                const turnstile = processedData?.LOGIN?.turnstileEnabled;

                                if (turnstile) {
                                    this.props.dispatch(portalAction.clearSiteKey());
                                    this.props.dispatch(portalAction.resetSiteKey());
                                }
                            });
                        setTimeout(() => {
                            this.props.dispatch(userAction.clearResetPassword());
                        }, 3000);
                    }
                })
                .then(() => this.setState({ isLoading: false }))
                .catch(() => {
                    this.setState({ isLoading: false });
                });
        };

        this.setState({ isLoading: true }, () => validateUser());
    };

    navigate(path) {
        this.props.history.push(path);
    }

    toggleShowPassword = () => {
        this.setState({ showPassword: !this.state.showPassword });
    };

    onBiometricClicked = () => {
        if (!window.isAgent && !window.isAffiliate) {
            if (window.SPL_LiteApp.isZT828LiteApp()) {
                window.SPL_LiteApp.onBiometricAuthenticationTapped(this.state.bioMode);
            }
        }
    };

    contactGet = () => {
        const { language, screen } = this.props;
        window.SPL_Content.getContact(language.countryLanguageKey, screen.viewType).then((data) => {
            if (data) {
                for (let i = 0; i < data.length; i++) {
                    const contact = data[i];
                    if (contact.name === 'LiveChat') {
                        this.setState({ liveChatUrl: contact.desc });
                    }
                }
            }
        });
    };

    contactOnClick = (url) => {
        window.open(url, '', 'width=500,height=700,toolbar=no,location=no,directories=no,status=yes,menubar=no,scrollbars=no');
    };

    render() {
        const { from } = this.props.location.state || {
            from: { pathname: '/' },
        };

        // If user was authenticated, redirect her to where she came from.
        if (this.state.redirectToReferrer === true) {
            return <Redirect to={from} />;
        }

        const { tag, screen, authSettingsReducer } = this.props;

        const { setForgotTag, setRegisterTag } = this.props.accountFunction;
        const { bioMode } = this.state;

        const isMobileLiteApp = commonUtil.getIsZT828LiteApp() && screen.isMobile;
        const isLiteApp = commonUtil.getIsZT828LiteApp();

        return (
            <Translation>
                {(t) =>
                    tag === 'login' && (
                        <>
                            {commonUtil.getIsZT828LiteApp() && (
                                <div className='form-header'>
                                    <h3>{t('login:login.bannerTitle')}</h3>
                                    <p>{t('login:login.bannerDesc')}</p>
                                </div>
                            )}
                            <form className={`login ${isMobileLiteApp ? 'login-panel-liteapp' : isLiteApp ? 'login-liteapp' : ''}`}>
                                {!commonUtil.getIsZT828LiteApp() && (
                                    <div className='form-header'>
                                        <h3>{t('login:login.bannerTitle')}</h3>
                                        <p>{t('login:login.bannerDesc')}</p>
                                    </div>
                                )}
                                <ul>
                                    <li className='username'>
                                        <label>
                                            {t('global:global.form.username', 'User name')} <span className='text-red'>*</span>
                                        </label>
                                        <div className='input-box'>
                                            <div className='icon-box'>
                                                <SVGElements className='icon-username' name='profile-icon' />
                                                {/* <i className="icon-username"></i> */}
                                            </div>
                                            <input
                                                type='text'
                                                value={this.state.userName}
                                                onChange={(e) => {
                                                    this.setState({ userName: e.target.value });
                                                }}
                                                onKeyPress={(e) => {
                                                    if (e.key === 'Enter') this.handleLogin(e);
                                                }}
                                            />
                                        </div>
                                    </li>

                                    <span className='enter-username text-red'>
                                        {/* {t('global:global.form.username-placeholderv2', 'Please enter your desired username')} */}
                                    </span>

                                    <li>
                                        <label>
                                            {t('global:global.form.newpassword-placeholder', 'Password')} <span className='text-red'>*</span>
                                        </label>
                                        <div className='input-box'>
                                            <div className='icon-box'>
                                                <SVGElements className='icon-lock' name='password-icon' />
                                                {/* <i className="icon-lock"></i> */}
                                            </div>
                                            <input
                                                value={this.state.pass}
                                                type={!this.state.showPassword ? 'password' : 'text'}
                                                onChange={(e) => {
                                                    this.setState({ pass: e.target.value });
                                                }}
                                                onKeyPress={(e) => {
                                                    if (e.key === 'Enter') this.handleLogin(e);
                                                }}
                                            />

                                            {this.state.isLiteAppLoginData && bioMode ? (
                                                <div className='bio-container'>
                                                    {<SVGElements name={bioMode === 'TouchID' ? 'bioFingerprint' : 'bioFace'} onClick={this.onBiometricClicked} />}
                                                </div>
                                            ) : (
                                                <span className={`btn ${this.state.showPassword && 'btn-show'}`} onClick={this.toggleShowPassword}>
                                                    {t('global:global.form.show', 'Show')}
                                                </span>
                                            )}
                                        </div>
                                    </li>
                                </ul>

                                {/* Cloudfare Capcha Turnstile */}
                                <div ref={this.elementRef} className='turnstile-widget-login'>
                                    <TurnstileWidget
                                        moduleType={'LOGIN'}
                                        theme={'light'}
                                        onVerifyCallback={(token) => {
                                            this.setState({
                                                captchaToken: token,
                                                isTurnstilelFailed: false,
                                            });
                                        }}
                                        onErrorCallback={(token) => {
                                            this.setState({
                                                captchaToken: token,
                                                isTurnstilelFailed: true,
                                            });
                                        }}
                                    />
                                </div>

                                <button className='btn-normal login-button' onClick={this.handleLogin}>
                                    {t('global:global.menu.account.login', 'Login')}
                                </button>

                                {this.props.user.showResetPassword && (
                                    <div className='login-failed'>
                                        <div className='f-modal-alert'>
                                            <div className='f-modal-icon f-modal-warning scaleWarning'>
                                                <span className='f-modal-body pulseWarningIns'></span>
                                                <span className='f-modal-dot pulseWarningIns'></span>
                                            </div>
                                        </div>
                                        {window.SPL_Member.isValidFailLoginCode(this.props.user.failLoginCode) ? (
                                            <p className='f-alert-text'>
                                                <Trans i18nKey={`global:error.loginError.${this.props.user.failLoginCode}`}>
                                                    Login Error! Please Try Again. Contact{' '}
                                                    <span onClick={() => this.contactOnClick(this.state.liveChatUrl)}>customer service</span> if error persist
                                                </Trans>
                                            </p>
                                        ) : (
                                            <p className='f-alert-text'>
                                                <Trans i18nKey={'global:error.authentication'}></Trans>
                                            </p>
                                        )}
                                        {this.state.isTurnstilelFailed && (
                                            <p className='f-alert-text'>
                                                {/* {t('register:register.messages.error.turnstileError')} */}
                                                <Trans i18nKey={'register:register.messages.error.turnstileError'}></Trans>
                                            </p>
                                        )}
                                    </div>
                                )}
                                <p className='link text-gray' onClick={setForgotTag}>
                                    {t('global:global.menu.account.forgot')} {t('global:global.menu.account.usernameSmall')} {t('global:global.or')}{' '}
                                    {t('global:global.menu.account.passwordSmall')} ?
                                </p>
                                <p className='link text-green' onClick={setRegisterTag}>
                                    {t('register:register.createAccount')}
                                </p>
                            </form>
                        </>
                    )
                }
            </Translation>
        );
    }
}

Login.propTypes = {
    accountFunction: PropTypes.object.isRequired,
};

export default connect(mapStateToProps)(withTranslation(['reset'])(withRouter(Login)));
