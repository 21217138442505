import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Translation } from 'react-i18next';
import { Col, ModalBody, Row, ModalHeader } from 'reactstrap';
import { MdClose } from 'react-icons/md';
import SVGElements from 'root/components/SVGElements';
class MessagingDetailModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
        };
    }

    render() {
        const { isOpen, messaging, closeModal } = this.props;
        let openClass = isOpen ? `open` : ``;
        if (isOpen) {
            return (
                <Translation>
                    {(t) => (
                        <div className='messaging-popup '>
                            <div className='popup'>
                                <div className='popup-header'>
                                    <div className='popup-header-left'>
                                        <div className='popup-title'>{messaging.title}</div>
                                        {/* <div className='popup-date'>{moment(messaging.createdDate).format('DD-MM-YYYY - HH:mm').toString()}</div> */}
                                    </div>
                                    <div className='popup-header-right'>
                                        <SVGElements name='close-icon' onClick={() => closeModal()} />
                                        {/* <MdClose onClick={() => closeModal()} /> */}
                                    </div>
                                </div>
                                <div className='popup-body'>
                                    <div dangerouslySetInnerHTML={{ __html: messaging?.message }}></div>
                                </div>
                            </div>
                            <div className='popup-overlay' onClick={() => closeModal()}>
                                {/* <div className='modal-container'>
                  <div className='modal-md'>
                    <ModalHeader className='w-100 d-flex'>
                      <Col xs='10' sm='10' className='d-block '>
                        <div className='h4 messaging-title'>{messaging.title}</div>
                        <div className='messaging-text lh-1 h6'>{moment(messaging.createdDate).format('DD-MM-YYYY - HH:mm').toString()}</div>
                      </Col>
                      <Col xs='2' sm='2' className='d-flex justify-content-end align-items-center  '>
                        <button type='submit' onClick={() => closeModal()} className='close'>
                          <span className='inner-cancel'>x</span>
                        </button>
                      </Col>
                    </ModalHeader>

                    <ModalBody className='text-left w-100'>
                      <h5 dangerouslySetInnerHTML={{ __html: messaging.message }}></h5>
                    </ModalBody>
                  </div>
                </div> */}
                            </div>
                        </div>
                    )}
                </Translation>
            );
        } else {
            return <div></div>;
        }
    }
}

export default MessagingDetailModal;

MessagingDetailModal.defaultProps = {
    buttonText: 'Click Me!',
};

MessagingDetailModal.propTypes = {
    buttonText: PropTypes.string,
    promotionObj: PropTypes.object,
};
