import React from 'react';
import { Fragment } from 'react';

const getViewBox = name => {
    switch (name) {
        // Background
        case 'contactbg':
            return '0 0 40 40';

        // Contact Us
        case 'livechat':
            return '0 0 38 36.39';
        case 'whatsapp':
            return '0 0 37.84 37.85';
        case 'wechat':
            return '0 0 38.2 33.4';
        case 'phone':
            return '0 0 37.5 37.6';
        case 'telegram':
            return '0 0 24 24';
        case 'email':
            return '0 0 37 27.3';
        case 'line':
            return '0 0 38.8 37.2';
        case 'skype':
            return '0 0 38.1 38.2';
        case 'zalo':
            return '0 0 40 38';
        case 'yahoo':
            return '0 0 30 30';
        case 'bbm':
            return '0 0 40 40';
        case 'youtube':
        case 'youtube-on':
        case 'twitter':
        case 'twitter-on':
        case 'followusline-on':
        case 'followusline':
        case 'facebook':
        case 'facebook-on':
            return '0 0 80 80';
        // NOTE: (SVG2JSX) DO NOT DELETE, ADD VIEWBOX.
        default:
            return '0 0 20 20';
    }
};

const getPath = (name, props) => {
    switch (name) {
        // Background
        case 'contactbg':
            return (
                <path
                    {...props}
                    d='M36.48 4.69h-1.17V3.52A3.52 3.52 0 0 0 31.8 0H7a3.52 3.52 0 0 0-3.48 3.52v1.17a3.52 3.52 0 1 0 0 7v4.84a3.52 3.52 0 1 0 0 7v4.69a3.52 3.52 0 1 0 0 7v1.17A3.52 3.52 0 0 0 7 40h24.8a3.52 3.52 0 0 0 3.51-3.52v-1.17h1.17A3.52 3.52 0 0 0 40 31.8V8.2a3.52 3.52 0 0 0-3.52-3.51zM2.34 8.2A1.18 1.18 0 0 1 3.52 7H8.2a1.18 1.18 0 1 1 0 2.35H3.52A1.18 1.18 0 0 1 2.34 8.2zm0 11.88a1.17 1.17 0 0 1 1.18-1.17H8.2a1.17 1.17 0 1 1 0 2.34H3.52a1.18 1.18 0 0 1-1.18-1.17zM3.52 33a1.17 1.17 0 1 1 0-2.34H8.2a1.17 1.17 0 1 1 0 2.34zM33 36.48a1.18 1.18 0 0 1-1.17 1.18H7a1.17 1.17 0 0 1-1.17-1.18v-1.17H8.2a3.52 3.52 0 1 0 0-7H5.86v-4.72H8.2a3.52 3.52 0 1 0 0-7H5.86v-4.87H8.2a3.52 3.52 0 1 0 0-7H5.86v-1.2A1.17 1.17 0 0 1 7 2.34h24.8A1.18 1.18 0 0 1 33 3.52zm4.69-4.68a1.18 1.18 0 0 1-1.21 1.2h-1.17v-4.72h2.35zm0-5.86h-2.38v-4.69h2.35zm0-7h-2.38v-4.88h2.35zm0-7.19h-2.38V7h1.17a1.18 1.18 0 0 1 1.18 1.2zm-12.92 4.66a3.52 3.52 0 0 0 3.51-3.52v-2.34A3.52 3.52 0 0 0 24.77 7a8.21 8.21 0 0 0-8.21 8.2v9.54A8.21 8.21 0 0 0 24.77 33a3.52 3.52 0 0 0 3.51-3.52v-2.37a3.52 3.52 0 0 0-3.51-3.52 1.17 1.17 0 0 1-1.18-1.17v-4.84a1.17 1.17 0 0 1 1.18-1.17zm0 9.53a1.17 1.17 0 0 1 1.17 1.17v2.34a1.18 1.18 0 0 1-1.17 1.18 5.86 5.86 0 0 1-5.86-5.86v-9.54a5.85 5.85 0 0 1 5.86-5.85 1.17 1.17 0 0 1 1.17 1.17v2.34a1.17 1.17 0 0 1-1.17 1.17 3.53 3.53 0 0 0-3.52 3.52v4.84a3.53 3.53 0 0 0 3.52 3.52z'
                />
            );

        // Contact Us
        case 'livechat':
            return (
                <path
                    {...props}
                    d='M35.86 34.1c-.51-.06-1.71-1.38-3-4.75A16.53 16.53 0 0038 17.44C38 7.82 29.48 0 19 0S0 7.82 0 17.44s8.52 17.44 19 17.44a20.54 20.54 0 006.71-1.12 12.27 12.27 0 007.81 2.63 12.11 12.11 0 002.48-.23 1 1 0 00.81-1.07 1.07 1.07 0 00-.95-.99zM10.27 21a3.11 3.11 0 113.11-3.11A3.11 3.11 0 0110.27 21zm9.18 0a3.11 3.11 0 113.11-3.11A3.11 3.11 0 0119.45 21zm9.19 0a3.11 3.11 0 113.11-3.11A3.11 3.11 0 0128.64 21z'
                />
            );
        case 'whatsapp':
            return (
                <path
                    {...props}
                    d='M18.92 0A18.91 18.91 0 003.6 30l-2.36 7 7.27-2.32A18.92 18.92 0 1018.92 0zm11 26.72c-.46 1.29-2.27 2.36-3.71 2.67-1 .21-2.28.38-6.63-1.42-5.58-2.3-9.13-7.97-9.41-8.32s-2.25-3-2.25-5.71a6 6 0 011.93-4.6 2.73 2.73 0 011.93-.68h.63c.56 0 .83.06 1.2.93.46 1.1 1.57 3.82 1.7 4.09a1.13 1.13 0 01.08 1 3.26 3.26 0 01-.61.87c-.28.32-.54.57-.82.91a.89.89 0 00-.22 1.18 16.8 16.8 0 003.07 3.82 13.94 13.94 0 004.44 2.74 1.19 1.19 0 001.33-.2 22.94 22.94 0 001.48-2 1.06 1.06 0 011.36-.41c.51.18 3.21 1.51 3.77 1.79s.92.41 1.06.65a4.57 4.57 0 01-.32 2.69z'
                />
            );
        case 'wechat':
            return (
                <path
                    {...props}
                    d='M14.3 0C6.4 0 0 5.4 0 11.9c.1 3.8 2.2 7.4 5.5 9.3L3.6 25l5.1-2.2c1.1.4 2.3.7 3.5.9-.2-.7-.3-1.5-.3-2.2 0-6.6 6.4-11.9 14.3-11.9.7 0 1.4.1 2.1.1-1.2-5.5-7-9.7-14-9.7zM9.6 9c-1 0-1.8-.8-1.8-1.8s.8-1.8 1.8-1.8 1.8.8 1.8 1.8S10.6 9 9.6 9zm9.5 0c-1 0-1.8-.8-1.8-1.8s.8-1.8 1.8-1.8 1.8.8 1.8 1.8S20.1 9 19.1 9zm19.1 12.5c0-5.3-5.3-9.5-11.9-9.5s-11.9 4.3-11.9 9.5S19.7 31 26.3 31c1 0 2.1-.2 3.1-.4l6.4 2.8-2.2-4.4c2.7-1.8 4.6-4.5 4.6-7.5zm-15.5-.6c-1 0-1.8-.8-1.8-1.8s.8-1.8 1.8-1.8 1.8.8 1.8 1.8-.8 1.8-1.8 1.8zm7.1 0c-1 0-1.8-.8-1.8-1.8s.8-1.8 1.8-1.8 1.8.8 1.8 1.8-.8 1.8-1.8 1.8z'
                />
            );
        case 'phone':
            return (
                <path
                    {...props}
                    d='M18.4 1.1c.1-1.5 1.5-1 2.2-1 9 .3 16.7 8.4 16.6 17.1 0 .9.3 2.1-1 2.1s-.9-1.3-1-2.1C34 7.8 29.7 3.5 20.3 2c-.7-.1-1.9.1-1.9-.9zm13.4 16.4c-1.4.2-1.1-1-1.3-1.9-1-5.6-3-7.7-8.8-8.9-.9-.2-2.2-.1-2-1.3s1.4-.8 2.3-.7c5.8.7 10.5 5.6 10.5 11 0 .5.4 1.6-.7 1.8zm-3.5-3c0 .8-.1 1.5-1 1.6-.6.1-1-.4-1.1-1-.1-2.2-1.7-3.9-3.9-4.1-.7-.1-1.4-.3-1-1.3.2-.6.8-.7 1.4-.7 2.6-.1 5.6 2.9 5.6 5.5zm9 17.6c-1 2.7-4.4 5.5-7.3 5.5-.7-.1-1.3-.3-2-.5C15.3 31.7 5.9 22.8.7 10.1-1.1 5.8.8 2.2 5.2.7 6 .4 6.8.4 7.6.7c1.9.7 6.7 7.2 6.8 9.1.1 1.5-1 2.3-2 3-2 1.3-2 2.9-1.1 4.8 1.9 4 5.1 7.1 9.2 8.8 1.5.7 3 .6 4-.9 1.8-2.7 4.1-2.6 6.5-.9 1.2.9 2.5 1.7 3.6 2.6 1.7 1.3 3.7 2.3 2.7 4.9z'
                />
            );
        case 'telegram':
            return (
                <path
                    {...props}
                    d='M9.417 15.181l-.397 5.584c.568 0 .814-.244 1.109-.537l2.663-2.545 5.518 4.041c1.012.564 1.725.267 1.998-.931L23.93 3.821l.001-.001c.321-1.496-.541-2.081-1.527-1.714l-21.29 8.151c-1.453.564-1.431 1.374-.247 1.741l5.443 1.693L18.953 5.78c.595-.394 1.136-.176.691.218z'
                />
            );
        case 'email':
            return (
                <path
                    {...props}
                    d='M32.4 27.3H4.6C2 27.3 0 25.6 0 23.4V3.9c0-1.1.5-2.1 1.4-2.8C2.3.4 3.4 0 4.6 0h27.7C34.9 0 37 1.7 37 3.9v19.5c0 1.1-.5 2.1-1.4 2.8-.9.7-2 1.1-3.2 1.1zm0-3.9V6.8L21 16.7c-1.4 1.1-3.5 1.1-4.9 0L4.6 6.8v16.6h27.8zM29.2 3.9H7.8l10.7 9.3 10.7-9.3'
                />
            );
        case 'line':
            return (
                <path
                    {...props}
                    d='M19.4 0C8.7 0 0 7.1 0 15.8c0 7.8 6.9 14.4 16.2 15.6.6.1 1.5.4 1.7 1 .1.6.2 1.1.1 1.7l-.3 1.7c-.1.5-.4 1.9 1.7 1s11.2-6.6 15.2-11.3c2.6-2.6 4.1-6.1 4.1-9.7C38.8 7.1 30.1 0 19.4 0zm-7.6 21H7.9c-.6 0-1-.4-1-1v-7.8c0-.6.4-1 1-1s1 .4 1 1V19h2.9c.6 0 1 .5 1 1s-.4 1-1 1zm4-1c0 .6-.4 1-1 1s-1-.4-1-1v-7.8c0-.6.4-1 1-1s1 .4 1 1V20zm9.3 0c0 .5-.3.9-.7 1H24c-.3 0-.6-.1-.8-.4l-4-5.4V20c0 .6-.4 1-1 1s-1-.4-1-1v-7.8c0-.5.3-.9.7-1h.3c.3 0 .6.2.8.4l4 5.4v-4.8c0-.6.4-1 1-1s1 .4 1 1l.1 7.8zm6.2-4.9c.6 0 1 .4 1 1s-.4 1-1 1h-2.9V19h2.8c.6 0 1 .4 1 1s-.4 1-1 1h-3.9c-.6 0-1-.4-1-1v-7.8c0-.6.4-1 1-1h3.9c.6 0 1 .4 1 1s-.4 1-1 1h-2.8V15l2.9.1z'
                />
            );
        case 'skype':
            return (
                <path
                    {...props}
                    d='M21.2 17.7l-2.6-.6c-1-.2-2.1-.5-2.1-1.5s.8-1.6 2.3-1.6c2.9 0 2.7 2 4.1 2 .7.1 1.4-.4 1.4-1.2v-.1c0-1.8-2.9-3.2-5.3-3.2-2.7 0-5.5 1.1-5.5 4.1 0 1.5.5 3 3.4 3.7l3.5.9c1.1.3 1.3.9 1.3 1.4 0 .9-.9 1.8-2.5 1.8-3.2 0-2.7-2.5-4.4-2.5-.7 0-1.3.5-1.3 1.2v.1c0 1.5 1.8 3.4 5.8 3.4 3.8 0 5.7-1.8 5.7-4.3-.2-1.3-.9-2.9-3.8-3.6zM19.1 0C8.5 0 0 8.5 0 19.1s8.5 19.1 19 19.1 19.1-8.5 19.1-19.1C38.1 8.5 29.6 0 19.1 0zM29 29c-1.2 1.2-2.9 1.9-4.7 1.9-1.2 0-2.3-.3-3.3-.9-.7.1-1.3.2-2 .2-1.5 0-3-.3-4.4-.9-4.1-1.8-6.8-5.8-6.8-10.3 0-.6 0-1.3.1-1.9-1.8-3.2-.7-7.2 2.5-9 2-1.1 4.4-1.2 6.4-.1.7-.1 1.4-.2 2.1-.2 1.5 0 3 .3 4.4.9 4.2 1.7 6.9 5.8 6.9 10.3 0 .7-.1 1.3-.2 2 .6 1 .8 2.1.8 3.2.2 1.8-.5 3.5-1.8 4.8z'
                />
            );
        case 'zalo':
            return (
                <path
                    {...props}
                    d='M13.5 18.5h3.8c.7 0 1.1.3 1.1.8.1.5-.3 1.1-.8 1.2H12c-.7.1-1.3-.3-1.4-1-.1-.4.1-.8.4-1.1 1.3-1.7 2.7-3.4 4-5.1.1-.1.1-.2.2-.3-.1-.1-.2-.1-.3-.1H12c-.2 0-.4 0-.6-.1-.5-.1-.8-.6-.7-1.1.1-.4.3-.6.7-.7h5.3c.3 0 .5 0 .8.1.5.2.8.7.6 1.3v.1c-.2.5-.5.9-.9 1.4l-3.4 4.3c-.1 0-.2.2-.3.3zm12.2-4.2c0-.6-.5-1.1-1.1-1h-.2c-.3.1-.6.3-.8.6-.9-.9-2.4-1-3.5-.3-1.3.8-2.1 2.3-1.8 3.8 0 1.5 1 2.7 2.4 3.2.9.3 1.9.2 2.6-.4.2-.2.3-.1.4 0 .3.4.8.5 1.3.3.4-.1.7-.5.7-1v-5.2zm-2.6 3.8l-.3.3c-.6.5-1.5.3-2-.3-.2-.3-.4-.7-.4-1 0-1.2.7-2 1.6-1.9.4 0 .8.2 1 .6.6.6.6 1.6.1 2.3zM37 16.7c-.1-2-1.8-3.6-3.9-3.5s-3.6 1.8-3.5 3.9v.1c0 2 1.5 3.6 3.5 3.6h.5c2-.1 3.5-1.8 3.4-3.8v-.3zm-2.6 1.4l-.3.3c-.6.5-1.5.4-2-.3-.3-.4-.4-.8-.4-1.2-.1-.8.5-1.6 1.3-1.7h.3c.4 0 .8.2 1 .6.6.6.6 1.6.1 2.3zm-5.6-2.7v4c0 .6-.5 1.1-1.1 1.1h-.3c-.5-.2-.8-.6-.8-1.1v-6.9-1.2c-.1-.5.3-1 .9-1.1h.2c.5-.1 1 .3 1.1.9v4.3zM40 5.7C40 2.6 37.5 0 34.3 0H5.7c-.5 0-1 .1-1.5.2C2 .7.3 2.5.1 4.8c-.1.3-.1.6-.1 1V32.7c0 2.8 2.2 5.2 5 5.3 2.4.1 4.8 0 7.1 0h22.5c2.5 0 4.6-1.8 5.1-4.2.2-.5.3-1.1.3-1.6V5.7zm-34.9 7c.5-3.2 2-6.2 4.2-8.6 1.3-1.5 2.9-2.7 4.6-3.7.1-.1.2-.1.3-.2h20.1c3.1 0 5.6 2.5 5.6 5.6v21.5c-.2 0-.2.1-.3.2-1.7 1.7-3.7 3-5.9 4-5.1 2.3-11 2.5-16.3.6-.5-.2-1-.2-1.5 0-.7.2-1.4.4-2.2.6-2.1.7-4.3.9-6.5.5 0 .1-.3-.1-.1-.3l.4-.2c1.2-.7 2.2-1.6 2.9-2.7.6-.7.5-1.7-.2-2.2 0 0-.1 0-.1-.1-4.1-3.9-6-9.5-5-15z'
                />
            );
        case 'yahoo':
            return (
                <path
                    {...props}
                    d='M22.48 0H7.5A7.53 7.53 0 000 7.52v15A7.53 7.53 0 007.5 30h15a7.54 7.54 0 007.5-7.52v-15A7.54 7.54 0 0022.48 0zm-1.77 12.19c-.45.12-4.64 3.36-4.92 4.14a25.24 25.24 0 00.07 3.34c.28.07 2.26 0 2.64.07l-.05.83h-4.33c-.74 0-3.1.07-3.81.05l.14-.79c.41 0 2.05.07 2.41-.31a13.84 13.84 0 00.07-3.19 45.13 45.13 0 00-4.81-5.83H5.71V9.29h8.34v1.19h-2.57c1.12 1.66 2.73 3.69 3.4 4.64l3.36-3h-2L16 11h7.31l-.05.07-.53.77-.19.31h-1.4a2.55 2.55 0 00-.43.04zm2.22 8.43l-.62-.05-.57-.05v-1.23l.71.09h.65zm.12-2.05l-1.12-.09v-5.22c.27.05 2.24.24 2.46.24z'
                />
            );
        case 'bbm':
            return (
                <path
                    {...props}
                    d='M20 0a20 20 0 1020 20A20 20 0 0020 0zm-9.62 22.7H6.54l1-4.83h3.67c2.39 0 3 1.18 3 2.1.01 1.32-.84 2.73-3.83 2.73zm1.32-7.27H7.87l1-4.83h3.68c2.39 0 3 1.17 3 2.1 0 1.3-.86 2.73-3.85 2.73zm7.51 14.85h-3.84l1-4.84h3.68c2.39 0 3 1.18 3 2.11 0 1.32-.85 2.73-3.84 2.73zm1.41-7.58h-3.83l1.05-4.83h3.67c2.39 0 3 1.18 3 2.1-.04 1.32-.89 2.73-3.89 2.73zM22 15.43h-3.88l1.05-4.83h3.67c2.39 0 3 1.17 3 2.1C25.8 14 25 15.43 22 15.43zm7.49 11.21h-3.88l1.05-4.84h3.67c2.39 0 3 1.18 3 2.11-.04 1.31-.89 2.73-3.89 2.73zm1.43-6.89H27l1-4.83h3.68c2.39 0 3 1.18 3 2.11.04 1.31-.81 2.72-3.81 2.72z'
                />
            );
        case 'youtube':
            return (
                <Fragment>
                    <path {...props} style={{ fillRule: 'evenodd', clipRule: 'evenodd', fill: '#a4a5a5' }} d="M36.318 45.353 45.727 40l-9.409-5.353z" />
                    <path {...props} style={{ fillRule: 'evenodd', clipRule: 'evenodd', fill: '#a4a5a5' }} d="M40 10c-16.569 0-30 13.431-30 30s13.431 30 30 30 30-13.431 30-30-13.431-30-30-30zm17.248 38.721a4.523 4.523 0 0 1-3.183 3.204c-2.807.757-14.065.757-14.065.757s-11.258 0-14.065-.757a4.523 4.523 0 0 1-3.183-3.204C22 45.896 22 40 22 40s0-5.896.752-8.721a4.523 4.523 0 0 1 3.183-3.204C28.742 27.318 40 27.318 40 27.318s11.258 0 14.065.757a4.523 4.523 0 0 1 3.183 3.204C58 34.104 58 40 58 40s0 5.896-.752 8.721z" />
                </Fragment>
            );
        case 'youtube-on':
            return (
                <Fragment>
                    <circle {...props} cx="40" cy="40" r="30" style={{ fillRule: 'evenodd', clipRule: 'evenodd', fill: 'red' }} />
                    <path {...props} style={{ fill: 'white' }} d="M57.248 31.279a4.523 4.523 0 0 0-3.183-3.204C51.258 27.318 40 27.318 40 27.318s-11.257 0-14.065.757a4.523 4.523 0 0 0-3.183 3.204C22 34.104 22 40 22 40s0 5.896.752 8.721a4.523 4.523 0 0 0 3.183 3.204c2.807.757 14.065.757 14.065.757s11.258 0 14.065-.757a4.523 4.523 0 0 0 3.183-3.204C58 45.896 58 40 58 40s0-5.896-.752-8.721zm-20.93 14.074V34.647L45.728 40l-9.41 5.353z" />
                </Fragment>
            );
        case 'facebook':
            return (
                <Fragment>
                    <path {...props} style={{ fillRule: 'evenodd', clipRule: 'evenodd', fill: '#a4a5a5' }} d="M40 70c-16.6 0-30-13.4-30-30s13.4-30 30-30 30 13.4 30 30-13.4 30-30 30zm-4.2-13.7h6.7V40H47l.6-5.6h-5.1v-2.8c0-1.5.1-2.3 2.2-2.3h2.8v-5.6H43c-5.4 0-7.3 2.7-7.3 7.3v3.4h-3.4V40h3.4v16.3h.1z" />
                </Fragment>
            );
        case 'facebook-on':
            return (
                <Fragment>
                    <circle {...props} style={{ fillRule: 'evenodd', clipRule: 'evenodd', fill: '#1778f2' }} cx="40" cy="40" r="30" />
                    <path {...props} style={{ fillRule: 'evenodd', clipRule: 'evenodd', fill: '#fff' }} d="M35.8 56.3h6.7V40H47l.6-5.6h-5.1v-2.8c0-1.5.1-2.3 2.2-2.3h2.8v-5.6H43c-5.4 0-7.3 2.7-7.3 7.3v3.4h-3.4V40h3.4v16.3h.1z" />
                </Fragment>
            )
        // NOTE: (SVG2JSX) DO NOT DELETE, ADD PATH.
        case 'followusline-on':
            return (
                <Fragment>
                    <path d="M22.28 10h35.44C64.474 10 70 15.526 70 22.28v35.44C70 64.474 64.474 70 57.72 70H22.28C15.526 70 10 64.474 10 57.72V22.28C10 15.526 15.526 10 22.28 10z" style={{ fill: '#3ace01' }} />
                    <path style={{ fill: '#fff' }} d="M39.942 19.939c-12.158 0-22.013 7.892-22.013 17.627 0 8.677 7.83 15.89 18.142 17.354l.005.001c.242.035.486.065.731.093 3.51.731.468 5.125 1.618 6.023 1.322 1.034 11.342-5.665 17.429-11.292a19.082 19.082 0 0 0 2.394-2.388l.013-.014c.17-.187.304-.356.407-.51 2.083-2.692 3.287-5.867 3.287-9.267 0-9.735-9.856-17.627-22.013-17.627zm-7.184 22.1c0 .633-.517 1.15-1.148 1.15h-4.44a1.151 1.151 0 0 1-1.148-1.15v-8.514c0-.632.516-1.148 1.148-1.148h.096c.631 0 1.148.516 1.148 1.148v7.271h3.196c.631 0 1.148.517 1.148 1.148v.096zm3.318 0c0 .633-.516 1.15-1.148 1.15h-.096a1.152 1.152 0 0 1-1.148-1.15v-8.514c0-.631.517-1.148 1.148-1.148h.096c.632 0 1.148.517 1.148 1.148v8.515zm10.399.001c0 .313-.128.598-.332.805a.81.81 0 0 1-.28.209c-.16.085-.343.134-.537.134h-.096c-.154 0-.3-.03-.435-.086a.965.965 0 0 1-.447-.33 1.176 1.176 0 0 1-.084-.115l-4.18-5.709v5.092c0 .632-.516 1.148-1.148 1.148h-.096a1.151 1.151 0 0 1-1.148-1.148v-8.515c0-.631.517-1.148 1.148-1.148h.096c.425 0 .799.235.997.58l4.15 5.567v-4.999c0-.631.516-1.148 1.148-1.148h.095c.632 0 1.149.517 1.149 1.148v8.515zm8.205-8.399c0 .632-.516 1.148-1.148 1.148h-3.196v1.818h3.196c.632 0 1.148.517 1.148 1.148v.096c0 .631-.516 1.148-1.148 1.148h-3.196v1.817h3.196c.632 0 1.148.517 1.148 1.148v.096c0 .632-.516 1.148-1.148 1.148h-4.44a1.151 1.151 0 0 1-1.148-1.148v-8.514c0-.632.517-1.149 1.148-1.149h4.44c.632 0 1.148.517 1.148 1.149v.095z" />
                </Fragment>
            );

        case 'followusline':
            return (
                <path {...props} style={{ fill: '#a4a5a5' }} d="M57.72 10H22.28C15.526 10 10 15.526 10 22.28v35.44C10 64.474 15.526 70 22.28 70h35.44C64.474 70 70 64.474 70 57.72V22.28C70 15.526 64.474 10 57.72 10zm.948 36.834a4.556 4.556 0 0 1-.407.51l-.013.013a19.081 19.081 0 0 1-2.394 2.388c-6.087 5.627-16.107 12.326-17.43 11.292-1.15-.898 1.894-5.292-1.617-6.023-.245-.028-.489-.058-.731-.093h-.005C25.76 53.455 17.93 46.242 17.93 37.565c0-9.735 9.855-17.627 22.013-17.627 12.157 0 22.013 7.892 22.013 17.627 0 3.4-1.204 6.575-3.287 9.267zM27.17 43.188h4.44c.631 0 1.148-.517 1.148-1.148v-.096c0-.631-.517-1.148-1.148-1.148h-3.196v-7.271c0-.631-.517-1.148-1.148-1.148h-.096c-.632 0-1.148.517-1.148 1.148v8.515c0 .631.516 1.148 1.148 1.148zm27.51-5.337v-.096c0-.631-.516-1.148-1.148-1.148h-3.196V34.79h3.196c.632 0 1.148-.516 1.148-1.148v-.095c0-.632-.516-1.149-1.148-1.149h-4.44c-.631 0-1.148.517-1.148 1.149v8.514c0 .632.517 1.148 1.148 1.148h4.44c.632 0 1.148-.516 1.148-1.148v-.096c0-.631-.516-1.148-1.148-1.148h-3.196V39h3.196a1.15 1.15 0 0 0 1.148-1.148zm-8.538 4.995c.205-.208.333-.493.333-.806v-8.515c0-.631-.517-1.148-1.149-1.148h-.095c-.632 0-1.148.517-1.148 1.148v4.999l-4.15-5.566a1.15 1.15 0 0 0-.997-.58h-.096c-.631 0-1.148.516-1.148 1.147v8.515c0 .632.517 1.148 1.148 1.148h.096c.632 0 1.148-.516 1.148-1.148v-5.092l4.18 5.709c.025.04.053.078.084.115a.965.965 0 0 0 .447.33c.134.055.281.086.435.086h.096c.194 0 .376-.049.537-.134a.81.81 0 0 0 .28-.208zm-11.31.342h.096c.632 0 1.148-.517 1.148-1.148v-8.515c0-.631-.516-1.148-1.148-1.148h-.096c-.631 0-1.148.517-1.148 1.148v8.515c0 .631.517 1.148 1.148 1.148z" />
            );

        case 'twitter-on':
            return (
                <Fragment>
                    <circle {...props} style={{ fillRule: 'evenodd', clipRule: 'evenodd' }} cx="40" cy="40" r="30" />
                    <path {...props} style={{ fillRule: 'evenodd', clipRule: 'evenodd', fill: '#fff' }} d="m43.4 37.8 10.7-12.2h-4.9l-8.1 9.2-7-9.2H24l12.1 15.8-11.4 13h4.9l8.8-10.1 7.7 10.1H56L43.4 37.8zm-13.6-9.4h2.9l17.5 23.1h-2.7L29.8 28.4z" />
                </Fragment>
            );

        case 'twitter':
            return (
                <path {...props} style={{ fillRule: 'evenodd', clipRule: 'evenodd', fill: '#a4a5a5' }} d="m29.8 28.4 17.7 23.1h2.7L32.7 28.4h-2.9zM40 10c-16.6 0-30 13.4-30 30s13.4 30 30 30 30-13.4 30-30-13.4-30-30-30zm6.1 44.4-7.7-10.1-8.8 10.1h-4.9l11.4-13L24 25.6h10.1l7 9.2 8.1-9.2h4.9L43.4 37.8 56 54.4h-9.9z" />
            );

        default:
            return <path />;
    }
};

const getStyle = (name, color) => {
    switch (name) {
        // NOTE: (SVG2JSX) DO NOT DELETE, ADD STYLE.
        case 'followusline-on':
            return `
	 .${name} .st2{fill:#3ACE01;}
	 .${name} .st3{fill:#FFFFFF;}
`;

        case 'followusline':
            return `
	 .${name} .st0{fill:#A4A5A5;}
`;

        case 'twitter-on':
            return `
	 .${name} .st1{fill:#2AA9E0;}
`;

        case 'twitter':
            return `
	 .${name} .st0{fill:#A4A5A5;}
`;

        default:
            return '';
    }
};

const SVGContact = ({
    name = '',
    style = {},
    fill = '',
    viewBox = '',
    width = '100%',
    className = '',
    height = '100%',
    color = ''
}) => (
    <svg
        width={width}
        style={style}
        height={height}
        className={className}
        xmlns='http://www.w3.org/2000/svg'
        viewBox={viewBox || getViewBox(name)}
        xmlnsXlink='http://www.w3.org/1999/xlink'
    >
        <style dangerouslySetInnerHTML={{ __html: getStyle(name, color) }}></style>
        {getPath(name, { fill })}
    </svg>
);

export default SVGContact;
