import React, { Component } from 'react';
import ItemDefault from './172_BankDetail_ItemDefault';
import ItemEditable from './173_BankDetail_ItemEditable';

export default class Item extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isEditing: false
    };
    this.handleEdit = this.handleEdit.bind(this);
    this.handleSkipEditing = this.handleSkipEditing.bind(this);
  }

  handleEdit() {
    this.setState({
      isEditing: true
    });
  }
  handleSkipEditing() {
    this.setState({
      isEditing: false
    });
  }
  render() {
    return this.state.isEditing ? (
      <ItemEditable item={this.props.item} onSkipEditing={this.handleSkipEditing} onSave={this.props.onSave} />
    ) : (
      <ItemDefault item={this.props.item} onEdit={this.handleEdit} onDelete={this.props.onDelete} onChecked={this.props.onChecked} />
    );
  }
}
