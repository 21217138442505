import React, { Component } from 'react';
import { Translation } from 'react-i18next';
import { connect } from 'react-redux';
import { Nav, NavLink, TabContent, TabPane } from 'reactstrap';
import classnames from 'classnames';
import { withRouter } from 'react-router-dom';
import { mapStateToProps } from '../../redux/selector/selector';
import Profile from './14_MyProfile';
import ChangePassword from '../13ChangePassword/13_ChangePassword';
import ChangeGamePassword from '../19ChangeGamePassword/19_ChangeGamePassword';
import BankDetail from '../17BankDetail/17_BankDetail';
import Messaging from '../12Messaging/12_Messaging';
import MessagingDetailModal from '../12Messaging/122_MessagingDetailModal';
import Loading from '../../components/Loading/Loading';

class EditProfile extends Component {
  isMount = true;

  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: 'myProfile',
      test: 'myProfile',
      account: {
        email: '',
        phone: '',
        login: '',
        name: '',
        dob: '',
        currency: '',
      },
      detailsObj: [],
      currentpassword: '',
      newpassword: '',
      confirmpassword: '',
      samePassword: false,
      hiddenCurrent: false,
      hiddenNewPassword: true,
      hiddenConfirmPassword: true,
      messageList: [],
      isLoading: false,
    };
  }
  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
      if (tab === 'changePassword') {
        this.props.history.push('/myaccount/changepassword');
      } else if (tab === 'bankDetails') {
        this.props.history.push('/myaccount/bankdetails');
      } else if (tab === 'privateMessage') {
        this.props.history.push('/myaccount/messaging');
      } else if (tab === 'myProfile') {
        this.props.history.push('/myaccount/myprofile');
      } else if (tab === 'changeGamePassword') {
        this.props.history.push('/myaccount/changegamepassword');
      }
    }
  }

  identity = () => {
    const { account } = this.props.user;
    if (this.isMount && account) {
      let duplicateAccount = window.SPL_Other.deepCopy(account);
      if (duplicateAccount && duplicateAccount.phone) {
        duplicateAccount.phone = window.SPL_Other.censored(duplicateAccount.phone, 'phone');
      }
      if (duplicateAccount && duplicateAccount.email) {
        duplicateAccount.email = window.SPL_Other.censored(duplicateAccount.email, 'email');
      }
      this.setState({ account: duplicateAccount });
    }
  };

  componentWillUnmount() {
    this.isMount = false;
  }
  componentDidMount() {
    this.isMount = true;
    this.identity();
    if (this.props.newpassword) {
      this.setState({ newpassword: this.props.newpassword });
    }
    this.getMessages();
    this.getPathForActiveTab();
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.getPathForActiveTab();
    }
  }

  getPathForActiveTab = () => {
    if (window.location.pathname.includes('messaging')) {
      this.toggle('privateMessage');
    } else if (window.location.pathname.includes('changepassword')) {
      this.toggle('changePassword');
    } else if (window.location.pathname.includes('changegamepassword')) {
      this.toggle('changeGamePassword');
    } else if (window.location.pathname.includes('bankdetails')) {
      this.toggle('bankDetails');
    } else if (window.location.pathname.includes('myprofile')) {
      this.toggle('myProfile');
    }
  };

  handlePasswordCurrentChange = (e) => {
    this.setState({ password: e.target.value });
  };

  toggleShowCurrent = () => {
    this.setState({ hiddenCurrent: !this.state.hiddenCurrent });
  };

  handlePasswordNewChange = (e) => {
    this.setState({ password: e.target.value });
  };
  handleConfirmPasswordChange = (e) => {
    this.setState({ password: e.target.value });
  };

  toggleShowNewPassword = () => {
    this.setState({ hiddenNewPassword: !this.state.hiddenNewPassword });
  };

  handleInputChangeCurrent = (e) => {
    const currentpassword = e.target.value;

    this.setState({
      currentpassword,
    });
  };

  handleInputChangeNewPassword = (e) => {
    const newpassword = e.target.value;

    this.setState({
      newpassword,
    });
  };

  handleInputChangeConfirmPassword = (e) => {
    const confirmpassword = e.target.value;

    this.setState({
      confirmpassword,
    });
  };

  onFlagChange = (index) => {
    let array = this.state.messageList;
    let msg = array[index];
    msg.isFlagChecked = !msg.isFlagChecked;
    let messageObj = {
      id: msg.id,
      isFlag: msg.isFlagChecked,
      status: msg.status,
    };
    array[index] = msg;
    window.SPL_Member.readAndFlagMessage(messageObj, ()=>{
      this.setState({ messageList: array });
    });
  };

  onClickMessage = (index, page) => {
    let subIndex = index;
    let i = 1;
    if (page > i) {
      for (let j = 0; j < i; j++) {
        subIndex = subIndex + 10;
      }
    }

    let array = this.state.messageList;
    let msg = array[subIndex];
    let messageObj = {
      id: msg.id,
      isFlag: msg.isFlagChecked,
      status: 'R',
    };
    array[subIndex] = msg;
    this.setState({ selectedMessage: msg, openMessageModal: true });
    window.SPL_Member.readAndFlagMessage(messageObj, ()=>{
      if (this.timeOut) {
        clearTimeout(this.timeOut);
      }
      this.timeOut = setTimeout(() => {
        if (this.isMount) {
          this.getMessages();
        }
      }, 1000);
    });
  };

  getMessages() {
    window.SPL_Member.getMessages().then((msgObj) => {
      if (this.isMount) {
        let messageArr = msgObj.messages;
        for (let i = 0; i < messageArr.length; i++) {
          messageArr[i].isChecked = false;
          if (messageArr[i].status.includes('F')) {
            messageArr[i].isFlagChecked = true;
          } else {
            messageArr[i].isFlagChecked = false;
          }
        }
      }
    });
  }

  closeModal = () => {
    this.setState({ selectedMessage: null, openMessageModal: false });
  };

  isChecked = (index) => {
    return this.state.checkAll || this.state.messageList[index].isChecked;
  };

  isFlagChecked = (index) => {
    return this.state.messageList[index].isFlagChecked;
  };

  onFlagChange = (index) => {
    let array = this.state.messageList;
    let msg = array[index];
    msg.isFlagChecked = !msg.isFlagChecked;
    let messageObj = {
      id: msg.id,
      isFlag: msg.isFlagChecked,
      status: msg.status,
    };
    array[index] = msg;
    window.SPL_Member.readAndFlagMessage(messageObj, ()=>{
      this.setState({ messageList: array });
    })
  };

  deleteMessageByBatch = () => {
    this.setState(
      { isLoading: true },
      () => {
        let listOfCheckedId = [];
        let messageList = this.state.messageList;
        for (let i = 0; i < messageList.length; i++) {
          const msg = messageList[i];
          if (msg.isChecked) {
            listOfCheckedId.push(msg.id);
          }
        }
        this.setState({ listOfCheckedId: listOfCheckedId }, () => {
          window.SPL_Member.deleteMessage(listOfCheckedId).then((data) => {
            this.timeOut = setTimeout(() => {
              if (this.isMount) {
                this.getMessages();
              }
            }, 1000);
            this.setState({ listOfCheckedId: [] });
          });
        });
      },
      setTimeout(() => {
        this.setState({ isLoading: false });
      }, 1800)
    );
  };

  render() {
    const { email, phone, login, name, dob, currency } = this.state.account || { email: '', phone: '', login: '', name: '', dob: '', currency: '' };

    return (
      <Translation>
        {(t) => (
          <div className='app-profile content'>
            {this.state.isLoading && <Loading></Loading>}

            <MessagingDetailModal
              messaging={this.state.selectedMessage}
              isOpen={this.state.openMessageModal}
              closeModal={this.closeModal}
              buttonText='DETAILS'
              buttonClass={'btn-secondary btn-lg'}
              onFlagChange={this.onFlagChange}
            />

            <div className='row d-flex justify-content-center'>
              <div className='account-col editProfile-bg'>
                <div className='method'>{/* <div className=''>{t('transaction:transaction.deposit.onlinetransfer.method')}</div> */}</div>

                <div className='deposit-tab-container'></div>
                <Nav tabs className='deposit-tab'>
                  <div
                    className='deposit-tab-click m-l-3 m-r-3'
                    onClick={() => {
                      this.toggle('myProfile');
                    }}>
                    <NavLink
                      className={classnames({
                        active: this.state.activeTab === 'myProfile',
                        navlinkForm: true,
                      })}>
                      <div className='deposit-quickpay'>{t('global:global.sidebar.account.profile', 'VIEW PROFILE').toLowerCase()}</div>
                    </NavLink>
                  </div>
                  <div
                    className='deposit-tab-click m-l-3 m-r-3'
                    onClick={() => {
                      this.toggle('changePassword');
                    }}>
                    <NavLink
                      className={classnames({
                        active: this.state.activeTab === 'changePassword',
                        navlinkForm: true,
                        w100: true,
                      })}>
                      <div className='deposit-quickpay'>{t('global:global.sidebar.account.password', 'CHANGE PASSWORD').toLowerCase()}</div>
                    </NavLink>
                  </div>
                  <div
                    className='deposit-tab-click m-l-3 m-r-3'
                    onClick={() => {
                      this.toggle('changeGamePassword');
                    }}>
                    <NavLink
                      className={classnames({
                        active: this.state.activeTab === 'changeGamePassword',
                        navlinkForm: true,
                        w100: true,
                      })}>
                      <div className='deposit-quickpay'>
                        {t('global:global.sidebar.account.provider_password', 'CHANGE GAME PASSWORD').toLowerCase()}
                      </div>
                    </NavLink>
                  </div>
                  <div
                    className='deposit-tab-click m-l-3 m-r-3'
                    onClick={() => {
                      this.toggle('bankDetails');
                    }}>
                    <NavLink
                      className={classnames({
                        active: this.state.activeTab === 'bankDetails',
                        navlinkForm: true,
                        w100: true,
                      })}>
                      <div className='deposit-quickpay'>{t('global:global.sidebar.account.banking', 'BANKING DETAILS').toLowerCase()}</div>
                    </NavLink>
                  </div>
                  <div
                    className='deposit-tab-click m-l-3 m-r-3'
                    onClick={() => {
                      this.toggle('privateMessage');
                    }}>
                    <NavLink
                      className={classnames({
                        active: this.state.activeTab === 'privateMessage',
                        navlinkForm: true,
                        w100: true,
                      })}>
                      <div className='deposit-quickpay'>{t('global:global.sidebar.account.messaging', 'PRIVATE MESSAGE').toLowerCase()}</div>
                    </NavLink>
                  </div>
                </Nav>

                <TabContent activeTab={this.state.activeTab} className='deposit-tab-content'>
                  {this.state.activeTab === 'myProfile' && (
                    <TabPane tabId='myProfile' className='  '>
                      <Profile></Profile>
                    </TabPane>
                  )}
                  {this.state.activeTab === 'changePassword' && (
                    <TabPane tabId='changePassword' className=' '>
                      <ChangePassword></ChangePassword>
                    </TabPane>
                  )}
                  {this.state.activeTab === 'changeGamePassword' && (
                    <TabPane tabId='changeGamePassword' className='  '>
                      <ChangeGamePassword></ChangeGamePassword>
                    </TabPane>
                  )}

                  {this.state.activeTab === 'bankDetails' && (
                    <TabPane tabId='bankDetails' className='  w-100'>
                      <BankDetail></BankDetail>
                    </TabPane>
                  )}
                  {this.state.activeTab === 'referral' && (
                    <TabPane tabId='referral' className=' w-100'>
                      <BankDetail></BankDetail>
                    </TabPane>
                  )}
                  {this.state.activeTab === 'privateMessage' && (
                    <TabPane tabId='privateMessage' className=' w-100 p-l-0 p-r-0 '>
                      <Messaging
                        onClickMessage={this.onClickMessage}
                        getMessages={this.getMessages}
                        messageList={this.state.messageList}
                        isChecked={this.isChecked}
                        isFlagChecked={this.isFlagChecked}
                        onFlagChange={this.onFlagChange}
                        deleteMessageByBatch={this.deleteMessageByBatch}></Messaging>
                    </TabPane>
                  )}
                </TabContent>
              </div>
            </div>
          </div>
        )}
      </Translation>
    );
  }
}

export default connect(mapStateToProps)(withRouter(EditProfile));
