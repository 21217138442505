export function wd_handleReceiptImageChange(vm, e) {
    let { errors } = vm.state;
    errors['receipt'] = '';

    let stateToUpdate = {
        errors,
        receiptImg: [],
        receiptImgFile: null,
        isInvalidUploadFileType: false,
    };

    // pending info from BE
    if (e && e.target && e.target.files && e.target.files.length > 0) {
        let receipt = e.target.files[0];
        window.SPL_Transaction.validateReceiptImage(receipt).then((data) => {
            const { errMsg, isInvalidUploadFileType } = data;

            errors['receipt'] = errMsg;
            if (data.isInvalidUploadFileType) {
                stateToUpdate = {
                    ...stateToUpdate,
                    isInvalidUploadFileType: isInvalidUploadFileType,
                    errors,
                };
            } else {
                stateToUpdate = {
                    ...stateToUpdate,
                    receiptImg: URL.createObjectURL(receipt),
                    receiptImgFile: receipt,
                };
            }
            vm.setState(stateToUpdate);
        });
    } else {
        vm.setState(stateToUpdate);
    }
}

export function wd_handleClearReceipt(vm) {
    let inputID = document.getElementById('hiddenFileInput');
    let mobileInputID = document.getElementById('uploadInput');
    if (inputID) {
        inputID.value = null;
    }
    if (mobileInputID) {
        mobileInputID.value = null;
    }
    vm.setState({
        receiptImg: [],
        receiptImgFile: null,
    });
}
