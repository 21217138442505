import moment from 'moment';
import React, { Component, Fragment } from 'react';
import { userAction } from 'root/redux/action';
import { Translation, withTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';
import MessagingModal from '../../12Messaging/121_MessagingModal';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import MessagingDetailModal from '../../12Messaging/122_MessagingDetailModal';
import { MdFlag } from 'react-icons/md';
import { AiFillDelete } from 'react-icons/ai';
import { connect } from 'react-redux';
import { mapStateToProps } from '../../../redux/selector/selector';
import { withRouter } from 'react-router-dom';
import { MdClose } from 'react-icons/md';
import Select from 'react-dropdown-select';
import { flagIcon, deleteIcon } from '../../16MyAccount/161_MyAccount_Items_Data';
import SVGMessage from 'root/components/Icon/SVGMessage';
import Pagination from 'react-js-pagination';
import SVGHistory from 'root/components/Icon/SVGHistory';
import Loading from 'root/components/Loading/Loading';
import SVGElements from 'root/components/SVGElements';

class Message extends Component {
    msgCategories = [
        { id: 0, name: this.props.t('global:infoCentre.promotions'), msgType: 'promo', type: 'P' },
        { id: 1, name: this.props.t('global:infoCentre.payment'), msgType: 'payment', type: 'PT' },
        { id: 1, name: this.props.t('settings:settings.profiletab.system'), msgType: 'inbox', type: 'N' },
        { id: 1, name: this.props.t('transaction:transaction.notification'), msgType: 'notification', type: 'I' },
    ];

    isMount = true;
    timeOut = null;

    constructor(props) {
        super(props);
        let startEndParams = {
            start: null,
            end: new Date(new Date(new Date()).setHours(23, 59, 59)),
        };
        this.state = {
            visible: false,
            messageList: [],
            listOfCheckedId: [],
            allChecked: false,
            show: false,
            checkAll: false,
            currentPage: 0,
            todosPerPage: 5,
            isLoading: false,
            checkBool: false,
            countCheck: 0,
            getId: [],
            unreadMsgCount: 0,
            showDelete: false,
            showSelectedDelete: false,
            isNotification: false,
            inboxCount: 0,
            notificationCount: 0,
            paymentCount: 0,
            promotionCount: 0,
            currentMsgType: 'P',
            startEndParams,
        };
    }

    componentDidMount() {
        this.isMount = true;
        this.getMessages(this.state.isNotification, this.state.currentMsgType);
        this.countMsg();

        if (this.props.screen.viewType === 'web' && this.props.location.pathname === '/messaging') {
            this.props.history.push('/myaccount/messaging');
        } else if (this.props.screen.viewType === 'mobile' && this.props.location.pathname === '/myaccount/messaging') {
            this.props.history.push('/myaccount/myprofile');
            this.props.history.push('/messaging');
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.user.unreadMsg !== this.props.user.unreadMsg) {
            this.countMsg();
        }
    }

    componentWillUnmount() {
        this.isMount = false;
        if (this.timeOut) {
            clearTimeout(this.timeOut);
        }
    }

    handleClick = (event) => {
        this.setState({ currentPage: Number(event.target.id) }, () => {
            this.clearCheck();
        });
    };

    deleteSelectedModal() {
        this.setState({ showSelectedDelete: true });
    }

    getMessages(isNotification, currentMsgType) {
        const { currentPage, todosPerPage } = this.state;

        this.setState({ isNotification: isNotification });

        window.SPL_Member.getMessages(isNotification, currentPage, todosPerPage, this.state.startEndParams, '', currentMsgType).then((msgObj) => {
            if (this.isMount) {
                let messageArr = msgObj.messages;
                let totalPageSize = msgObj.totalPageSize;

                if (messageArr.length === 0) {
                    if (currentPage - 1 < 0) {
                        this.setState({ currentPage: 0 });
                    } else {
                        this.setState({ currentPage: currentPage - 1 });
                    }

                    this.getMessages(this.state.isNotification, this.state.currentMsgType);
                }

                for (let i = 0; i < messageArr.length; i++) {
                    messageArr[i].isChecked = false;

                    if (messageArr[i].status.includes('F')) {
                        messageArr[i].isFlagChecked = true;
                    } else {
                        messageArr[i].isFlagChecked = false;
                    }
                }

                this.setState({ messageList: messageArr, totalItems: totalPageSize, isLoading: false });
            }
        });
    }

    /* CAN DELETE ALOT MESSAGE AT A TIME */
    deleteMessageByBatch = () => {
        this.setState({ isLoading: true }, () => {
            let listOfCheckedId = [];
            let messageList = this.state.messageList;
            let setTimeOut = 1000;

            for (let i = 0; i < messageList.length; i++) {
                const msg = messageList[i];

                if (msg.isChecked) {
                    listOfCheckedId.push(msg.id);
                }
            }

            if (listOfCheckedId.length > 0) {
                setTimeOut = 5000;
            }

            this.setState({ listOfCheckedId: listOfCheckedId }, () => {
                window.SPL_Member.deleteMessage(listOfCheckedId).then((data) => {
                    if (this.timeOut) {
                        clearTimeout(this.timeOut);
                    }

                    this.timeOut = setTimeout(() => {
                        if (this.isMount) {
                            this.countMsg();
                            this.getMessages(this.state.isNotification, this.state.currentMsgType);
                            this.onCheckAllChange(false);
                        }
                    }, setTimeOut);

                    this.setState({ listOfCheckedId: [], showSelectedDelete: false });
                });
            });
        });
    };

    /* CAN ONLY DELETE ONE MESSAGE AT A TIME */
    deleteMessage = (id) => {
        this.setState({ isLoading: true }, () => {
            let listOfCheckedId = [];

            listOfCheckedId.push(id);
            this.setState({ listOfCheckedId: listOfCheckedId }, () => {
                window.SPL_Member.deleteMessage(listOfCheckedId).then((data) => {
                    this.timeOut = setTimeout(() => {
                        if (this.isMount) {
                            this.countMsg();
                            this.getMessages(this.state.isNotification, this.state.currentMsgType);
                        }
                    }, 1000);

                    this.setState({ listOfCheckedId: [] });
                });
            });
        });
    };

    onChange = (index, id) => {
        let array = this.state.messageList;

        for (let a = 0; a < array.length; a++) {
            if (id === array[a].id) {
                if (array[a].isChecked === false) {
                    array[a].isChecked = true;
                } else {
                    array[a].isChecked = false;
                }
            }
        }

        this.setState({ messageList: array, checkAll: false }, () => {
            let msgList = this.state.messageList;

            for (let i = 0; i < msgList.length; i++) {
                const contact = msgList[i];

                if (contact.isChecked === true) {
                    this.setState({ countCheck: 1 });
                    break;
                } else {
                    this.setState({ countCheck: 0 });
                }
            }
        });
    };

    onFlagChange = (index) => {
        let array = this.state.messageList;
        let msg = array[index];

        msg.isFlagChecked = !msg.isFlagChecked;

        let messageObj = {
            id: msg.id,
            isFlag: msg.isFlagChecked,
            status: msg.status,
        };

        array[index] = msg;

        window.SPL_Member.readAndFlagMessage(messageObj, () => {
            this.setState({ messageList: array });
        });
    };

    /* CAN MARK AS READ ALOT MESSAGE AT A TIME */
    onClickMessageByBatch = () => {
        this.setState({ isLoading: true }, () => {
            let messageList = this.state.messageList;
            let msg = null;

            for (let i = 0; i < messageList.length; i++) {
                if (messageList[i].isChecked) {
                    msg = messageList[i];

                    let messageObj = {
                        id: msg.id,
                        isFlag: msg.isFlagChecked,
                        status: 'R',
                    };

                    window.SPL_Member.readAndFlagMessage(messageObj, () => {
                        if (this.timeOut) {
                            clearTimeout(this.timeOut);
                        }

                        this.timeOut = setTimeout(() => {
                            if (this.isMount) {
                                this.countMsg();
                                this.getMessages(this.state.isNotification, this.state.currentMsgType);
                                this.onCheckAllChange(false);
                            }
                        }, 1000);
                    });
                }
            }
        });
    };

    formatCurrentLanguage = (lng) => {
      return lng ? lng.toLowerCase().replace('_', '-') : 'en-my';
    };

    formatMessage(message) {
        if (!message) {
            return message;
        }
        const currentLang = this.formatCurrentLanguage(this?.props?.language?.countryLanguageKey);
        const providerLink = `/${currentLang}${this.props.portal?.settings?.features?.floatingFreeSpinSettings?.ctaLink ?? `/slots/$1`}`;

        let formattedMessage = message?.replaceAll(/{{(.*?)\s*(?:<<|&lt;&lt;)\s*(.*?)\s*(?:>>|&gt;&gt;)}}/g, (_, textTranslation) => {
            return `<a style="color:#3498db" href="${providerLink}">${textTranslation}</a>`;
        });

        // Replace date format like 2024-11-23 16:59:59.000 to YYYY-MM-DD HH:MM
        return formattedMessage?.replaceAll(/\b(\d{4})-(\d{2})-(\d{2}) (\d{2}):(\d{2}):\d{2}\.\d{3}\b/g, (_, year, month, day, hour, minute) => {
            return `${year}-${month}-${day} ${hour}:${minute}`;
        });
    }

    onClickMessage(index, id) {
        this.onCheckAllChange(false);

        let counter = 1;
        let page = this.state.currentPage;

        if (page > counter) {
            let i = page - counter;
            let j = 0;

            for (j; j > i; j++) {
                index = index + 10;
            }
        }

        let array = this.state.messageList;
        let msg = null;

        for (let a = 0; a < array.length; a++) {
            if (id === array[a].id) {
                msg = array[a];
            }
        }

        let messageObj = {
            id: msg.id,
            isFlag: msg.isFlagChecked,
            status: 'R',
        };

        if (msg.messageType === 'I') {
          msg.message = this.formatMessage( msg.message);
        }

        this.setState({ selectedMessage: msg, openMessageModal: true });

        window.SPL_Member.readAndFlagMessage(messageObj, () => {
            if (this.timeOut) {
                clearTimeout(this.timeOut);
            }

            this.timeOut = setTimeout(() => {
                if (this.isMount) {
                    this.countMsg();
                    this.getMessages(this.state.isNotification, this.state.currentMsgType);
                }
            }, 1000);
        });
    }

    countMsg = () => {
        window.SPL_Member.getUnreadMessageCount().then((data) => {
            if (this.isMount) {
                this.setState(
                    {
                        unreadMsgCount: data.unreadCount,
                        inboxCount: data.unreadInboxCount,
                        notificationCount: data.unreadNotificationsCount,
                        paymentCount: data.unreadPaymentCount,
                        promotionCount: data.unreadPromotionCount,
                    },
                    () => {
                        let count = this.state.unreadMsgCount;

                        this.props.dispatch(userAction.getUnreadMsg(count));
                    }
                );
            }
        });
    };

    isChecked(index, id) {
        let array = this.state.messageList;
        let check = null;

        for (let a = 0; a < array.length; a++) {
            if (id === array[a].id) {
                check = array[a].isChecked;
            }
        }

        return this.state.checkAll || check;
    }

    isFlagChecked(index, id) {
        let array = this.state.messageList;
        let check = null;

        for (let a = 0; a < array.length; a++) {
            if (id === array[a].id) {
                check = array[a].isFlagChecked;
            }
        }

        return check;
    }

    onCheckAllChange = (e) => {
        let checked = false;
        let messageList = this.state.messageList;

        if (e) {
            checked = e.target.checked;
        }

        for (let i = 0; i < messageList.length; i++) {
            messageList[i].isChecked = checked;
        }

        let id = this.state.getId;

        this.setState({ messageList: messageList, getId: id, checkAll: checked, checkBool: !this.state.checkBool }, () => {
            let check = this.state.checkBool;

            if (check) {
                this.setState({ countCheck: 1 });
            } else {
                this.setState({ countCheck: 0 });
            }
        });
    };

    uncheck() {
        let messageList = this.state.messageList;

        for (let i = 0; i < messageList.length; i++) {
            messageList[i].isChecked = this.state.checkAll;
        }

        this.setState({ messageList: messageList });
    }

    clearCheck = () => {
        let messageList = this.state.messageList;

        for (let i = 0; i < messageList.length; i++) {
            messageList[i].isChecked = false;
        }

        this.setState({ messageList: messageList, checkAll: false, checkBool: false, countCheck: 0, getId: [] });
    };

    showModal = () => {
        this.setState({ show: true });
    };

    hideModal = () => {
        this.setState({ show: false });
    };

    closeModal = () => {
        this.setState({ selectedMessage: null, openMessageModal: false });
    };

    showDeleteBtn = (id) => {
        this.onCheckAllChange(false);
        this.setState({ showDelete: id });
    };

    onTabClick = (index) => {
        this.setState({ currentPage: 0, currentMsgType: index[0].type }, () => {
            this.props.dispatch(userAction.getUnreadMsg());

            let notification = false;
            if (index[0].msgType === 'notification') {
                notification = true;
            }

            // if (index[0].msgType === 'notification') {
            //   this.getMessages(true);
            // } else {
            this.getMessages(notification, index[0].type);
            // }
        });
    };

    handlePageChange = (pageNumber) => {
        this.setState({ currentPage: pageNumber - 1 }, () => {
            this.getMessages(this.state.isNotification, this.state.currentMsgType);
        });
    };

    render() {
        const renderTodos = this.state.messageList.map((msg, index) => {
            return (
                <div key={msg.id} id={index} className='message-item'>
                    <label className='msgitem-checkbox'>
                        <input type='checkbox' value={index} checked={this.isChecked(index, msg.id)} onChange={() => this.onChange(index, msg.id)} />
                        <span className='checkmark'></span>
                    </label>

                    <div
                        className={`msgSubject ${['R', 'FR'].includes(msg.status) && 'msg-read'}`}
                        onClick={() => {
                            this.onClickMessage(index, msg.id);
                        }}
                    >
                        {msg.title}
                    </div>

                    <div className={`flag-delete ${this.state.showDelete === msg.id ? 'hide' : 'show'}`}>
                        <SVGElements
                            name='mark-as-read-icon'
                            className={`${this.isFlagChecked(index, msg.id) ? 'msg-flagged' : 'msg-unflag'}`}
                            onClick={() => this.onFlagChange(index, msg.id)}
                        />
                        {/* <img src={flagIcon} className={`${this.isFlagChecked(index, msg.id) ? 'msg-flagged' : 'msg-unflag'}`} onClick={() => this.onFlagChange(index, msg.id)} /> */}
                        <SVGElements className='msg-delete' name='delete-icon' onClick={() => this.showDeleteBtn(msg.id)} />
                        {/* <img src={deleteIcon} className='msg-delete' onClick={() => this.showDeleteBtn(msg.id)} /> */}
                    </div>

                    <div className={`msg-cancel-delete ${this.state.showDelete === msg.id ? 'show' : 'hide'}`}>
                        <button className='btn-normal btn-cancel' onClick={() => this.showDeleteBtn('')}>
                            {this.props.t('global:entity.action.cancel', 'Cancel')}
                        </button>
                        <button className='btn-normal btn-delete' onClick={() => this.deleteMessage(msg.id)}>
                            {this.props.t('global:entity.action.delete', 'Delete')}
                        </button>
                    </div>
                </div>
            );
        });

        const customContentRenderer = ({ props, state }) => {
            return (
                <span>
                    {state.values[0].name}
                    {state.values[0].msgType === 'promo' ? (
                        this.state.promotionCount > 0 ? (
                            <Fragment> ({this.state.promotionCount})</Fragment>
                        ) : this.state.promotionCount > 99 ? (
                            <Fragment> (99+)</Fragment>
                        ) : (
                            <Fragment> (0)</Fragment>
                        )
                    ) : state.values[0].msgType === 'payment' ? (
                        this.state.paymentCount > 0 ? (
                            <Fragment> ({this.state.paymentCount})</Fragment>
                        ) : this.state.paymentCount > 99 ? (
                            <Fragment> (99+)</Fragment>
                        ) : (
                            <Fragment> (0)</Fragment>
                        )
                    ) : state.values[0].msgType === 'inbox' ? (
                        this.state.inboxCount > 0 ? (
                            <Fragment> ({this.state.inboxCount})</Fragment>
                        ) : this.state.inboxCount > 99 ? (
                            <Fragment> (99+)</Fragment>
                        ) : (
                            <Fragment> (0)</Fragment>
                        )
                    ) : state.values[0].msgType === 'notification' && this.state.notificationCount > 0 ? (
                        <Fragment> ({this.state.notificationCount})</Fragment>
                    ) : this.state.notificationCount > 99 ? (
                        <Fragment> (99+)</Fragment>
                    ) : (
                        <Fragment> (0)</Fragment>
                    )}
                </span>
            );
        };

        const customDropdownRenderer = ({ methods, state, props }) =>
            props.options.map((item) => {
                return item.name === this.props.t('global:infoCentre.promotions') ? (
                    <span key={item.id} className={`msg-filter-box ${this.state.currentMsgType === 'P' && 'active'}`} onClick={() => methods.addItem(item)}>
                        {this.props.t('global:infoCentre.promotions')}
                        {this.state.promotionCount > 0 ? (
                            <Fragment> ({this.state.promotionCount})</Fragment>
                        ) : this.state.promotionCount > 99 ? (
                            <Fragment> (99+)</Fragment>
                        ) : (
                            <Fragment> (0)</Fragment>
                        )}
                    </span>
                ) : item.name === this.props.t('global:infoCentre.payment') ? (
                    <span key={item.id} className={`msg-filter-box ${this.state.currentMsgType === 'PT' && 'active'}`} onClick={() => methods.addItem(item)}>
                        {this.props.t('global:infoCentre.payment')}
                        {this.state.paymentCount > 0 ? (
                            <Fragment> ({this.state.paymentCount})</Fragment>
                        ) : this.state.paymentCount > 99 ? (
                            <Fragment> (99+)</Fragment>
                        ) : (
                            <Fragment> (0)</Fragment>
                        )}
                    </span>
                ) : item.name === this.props.t('settings:settings.profiletab.system') ? (
                    <span key={item.id} className={`msg-filter-box ${this.state.currentMsgType === 'N' && 'active'}`} onClick={() => methods.addItem(item)}>
                        {this.props.t('settings:settings.profiletab.system')}
                        {this.state.inboxCount > 0 ? (
                            <Fragment> ({this.state.inboxCount})</Fragment>
                        ) : this.state.inboxCount > 99 ? (
                            <Fragment> (99+)</Fragment>
                        ) : (
                            <Fragment> (0)</Fragment>
                        )}
                    </span>
                ) : (
                    item.name === this.props.t('transaction:transaction.notification') && (
                        <span key={item.id} className={`msg-filter-box ${this.state.currentMsgType === 'I' && 'active'}`} onClick={() => methods.addItem(item)}>
                            {this.props.t('transaction:transaction.notification', 'Notification')}
                            {this.state.notificationCount > 0 ? (
                                <Fragment> ({this.state.notificationCount})</Fragment>
                            ) : this.state.notificationCount > 99 ? (
                                <Fragment> (99+)</Fragment>
                            ) : (
                                <Fragment> (0)</Fragment>
                            )}
                        </span>
                    )
                );
            });

        return (
            <Translation>
                {(t) => (
                    <div className='wap-message-page'>
                        {this.state.isLoading && <Loading />}

                        {this.state.showSelectedDelete && (
                            <div className='messaging-popup'>
                                <div className='popup'>
                                    <div className='popup-header'>
                                        <div className='popup-header-left'>
                                            <div className='popup-title'>{t('message:message.confirm-subject', 'Delete Selected Message?')}</div>
                                        </div>
                                        <div className='popup-header-right'>
                                            <SVGElements name='close-icon' onClick={() => this.setState({ showSelectedDelete: false })} />
                                            {/* <MdClose onClick={() => this.setState({ showSelectedDelete: false })} /> */}
                                        </div>
                                    </div>
                                    <div className='popup-body'>
                                        <span className='btn-normal btn-body btn-ok' onClick={this.deleteMessageByBatch}>
                                            {t('login:login.ok', 'ok')}
                                        </span>
                                    </div>
                                </div>
                                <div className='popup-overlay' onClick={() => this.setState({ showSelectedDelete: false })}></div>
                            </div>
                        )}

                        {/* *********** Message content Modal ******************** */}
                        <MessagingDetailModal
                            messaging={this.state.selectedMessage}
                            isOpen={this.state.openMessageModal}
                            closeModal={this.closeModal}
                            buttonText='DETAILS'
                            buttonClass={'btn-secondary btn-lg'}
                            onFlagChange={this.onFlagChange}
                        ></MessagingDetailModal>

                        <div className='page-content'>
                            <div className='msg-item-box'>
                                <div className='title-item'>
                                    <span>
                                        {t('message:message.message', 'Message')} {t('transaction:transaction.deposit.onlinetransfer.option', 'Option')}
                                    </span>
                                </div>

                                <div className='msg-filter'>
                                    <Select
                                        key='name'
                                        labelField='name'
                                        valueField='name'
                                        searchable={false}
                                        placeholder={t('global:global.form.pleaseSelect2')}
                                        options={this.msgCategories}
                                        values={this.msgCategories}
                                        onChange={(value) => this.onTabClick(value)}
                                        dropdownRenderer={customDropdownRenderer}
                                        contentRenderer={customContentRenderer}
                                    />
                                </div>
                            </div>

                            {this.state.messageList.length > 0 ? (
                                <Fragment>
                                    <div className='message-item-box'>
                                        <div className='message-header'>
                                            <label className='message-checkbox'>
                                                <input type='checkbox' onChange={this.onCheckAllChange} checked={this.state.checkAll} />
                                                {t('message:message.selectAll')}
                                            </label>
                                            <div>
                                                <div onClick={() => this.onClickMessageByBatch()}>
                                                    <SVGElements name='mark-as-read-icon' />
                                                </div>
                                                <SVGElements name='delete-icon' onClick={() => this.setState({ showSelectedDelete: true })} />
                                                {/* <AiFillDelete onClick={() => this.setState({ showSelectedDelete: true })} /> */}
                                            </div>
                                        </div>
                                    </div>

                                    <div className='message-item-box'>
                                        {renderTodos}

                                        <Pagination
                                            firstPageText={<SVGHistory name='prevFirst-arrow' className='' alt='prevFirst-arrow' />}
                                            lastPageText={<SVGHistory name='nextLast-arrow' className='' alt='nextLast-arrow' />}
                                            prevPageText={<SVGHistory name='prev-arrow' className='' alt='prev-arrow' />}
                                            nextPageText={<SVGHistory name='next-arrow' className='' alt='next-arrow' />}
                                            activePage={this.state.currentPage + 1}
                                            itemsCountPerPage={this.state.todosPerPage}
                                            totalItemsCount={parseInt(this.state.totalItems)}
                                            pageRangeDisplayed={5}
                                            onChange={this.handlePageChange.bind(this)}
                                        />
                                    </div>
                                </Fragment>
                            ) : (
                                <div className='no-msg-text'>{t('message:message.noMessage', 'No Message')}</div>
                            )}
                        </div>
                    </div>
                )}
            </Translation>
        );
    }
}

export default connect(mapStateToProps, null)(withTranslation(['message', 'global'])(withRouter(Message)));
