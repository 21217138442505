import classnames from 'classnames';
import moment from 'moment';
import 'rc-datetime-picker/dist/picker.css';
import React, { Component } from 'react';
import Select from 'react-dropdown-select';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Trans, Translation, withTranslation } from 'react-i18next';
import { FaRegCalendar } from 'react-icons/fa';
import { connect } from 'react-redux';
import { Nav, NavLink, NavItem, Row, TabContent, TabPane } from 'reactstrap';
import { Col } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { mapStateToProps } from '../../redux/selector/selector';
import { AiOutlineCopy } from 'react-icons/ai';
import { IoIosAdd } from 'react-icons/io';
import DatePicker from 'react-datepicker';
import { FiArrowDownRight } from 'react-icons/fi';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import BankDetailModal from '../17BankDetail/174_BankDetail_Modal';
import SmallLoading from '../../components/Loading/SmallLoading';
import {
    KBank,
    KrungThai,
    BangkokBank,
    SiamBank,
    GovernmentBank,
    TMB,
    Krungsri,
    CIMB,
    Thanachart,
    UOB,
    LandandHousesBank,
    GovernmentHousing,
    HSBC,
    StandardCharter,
    BAAC,
    CITI,
    Deutsche,
    Islamic,
    Kiatnakin,
    Tisco,
} from './081_Deposit_Items_Data';
import QuickPay from './084_QuickPay';
import OnlineTransfer from './085_OnlineTransfer';
import { trans_handlePGDepositCountDown } from 'root/utils/transaction-util';
import notification from 'root/utils/notification';

class Deposit extends Component {
    isMount = true;
    constructor(props) {
        super(props);
        let currentDate = new Date();
        let currentDateTime = moment(Date.now()).format('YYYY-MM-DD hh:mm a');
        this.toggle = this.toggle.bind(this);
        this.state = {
            activeTab: 'quickPay',
            iconLoading: false,
            depositDetails: null,
            selectedMethod: [],
            selectedBank: [],
            amount: '',
            amountErrMsg: [],
            disabledDepositButton: true,
            limitAmount: '',
            currency: '',
            referenceCode: '',
            depositOptions: [],
            bankList: [],
            bankNameErrMsg: [],
            selectedDepositChannel: [],
            receiptImg: [],
            depositDate: moment(currentDateTime, 'YYYY-MM-DD hh:mm a'),
            depositDateString: moment(currentDateTime, 'YYYY-MM-DD hh:mm a').format('YYYY-MM-DDTHH:mm'),
            selectedDepositBank: null,
            radioValue: 0,
            status: '',
            message: '',
            moment: moment(),
            histories: [],
            start: new Date(new Date().setMonth(currentDate.getMonth() - 1)),
            end: currentDate,
            currentPage1: 1,
            todosPerPage1: 5,
            showLoading: false,
            depositSubmitCountdown: null,
        };
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState(
                {
                    activeTab: tab,
                },
                () => {
                    this.setState({ amount: '', errMsgLimitAmount: [], amountErrMsg: [] });
                }
            );
            // if (this.props.screen.viewType === 'web') {
            //   if (tab === 'quickPay') {
            //     this.props.history.push('/myaccount/quickpay');
            //   } else if (tab === 'onlinetransfer') {
            //     this.props.history.push('/myaccount/onlinetransfer');
            //   }
            // }
        }
    }

    getSubmitCountDown(){
      const { filteredDepositApiJson } = this.state;
      const paymentCode = filteredDepositApiJson && filteredDepositApiJson.optionCode;
      trans_handlePGDepositCountDown(this, false, 60, paymentCode, Date.now().valueOf(), { disabledDepositButton: true });
    }

    setDepositOptions() {
        const { t } = this.props;
        let depositOptions = [
            { label: t('settings:settings.dropdown.onlineBanking'), value: 'O' },
            { label: t('settings:settings.dropdown.cashDepositMachine'), value: 'D' },
            { label: t('settings:settings.dropdown.atmTransfer'), value: 'A' },
        ];
        this.setState({ depositOptions: depositOptions });
    }

    handleMethodChange = (value) => {
        this.setState({ selectedMethod: value }, () => {
            this.checkError();
        });
    };
    handleBankChange = (value) => {
        this.setState({ selectedBank: value }, () => {
            this.checkError();
        });
    };

    handleDepositChannelChange = (value) => {
        this.setState({ selectedDepositChannel: value }, () => {
            const { selectedDepositChannel, receiptImg } = this.state;
            if (
                selectedDepositChannel.length > 0 &&
                ((receiptImg.length <= 0 && selectedDepositChannel[0].value === 'O') ||
                    (receiptImg.length <= 0 && selectedDepositChannel[0].value === 'D') ||
                    (receiptImg.length <= 0 && selectedDepositChannel[0].value === 'A'))
            ) {
                this.setState({ status: 'F', message: 'transaction:transaction.reload.receiptIsRequired' });
            }
            this.checkError();
        });
    };

    handleReceiptImageChange = (event) => {
        if (event && event.target && event.target.files.length > 0) {
            this.setState({ receiptImg: URL.createObjectURL(event.target.files[0]), receiptImgFile: event.target.files[0] }, () => {
                if (this.state.receiptImg.length > 0) {
                    this.setState({ status: '', message: '' });
                }
                this.checkError();
            });
        }
    };

    handlereferenceCodeChange = (event) => {
        this.setState({ referenceCode: event.target.value }, () => {
            this.checkError();
        });
    };

    handleDepositDateChanged = (date, dateString) => {
        this.setState({ depositDate: date, depositDateString: date.format('YYYY-MM-DDTHH:ss') }, () => {
            this.checkError();
        });
    };

    onDepositBankAcc = (index) => {
        this.setState({ selectedDepositBank: this.state.merchantBankList[index], radioValue: index }, () => {
            this.checkError();
        });
    };

    componentDidMount() {
        this.getSubmitCountDown();
        this.setDepositOptions();
        this.loadDepositDetails();
        this.loadMerchantBank();
        this.loadBankAccounts();
        this.loadHistories();
        this.loadBankAccountsForDeposit();

        if (this.props.screen.viewType === 'web' && this.props.location.pathname === '/deposit') {
            this.props.history.push('/myaccount/deposit');
        } else if (this.props.screen.viewType === 'mobile' && this.props.location.pathname === '/myaccount/deposit') {
            // to able to back to home when switch from desktop deposit page to mobile deposit page
            this.props.history.push('/home');
            this.props.history.push('/fund');
            this.props.history.push('/deposit');
        }
    }

    componentWillUnmount() {
        this.isMount = false;
    }

    componentDidUpdate(prevProps) {
        if (prevProps != this.props) {
            this.loadBankAccounts();
            this.getPathForActiveTab();
        }
    }

    getPathForActiveTab = () => {
        if (this.props.screen && this.props.screen.viewType && this.props.screen.viewType === 'web') {
            if (window.location.pathname.includes('onlinetransfer')) {
                this.toggle('onlinetransfer');
            } else if (window.location.pathname.includes('quickpay')) {
                this.toggle('quickPay');
            }
        }
    };

    loadDepositDetails = () => {
        this.setState({ isLoading: true });
        const { account } = this.props.user;
        let currency = '';
        if (account) {
            currency = account.currency;
        }

        window.SPL_Transaction.loadDepositDetails(currency, this.state.activeTab, false).then((depositDetails) => {
            if (this.isMount) {
                let activeTab = 'quickPay';
                if (!depositDetails.defaultMethod) {
                    activeTab = 'onlinetransfer';
                }
                this.setState({ depositDetails: depositDetails, selectedMethod: [depositDetails.defaultMethod], isLoading: false, activeTab }, () => {
                    this.validateDepositAmount();
                });
            }
        });
    };

    loadMerchantBank = () => {
        let merchantBankList = this.props.merchantBankList;
        // window.SPL_Transaction.loadMerchantBank().then((merchantBankList) => {
        if (this.isMount) {
            this.setState({ merchantBankList: merchantBankList, selectedDepositBank: merchantBankList[this.state.radioValue] });
        }
        // });
    };

    loadBankAccounts = (callback) => {
        window.SPL_Transaction.loadAllOwnedAndUnOwnedBank().then((data) => {
            let memberBankList = data.memberBankList;
            for (let i = 0; i < memberBankList.length; i++) {
                const memberBank = memberBankList[i];
                memberBank.displayName = `${memberBank.bankName} ${memberBank.accountNumber} ${memberBank.accountHolder}`;
            }
            if (this.isMount) {
                this.setState({ bankList: data.bankList, memberBankList: memberBankList, unOwnedBankList: data.unOwnedBankList }, () => {
                    if (callback) {
                        callback();
                    }
                });
            }
        });
    };

    handleAmountChange = (value) => {
        if (value) {
            if (value.toString().match(/^(\d*)\.{0,1}(\d){0,2}$/)) {
                this.setState({ amount: value }, () => {
                    this.validateDepositAmount();
                });
            }
        } else {
            this.setState({ amount: '' }, () => {
                this.validateDepositAmount();
            });
        }
    };

    checkError = () => {
        const { activeTab, amountErrMsg, selectedMethod, selectedBank } = this.state;
        if (activeTab === 'quickpay') {
            if (amountErrMsg.length > 0 || selectedMethod.length <= 0 || selectedBank.length <= 0) {
                this.setState({ disabledDepositButton: true });
            } else {
                this.setState({ disabledDepositButton: false });
            }
        } else {
            const { amount, depositDateString, selectedDepositChannel, selectedDepositBank, receiptImg, selectedBank, referenceCode } = this.state;

            if (
                !amount ||
                selectedDepositChannel.length <= 0 ||
                (selectedBank.length <= 0 && selectedDepositChannel[0].value !== 'D') ||
                !selectedDepositBank ||
                (receiptImg.length <= 0 && selectedDepositChannel[0].value === 'O') ||
                (receiptImg.length <= 0 && selectedDepositChannel[0].value === 'D') ||
                (receiptImg.length <= 0 && selectedDepositChannel[0].value === 'A') ||
                !depositDateString
            ) {
                this.setState({ disabledDepositButton: true });
            } else {
                this.setState({ disabledDepositButton: false });
            }
        }
    };

    handleSubmit(type) {
      const { depositSubmission, t } = this.props;
      const { filteredDepositApiJson, depositSubmitCountdown } = this.state;
      const paymentCode = filteredDepositApiJson && filteredDepositApiJson.optionCode;
      const submissionData = depositSubmission && depositSubmission.submissionData;
      const paymentObj = submissionData && submissionData[paymentCode];

      if (paymentObj && paymentObj.submissionRetryTime > Date.now().valueOf()) {
          notification.showNotification('error', t('transaction:transaction.deposit.resubmitError', {retryTime: depositSubmitCountdown}));
      } else {
        if (type === 'quickpay') {
          this.quickPaySubmit(paymentCode);
        } else {
          this.localPaySubmit();
        }
      }
    }

    validateDepositAmount = () => {
        window.SPL_Transaction.validateDepositAmount(this.state.amount, this.state.depositDetails.depositLimit).then((errMsg) => {
            if (this.isMount) {
                let errMsgLimitAmount = this.getLimitAmountForErrMsg(errMsg[0]);
                this.setState({ amountErrMsg: errMsg, limitAmount: errMsgLimitAmount.limitAmount, currency: errMsgLimitAmount.currency }, () => {
                    this.checkError();
                });
            }
        });
    };

    getLimitAmountForErrMsg(errMsg) {
        let limitAmount = '';
        let currency = this.props.user.account.currency;
        if (errMsg) {
            if (errMsg.includes('invalidMinAmount')) {
                if (this.state.activeTab === 'quickPay') {
                    limitAmount = window.SPL_Other.formatAmount(this.state.depositDetails.depositLimit.minLimitQuick);
                } else {
                    limitAmount = window.SPL_Other.formatAmount(this.state.depositDetails.depositLimit.minLimit);
                }
            } else if (errMsg.includes('invalidMaxAmount')) {
                if (this.state.activeTab === 'quickPay') {
                    limitAmount = window.SPL_Other.formatAmount(this.state.depositDetails.depositLimit.maxLimitQuick);
                } else {
                    limitAmount = window.SPL_Other.formatAmount(this.state.depositDetails.depositLimit.maxLimit);
                }
            }
        }

        return {
            limitAmount: limitAmount,
            currency: currency,
        };
    }

    quickPaySubmit = (paymentCode) => {
        if (this.state.amountErrMsg.length > 0 || this.state.selectedMethod.length <= 0 || this.state.selectedBank.length <= 0) {
            return;
        }
        let method = this.state.selectedMethod[0];
        let selectedBankCode = this.state.selectedBank[0].code;
        let amount = this.state.amount;
        let preferredWallet = this.state.depositDetails.preferredWalletDetails.preferredWallet;
        let hidePWallet = this.state.depositDetails.preferredWalletDetails.hidePWallet;
        let callBackUrl = '/myaccount/deposit';

        let stateUrl = `/${this.props.langPath}/quickpayredirect`;
        // window.open(stateUrl);
        window.SPL_Transaction.requestDepositOnlineTransfer(
            method,
            selectedBankCode,
            amount,
            preferredWallet,
            hidePWallet,
            stateUrl,
            this.props.language.key,
            callBackUrl
        ).then((data) => {
          if(data.errorCode){
            notification.showNotification('error', (data.message));
          } else{
            trans_handlePGDepositCountDown(this, true, 60, paymentCode, Date.now().valueOf(), { disabledDepositButton: false });
          }
        });
    };

    localPaySubmit = () => {
        const { preferredWalletDetails } = this.state.depositDetails;
        const { amount, depositDateString, selectedDepositChannel, selectedDepositBank, receiptImgFile, receiptImg, referenceCode, selectedBank } = this.state;

        if (
            !amount ||
            selectedDepositChannel.length <= 0 ||
            (selectedBank.length <= 0 && selectedDepositChannel[0].value !== 'D') ||
            !selectedDepositBank ||
            (receiptImg.length <= 0 && selectedDepositChannel[0].value === 'O') ||
            (receiptImg.length <= 0 && selectedDepositChannel[0].value === 'D') ||
            (receiptImg.length <= 0 && selectedDepositChannel[0].value === 'A') ||
            !depositDateString
        ) {
            return;
        }
        let preferredWallet = preferredWalletDetails.preferredWallet;
        let hidePWallet = preferredWalletDetails.hidePWallet;
        let depositAmount = amount;
        let depositChannel = selectedDepositChannel[0].value;
        let depositDateTime = depositDateString;
        let merchantBankId = selectedDepositBank.id;
        let memberBankId = (selectedDepositChannel[0].value !== 'D' && selectedBank[0].id) || null;
        let picFile = receiptImgFile;

        // preferredWallet, hidePWallet, merchantBankId, depositAmount, depositChannel, depositDateTime, memberBankId, picFile, referenceCode
        window.SPL_Transaction.localPaySubmit(
            preferredWallet,
            hidePWallet,
            merchantBankId,
            depositAmount,
            depositChannel,
            depositDateTime,
            memberBankId,
            picFile,
            referenceCode
        ).then((data) => {
            if (this.isMount) {
                if (data.status === 'S') {
                    this.setState({ disabledDepositButton: false });

                    let depositAmount = '';
                    let depositChannel = [];
                    let receiptImgFile = null;
                    let referenceCode = '';
                    let selectedBank = [];
                    this.setState(
                        {
                            status: 'S',
                            message: 'transaction:transaction.deposit.request-success',
                            amount: depositAmount,
                            selectedDepositChannel: depositChannel,
                            receiptImgFile: receiptImgFile,
                            referenceCode: referenceCode,
                            selectedBank: selectedBank,
                            receiptImg: '',
                        },
                        () => {
                            let status = this.state.status;
                            if (status === 'S') {
                                setTimeout(() => {
                                    this.setState({ message: '' });
                                }, 5000);
                            }
                        }
                    );
                } else {
                    this.setState({ disabledDepositButton: false });

                    if (data.code === 'receipt_fail') {
                        this.setState({ status: 'F', message: 'transaction:transaction.withdrawal.request-uploadReceiptError' });
                    } else if (data.msg.data.status === 409) {
                        this.setState({ status: 'F', message: 'transaction:transaction.withdrawal.request-pending' });
                    } else {
                        this.setState({ status: 'F', message: 'transaction:transaction.withdrawal.request-error' });
                    }
                }
            }
        });
    };

    copy(text) {
        window.SPL_Other.copyToClipboard(text);
    }

    openBankDetailModal = () => {
        this.setState({ isOpenBankModal: true });
    };

    selectAddedBank = (bankId) => {
        this.loadBankAccounts(() => {
            let memberBankList = this.state.memberBankList;
            let autoSelectedAddedBank = memberBankList.filter((e) => e.bankId === bankId);
            this.setState({ selectedBank: autoSelectedAddedBank });
        });
    };

    closeBankModal = () => {
        this.setState({ isOpenBankModal: false });
    };
    handleChange = (moment) => {
        this.setState({
            moment,
        });
    };

    onDateChanged = (date, type) => {
        if (type === 'start') {
            this.setState({ start: date });
        } else {
            this.setState({ end: date });
        }
    };

    showDetails = (type, history) => {
        this.setState({ type: type, historySelected: history, isOpenHistoryDetailsModal: true });
    };

    loadHistories = () => {
        let params = {
            start: new Date(new Date(this.state.start).setHours(0, 0, 0)),
            end: new Date(new Date(this.state.end).setHours(23, 59, 59)),
        };
        window.SPL_Transaction.loadHistories('depositWithdraw', params).then((histories) => {
            if (this.isMount) {
                let historiess = [
                    {
                        id: 1,
                        from: 'GD',
                        to: 'SA',
                        createdDate: '2020-03-03',
                        currency: 'MYR',
                        resultClass: 'text-success',
                        result: 'transaction:transaction.status.success',
                        amount: 50,
                    },
                    {
                        id: 2,
                        from: 'GD',
                        to: 'Haha',
                        createdDate: '2020-03-04',
                        currency: 'MYR',
                        resultClass: 'text-success',
                        result: 'transaction:transaction.status.success',
                        amount: 40,
                    },
                    {
                        id: 3,
                        from: 'GD',
                        to: 'hehe',
                        createdDate: '2020-03-05',
                        currency: 'MYR',
                        resultClass: 'text-success',
                        result: 'transaction:transaction.status.success',
                        amount: 30,
                    },
                    {
                        id: 4,
                        from: 'GD',
                        to: 'huhu',
                        createdDate: '2020-03-06',
                        currency: 'MYR',
                        resultClass: 'text-success',
                        result: 'transaction:transaction.status.success',
                        amount: 10,
                    },
                    {
                        id: 5,
                        from: 'GD',
                        to: 'wwwwhhheeennn',
                        createdDate: '2020-03-07',
                        currency: 'MYR',
                        resultClass: 'text-success',
                        result: 'transaction:transaction.status.success',
                        amount: 500,
                    },
                    {
                        id: 6,
                        from: 'GD',
                        to: 'wwwwwhhhhyyyyy',
                        createdDate: '2020-03-08',
                        currency: 'MYR',
                        resultClass: 'text-success',
                        result: 'transaction:transaction.status.success',
                        amount: 600,
                    },
                    {
                        id: 7,
                        from: 'GD',
                        to: 'hhhooowww',
                        createdDate: '2020-03-09',
                        currency: 'MYR',
                        resultClass: 'text-success',
                        result: 'transaction:transaction.status.success',
                        amount: 1000,
                    },
                    {
                        id: 8,
                        from: 'GD',
                        to: 'gdgddgd',
                        createdDate: '2020-03-10',
                        currency: 'MYR',
                        resultClass: 'text-success',
                        result: 'transaction:transaction.status.success',
                        amount: 50,
                    },
                    {
                        id: 9,
                        from: 'GD',
                        to: 'gdgdgd',
                        createdDate: '2020-03-11',
                        currency: 'MYR',
                        resultClass: 'text-success',
                        result: 'transaction:transaction.status.success',
                        amount: 50,
                    },
                ];
                this.setState({ histories: histories.histories });
            }
        });
    };

    handleClick = (event) => {
        this.setState({
            currentPage: Number(event.target.id),
            currentPage1: Number(event.target.id),
            currentPage2: Number(event.target.id),
            currentPage3: Number(event.target.id),
            currentPage4: Number(event.target.id),
        });
    };

    backwards = () => {
        let check = this.state.currentPage;
        if (check > 1 && check !== 1) {
            this.setState({
                currentPage1: check - 1,
            });
        }
    };

    forwards = (e) => {
        let check = this.state.currentPage;
        if (e > check && e !== check) {
            this.setState({
                currentPage1: check + 1,
            });
        }
    };

    toggleLoading = (toggle) => {
        this.setState({ showLoading: toggle });
    };

    loadBankAccountsForDeposit = () => {
        window.SPL_Transaction.loadAllOwnedAndUnOwnedBank()
            .then((data) => {
                if (this.isMount) {
                    this.setState({
                        unOwnedBankList: data.unOwnedBankList,
                    });
                }
                this.toggleLoading(false);
            })
            .catch((err) => {
                this.toggleLoading(false);
            });
    };

    closeBankModal = () => {
        this.setState({ isOpenBankModal: false });
    };

    render() {
        const shortcuts = {
            Today: moment(),
            Yesterday: moment().subtract(1, 'days'),
            Clear: '',
        };
        const { depositDetails } = this.state;

        let { currentPage1, todosPerPage1 } = this.state;
        let indexOfLastTodo1 = currentPage1 * todosPerPage1;
        let indexOfFirstTodo1 = indexOfLastTodo1 - todosPerPage1;
        let currentTodos1 = this.state.histories.slice(indexOfFirstTodo1, indexOfLastTodo1);

        let renderTodos1 = currentTodos1.map((hist, index) => {
            return (
                <Translation>
                    {(t) => (
                        <Row
                            className='w-100 d-flex history-detail-row'
                            key={(hist, index)}
                            onClick={() => {
                                this.showDetails('depositWithdraw', hist);
                            }}
                        >
                            <Col className='d-flex p-l-0 p-r-0'>
                                <FiArrowDownRight className='icon-sm'></FiArrowDownRight>
                            </Col>

                            <Col xs='5' sm='5' className='p-l-0'>
                                <div className='table-title'>{t(hist.transType)}</div>
                                <div className='table-date'>{moment(hist.date).format('YYYY-MM-DD HH:mm').toString()}</div>
                            </Col>
                            <Col xs='4' sm='4' className='p-l-0 p-r-0 d-flex align-items-center'>
                                <div className='no-wrap h5'>{`${hist.currency} ${window.SPL_Other.formatAmount(hist.amount)}`}</div>
                            </Col>
                            <Col xs='2' sm='2' className='h5 p-l-0 p-r-0 d-flex justify-content-end align-items-center'>
                                <div className={hist.resultClass}>{t(hist.status)}</div>
                            </Col>
                        </Row>
                    )}
                </Translation>
            );
        });
        let pageNumbers1 = [];
        for (let i = 1; i <= Math.ceil(this.state.histories.length / todosPerPage1); i++) {
            pageNumbers1.push(i);
        }

        return (
            this.props.user.account && (
                <Translation>
                    {(t) => (
                        <div className='app-deposit'>
                            <div className='row d-none d-md-flex justify-content-center'>
                                <div className='account-col'>
                                    <div className='method'>
                                        <div className=''></div>
                                    </div>
                                    <div className='deposit-tab-container'></div>
                                    <Nav tabs className='deposit-tab'>
                                        {depositDetails && depositDetails.defaultMethod && (
                                            <NavItem
                                                className='deposit-tab-click m-l-3 m-r-3'
                                                onClick={() => {
                                                    this.toggle('quickPay');
                                                }}
                                            >
                                                <NavLink
                                                    className={classnames({
                                                        active: this.state.activeTab === 'quickPay',
                                                        navlinkForm: true,
                                                    })}
                                                >
                                                    <div className='deposit-quickpay'>{t('settings:settings.deposittab.quickPay').toLowerCase()}</div>
                                                </NavLink>
                                            </NavItem>
                                        )}
                                        <NavItem
                                            className='deposit-tab-click m-l-3 m-r-3'
                                            onClick={() => {
                                                this.toggle('onlinetransfer');
                                                this.loadBankAccounts();
                                            }}
                                        >
                                            <NavLink
                                                className={classnames({
                                                    active: this.state.activeTab === 'onlinetransfer',
                                                    navlinkForm: true,
                                                })}
                                            >
                                                <div className='deposit-banktransfer'>{t('settings:settings.deposittab.onlinetransfer').toLowerCase()}</div>
                                            </NavLink>
                                        </NavItem>
                                    </Nav>

                                    <TabContent activeTab={this.state.activeTab} className='deposit-tab-content'>
                                        {this.state.isLoading && <SmallLoading></SmallLoading>}
                                        {this.state.activeTab === 'quickPay' && depositDetails && (
                                            <TabPane tabId='quickPay' className='d-flex form-content'>
                                                {depositDetails && depositDetails.defaultMethod && <QuickPay />}
                                                {!depositDetails.defaultMethod && <div className='coming-soon'>Coming Soon...</div>}
                                            </TabPane>
                                        )}
                                        {/* Online Trans */}
                                        {this.state.activeTab === 'onlinetransfer' && depositDetails && (
                                            <TabPane tabId='onlinetransfer' className='d-flex form-content'>
                                                {this.props.screen.viewType === 'web' && <OnlineTransfer />}
                                            </TabPane>
                                        )}

                                        {/* {!this.state.depositDetails && <Loading className='vh-50'></Loading>}s */}
                                    </TabContent>
                                </div>
                            </div>

                            {/* Deposit Mobile View */}
                            <div className='d-block d-md-none content'>
                                <div className='row d-flex justify-content-center content-inner'>
                                    <Nav tabs className='deposit-tab-mobile '>
                                        {depositDetails && depositDetails.defaultMethod && (
                                            <NavItem
                                                className='deposit-tab-click-mobile'
                                                onClick={() => {
                                                    this.toggle('quickPay');
                                                }}
                                            >
                                                <NavLink
                                                    className={classnames({
                                                        active: this.state.activeTab === 'quickPay',
                                                        navlinkForm: true,
                                                    })}
                                                >
                                                    <div className='deposit-quickpay'>{t('settings:settings.deposittab.quickPay').toLowerCase()}</div>
                                                </NavLink>
                                            </NavItem>
                                        )}
                                        <NavItem
                                            className='deposit-tab-click-mobile'
                                            onClick={() => {
                                                this.toggle('onlinetransfer');
                                            }}
                                        >
                                            <NavLink
                                                className={classnames({
                                                    active: this.state.activeTab === 'onlinetransfer',
                                                    navlinkForm: true,
                                                })}
                                            >
                                                <div className='deposit-banktransfer'>{t('settings:settings.deposittab.onlinetransfer').toLowerCase()}</div>
                                            </NavLink>
                                        </NavItem>
                                    </Nav>
                                    {this.state.isLoading && <SmallLoading></SmallLoading>}
                                    <TabContent activeTab={this.state.activeTab}>
                                        {this.state.activeTab === 'quickPay' && depositDetails && (
                                            <TabPane tabId='quickPay' className='mobile-account-width'>
                                                {depositDetails && depositDetails.defaultMethod && <QuickPay></QuickPay>}
                                                {!depositDetails.defaultMethod && <div className='coming-soon'>Coming Soon...</div>}
                                            </TabPane>
                                        )}
                                        {/* Online Trans */}
                                        {this.state.activeTab === 'onlinetransfer' && depositDetails && (
                                            <TabPane tabId='onlinetransfer' className='mobile-account-width'>
                                                {this.props.screen.viewType === 'mobile' && <OnlineTransfer />}
                                            </TabPane>
                                        )}
                                    </TabContent>
                                </div>
                            </div>
                            <div></div>
                        </div>
                    )}
                </Translation>
            )
        );
    }
}

export default connect(mapStateToProps)(withTranslation(['transaction', 'settings', 'bank'])(withRouter(Deposit)));
