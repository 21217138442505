import React from 'react';
import { CSSTransition } from 'react-transition-group';
import { connect } from 'react-redux';
import { mapStateToProps } from '../../redux/selector/selector';
import { withRouter } from 'react-router-dom';
import { Translation, withTranslation } from 'react-i18next';
import { languageAction } from '../../redux/action/language.action';
import SVGElements from 'root/components/SVGElements';
import { LazyLoadImage } from 'react-lazy-load-image-component';

class Language extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            in: false,
            modal: false,
            isTrans: 'my-en',
            activeId: 'my-en',
            languageList: [],
            arrowRotate: false,
        };
        this.languageClicked = this.languageClicked.bind(this);
    }

    componentDidMount() {
        this.initEvent();
        this.getLanguageList();
    }

    onChangeLanguage = (lang) => {
        const { portal, history, i18n, dispatch } = this.props;

        let languageDomain = portal.settings.languageDomains;
        if (languageDomain) {
            let urlParam = languageDomain[lang.countryLanguageKey];
            let langKey = lang.countryLanguageKey.replace('_', '-').toLowerCase();
            let path = history.location.pathname;
            let jwt = window.SPL_Cookie.getCookie('t');

            window.location.href = urlParam + '?langCode=' + langKey + '&path=' + path + '&t=' + jwt;
        } else {
            i18n.changeLanguage(lang.key);
            dispatch(languageAction.changeLanguage(lang));
            this.changeUrl(lang.countryLanguageKey);
        }
    };

    changeUrl = (countryLanguageKey) => {
        const { history } = this.props;
        let path = countryLanguageKey.replace('_', '-').toLowerCase();
        if (history && history.location && history.location.pathname) {
            window.location.pathname = `/${path}` + history.location.pathname;
        } else {
            window.location.pathname = `/${path}`;
        }
    };

    getLanguageList = () => {
        window.SPL_Content.getLanguageList().then((data) => {
            let languageList = data;

            const { user } = this.props;
            if (user && user.account) {
                for (let i = 0, l = data.length; i < l; i++) {
                    const currentLanguage = data[i];
                    if (currentLanguage.currency === user.account.currency) {
                        languageList = [currentLanguage];
                        break;
                    }
                }
            }
            this.setState({ languageList });
        });
    };

    languageClicked(e) {
        let key = e.currentTarget.getAttribute('data-key');
        this.setState({ modal: !this.state.modal, isTrans: key, activeId: key });
    }

    initEvent() {
        document.querySelector('#root').addEventListener('click', (e) => {
            if (this.state.in) {
                let stopEventEl = [document.querySelector('.lan-list-box'), document.querySelector('.current-lan-box')];
                for (let index = 0; index < stopEventEl.length; index++) {
                    let child = stopEventEl[index];
                    child = child && child.$el ? child.$el : child;
                    if (child && child.contains(e.target)) return;
                }

                this.onShow();
            }
        });
    }

    onShow() {
        this.setState((state) => ({
            in: !state.in,
        }));
    }

    render() {
        const { language } = this.props;
        const { languageList } = this.state;

        return (
            <Translation>
                {(t) => (
                    <div className='lan-select-box'>
                        <LazyLoadImage className='icon-flag' loading="lazy" src={language.src} alt={language?.country + '-flag'} />
                        <span className='current-lan-box' onClick={() => this.onShow()}>
                            <span className='current-lan'>{language.display}</span>
                            <CSSTransition classNames='rotate180' in={this.state.in} timeout={300}>
                                <SVGElements className='icon-arrow-down' name='arrow-down-icon' />
                            </CSSTransition>
                        </span>

                        <CSSTransition classNames='spread' in={this.state.in} timeout={300} unmountOnExit>
                            <div className='lan-list-box'>
                                <ul>
                                    {languageList.map((languageObj) => (
                                        <li key={languageObj.countryName} className='lang-choice'>
                                            <LazyLoadImage className='icon-flag' loading="lazy" src={languageObj.languages[0].src}  alt={languageObj.countryName + '-flag'} />
                                            {languageObj.languages.map((lang) => (
                                                <div key={lang.key}>
                                                    <span
                                                        key={lang.countryLanguageKey}
                                                        className={`language-select ${language.countryLanguageKey === lang.countryLanguageKey && 'is-active'}`}
                                                        data-key='my-en'
                                                        onClick={() => {
                                                            this.onChangeLanguage(lang);
                                                        }}
                                                    >
                                                        {lang.display}
                                                    </span>
                                                    <span className='divide'>/</span>
                                                </div>
                                            ))}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </CSSTransition>
                    </div>
                )}
            </Translation>
        );
    }
}

export default connect(mapStateToProps)(withTranslation('member')(withRouter(Language)));
