import React from 'react';
import { CSSTransition } from 'react-transition-group';
import { Translation, withTranslation, Trans } from 'react-i18next';
import { connect } from 'react-redux';
import { mapStateToProps } from '../../redux/selector/selector';
import { withRouter } from 'react-router-dom';
import Login from './Login';
import Register from './Register';
import Forgot from './Forgot';
import Complete from './Complete';
import { isMobile, isAndroid } from 'react-device-detect';
import SVGElements from 'root/components/SVGElements';
import commonUtil from '../../utils/common-util';
import { gu_getSettingFromPortalSettings } from 'root/utils/general-util';
import { MODULE_TYPE as MT } from '@constants';

class LoginPanel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tag: '',
            in: false,
            windowWidth: null,
            windowHeight: null,
            contactList: {},
        };
    }

    componentDidMount() {
        this.initCompSettings();
        if (this.props.history.location.pathname === '/register') {
            this.setState({ tag: 'register' });
        } else if (this.props.history.location.pathname === '/login') {
            this.setState({ tag: 'login' });
        } else if (this.props.history.location.pathname === '/resetpassword') {
            this.setState({ tag: 'forgot' });
        } else if (this.props.history.location.pathname === '/registersuccess') {
            this.setState({ tag: 'complete' });
        }
        this.setSize();
        this.getContact();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.count !== this.props.count || prevProps.tag !== this.props.tag || prevProps.show !== this.props.show) {
            this.update();
        }

        if (this.state.tag !== 'login' && this.props.history.location.pathname === '/login') {
            this.setState({ tag: 'login' });
        } else if (this.state.tag !== 'register' && this.props.history.location.pathname === '/register') {
            this.setState({ tag: 'register' });
        } else if (this.state.tag !== 'forgot' && this.props.history.location.pathname === '/resetpassword') {
            this.setState({ tag: 'forgot' });
        } else if (this.state.tag !== 'complete' && this.props.history.location.pathname === '/registersuccess') {
            this.setState({ tag: 'complete' });
        }

        if (this.state.windowWidth) {
            window.addEventListener('resize', this.setSize);
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.setSize);
    }

    initCompSettings = () => {
        return new Promise((resolve) => {
            // Step 1: Standard way to get from portal settings
            let enableCNYTheme = gu_getSettingFromPortalSettings({
                vm: this,
                settingName: 'enableCNYTheme',
            });
            // Step 2: Massage again to get the specific data you need (Skip if not needed)

            // Step 3: Initial all into compSettings
            const { language } = this.props;
            let compSettings = {
                enableCNYTheme: Object.keys(enableCNYTheme).length > 0 && enableCNYTheme?.includes(language.currencyLang),
            };
            // Use setState callback to ensure the state has been updated before logging
            this.setState({ compSettings: compSettings }, () => {
                resolve();
            });
        });
    };

    setSize = () => {
        this.setState({ windowWidth: window.innerWidth, windowHeight: window.innerHeight });
    };

    update = () => {
        this.setState({ tag: this.props.tag, in: this.props.show });
    };

    show = (con) => {
        localStorage.setItem('loginPanel', false);
        this.setState({ in: con });
    };

    setForgotTag = () => {
        this.props.history.push('/resetpassword');
        this.setState({ tag: 'forgot' });
    };

    setRegisterTag = () => {
        this.props.history.push('/register');
        this.setState({ tag: 'register' });
    };

    setCompleteTag = () => {
        this.props.history.push('/registersuccess');
        this.setState({ tag: 'complete' });
    };

    getContact() {
        const { language } = this.props;
        let excludeList = ['Facebook', 'Instagram', 'Youtube', 'Twitter', 'WeChat', 'WhatsApp', 'Phone'];
        window.SPL_Content.getContact(language.countryLanguageKey, 'mobile', excludeList).then((data) => {
            const filteredData = data.filter((e) => e.name === 'LiveChat');
            this.setState({ contactList: filteredData });
        });
    }

    customBannerImage = () => {
        const { language } = this.props;
        const { compSettings } = this.state;
        const bannerImages = `/public/html/images/login/MAPLE-RegisterPage-Banner-${compSettings?.enableCNYTheme ? 'cny' : language.key}.webp`;

        return bannerImages;
    };

    render() {
        const pages = [
            { tag: 'login', Component: Login },
            { tag: 'forgot', Component: Forgot },
            { tag: 'register', Component: Register },
            { tag: 'complete', Component: Complete },
        ];

        let accountFunction = {
            setForgotTag: this.setForgotTag,
            setRegisterTag: this.setRegisterTag,
            setCompleteTag: this.setCompleteTag,
        };

        return (
            <Translation>
                {(t) => (
                    <CSSTransition
                        classNames='slide-right'
                        in={['/register', '/login', '/resetpassword', '/registersuccess'].includes(this.props.history.location.pathname)}
                        timeout={300}
                        unmountOnExit
                    >
                        <div className='account-box'>
                            {(isMobile || isAndroid) && (window.orientation == 90 || window.orientation == -90) && (
                                <div className='messaging-popup rotate-alert'>
                                    <div className='popup'>
                                        <div className='popup-body'>
                                            <img src='/public/html/images/home_img/phone-rotate.png' />
                                            <span>{t('login:login.rotateDevice', 'Please rotate your device for Registration or Login purpose only.')}</span>
                                        </div>
                                    </div>
                                </div>
                            )}

                            <div className={`account-content ${commonUtil.getIsZT828LiteApp() ? 'account-content-liteapp' : ''}`}>
                                <div className='account-box-top'>
                                    <div className='tabs-box'>
                                        <span
                                            className={'btn-skew ' + (this.state.tag === 'register' || this.state.tag === 'complete' ? 'on' : '')}
                                            onClick={this.setRegisterTag}
                                        >
                                            <span>{t('register:register.createAccount')}</span>
                                        </span>
                                        <span
                                            className={'btn-skew ' + (this.state.tag === 'login' || this.state.tag === 'forgot' ? 'on' : '')}
                                            onClick={() => {
                                                this.props.history.push('/login');
                                                this.setState({ tag: 'login' });
                                            }}
                                        >
                                            <span>{t('global:global.menu.account.login')}</span>
                                        </span>
                                    </div>
                                    <SVGElements
                                        className='icon-close'
                                        name='close-icon'
                                        onClick={() => {
                                            this.props.history.push('/home');
                                            this.show(false);
                                        }}
                                    />
                                </div>
                                <div className='account-main-content'>
                                    <div className='account-box-banner'>
                                        <figure className='banner-image'>
                                            <img src={this.customBannerImage()} alt='login banner' width="100%" height="100%" />
                                        </figure>
                                    </div>
                                    {pages.map(({ tag, Component }) => (
                                        <CSSTransition key={tag} classNames='page' in={this.state.tag === tag} timeout={300} unmountOnExit>
                                            <div className='page'>
                                                <Component
                                                    count={this.props.count}
                                                    show={this.state.in}
                                                    tag={this.state.tag}
                                                    accountFunction={accountFunction}
                                                    brandLogoObj={this.props.brandLogoObj}
                                                />

                                                {commonUtil.getIsZT828LiteApp() && (
                                                    <div className='account-box-footer'>
                                                        <Trans i18nKey={'login:login.footerDesc1'} />
                                                        <p>
                                                            <span
                                                                onClick={() => {
                                                                    window.open(
                                                                        this.state.contactList[0].url,
                                                                        '',
                                                                        'width=1025,height=700,toolbar=no,location=no,directories=no,status=yes,menubar=no,scrollbars=no'
                                                                    );
                                                                }}
                                                            >
                                                                {t('login:login.footerDescCustomerService')}
                                                            </span>
                                                            <Trans i18nKey={'login:login.footerDesc1-2'} />
                                                        </p>
                                                        <div
                                                            className='select-lang'
                                                            onClick={() => {
                                                                this.props.history.push('/language');
                                                                this.show(false);
                                                            }}
                                                        >
                                                            <SVGElements
                                                                className='language-icon'
                                                                name='language-icon'
                                                                onClick={() => {
                                                                    this.props.history.push('/home');
                                                                    this.show(false);
                                                                }}
                                                            />
                                                            {t('login:login.footerDesc2')}
                                                        </div>
                                                        <div>{t('login:login.footerDesc3')}</div>
                                                    </div>
                                                )}
                                            </div>
                                        </CSSTransition>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </CSSTransition>
                )}
            </Translation>
        );
    }
}

export default connect(mapStateToProps)(withTranslation('login')(withRouter(LoginPanel)));
