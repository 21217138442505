import React, { Component } from 'react';
import { Translation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Redirect, withRouter } from 'react-router-dom';
import { userAction } from '../../redux/action';
import { mapStateToProps } from '../../redux/selector/selector';
class ConnectedLogout extends Component {
  isMount = true;
  constructor() {
    super();
    this.state = {
      userName: '',
      pass: '',
      redirectToReferrer: false,
    };
  }

  handleLogout = (e) => {
    e.preventDefault();
    this.props.dispatch(userAction.logout());
    this.props.history.push('/home');
  };

  componentDidMount() {
    this.isMount = true;
    const { userName, password } = this.props.user;
    this.setState({ userName: userName, pass: password });
  }

  componentWillUnmount() {
    this.isMount = false;
  }

  render() {
    const { from } = this.props.location.state || { from: { pathname: '/' } };

    // If user was authenticated, redirect her to where she came from.
    if (this.state.redirectToReferrer === true) {
      return <Redirect to={from} />;
    }

    return (
      <Translation>
        {(t) => (
          <div className='w-100 '>
            <button
              href='!#'
              type='submit'
              className='button-logout button-35px button-header'
              //disabled={this.state.disabledDepositButton}
              onClick={this.handleLogout}>
              <span>{t('global:global.menu.account.logout')}</span>
            </button>
          </div>
        )}
      </Translation>
    );
  }
}

const Logout = withRouter(connect(mapStateToProps, null)(withTranslation('mobile')(ConnectedLogout)));

export default Logout;
