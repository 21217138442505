const APIClient = {
    // NOTE: DO NOT ADD ANYTHING THAN BACKEND API RELATED (THE ONE U CAN SEE IN INSPECTOR NETWORK TAB)
    createMemberCryptoAddress(params, onSuccess, onError, onFinal) {
        window.SPL_Transaction.createMemberCryptoAddress(params)
            .then((data) => {
                if (data) {
                    const { status, msg } = data;
                    if (status === 'S') {
                        onSuccess();
                    } else {
                        onError(msg);
                    }
                }
            })
            .finally(() => {
                onFinal && onFinal();
            });
    },
};

export default APIClient;
