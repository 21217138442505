import React from 'react';
import { useTranslation } from 'react-i18next';

// components
import PhoneOtpMethodSelectionStyle from './phone-otp-method-selection.module.scss';
import SelectDropdown from 'root/pages/Share/select-dropdown/select-dropdown';
import Option from 'root/pages/Share/select-dropdown/sub-component/option';

// logic/helper/

const PhoneOtpMethodSelection = ({ selectedLabel, preferMethod, phoneVerificationMethods, disabled }) => {
    const { t } = useTranslation('otp');

    return (
        <>
            <div className={`standard-form-field phone-otp-method-field ${PhoneOtpMethodSelectionStyle.phoneOtpMethodField}`}>
                <label className='standard-label'>{t(`otp:otp.field.verificationMethod.label`, 'Send OTP To')}</label>

                <SelectDropdown selectedLabel={selectedLabel} disabled={disabled}>
                    {(phoneVerificationMethods || []).map((method) => {
                        const { type, i18n, onClick } = method;
                        return <Option key={type} className={`${type === preferMethod ? 'active' : ''} `} optionLabel={t(i18n, type)} onClickFunction={onClick} />;
                    })}
                </SelectDropdown>
            </div>
        </>
    );
};

export default PhoneOtpMethodSelection;
