import React, { Component } from 'react';
import { Col, Row, Nav, NavLink, TabContent, TabPane } from 'reactstrap';
import { Translation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { mapStateToProps } from '../../redux/selector/selector';
import classnames from 'classnames';
import { Redirect, withRouter } from 'react-router-dom';
import { userAction } from '../../redux/action';
import { IoMdClose } from 'react-icons/io';
import { login_PanelTab } from '../01Home/012_Home_Content_Item_Data';
import {
  userIcon,
  lock
} from '../05Registration/053_Register_Content_Item_Data';
import MasterForm from '../05Registration/Register_Step/MasterForm';

class LoginPanel extends Component {
  isMount = true;
  constructor() {
    super();
    this.state = {
      userName: '',
      pass: '',
      redirectToReferrer: false,
      activeTab: 'registerTab',
    };
  }

  componentDidMount() {
    this.isMount = true;
    const { userName, password } = this.props.user;
    this.setState({ userName: userName, pass: password });
  }

  componentWillUnmount() {
    this.isMount = false;
  }

  handleLogin = (e) => {
    e.preventDefault();
    let loginObj = {
      domain: '',
      isAffiliate: false,
      isVipLogin: false,
      language: this.props.language.key,
      merchantCode: window.merchantCode,
      login: this.state.userName,
      password: this.state.pass,
      rememberMe: false,
    };

    this.props.dispatch(userAction.login(loginObj)).then(() => {
      this.props.dispatch(userAction.isAuthenticated()).then(() => {
        this.props.history.push('/myaccount/deposit');
      });
      window.SPL_FingerPrint.checkingFp();
    });
  };

  keyHandleLogin = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      let loginObj = {
        domain: '',
        isAffiliate: false,
        isVipLogin: false,
        language: this.props.language.key,
        merchantCode: window.merchantCode,
        login: this.state.userName,
        password: this.state.pass,
        rememberMe: false,
      };

      this.props.dispatch(userAction.login(loginObj)).then(() => {
        this.props.dispatch(userAction.isAuthenticated()).then(() => {
          this.props.history.push('/myaccount/deposit');
        });
        window.SPL_FingerPrint.checkingFp();
      });
    }
  };

  navigate(path) {
    this.props.history.push(path);
  }

  render() {
    const { from } = this.props.location.state || {
      from: { pathname: '/' },
    };

    // If user was authenticated, redirect her to where she came from.
    if (this.state.redirectToReferrer === true) {
      return <Redirect to={from} />;
    }

    return (
      <Translation>
        {(t) => (
          <div className='loginPanel-box'>
            <Nav defaulttab = {this.state.itemIdOnclick} className='w-100 align-items-start justify-content-center'>
              {login_PanelTab.map((tab) => {
                return(
                  <div className={classnames({ active: this.props.activeTab === tab.id, titleTab: true, })} 
                      key={tab.id} onClick={() => {this.props.toggle(tab.id);}} >
                    <NavLink className={classnames({ 
                      active: this.props.activeTab === tab.id,
                      tabLink: true,
                      })} >
                      <div className='h-auto align-items-center justify-content-center align-self-center'>
                        {t(tab.name)}
                      </div>
                    </NavLink>
                  </div>
                );
              })}
              <IoMdClose className='pointer loginPanel-close'
                onClick={this.props.setOpenClose} />
            </Nav>

            <div className='loginPanel-content'>
              <TabContent activeTab={this.props.activeTab} className=''>
                {this.props.activeTab === 'registerTab' && (
                  <TabPane tabId='registerTab' className='h-auto registerTab'>
                    <MasterForm />
                  </TabPane>
                )}
                {this.props.activeTab === 'loginTab' && (
                  <TabPane tabId='loginTab' className='h-auto loginTab'>
                    <div className='loginTab-input'>
                      <p>{t('global:global.form.username-placeholder')} <span>*</span></p>
                      <div class="input-box">
                        <div class="icon-box">
                          <img class="icon-username" src={userIcon} />
                        </div>
                        <input className='form-control-header'
                          value={this.state.userName}
                          onChange={(e) => {
                            this.setState({ userName: e.target.value });
                          }}
                          onKeyPress={this.keyHandleLogin}
                        />
                      </div>
                      <span>{t('global:global.menu.account.enterUsername')}</span>
                    </div>

                    <div className='loginTab-input'>
                      <p>{t('global:global.form.newpassword-placeholder')} <span>*</span></p>
                      <div class="input-box">
                        <div class="icon-box">
                          <img class="icon-username" src={lock} />
                        </div>
                        <input className='form-control-header'
                          value={this.state.pass}
                          type='password'
                          onChange={(e) => {
                            this.setState({ pass: e.target.value });
                          }}
                          onKeyPress={this.keyHandleLogin}
                        />
                      </div>
                    </div>

                    <button className='button-login button-35px d-flex justify-content-center align-items-center button-header'
                      onClick={this.handleLogin}>
                      <span>{t('global:global.menu.account.login')}</span>
                    </button>

                    <div className='d-flex text-underline pointer' onClick={() => this.navigate('/resetpassword')}>
                      <span>
                        {t('global:global.menu.account.forgot')}&nbsp;
                        {t('global:global.menu.account.usernameSmall')}&nbsp;
                        {t('global:global.or')}&nbsp;
                        {t('global:global.menu.account.passwordSmall')}&nbsp;?
                      </span>
                    </div>
                  </TabPane>
                )}
              </TabContent>
            </div>
          </div>
        )}
      </Translation>
    )
  }
}

const SideLoginPanel = withRouter(connect(mapStateToProps, null)(withTranslation('global')(LoginPanel)));

export default SideLoginPanel;