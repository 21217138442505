import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from "react-redux";
import { userAction } from '../../../redux/action';
import { mapStateToProps } from "../../../redux/selector/selector";
import { Trans, Translation, withTranslation } from 'react-i18next';
import { Container, Row, Col } from 'reactstrap';

class LoginFailSection extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
		};
	}

	componentDidMount() {
		this.isMount = true;
	}

	componentWillUnmount() {
		this.isMount = false;
	}

	componentDidUpdate(prevProps) {
		if (this.props.location.pathname !== prevProps.location.pathname) {
			this.props.dispatch(userAction.clearResetPassword());
		}
	}

	render() {
		return (
			<Translation>
				{t => (
					<div className='d-none d-md-block'>
						<div className=''>
							<Container>
								{this.props.user.showResetPassword && (
									<Row className='error text-danger z1'>
										<Col xs='1' sm='1'>
											<div className='f-modal-alert'>
												<div className='f-modal-icon f-modal-warning scaleWarning'>
													<span className='f-modal-body pulseWarningIns'></span>
													<span className='f-modal-dot pulseWarningIns'></span>
												</div>
											</div>
										</Col>
										<Col xs='11' sm='11' className='d-flex align-items-center'>
											<div className='text-danger'>
												<Trans i18nKey={'global:error.authentication'}></Trans>
											</div>
											<div className='p-l-5 '>
												<Link to='/resetpassword' className='secondary-btn button-35px reset-button'>
													<span>{t('home:home.mobile.reset-password', 'Reset Password')}</span>
												</Link>
											</div>
										</Col>
									</Row>
								)}
							</Container>
						</div>
					</div>
				)}
			</Translation>
		);
	}

}

export default connect(mapStateToProps)(withTranslation('mobile','home')(withRouter(LoginFailSection)));