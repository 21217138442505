import React, { useEffect, useState } from 'react';
import { Translation, withTranslation } from 'react-i18next';
import { AiOutlineLeft, AiOutlineMenu } from 'react-icons/ai';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { connect, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import LoginDesktop from '../../../../pages/02Login/021_LoginDesktop';
import Logout from '../../../../pages/02Login/021_Logout';
import { mapStateToProps } from '../../../../redux/selector/selector';
import UserName from '../GetLoginUsername';
import HeaderMenu from '../HeaderMenu';
import Setting from '../GetLoginSetting';
import LanguageModal from '../LanguageModal';
import LoginFailSection from '../LoginFailSection';
import MobileFooterAfterLogin from '../MobileFooterAfterLogin';
import NavigationAnnounceModal from '../Navigation_Announce_Modal';
import SVGMaster from '../../../../components/Icon/SVGMaster';
import SVGContact from '../../../../components/Icon/SVGContact';
import SVGMyAcc from '../../../../components/Icon/SVGMyAcc';
import { isMobile } from 'react-device-detect';
import { Fragment } from 'react';
import Clock from 'react-live-clock';
import UnreadMsg from '../../../../pages/12Messaging/UnreadMsgCount';

const Navbar = ({ location, history, isLogin, showSidebar, sideBarSubMenuShow, toggleSidebar }) => {
  const [displayTitle, setDisplayTitle] = useState('');
  const [displayLogo, setDisplayLogo] = useState(true);
  const languageState = useSelector((state) => state.language);
  const [subCatData, setSubCatData] = useState([]);
  const [showBack, setShowBack] = useState(false);
  const [isHomePage, setIsHomePage] = useState(false);
  const [isLoginPage, setIsLoginPage] = useState(false);
  const [liveChatUrl, setLiveChatUrl] = useState('');
  const [brandLogoObj, setBrandLogoObj] = useState(null);
  const screenState = useSelector((state) => state.screen);
  // const [showSidebar, setShowSidebar] = useState(false);
  // get sidebar menu

  function contactOnClick(url) {
    window.open(url, '', 'width=500,height=700,toolbar=no,location=no,directories=no,status=yes,menubar=no,scrollbars=no');
  }

  useEffect(() => {
    
  });

  // get contact
  useEffect(() => {
    let isMount = true;
    getContact();
    function getContact() {
      window.SPL_Content.getContact(languageState.countryLanguageKey, screenState.viewType).then((data) => {
        if (isMount) {
          if (data) {
            for (let i = 0; i < data.length; i++) {
              const contact = data[i];
              if (contact.name === 'LiveChat') {
                setLiveChatUrl(contact.url);
              }
            }
          }
        }
      });
    }

    return () => {
      isMount = false;
    };
  });

  // get contact
  useEffect(() => {
    let isMount = true;
    getContact();
    function getContact() {
      window.SPL_Content.getContact(languageState.countryLanguageKey, screenState.viewType).then((data) => {
        if (isMount) {
          if (data) {
            for (let i = 0; i < data.length; i++) {
              const contact = data[i];
              if (contact.name === 'LiveChat') {
                setLiveChatUrl(contact.url);
              }
            }
          }
        }
      });
    }

    return () => {
      isMount = false;
    };
  });

  // get logo
  useEffect(() => {
    window.SPL_Content.getBrandLogo().then((res) => {
      setBrandLogoObj(res);
    });
  }, [setBrandLogoObj]);

  function switchToMobile() {
    window.SPL_Other.setViewPort('mobile');
  }

  useEffect(() => {
    initTitle(location);
    history.listen((location, action) => {
      initTitle(location);
    });

    function initTitle(location) {
      let pathname = location.pathname;
      setDisplayLogo(false);
      if (pathname === '/contact') {
        setDisplayTitle('home:home.mobile.contactus');
      } else if (pathname === 'mnavphone') {
        setDisplayTitle('contactus:contactus.mobile.phone');
      } else if (pathname === '/promotion') {
        setDisplayTitle('home:home.mobile.promotion');
      } else if (pathname === 'm-vip') {
        setDisplayTitle('home:home.mobile.vip');
      } else if (pathname === '/fund') {
        setDisplayTitle('home:home.mobile.funds');
      } else if (pathname === '/deposit') {
        setDisplayTitle('home:home.mobile.deposit');
      } else if (pathname === '/transfer') {
        setDisplayTitle('home:home.mobile.transfer');
      } else if (pathname === '/withdraw') {
        setDisplayTitle('home:home.mobile.withdraw');
      } else if (pathname === 'mweeklyturnover') {
        setDisplayTitle('home:home.mobile.weeklyturnover');
      } else if (pathname === '/myaccount') {
        setDisplayTitle('home:home.mobile.account');
      } else if (pathname === '/myaccount/myprofile') {
        setDisplayTitle('home:home.mobile.member-profile');
      } else if (pathname === '/messaging') {
        setDisplayTitle('home:home.mobile.messaging');
      } else if (pathname === '/changegamepassword') {
        setDisplayTitle('home:home.mobile.change-provider-password');
      } else if (pathname === '/changepassword') {
        setDisplayTitle('home:home.mobile.change-password');
      } else if (pathname === '/profile') {
        setDisplayTitle('home:home.mobile.member-profile');
      } else if (pathname === '/bankdetail') {
        setDisplayTitle('transaction:transaction.deposit.depositBankDetails');
      } else if (pathname === '/resetpassword') {
        setDisplayTitle('home:home.mobile.reset-password');
      } else if (pathname.includes('/poker') || pathname.includes('/casino/idn')) {
        setDisplayTitle('casino:casino.mobile.idn');
      } else if (pathname === '/login') {
        setDisplayTitle('home:home.mobile.login');
        // setDisplayTitle('home:home.mobile.login');
      } else if (pathname === '/history') {
        setDisplayTitle('home:home.mobile.history');
      } else if (pathname === '/download') {
        setDisplayTitle('home:home.mobile.download');
      } else if (pathname === '/register' || pathname === '/registersuccess') {
        setDisplayTitle('home:home.mobile.register');
      } else if (pathname === '/language') {
        setDisplayTitle('member:PortalApp.member.language');
      } else if (pathname === '/livetv') {
        setDisplayTitle('home:home.mobile.livetv');
      } else if (pathname.includes('/home')) {
        setDisplayLogo(true);
      } else if (pathname === 'mredirect-error') {
        setDisplayTitle('');
      } else if (pathname === 'mDnsUnblock') {
        setDisplayTitle('global.dns.heading');
      } else if (pathname === 'mfaq') {
        setDisplayTitle('info.heading');
      } else if (pathname === 'm-viphome') {
        setDisplayTitle('Vip Home');
      } else if (pathname.includes('/myaccount/deposit')) {
        setDisplayTitle('global:global.sidebar.info.heading');
      } else if (pathname.includes('/scr888') || pathname.includes('/918kiss')) {
        setDisplayTitle('home:home.mobilePage.menu.918kiss');
      } else if (pathname.includes('/mega888')) {
        setDisplayTitle('home:home.mobilePage.submenu.mega');
      } else if (pathname.includes('/sports/cmd368')) {
        setDisplayTitle('home:home.mobilePage.submenu.cmd');
      } else if (pathname.includes('/slot') || pathname.includes('/casino')) {
        let gameType = '';
        let provider = '';
        let splitPathName = pathname.split('/');
        provider = splitPathName[splitPathName.length - 1];
        if (pathname.includes('/slot')) {
          // title =
          gameType = 'SL';
        } else {
          gameType = 'C';
        }
        for (let i = 0; i < subCatData.length; i++) {
          const menuElement = subCatData[i];
          if (menuElement.link === gameType) {
            for (let j = 0; j < menuElement.submenu.length; j++) {
              const submenuElement = menuElement.submenu[j];
              let route = submenuElement.route;
              let splitRoute = route.split('/');
              let theRoute = splitRoute[splitRoute.length - 1];
              if (theRoute.toLowerCase() === provider.toLowerCase()) {
                setDisplayTitle(submenuElement.content);
              }
            }
          }
        }
      } else if (pathname.includes('/home')) {
        setDisplayLogo(true);
      }
    }
  }, [subCatData]);

  useEffect(() => {
    let isMount = true;
    getDisplaySubCategory(isMount);
    return () => {
      isMount = false;
    };
  }, []);

  function getDisplaySubCategory(isMount) {
    window.SPL_Content.getDisplaySubCategory(languageState.countryLanguageKey, 'mobile').then((data) => {
      if (isMount) {
        setSubCatData(data);
      }
    });
  }

  function goBack() {
    history.goBack();
  }

  function navigate(path) {
    history.push(path);
  }

  useEffect(() => {
    if (history.location.pathname.includes('/home')) {
      setIsHomePage(true);
      setDisplayLogo(true);
      setIsLoginPage(false);
    } else if (history.location.pathname === '/login') {
      setDisplayLogo(false);
      setIsLoginPage(true);
      setIsHomePage(false);
    } else if (history.location.pathname === '/faq') {
      setDisplayLogo(true);
      setIsLoginPage(false);
      setIsHomePage(false);
    } else {
      setDisplayLogo(false);
      setIsHomePage(false);
      setIsLoginPage(false);
    }

    if (history.location.pathname.includes('/home') || history.location.pathname.includes('/promotion')) {
      setShowBack(false);
    } else {
      setShowBack(true);
    }
  });

  if (history.location.pathname === '/game') {
    return null;
  } else {
    return (
      <Translation>
        {(t) => (
          <div className='app-nav'>
            <nav
              className={`toolbar sidebar-transition fixed-header ${showSidebar && !sideBarSubMenuShow ? 'screenMoveRight' : 'screenMoveLeft'} ${
                showSidebar && sideBarSubMenuShow ? 'screenMoveRightWithSubmenu' : ''} 
                ${history.location.pathname.includes('/home') ? 'toolbar' : 'sub-toolbar'}`}>
              <div className='headerContent'>
                {history.location.pathname !== '/faq' ? (
                  <div className='row d-flex align-items-center h-100'>
                    <Col xs='12' className='headerBg-1'>
                      <div className='d-none d-md-flex w-100 align-items-center justify-content-start header-datetime'>
                        <div className='info d-flex'>
                          <Clock date='' format={'D/M/YYYY h:mm:ssa'} ticking={true} />
                          <span>
                            &nbsp;(<span id='zone'>GMT+8</span>)
                          </span>
                        </div>
                        <LanguageModal />
                        {isLogin === true && 
                          <div className='d-flex justify-content-end userInfo'>
                            <UserName />
                            <div className='position-relative'>
                              <UnreadMsg />
                              <SVGMyAcc name='messaging' className='icon-xs messaging-icon pointer' onClick={() => navigate('/myaccount/messaging')} />
                            </div>
                            <span className='header-username'>{t('global:global.menu.account.message')}</span>
                          </div>
                        }
                      </div>
                    </Col>
                    <Col className='d-flex h-100 col-sm-12 col-md-3 col-lg-3 col-xs-3 p-l-0 p-r-0'>
                      <div className='w-100 d-flex'>
                        {/* mobile */}
                        {!showBack && (
                          <div
                            className='vw-20 h-100 align-items-center toolbar_toggle-button d-flex d-md-none justify-content-center'
                            onClick={() => {
                              toggleSidebar();
                            }}>
                            {!showSidebar && <AiOutlineMenu className='icon-sm mtoolBar-icon' />}
                            {showSidebar && <AiOutlineMenu className='icon-sm mtoolBar-icon' />}
                          </div>
                        )}
                        {/* mobile */}
                        {showBack && (
                          <div
                            onClick={() => goBack()}
                            className='vw-20 h-100 d-flex align-items-center toolbar_toggle-button d-md-none justify-content-center'>
                            <AiOutlineLeft className={`icon-sm mtoolBar-icon ${history.location.pathname !== '/home' ? 'sub-mtoolBar-icon' : 'mtoolBar-icon'}`} />
                          </div>
                        )}
                        <div className='d-block align-items-center justify-content-center d-md-none w-100'>
                          {
                            <div className='header-title pointer' onClick={() => navigate('/home')}>
                              {!isLoginPage && displayLogo && brandLogoObj && (
                                <LazyLoadImage src={brandLogoObj.brandLogoMobile} className='logo-mobile' alt='brand-logo' />
                              )}
                              <h5>{!displayLogo && t(displayTitle).toUpperCase()}</h5>
                            </div>
                          }
                        </div>

                        {/* <div className='logo-desktop d-none d-md-flex align-items-center' onClick={() => navigate('/home')}>
                          {brandLogoObj && <LazyLoadImage src={brandLogoObj.brandLogo} className='brand-logo pointer' alt='brand-logo' />}
                          <h5 className='d-flex d-sm-none'>{!displayLogo && t(displayTitle).toUpperCase()}</h5>
                        </div> */}

                        {/*Mobile Navigation */}
                        <div className='d-flex align-items-center justify-content-center d-md-none vw-20'>
                          <div className='d-flex align-items-center justify-content-center'>{isHomePage && <NavigationAnnounceModal />}</div>
                        </div>
                      </div>
                    </Col>

                    <Col xs='12' className='headerBg-2'>
                      <div className='d-none d-md-flex w-100 align-items-center header-tab'>
                        <div className='logo-desktop d-none d-md-flex align-items-center' onClick={() => navigate('/home')}>
                          {brandLogoObj && <LazyLoadImage src={brandLogoObj.brandLogo} className='brand-logo pointer' alt='brand-logo' />}
                          <h5 className='d-flex d-sm-none'>{!displayLogo && t(displayTitle).toUpperCase()}</h5>
                        </div>

                        {/* design mobile button */}
                        <div className='headerContent-Right-Group'>
                          <div className='headerContent-Right'>
                            <Row className='bg-transparent d-none d-md-flex justify-content-end'>
                              {/* //HomeHEADER before login */}
                              {isLogin === false ? (
                                <Col lg='12'>
                                  <Col>
                                    <div className='d-flex align-items-center headerContent-Right-Lower'>
                                      <LoginDesktop></LoginDesktop>
                                    </div>
                                  </Col>
                                </Col>
                              ) : isLogin === true ? (
                                <Col lg='12' className='isHomePage-Login'>
                                  <Col>
                                    <div className='w-100 d-flex align-items-center justify-content-end headerContent-Right-Lower'>
                                      <Setting></Setting>
                                      <Logout />
                                    </div>
                                  </Col>
                                </Col>
                              ) : ('')}
                            </Row>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </div>
                ) : (
                  <Col className='d-flex h-100 col-sm-12 col-md-12 col-lg-12 col-xs-3 p-l-0 p-r-0'>
                    <div className='w-100 d-flex'>
                      {/* mobile */}
                      {!showBack && (
                        <div
                          className='vw-20 h-100 align-items-center toolbar_toggle-button d-flex d-md-none justify-content-center'
                          onClick={() => {
                            toggleSidebar();
                          }}>
                          {!showSidebar && <AiOutlineMenu className='icon-sm mtoolBar-icon' />}
                          {showSidebar && <AiOutlineMenu className='icon-sm mtoolBar-icon' />}
                        </div>
                      )}
                      {/* mobile */}
                      {showBack && (
                        <div
                          onClick={() => goBack()}
                          className='vw-20 h-100 d-flex align-items-center toolbar_toggle-button d-md-none justify-content-center'>
                          <AiOutlineLeft className={`icon-sm mtoolBar-icon ${history.location.pathname !== '/home' ? 'sub-mtoolBar-icon' : 'mtoolBar-icon'}`} />
                        </div>
                      )}
                      <div className='d-block align-items-center justify-content-center d-md-none w-100'>
                        {
                          <div className='header-title pointer' onClick={() => navigate('/home')}>
                            {!isLoginPage && displayLogo && brandLogoObj && (
                              <LazyLoadImage src={brandLogoObj.brandLogoMobile} className='logo-mobile' alt='brand-logo' />
                            )}
                            <h5>{!displayLogo && t(displayTitle).toUpperCase()}</h5>
                          </div>
                        }
                      </div>

                      <div className='logo-desktop d-none d-md-flex align-items-center justify-content-center w-100' onClick={() => navigate('/home')}>
                        {brandLogoObj && <LazyLoadImage src={brandLogoObj.brandLogo} className='brand-logo pointer' alt='brand-logo' />}
                      </div>

                      {/*Mobile Navigation */}
                      <div className='d-flex align-items-center justify-content-center d-md-none vw-20'>
                        <div className='d-flex align-items-center justify-content-center'>{isHomePage && <NavigationAnnounceModal />}</div>
                      </div>
                    </div>
                  </Col>
                )}
              </div>
            </nav>

            {/* fixed footer nav */}
            {/* {history.location.pathname !== '/faq' ? (
              <Row className='toolbar_navigation w-100 d-none d-md-block m-l-0'>
                <HeaderMenu></HeaderMenu>
              </Row>
            ) : (
              <Row className='toolbar_navigation w-100 d-block m-l-0'>
                <HeaderMenu></HeaderMenu>
              </Row>
            )} */}
            <LoginFailSection />
            <MobileFooterAfterLogin />
            <Container></Container>
          </div>
        )}
      </Translation>
    );
  }
};
export default withRouter(connect(mapStateToProps, null)(withTranslation(['casino', 'member'])(Navbar)));