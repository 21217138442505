import React from 'react';
import { useTranslation } from 'react-i18next';
import Label from '@components/atoms/label/label';
import ReminderNote from '@components/atoms/reminder-note/reminder-note';
import styles from './bank-statement-upload-section.module.scss';
import Button from 'root/pages/Share/button/button';

const BankStatementUploadSection = ({ ...props }) => {
    const { receiptImg, className } = props;
    const { t } = useTranslation('transaction');
    return (
        <div className={`${styles.bankStatementUploadWrapper}  ${className || ''}`}>
            <Label labelText={t('transaction:transaction.withdrawal.bankStatement', 'Bank Statement')} isMandatory={true} />
            <div className={styles.inputButtonWrapper}>
                <BankStatementUploadButton {...props} />
                {receiptImg?.length > 0 && <DisplayUploadBankStatementImg {...props} />}
                <ReminderNote msg={t('transaction:transaction.withdrawal.bankStatementRequirement2', 'Bank statement must show with full name and bank account number')} />
            </div>
        </div>
    );
};

export default BankStatementUploadSection;

export const DisplayUploadBankStatementImg = ({ ...props }) => {
    const { t } = useTranslation('transaction');
    const { pdfLabel, receiptImg, receiptImgFile, handleClearReceipt } = props;
    const isPdf = receiptImgFile?.type === 'application/pdf';

    return (
        <>
            {isPdf ? (
                <div className={styles.imgContainer}>
                    <div className={styles.label}>{pdfLabel || t('transaction:transaction.deposit.pdf-file-name', 'PDF File Name: ')}</div>
                    <span className={styles.pdfName}>{receiptImgFile?.name}</span>
                    <img className={styles.clearImg} src='/public/images/icon/close-red.png' alt='pdf-clear' onClick={handleClearReceipt} />
                </div>
            ) : (
                <div className={styles.imgContainer}>
                    <img className={`${styles.receiptImg} receipt-image`} src={receiptImg} alt='bank-statement-img' onClick={handleClearReceipt} />
                </div>
            )}
        </>
    );
};

export const BankStatementUploadButton = ({ ...props }) => {
    const { buttonText, onClickFunction, handleReceiptImageChange, isReceiptInput } = props;
    return (
        <Button
            className={`${styles.bankStatementUploadButton} standard-button`}
            buttonText={buttonText}
            onClickFunction={onClickFunction}
            handleReceiptFunction={handleReceiptImageChange}
            isReceiptInput={isReceiptInput}
        />
    );
};
