import React from 'react';
import { CSSTransition } from 'react-transition-group';
import { userAction } from 'root/redux/action';
import { Translation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { mapStateToProps } from 'root/redux/selector/selector';
import { withRouter } from 'react-router-dom';
// import Menu from "../Menu";
import SVGElements from 'root/components/SVGElements';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { gu_getNestedValue } from 'root/utils/general-util';
import { LodashUtils } from 'root/utils/lodash-util';

class MenuDrop extends React.Component {
    isMount = true;
    constructor(props) {
        super(props);
        const href = window.location.href;
        const route = href.substr(href.lastIndexOf('/') + 1) || 'index.html';
        this.state = {
            route: route,
            menus: [],
            hideRaffleDraw: false,
        };
    }

    componentDidMount() {
        this.isMount = true;
        this.getDisplayMenu();
        this.initEvent();
        this.getPortalSettings();
    }

    componentWillUnmount() {
        this.isMount = false;
    }

    getDisplayMenu = () => {
        const { language } = this.props;

        window.SPL_Content.getDisplayMenu(language.countryLanguageKey, 'web').then((data) => {
            if (this.isMount) {
                this.setState({ menus: data }, () => {
                    this.addMenu();
                });
            }
        });
    };

    getPortalSettings = () => {
        const { language } = this.props;
        window.SPL_Content.getPortalSettings().then((settings) => {
            if (settings && settings.replaceRaffleDrawFor) {
                const hideRaffleDraw = settings.replaceRaffleDrawFor.find((item) => item === language.countryLanguageKey);
                if (hideRaffleDraw) {
                    this.setState({ hideRaffleDraw: true });
                }
            }
        });
    };

    addMenu = () => {
        const { user, language } = this.props;
        const countryLanguageKey = LodashUtils.get(language, 'countryLanguageKey', '');
        let menu = this.state.menus;

        const favourite = {
            id: 20,
            name: 'FAVOURITE',
            sref: 'favourite',
            content: 'global:global.menu.favourite',
            route: '/favourite',
            imageMenu: '/public/html/images/icon/favourite.svg',
        };

        const mobileMenu = [
            {
                id: 15,
                name: 'DOWNLOAD',
                sref: 'download',
                class: 'mb-only',
                content: 'global:global.menu.download',
                route: '/download',
                imageMenu: '/public/html/images/icon/download.svg',
            },
            {
                id: 16,
                name: 'Sponsorship',
                sref: 'sponsorship',
                class: 'mb-only',
                content: 'global:global.menu.sponsorship',
                imageMenu: '/public/html/images/drawer_img/sponsorship.png',
                imageMenu_ov: '/public/html/images/drawer_img/sponsorship-ov.png',
                route: '/sponsorship',
            },
            {
                id: 17,
                name: 'CONTACT',
                sref: 'contact',
                class: 'mb-only',
                content: 'global:global.menu.contact',
                route: '/contact',
                imageMenu: '/public/html/images/icon/contact.svg',
            },
            {
                id: 18,
                name: 'LANGUAGE',
                sref: 'lan-white',
                class: 'mb-only',
                content: 'global:global.menu.lang',
                route: '/language',
                imageMenu: '/public/html/images/icon/lang.svg',
            },
            {
                id: 19,
                name: 'LOGOUT',
                sref: 'logout',
                class: `mb-only ${!this.props.user.isLogin && 'd-none'}`,
                content: 'global:global.menu.logout',
                route: '/logout',
                imageMenu: '/public/html/images/icon/logout.svg',
            },
            {
                id: 20,
                name: 'Settings',
                sref: 'settings',
                class: `mb-only`,
                content: 'global:global.menu.account.settings',
                imageMenu: '/public/html/images/icon/logout.svg',
                route: '/settings',
            },
        ];

        for (let i = 0; i < menu.length; i++) {
            if (menu[i].sref === 'home') {
                menu[i].route = '/home';
            }

            if (i === 0) {
                if (this.props.language.country === 'MY') {
                    menu.splice(9, 0, favourite);
                } else if (this.props.language.country === 'SG') {
                    menu.splice(6, 0, favourite);
                } else if (this.props.language.country === 'VN') {
                    menu.splice(9, 0, favourite);
                }
            }
        }

        for (let k = 0; k < mobileMenu.length; k++) {
            if (mobileMenu[k].name === 'Settings') {
                if (window.SPL_LiteApp.isZT828LiteApp()) {
                    menu.push(mobileMenu[k]);
                }
            } else if (mobileMenu[k].name === 'Sponsorship') {
                let key = this.props.language.countryLanguageKey;
                let setting = this.props.portal.settings;
                if (setting && setting.sponsorshipUrl && setting.sponsorshipUrl[key]) {
                    menu.push(mobileMenu[k]);
                }
            } else {
                menu.push(mobileMenu[k]);
            }
        }

        const ignoreMenuHOC = (name) => (menu) => LodashUtils.toLower(menu.name) !== LodashUtils.toLower(name);
        const hideCockFight = !LodashUtils.isEqual(countryLanguageKey, 'VI_VN') || !LodashUtils.get(user, 'isLogin');
        if (hideCockFight) {
            menu = menu.filter(ignoreMenuHOC('Cockfight'));
        }

        this.setState({ menus: menu });
    };

    handle(route) {
        if (/^#/.test(route)) {
            switch (route) {
                case '#login':
                    //"login logic"
                    break;
                case '#logout':
                    break;

                default:
                    break;
            }
            return;
        }

        if (route === '/logout') {
            this.props.dispatch(userAction.logout()).then(() => {
                window.location.href = '/home';
            });
        }

        if (route === '/sponsorship') {
            let key = this.props.language.countryLanguageKey;
            let setting = this.props.portal.settings;
            if (setting && setting.sponsorshipUrl && setting.sponsorshipUrl[key]) {
                window.open(setting.sponsorshipUrl[key], '_blank');
            }
        }

        if (route !== '/settings') {
            this.props.history.push(route);
        } else {
            if (!window.isAgent && !window.isAffiliate) {
                if (window.SPL_LiteApp.isZT828LiteApp()) {
                    window.SPL_LiteApp.onSettingsTapped();
                }
            }
        }
    }

    initEvent() {
        document.querySelector('#root').addEventListener('click', (e) => {
            if (window.innerWidth > 1025) {
                let stopEventEl = [document.querySelector('.menu-side-box .menu-side'), document.querySelector('.icon-game-menu')];
                if (this.props.menuIn) {
                    for (let index = 0; index < stopEventEl.length; index++) {
                        let child = stopEventEl[index];
                        child = child && child.$el ? child.$el : child;
                        if (child && child.contains(e.target)) return;
                    }

                    this.props.toggleShow();
                }
            }
        });
    }

    iconNameMapping(name, type) {
        let country = gu_getNestedValue(this, 'props.language.country');
        if (name.toLowerCase() === 'raffle') {
            if (type === 'name') {
                //to generate name for icon mapping
                return country === 'VN' ? 'maxim-raffle-icon' : 'icon-evo';
            } else {
                //to generate class name for styling
                return country === 'VN' ? 'icon-raffle' : 'icon-evo';
            }
        } else {
            // Other icons
            return name.toLowerCase() + '-icon';
        }
    }

    renderMobileMenu = (t, info) => {
        const { history, language } = this.props;

        if (this.state.hideRaffleDraw && info.name === 'RAFFLE') {
            return;
        }

        return (
            !['918Kiss', 'Poker'].some((menuName) => info.name.includes(menuName)) && (
                <li
                    key={info.name}
                    className={info.class + ' ' + (history.location.pathname.includes(info.route) ? 'current' : '')}
                    onClick={this.handle.bind(this, info.route)}
                >
                    <span className='icon-box'>
                        {info.name === 'LANGUAGE' ? (
                            <LazyLoadImage className={`icon-menu menu-${info.sref}`} src={language.src}></LazyLoadImage>
                        ) : (
                            <SVGElements
                                className={`icon-menu menu-${info.sref} ${this.iconNameMapping(info.name, 'className')}`}
                                name={this.iconNameMapping(info.name, 'name')}
                            />
                        )}
                    </span>
                    <span className='menu-name'>{t(info.content)}</span>
                </li>
            )
        );
    };

    render() {
        const { menus } = this.state;
        const { menuIn, toggleShow, screen, history, language } = this.props;

        return (
            <Translation>
                {(t) => (
                    <CSSTransition classNames='menu-animation' in={menuIn} timeout={300} unmountOnExit>
                        <div
                            className='menu-side-box'
                            onClick={() => {
                                toggleShow();
                            }}
                        >
                            <div className='menu-side'>
                                <ul className='nav-menu'>
                                    {menus.map((info) => {
                                        const { name, route, sref, content } = info;
                                        Object.assign(info, {
                                            sideBarImg: '/public/html/images/icon/' + name + '.svg',
                                        });

                                        return screen.isMobile ? (
                                            this.renderMobileMenu(t, info)
                                        ) : (
                                            <li
                                                key={name}
                                                className={info.class + ' ' + (history.location.pathname.includes(route) ? 'current' : '')}
                                                onClick={this.handle.bind(this, route)}
                                            >
                                                <span className='icon-box'>
                                                    <SVGElements className={`icon-menu menu-${sref}`} name={name.toLowerCase() + '-icon'} />
                                                </span>
                                                <span className='menu-name'>{t(content)}</span>
                                            </li>
                                        );
                                    })}
                                </ul>
                            </div>
                        </div>
                    </CSSTransition>
                )}
            </Translation>
        );
    }
}

export default connect(mapStateToProps)(withTranslation()(withRouter(MenuDrop)));
