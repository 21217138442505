import { authSettingsAction } from '@redux/action';

export function getWidgetType(vm) {
    let widgetTypeMode = 'light';
    if (vm.elementRef.current) {
        const computedStyle = window.getComputedStyle(vm.elementRef.current);
        if (computedStyle.color === '#000' || computedStyle.color === 'rgba(0, 0, 0, 0)' || computedStyle.color === 'rgb(0, 0, 0)') {
            widgetTypeMode = 'dark';
        }
    }
    vm.setState({ widgetTypeMode });
}

export const loadAuthSettings = async (isPublic, type, vm, currencyCode = false, version) => {
    const _splConfig = {
        otpOptions: {
            isPublic: isPublic ? isPublic : false,
            otpType: type,
            ...(currencyCode && { currencyCode }),
        },
        ...(version && { version }),
    };
    await vm.props.dispatch(authSettingsAction.fetchAuthSettings(_splConfig, false));
};
