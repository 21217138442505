import moment from 'moment';
import React from 'react';
import { Row } from 'react-bootstrap';
import 'react-datepicker/dist/react-datepicker.css';
import { Translation, withTranslation } from 'react-i18next';
import Pagination from 'react-js-pagination';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import SVGHistory from 'root/components/Icon/SVGHistory';
import { FreeSpinColumnConfigs } from 'root/configs/history';
import { mapStateToProps } from 'root/redux/selector/selector';
import commonUtil from 'root/utils/common-util';
import HistoryModal from '../../15History/152_HistoryModal';
import FreeSpinHistory from './freespin/freespin-history';
import InstantRebateHistoryModal from './instant-rebate/sub-component/history-popup/history-popup';

class History extends React.Component {
    historyCategories = [
        { id: 0, name: this.props.t('settings:settings.history.bettingSummary'), transType: 'bettingSummary' },
        { id: 1, name: this.props.t('settings:settings.history.transfer'), transType: 'transfer' },
        { id: 2, name: this.props.t('settings:settings.history.withdrawalDeposit'), transType: 'depositWithdraw' },
        { id: 3, name: this.props.t('settings:settings.history.promotionApply'), transType: 'promotion' },
        { id: 4, name: this.props.t('settings:settings.history.rebateCashback'), transType: 'rebate' },
        { id: 5, name: this.props.t('settings:settings.history.rewards'), transType: 'rewards' },
        { id: 6, name: this.props.t('settings:settings.history.freeSpin'), transType: 'freeSpinHistory' },
    ];

    historyTime = [
        { id: 0, name: this.props.t('settings:settings.history.today'), transType: 'today' },
        { id: 1, name: this.props.t('settings:settings.history.in3days'), transType: 'in3days' },
        { id: 2, name: this.props.t('settings:settings.history.inaweek'), transType: 'inaweek' },
        { id: 3, name: this.props.t('settings:settings.history.inamonth'), transType: 'inamonth' },
    ];
    isMount = true;

    constructor(props) {
        super(props);
        let currentDate = new Date();

        this.toggle = this.toggle.bind(this);
        this.toggleHistoryPopup = this.toggleHistoryPopup.bind(this);
        this.state = {
            filter: {
                startDate: currentDate,
                endDate: currentDate,
                activeTab: 0,
                transType: 'bettingSummary',
                transactionType: [{ value: '', desc: this.props.t('transaction:transaction.history.filter.all') }],
                status: [{ value: '', desc: this.props.t('transaction:transaction.history.filter.all') }],
            },
            histories: [],
            instantRebate: [],
            pageSize: '',
            totalItems: 0,
            transTypeList: [
                { value: '', desc: this.props.t('transaction:transaction.history.filter.all') },
                { value: 'D', desc: this.props.t('transaction:transaction.trans_type.deposit') },
                { value: 'W', desc: this.props.t('transaction:transaction.trans_type.withdrawal') },
            ],
            statusList: [
                { value: '', desc: this.props.t('transaction:transaction.history.filter.all') },
                { value: 'P', desc: this.props.t('transaction:transaction.status.pending') },
                { value: 'A', desc: this.props.t('transaction:transaction.status.accepted') },
                { value: 'R', desc: this.props.t('transaction:transaction.status.rejected') },
                { value: 'I', desc: this.props.t('transaction:transaction.status.inProgress') },
            ],
            activeTab: 0,
            selectedFilterType: [{ id: 0, name: this.props.t('settings:settings.history.bettingSummary'), transType: 'bettingSummary' }],
            currentPage: 1,
            todosPerPage: 5,
            activePagination: 0,
            startIndex1: 0,
            endIndex1: 5,
            language: '',
            sortDate: 'createdDate,desc',
            sortAmount: '',
            sortStatus: '',
            rebatePeriodStart: null,
            rebatePeriodEnd: null,
            compSettings: [],
        };

        this.controls = {};
        for (var tab of props.config || []) {
            for (var ctrl of tab.controls || []) {
                this.controls[tab.title + '/' + ctrl.name] = React.createRef();
            }
        }
    }
    state = {
        visible: false,
    };

    handleShow = () => {
        this.setState({ visible: true });
    };

    handleHide = () => {
        this.setState({ visible: false });
    };

    componentDidMount() {
        this.isMount = true;
        commonUtil.loadCustomTranslation(['Rebate', 'History'], this, function () {});
        this.initCompSettings().then(() => {
            this.checkInstantRebateShow();
            this.loadHistories('');
            this.checkCountryLanguage();
        });
        if (this.props.screen.viewType === 'web' && this.props.location.pathname === '/history') {
            this.props.history.push('/myaccount/report');
        }
    }

    componentWillUnmount() {
        this.isMount = false;
    }

    checkInstantRebateShow() {
        const { compSettings } = this.state;
        if (compSettings && compSettings.showInstantRebate) {
            for (let i = 0; i < this.historyCategories.length; i++) {
                if (this.historyCategories[i].transType === 'rebate') {
                    this.historyCategories[i].name = this.props.t('settings:settings.history.instantRebate');
                }
            }
        }
    }

    checkCountryLanguage = () => {
        if (this.props.language.countryLanguageKey === 'TH_TH') {
            this.setState({ language: 'th' });
        } else if (this.props.language.countryLanguageKey === 'ZH_MY') {
            this.setState({ language: 'zhCN' });
        } else {
            this.setState({ language: '' });
        }
    };

    loadHistories = (sort) => {
        const { startDate, endDate, transactionType, status, transType } = this.state.filter;
        const { currentPage, todosPerPage, compSettings } = this.state;

        let params = {
            start: new Date(new Date(startDate).setHours(0, 0, 0)),
            end: new Date(new Date(endDate).setHours(23, 59, 59)),
            type: transactionType.length > 0 ? transactionType[0].value : null,
            status: status.length > 0 ? status[0].value : null,
            page: currentPage,
            size: todosPerPage,
            lang: this.props.language.key,
            sort: sort,
        };

        if (transType === 'bettingSummary') {
            params = { ...params, isMobile: true };
        }

        if (transType === 'freeSpinHistory') {
            params.start = moment(startDate).format('YYYY-MM-DD');
            params.end = moment(endDate).format('YYYY-MM-DD');
        }

        //only rebate need use other api
        if (compSettings && compSettings.showInstantRebate && this.state.filter.transType === 'rebate') {
            this.instantRebateHistory(startDate, endDate);
            this.setState({ filter: { ...this.state.filter, transType: 'instant-rebate' } });
        } else {
            window.SPL_Transaction.loadHistories(this.state.filter.transType, params).then((histories) => {
                if (this.isMount && this.state.filter.transType === transType) {
                    if (transType === 'freeSpinHistory') {
                        this.setState({ histories: histories.content, totalItems: histories.totalElements, pageSize: histories.size });
                    } else {
                        this.setState({ histories: histories.histories, totalItems: histories.totalItems, pageSize: histories.pageSize });
                    }
                }
            });
        }
    };

    initCompSettings() {
        return new Promise((resolve) => {
            // Step 1: Standard way to get from portal settings
            let tempSettings = commonUtil.getSettingFromPortalSettings({
                vm: this,
                settingName: 'memberSettings',
            });

            // Step 2: Massage again to get the specific data you need (Skip if not needed)

            // Step 3: Initial all into compSettings
            let compSettings = {
                showInstantRebate: tempSettings.showInstantRebate,
            };

            this.setState({ compSettings }, () => {
                resolve();
            });
        });
    }

    instantRebateHistory(historyStart, historyEnd) {
        window.SPL_Member.loadMemberRebateSummaryPage(this.state.currentPage - 1, historyStart, historyEnd).then((data) => {
            let rebateSummary = data;
            if (rebateSummary && rebateSummary.histories) {
                rebateSummary.histories.filter((rebate) => {
                    rebate.totalEligibleTurnOver = rebate.totalValidTurnOver - rebate.totalPromoTurnOver;
                });
                this.setState({ instantRebate: rebateSummary.histories, totalItems: rebateSummary.totalItems, pageSize: rebateSummary.pageSize, todosPerPage: 10 });
            }
        });
    }

    massageCorrectProvider(providerList, categoryList) {
        if (providerList && categoryList) {
            for (let i = 0; i < providerList.length; i++) {
                if (categoryList[providerList[i].provider] && categoryList[providerList[i].provider][providerList[i].product]) {
                    providerList[i].product = categoryList[providerList[i].provider][providerList[i].product]
                        ? categoryList[providerList[i].provider][providerList[i].product]
                        : providerList[i].product;
                }
            }
        }
        return providerList;
    }

    toggleHistoryPopup(history) {
        let loadDetailsMemberRebateSummary = new Promise((resolve) => {
            window.SPL_Member.getDetailsMemberRebateSummary({
                start: history && history.rebatePeriodStart,
                end: history && history.rebatePeriodEnd,
                transactionDate: history && history.transactionDate,
            }).then((hist) => resolve(hist));
        });
        let loadInstantRebateProviderCategory = new Promise((resolve) => {
            window.SPL_JsonSettings.getInstantRebateProviderCategoryJson((cat) => resolve(cat));
        });
        Promise.all([loadDetailsMemberRebateSummary, loadInstantRebateProviderCategory]).then((result) => {
            let [rebateSummary, providerCategory] = result;
            let historyKey = [];

            if (rebateSummary) {
                let histories = rebateSummary.rebateHistories;

                if (histories) {
                    //filter empty turnover
                    histories = histories.filter((turnover) => {
                        turnover.totalEligibleTurnOver = turnover.totalValidTurnOver - turnover.totalPromoTurnOver;
                        return Number(turnover.releasedAmount) > 0;
                    });
                    if (providerCategory && providerCategory.providerCategory) {
                        this.massageCorrectProvider(histories, providerCategory.providerCategory);
                    }

                    //to total up duplicate product, provider and rate
                    for (let i = 0; i < histories.length; i++) {
                        let res = {
                            memberGroupCode: histories[i].memberGroupCode,
                            product: histories[i].product,
                            provider: histories[i].provider,
                            rate: histories[i].rate,
                            releasedAmount: 0,
                            totalEligibleTurnOver: 0,
                        };

                        for (let j = 0; j < histories.length; j++) {
                            if (
                                histories[i].memberGroupCode === histories[j].memberGroupCode &&
                                histories[i].product === histories[j].product &&
                                histories[i].rate === histories[j].rate &&
                                histories[i].provider === histories[j].provider
                            ) {
                                res.releasedAmount = res.releasedAmount + histories[j].releasedAmount;

                                if (histories[j].includePromoTurnover) {
                                    res.totalEligibleTurnOver += histories[j].totalValidTurnOver;
                                } else {
                                    res.totalEligibleTurnOver += histories[j].totalValidTurnOver - histories[j].totalPromoTurnOver;
                                }
                            }
                        }
                        historyKey.push(res);
                    }

                    //massage history duplicate data
                    historyKey = [...new Set(historyKey.map((d) => d.product))].map((product) => {
                        let filterProduct = historyKey.filter((d) => d.product === product).map((d) => d);
                        let filterProvider = filterProduct.filter((current, index) => {
                            return (
                                filterProduct.findIndex(
                                    (user) => user.provider === current.provider && user.memberGroupCode === current.memberGroupCode && user.rate === current.rate
                                ) === index
                            );
                        });
                        return {
                            product: product,
                            productHistories: filterProvider,
                        };
                    });
                }
            }

            this.setState({
                showHistoryPopup: !this.state.showHistoryPopup,
                selectedHistory: historyKey,
                rebatePeriodStart: history && history.rebatePeriodStart,
                rebatePeriodEnd: history && history.rebatePeriodEnd,
            });
        });
    }

    sortHistories = (type) => {
        const { sortDate, sortAmount, sortStatus } = this.state;

        if (type === 'date') {
            if (sortDate === 'createdDate,desc') {
                this.loadHistories('createdDate,asc');
                this.setState({ sortDate: 'createdDate,asc' });
            } else {
                this.loadHistories('createdDate,desc');
                this.setState({ sortDate: 'createdDate,desc' });
            }
        } else if (type === 'amount') {
            if (sortAmount === 'amount,asc') {
                this.loadHistories('amount,desc');
                this.setState({ sortAmount: 'amount,desc' });
            } else {
                this.loadHistories('amount,asc');
                this.setState({ sortAmount: 'amount,asc' });
            }
        } else if (type === 'status') {
            if (sortStatus === 'status,asc') {
                this.loadHistories('status,desc');
                this.setState({ sortStatus: 'status,desc' });
            } else {
                this.loadHistories('status,asc');
                this.setState({ sortStatus: 'status,asc' });
            }
        }
    };

    onTabClick(index) {
        let transType = this.historyCategories[index].transType;
        this.selectedFilterType = [this.historyCategories[index]];
        this.setState(
            {
                currentPage: 1,
                filter: { ...this.state.filter, transType: transType },
                histories: [],
                selectedFilterType: [this.historyCategories[index]],
                activeTab: index,
            },
            () => {
                this.loadHistories('');
            }
        );
    }

    onSelectCategory = (value) => {
        this.setState({ selectedFilterType: value, currentPage: 1 }, () => {
            this.applyOnClick();
        });
    };

    applyOnClick = () => {
        this.setState({ filter: { ...this.state.filter, transType: this.state.selectedFilterType[0].transType } }, () => {
            this.loadHistories('');
        });
    };

    onDateChanged = (date, type) => {
        if (type === 'start') {
            this.setState({ filter: { ...this.state.filter, startDate: date } });
        } else {
            this.setState({ filter: { ...this.state.filter, endDate: date } });
        }
    };

    onTransTypeChange = (value) => {
        this.setState({ filter: { ...this.state.filter, transactionType: value } });
    };

    onStatusChange = (value) => {
        this.setState({ filter: { ...this.state.filter, status: value } });
    };

    getHistoryTemplate(type, historyObj) {
        const { t } = this.props;
        let disPlayItem = [];
        if (type === 'transfer') {
            disPlayItem = [
                {
                    label: t('history:history.id'),
                    detail: t(historyObj.id),
                },
                {
                    label: t('history:history.to'),
                    detail: t(historyObj.to),
                },
                {
                    label: t('history:history.from'),
                    detail: t(historyObj.from),
                },
                {
                    label: t('history:history.promo_code'),
                    detail: t(historyObj.promoCode),
                },
                {
                    label: t('history:history.amount'),
                    detail: window.SPL_Other.formatAmount(historyObj.amoun),
                },
                {
                    label: t('history:history.datatime'),
                    detail: moment(historyObj.createdDate).format('YYYY-MM-DD HH:mm').toString(),
                },
                {
                    label: t('history:history.status'),
                    detail: t(historyObj.result),
                },
            ];

            return disPlayItem;
        } else if (type === 'depositWithdraw') {
            disPlayItem = [
                {
                    label: t('history:history.method'),
                    detail: t(historyObj.method),
                },
                {
                    label: t('history:history.id'),
                    detail: t(historyObj.id),
                },
                {
                    label: t('history:history.amount'),
                    detail: window.SPL_Other.formatAmount(historyObj.amount),
                },
                {
                    label: t('transaction:transaction.deposit.deposithistory.status'),
                    detail: t(historyObj.status),
                },
                {
                    label: t('history:history.datatime'),
                    detail: moment(historyObj.createdDate).format('YYYY-MM-DD HH:mm').toString(),
                },
            ];

            return disPlayItem;
        } else if (type === 'promotion') {
            disPlayItem = [
                {
                    label: t('history:history.id'),
                    detail: t(historyObj.id),
                },
                {
                    label: t('history:history.promo_code'),
                    detail: t(historyObj.promoCode),
                },
                {
                    label: t('history:history.amount'),
                    detail: window.SPL_Other.formatAmount(Math.abs(historyObj.targetAmount)),
                },
                {
                    label: t('history:history.target_achieved'),
                    detail: window.SPL_Other.formatAmount(historyObj.targetAchieved),
                },
                {
                    label: t('transaction:transaction.deposit.deposithistory.status'),
                    detail: t(historyObj.status),
                },
                {
                    label: t('history:history.datatime'),
                    detail: moment(historyObj.createdDate).format('YYYY-MM-DD HH:mm').toString(),
                },
            ];

            return disPlayItem;
        } else if (type === 'rebate') {
            disPlayItem = [
                {
                    label: t('history:history.id'),
                    detail: t(historyObj.id),
                },
                {
                    label: t('transaction:transaction.history.provider'),
                    detail: t(historyObj.provider),
                },
                {
                    label: t('history:history.amount'),
                    detail: window.SPL_Other.formatAmount(historyObj.amount),
                },
                {
                    label: t('transaction:transaction.history.totalValidTurnOver'),
                    detail: window.SPL_Other.formatAmount(historyObj.totalValidTurnOver),
                },
                {
                    label: t('transaction:transaction.history.totalPromoTurnOver'),
                    detail: window.SPL_Other.formatAmount(historyObj.totalPromoTurnOver),
                },
                {
                    label: t('history:history.totalEligibleTurnOver'),
                    detail: window.SPL_Other.formatAmount(historyObj.totalEligibleTurnOver),
                },
                {
                    label: t('transaction:transaction.history.transactionDate'),
                    detail: moment(historyObj.createdDate).format('YYYY-MM-DD HH:mm').toString(),
                },
            ];

            return disPlayItem;
        } else if (type === 'rewards') {
            disPlayItem = [
                {
                    label: t('history:history.id'),
                    detail: t(historyObj.id),
                },
                {
                    label: t('transaction:transaction.history.rewardName'),
                    detail: t(historyObj.name),
                },
                {
                    label: t('transaction:transaction.history.rewardType'),
                    detail: historyObj.rewardType,
                },
                {
                    label: t('history:history.amount'),
                    detail: historyObj.rewardType === 'FREE_SPIN' ? historyObj.noOfFreeSpin : window.SPL_Other.formatAmount(historyObj.amount),
                    currency: historyObj.currency,
                    rewardType: historyObj.rewardType,
                },
                {
                    label: t('transaction:transaction.deposit.deposithistory.status'),
                    detail: t(historyObj.status),
                },
                {
                    label: t('transaction:transaction.history.transactionDate'),
                    detail: moment(historyObj.createdDate).format('YYYY-MM-DD HH:mm').toString(),
                },
            ];

            return disPlayItem;
        } else if (type === 'freeSpinHistory') {
            disPlayItem = FreeSpinColumnConfigs.map(({ key, render }) => ({
                label: t(`transaction:transaction.freeSpinHistory.${key}`),
                detail: render(historyObj, t),
            }));

            disPlayItem = [
                {
                    label: t('history:history.id'),
                    detail: historyObj.id,
                },
                ...disPlayItem,
            ];

            return disPlayItem;
        }
    }

    showDetails = (type, history) => {
        this.setState({ type: type, historySelected: history, isOpenHistoryDetailsModal: true });
    };

    closeDetailsModal = () => {
        this.setState({ isOpenHistoryDetailsModal: false });
    };

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({ activeTab: tab });
        }
    }

    handlePageChange = (pageNumber) => {
        this.setState({ currentPage: pageNumber }, () => {
            this.loadHistories('');
        });
    };

    render() {
        const { customTranslationObj } = this.state;
        return (
            <Translation>
                {(t) => (
                    <div className='wap-history-page'>
                        <div className='page-content'>
                            <div className='history-item-box'>
                                <div className='title-item'></div>

                                <div className='history-filter'>
                                    <HistoryModal
                                        filter={this.state.filter}
                                        selectedFilterType={this.state.selectedFilterType}
                                        buttonClass={'btn-link position-absolute'}
                                        applyOnClick={this.applyOnClick}
                                        categories={this.historyCategories}
                                        onSelectCategory={this.onSelectCategory}
                                        onDateChanged={this.onDateChanged}
                                        transType={this.state.filter.transType}
                                        transTypeList={this.state.transTypeList}
                                        statusList={this.state.statusList}
                                        transactionType={this.state.filter.transactionType}
                                        status={this.state.filter.status}
                                        onTransTypeChange={this.onTransTypeChange}
                                        onStatusChange={this.onStatusChange}
                                        language={this.state.language}
                                    />
                                </div>
                            </div>

                            <div className='history-item-box'>
                                <div className='title-item'></div>
                                <div className='history-mobile-content'>
                                    <div className=' history-container'>
                                        {(() => {
                                            switch (this.state.filter.transType) {
                                                case 'bettingSummary':
                                                    let bettingSummary = this.state.histories.map((hist, index) => {
                                                        return (
                                                            <ul key={index} className='history-details'>
                                                                <li className='history-gameProvider'>
                                                                    {customTranslationObj && t(customTranslationObj[hist.provider])
                                                                        ? t(customTranslationObj[hist.provider])
                                                                        : t(`providerName:ProviderName.${hist.provider}`)}
                                                                </li>
                                                                <li className='history-betCount'>{hist.totalBet}</li>
                                                                <li className='history-betAmount'>{hist.totalTurnOver}</li>
                                                                <li className='history-validBetAmount'>{hist.totalValidTurnOver}</li>
                                                                <li className='history-bonusTurnover'>{hist.totalPromoTurnOver}</li>
                                                                <li className='history-turnoverWithoutBonus'>{hist.turnoverWithoutBonus}</li>
                                                                <li className='history-totalBonus'>{hist.totalBonus}</li>
                                                                <li className='history-totalRebate'>{hist.totalRebate}</li>
                                                            </ul>
                                                        );
                                                    });

                                                    return (
                                                        <div className='history-header'>
                                                            <ul className='history-title-div bettingSummary-history'>
                                                                <li className='history-gameProvider'>{t('transaction:transaction.history.gameProvider')}</li>
                                                                <li className='history-betCount'>{t('transaction:transaction.history.betCount')}</li>
                                                                <li className='history-betAmount'>{t('transaction:transaction.history.betAmount')}</li>
                                                                <li className='history-validBetAmount'>{t('transaction:transaction.history.validBetAmount')}</li>
                                                                <li className='history-bonusTurnover'>{t('transaction:transaction.history.bonusTurnover')}</li>
                                                                <li className='history-turnoverWithoutBonus'>{t('transaction:transaction.history.turnoverWithoutBonus')}</li>
                                                                <li className='history-totalBonus'>{t('transaction:transaction.history.totalBonus')}</li>
                                                                <li className='history-totalRebate'>{t('transaction:transaction.history.totalRebate')}</li>
                                                            </ul>

                                                            {this.state.histories.length > 0 ? (
                                                                <div>
                                                                    {bettingSummary}

                                                                    <Pagination
                                                                        firstPageText={<SVGHistory name='prevFirst-arrow' className='' alt='prevFirst-arrow' />}
                                                                        lastPageText={<SVGHistory name='nextLast-arrow' className='' alt='nextLast-arrow' />}
                                                                        prevPageText={<SVGHistory name='prev-arrow' className='' alt='prev-arrow' />}
                                                                        nextPageText={<SVGHistory name='next-arrow' className='' alt='next-arrow' />}
                                                                        activePage={this.state.currentPage}
                                                                        itemsCountPerPage={this.state.todosPerPage}
                                                                        totalItemsCount={parseInt(this.state.totalItems)}
                                                                        pageRangeDisplayed={5}
                                                                        onChange={this.handlePageChange.bind(this)}
                                                                    />
                                                                </div>
                                                            ) : (
                                                                <Row className='no-Data'>{t('transaction:transaction.emptyHistory')}</Row>
                                                            )}
                                                        </div>
                                                    );

                                                case 'transfer':
                                                    let renderTodos = this.state.histories.map((hist, index) => {
                                                        return (
                                                            <ul key={index} className='history-details' onClick={() => this.showDetails('transfer', hist)}>
                                                                <li className='history-date'>{moment(hist.createdDate).format('YYYY-MM-DD HH:mm').toString()}</li>
                                                                <li className='history-amount'>{`${hist.currency} ${window.SPL_Other.formatAmount(hist.amount)}`}</li>
                                                                <li className='history-from'>
                                                                    {customTranslationObj && t(customTranslationObj[hist.fromProvider])
                                                                        ? t(customTranslationObj[hist.fromProvider])
                                                                        : t(hist.from)}
                                                                </li>
                                                                <li className='history-from'>
                                                                    {customTranslationObj && t(customTranslationObj[hist.toProvider])
                                                                        ? t(customTranslationObj[hist.toProvider])
                                                                        : t(hist.to)}
                                                                </li>
                                                                <li className='history-type'>{t('settings:settings.history.transfer')}</li>
                                                                <li className='history-promo'>{hist.promoCode}</li>
                                                                <li className={`history-status ${hist.resultClass}`}>{t(hist.result)}</li>
                                                                <li className='history-remark'>-</li>
                                                            </ul>
                                                        );
                                                    });

                                                    return (
                                                        <div className='history-header'>
                                                            <ul className='history-title-div'>
                                                                <li className='history-date' onClick={() => this.sortHistories('date')}>
                                                                    {t('transaction:transaction.history.date')}
                                                                </li>
                                                                <li className='history-amount' onClick={() => this.sortHistories('amount')}>
                                                                    {t('transaction:transaction.history.amount')}
                                                                </li>
                                                                <li className='history-from'>{t('transaction:transaction.history.from')}</li>
                                                                <li className='history-to'>{t('transaction:transaction.history.to')}</li>
                                                                <li className='history-type'>{t('transaction:transaction.history.type')}</li>
                                                                <li className='history-promo'>{t('transaction:transaction.history.promoCode')}</li>
                                                                <li className='history-status' onClick={() => this.sortHistories('status')}>
                                                                    {t('transaction:transaction.history.status')}
                                                                </li>
                                                                <li className='history-remark'>{t('transaction:transaction.history.remark')}</li>
                                                            </ul>

                                                            {this.state.histories.length > 0 ? (
                                                                <div>
                                                                    {renderTodos}

                                                                    <Pagination
                                                                        firstPageText={<SVGHistory name='prevFirst-arrow' className='' alt='prevFirst-arrow' />}
                                                                        lastPageText={<SVGHistory name='nextLast-arrow' className='' alt='nextLast-arrow' />}
                                                                        prevPageText={<SVGHistory name='prev-arrow' className='' alt='prev-arrow' />}
                                                                        nextPageText={<SVGHistory name='next-arrow' className='' alt='next-arrow' />}
                                                                        activePage={this.state.currentPage}
                                                                        itemsCountPerPage={this.state.todosPerPage}
                                                                        totalItemsCount={parseInt(this.state.totalItems)}
                                                                        pageRangeDisplayed={5}
                                                                        onChange={this.handlePageChange.bind(this)}
                                                                    />
                                                                </div>
                                                            ) : (
                                                                <Row className='no-Data'>{t('transaction:transaction.emptyHistory')}</Row>
                                                            )}
                                                        </div>
                                                    );

                                                case 'depositWithdraw':
                                                    let renderTodos1 = this.state.histories.map((hist, index) => {
                                                        return (
                                                            <ul key={index} className='history-details' onClick={() => this.showDetails('depositWithdraw', hist)}>
                                                                <li className='history-date'>{moment(hist.date).format('YYYY-MM-DD HH:mm').toString()}</li>
                                                                <li className='history-amount'>{hist.amount}</li>
                                                                <li className='history-method'>{t(hist.method)}</li>
                                                                <li className='history-type'>{t(hist.transType)}</li>
                                                                <li className={`history-status ${hist.resultClass}`}>{t(hist.status)}</li>
                                                            </ul>
                                                        );
                                                    });

                                                    return (
                                                        <div className='history-header'>
                                                            <ul className='history-title-div'>
                                                                <li className='history-date' onClick={() => this.sortHistories('date')}>
                                                                    {t('transaction:transaction.history.date')}
                                                                </li>
                                                                <li className='history-amount' onClick={() => this.sortHistories('amount')}>
                                                                    {t('transaction:transaction.history.amount')}
                                                                </li>
                                                                <li className='history-method'>{t('transaction:transaction.history.method')}</li>
                                                                <li className='history-type'>{t('transaction:transaction.history.type')}</li>
                                                                <li className='history-status' onClick={() => this.sortHistories('status')}>
                                                                    {t('transaction:transaction.history.status')}
                                                                </li>
                                                            </ul>

                                                            {this.state.histories.length > 0 ? (
                                                                <div>
                                                                    {renderTodos1}

                                                                    <Pagination
                                                                        firstPageText={<SVGHistory name='prevFirst-arrow' className='' alt='prevFirst-arrow' />}
                                                                        lastPageText={<SVGHistory name='nextLast-arrow' className='' alt='nextLast-arrow' />}
                                                                        prevPageText={<SVGHistory name='prev-arrow' className='' alt='prev-arrow' />}
                                                                        nextPageText={<SVGHistory name='next-arrow' className='' alt='next-arrow' />}
                                                                        activePage={this.state.currentPage}
                                                                        itemsCountPerPage={this.state.todosPerPage}
                                                                        totalItemsCount={parseInt(this.state.totalItems)}
                                                                        pageRangeDisplayed={5}
                                                                        onChange={this.handlePageChange.bind(this)}
                                                                    />
                                                                </div>
                                                            ) : (
                                                                <Row className='no-Data'>{t('transaction:transaction.emptyHistory')}</Row>
                                                            )}
                                                        </div>
                                                    );

                                                case 'promotion':
                                                    let renderTodos2 = this.state.histories.map((hist, index) => {
                                                        return (
                                                            <ul key={index} className='history-details' onClick={() => this.showDetails('promotion', hist)}>
                                                                <li className='history-date'>{moment(hist.createdDate).format('YYYY-MM-DD HH:mm').toString()}</li>
                                                                <li className='history-amount'>{t(hist.promoName)}</li>
                                                                <li className='history-targetamount'>
                                                                    {`${hist.currency} ${window.SPL_Other.formatAmount(hist.targetAmount)}`}
                                                                </li>
                                                                <li className='history-targetamount'>
                                                                    {`${hist.currency} ${window.SPL_Other.formatAmount(hist.targetAchieved)}`}
                                                                </li>
                                                                <li className={`history-status ${hist.resultClass}`}>{t(hist.status)}</li>
                                                            </ul>
                                                        );
                                                    });

                                                    return (
                                                        <div className='history-header'>
                                                            <ul className='history-title-div'>
                                                                <li className='history-date' onClick={() => this.sortHistories('date')}>
                                                                    {t('transaction:transaction.history.transactionDate')}
                                                                </li>
                                                                <li className='history-amount'>{t('transaction:transaction.history.promoName')}</li>
                                                                <li className='history-targetamount'>{t('transaction:transaction.history.targetAmount')}</li>
                                                                <li className='history-targetamount'>{t('transaction:transaction.history.targetAchieved')}</li>
                                                                <li className='history-status'>{t('transaction:transaction.history.status')}</li>
                                                            </ul>

                                                            {this.state.histories.length > 0 ? (
                                                                <div>
                                                                    {renderTodos2}

                                                                    <Pagination
                                                                        firstPageText={<SVGHistory name='prevFirst-arrow' className='' alt='prevFirst-arrow' />}
                                                                        lastPageText={<SVGHistory name='nextLast-arrow' className='' alt='nextLast-arrow' />}
                                                                        prevPageText={<SVGHistory name='prev-arrow' className='' alt='prev-arrow' />}
                                                                        nextPageText={<SVGHistory name='next-arrow' className='' alt='next-arrow' />}
                                                                        activePage={this.state.currentPage}
                                                                        itemsCountPerPage={this.state.todosPerPage}
                                                                        totalItemsCount={parseInt(this.state.totalItems)}
                                                                        pageRangeDisplayed={5}
                                                                        onChange={this.handlePageChange.bind(this)}
                                                                    />
                                                                </div>
                                                            ) : (
                                                                <Row className='no-Data'>{t('transaction:transaction.emptyHistory')}</Row>
                                                            )}
                                                        </div>
                                                    );

                                                case 'rebate':
                                                    let renderTodos3 = this.state.histories.map((hist, index) => {
                                                        return (
                                                            <ul key={index} className='history-details' onClick={() => this.showDetails('rebate', hist)}>
                                                                <li className='history-date'>{moment(hist.createdDate).format('YYYY-MM-DD HH:mm').toString()}</li>
                                                                <li className='history-provider'>{t(hist.provider)}</li>
                                                                <li className='history-validto'>{hist.totalValidTurnOver}</li>
                                                                <li className='history-promoto'>{hist.totalPromoTurnOver}</li>
                                                                <li className='history-eligibleto'>{hist.totalEligibleTurnOver}</li>
                                                                <li className='history-amount'>{` ${window.SPL_Other.formatAmount(hist.amount)}`}</li>
                                                            </ul>
                                                        );
                                                    });

                                                    return (
                                                        <div className='history-header'>
                                                            <ul className='history-title-div'>
                                                                <li className='history-date' onClick={() => this.sortHistories('date')}>
                                                                    {t('transaction:transaction.history.transactionDate')}
                                                                </li>
                                                                <li className='history-from'>{t('transaction:transaction.history.provider')}</li>
                                                                <li className='history-validto'>{t('transaction:transaction.history.totalValidTurnOver')}</li>
                                                                <li className='history-promoto'>{t('transaction:transaction.history.totalPromoTurnOver')}</li>
                                                                <li className='history-eligibleto'>{t('transaction:transaction.history.totalEligibleTurnOver')}</li>
                                                                <li className='history-amount' onClick={() => this.sortHistories('amount')}>
                                                                    {t('transaction:transaction.history.amount')}
                                                                </li>
                                                            </ul>

                                                            {this.state.histories.length > 0 ? (
                                                                <div>
                                                                    {renderTodos3}

                                                                    <Pagination
                                                                        firstPageText={<SVGHistory name='prevFirst-arrow' className='' alt='prevFirst-arrow' />}
                                                                        lastPageText={<SVGHistory name='nextLast-arrow' className='' alt='nextLast-arrow' />}
                                                                        prevPageText={<SVGHistory name='prev-arrow' className='' alt='prev-arrow' />}
                                                                        nextPageText={<SVGHistory name='next-arrow' className='' alt='next-arrow' />}
                                                                        activePage={this.state.currentPage}
                                                                        itemsCountPerPage={this.state.todosPerPage}
                                                                        totalItemsCount={parseInt(this.state.totalItems)}
                                                                        pageRangeDisplayed={5}
                                                                        onChange={this.handlePageChange.bind(this)}
                                                                    />
                                                                </div>
                                                            ) : (
                                                                <Row className='no-Data'>{t('transaction:transaction.emptyHistory')}</Row>
                                                            )}
                                                        </div>
                                                    );

                                                case 'instant-rebate':
                                                    let renderTodos4 =
                                                        this.state.instantRebate &&
                                                        this.state.instantRebate.map((hist, index) => {
                                                            return (
                                                                <ul key={index} className='history-details history-rebate'>
                                                                    <li className='rebate-date'>{moment(hist.rebatePeriodEnd).format('YYYY-MM-DD HH:mm').toString()}</li>
                                                                    <li className='rebate-popup' onClick={() => this.toggleHistoryPopup(hist)}>
                                                                        {moment(hist.rebatePeriodStart).format('YYYY-MM-DD HH:mm').toString() +
                                                                            '-' +
                                                                            moment(hist.rebatePeriodEnd).format('YYYY-MM-DD HH:mm').toString()}
                                                                    </li>
                                                                    <li className='rebate-turnover'>{`${window.SPL_Other.formatAmount(hist.totalValidTurnOver)}`}</li>
                                                                    <li className='rebate-promo'>{`${window.SPL_Other.formatAmount(hist.totalPromoTurnOver)}`}</li>
                                                                    <li className='rebate-eligible'>{`${window.SPL_Other.formatAmount(hist.totalEligibleTurnOver)}`}</li>
                                                                    <li className='rebate-amount'>{`${this.props.user.account.currency} ${
                                                                        window.SPL_Other.formatAmount(hist.releasedAmount)
                                                                            ? window.SPL_Other.formatAmount(hist.releasedAmount)
                                                                            : 0
                                                                    }`}</li>
                                                                </ul>
                                                            );
                                                        });

                                                    return (
                                                        <div className='history-header'>
                                                            <ul className='history-title-div history-rebate'>
                                                                <li className='history-date' onClick={() => this.sortHistories('date')}>
                                                                    {t('transaction:transaction.history.transactionDate')}
                                                                </li>
                                                                <li className='history-provider'>{t('rebate:rebate.rebatePeriod', 'Rebate Period')}</li>
                                                                <li className='history-turnover'>{t('rebate:rebate.totalValidBet', 'Total Valid Bet')}</li>
                                                                <li className='history-turnover'>{t('rebate:rebate.totalPromoTurnover', 'TotaL Promo Turnover')}</li>
                                                                <li className='history-turnover'>{t('rebate:rebate.totalEligibleTurnover', 'Total Eligible Turnover')}</li>
                                                                <li className='history-amount' onClick={() => this.sortHistories('amount')}>
                                                                    {t('rebate:rebate.amount', 'Amount')}
                                                                </li>
                                                            </ul>

                                                            {this.state.instantRebate && this.state.instantRebate.length > 0 ? (
                                                                <div>
                                                                    {renderTodos4}

                                                                    <Pagination
                                                                        firstPageText={<SVGHistory name='prevFirst-arrow' className='' alt='prevFirst-arrow' />}
                                                                        lastPageText={<SVGHistory name='nextLast-arrow' className='' alt='nextLast-arrow' />}
                                                                        prevPageText={<SVGHistory name='prev-arrow' className='' alt='prev-arrow' />}
                                                                        nextPageText={<SVGHistory name='next-arrow' className='' alt='next-arrow' />}
                                                                        activePage={this.state.currentPage}
                                                                        itemsCountPerPage={this.state.todosPerPage}
                                                                        totalItemsCount={parseInt(this.state.totalItems)}
                                                                        pageRangeDisplayed={5}
                                                                        onChange={this.handlePageChange.bind(this)}
                                                                    />
                                                                </div>
                                                            ) : (
                                                                <Row className='no-Data'>{t('transaction:transaction.emptyHistory')}</Row>
                                                            )}
                                                        </div>
                                                    );

                                                case 'rewards':
                                                    let renderTodos5 = this.state.histories.map((hist, index) => {
                                                        return (
                                                            <ul key={index} className='history-details' onClick={() => this.showDetails('rewards', hist)}>
                                                                <li className='history-date'>{moment(hist.createdDate).format('YYYY-MM-DD HH:mm').toString()}</li>
                                                                <li className='history-reward'>{t(hist.name)}</li>
                                                                <li className='history-reward'>{t(hist.rewardType)}</li>
                                                                <li className='history-amount'>{`${hist.currency} ${window.SPL_Other.formatAmount(hist.amount)}`}</li>
                                                            </ul>
                                                        );
                                                    });

                                                    return (
                                                        <div className='history-header'>
                                                            <ul className='history-title-div'>
                                                                <li className='history-date' onClick={() => this.sortHistories('date')}>
                                                                    {t('transaction:transaction.history.transactionDate')}
                                                                </li>
                                                                <li className='history-reward'>{t('transaction:transaction.history.rewardName')}</li>
                                                                <li className='history-reward'>{t('transaction:transaction.history.rewardType')}</li>
                                                                <li className='history-amount'>{t('transaction:transaction.history.amount')}</li>
                                                            </ul>

                                                            {this.state.histories.length > 0 ? (
                                                                <div>
                                                                    {renderTodos5}

                                                                    <Pagination
                                                                        firstPageText={<SVGHistory name='prevFirst-arrow' className='' alt='prevFirst-arrow' />}
                                                                        lastPageText={<SVGHistory name='nextLast-arrow' className='' alt='nextLast-arrow' />}
                                                                        prevPageText={<SVGHistory name='prev-arrow' className='' alt='prev-arrow' />}
                                                                        nextPageText={<SVGHistory name='next-arrow' className='' alt='next-arrow' />}
                                                                        activePage={this.state.currentPage}
                                                                        itemsCountPerPage={this.state.todosPerPage}
                                                                        totalItemsCount={parseInt(this.state.totalItems)}
                                                                        pageRangeDisplayed={5}
                                                                        onChange={this.handlePageChange.bind(this)}
                                                                    />
                                                                </div>
                                                            ) : (
                                                                <Row className='no-Data'>{t('transaction:transaction.emptyHistory')}</Row>
                                                            )}
                                                        </div>
                                                    );

                                                case 'freeSpinHistory':
                                                    return (
                                                        <FreeSpinHistory
                                                            currentPage={this.state.currentPage}
                                                            todosPerPage={this.state.todosPerPage}
                                                            totalItems={this.state.totalItems}
                                                            freeSpinColumnConfigs={FreeSpinColumnConfigs}
                                                            handlePageChange={this.handlePageChange.bind(this)}
                                                            histories={this.state.histories}
                                                            showDetails={this.showDetails.bind(this)}
                                                            sortHistories={this.sortHistories.bind(this)}
                                                        />
                                                    );

                                                default:
                                                    return <Row className='no-Data'>{t('transaction:transaction.emptyHistory')}</Row>;
                                            }
                                        })()}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {this.state.showHistoryPopup && (
                            <InstantRebateHistoryModal
                                t={t}
                                isOpen={this.state.showHistoryPopup}
                                item={this.state.selectedHistory}
                                closeModal={this.toggleHistoryPopup}
                                rebatePeriodStart={this.state.rebatePeriodStart}
                                rebatePeriodEnd={this.state.rebatePeriodEnd}
                            ></InstantRebateHistoryModal>
                        )}
                    </div>
                )}
            </Translation>
        );
    }
}

export default connect(mapStateToProps, null)(withTranslation(['history', 'settings', 'transaction', 'providerName', 'rebate'])(withRouter(History)));
